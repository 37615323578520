import React from 'react';

// Components
import ContentPreview from './ContentPreview';

export default function PostSnippet({ content, users }) {
  const user = users?.find((user) => user?._id === `${content?.userID}`);

  return (
    <div className='w-full overflow-auto bg-white box-border rounded border border-linecolor py-5  flex flex-col'>
      <div className='pl-4 flex items-center '>
        <div className='flex items-center'>
          <img
            src={
              user?.profilePicture?.publicUrl ||
              `https://ui-avatars.com/api/?name=${
                content?.author || user?.firstname + ' ' + user?.lastname
              }&bold=true`
            }
            alt='user'
            className='rounded-full h-10 w-10 flex items-center justify-center mr-4'
          />
          <div className='flex flex-col pl-3.5 pr-12'>
            <div>
              <p
                style={{ fontSize: '16px' }}
                className='flex-1 w-32 text-sm font-semibold font-body text-charcoal'
              >
                {content?.author || user?.firstname + ' ' + user?.lastname}
              </p>
            </div>
            <div>
              <p className='text-xs font-body text-textparagraph'>
                {content?.contentFile?.mimeType === 'application/pdf' ||
                content?.thumbnailFile?.publicUrl ||
                content?.contentFile?.publicUrl
                  ? content?.title || content?.postText
                  : null}
              </p>
            </div>
          </div>
        </div>
      </div>

      <a
        href={
          content?.category === 'Video'
            ? `/video/watch/${content?._id}`
            : `/article/read/${content?._id}`
        }
      >
        {content?.contentFile?.mimeType === 'application/pdf' ||
        content?.link ? (
          <div className='w-full h-64 sm:h-96 text-xs font-body text-charcoal px-4 pt-5 mb-2'>
            <iframe
              width='100%'
              height='100%'
              title='post-attachment'
              src={content?.link || content?.contentFile?.publicUrl}
            />
          </div>
        ) : content?.thumbnailFile?.publicUrl ||
          content?.contentFile?.publicUrl ? (
          <div className='w-full h-64 sm:h-96 text-xs font-body text-charcoal px-4 pt-5 mb-2'>
            <ContentPreview
              fileUrl={
                content?.thumbnailFile?.publicUrl ||
                content?.contentFile?.publicUrl
              }
              profile_post
            />
          </div>
        ) : (
          <>
            <div
              style={{ fontSize: '15px' }}
              className='w-full h-10 sm:h-20 font-body text-charcoal px-20'
            >
              {content?.title || content?.postText}
            </div>
          </>
        )}
      </a>
    </div>
  );
}
