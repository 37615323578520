import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { ExpandMore } from '@material-ui/icons';

export default function BrowseMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls='browse-menu'
        aria-haspopup='true'
        onClick={handleClick}
        style={{ textTransform: 'none', color: 'white' }}
      >
        <span className='font-display'>Browse</span> <ExpandMore />
      </Button>
      <Menu
        id='browse-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to='/careerguidance'>
          <MenuItem onClick={handleClose}>Career guidance</MenuItem>
        </Link>

        <a href='https://www.truity.com/test/holland-code-career-test'>
          <MenuItem onClick={handleClose}>Career assessement</MenuItem>
        </a>

        <a href='https://drive.google.com/file/d/1AM08rHzxqF_sZMNxGZXsG_URu2P6N5zO/view?usp=sharing'>
          <MenuItem onClick={handleClose}>Personal development plan</MenuItem>
        </a>
        <Link to='/mentors'>
          <MenuItem onClick={handleClose}>Mentors</MenuItem>
        </Link>
        <Link to='/upskilling'>
          <MenuItem onClick={handleClose}>Upskill</MenuItem>
        </Link>
        <Link to='#'>
          <MenuItem onClick={handleClose}>Courses</MenuItem>
        </Link>
        <Link to='#'>
          <MenuItem onClick={handleClose}>
            Connect with peers / communities/ Groups
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}
