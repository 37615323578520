import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Select = ({
  placeholder,
  borderColor = 'border-linecolor',
  backgroundColor = 'bg-lightgraybg',
  textColor = 'text-gray',
  fontWeight = 'font-medium',
  focus = 'focus:text-textsecondary',
  outline = 'focus:outline-noone ',
  margin,
  disabled = false,
  type,
  icon,
  submitInput,
  value,
  width = 'w-full',
  name,
  data,
}) => {
  const [content, setContent] = useState('');

  const getInputValue = ({ target: { value } }) => {
    setContent(value);
    if (submitInput) {
      submitInput(value);
    }
  };

  useEffect(() => {
    if (value && value !== content) {
      setContent(value);
    }
  }, [value, content]);

  return (
    <div>
      <select
        value={content}
        className={`${backgroundColor} h-11 outline-none  border ${borderColor} box-border rounded ${margin} ${width} ${outline}  ${focus} px-4 font-body ${textColor} ${fontWeight}`}
        margin={margin}
        onChange={getInputValue}
        disabled={disabled}
      >
        <option value='' hidden defaultValue>
          {placeholder}
        </option>
        {data.map((item, key) => (
          <option key={key} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

Select.protoTypes = {
  placeholder: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  // backgroundColor: PropTypes.string,
  // loading: PropTypes.boolean,
  // contentColor: PropTypes.string,
  // borderColor: PropTypes.string,
  // borderRadius: PropTypes.string,
  submitInput: PropTypes.func.isRequired,
  // disabled: PropTypes.boolean,
};
export default Select;
