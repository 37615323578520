import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StarRate } from '@material-ui/icons';
import Loading from '../components/Loading';
import NavHeader from '../components/NavBarHeader';
import FeedbackModal from '../components/ContentFeedBack';
import RelatedContent from '../components/RelatedContent';

import * as helpers from '../helpers';

import * as institutionActions from '../redux/actions/institution';
import * as contentActions from '../redux/actions/content';

const PageBody = ({ video }) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [hideDescription, toggleHideDescription] = helpers.useToggle(true);

  return (
    <>
      <div className='mx-auto sm:w-screen md:w-full lg:max-w-screen-xl lg:px-8'>
        <div className='px-2 pt-4 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-8 lg:px-8 lg:py-8'>
          <div className='flex flex-col mb-2 lg:justify-start lg:flex-row'>
            <h2 className='w-full font-sans text-xl font-bold tracking-tight text-charcoal sm:text-base sm:leading-none md:text-xl'>
              <span className='inline-block mb-1 sm:mb-2 font-display'>
                {video?.title}
              </span>
            </h2>
          </div>
        </div>
      </div>
      <div className='mx-auto sm:w-screen md:w-full lg:max-w-screen-xl lg:px-8'>
        <div className=' pb-4 container mx-auto sm:max-w-full sm:px-2 md:max-w-full lg:max-w-full md:px-8 lg:px-8 '>
          <div className='items-center'>
            {`${video?.contentFile?.publicUrl}`.includes('youtube.com/') ? (
              <iframe
                src={helpers.embedUrlSanitizer(video?.contentFile?.publicUrl)}
                title={video?.title}
                width='100%'
                height='500'
              />
            ) : (
              <video controls className='w-full appearance-none h-1/4 mb-4'>
                <source src={video?.contentFile?.publicUrl} />
              </video>
            )}
          </div>
          <div className='py-4 px-2 sm:px-0 mb-5 font-semibold text-sm text-gray font-display'>
            <p>Author: {video?.author || video?.postedBy}</p>
            {video?.sourceLink ? (
              <p className='text-gray4 mb-8 font-display'>
                Source Link:
                <a href={video.sourceLink} target='_blank' rel='noreferrer'>
                  {video.sourceLink}
                </a>
              </p>
            ) : null}
          </div>
          <div className='flex flex-row w-full justify-between py-4 px-2 sm:px-0 mb-5 font-semibold text-sm text-gray'>
            <div className='items-center '>
              <span
                className='cursor-pointer inline-flex items-center justify-center h-10 px-6 font-medium tracking-wide text-white transition duration-200 rounded-full font-display shadow-md bg-headingtext hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                aria-label='Read Description'
                title='Click to view description'
                aria-controls='Read Description'
                aria-haspopup='true'
                onClick={() => toggleHideDescription()}
              >
                Read Description
              </span>
            </div>
            <div className='text-starsorange text-xl sm:text-4xl '>
              <StarRate style={{ fontSize: '40px' }} />
              <span className=' font-semibold  text-starsorange mb-6'>
                {parseFloat(video?.avgRating || 0).toFixed(1)}
              </span>
            </div>
          </div>
          <div
            hidden={hideDescription}
            className='bg-lightGrayBg2 border-b pb-11 border-linecolor leading-6 px-4'
          >
            <p className='inline-block mb-1 sm:mb-2 '>{video?.description}</p>
          </div>
        </div>
      </div>
      <div className='mx-auto sm:w-screen md:w-full lg:max-w-screen-xl lg:px-8'>
        <div className='px-2 pb-4 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full font-display md:px-8 lg:px-8  z-30'>
          <div className='flex flex-col text-charcoal'>
            <RelatedContent type={'Video'} typeID={video?._id} />
          </div>
        </div>
        <FeedbackModal
          content={video}
          showFeedbackModal={showFeedbackModal}
          setShowFeedbackModal={setShowFeedbackModal}
        />
      </div>
    </>
  );
};

export default function VideoWatchPage() {
  const { videoID } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const video = useSelector((state) => state.content.data);
  const [loading, setLoading] = useState(true);
  const institutions = useSelector((state) => state?.institution?.data);

  useEffect(() => {
    dispatch(institutionActions.getInstitutions({}));
  }, []);

  useEffect(() => {
    if (video.length <= 0) {
      dispatch(contentActions.getSpecificContent(videoID));
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [video, videoID]);

  return (
    <>
      <NavHeader institutions={institutions} user={user} />
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <PageBody video={video} />
      )}
    </>
  );
}
