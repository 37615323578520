import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import logo from '../assets/new_delvLogo.png';

import { Person, School } from '@material-ui/icons';

const Signup = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (user !== null) {
      if (user.usercategory[0] === 'institution') {
        history.push('/institution/feeds');
      } else {
        history.push('/feeds');
      }
    }
  }, []);

  return (
    <div>
      <section className='bg-white {-- h-screen --}'>
        <div className='mx-auto flex lg:justify-center h-full flex-col lg:flex-row'>
          <div className='w-full lg:w-2/5 px-2 py-20 sm:py-40 sm:px-12 flex flex-col justify-center items-center bg-darkerblue relative'>
            <div className='absolute left-0 top-0 pt-4 px-4 sm:px-12 sm:pt-8'>
              <div className=''>
                <Link to='/'>
                  <img
                    src={logo}
                    className='object-scale-down w-28 h-8 sm:w-28 sm:h-8'
                    alt='delv logo'
                  />
                </Link>
              </div>
            </div>
            <div className='absolute right-0 top-0 '>
              <svg width='208' height='208' xmlns='http://www.w3.org/2000/svg'>
                <g id='SemiCircle'>
                  <title>SemiCircle</title>
                  <ellipse
                    stroke='null'
                    fill='#000000'
                    strokeWidth='12'
                    cx='145'
                    cy='63'
                    id='svg_6'
                    rx='144'
                    ry='144'
                    opacity='0.1'
                  />
                </g>
              </svg>
            </div>
            <div className='flex relative z-30 flex-col justify-center px-4 md:pr-12 text-left text-white'>
              <h3 className='text-4xl leading-tight font-bold font-display'>
                Rethink, Ignite your Career
              </h3>
              {/* <h5 className='text-4xl pt-4 leading-tight font-semibold'></h5> */}
              <span className='text-lg pt-8 leading-relaxed  font-normal font-display'>
                Find practical career guidance, Learn new skills, Join learning
                communities, explore, engage, collaborate, become a life long
                learner
              </span>
            </div>
            <div className='absolute left-0 bottom-0'>
              <svg width='208' height='256' xmlns='http://www.w3.org/2000/svg'>
                <g id='squarePattern'>
                  <title>SquarePatten</title>
                  <rect
                    stroke='#000000'
                    fill='none'
                    strokeWidth='9'
                    x='-188.00001'
                    y='7.99999'
                    width='389.00001'
                    height='330.42585'
                    id='svg_7'
                    opacity='0.1'
                  />
                  <rect
                    stroke='null'
                    fill='#000000'
                    strokeWidth='21'
                    x='-160.50001'
                    y='27.84689'
                    width='343.00001'
                    height='294.30622'
                    id='svg_8'
                    opacity='0.1'
                  />
                </g>
              </svg>
            </div>
          </div>

          <UserCards />
        </div>
      </section>
    </div>
  );
};

export const UserCards = ({ setInstitutionAccount, setIndividualAccount }) => {
  return (
    <div className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
      <div className='max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12'>
        <div>
          <p className='inline-block px-3 py-px mb-4 text-lg font-semibold tracking-wider text-charcoal uppercase rounded-full font-display'>
            Sign Up
          </p>
        </div>
        <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-charcoal sm:text-4xl md:mx-auto font-display'>
          <span className='relative inline-block'>
            <span className='relative'>Join </span>
          </span>{' '}
          the Delv community.
        </h2>
      </div>
      <div className='grid max-w-md gap-10 row-gap-5 sm:row-gap-10 lg:max-w-screen-md lg:grid-cols-2 sm:mx-auto'>
        <Link to='/signup/individual'>
          <UserType type='individual' icon={<Person fontSize='inherit' />} />
        </Link>
        <Link to='/signup/institution'>
          <UserType type='institution' icon={<School fontSize='inherit' />} />
        </Link>
      </div>
      <div className='max-w-xl mt-10 md:mx-auto sm:text-center lg:max-w-2xl md:mt-12'>
        <p className='text-base text-charcoal md:text-sm font-display'>
          Already a member{'  '}
          <Link to='/login' className='ml-4 font-semibold underline text-main'>
            Sign In
          </Link>
        </p>
      </div>
    </div>
  );
};

const UserType = ({ type, icon }) => {
  return (
    <div className='flex flex-col justify-between p-5 bg-white boverflow-hidden duration-300 transform rounded shadow-sm hover:-translate-y-2 border-b-4 border-white hover:border-darkerblue'>
      <div className=''>
        <div className='flex items-center justify-between py-9 '>
          <div>
            <p className='text-base text-headingtext md:text-basefont-bold tracking-wider  mb-2'>
              Delv in as an
            </p>
            <p className='text-3xl sm:text-4xl font-extrabold capitalize text-charcoal'>
              {type}
            </p>
          </div>
          <div className='ml-4 flex items-center justify-center text-6xl rounded-full text-darkerblue'>
            {icon}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
