import React from 'react';

import ContentPreview from './ContentPreview';
import ModalWrapper from './ModalWrapper';

export default function ActivityDetailModal(props) {
  return (
    <>
      {props.showActivityDetail ? (
        <>
          <form onSubmit={props.handleFeedback}>
            <ModalWrapper
              title={props?.selectedActivityPoint?.activity}
              closeModal={() => props.setShowActivityDetail(false)}
              bodyContent={
                <>
                  <div className='w-full overflow-auto bg-white box-border rounded border border-linecolor py-5  flex flex-col'>
                    {props.selectedPointPost?.contentFile?.mimeType ===
                      'application/pdf' || props.selectedPointPost?.link ? (
                      <div className='w-full h-64 sm:h-96 text-xs font-body text-charcoal px-4 pt-5 mb-2'>
                        <iframe
                          width='100%'
                          height='100%'
                          title='post-attachment'
                          src={
                            props.selectedPointPost?.link ||
                            props.selectedPointPost?.contentFile?.publicUrl
                          }
                        />
                      </div>
                    ) : props.selectedPointPost?.thumbnailFile?.publicUrl ||
                      props.selectedPointPost?.contentFile?.publicUrl ? (
                      <div className='w-full h-64 sm:h-96 text-xs font-body text-charcoal px-4 pt-5 mb-2'>
                        <ContentPreview
                          fileUrl={
                            props.selectedPointPost?.thumbnailFile?.publicUrl ||
                            props.selectedPointPost?.contentFile?.publicUrl
                          }
                          profile_post
                        />
                      </div>
                    ) : (
                      <>
                        <div
                          style={{ fontSize: '15px' }}
                          className='w-full h-10 sm:h-20 font-body text-charcoal px-20'
                        >
                          {props.selectedPointPost?.title ||
                            props.selectedPointPost?.postText}
                        </div>
                      </>
                    )}
                  </div>

                  <textarea
                    className='flex-grow w-full p-4 mb-2
                transition duration-200 bg-lightgraybg border
                border-linecolor rounded appearance-none
                focus:border-darkerblue focus:outline-noone
                focus:shadow-outline'
                    placeholder={'Add a Feedback to this Post'}
                    rows='1'
                    value={props.feedback}
                    onChange={(e) => {
                      props.setFeedback(e.target.value);
                    }}
                  />
                  <button
                    type='submit'
                    className='cursor-pointer w-full inline-flex items-center justify-center h-14 text-xl px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                  >
                    Remove Points from Post
                  </button>
                </>
              }
            />
          </form>
        </>
      ) : null}
    </>
  );
}
