import React from 'react';
import Truncate from 'react-truncate';

const CourseCard = ({ course }) => {
  return (
    <a href={`/course/view/${course}`}>
      <div
        aria-label='video Item'
        className='inline-block overflow-hidden duration-300 transform bg-white rounded shadow-sm hover:-translate-y-2 border-b-4 border-white hover:border-darkerblue sm:m-3 w-full p-2'
      >
        <img
          src={`https://storage.googleapis.com/delvapp/${course}.png`}
          alt={course}
          className='object-cover w-full h-full'
          style={{ height: '200px' }}
        />

        <div className='flex flex-col'>
          <div className='flex flex-col flex-grow justify-between  rounded-b'>
            <div className='py-3 px-2'>
              <h6 className='font-semibold leading-5 capitalize'>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {course}
                </Truncate>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default CourseCard;
