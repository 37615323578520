import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import CustomAlert from '../components/CustomAlert';

// Icons

import logo from '../assets/new_delvLogo.png';

// components
import Input from '../components/Input';
import Select from '../components/Select';
import ButtonLoader from '../components/ButtonLoader';

// Actions
import * as institutionActions from '../redux/actions/institution';
import * as userActions from '../redux/actions/users';

import { ArrowBack, Close } from '@material-ui/icons';
import CustomAlert from '../components/CustomAlert';
import VisibilityHandler from '../components/VisibilityHandler';

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user.user);
  const [userCategory, setUserCategory] = useState('institution');
  const institution = useSelector((state) => state?.institution);
  const { loading, success } = institution;

  // Initial state
  // Admin Account Credentials
  const [adminFirstName, setAdminFirstName] = useState('');
  const [adminLastName, setAdminLastName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  //
  // Institution Account Credentials
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [industry, setIndustry] = useState('');
  const [website, setWebsite] = useState('');
  const [bio, setBio] = useState('');
  const [specialities, setSpecialities] = useState([]);
  // const [profilePicture, setProfilePicture] = useState([]);

  const [admin, setAdmin] = useState(`${user?._id}`);

  // Tag controllers

  // remove tags
  const removeTags = (indexToRemove) => {
    setSpecialities([
      ...specialities.filter((_, index) => index !== indexToRemove),
    ]);
  };
  //Add Chips tags
  const addTags = (event) => {
    if (
      ['Enter', ',', 'Tab'].includes(event.key) &&
      event.target.value !== ''
    ) {
      event.preventDefault();
      setSpecialities([...specialities, event.target.value.trim()]);
      event.target.value = '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      firstname: adminFirstName,
      lastname: adminLastName,
      email: email,
      password: adminPassword,
      usercategory: userCategory,
      institutionName: institutionName,
      country: country,
      area: areaCode,
      postalcode: postalCode,
      website: website,
      city: city,
      specialities: specialities,
      bio: bio,
    };
    // console.log(` Institution Payload`, payload);
    dispatch(institutionActions.createInstitution(payload));
  };

  useEffect(() => {
    if (success.message === 'Institution profile created') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Institution profile created',
      });

      dispatch(
        userActions.logIn({
          email: email,
          password: adminPassword,
        }),
      );
      history.push('/institution/feeds');
    }
  }, [success]);

  const [visibility, setVisibility] = useState(false);
  const [secondVisibility, setSecondVisibility] = useState(false);

  return (
    <div>
      <section className='bg-white {-- h-full --}'>
        <div className='mx-auto flex lg:justify-center h-full flex-col lg:flex-row'>
          <div className='w-full lg:w-2/5 px-2 py-20 sm:py-40 sm:px-12 flex flex-col justify-center items-center bg-darkerblue relative'>
            <div className='absolute left-0 top-0 p-4 sm:p-12'>
              <div className='px-2 sm:px-4'>
                <Link to='/'>
                  <img
                    src={logo}
                    alt='delv logo'
                    className='object-scale-down w-16 h-5 sm:w-28 sm:h-10'
                  />
                </Link>
              </div>
            </div>
            <div className='absolute right-0 top-0 '>
              <svg width='208' height='208' xmlns='http://www.w3.org/2000/svg'>
                <g id='SemiCircle'>
                  <title>SemiCircle</title>
                  <ellipse
                    stroke='null'
                    fill='#000000'
                    strokeWidth='12'
                    cx='145'
                    cy='63'
                    id='svg_6'
                    rx='144'
                    ry='144'
                    opacity='0.1'
                  />
                </g>
              </svg>
            </div>
            <div className='flex relative z-30 flex-col justify-center px-4 md:pr-12 text-left text-white'>
              <h3 className='text-4xl leading-tight font-bold font-display'>
                Rethink, Ignite your Career
              </h3>
              {/* <h5 className='text-4xl pt-4 leading-tight font-semibold'></h5> */}
              <span className='text-lg pt-8 leading-relaxed  font-normal font-display'>
                Find practical career guidance, Learn new skills, Join learning
                communities, explore, engage, collaborate, become a life long
                learner
              </span>
            </div>
            <div className='absolute left-0 bottom-0'>
              <svg width='208' height='256' xmlns='http://www.w3.org/2000/svg'>
                <g id='squarePattern'>
                  <title>SquarePatten</title>
                  <rect
                    stroke='#000000'
                    fill='none'
                    strokeWidth='9'
                    x='-188.00001'
                    y='7.99999'
                    width='389.00001'
                    height='330.42585'
                    id='svg_7'
                    opacity='0.1'
                  />
                  <rect
                    stroke='null'
                    fill='#000000'
                    strokeWidth='21'
                    x='-160.50001'
                    y='27.84689'
                    width='343.00001'
                    height='294.30622'
                    id='svg_8'
                    opacity='0.1'
                  />
                </g>
              </svg>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            // ref={form}
            className='w-full lg:w-3/5 flex justify-center bg-white h-full overflow-y-auto'
          >
            <div className='w-full sm:w-4/6 md:w-3/6 lg:w-2/3 text-charcoal  flex flex-col justify-center px-2 sm:px-0 py-16'>
              <div className='px-2 sm:px-6 grid grid-cols-3'>
                <Link to='/signup'>
                  <h3 className='inline-flex items-center justify-center w-full text-2xl text-charcoal hover:text-darkerblue font-display'>
                    <ArrowBack fontSize='inherit' /> Go back
                  </h3>
                </Link>

                <h3 className='text-2xl sm:text-3xl md:text-2xl font-bold leading-relaxed text-center font-display'>
                  Register Institution
                </h3>
              </div>
              <div className='mt-8 w-full px-2 sm:px-6'>
                <div className='flex flex-col mb-4'>
                  <Input
                    placeholder='Institution Name'
                    type='text'
                    width='w-full md:w-full'
                    submitInput={setInstitutionName}
                    id='institutionName'
                    value={institutionName}
                  />
                </div>
                <div className='flex flex-col mb-4'>
                  <Select
                    name='Higher Education'
                    margin=''
                    width='w-full sm:w-full md:w-full '
                    data={[
                      { value: 'Higher Education', label: 'Higher Education' },
                      {
                        value: 'Education / Learning',
                        label: 'Education / Learning',
                      },
                      { value: 'Finance', label: 'Finance' },
                      { value: 'Manufacturing', label: 'Manufacturing' },
                      { value: 'Commerce / trade', label: 'Commerce / trade' },
                      { value: 'Distribution', label: 'Distribution' },
                      {
                        value: 'Information Technology',
                        label: 'Information Technology',
                      },
                      {
                        value: 'Health care / Medicine',
                        label: 'Health care / Medicine',
                      },
                      { value: 'Construction', label: 'Construction' },
                      { value: 'Media', label: 'Media' },
                      { value: 'Banking', label: 'Banking' },
                      {
                        value: 'Social Entreprise',
                        label: 'Social Entreprise',
                      },
                      {
                        value: 'Financial services',
                        label: 'Financial services',
                      },
                      { value: 'Agriculture', label: 'Agriculture' },
                      { value: 'Human Ressources', label: 'Human Ressources' },
                      { value: 'Consulting', label: 'Consulting' },
                      { value: 'Government', label: 'Government' },
                      {
                        value: 'Development sector',
                        label: 'Development sector',
                      },
                      { value: 'Philantropy', label: 'Philantropy' },
                      { value: 'Other,…………', label: 'Other,…………' },
                    ]}
                    placeholder='Institution Category'
                    submitInput={setIndustry}
                    id='industry'
                    value={industry}
                  />
                </div>
                <div className='flex flex-col justify-between  sm:grid sm:grid-cols-2 mt-2 space-y-2 sm:space-x-2 sm:space-y-0 mb-4'>
                  <Select
                    name='Country'
                    margin='mr-6'
                    width='w-full '
                    data={[
                      { value: 'Uganda', label: 'Uganda' },
                      { value: 'Kenya', label: 'Kenya' },
                      { value: 'Rwanda', label: 'Rwanda' },
                      { value: 'Burundi', label: 'Burundi' },
                      { value: 'Tanzania', label: 'Tanzania' },
                    ]}
                    placeholder='Country'
                    submitInput={setCountry}
                    id='country'
                    value={country}
                  />
                  <Input
                    placeholder='City'
                    type='text'
                    width='w-full'
                    submitInput={setCity}
                    id='city'
                    value={city}
                  />
                </div>
                <div className='flex flex-col justify-between  sm:grid sm:grid-cols-2 mt-2 space-y-2 sm:space-x-2 sm:space-y-0 mb-4'>
                  <Input
                    placeholder='Area code'
                    type='text'
                    width='w-full'
                    submitInput={setAreaCode}
                    id='area'
                    value={areaCode}
                  />
                  <Input
                    placeholder='Postal code'
                    type='text'
                    width='w-full'
                    submitInput={setPostalCode}
                    id='postalCode'
                    value={postalCode}
                  />
                </div>
                <div className='flex-col mt-2 hidden'>
                  <input
                    value={userCategory}
                    type='text'
                    className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                    id='firstName'
                    name='userCategory'
                    submitInput={setUserCategory}
                    readOnly={true}
                  />
                </div>

                <div className='flex flex-col mb-4'>
                  <Input
                    placeholder='Institution Website'
                    type='text'
                    width='w-full md:w-full'
                    submitInput={setWebsite}
                    id='website'
                    value={website}
                  />
                </div>
                <div className='flex flex-col mb-2'>
                  <h3 className=' text-textparagraph font-semibold '>
                    SPECIALITIES
                  </h3>
                  <p className=' text-textparagraph font-normal text-sm'>
                    Comma-separated list of your skills (keep it below 10).
                  </p>
                  <div className='bg-lightgraybg rounded max-w-full h-auto  py-4 pl-3 mb-4 border border-linecolor'>
                    <div className='tags-input flex items-start flex-wrap w-full  bg-lightgraybg rounded   pl-3 '>
                      <ul
                        id='tags'
                        className='flex flex-wrap mt-2 text-white  pl-1 '
                      >
                        {specialities.map((tag, index) => (
                          <li
                            key={index}
                            className='tag px-1 h-6 flex bg-main rounded justify-center mr-1 mb-1 '
                          >
                            <span className='tag-title font-medium '>
                              {tag}
                            </span>
                            <span
                              className='tag-close-icon ml-2 my-1 text-center h-4 w-4 rounded-full bg-white text-main text-xs cursor-pointer hover:bg-buttonRed hover:text-white '
                              onClick={() => removeTags(index)}
                            >
                              <Close fontSize='inherit' />
                            </span>
                          </li>
                        ))}
                      </ul>
                      <input
                        className='bg-lightgraybg rounded  text-sm border border-linecolor h-8 outline-noone hover:border-darkerblue'
                        type='text'
                        placeholder='Put a comma to add tags'
                        onKeyDown={addTags}
                      />
                    </div>
                  </div>
                </div>
                <div className='flex flex-col mb-8'>
                  <h3 className=' text-textparagraph font-semibold '>BIO</h3>
                  <textarea
                    id='bio'
                    value={bio}
                    className='bg-lightgraybg border border-linecolor hover:border-darkerblue rounded p-2 text-sm outline-noone'
                    placeholder='Type a summary of your bio...'
                    rows={6}
                    onChange={(e) => {
                      setBio(e.target.value);
                    }}
                  />
                </div>
                <div className='flex flex-col mt-2 '>
                  {/* <p className=' pt-6 text-textparagraph font-semibold'>
                    PROFILE PICTURE
                  </p> */}
                  <p className=' text-textparagraph font-normal text-sm'>
                    Share yout institution image - at least 400x400 pixels big.
                  </p>
                  {/* <div className=' bg-lightgraybg rounded-xl max-w-full mb-4 '>
                    <DropzoneArea
                      filesLimit={1}
                      showAlerts={true}
                      maxFileSize={5000000}
                      acceptedFiles={['image/jpg', 'image/jpeg', 'image/png']}
                      onChange={(files) => setProfilePicture(files)}
                      dropzoneText={'Add profile photo. (Drag and drop)'}
                      dropzoneParagraphClass='text-textparagraph font-semibold text-sm'
                    />
                  </div> */}
                </div>
                <div className='flex flex-col mt-2 hidden'>
                  <Input
                    placeholder='Institution ID'
                    type='text'
                    width='w-full md:w-full '
                    submitInput={setAdmin}
                    id='bio'
                    value={admin}
                    height='h-32'
                    backgroundColor='bg-lightgraybg'
                  />
                </div>
                <h3 className=' text-textparagraph font-semibold mt-6'>
                  INSITUTION ADMINISTRATOR
                </h3>
                <p className=' text-textparagraph font-normal text-sm'>
                  The information provided here will be for the Administrator
                  account.
                </p>
                <div className='flex flex-col justify-between  sm:grid sm:grid-cols-2 mt-2 space-y-2 sm:space-x-2 sm:space-y-0 mb-2'>
                  <Input
                    placeholder='First Name'
                    type='text'
                    margin='mb-2'
                    width='w-full'
                    submitInput={setAdminFirstName}
                    id='firstName'
                    value={adminFirstName}
                  />
                  <Input
                    placeholder='Last Name'
                    type='text'
                    width='w-full'
                    margin='mb-2'
                    submitInput={setAdminLastName}
                    id='lastName'
                    value={adminLastName}
                  />
                </div>

                <div className='flex flex-col mt-2'>
                  <Input
                    placeholder='Email'
                    type='email'
                    margin='mb-4'
                    width='w-full'
                    submitInput={setEmail}
                    id='email'
                    value={email}
                  />
                </div>
                <div className='flex flex-col justify-between  sm:grid sm:grid-cols-2 mt-2 space-y-2 sm:space-x-2 sm:space-y-0 mb-2'>
                  <div className='relative mb-4'>
                    <VisibilityHandler
                      visibility={visibility}
                      setVisibility={setVisibility}
                    />
                    <input
                      className='text-sm focus:outline-noone sm:text-lg text-charcoal relative h-11 border rounded border-linecolor focus:border-main bg-lightgraybg opacity-1 text-gray w-full py-2 pr-2 pl-12'
                      id='password'
                      type={visibility ? 'text' : 'password'}
                      name='password-repeat'
                      placeholder='Type password'
                      value={adminPassword}
                      onChange={(e) => setAdminPassword(e.target.value)}
                    />
                  </div>
                  <div className='relative mb-4'>
                    <VisibilityHandler
                      visibility={secondVisibility}
                      setVisibility={setSecondVisibility}
                    />
                    <input
                      className='text-sm focus:outline-noone sm:text-lg text-charcoal relative h-11 border rounded border-linecolor focus:border-main bg-lightgraybg opacity-1 text-gray w-full py-2 pr-2 pl-12'
                      id='password'
                      type={secondVisibility ? 'text' : 'password'}
                      name='password-repeat'
                      placeholder='Re-type password'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <span
                    className={`text-red text-sm ${
                      adminPassword === confirmPassword && 'hidden mb-4 '
                    }`}
                  >
                    Passwords do not match.
                  </span>
                </div>
              </div>

              <div className='px-2 sm:px-6'>
                <button
                  type='submit'
                  className='font-body inline-flex items-center justify-center w-full h-14 px-6 font-semibold tracking-wide text-white transition duration-200 rounded-md  md:w-full bg-darkerblue hover:bg-navyblue ho focus:shadow-outline focus:outline-noone'
                  disabled={adminPassword !== confirmPassword}
                >
                  <span className='ml-2'>
                    {loading ? <ButtonLoader /> : 'Create Account'}
                  </span>
                </button>

                <p className='mt-6 text-xs'>
                  Already a member{' '}
                  <Link
                    to='/login'
                    className='font-semibold underline text-main'
                  >
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Signup;
