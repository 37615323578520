import React, { useState } from 'react';
import { YouTube, Apple, Android, Twitter, Facebook } from '@material-ui/icons';
import '../bright.css';
import { EmailModal } from './ContactForms';
import { ExpandMore } from '@material-ui/icons';
import { motion } from 'framer-motion/dist/framer-motion';
import { useHistory } from 'react-router';

export default function Footer() {
  const history = useHistory();
  const [dropDownActive, setDropDownActive] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const mouseHoveredBrowse = () => {
    setDropDownActive(true);
  };

  const mouseUnHoveredDropDown = () => {
    setDropDownActive(false);
  };

  const toLink = (link) => {
    history.push(`${link}`);
    console.log(link);
    setDropDownActive(false);
  };

  return (
    <>
      <div className='footer pb-5 pt-16 md:pt-40 px-4 md:px-10 text-white open-sans'>
        <div className='footerWrapper'>
          <div className='grid md:gap-5 lg:gap-10 row-gap-8 md:grid-cols-4 footerLowerDiv w-full'>
            <div className='mt-3'>
              <div className='w-full'>
                <p
                  onClick={() => setShowEmailModal(true)}
                  className='text-center contact-us py-4 px-6 md:px-5 lg:px-16 justify-center mb-10'
                >
                  Contact Us
                </p>
              </div>
              <div></div>
            </div>

            <div className='my-3 mt-10 md:mt-0'>
              <div
                className='flex flex-row text-white ml-8 py-3 my-auto cursor-pointer w-full'
                onClick={() => setDropDownActive(!dropDownActive)}
                onMouseOver={() => mouseHoveredBrowse()}
              >
                BROWSE
                <span className='ml-2'>
                  {dropDownActive ? (
                    <motion.span
                      animate={{ rotate: 180, x: 0, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className=''
                    >
                      <span>
                        <ExpandMore />
                      </span>
                    </motion.span>
                  ) : (
                    <ExpandMore />
                  )}
                </span>
              </div>
              <div className='broswe-links'>
                {dropDownActive && (
                  <motion.div
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {/* Broswe drop down on small screens */}
                    <div
                      className='bg-newMain text-white w-full top-48 md:top-20 md:right-10 lg:top-16 lg:left-40 lg:right-96 z-99'
                      onMouseLeave={() => mouseUnHoveredDropDown()}
                    >
                      <ul className='w-full'>
                        <li
                          className='dropDownItem p-3 animate-spin'
                          onClick={() => toLink('/careerguidance')}
                        >
                          CAREER GUIDANCE
                        </li>
                        <li
                          className='dropDownItem p-3 border-t border-white border-b'
                          onClick={() => toLink('/upskilling')}
                        >
                          UPSKILL
                        </li>
                        <li
                          className='dropDownItem p-3  '
                          onClick={() => toLink('/JobSearch')}
                        >
                          JOBS
                        </li>
                      </ul>
                    </div>
                  </motion.div>
                )}
              </div>
            </div>

            <div className='address-box my-4 mt-6 md:mt-0 poppins'>
              <h2 className='my-5'>info@delvcareers.com</h2>

              <h2 className='my-5 '>P.O.Box 65925 Kampala </h2>

              <h2 className='my-5 '>
                Kampala, Uganda: 428 Spine Road, Naalya{' '}
              </h2>

              <br />

              <h2 className='my-5'>HQ: 345 Grackle Street, Ottawa, Canada.</h2>

              <h2 className='my-5 '>Tel: +1 613 240 1378 </h2>
            </div>

            <div className='mb-4 lg:px-auto mt-6 md:mt-0'>
              <div>
                <p className='mb-3 w-2/3 flex justify-between'>
                  <a href='https://facebook.com'>
                    <Facebook />
                  </a>
                  <a className='ml-6' href='https://twitter.com'>
                    <Twitter />
                  </a>
                  <a href='https://youtube.com' className='mr-6'>
                    <YouTube />
                  </a>
                </p>
                <p className='my-3'>
                  <Android /> <span className='ml-6'>Android</span>
                </p>
                <p className='my-3'>
                  <Apple />
                  <span className='ml-6'> iOS</span>{' '}
                </p>
              </div>
            </div>
          </div>
          <div className='row footerLowerDiv border-t border-white mt-10 pt-10 md:flex md:justify-between w-full'>
            <div className='lg:px-3 mt-4 mb-3'>
              <p
                style={{
                  height: '48px',
                  width: '130px',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundImage: `url('https://irp.cdn-website.com/09b19d04/dms3rep/multi/delv-web-logo-01.svg')`,
                }}
                className='delv text-xl mx-3 lg:my-auto text-'
              ></p>
            </div>
            <div></div>
            <div className='my-3 lg:px-3'>
              <h4 className='font-weight-100 leading'>
                &#169; 2022 All Rights Reserved | DELV Careers
              </h4>
            </div>
            <div className='my-3 lg:px-3 text-sm'>Privacy & Terms</div>
          </div>
        </div>
      </div>

      <EmailModal
        showEmailModal={showEmailModal}
        setShowEmailModal={setShowEmailModal}
      />
    </>
  );
}
