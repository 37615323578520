import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
//import { GoogleLogin } from 'react-google-login';

// Icons
//import googleImg from '../assets/googleG.svg';
import logo from '../assets/new_delvLogo.png';
import facebook from '../assets/facebook.svg';

// components
import Input from '../components/Input';
import Select from '../components/Select';
import Button from '../components/Button';
import CustomAlert from '../components/CustomAlert';
import VisibilityHandler from '../components/VisibilityHandler';

// Actions
import * as userActions from '../redux/actions/users';
import { ArrowBack } from '@material-ui/icons';

const Signup = () => {
  let form = useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.user.loading);
  const error = useSelector((state) => state.user.error);
  const success = useSelector((state) => state.user.success);
  const errorMessage = useSelector((state) => state.user.errorMessage);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [userCategory, setUserCategory] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const responseGoogle = (response) => {
    dispatch(userActions.googleLogIn({ googleUserToken: response.credential }));

    setTimeout(function () {
      dispatch(
        userActions.googleLogIn({ googleUserToken: response.credential }),
      );
    }, 1500);
  };

  const responseFacebook = (response) => {
    // console.log(response);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      dispatch(
        userActions.createUser({
          email,
          firstname: firstName,
          lastname: lastName,
          country,
          usercategory: userCategory,
          password,
        }),
      );
    } else {
      CustomAlert({
        alertType: 'error',
        alertMessage: `Password don't match`,
      });
    }
  };

  useEffect(() => {
    if (success.message === 'Account created')
      CustomAlert({
        alertType: 'success',
        alertMessage: `An email has been sent to ${email}. Click the link to proceed`,
      });
    if (success.message === 'User logged in') history.push('/feeds');
  }, [success]);

  useEffect(() => {
    if (error) {
      if (`${errorMessage}`.includes('NetworkError')) {
        CustomAlert({
          alertType: 'error',
          alertMessage: errorMessage,
        });
      } else {
        // console.log(errorMessage);
      }
    }
  }, [error]);

  const [visibility, setVisibility] = useState(false);
  const [secondVisibility, setSecondVisibility] = useState(false);

  useEffect(() => {
    /* global google */

    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_CLIENT_ID,
      callback: responseGoogle,
    });

    google.accounts.id.renderButton(
      document.getElementById('renderGoogleButton'),
      { theme: 'outline', size: 'large', width: 500, text: 'continue_with' },
    );
  }, []);

  return (
    <div>
      <section className='bg-white {-- h-full --}'>
        <div className='mx-auto flex lg:justify-center h-full flex-col lg:flex-row'>
          <div className='w-full lg:w-2/5 px-2 py-20 sm:py-40 sm:px-12 flex flex-col justify-center items-center bg-darkerblue relative'>
            <div className='absolute left-0 top-0 p-4 sm:p-12'>
              <div className='px-2 sm:px-4'>
                <Link to='/'>
                  <img
                    src={logo}
                    alt='delv logo'
                    className='object-scale-down w-16 h-5 sm:w-28 sm:h-10'
                  />
                </Link>
              </div>
            </div>
            <div className='absolute right-0 top-0 '>
              <svg width='208' height='208' xmlns='http://www.w3.org/2000/svg'>
                <g id='SemiCircle'>
                  <title>SemiCircle</title>
                  <ellipse
                    stroke='null'
                    fill='#000000'
                    strokeWidth='12'
                    cx='145'
                    cy='63'
                    id='svg_6'
                    rx='144'
                    ry='144'
                    opacity='0.1'
                  />
                </g>
              </svg>
            </div>
            <div className='flex relative z-30 flex-col justify-center px-4 md:pr-12 text-left text-white'>
              <h3 className='text-4xl leading-tight font-bold font-display'>
                Rethink, Ignite your Career
              </h3>
              {/* <h5 className='text-4xl pt-4 leading-tight font-semibold'></h5> */}
              <span className='text-lg pt-8 leading-relaxed  font-normal font-display'>
                Find practical career guidance, Learn new skills, Join learning
                communities, explore, engage, collaborate, become a life long
                learner
              </span>
            </div>
            <div className='absolute left-0 bottom-0'>
              <svg width='208' height='256' xmlns='http://www.w3.org/2000/svg'>
                <g id='squarePattern'>
                  <title>SquarePatten</title>
                  <rect
                    stroke='#000000'
                    fill='none'
                    strokeWidth='9'
                    x='-188.00001'
                    y='7.99999'
                    width='389.00001'
                    height='330.42585'
                    id='svg_7'
                    opacity='0.1'
                  />
                  <rect
                    stroke='null'
                    fill='#000000'
                    strokeWidth='21'
                    x='-160.50001'
                    y='27.84689'
                    width='343.00001'
                    height='294.30622'
                    id='svg_8'
                    opacity='0.1'
                  />
                </g>
              </svg>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            ref={form}
            className='w-full lg:w-3/5 flex justify-center bg-white '
          >
            <div className='w-full sm:w-4/6 md:w-3/6 lg:w-2/3 text-charcoal  flex flex-col justify-center px-2 sm:px-0 py-16'>
              <div className='px-2 sm:px-6 grid grid-cols-3'>
                <Link to='/signup'>
                  <h3 cclassName='inline-flex items-center justify-center w-full text-2xl text-charcoal hover:text-darkerblue font-display'>
                    <ArrowBack fontSize='inherit' /> Go back
                  </h3>
                </Link>

                <h3 className='text-2xl sm:text-3xl md:text-2xl font-bold leading-tight text-center'>
                  Sign Up
                </h3>
              </div>
              <div className='mt-8 w-full px-2 sm:px-6'>
                <div className='flex flex-col mt-5'>
                  <div
                    id='renderGoogleButton'
                    style={{ width: '100%', marginLeft: '10%' }}
                  ></div>
                  {/*
                  <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className='bg-white hover:border-main py-2 px-4 rounded inline-flex items-center text-charcoal w-full border border-linecolor mb-3 p-1 opacity-100 font-semibold leading-4 text-base'
                      >
                        <img
                          src={googleImg}
                          alt='google icon'
                          className='fill-current w-10 h-10 mr-6'
                        />
                        <span className='font-display'>
                          Continue with Google
                        </span>
                      </button>
                    )}
                    buttonText=''
                    onSuccess={responseGoogle}
                    />*/}
                </div>
                <div className='flex flex-col mt-2'>
                  <FacebookLogin
                    appId='' // APP ID NOT CREATED YET
                    fields='name,email,picture'
                    icon={facebook}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button
                        style={{
                          width: '400px',
                          height: '40px',
                          marginLeft: '10%',
                        }}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className='bg-white hover:border-main py-2 px-4 rounded inline-flex items-center text-charcoal w-full border border-linecolor mb-3 p-1 opacity-100 font-semibold leading-4 text-base'
                      >
                        <img
                          src={facebook}
                          style={{ width: '8%' }}
                          className='fill-current mr-6'
                          alt='Facebook icon'
                        />
                        <span className='font-display'>
                          Continue with Facebook
                        </span>
                      </button>
                    )}
                  />
                </div>
                <div
                  className='flex flex-col mt-5'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <div className='flex justify-between flex-row mb-5'>
                    <span className='border-linecolor border-b-2 mr-1 m-auto  w-full'></span>
                    <span className='font-semibold text-headingtext mx-2'>
                      OR
                    </span>
                    <span className='border-linecolor border-b-2 ml-1 m-auto  w-full'></span>
                  </div>
                </div>
                <div
                  className='flex flex-col justify-between  sm:grid sm:grid-cols-2 mt-2 space-y-2 sm:space-x-2 sm:space-y-0 mb-2'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <Input
                    placeholder='First Name'
                    type='text'
                    margin='mb-2'
                    width='w-full'
                    submitInput={setFirstName}
                    id='firstName'
                    value={firstName}
                  />
                  <Input
                    placeholder='Last Name'
                    type='text'
                    width='w-full'
                    margin='mb-2'
                    submitInput={setLastName}
                    id='lastName'
                    value={lastName}
                  />
                </div>
                <div
                  className='flex flex-col mt-2'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <Input
                    placeholder='Email'
                    type='email'
                    margin='mb-4'
                    width='w-full'
                    submitInput={setEmail}
                    id='email'
                    value={email}
                  />
                </div>
                <div
                  className='flex flex-col mt-2'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <Select
                    name='Country'
                    margin='mb-4'
                    width='w-full'
                    data={[
                      { value: 'Uganda', label: 'Uganda' },
                      { value: 'Kenya', label: 'Kenya' },
                      { value: 'Rwanda', label: 'Rwanda' },
                      { value: 'Burundi', label: 'Burundi' },
                      { value: 'Tanzania', label: 'Tanzania' },
                      { value: 'Other', label: 'Other' },
                    ]}
                    placeholder='Select Your Country'
                    submitInput={setCountry}
                    id='country'
                    value={country}
                  />
                </div>
                <div
                  className='flex flex-col mt-2'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <Select
                    name='Country'
                    margin='mb-4'
                    width='w-full'
                    data={[
                      { value: 'student', label: 'Student' },
                      { value: 'graduate', label: 'Graduate' },
                      { value: 'employee', label: 'Employed' },
                      { value: 'mentor', label: 'Mentor' },
                      { value: 'trainer', label: 'Trainer' },
                    ]}
                    placeholder='Category'
                    submitInput={setUserCategory}
                    id='userCategory'
                    value={userCategory}
                  />
                </div>
                <div
                  className='flex flex-col mt-2'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <div className='relative mb-4'>
                    <VisibilityHandler
                      visibility={visibility}
                      setVisibility={setVisibility}
                    />
                    <input
                      required
                      className='text-sm focus:outline-noone sm:text-lg text-charcoal relative h-11 border rounded border-linecolor focus:border-main bg-lightgraybg opacity-1 text-gray w-full py-2 pr-2 pl-12'
                      id='password'
                      type={visibility ? 'text' : 'password'}
                      name='password'
                      placeholder='Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className='flex flex-col mt-2'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <div className='relative mb-4'>
                    <VisibilityHandler
                      visibility={secondVisibility}
                      setVisibility={setSecondVisibility}
                    />
                    <input
                      required
                      className='text-sm focus:outline-noone sm:text-lg text-charcoal relative h-11 border rounded border-linecolor focus:border-main bg-lightgraybg opacity-1 text-gray w-full py-2 pr-2 pl-12'
                      id='password'
                      type={secondVisibility ? 'text' : 'password'}
                      name='password-repeat'
                      placeholder='Repeat password'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  className=' w-full flex justify-between  mb-4 text-xs text-headingtext font-display'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  Password must be atleast 8 characters long
                </div>
                {error && (
                  <div className=' w-full flex justify-between  mb-4 text-xs text-red'>
                    {error}
                  </div>
                )}
              </div>

              <div
                className='px-2 sm:px-6'
                style={{ marginLeft: '10%', width: '440px' }}
              >
                <Button
                  content={loading ? <ClipLoader color='white' /> : 'Register'}
                  margin='mb-6'
                  backgroundColor='bg-darkerblue'
                />
                <p
                  className='mt-6 text-xs font-display'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  Already a member{' '}
                  <Link
                    to='/login'
                    className='font-semibold underline text-main'
                  >
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Signup;
