const base = `${process.env.REACT_APP_BACKEND_URL}`;
const endpoints = {
  get_all_chats: `${base}/chat/`,
  create_New_Chat: `${base}/chat/`,
  get_all_chatDiscussions: `${base}/chat/getAllChatDiscusions`,
  create_New_ChatDiscussion: `${base}/chat/submitchat`,
  check_member_in_chat: `${base}/chat/checkChatMember`,
  get_group_chat_requests: `${base}/chat/getGroupChatRequests`,
  submit_chat_request_status: `${base}/chat/submitChatRequestStatus`,
};

export default endpoints;
