import * as usersTypes from '../types/users';
import * as fetchFunctions from '../../api/index';
import usersEndpoints from '../endpoints/users';
import * as usersActions from './users';

export const createUser = (payload) => async (dispatch) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const result = await fetchFunctions.postData(
    usersEndpoints.create_user,
    payload,
  );
  if (result.success) {
    dispatch({
      type: usersTypes.USERS_SUCCESS,
      payload: {
        status: true,
        message: 'Account created',
      },
    });
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error?.message,
      },
    });
  }
};

export const confirmUser = (unconfirmedUserID) => async (dispatch) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const result = await fetchFunctions.getData(
    `${usersEndpoints.confirm_user}/${unconfirmedUserID}`,
  );

  if (result.success) {
    dispatch({
      type: usersTypes.USER_CONFIRMED,
      payload: {
        status: true,
        message: 'User Confirmed',
      },
    });
    setTimeout(function () {
      dispatch({
        type: usersTypes.USERS_RESET,
      });
    }, 1000);
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const rejectedUserPoints = (payload) => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    `${usersEndpoints.rejected_points}`,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: usersTypes.REJECTED_POINTS,
      payload: {
        status: true,
        message: 'User points have been rejected',
      },
    });
    setTimeout(function () {
      dispatch({
        type: usersTypes.USERS_RESET,
      });
    }, 1000);
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const logIn = (payload) => async (dispatch) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const result = await fetchFunctions.postData(usersEndpoints.log_in, payload);
  if (result.success) {
    dispatch({
      type: usersTypes.USER_LOGIN,
      payload: result.response,
    });
    setTimeout(function () {
      dispatch({
        type: usersTypes.USERS_SUCCESS,
        payload: {
          status: true,
          message: 'User logged in',
        },
      });
    }, 100);
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const googleLogIn = (payload) => async (dispatch) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const result = await fetchFunctions.postData(
    usersEndpoints.google_log_in,
    payload,
  );

  if (result.success) {
    dispatch({
      type: usersTypes.USER_LOGIN,
      payload: result.response,
    });
    if (result?.response?.message === 'user successfully created') {
      dispatch({
        type: usersTypes.USERS_SUCCESS,
        payload: {
          status: true,
          message: 'Account created',
        },
      });
    } else {
      dispatch({
        type: usersTypes.USERS_SUCCESS,
        payload: {
          status: true,
          message: 'User logged in',
        },
      });
    }
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.message,
      },
    });
  }
};

export const resetPassword = (payload) => async (dispatch) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const result = await fetchFunctions.postData(
    usersEndpoints.reset_password,
    payload,
  );

  if (result.success) {
    dispatch({
      type: usersTypes.USERS_SUCCESS,
      payload: {
        status: true,
        message: result.response.message,
      },
    });
    dispatch(usersActions.logOut());
    dispatch(
      usersActions.logIn({
        email: payload.email,
        password: payload.newPassword,
      }),
    );
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const logOut = () => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(usersEndpoints.log_out, token);

  dispatch({
    type: usersTypes.USER_LOGOUT,
  });

  if (!result.success) {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error?.message,
      },
    });
  }
};

export const updateUser = (payload) => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.putData(
    usersEndpoints.update_user,
    // `${process.env.REACT_APP_CORS_PROXY}/${usersEndpoints.update_user}`,
    payload,
    token,
  );

  if (result?.success) {
    dispatch({
      type: usersTypes.USERS_SUCCESS,
      payload: {
        status: true,
        message: 'User update complete',
      },
    });
    dispatch(usersActions.userProfile());
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: 'User update failed',
      },
    });
  }
};

export const updateImage = (payload) => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.putData(
    usersEndpoints.update_image,
    payload,
    token,
  );

  if (result?.success) {
    dispatch({
      type: usersTypes.USERS_SUCCESS,
      payload: {
        status: true,
        message: result.response?.message,
      },
    });
    dispatch(usersActions.userProfile());
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result.response?.message,
      },
    });
  }
};

export const addInterests = (payload) => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.putData(
    usersEndpoints.add_interests,
    payload,
    token,
  );

  if (result?.success) {
    dispatch({
      type: usersTypes.USERS_SUCCESS,
      payload: {
        status: true,
        message: 'User interests added',
      },
    });
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const userProfile = () => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    usersEndpoints.user_profile,
    token,
  );

  if (result.success) {
    dispatch({
      type: usersTypes.USER_PROFILE,
      payload: result.data,
    });
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const deleteAccount = () => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.deleteData(
    usersEndpoints.delete_user_account,
    token,
  );

  if (result.success) {
    dispatch({
      type: usersTypes.USERS_SUCCESS,
      payload: {
        status: true,
        message: result.response?.message,
      },
    });
    dispatch({
      type: usersTypes.USER_LOGOUT,
    });
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result.response?.message,
      },
    });
  }
};

export const getUsers = () => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.getData(usersEndpoints.get_users, token);
  if (result.success) {
    dispatch({
      type: usersTypes.GET_USERS,
      payload: result.data,
    });
    dispatch({
      type: usersTypes.USERS_RESET,
    });
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getUserPoints = (payload) => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    `${usersEndpoints.user_points}/${payload.userId}`,
    token,
  );
  if (result.success) {
    dispatch({
      type: usersTypes.USER_POINTS,
      payload: result.data,
    });
    dispatch({
      type: usersTypes.USERS_RESET,
    });
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getUserByID =
  ({ userID }) =>
  async (dispatch, getState) => {
    dispatch({
      type: usersTypes.USERS_LOADING,
    });
    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${usersEndpoints.get_user_by_ID}/${userID}`,
      token,
    );

    if (result.success) {
      dispatch({
        type: usersTypes.GET_A_USER,
        payload: result.data,
      });
      dispatch({
        type: usersTypes.USERS_RESET,
      });
    } else {
      dispatch({
        type: usersTypes.USERS_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getMentors = () => async (dispatch) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const result = await fetchFunctions.getData(`${usersEndpoints.get_mentors}`);
  if (result.success) {
    dispatch({
      type: usersTypes.GET_MENTORS,
      payload: result.data.mentors,
    });
    dispatch({
      type: usersTypes.USERS_RESET,
    });
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const addFollow = (payload) => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });
  const { token } = getState().user;
  const result = await fetchFunctions.postData(
    usersEndpoints.user_follow,
    payload,
    token,
  );
  if (result.success) {
    dispatch({
      type: usersTypes.ADD_FOLLOW,
      payload: {
        status: true,
        message: 'Follow Added',
      },
    });
    dispatch({
      type: usersTypes.USERS_RESET,
    });
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error?.message,
      },
    });
  }
};

export const getFollow = (payload) => async (dispatch, getState) => {
  dispatch({
    type: usersTypes.USERS_LOADING,
  });

  const { token } = getState().user;
  const result = await fetchFunctions.getData(
    `${usersEndpoints.user_follow}/${payload.userID}`,
    token,
  );
  if (result.success) {
    dispatch({
      type: usersTypes.GET_FOLLOW,
      payload: result.data,
    });
    dispatch({
      type: usersTypes.USERS_RESET,
    });
  } else {
    dispatch({
      type: usersTypes.USERS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
