import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Nav from '../components/NavBarR';

// Actions
import * as contentActions from '../redux/actions/content';

import ContentTable from '../components/MyContentTable';

const InstitutionBooks = () => {
  const dispatch = useDispatch();
  const myContent = useSelector((state) => state.content.myContent);

  useEffect(() => {
    dispatch(contentActions.getMyContent());
  }, []);

  const myBooks = [];
  myContent?.map((value) =>
    value?.category === 'Book' ? myBooks.push(value) : null,
  );

  return (
    <>
      <>
        <Nav booksActive={true} userType='institution' />
        <div className='bg-lightGrayBg2 w-full h-screen'>
          <ContentTable
            myContent={myBooks}
            contentType={'Book'}
            userType={'Institution'}
          />
        </div>
      </>
    </>
  );
};

export default InstitutionBooks;
