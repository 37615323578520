const base = `${process.env.REACT_APP_BACKEND_URL}`;
const endpoints = {
  upload_content: `${base}/contents/`,
  get_all_content: `${base}/contents/`,
  get_videos: `${base}/contents/videos`,
  get_courses: `${base}/contents/courses`,
  get_courseunits: `${base}/contents/courseunits`,
  get_articles: `${base}/contents/articles`,
  specific_content: `${base}/contents/content`,
  related_content: `${base}/contents/relatedContent`,
  get_my_content: `${base}/contents/mentor`,
  get_my_videos: `${base}/contents/mentor/videos`,
  get_my_courses: `${base}/contents/mentor/courses`,
  get_my_articles: `${base}/contents/mentor/articles`,
  latest_content: `${base}/contents/latest`,
  popular_content: `${base}/contents/popular`,
  trending_content: `${base}/contents/trending`,
  update_content: `${base}/contents/update`,
  update_content_details: `${base}/contents/update/details`,
  delete_content: `${base}/contents/delete`,
};

export default endpoints;
