import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Twitter from '../assets/twitter.svg';

import {
  KeyboardBackspace,
  StarRate,
  Room,
  ExpandMore,
} from '@material-ui/icons';
import * as sessionsActions from '../redux/actions/sessions';
import * as userActions from '../redux/actions/users';
import * as commentActions from '../redux/actions/comments';
import * as contentActions from '../redux/actions/content';

import * as institutionActions from '../redux/actions/institution';

import CalendlyEmbed from '../components/CalendlyEmbed';

import Nav from '../components/NavBarR';
import LoadingState from '../components/Loading';
import CustomAlert from '../components/CustomAlert';
import { ReviewModal } from '../components/ContactForms';
import UpcomingSessionsModal from '../components/UpcomingSessionsModal';
import NoContentPlaceholder from '../components/NoContentPlaceholder';
import ReviewItem from '../components/ReviewItem';
import { CarouselController } from '../components/CarouselController';
import LibraryContentCard from '../components/LibraryContentCard';

const InstitutionMentorsFindoutMore = () => {
  const { mentorID } = useParams();
  const dispatch = useDispatch();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showUpcomingSessionsModal, setShowUpcomingSessionsModal] =
    useState(false);

  const { success, error, errorMessage } = useSelector(
    (state) => state.comments,
  );

  const { data, loading } = useSelector((state) => state?.user);
  const users = data;
  const sessions = useSelector((state) => state?.sessions?.data);
  const userSessions = sessions.filter((item) => item.userID === mentorID);
  const content = useSelector((state) => state?.content?.data);
  const comments = useSelector((state) => state.comments.data);

  useEffect(() => {
    dispatch(sessionsActions.getAllSessions());
    dispatch(commentActions.getAllComments());
    dispatch(institutionActions.getInstitutions({}));
    dispatch(institutionActions.getYourMentor());
    dispatch(userActions?.getUsers());
  }, []);

  const filteredMentor = users.filter((mentor) => mentor._id === mentorID);
  const mentor = filteredMentor[0];

  const mentorComments = comments?.filter(
    (item) => item.idOfCommentedAbout === mentorID,
  );

  const institutions = useSelector((state) => state.institution);
  const institution = institutions?.data;
  useEffect(() => {
    if (success.message === 'Comment added successfully') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Review added successfully',
      });
    } else if (error && errorMessage === 'Failed to submit comment') {
      CustomAlert({
        alertType: 'error',
        alertMessage: 'Failed to submit review',
      });
    }
  }, [success, error]);

  let institutionMentor = true;
  return (
    <>
      <Nav
        mentorsActive={true}
        userType='institution'
        institutions={institution}
      />
      {loading && !mentor ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <LoadingState />
        </div>
      ) : (
        <>
          <div className='w-full bg-lightgraybg'>
            <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full  lg:px-0 '>
                <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                  <div className='flex flex-col  container mx-auto'>
                    <div className='flex justify-start w-full   py-4 pb-5 lg:w-full mb-1 text-black font-sans'>
                      <Link to='/institution/mentors'>
                        <KeyboardBackspace fontSize='medium' />{' '}
                        <span className='ml-2'>Back</span>
                      </Link>
                    </div>

                    <div className='  lg:w-full block mt-1 text-headingtext font-sans z-20'>
                      <div className='p-8 flex  flex-col lg:flex-row justify-center lg:justify-between  bg-white rounded shadow-sm  md:text-left sm:text-md'>
                        <div className=' mx-auto lg:mx-0 flex flex-col lg:flex-row justify-start'>
                          <div className='mx-auto lg:mx-0 h-20 w-20  sm:h-28 sm:w-28  md:h-36 md:w-36 lg:h-48 lg:w-48  mb-4 lg:mb-0  '>
                            <img
                              src={
                                mentor?.profilePicture?.publicUrl ||
                                `https://ui-avatars.com/api/?name=${mentor?.firstname}+${mentor?.lastname}&bold=true`
                              }
                              alt={mentor?.firstname}
                              className='rounded-full object-cover h-full w-full shadow-md'
                            />
                          </div>
                          <div className='lg:mx-8 px-4 text-center lg:text-left'>
                            <h5 className='mb-3 text-2xl lg:text-5xl font-bold leading-none text-charcoal capitalize'>
                              {mentor?.firstname || 'No'} {` `}
                              {mentor?.lastname || 'Name'}
                            </h5>
                            <p className='mb-5 text-textsecondary opacity-70 text-2xl capitalize'>
                              {mentor?.usercategory[0] || 'Mentor'}
                            </p>
                            <p className='mb-3 text-charcoalfont-semibold text-xs text-charcoal font-bold'>
                              Specialities
                            </p>
                            <div className='mb-6 grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0 text-xs mx-auto lg:mx-0'>
                              <ul
                                className='grid grid-flow-row grid-cols-2   gap-x-6 lg:gap-x-2  gap-y-px text-headingtext  text-left lg:text-justify'
                                style={{ fontSize: '12px' }}
                              >
                                {mentor?.interests.length > 0
                                  ? mentor?.interests?.map((value) => (
                                      <li className='lg:w-11/12' key={value}>
                                        <span className='lg:mr-1'>*</span>
                                        {value}
                                      </li>
                                    ))
                                  : 'No interests Defined'}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className=' px-4  sm:px-2 '>
                          {institutionMentor ? (
                            <div className='flex flex-col justify-around w-full lg:w-60  h-full '>
                              <a
                                href={`mailto: ${mentor?.email}?subject=REQUEST TO PRESENT SESSION&body=Dear ${mentor?.firstname} ${mentor?.lastname}`}
                                aria-label='Send Email'
                                title='Send Email'
                                target='_blank'
                                rel='noreferrer'
                              >
                                <button
                                  type='submit'
                                  className='inline-flex  font-bold items-center justify-center w-full h-12 mb-4 px-8 py-4 mr-6  border-2 border-darkerblue tracking-wide text-darkerblue text-base sm:text-sm  transition duration-200 rounded-lg shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                                >
                                  Contact {mentor?.firstname}
                                </button>
                              </a>

                              <Link to={'/comingsoon'}>
                                <button
                                  type='submit'
                                  className='inline-flex font-bold items-center justify-center w-full h-12 mb-4 px-8 py-4 mr-6  border-2 border-darkerblue tracking-wide text-darkerblue text-base sm:text-sm  transition duration-200 rounded-lg shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                                >
                                  Upcoming Sessions
                                </button>
                              </Link>
                              <Link to={'#'}>
                                <button
                                  type='submit'
                                  className='inline-flex font-bold items-center justify-center w-full h-12 mb-4 px-8 py-4 mr-6  border-2 border-darkerblue tracking-wide text-darkerblue text-base sm:text-sm  transition duration-200 rounded-lg shadow-md bg-white hover:bg-buttonRed hover:border-buttonRed hover:text-white focus:shadow-outline focus:outline-none'
                                >
                                  Remove affiliation
                                </button>
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className='  pb-5 lg:w-full block  text-headingtext font-sans z-10'>
                      <div className=' flex flex-col lg:flex-row justify-between  bg-white  md:text-left sm:text-md'>
                        <div className=' flex justify-start md:w-8/12 '>
                          <div className='flex flex-col justify-start p-6 w-full'>
                            <TabLayout
                              userData={users}
                              mentor={mentor}
                              mentorComments={mentorComments?.reverse()}
                              content={content}
                              setShowReviewModal={setShowReviewModal}
                              showReviewModal={showReviewModal}
                            />
                          </div>
                        </div>

                        <div className='md:w-4/12 justify-around border-l-2 border-lightGrayBg2'>
                          <div className='flex flex-col justify-start p-6 '>
                            <div className='bg-lightGrayBg2 flex flex-col justify-around p-8 rounded-lg mb-9'>
                              <div className='flex flex-row mb-6'>
                                <div className='bg-buttonGreen h-14 w-14 rounded-lg mr-2'></div>
                                <div className='flex flex-col justify-around'>
                                  <span className='text-xl text-charcoal font-bold'>
                                    Very Relaible
                                  </span>
                                  <span className='text-sm text-headingtext font-normal'>
                                    100% attendance rate
                                  </span>
                                </div>
                              </div>
                              <div className='flex flex-row justify-between mb-3'>
                                <span className='text-sm font-normal text-headingtext'>
                                  Completed courses
                                </span>
                                <span className='text-sm font-semibold text-main '>
                                  {'08'}
                                </span>
                              </div>
                              <div className='flex flex-row justify-between mb-3'>
                                <span className='text-sm font-normal text-headingtext'>
                                  Mentoring Since
                                </span>
                                <span className='text-sm font-semibold text-main '>
                                  {/* {moment(mentor?.).format('mmm yyyy')} */}
                                  {'Mar 2021'}
                                </span>
                              </div>
                              <div className='flex flex-row justify-between mb-1'>
                                <span className='text-sm font-normal text-headingtext'>
                                  On Delv since
                                </span>
                                <span className='text-sm font-semibold text-main '>
                                  {'Mar 2021'}
                                </span>
                              </div>
                            </div>
                            <div className='bg-white shadow-md flex flex-row justify-between p-2 rounded-lg mb-9'>
                              <div className='flex flex-row  p-4 rounded-lg w-1/2'>
                                <div className='flex flex-col justify-around items-center'>
                                  <span className='text-2xl text-charcoal font-bold'>
                                    {mentorComments?.length}
                                  </span>
                                  <span className='text-sm text-headingtext font-normal'>
                                    Reviews
                                  </span>
                                </div>
                              </div>
                              <div className='flex flex-row bg-lightGrayBg2 p-4 rounded-lg w-1/2'>
                                <div className='bg-starsorange h-14 w-14 rounded-lg mr-2 flex justify-center pt-1'>
                                  <StarRate
                                    style={{ color: 'white', fontSize: '50px' }}
                                  />
                                </div>
                                <div className='flex flex-col justify-around'>
                                  <span className='text-2xl text-charcoal font-bold'>
                                    {mentorComments?.length > 0 ? '5.0' : '0.0'}
                                  </span>
                                  <span className='text-sm text-headingtext font-normal'>
                                    {`From ${mentorComments?.length}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReviewModal
            content={mentor}
            userType={'mentor'}
            showReviewModal={showReviewModal}
            setShowReviewModal={setShowReviewModal}
          />
          <UpcomingSessionsModal
            mentor={mentor}
            data={userSessions}
            showUpcomingSessionsModal={showUpcomingSessionsModal}
            setShowUpcomingSessionsModal={setShowUpcomingSessionsModal}
          />
        </>
      )}
    </>
  );
};

const NavigationHeader = ({ activeStatus, setActiveStatus }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let tabLabel;
  if (activeStatus === 1) tabLabel = 'About';
  if (activeStatus === 2) tabLabel = 'Content';
  if (activeStatus === 3) tabLabel = 'Review';
  if (activeStatus === 4) tabLabel = 'Schedule';

  const [selectedTab, setSelectedTab] = useState(tabLabel);
  return (
    <div className='px-2 flex items-center justify-center lg:justify-start lg:items-start w-full'>
      <ul className='w-full hidden md:flex items-center  border-b border-linecolor'>
        <li
          onClick={() => {
            setActiveStatus(1);
          }}
          className={
            activeStatus === 1
              ? 'py-2 px-2 cursor-pointer  border-b-4 border-main text-charcoal  text-base   leading-none text-center '
              : 'py-2 px-2 cursor-pointer  bg-transparent hover:bg-lightGrayBg   text-base   leading-none text-gray5'
          }
        >
          About
        </li>
        <li
          onClick={() => setActiveStatus(2)}
          className={
            activeStatus === 2
              ? 'py-2 px-2 cursor-pointer  border-b-4 border-main text-charcoal   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg   text-base   leading-none text-gray5'
          }
        >
          Content
        </li>
        <li
          onClick={() => setActiveStatus(3)}
          className={
            activeStatus === 3
              ? 'py-2 px-2 cursor-pointer  border-b-4 border-main text-charcoal   ml-12  text-base   leading-none text-center '
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg   text-base   leading-none text-gray5'
          }
        >
          Review
        </li>
        <li
          onClick={() => setActiveStatus(4)}
          className={
            activeStatus === 4
              ? 'py-2 px-2 cursor-pointer  border-b-4 border-main text-charcoal   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg   text-base   leading-none text-gray5'
          }
        >
          Schedule
        </li>
      </ul>
      <div className='md:hidden relative w-11/12 mx-auto bg-white rounded'>
        <div className='lg:hidden w-full flex justify-center'>
          <div className='flex flex-row lg:hidden py-1 w-full'>
            <button
              aria-label='Open Menu'
              title='Open Menu'
              className='px-2 py-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semiboldtext-base   leading-none text-center  flex flex-row w-full justify-between  transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50'
              onClick={() => setIsMenuOpen(true)}
            >
              <span className=''>{selectedTab}</span>
              <div>
                <ExpandMore fontSize='inherit' />
              </div>
            </button>
          </div>

          {isMenuOpen && (
            <div className='absolute top-0 left-0 w-full z-50'>
              <div className='p-5 bg-white border rounded shadow-sm'>
                <nav>
                  <ul className='space-y-4'>
                    <li
                      onClick={() => {
                        setActiveStatus(1);
                        setIsMenuOpen(false);
                        setSelectedTab('About');
                      }}
                      className={
                        activeStatus === 1
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold  text-base   leading-none text-center '
                          : 'py-2 px-2 cursor-pointer  bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      About
                    </li>
                    <li
                      onClick={() => {
                        setActiveStatus(2);
                        setIsMenuOpen(false);
                        setSelectedTab('Content');
                      }}
                      className={
                        activeStatus === 2
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center'
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Content
                    </li>

                    <li
                      onClick={() => {
                        setActiveStatus(3);
                        setIsMenuOpen(false);
                        setSelectedTab('Review');
                      }}
                      className={
                        activeStatus === 3
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center '
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Review
                    </li>

                    <li
                      onClick={() => {
                        setActiveStatus(4);
                        setIsMenuOpen(false);
                        setSelectedTab('Schedule');
                      }}
                      className={
                        activeStatus === 4
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center'
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Schedule
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TabLayout = (props) => {
  const [activeStatus, setActiveStatus] = useState(1);
  const { mentorID } = useParams();
  const dispatch = useDispatch();

  const myContent = props.content
    .filter((mentorContent) => mentorContent.userID === mentorID)
    .filter((content) => content.category === 'Video');

  useEffect(() => {
    dispatch(contentActions.getAllContent());
  }, []);

  return (
    <>
      <div className='text-center lg:text-left'>
        <NavigationHeader
          activeStatus={activeStatus}
          setActiveStatus={setActiveStatus}
        />
        {activeStatus === 1 && <About mentor={props.mentor} />}
        {activeStatus === 2 && <Content content={myContent} />}
        {activeStatus === 3 && (
          <Reviews
            userData={props.userData}
            setShowReviewModal={props.setShowReviewModal}
            mentorComments={props.mentorComments}
          />
        )}
        {activeStatus === 4 && <Schedule1 mentorId={mentorID} />}
      </div>
    </>
  );
};

const About = ({ mentor }) => {
  console.log(`mentor`, mentor);
  return (
    <div className=' flex flex-col justify-around py-2 lg:p-8 rounded-lg mb-9'>
      <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
        <span className='font-base text-gray5 lg:w-3/12'>Location</span>
        <div className=' lg:w-9/12'>
          <div className='py-1 px-3 lg:py-2 lg:px-6 font-base text-lightBlue bg-linecolor rounded-lg w-max'>
            <Room fontSize='small' />{' '}
            <span className='ml-2'> {mentor?.country}</span>
          </div>
        </div>
      </div>
      <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
        <span className='font-base text-gray5 lg:w-3/12'>Experience</span>
        <div className=' lg:w-9/12'>
          <span className='font-base text-charcoal '>
            {/* {mentor.dateOfBirth} */}
            {mentor?.experience || 'Not set'}
          </span>
        </div>
      </div>
      <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
        <span className='font-base text-gray5 lg:w-3/12'>Education</span>
        <div className=' lg:w-9/12'>
          <span className='font-base text-charcoal  lg:w-9/12'>
            {mentor?.education || 'Not set'}
          </span>
        </div>
      </div>
      <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
        <span className='font-base text-gray5 lg:w-3/12'>About</span>
        <div className='ml-4 lg:ml-0 lg:w-9/12'>
          <span className='font-base text-charcoal lg:w-9/12 text-right'>
            {mentor?.about || 'Not set'}
          </span>
        </div>
      </div>
      <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
        <span className='font-base text-gray5 lg:w-3/12'>Skills</span>
        <div className=' lg:w-9/12'>
          <span className='font-base text-charcoal '>
            {mentor?.interests.length > 0 ? (
              <ul className='space-y-1'>
                {mentor?.interests?.map((value) => (
                  <li className='flex' key={value}>
                    <span className='mr-1'>*</span>
                    {value}
                  </li>
                ))}
              </ul>
            ) : (
              'Not set'
            )}
          </span>
        </div>
      </div>
      <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
        <span className='font-base text-gray5 lg:w-3/12'>Social</span>
        <div className=' lg:w-9/12'>
          <div className='flex justify-start'>
            <a href='/'>
              <img src={Twitter} alt='twitter-link' className='pr-6' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Content = ({ content }) => {
  const myVideos = [];
  content?.map((value) =>
    value?.category === 'Video' ? myVideos.push(value) : null,
  );

  const myArticles = [];
  content?.map((value) =>
    value?.category === 'Article' ? myArticles.push(value) : null,
  );
  return (
    <div className='w-full md:mx-auto   pt-4'>
      <div className='flex flex-col mb-6 lg:justify-between md:mb- shadow '>
        <div>
          <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:pt-14'>
            <div className='flex flex-col  md:justify-between md:flex-row '>
              <h2 className='max-w-lg mb-5 font-sans text-2xl font-bold tracking-tight text-gray  sm:leading-none group'>
                <span className='inline-block mb-1 sm:mb-4'>
                  Videos ({myVideos?.length || 0})
                </span>
              </h2>
            </div>
            {myVideos.length > 0 ? (
              <CarouselController
                type='post'
                content={myVideos.map((value, key) => (
                  <LibraryContentCard content={value} type='Video' key={key} />
                ))}
              />
            ) : (
              <NoContentPlaceholder content='Videos' generic />
            )}
          </div>
          <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:pt-14'>
            <div className='flex flex-col  md:justify-between md:flex-row '>
              <h2 className='max-w-lg mb-5 font-sans text-2xl font-bold tracking-tight text-gray  sm:leading-none group'>
                <span className='inline-block mb-1 sm:mb-4'>
                  Articles ({myArticles?.length || 0})
                </span>
              </h2>
            </div>
            {myArticles.length > 0 ? (
              <CarouselController
                type='post'
                content={myArticles.map((value, key) => (
                  <LibraryContentCard
                    content={value}
                    type='Article'
                    key={key}
                  />
                ))}
              />
            ) : (
              <NoContentPlaceholder content='Articles' generic />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const Reviews = ({ setShowReviewModal, isMentor, mentorComments }) => {
  const [slice, setSlice] = useState(5);
  const institutions = useSelector((state) => state?.institution.data);

  const handleMoreReview = () => {
    setSlice(slice + 4);
  };
  return (
    <>
      {' '}
      <div className='flex flex-col mt-4'>
        <div className='w-full flex lg:flex-row flex-col justify-between pt-6 pb-2'>
          <span className='text-lg text-gray font-semibold px-2  mb-4 '>
            {' '}
            Recent reviews from users on Delv.
          </span>
          <span
            className='cursor-pointer inline-flex items-center justify-center h-10 px-6 font-medium tracking-wide text-darkerblue  hover:text-white transition duration-200 rounded-lg border-2 border-darkerblue shadow-md bg-none hover:bg-darkerblue focus:shadow-outline focus:outline-noone'
            aria-label='Download Video'
            title='Download'
            aria-controls='Download'
            aria-haspopup='true'
            onClick={() => {
              setShowReviewModal(true);
            }}
          >
            {` Review ${isMentor ? 'Mentor' : 'Delv User'}`}
          </span>
        </div>
        <div className='flex lg:justify-start pb-4'>
          <select className='appearance-none ainline-flex justify-center w-max  px-2 lg:px-6 py-3 font-normal text-headingtext text-sm focus:outline-noone border border-lightGrayBg2  focus:border-linecolor lg:text-base transition duration-200 rounded-lg bg-lightGrayBg2 hover:bg-white hover:text-graybg2 focus:shadow-outline focus:outline-none'>
            <option>Sort By: Time</option>
            <option>Rating Ascending</option>
            <option>Rating Descending</option>
          </select>
        </div>
        {mentorComments.length > 0 ? (
          <div>
            {mentorComments?.slice(0, slice)?.map((user, key) => (
              <ReviewItem
                key={user._id}
                data={user}
                institutions={institutions}
              />
            ))}
          </div>
        ) : (
          <NoContentPlaceholder content='Reviews' generic />
        )}
        <div className='flex justify-center py-3'>
          <span
            className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
            onClick={() => handleMoreReview()}
            disabled={slice >= mentorComments?.length}
          >
            {slice >= mentorComments?.length
              ? mentorComments.length === 0
                ? ' '
                : 'No more reviews'
              : 'Show more reviews'}
          </span>
        </div>
      </div>
    </>
  );
};

const Schedule1 = (props) => {
  return (
    <div className='max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 mt-4 bg-loading bg-no-repeat bg-top'>
      <CalendlyEmbed
        link={'https://calendly.com/walihiwalil'}
        title={props.mentorId}
      />
    </div>
  );
};
export default InstitutionMentorsFindoutMore;
