import { combineReducers } from 'redux';
// Reducer imports
import homeReducer from './home';
import postReducer from './post';
import rateReducer from './rate';
import usersReducer from './users';
import searchReducer from './search';
import sharedReducer from './shared';
import contentReducer from './content';
import mentorsReducer from './mentors';
import sessionsReducer from './sessions';
import commentsReducer from './comments';
import disciplinesReducer from './disciplines';
import institutionReducer from './institution';
import discussionGroupsReducer from './discussiongroups';
import affiliationsReducer from './affiliations';
import chatReducer from './chat';
import joinRequestReducer from './joinrequests';
import invitationsReducer from './invitations';
import likesReducer from './likes';
import claimReducer from './claim';

// Combining and exporting the reducers
export default combineReducers({
  home: homeReducer,
  post: postReducer,
  rate: rateReducer,
  user: usersReducer,
  search: searchReducer,
  shared: sharedReducer,
  content: contentReducer,
  mentors: mentorsReducer,
  sessions: sessionsReducer,
  comments: commentsReducer,
  disciplines: disciplinesReducer,
  institution: institutionReducer,
  discussiongroups: discussionGroupsReducer,
  affiliations: affiliationsReducer,
  chat: chatReducer,
  joinRequest: joinRequestReducer,
  invitation: invitationsReducer,
  like: likesReducer,
  claim: claimReducer,
});
