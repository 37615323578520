import React, { useState } from 'react';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';

import ContentWrapper from './ContentWrapper';
import Feeds from '../views/InstitutionFeeds';
import Home from '../views/InstitutionHome';
import About from '../views/InstitutionAbout';
import LibraryViewPage from '../views/AllArticles';
import YourMentors from '../views/YourMentors';
import logo from '../assets/DelvLogo.svg';

const InstitutionTab = ({
  homeActive,
  feedsActive,
  aboutActive,
  mentorsActive,
  libraryActive,
  eventsActive,
}) => {
  const { path } = useRouteMatch();

  const [show, setShow] = useState(null);
  const [homeSelected, setHomeSelected] = useState(homeActive || false);
  const [feedsSelected, setFeedsSelected] = useState(feedsActive || false);
  const [aboutSelected, setAboutSelected] = useState(aboutActive || false);
  const [mentorsSelected, setMentorsSelected] = useState(
    mentorsActive || false,
  );
  const [librarySelected, setLibrarySelected] = useState(
    libraryActive || false,
  );
  const [eventsSelected, setEventsSelected] = useState(eventsActive || false);

  const selectedStyle = '  border-b-4 border-main  outline-none text-charcoal';

  return (
    <>
      <div className='bg-gray-200 h-full w-full sticky top-20 z-30'>
        {/* Code block starts */}
        <nav className='bg-white shadow xl:block hidden'>
          <div className='mx-auto container  py-2 xl:py-0'>
            <div className='flex items-center justify-between'>
              <div className='flex flex-row justify-between w-full'>
                <div className='hidden xl:flex md:mr-2 xl:mr-16 px-9 '>
                  <Link
                    to={`/institutionhome`}
                    onClick={() => {
                      setHomeSelected(true);
                      setLibrarySelected(false);
                      setFeedsSelected(false);
                      setMentorsSelected(false);
                      setEventsSelected(false);
                      setAboutSelected(false);
                    }}
                    className={
                      ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out' +
                      (homeSelected ? selectedStyle : ' text-gray5')
                    }
                  >
                    Home
                  </Link>

                  <Link
                    to='/institutionabout'
                    onClick={() => {
                      setHomeSelected(false);
                      setLibrarySelected(false);
                      setFeedsSelected(false);
                      setMentorsSelected(false);
                      setEventsSelected(false);
                      setAboutSelected(true);
                    }}
                    className={
                      ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out' +
                      (aboutSelected ? selectedStyle : ' text-gray5')
                    }
                  >
                    About
                  </Link>
                  <Link
                    to='/institution/feeds'
                    onClick={() => {
                      setHomeSelected(false);
                      setLibrarySelected(false);
                      setFeedsSelected(true);
                      setMentorsSelected(false);
                      setEventsSelected(false);
                      setAboutSelected(false);
                    }}
                    className={
                      ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out' +
                      (feedsSelected ? selectedStyle : ' text-gray5')
                    }
                  >
                    Feeds
                  </Link>

                  <Link
                    to='/yourmentors'
                    onClick={() => {
                      setHomeSelected(false);
                      setLibrarySelected(false);
                      setFeedsSelected(false);
                      setMentorsSelected(true);
                      setEventsSelected(false);
                      setAboutSelected(false);
                    }}
                    className={
                      ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out' +
                      (mentorsSelected ? selectedStyle : ' text-gray5')
                    }
                  >
                    Alumni/Mentors
                  </Link>
                  <Link
                    to='/library'
                    onClick={() => {
                      setHomeSelected(false);
                      setLibrarySelected(true);
                      setFeedsSelected(false);
                      setMentorsSelected(false);
                      setEventsSelected(false);
                      setAboutSelected(false);
                    }}
                    className={
                      ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out' +
                      (librarySelected ? selectedStyle : ' text-gray5')
                    }
                  >
                    Library
                  </Link>
                  <Link
                    to='/events'
                    onClick={() => {
                      setHomeSelected(false);
                      setLibrarySelected(false);
                      setFeedsSelected(false);
                      setMentorsSelected(false);
                      setEventsSelected(true);
                      setAboutSelected(false);
                    }}
                    className={
                      ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base duration-150 ease-in-out' +
                      (eventsSelected ? selectedStyle : ' text-gray5')
                    }
                  >
                    Events
                  </Link>
                </div>
                <div className='hidden xl:flex items-center'>
                  <div className='relative md:mr-6 my-2'>
                    <button className='focus:outline-none bg-none font-semibold text-base transition duration-150 ease-in-out hover:text-navyblue rounded text-main px-5 py-2 '>
                      Feedback
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='border-t-2 border-linecolor'></div>
        </nav>

        <nav>
          <div className='py-4 px-6 w-full flex xl:hidden justify-between items-center bg-white fixed top-0 z-40'>
            <div className='w-24'>
              <img
                src={logo}
                alt='delv-logo'
                className='w-16 h-16 object-fit '
              />
            </div>
            <div className='flex items-center'>
              <div className='relative mr-6 '>
                <button className='focus:outline-none bg-lightGrayBg2 border-gray-300 border transition duration-150 ease-in-out hover:bg-gray-300 rounded text-gray-600 px-5 py-2 text-xs'>
                  Feedback
                </button>
              </div>
              <div
                id='menu'
                className='text-gray-800'
                onClick={() => setShow(!show)}
              >
                {show ? (
                  ''
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='icon icon-tabler icon-tabler-menu-2'
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    fill='none'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  >
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <line x1={4} y1={6} x2={20} y2={6} />
                    <line x1={4} y1={12} x2={20} y2={12} />
                    <line x1={4} y1={18} x2={20} y2={18} />
                  </svg>
                )}
              </div>
            </div>
          </div>
          {/*Mobile responsive sidebar*/}
          <div
            className={
              show
                ? 'w-full xl:hidden h-full absolute z-40  transform  translate-x-0 '
                : '   w-full xl:hidden h-full absolute z-40  transform -translate-x-full'
            }
          >
            <div
              className='bg-gray-800 opacity-50 w-full h-full'
              onClick={() => setShow(!show)}
            />
            <div className='w-64 z-40 fixed overflow-y-auto top-0 bg-white shadow h-full flex-col justify-between xl:hidden pb-4 transition duration-150 ease-in-out'>
              <div className='px-6 h-full'>
                <div className='flex flex-col justify-between h-full w-full'>
                  <div>
                    <div className='mt-6 flex w-full items-center justify-between'>
                      <div className='flex items-center justify-between w-full'>
                        <div
                          id='cross'
                          className='text-gray-800'
                          onClick={() => setShow(!show)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='icon icon-tabler icon-tabler-x'
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='currentColor'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' />
                            <line x1={18} y1={6} x2={6} y2={18} />
                            <line x1={6} y1={6} x2={18} y2={18} />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <ul className='f-m-m'>
                      <Link to='/institutionhome '>
                        <li className='text-gray-800 pt-8'>
                          <div className='flex items-center justify-between'>
                            <div className='flex items-center'>
                              <p className='text-gray-800 xl:text-base md:text-2xl text-base ml-3'>
                                Home
                              </p>
                            </div>
                          </div>
                        </li>
                      </Link>
                      <Link to='/institution/feeds '>
                        <li className='text-gray-800 pt-8'>
                          <div className='flex items-center justify-between'>
                            <div className='flex items-center'>
                              <p className='text-gray-800 xl:text-base md:text-2xl text-base ml-3'>
                                Feeds
                              </p>
                            </div>
                          </div>
                        </li>
                      </Link>
                      <Link to='/institutionabout '>
                        <li className='text-gray-800 pt-8'>
                          <div className='flex items-center'>
                            <p className='text-gray-800 xl:text-base md:text-2xl text-base ml-3'>
                              About
                            </p>
                          </div>
                        </li>
                      </Link>
                      <Link to='/library '>
                        <li className='text-gray-800 pt-8'>
                          <div className='flex items-center justify-between'>
                            <div className='flex items-center'>
                              <p className='text-gray-800 xl:text-base md:text-2xl text-base ml-3'>
                                Library
                              </p>
                            </div>
                          </div>
                        </li>
                      </Link>
                      <Link to='/yourmentors '>
                        <li className='text-gray-800 pt-8'>
                          <div className='flex items-center justify-between'>
                            <div className='flex items-center'>
                              <p className='text-gray-800 xl:text-base md:text-2xl text-base ml-3'>
                                Alumni/Mentors
                              </p>
                            </div>
                          </div>
                        </li>
                      </Link>
                      <Link to='/events '>
                        <li className='text-gray-800 pt-8'>
                          <div className='flex items-center justify-between'>
                            <div className='flex items-center'>
                              <p
                                // className='hover:text-main text-body font-semibold text-lg text-textparagraph active:text-main focus:text-main cursor-pointer hover:outline-main pr-11'
                                className='text-gray-800 xl:text-base md:text-2xl text-base ml-3'
                              >
                                Events
                              </p>
                            </div>
                          </div>
                        </li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <Switch>
          <Route path={`${path}/home`}>
            <ContentWrapper tab={<Home />} />
          </Route>
          <Route path={`${path}/feeds`}>
            <ContentWrapper tab={<Feeds />} />
          </Route>
          <Route path={`${path}/library`}>
            <ContentWrapper tab={<LibraryViewPage />} />
          </Route>
          <Route path={`${path}/institutionabout`}>
            <ContentWrapper tab={<About />} />
          </Route>
          <Route path={`${path}/yourmentors`}>
            <ContentWrapper tab={<YourMentors />} />
          </Route>
          <Route path={`${path}/events`}>
            <ContentWrapper tab={<Feeds />} />
          </Route>
        </Switch>
        {/* Code block ends */}
      </div>
    </>
  );
};

export default InstitutionTab;
