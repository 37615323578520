import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Nav from '../components/NavBarR';
import ContentTable from '../components/MyContentTable';

// Actions
import * as contentActions from '../redux/actions/content';

const InstitutionArticles = () => {
  const dispatch = useDispatch();
  const myContent = useSelector((state) => state.content.myContent);
  const error = useSelector((state) => state.content.error);

  useEffect(() => {
    // if (error) console.log(errorMessage);
  }, [error]);

  useEffect(() => {
    dispatch(contentActions.getMyContent());
  }, []);

  const myArticles = [];
  myContent?.map((value) =>
    value?.category === 'Article' ? myArticles.push(value) : null,
  );

  return (
    <>
      <>
        <Nav articlesActive={true} userType='institution' />
        <div className='bg-lightGrayBg2 w-full h-screen'>
          <ContentTable
            userType='institution'
            myContent={myArticles}
            contentType={'Article'}
          />
        </div>
      </>
    </>
  );
};

export default InstitutionArticles;
