import * as fetchFunctions from '../../api/index';
import * as joinRequestTypes from '../types/joinrequests';
import joinRequestEndpoints from '../endpoints/joinrequests';

export const getAllJoinRequests =
  ({ adminID }) =>
  async (dispatch, getState) => {
    dispatch({
      type: joinRequestTypes.JOINREQUEST_LOADING,
    });
    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${joinRequestEndpoints.get_all_joinrequests}/${adminID}`,
      token,
    );

    if (result.success) {
      dispatch({
        type: joinRequestTypes.GET_ALL_JOIN_REQUESTS,
        payload: result.data,
      });
      dispatch({
        type: joinRequestTypes.JOINREQUEST_RESET,
      });
    } else {
      dispatch({
        type: joinRequestTypes.JOINREQUEST_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const updateRequestStatus = (payload) => async (dispatch, getState) => {
  dispatch({
    type: joinRequestTypes.JOINREQUEST_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    joinRequestEndpoints.update_request_status,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: joinRequestTypes.UPDATE_REQUEST_STATUS,
      payload: {
        status: true,
        message: 'Upload Complete',
      },
    });
    dispatch({
      type: joinRequestTypes.JOINREQUEST_RESET,
    });
  } else {
    dispatch({
      type: joinRequestTypes.JOINREQUEST_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
