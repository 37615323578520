import React from 'react';

// assets
import section2Img from '../assets/upskilling.jpeg';
import section1Img1 from '../assets/landingX.jpeg';
import section1Img2 from '../assets/upskillingFull.jpeg';
import section1Img3 from '../assets/landing3.jpeg';

// Components
import Footer from '../components/Footer';
import NavHeaderLanding from '../components/NavBarLanding';

export default function AboutUs() {
  return (
    <div>
      <div className='flex flex-col bg-darkerblue  '>
        <NavHeaderLanding />
        <div
          className='bg-darkerblue
         w-full h-full '
        >
          <Section1 />
        </div>
        <div className='bg-lightgraybg w-full h-full '>
          <Section2 />
        </div>

        <div className='bg-black2'>
          <Footer />
        </div>
      </div>
    </div>
  );
}

const Section2 = () => {
  return (
    <div className='px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
      <div className='flex flex-col items-center justify-between lg:flex-row'>
        <div>
          <img
            className='object-cover w-full h-56 rounded shadow-lg sm:h-96'
            src={section2Img}
            alt='pic'
          />
        </div>
        <div className=' lg:max-w-lg lg:pr-5 lg:mb-0 p-4'>
          <div className='max-w-xl mb-6 text-charcoal'>
            <p className='pr-5 mb-5 text-base  md:text-lg'>
              With the digital era comes fast paced change and decreasing shelf
              life of skills hence the need for lifelong learning and
              development to remain relevant on the job market. With this new
              reality, everyone, whatever their age, will at some point have to
              spend time either re-skilling or up-skilling
            </p>
            <div className='flex items-center'>
              <p className='pr-5 mb-5 text-base  md:text-lg'>
                At Delv, we are focused on enabling Africa’s youth to critically
                think through their career paths - through industry driven
                career guidance and mentorship, peer connections, skilling and
                learning for the 21st century through curated content, relevant
                for the continent.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section1 = () => {
  return (
    <div className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
      <div className='grid gap-10 lg:grid-cols-2'>
        <div className='flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg'>
          <div className='max-w-xl mb-6 text-white'>
            <h2 className='mb-5 font-sans text-3xl font-bold tracking-tight sm:text-4xl sm:leading-none'>
              About Us <br className='hidden md:block' />
            </h2>
            <p className='pr-5 mb-5 text-base  md:text-lg'>
              Delv inc. is a Edtech company focused on rethinking learning and
              skilling for employability of the youths across Africa. Finding a
              job and leading a fulfilling career is among today’s top most
              challenges for the younger generations.
            </p>
            <div className='flex items-center'>
              <p className='pr-5 mb-5 text-base  md:text-lg'>
                At Delv, we believe this challenge requires solutions that take
                a holistic look at career paths – from the early choices made in
                school, to transitioning to work and later to the choices one
                makes along their career path.
              </p>
            </div>
          </div>
        </div>
        <div className='flex items-center justify-center -mx-4 lg:pl-8'>
          <div className='flex flex-col items-end'>
            <img
              className='object-cover h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56'
              src={section1Img1}
              alt='pic'
            />
            <img
              className='object-cover w-20 h-20 sm:h-32 xl:h-40 sm:w-32 xl:w-40 -mt-2'
              src={section1Img2}
              alt='pic'
            />
          </div>
          <div className='-ml-3'>
            <img
              className='object-cover w-40 h-40 sm:h-64 xl:h-80 sm:w-64 xl:w-80'
              src={section1Img3}
              alt='pic'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
