import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import PostSnippet from './PostSnippet';

import CustomAlert from './CustomAlert';
import ModalWrapper from './ModalWrapper';

const SendPostModal = ({
  setShowSendPost,
  showSendPost,
  postSnippet,
  users,
}) => {
  const host = `${process.env.REACT_APP_HOST}`;
  const shareUrl =
    postSnippet?.category === 'Video' // If Video
      ? `${host}/video/watch/${postSnippet?._id}`
      : postSnippet?.category === 'Article' // If Article
      ? `${host}/article/read/${postSnippet?._id}`
      : `${host}/image/${postSnippet?._id}`; // Photo url
  const title = `${postSnippet?.title || postSnippet?.postText}`;

  return (
    <>
      {showSendPost ? (
        <>
          <form onSubmit={'/'}>
            <ModalWrapper
              title={'Send'}
              closeModal={() => setShowSendPost(false)}
              bodyContent={
                <>
                  <div className='relative flex-auto'>
                    {/* Post snip */}
                    <PostSnippet
                      key={postSnippet?._id}
                      content={postSnippet}
                      users={users}
                    />
                    <div className='mt-3 pt-0 w-full flex flex-col '>
                      <label className='font-semibold text-charcoal text-lg w-full mb-4 flex justify-center'>
                        Send Via
                      </label>

                      <div className='flex justify-around px-4'>
                        <div className='flex flex-col justify-center'>
                          <EmailShareButton
                            url={shareUrl}
                            subject={`Delv ${
                              postSnippet?.category || 'Post'
                            }: ${title}`}
                            body={`Check out this ${
                              postSnippet?.category || 'Post'
                            } on Delv`}
                            className=''
                          >
                            <EmailIcon size={52} round />
                          </EmailShareButton>
                          <span className='text-charcoal font-normal text-sm'>
                            Email
                          </span>
                        </div>
                        <div className='flex flex-col items-center'>
                          <FacebookShareButton
                            url={shareUrl}
                            title={`Delv ${
                              postSnippet?.category || 'Post'
                            }: ${title}`}
                            quote={`Check out this ${
                              postSnippet?.category || 'Post'
                            } on Delv: `}
                            openShareDialogOnClick={true}
                            className=''
                          >
                            <FacebookIcon size={52} round />
                          </FacebookShareButton>
                          <span className='text-charcoal font-normal text-sm'>
                            Facebook
                          </span>
                        </div>
                        <div className='flex flex-col justify-center'>
                          <TwitterShareButton
                            url={shareUrl}
                            title={`Check out this ${
                              postSnippet?.category || 'Post'
                            } "${title}" on Delv`}
                            hashtags={['delv']}
                            className=''
                          >
                            <TwitterIcon size={52} round />
                          </TwitterShareButton>
                          <span className='text-charcoal font-normal text-sm'>
                            Twitter
                          </span>
                        </div>
                        <div className='flex flex-col justify-center'>
                          <WhatsappShareButton
                            url={shareUrl}
                            title={`Check out this ${
                              postSnippet?.category || 'Post'
                            } "${title}" on Delv`}
                            className=''
                          >
                            <WhatsappIcon size={52} round />
                          </WhatsappShareButton>
                          <span className='text-charcoal font-normal text-sm'>
                            WhatsApp
                          </span>
                        </div>
                        <div className='flex flex-col justify-center'>
                          <FileCopyIcon
                            color='action'
                            style={{ fontSize: 52 }}
                            onClick={() => {
                              copy(shareUrl, {
                                message: null,
                              });
                              CustomAlert({
                                alertType: 'success',
                                alertMessage: 'Link copied to clipboard',
                              });
                            }}
                          />
                          <span className='text-charcoal font-normal text-sm'>
                            Copy Link
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>{' '}
                </>
              }
            />
          </form>
        </>
      ) : null}
    </>
  );
};

export default SendPostModal;
