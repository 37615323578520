import React from 'react';
import Truncate from 'react-truncate';

const UpcomingEventCard = ({ event }) => {
  var arr = event?.sessionDate.split('-');
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  var month_index = parseInt(arr[1], 10) - 1;

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <a href={`/joinsession/${event?._id}`}>
      <div
        style={{ height: '340px', border: '1px solid #C4C4C4' }}
        className={`active hover:bg-newMain cursor-pointer bg-white rounded-md hover:text-white p-5`}
      >
        <div className='pb-10'>
          <h4
            style={{ textAlign: 'center' }}
            className='text-4xl font-display text-center md:text-left'
          >
            {event?.sessionDate.substr(8)}
          </h4>
          <h4
            style={{ textTransform: 'uppercase', textAlign: 'center' }}
            className='text-md font-display text-center md:text-left'
          >
            {months[month_index]}
          </h4>
        </div>
        <div className='title-wrapper my-2 '>
          <h4
            style={{
              textTransform: 'capitalize',
              height: '70px',
              textAlign: 'center',
            }}
            className='text-2xl text-center font-display md:text-left'
          >
            <Truncate lines={2} ellipsis={<span>...</span>}>
              {event?.sessionTitle}
            </Truncate>
          </h4>
        </div>

        <div className='time mt-4'>
          <h4
            style={{ textAlign: 'center' }}
            className='text-md font-display text-center md:text-left'
          >
            Starts: {event?.startTime}
          </h4>
          <h4
            style={{ textAlign: 'center' }}
            className='text-md font-display text-center md:text-left'
          >
            <br />
            <Truncate lines={2} ellipsis={<span>...</span>}>
              {Capitalize(event?.description)}
            </Truncate>
          </h4>
        </div>
      </div>
    </a>
  );
};

export default UpcomingEventCard;
