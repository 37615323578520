import React from 'react';
import { PulseLoader } from 'react-spinners';

const ButtonLoader = ({ size, color }) => {
  return (
    <span className={`${size === 'default' && 'pt-2'}`}>
      <span className='h-full'>
        <PulseLoader
          size={15}
          margin={2}
          color={color === 'primary' ? '#0057FF' : 'white'}
        />
      </span>
    </span>
  );
};

export default ButtonLoader;
