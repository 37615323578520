import * as fetchFunctions from '../../api/index';
import * as contentTypes from '../types/content';
import contentEndpoints from '../endpoints/content';

export const uploadContent = (payload) => async (dispatch, getState) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });

  const GCSUpolad = await fetchFunctions.postData(
    process.env.REACT_APP_STORAGE_BUCKET_API,
    payload.contentForm,
  );
  if (!GCSUpolad.success) console.log(GCSUpolad?.error);

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    contentEndpoints.upload_content,
    payload.detailsForm,
    token,
  );

  if (result.success) {
    dispatch({
      type: contentTypes.CONTENT_SUCCESS,
      payload: {
        status: true,
        message: 'Upload Complete',
      },
    });
    dispatch({
      type: contentTypes.CONTENT_RESET,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getAllContent = () => async (dispatch) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });

  const result = await fetchFunctions.getData(contentEndpoints.get_all_content);
  if (result.success) {
    dispatch({
      type: contentTypes.ALL_CONTENT,
      payload: result.data,
    });
    dispatch({
      type: contentTypes.CONTENT_RESET,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getVideos =
  ({ skip = 0, limit = 30 }) =>
  async (dispatch) => {
    dispatch({
      type: contentTypes.CONTENT_LOADING,
    });

    const result = await fetchFunctions.getData(
      `${contentEndpoints.get_videos}/?skip=${skip}&limit=${limit}`,
    );
    console.log('results videos', result);

    if (result.success) {
      dispatch({
        type: contentTypes.VIDEOS,
        payload: result.data,
      });
      dispatch({
        type: contentTypes.CONTENT_RESET,
      });
    } else {
      dispatch({
        type: contentTypes.CONTENT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getCourses =
  ({ skip = 0, limit = 30 }) =>
  async (dispatch) => {
    dispatch({
      type: contentTypes.CONTENT_LOADING,
    });

    const result = await fetchFunctions.getData(
      `${contentEndpoints.get_courses}/?skip=${skip}&limit=${limit}`,
    );
    console.log('results courses', result);

    if (result.success) {
      dispatch({
        type: contentTypes.COURSES,
        payload: result.data,
      });
      dispatch({
        type: contentTypes.CONTENT_RESET,
      });
    } else {
      dispatch({
        type: contentTypes.CONTENT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getCourseUnits =
  ({ skip = 0, limit = 30, course }) =>
  async (dispatch) => {
    dispatch({
      type: contentTypes.CONTENT_LOADING,
    });

    const result = await fetchFunctions.getData(
      `${contentEndpoints.get_courseunits}/?skip=${skip}&limit=${limit}&course=${course}`,
    );
    console.log('results course units', result);

    if (result.success) {
      dispatch({
        type: contentTypes.COURSEUNITS,
        payload: result.data,
      });
      dispatch({
        type: contentTypes.CONTENT_RESET,
      });
    } else {
      dispatch({
        type: contentTypes.CONTENT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getArticles =
  ({ skip = 0, limit = 30 }) =>
  async (dispatch) => {
    dispatch({
      type: contentTypes.CONTENT_LOADING,
    });

    const result = await fetchFunctions.getData(
      `${contentEndpoints.get_articles}/?skip=${skip}&limit=${limit}`,
    );
    console.log('results articles', result);

    if (result.success) {
      dispatch({
        type: contentTypes.ARTICLES,
        payload: result.data,
      });
      dispatch({
        type: contentTypes.CONTENT_RESET,
      });
    } else {
      dispatch({
        type: contentTypes.CONTENT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getSpecificContent = (contentID) => async (dispatch, getState) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });

  const { token } = getState().user;
  const result = await fetchFunctions.getData(
    `${contentEndpoints.specific_content}/${contentID}`,
    token,
  );

  if (result.success) {
    dispatch({
      type: contentTypes.SPECIFIC_CONTENT,
      payload: result.data,
    });
    dispatch({
      type: contentTypes.CONTENT_RESET,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getRelatedContent = (contentID) => async (dispatch) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });

  const result = await fetchFunctions.getData(
    `${contentEndpoints.related_content}/${contentID}`,
  );

  if (result.success) {
    dispatch({
      type: contentTypes.RELATED_CONTENT,
      payload: result.data,
    });
    dispatch({
      type: contentTypes.CONTENT_RESET,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getLatestContent = () => async (dispatch) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });

  const result = await fetchFunctions.getData(contentEndpoints.latest_content);
  if (result.success) {
    dispatch({
      type: contentTypes.LATEST_CONTENT,
      payload: result.data.content,
    });
    dispatch({
      type: contentTypes.CONTENT_RESET,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getPopularContent = () => async (dispatch) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });

  const result = await fetchFunctions.getData(contentEndpoints.popular_content);
  if (result.success) {
    dispatch({
      type: contentTypes.POPULAR_CONTENT,
      payload: result.data.content,
    });
    dispatch({
      type: contentTypes.CONTENT_RESET,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getTrendingContent = () => async (dispatch) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });

  const result = await fetchFunctions.getData(
    contentEndpoints.trending_content,
  );
  if (result.success) {
    dispatch({
      type: contentTypes.TRENDING_CONTENT,
      payload: result.data.content,
    });
    dispatch({
      type: contentTypes.CONTENT_RESET,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getMyContent = () => async (dispatch, getState) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    contentEndpoints.get_my_content,
    token,
  );
  if (result.success) {
    dispatch({
      type: contentTypes.MY_CONTENT,
      payload: result.data,
    });
    dispatch({
      type: contentTypes.CONTENT_RESET,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getMyArticles =
  ({ skip = 0, limit = 30 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: contentTypes.CONTENT_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${contentEndpoints.get_my_articles}/?skip=${skip}&limit=${limit}`,
      token,
    );
    if (result.success) {
      dispatch({
        type: contentTypes.MY_ARTICLES,
        payload: result.data,
      });
      dispatch({
        type: contentTypes.CONTENT_RESET,
      });
    } else {
      dispatch({
        type: contentTypes.CONTENT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getMyVideos =
  ({ skip = 0, limit = 30 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: contentTypes.CONTENT_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${contentEndpoints.get_my_videos}/?skip=${skip}&limit=${limit}`,
      token,
    );
    if (result.success) {
      dispatch({
        type: contentTypes.MY_VIDEOS,
        payload: result.data,
      });
      dispatch({
        type: contentTypes.CONTENT_RESET,
      });
    } else {
      dispatch({
        type: contentTypes.CONTENT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getMyCourses =
  ({ skip = 0, limit = 30 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: contentTypes.CONTENT_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${contentEndpoints.get_my_courses}/?skip=${skip}&limit=${limit}`,
      token,
    );
    if (result.success) {
      dispatch({
        type: contentTypes.MY_COURSES,
        payload: result.data,
      });
      dispatch({
        type: contentTypes.CONTENT_RESET,
      });
    } else {
      dispatch({
        type: contentTypes.CONTENT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const updateContentFile = (payload) => async (dispatch, getState) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });

  const GCSUpolad = await fetchFunctions.postData(
    process.env.REACT_APP_STORAGE_BUCKET_API,
    payload.contentForm,
  );
  if (!GCSUpolad.success) console.log(GCSUpolad?.error);

  const { token } = getState().user;

  const result = await fetchFunctions.putData(
    `${contentEndpoints.update_content}/?id=${payload.contentID}`,
    payload.detailsForm,
    token,
    true,
  );

  if (result.success) {
    dispatch({
      type: contentTypes.CONTENT_SUCCESS,
      payload: {
        status: true,
        message: 'Update Complete',
      },
    });
    dispatch({
      type: contentTypes.MY_CONTENT,
      payload: result.data,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const updateContentDetails = (payload) => async (dispatch, getState) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.putData(
    `${contentEndpoints.update_content_details}/?id=${payload.contentID}`,
    payload,
    token,
    true,
  );

  if (result.success) {
    dispatch({
      type: contentTypes.CONTENT_SUCCESS,
      payload: {
        status: true,
        message: 'Update Complete',
      },
    });
    dispatch({
      type: contentTypes.MY_CONTENT,
      payload: result.data,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const deleteContent = (content) => async (dispatch, getState) => {
  dispatch({
    type: contentTypes.CONTENT_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.deleteData(
    `${contentEndpoints.delete_content}/?userID=${content.userID}&id=${content._id}`,
    token,
  );
  if (result.success) {
    dispatch({
      type: contentTypes.MY_CONTENT,
      payload: result.data,
    });
    dispatch({
      type: contentTypes.CONTENT_RESET,
    });
  } else {
    dispatch({
      type: contentTypes.CONTENT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
