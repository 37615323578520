import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DropzoneArea } from 'material-ui-dropzone';
import { PersonAdd } from '@material-ui/icons';
import { PuffLoader } from 'react-spinners';
import { v4 } from 'uuid';

import TagsInput from './TagsInput';
import CustomAlert from './CustomAlert';
import ModalWrapper from './ModalWrapper';

import * as helpers from '../helpers';

import * as contentActions from '../redux/actions/content';
import * as disciplinesActions from '../redux/actions/disciplines';

export const UploadNewContent = ({
  contentType,
  showUploadContent,
  setShowUploadContent,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const keywords = useSelector((state) => state.disciplines.keywordList);
  const { loading, success, error, errorMessage } = useSelector(
    (state) => state.content,
  );

  const [tags, setTags] = useState([]);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [courseCategory, setCourseCategory] = useState('');
  const [content, setContent] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [sourceLink, setSourceLink] = useState('');
  const [description, setDescription] = useState('');
  const [visible, toggleVisible] = helpers.useToggle(true);

  const fileType = () => {
    if (contentType.toLowerCase() === 'photo') return ['image/*'];
    if (contentType.toLowerCase() === 'article') return ['application/pdf'];
    if (contentType.toLowerCase() === 'video')
      return ['video/mp4', 'video/webm'];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tagsText = tags.map((tag) => tag.text);
    const contentFileName = `${v4()}-${title.replace(
      /[^a-zA-Z0-9]/g,
      '',
    )}-${content[0]?.path.replace(/[^a-zA-Z0-9.]/g, '')}`;

    const contentForm = new FormData(document.getElementById('contentForm'));
    contentForm.append('key', contentFileName);
    contentForm.append('Content-Type', content[0]?.type);
    contentForm.append('file', content[0]);

    const detailsForm = new FormData();
    detailsForm.append('title', title);
    detailsForm.append('author', author);
    detailsForm.append('visible', visible);
    detailsForm.append('courseCategory', courseCategory);
    detailsForm.append('sourceLink', sourceLink);
    detailsForm.append('description', description);
    detailsForm.append('category', helpers.capitalizeFirst(contentType));
    detailsForm.append('postedBy', `${user?.firstname} ${user?.lastname}`);
    detailsForm.append('contentFileName', contentFileName);
    detailsForm.append(
      'contentPublicUrl',
      `${process.env.REACT_APP_STORAGE_BUCKET_API}/${contentFileName}`,
    );
    detailsForm.append(
      'tags',
      `${tagsText.toString()},${user?.firstname} ${user?.lastname}`,
    );
    detailsForm.append(
      'thumbnail',
      contentType.toLowerCase() === 'photo' ? content[0] : thumbnail[0],
    );

    dispatch(contentActions.uploadContent({ contentForm, detailsForm }));

    setTitle('');
    setAuthor('');
    setSourceLink('');
    // setTags('');
    setDescription('');
    setCourseCategory('');
  };

  useEffect(() => {
    if (showUploadContent) dispatch(disciplinesActions.getDisciplines());
  }, [showUploadContent]);

  useEffect(() => {
    setShowUploadContent(false);
    if (success.message === 'Upload Complete')
      dispatch(contentActions.getMyContent());
    if (error)
      if (!`${errorMessage}`.includes('NetworkError')) {
        CustomAlert({
          alertType: 'error',
          alertMessage: 'Network error!',
        });
      }
  }, [success, error]);

  return (
    <>
      {showUploadContent ? (
        <>
          <>
            {/* This empty content form is for specifying the headers */}
            <form hidden id='contentForm' encType='multipart/form-data'></form>
            <form onSubmit={handleSubmit}>
              <ModalWrapper
                title={'Details'}
                closeModal={() => setShowUploadContent(false)}
                bodyContent={
                  <>
                    {' '}
                    <div className='relative'>
                      <div className=''>
                        <div className=''>
                          <label className='font-semibold text-textparagraph text-sm'>
                            TITLE
                          </label>
                          <input
                            required
                            type='text'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                          />
                        </div>
                        <div className='pt-3'>
                          <label className='font-semibold text-textparagraph text-sm'>
                            COURSE
                          </label>
                          <select
                            onChange={(e) => setCourseCategory(e.target.value)}
                            value={courseCategory}
                            className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                          >
                            <option value=''>COURSE</option>
                            <option value='Cloud Architect'>
                              Cloud Architect
                            </option>
                            <option value='Devops Engineer'>
                              Devops Engineer
                            </option>
                            <option value='Data Scientist'>
                              Data Scientist
                            </option>
                            <option value='Financial Analyst'>
                              Financial Analyst
                            </option>
                            <option value='Scrum Master'>Scrum Master</option>
                            <option value='Cyber Security'>
                              Cyber Security
                            </option>
                            <option value='Product Manager'>
                              Product Manager
                            </option>
                            <option value='Writing'>Writing</option>
                            <option value='IT & Software'>IT & Software</option>
                            <option value='Animation'>Animation</option>
                            <option value='Design'>Design</option>
                            <option value='Public Speaking'>
                              Public Speaking
                            </option>
                            <option value='Illustration'>Illustration</option>
                            <option value='Business'>Business</option>
                            <option value='Photo Film'>Photo & Film</option>
                          </select>
                        </div>
                      </div>
                      <div className='pt-6'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          DESCRIPTION
                        </label>
                        <textarea
                          rows='5'
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder={`Tell your viewers about the ${contentType.toLowerCase()}`}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                      <div className='pt-6'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          AUTHOR
                        </label>
                        <input
                          type='text'
                          value={author}
                          onChange={(e) => setAuthor(e.target.value)}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>

                      <label className='font-semibold text-textparagraph text-sm'>
                        TAGS
                      </label>
                      <TagsInput
                        tags={tags}
                        setTags={setTags}
                        suggestions={keywords || []}
                        placeholder={`Enter keywords relating to this ${contentType.toLowerCase()}`}
                      />

                      <div className='mb-5 pt-0 w-full text-left'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          SOURCE LINK
                        </label>
                        <input
                          type='text'
                          value={sourceLink}
                          onChange={(e) => setSourceLink(e.target.value)}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                      <div className='pt-6'>
                        <div>
                          <label className='font-semibold text-textparagraph text-sm'>
                            UPLOAD {contentType.toUpperCase()}
                          </label>
                          <DropzoneArea
                            className='g-lightgraybg'
                            filesLimit={1}
                            useChipsForPreview={
                              contentType.toLowerCase === 'photo' ? false : true
                            }
                            showAlerts={false}
                            showFileNames
                            maxFileSize={200000000} // 200mb limit
                            acceptedFiles={fileType()}
                            onChange={(files) => setContent(files)}
                          />
                        </div>
                      </div>

                      <div>
                        <div
                          className='pt-6'
                          hidden={
                            contentType.toLowerCase() === 'photo' ? true : false
                          }
                        >
                          <label className='font-semibold text-textparagraph text-sm'>
                            THUMBNAIL
                          </label>
                          <p className='font-base text-textparagraph text-sm mb-2'>
                            Select or upload a picture that shows what your
                            {contentType.toLowerCase()} is about. A good
                            thumbnail stands out and draws viewers attention
                          </p>
                          <DropzoneArea
                            filesLimit={1}
                            showAlerts={false}
                            maxFileSize={5000000} //5mb limit
                            acceptedFiles={['image/*']}
                            onChange={(files) => setThumbnail(files)}
                          />
                        </div>
                        <div className='w-full text-left'>
                          <label className='font-semibold text-textparagraph text-sm '>
                            VISIBILITY
                          </label>
                          <ul className='space-y-3 mt-4 text-charcoal text-sm '>
                            <li className='flex'>
                              <span className='mr-1'>
                                <input
                                  type='radio'
                                  className='px-3 py-3 mr-2'
                                  checked={!visible}
                                  onClick={toggleVisible}
                                />
                              </span>
                              <div className='flex flex-col'>
                                <span className='font-semibold'>Private</span>
                                <p className='font-base text-textparagraph text-sm mb-2'>
                                  Only you and the people or groups you choose
                                  can view your {contentType.toLowerCase()}
                                </p>
                                <a
                                  href
                                  className='font-md text-darkerblue text-sm mb-2 uppercase font-bold hover:text-bannerBlue cursor-pointer'
                                >
                                  <p className=''>
                                    <PersonAdd className='mr-1' /> Share
                                    Privately
                                  </p>
                                </a>
                              </div>
                            </li>
                            <li className='flex'>
                              <span className='mr-1'>
                                <input
                                  type='radio'
                                  className='px-3 py-3 mr-2'
                                  checked={visible}
                                  onClick={toggleVisible}
                                />
                              </span>
                              <div className='flex flex-col'>
                                <span className='font-semibold'>Public</span>
                                <p className='font-base text-textparagraph text-sm mb-2'>
                                  Everyone can view your{' '}
                                  {contentType.toLowerCase()}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                }
                footer={true}
                footerContent={
                  <>
                    <button
                      className='w-full h-14 falsefocus:shadow-outline  bg-darkerblue text-white font-semibold font-display text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                      type='submit'
                      onClick={(e) => handleSubmit(e)}
                      disabled={loading}
                    >
                      {loading ? <PuffLoader color='white' /> : 'Submit'}
                    </button>
                  </>
                }
              />
            </form>
          </>
          ;
        </>
      ) : null}
    </>
  );
};

export const UpdateContentFile = ({
  content,
  showUpdateContentFile,
  setShowUpdateContentFile,
}) => {
  const dispatch = useDispatch();
  const { loading, success, error, errorMessage } = useSelector(
    (state) => state.content,
  );

  const [contentFile, setContentFile] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contentFileName = `${v4()}-${contentFile[0].path.replace(
      /[^a-zA-Z0-9.]/g,
      '',
    )}`;

    const contentForm = new FormData(document.getElementById('contentForm'));
    contentForm.append('key', contentFileName);
    contentForm.append('Content-Type', contentFile[0]?.type);
    contentForm.append('file', contentFile[0]);

    const detailsForm = new FormData();
    detailsForm.append('contentFileName', contentFileName);
    detailsForm.append(
      'contentPublicUrl',
      `${process.env.REACT_APP_STORAGE_BUCKET_API}/${contentFileName}`,
    );

    dispatch(
      contentActions.updateContentFile({
        contentForm,
        detailsForm,
        contentID: content._id,
      }),
    );
  };

  const fileType = () => {
    if (content?.category.toLowerCase() === 'photo') return ['image/*'];
    if (content?.category.toLowerCase() === 'article')
      return ['application/pdf'];
    if (content?.category.toLowerCase() === 'video')
      return ['video/mp4', 'video/webm'];
  };

  useEffect(() => {
    dispatch(disciplinesActions.getDisciplines());
  }, []);

  useEffect(() => {
    setShowUpdateContentFile(false);
    if (success.message === 'Upload Complete')
      dispatch(contentActions.getMyContent());
    if (error)
      if (!`${errorMessage}`.includes('NetworkError')) {
        CustomAlert({
          alertType: 'error',
          alertMessage: 'Network error!',
        });
      } else {
        dispatch(contentActions.getMyContent());
      }
  }, [success, error]);

  return (
    <>
      {showUpdateContentFile ? (
        <>
          {/* This empty content form is for specifying the headers */}
          <form hidden id='contentForm' encType='multipart/form-data'></form>
          <form onSubmit={handleSubmit}>
            <ModalWrapper
              title={`Update ${content?.category}`}
              closeModal={() => {
                setShowUpdateContentFile(false);
              }}
              bodyContent={
                <>
                  <div className='relative'>
                    <div className=''>
                      <div className='pt-6'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          CONTENT
                        </label>
                        <DropzoneArea
                          filesLimit={1}
                          maxFileSize={200000000} // 200mb limit
                          useChipsForPreview={
                            content?.category.toLowerCase() === 'photo'
                              ? false
                              : true
                          }
                          showAlerts={false}
                          showFileNames
                          acceptedFiles={fileType()}
                          dropzoneText={`Upload ${content?.category.toLowerCase()}`}
                          dropzoneClass={
                            'MuiDropzoneArea-root bg-lightgraybg py-10 px-8 text-center text-sm  text-headingtext border-2 border-dashed border-charcoal rounded-md'
                          }
                          dropzoneTextClass={'font-semfibold'}
                          onChange={(files) => setContentFile(files)}
                        />
                      </div>
                    </div>
                  </div>
                </>
              }
              footer={true}
              footerContent={
                <>
                  <button
                    className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                    type='submit'
                    onClick={(e) => handleSubmit(e)}
                    disabled={loading}
                  >
                    {loading ? <PuffLoader color='white' /> : 'Update'}
                  </button>
                </>
              }
            />
          </form>
        </>
      ) : null}
    </>
  );
};

export const UpdateContentDetails = ({
  content,
  showUpdateContentDetails,
  setShowUpdateContentDetails,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const keywords = useSelector((state) => state.disciplines.keywordList);
  const { loading, success, error, errorMessage } = useSelector(
    (state) => state.content,
  );

  const [tags, setTags] = useState([]);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [thumbnail, setThumbnail] = useState([]);
  const [sourceLink, setSourceLink] = useState('');
  const [description, setDescription] = useState('');
  const [visible, toggleVisible] = helpers.useToggle(content?.visible || true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tagsText;

    if (tags.length === 0) {
      tagsText = content?.tags;
    } else {
      tagsText = tags.map((tag) => tag.text);
    }

    dispatch(
      contentActions.updateContentDetails({
        visible,
        category: content?.category,
        title: title || content?.title,
        author: author || content?.author,
        sourceLink: sourceLink || content?.sourceLink,
        description: description || content.description,
        postedBy: `${user?.firstname} ${user?.lastname}`,
        thumbnail: thumbnail[0],
        tags: tagsText.toString(),
        contentID: content._id,
      }),
    );
  };

  useEffect(() => {
    dispatch(disciplinesActions.getDisciplines());
  }, []);

  useEffect(() => {
    setShowUpdateContentDetails(false);
    if (success.message === 'Upload Complete')
      dispatch(contentActions.getMyContent());
    if (error)
      if (!`${errorMessage}`.includes('NetworkError')) {
        CustomAlert({
          alertType: 'error',
          alertMessage: 'Network error!',
        });
      } else {
        dispatch(contentActions.getMyContent());
      }
  }, [success, error]);

  return (
    <>
      {showUpdateContentDetails ? (
        <>
          <form onSubmit={handleSubmit}>
            <ModalWrapper
              title={`Edit ${content?.category} details`}
              closeModal={() => {
                setShowUpdateContentDetails(false);
              }}
              bodyContent={
                <>
                  <div className='relative'>
                    <div className=''>
                      <div className='pt-6'>
                        <div>
                          <label className='font-semibold text-textparagraph text-sm'>
                            TITLE
                          </label>
                          <input
                            required
                            type='text'
                            defaultValue={content?.title}
                            onChange={(e) => setTitle(e.target.value)}
                            className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                          />
                        </div>
                      </div>
                      <div className='pt-6'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          AUTHOR
                        </label>
                        <input
                          type='text'
                          defaultValue={content?.author}
                          onChange={(e) => setAuthor(e.target.value)}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                      <div className='pt-6'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          SOURCE LINK
                        </label>
                        <input
                          type='text'
                          defaultValue={content?.sourceLink}
                          onChange={(e) => setSourceLink(e.target.value)}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                      <div className='pt-6'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          TAGS
                        </label>
                        <p className='text-textparagraph text-sm'>
                          Current tags: {content.tags.toString()}
                        </p>
                        <TagsInput
                          tags={tags}
                          setTags={setTags}
                          suggestions={keywords || []}
                          placeholder={`Enter keywords relating to this ${content?.category.toLowerCase()}`}
                        />
                      </div>
                      <div className='pt-6'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          DESCRIPTION
                        </label>
                        <textarea
                          rows='5'
                          defaultValue={content?.description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder={'Tell your viewers about the article'}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                      <div
                        className='pt-6'
                        hidden={content?.category === 'Photo' ? true : false}
                      >
                        <label className='font-semibold text-textparagraph text-sm'>
                          THUMBNAIL
                        </label>
                        <DropzoneArea
                          filesLimit={1}
                          showAlerts={false}
                          maxFileSize={5000000} //5mb limit
                          acceptedFiles={['image/*']}
                          dropzoneText={'Upload cover photo'}
                          dropzoneClass={
                            'MuiDropzoneArea-root bg-lightgraybg py-10 px-8 text-center text-sm  text-headingtext border-2 border-dashed border-charcoal rounded-md'
                          }
                          dropzoneTextClass={'font-semfibold'}
                          onChange={(files) => setThumbnail(files)}
                        />
                      </div>
                      <div className='pt-6'>
                        <label className='font-semibold text-textparagraph text-sm '>
                          VISIBILITY
                        </label>
                        <ul className='space-y-3 mt-4 text-charcoal text-sm '>
                          <li className='flex'>
                            <span className='mr-1'>
                              <input
                                type='radio'
                                className='px-3 py-3 mr-2'
                                checked={!visible}
                                onClick={toggleVisible}
                              />
                            </span>
                            <div className='flex flex-col'>
                              <span className='font-semibold'>Private</span>
                              <p className='font-base text-textparagraph text-sm mb-2'>
                                Only you and the people or groups you choose can
                                view your {content?.category?.toLowerCase()}
                              </p>
                              <a
                                href
                                className='font-md text-darkerblue text-sm mb-2 uppercase font-bold hover:text-bannerBlue cursor-pointer'
                              >
                                <p className=''>
                                  <PersonAdd className='mr-1' /> Share Privately
                                </p>
                              </a>
                            </div>
                          </li>
                          <li className='flex'>
                            <span className='mr-1'>
                              <input
                                type='radio'
                                className='px-3 py-3 mr-2'
                                checked={visible}
                                onClick={toggleVisible}
                              />
                            </span>
                            <div className='flex flex-col'>
                              <span className='font-semibold'>Public</span>
                              <p className='font-base text-textparagraph text-sm mb-2'>
                                Everyone can view your{' '}
                                {content?.category?.toLowerCase()}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              }
              footer={true}
              footerContent={
                <>
                  <button
                    className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                    type='submit'
                    onClick={(e) => handleSubmit(e)}
                    disabled={loading}
                  >
                    {loading ? <PuffLoader color='white' /> : 'Update'}
                  </button>
                </>
              }
            />
          </form>
        </>
      ) : null}
    </>
  );
};
