import React from 'react';
import { Link } from 'react-router-dom';

import ModalWrapper from './ModalWrapper';

export const CallToActionModal = ({
  articleID,
  showCallToActionModal,
  setShowCallToActionModal,
}) => {
  return (
    <>
      {showCallToActionModal ? (
        <>
          <ModalWrapper
            title={''}
            closeModal={() => setShowCallToActionModal(false)}
            bodyContent={
              <div className='px-4 mx-auto sm:max-w-xl md:max-w-full '>
                <div className='flex flex-col max-w-screen-lg overflow-hidden bg-white lg:flex-row sm:mx-auto'>
                  <div className='flex flex-col justify-center bg-white lg:p-4 lg:pl-10 '>
                    <h5 className='mb-3 text-3xl font-extrabold leading-none sm:text-4xl'>
                      Join the Delv community
                    </h5>
                    <p className='mb-5 text-gray-800'>
                      <span className='font-bold'>Delv in </span> to enjoy
                      unlimited supply of curated content to guide your career
                      aspirations. Level up your game for prospective employers
                      to notice you. Register your institution and begin
                      training your students.
                    </p>
                    <div className='flex items-center'>
                      <Link
                        to='/signup'
                        aria-label=''
                        className='inline-flex items-center font-semibold transition-colors duration-200 text-darkerblue hover:text-navyblue'
                      >
                        <button
                          type='submit'
                          className='inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'
                        >
                          Get started
                        </button>
                      </Link>
                      <Link
                        to={`/login?article=${articleID}`}
                        aria-label=''
                        className='inline-flex items-center font-semibold transition-colors duration-200 text-darkerblue hover:text-navyblue'
                      >
                        Log in
                        <svg
                          className='inline-block w-3 ml-2'
                          fill='currentColor'
                          viewBox='0 0 12 12'
                        >
                          <path d='M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z' />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </>
      ) : null}
    </>
  );
};

export const MissingInformation = ({
  mentorName,
  showCallToActionModal,
  setShowCallToActionModal,
}) => {
  console.log(mentorName);
  return (
    <>
      {showCallToActionModal ? (
        <>
          <ModalWrapper
            title={
              <h5 className='mb-3 font-bold leading-none '>
                Booking calendar unavailable
              </h5>
            }
            closeModal={() => setShowCallToActionModal(false)}
            bodyContent={
              <div className='px-4 mx-auto sm:max-w-xl md:max-w-full '>
                <div className='flex flex-col max-w-screen-lg overflow-hidden bg-white lg:flex-row sm:mx-auto'>
                  <div className='flex flex-col justify-center bg-white lg:p-4 lg:pl-10 '>
                    <p className='mb-5 text-gray-800 text-lg'>
                      <span className='font-bold'>Dear Delver,</span>
                      <br />
                      {` Mentor
                      has not yet setup their Calendly profile to enable
                      session booking.`}{' '}
                      <br /> Contact our help desk for further guidance.
                    </p>
                    <div className='flex items-center'>
                      <div
                        // to='/signup'
                        aria-label=''
                        className='inline-flex items-center font-semibold transition-colors duration-200 text-darkerblue hover:text-navyblue'
                      >
                        <a
                          href='mailto: delvinfo@delvcareers.com'
                          aria-label='Send Email'
                          title='Send Email'
                          className=' w-28 inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'
                        >
                          Email us
                        </a>
                      </div>
                      <div
                        href='tel:077-600-7707'
                        aria-label='Our phone'
                        title='Our phone'
                        className='inline-flex items-center font-semibold transition-colors duration-200 text-darkerblue hover:text-navyblue'
                      >
                        <a
                          href='tel:077-600-7707'
                          aria-label='Our phone'
                          title='Our phone'
                          className='w-28 inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'
                        >
                          Call us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </>
      ) : null}
    </>
  );
};
