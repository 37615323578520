import React, { useState, useEffect } from 'react';
import NavHeaderLanding from '../components/NavBarLanding';
import Button from '../components/Button';
import emailjs from 'emailjs-com';
import CustomAlert from '../components/CustomAlert';
import Phone from '../assets/telephone.png';
import Location from '../assets/pin.png';
import Mail from '../assets/mail.png';

const ContactUs = () => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const submitForm = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_dvg8oyv',
        'template_ayj7z6j',
        e.target,
        'user_LhZl7V75cd3jIR3BYcVMN',
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response);
          setValues({
            name: '',
            email: '',
            subject: '',
            message: '',
          });
          setStatus('SUCCESS');
        },
        (error) => {
          console.log('FAILED...', error);
        },
      );
  };

  useEffect(() => {
    if (status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 100);
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Your Message has been sent',
      });
    }
  }, [status]);

  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <>
      <NavHeaderLanding />
      <div>
        <link
          rel='stylesheet'
          href='https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css'
          integrity='sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh'
        />
        <section className='bg-white {-- h-screen --}'>
          <div className='mx-auto flex lg:justify-center h-full flex-col lg:flex-row'>
            <div className='w-full lg:w-2/5 px-2 py-20 sm:py-40 sm:px-12 flex flex-col justify-center items-center bg-darkerblue relative'>
              {/* <div className='absolute left-0 top-0 pt-4 px-4 sm:px-12 sm:pt-8'>
            </div> */}
              <div className='absolute right-0 top-0 '>
                <svg
                  width='208'
                  height='208'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g id='SemiCircle'>
                    <title>SemiCircle</title>
                    <ellipse
                      stroke='null'
                      fill='#000000'
                      strokeWidth='12'
                      cx='145'
                      cy='63'
                      id='svg_6'
                      rx='144'
                      ry='144'
                      opacity='0.1'
                    />
                  </g>
                </svg>
              </div>
              <div className='flex relative z-30 flex-col justify-center px-4 md:pr-12 text-left text-white'>
                <h3 className='text-3xl leading-tight font-bold font-display'>
                  Have Some Questions?
                </h3>
                <h5 className='text-2xl pt-4 leading-tight font-semibold font-display'>
                  Please fill out the form with any question you would like to
                  ask and we shall reply shortly
                </h5>
                <span className='text-lg pt-8 leading-relaxed  font-normal font-display'>
                  <h5 className='text-2xl pt-4 leading-tight font-semibold font-display mb-3'>
                    Contact Information
                  </h5>
                  <div className=' flex space-x-4'>
                    <img
                      height={25}
                      width={25}
                      src={Phone}
                      alt='Delv-logo'
                      className='object-scale-down'
                    />
                    <p>+256 776 007 707</p>
                  </div>

                  <br />
                  <div className=' flex space-x-4'>
                    <img
                      height={25}
                      width={25}
                      src={Mail}
                      alt='Delv-logo'
                      className='object-scale-down'
                    />
                    <p>info@delvcareers.com </p>
                  </div>
                  <br />

                  <div className=' flex space-x-4'>
                    <img
                      height={25}
                      width={25}
                      src={Location}
                      alt='Delv-logo'
                      className='object-scale-down'
                    />
                    <p>
                      Kampala, Uganda
                      <br />
                    </p>
                  </div>
                </span>
              </div>
              <div className='absolute left-0 bottom-0'>
                <svg
                  width='208'
                  height='256'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g id='squarePattern'>
                    <title>SquarePatten</title>
                    <rect
                      stroke='#000000'
                      fill='none'
                      strokeWidth='9'
                      x='-188.00001'
                      y='7.99999'
                      width='389.00001'
                      height='330.42585'
                      id='svg_7'
                      opacity='0.1'
                    />
                    <rect
                      stroke='null'
                      fill='#000000'
                      strokeWidth='21'
                      x='-160.50001'
                      y='27.84689'
                      width='343.00001'
                      height='294.30622'
                      id='svg_8'
                      opacity='0.1'
                    />
                  </g>
                </svg>
              </div>
            </div>
            <form
              onSubmit={submitForm}
              // ref={form}
              className='w-full lg:w-3/5 flex justify-center bg-white '
            >
              <div className='w-full sm:w-4/6 md:w-3/6 lg:w-2/3 text-charcoal  flex flex-col justify-center px-2 sm:px-0 py-16'>
                <div className='px-2 sm:px-6'>
                  <h3 className='text-2xl sm:text-3xl md:text-2xl font-bold leading-relaxed text-center font-display'>
                    Contact Us
                  </h3>
                  <h3 className='text-lg pt-2 leading-relaxed text-center font-normal font-display'>
                    Feel free to contact us 24/7 hours
                  </h3>
                </div>
                <div className='mt-8 w-full px-2 sm:px-6'>
                  <div className='flex flex-col mt-2'>
                    <input
                      className='text-sm focus:outline-noone sm:text-lg text-charcoal relative h-11 border rounded border-linecolor focus:border-main bg-lightgraybg opacity-1 text-gray w-full py-2 pr-2 pl-12'
                      placeholder='Name'
                      type='text'
                      margin='mb-2'
                      width='w-full'
                      id='email'
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='flex flex-col mt-2'>
                    <input
                      className='text-sm focus:outline-noone sm:text-lg text-charcoal relative h-11 border rounded border-linecolor focus:border-main bg-lightgraybg opacity-1 text-gray w-full py-2 pr-2 pl-12'
                      placeholder='Email'
                      type='email'
                      margin='mb-2'
                      width='w-full'
                      id='email'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='flex flex-col mt-2'>
                    <div className='relative mb-4'>
                      <input
                        className='text-sm focus:outline-noone sm:text-lg text-charcoal relative h-11 border rounded border-linecolor focus:border-main bg-lightgraybg opacity-1 text-gray w-full py-2 pr-2 pl-12'
                        type='text'
                        id='Subject'
                        placeholder='Subject'
                        margin='mb-2'
                        width='w-full'
                        name='subject'
                        value={values.subject}
                        onChange={handleChange}
                      />
                    </div>

                    <textarea
                      className='flex-grow w-full p-4 mb-2
                          transition duration-200 bg-lightgraybg border
                          border-linecolor rounded appearance-none
                          focus:border-darkerblue focus:outline-noone
                          focus:shadow-outline'
                      id='messageBody'
                      placeholder={'Type your message body here...'}
                      rows='7'
                      name='message'
                      value={values.message}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className='px-2 sm:px-6'>
                  <Button
                    type='submit'
                    content='Send'
                    margin='mb-6'
                    backgroundColor='bg-darkerblue'
                  />
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
