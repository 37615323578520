import React from 'react';

export default function Video(props) {
  return (
    <div>
      <img
        src={props.url}
        alt='video posta'
        className='w-full h-44 rounded mb-2.5 object-cover'
      />
    </div>
  );
}
