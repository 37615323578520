import React from 'react';

export default function CourseCarddata(props) {
  return (
    <div>
      <div className='flex flex-row w-full mb-3'>
        <div className='flex-1 text-xs ml-2.5 font-medium font-body text-headingtext'>
          {props.students}
        </div>
        <div className='text-xs font-medium font-body text-headingtext mr-2.5'>
          {props.hours}
        </div>
      </div>
      <div className='font-semibold text-sm font-body text-black mb-2.5 ml-2.5'>
        {props.description}
      </div>
      <div className='flex flex-row'>
        <div className='flex-1 ml-2.5 font-medium text-headingtext'>
          {props.author}
        </div>
        <div>
          <i
            className='m-auto fa fa-star text-starsorange mb-6 ml-1'
            aria-hidden='true'
          ></i>
          <span className=' mx-1  font-semibold text-base text-starsorange mb-6'>
            {props.star}
          </span>
          <span className='mr-2.5 text-linecolor'>{props.value}</span>
        </div>
      </div>
    </div>
  );
}
