import * as fetchFunctions from '../../api/index';
import * as searchTypes from '../types/search';
import searchEndpoints from '../endpoints/search';

export const search = (payload) => async (dispatch) => {
  dispatch({
    type: searchTypes.SEARCH_LOADING,
  });
  const result = await fetchFunctions.postData(
    `${searchEndpoints.search}`,
    payload,
  );

  if (result.success) {
    dispatch({
      type: searchTypes.SEARCH_RESULTS,
      payload: result.response,
      query: payload.keywords,
    });
    dispatch({
      type: searchTypes.SEARCH_RESET,
    });
  } else {
    dispatch({
      type: searchTypes.SEARCH_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
