const base = `${process.env.REACT_APP_BACKEND_URL}`;
const endpoints = {
  create_discussion_groups: `${base}/discussiongroup`,
  update_group_members: `${base}/discussiongroup/addmembers`,
  get_all_discussion_groups: `${base}/discussiongroup`,
  get_one_discussion_group: `${base}/discussiongroup`,
  get_one_discussion_groups: `${base}/discussiongroup/group`,
  check_member_in_group: `${base}/discussiongroup/searchMember`,
  update_profilepic_discussion_group: `${base}/discussiongroup/profilepic`,
  request_join_discussion_group: `${base}/user/requesttojoin/new`,
  get_all_join_requests: `${base}/user/requeststojoin/view`,
  reject_join_request: `${base}/user/requesttojoin/reject`,
  accept_join_request: `${base}/user/requesttojoin/accept`,
};

export default endpoints;
