import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Truncate from 'react-truncate';

import Footer from '../components/Footer';
import Loading from '../components/Loading';
import NavHeader from '../components/NavBarHeader';
import RelatedContent from '../components/RelatedContent';
import DefaultThumbnail from '../components/DefaultThumbnail';
import { CallToActionModal } from '../components/CallToActionModal';

import * as helpers from '../helpers';
import * as contentActions from '../redux/actions/content';

const ArtileDescription = ({ article, postedBy }) => {
  return (
    <div className='mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl'>
      <div className='grid gap-12 row-gap-8 lg:grid-cols-5'>
        <div className='lg:col-span-3'>
          {article?.thumbnailFile?.publicUrl ? (
            <img
              src={article?.thumbnailFile?.publicUrl}
              alt={article?.title}
              className='object-fit w-full h-56 rounded shadow-lg sm:h-96'
            />
          ) : (
            <div className='object-fit w-full h-56 rounded shadow-lg sm:h-96'>
              <DefaultThumbnail text={article.title} />
            </div>
          )}
        </div>
        <div className='flex flex-col justify-center lg:col-span-2 '>
          <div className='max-w-xl mb-6'>
            <h2 className='max-w-lg mb-6 font-sans text-xl font-bold tracking-tight text-charcoal sm:text-2xl sm:leading-none uppercase'>
              {article?.tags[0]}
              {`.`}
              <span className='inline-block text-headingtext ml-4 text-lg sm:text-xl capitalize font-normal'>
                {helpers.dateFormatter(article?.createdAt)}
              </span>
            </h2>
            <h5 className='mb-4 text-3xl font-extrabold leading-none'>
              {article?.title}
            </h5>
            <p className='text-xs text-headingtext md:text-base'>
              <Truncate lines={8} ellipsis={<span>...</span>}>
                {article?.description}
              </Truncate>
            </p>
          </div>
          <div className='grid gap-8 row-gap-8 sm:grid-cols-1'>
            <div>
              <div className='flex'>
                <img
                  className='object-cover w-14 h-14 mr-4 rounded-full shadow'
                  src={postedBy?.profilePicture?.publicUrl}
                  alt={postedBy?.firstname}
                />
                <div className='flex flex-col justify-center'>
                  <p className='text-lg font-bold text-charcoal'>
                    {postedBy?.firstname} {postedBy?.lastname}
                  </p>
                  <p className='text-sm text-headingtext'>
                    {postedBy?.usercategory[postedBy?.usercategory?.length - 1]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PageBody = () => {
  const dispatch = useDispatch();
  const { articleID } = useParams();
  const users = useSelector((state) => state?.user?.data);
  const article = useSelector((state) => state.content.data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(contentActions.getMyContent());
  }, []);

  const postedBy = users?.find((user) => user?._id === `${article?.userID}`);

  useEffect(() => {
    // Article is stored in an array data type
    if (article?.length === 0) {
      dispatch(contentActions.getSpecificContent(articleID));
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [article, articleID]);
  const [showCallToActionModal, setShowCallToActionModal] = useState(true);

  return (
    <>
      {' '}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div className='bg-lightgraybg h-full'>
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8'>
              <div className='mb-8'>
                <ArtileDescription article={article} postedBy={postedBy} />
              </div>
              <div className='mb-8'>
                <RelatedContent type={'Article'} typeID={article?._id} />
              </div>
            </div>
          </div>
        </div>
      )}
      <CallToActionModal
        articleID={articleID}
        showCallToActionModal={showCallToActionModal}
        setShowCallToActionModal={setShowCallToActionModal}
      />
    </>
  );
};

export default function ArticleOveview() {
  return (
    <>
      <NavHeader />
      <PageBody />
      <div className='bg-black2'>
        <Footer />
      </div>
    </>
  );
}
