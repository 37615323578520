import React, { useEffect, useState } from 'react';
import { ArrowBack, Cast, Share } from '@material-ui/icons';
import { Link, useParams } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import '../carousel.css';
import Loading from '../components/Loading';
import VideoPlaceholder from '../assets/video1.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as sessionsActions from '../redux/actions/sessions';
import * as usersActions from '../redux/actions/users';
import Nav from '../components/NavBarR';
import SharePostModal from '../components/SharePostModal';
import moment from 'moment';

const SessionViewOne = () => {
  const { sessionID } = useParams();
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.user?.data);
  const { loading, session } = useSelector((state) => state.sessions);
  console.log(session);
  useEffect(() => {
    dispatch(usersActions.getUsers());
    dispatch(sessionsActions.getSpecificSession(sessionID));
  }, []);

  return (
    <>
      <div>
        <Nav />
        {loading ? (
          <div className='bg-lightgraybg h-screen w-full fixed'>
            <Loading />
          </div>
        ) : (
          <div className='bg-lightgraybg h-full'>
            <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
                <div className='flex justify-start'>
                  <div className='mb-3 text-center md:mb-4 lg:mb-4'>
                    <Link
                      to='/sessions/subscribed'
                      className='inline-flex items-center justify-center w-full h-12 font-medium tracking-wide text-headingtext transition duration-200  md:w-auto bg-none hover:text-navyblue focus:outline-none'
                    >
                      <ArrowBack />
                      <span className='ml-2'>Back</span>
                    </Link>
                  </div>
                </div>
                <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                  <SessionSection session={session} users={users} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const SessionSection = ({ session, users }) => {
  const [showSharePost, setShowSharePost] = useState(false);
  const currentUser = useSelector((state) => state?.user?.user);
  const isPresenter =
    session?.presenter === `${currentUser.firstname} ${currentUser.lastname}`;

  return (
    <div className='flex flex-col mb-6 lg:justify-between shadow '>
      <div className='grid gap-8  lg:grid-cols-12 grid-cols-1'>
        <div className='w-full flex flex-col mb-4 lg:justify-between col-span-12 lg:col-span-9 bg-white p-4 lg:p-8'>
          <div className=' mb-5 flex flex-row justify-between'>
            <h2 className='text-3xl inline-block font-bold tracking-tight text-black sm:text-3xl sm:leading-none'>
              {`Session: ${session?.sessionTitle}`}
            </h2>
            <span className='inline-block font-semibold tracking-tight text-buttonGreen sm:text-lg sm:leading-none'>
              {`Starts ${moment().to(moment(session?.sessionDate))}`}
            </span>
          </div>
          <div className='flex flex-col'>
            <img
              src={session?.thumbnailFile?.publicUrl || `${VideoPlaceholder}`}
              className='object-cover w-full h-56 lg:h-96 mb-5'
              alt={session?.sessionTitle}
            />

            <div className='flex-grow  rounded-b px-2'>
              <div className='flex flex-row w-full justify-between mb-2'>
                <p className='text-xl lg:text-3xl font-semibold text-gray '>
                  {'About'}
                </p>
              </div>
              <div className='mb-5 text-textparagraph'>
                <span className='font-base text-sm lg:text-base leading-7'>
                  {session?.description}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-12 lg:col-span-3'>
          <div className='w-full flex flex-col mb-4 lg:justify-between col-span-2 bg-none px-4'>
            <div className='grid grid-cols-2 mb-5'>
              <span className=' text-3xl font-bold tracking-tight text-charcoal sm:text-4xl sm:leading-none group inline-block'>
                {session?.price > 0 ? `Ugx ${session?.price}` : 'FREE'}
              </span>
            </div>
            <div className=' grid grid-cols-2 mb-5'>
              <span className=' text-xl font-bold tracking-tight text-charcoal sm:text-lg sm:leading-none group inline-block'>
                Date:
              </span>
              <span className=' text-xl font-base tracking-tight text-textparagraph sm:text-lg sm:leading-none groupinline-block'>
                {/* Jan, 4th, 2021 */}
                {session?.sessionDate}
              </span>
            </div>
            <div className=' grid grid-cols-2 mb-5'>
              <span className=' text-lg font-bold tracking-tight text-charcoal sm:text-lg sm:leading-none group inline-block'>
                Start time :
              </span>
              <span className=' text-lg font-base tracking-tight text-textparagraph sm:text-lg sm:leading-none groupinline-block'>
                {/* 11:00am - 12:00pm */}
                {session?.startTime} {` - `} {session?.endTime}
              </span>
            </div>
            {session?.duration && (
              <div className=' grid grid-cols-2 mb-5'>
                <span className=' text-lg font-bold tracking-tight text-charcoal sm:text-lg sm:leading-none group inline-block'>
                  Duration :
                </span>
                <span className=' text-lg font-base tracking-tight text-textparagraph sm:text-lg sm:leading-none groupinline-block'>
                  {session?.duration}
                </span>
              </div>
            )}
            <div className=' grid grid-cols-2 mb-5'>
              <span className=' text-base font-bold tracking-tight text-charcoal sm:text-lg sm:leading-none group inline-block'>
                Speaker :
              </span>
              <span className=' text-base font-base tracking-tight text-textparagraph sm:text-lg sm:leading-none groupinline-block'>
                {session?.presenter}
              </span>
            </div>
            <div className=' grid grid-cols-2 mb-5'>
              <span className=' text-base font-bold tracking-tight text-charcoal sm:text-lg sm:leading-none group inline-block'>
                Organizer:
              </span>
              <span className=' text-base font-base tracking-tight text-textparagraph sm:text-lg sm:leading-none groupinline-block'>
                {/* Ministry of ICT */}
                {session?.organiser}
              </span>
            </div>

            <p className=' mb-7'></p>
            <div className='mb-3 text-center md:mb-4 lg:mb-4 flex flex-col'>
              <button className='mb-6 inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-buttonGreen hover:bg-green3 focus:shadow-outline focus:outline-none'>
                <a
                  href={
                    `https://${session?.sessionAddressLink}` ||
                    'https://meet.google.com/#'
                  }
                  aria-label='Go to Session'
                  title='Go to session'
                  target='_blank'
                  rel='noreferrer'
                >
                  <Cast />
                  <span className='ml-2'>
                    {isPresenter ? 'Go Live' : 'Attend Session'}
                  </span>
                </a>
              </button>
              <button
                aria-label='Share Session'
                title='Share Session'
                onClick={() => setShowSharePost(true)}
                className='mb-3 inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'
              >
                <Share />
                <span className='ml-2'>Share session</span>
              </button>
            </div>
          </div>
        </div>
        <SharePostModal
          content='Session'
          setShowSharePost={setShowSharePost}
          showSharePost={showSharePost}
          sessionSnippet={session}
          users={users}
        />
      </div>
    </div>
  );
};

export default SessionViewOne;
