export let controller;
let signal;

export const getData = async (url = '', authToken) => {
  controller = new AbortController();
  signal = controller.signal;
  try {
    let response;
    let fetchHeaders = {};
    if (!authToken) {
      response = await fetch(url);
    } else {
      fetchHeaders.Authorization = `Bearer ${authToken}`;
      response = await fetch(url, {
        headers: { ...fetchHeaders },
        signal,
      });
    }

    if (response.ok) {
      response = await response.json();
      return {
        data: response,
        success: true,
      };
    }
    response = await response.json();
    return { error: response.err, success: false };
  } catch (err) {
    return {
      error: err,
      success: false,
    };
  }
};

export const postData = async (url = '', data, authToken) => {
  controller = new AbortController();
  signal = controller.signal;
  try {
    let dataIsFormData = data instanceof FormData;
    let postObject;
    let fetchHeaders = {};

    if (!dataIsFormData) {
      postObject = JSON.stringify(data);
      fetchHeaders = {
        'Content-Type': 'application/json',
      };
    } else {
      postObject = data;
    }

    if (authToken) {
      fetchHeaders.Authorization = `Bearer ${authToken}`;
    }

    let response = await fetch(url, {
      method: 'POST',
      headers: { ...fetchHeaders },
      body: postObject,
      signal,
    });

    if (response.ok) {
      if (!dataIsFormData) response = await response.json();
      return {
        response,
        success: true,
      };
    }
    response = await response?.json();
    return { error: response.err, success: false };
  } catch (err) {
    return {
      error: err,
      success: false,
    };
  }
};

export const deleteData = async (url = '', authToken) => {
  controller = new AbortController();
  signal = controller.signal;
  try {
    let fetchHeaders = {};
    fetchHeaders = {
      'Content-Type': 'application/json',
    };

    if (authToken) {
      fetchHeaders.Authorization = `Bearer ${authToken}`;
    }

    let response = await fetch(url, {
      method: 'DELETE',
      headers: { ...fetchHeaders },
      signal,
    });
    if (response.ok) {
      response = await response.json();
      return {
        response,
        success: true,
      };
    }
    return { error: response.err, success: false };
  } catch (err) {
    return {
      error: err,
      success: false,
    };
  }
};

export const patchData = async (url = '', data, authToken) => {
  controller = new AbortController();
  signal = controller.signal;
  try {
    let dataIsFormData = data instanceof FormData;

    let postObject;
    let fetchHeaders = {};

    if (!dataIsFormData) {
      postObject = JSON.stringify(data);
      fetchHeaders = {
        'Content-Type': 'application/json',
      };
    } else {
      postObject = data;
    }

    if (authToken) {
      fetchHeaders.Authorization = `Bearer ${authToken}`;
    }

    let response = await fetch(url, {
      method: 'PATCH',
      headers: { ...fetchHeaders },
      body: postObject,
      signal,
    });

    response = response.json();
    return response;
  } catch (err) {
    return {
      error: err,
      success: false,
    };
  }
};

export const putData = async (url = '', data, authToken) => {
  controller = new AbortController();
  signal = controller.signal;
  try {
    let dataIsFormData = data instanceof FormData;
    let postObject;
    let fetchHeaders = {};

    if (!dataIsFormData) {
      postObject = JSON.stringify(data);
      fetchHeaders = {
        'Content-Type': 'application/json',
      };
    } else {
      postObject = data;
    }

    if (authToken) {
      fetchHeaders.Authorization = `Bearer ${authToken}`;
    }

    let response = await fetch(url, {
      method: 'PUT',
      headers: { ...fetchHeaders },
      body: postObject,
      signal,
      mode: 'cors',
    });

    if (response.ok) {
      response = await response.json();
      return {
        response,
        success: true,
      };
    }
    response = await response?.json();
    return response;
  } catch (err) {
    return {
      error: err,
      success: false,
    };
  }
};

export const downloadData = (fileURL, fileName, contentType) => {
  // Request through proxy server to append download headers
  fetch(`${process.env.REACT_APP_CORS_PROXY}/${fileURL}`, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};
