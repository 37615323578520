import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../redux/actions/users';

const TheirMessage = ({ message, userID }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state?.user);

  useEffect(() => {
    dispatch(userActions.getUserByID({ userID }));
  }, []);

  return (
    <div className='message-row'>
      {message.attachments && message.attachments.length > 0 ? (
        <img
          src={message.attachments[0].file}
          alt='message-attachment'
          className='message-image'
        />
      ) : (
        <>
          <div
            className='message'
            style={{
              float: 'left',
              backgroundColor: '#CABCDC',
            }}
          >
            <p style={{ fontSize: '12px' }}>{userData?.firstname}</p>

            {message}
          </div>
        </>
      )}
    </div>
  );
};

export default TheirMessage;
