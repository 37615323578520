export const CHECK_MEMBER_IN_GROUP = 'CHECK_MEMBER_IN_GROUP';
export const ADDED_GROUP_MEMBERS = 'ADDED_GROUP_MEMBERS';
export const GET_DISCUSSION_GROUPS = 'GET_DISCUSSION_GROUPS';
export const GET_ONE_DISCUSSION_GROUP = 'GET_ONE_DISCUSSION_GROUP';
export const DISCUSSION_GROUPS_ERROR = 'DISCUSSION_GROUPS_ERROR';
export const DISCUSSION_GROUPS_RESET = 'DISCUSSION_GROUPS_RESET';
export const DISCUSSION_GROUPS_SUCCESS = 'DISCUSSION_GROUPS_SUCCESS';
export const DISCUSSION_GROUPS_REQUEST_TO_JOIN =
  'DISCUSSION_GROUPS_REQUEST_TO_JOIN';
export const DISCUSSION_GROUPS_GET_JOIN_REQUESTS =
  'DISCUSSION_GROUPS_GET_JOIN_REQUESTS';
export const DISCUSSION_GROUPS_ACCEPT_JOIN_REQUEST =
  'DISCUSSION_GROUPS_ACCEPT_JOIN_REQUEST';
export const DISCUSSION_GROUPS_REJECT_JOIN_REQUEST =
  'DISCUSSION_GROUPS_REJECT_JOIN_REQUEST';
export const DISCUSSION_GROUPS_LOADING = 'DISCUSSION_GROUPS_LOADING';
