import * as fetchFunctions from '../../api/index';
import * as commentsTypes from '../types/comments';
import commentsEndpoints from '../endpoints/comments';

export const getAllComments = () => async (dispatch, getState) => {
  dispatch({
    type: commentsTypes.COMMENTS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    commentsEndpoints.get_all_comments,
    token,
  );

  if (result.success) {
    dispatch({
      type: commentsTypes.ALL_COMMENTS,
      payload: result.data,
    });
    dispatch({
      type: commentsTypes.COMMENTS_RESET,
    });
  } else {
    dispatch({
      type: commentsTypes.COMMENTS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const addComment = (payload) => async (dispatch, getState) => {
  dispatch({
    type: commentsTypes.COMMENTS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    commentsEndpoints.add_comment,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: commentsTypes.COMMENTS_SUCCESS,
      payload: {
        status: true,
        message: 'Comment added successfully',
      },
    });
    dispatch({
      type: commentsTypes.COMMENTS_RESET,
    });
  } else {
    dispatch({
      type: commentsTypes.COMMENTS_ERROR,
      payload: {
        error: true,
        errorMessage: 'Failed to submit comment',
      },
    });
  }
};

export const itemComments = (itemID) => async (dispatch, getState) => {
  dispatch({
    type: commentsTypes.COMMENTS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    `${commentsEndpoints.item_comments}/${itemID}`,
    token,
  );

  if (result.success) {
    dispatch({
      type: commentsTypes.ITEM_COMMENTS,
      payload: result.data,
    });
    dispatch({
      type: commentsTypes.COMMENTS_RESET,
    });
  } else {
    dispatch({
      type: commentsTypes.COMMENTS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
