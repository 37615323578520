export const randomSelector = () => {
  const colors = [
    {
      primary: '#ffafbd',
      secondary: '#ffc3a0',
    },

    {
      primary: '#2193b0',
      secondary: '#6dd5ed',
    },
    {
      primary: '#cc2b5e',
      secondary: '#753a88',
    },
    {
      primary: '#ee9ca7',
      secondary: '#ffdde1',
    },
    {
      primary: '#42275a',
      secondary: '#734b6d',
    },
    {
      primary: '#bdc3c7',
      secondary: '#2c3e50',
    },
    {
      primary: '#de6262',
      secondary: '#ffb88c',
    },
    {
      primary: '#dd5e89 ',
      secondary: '  #f7bb97',
    },
    {
      primary: '#56ab2f ',
      secondary: ' #a8e063',
    },
    {
      primary: '#614385 ',
      secondary: '#6dd5ed',
    },
    {
      primary: '#614385 ',
      secondary: '#516395',
    },
    {
      primary: '#eecda3 ',
      secondary: '#ef629f',
    },
    {
      primary: '#ef629f ',
      secondary: '#6dd5ed',
    },
    {
      primary: '#eacda3 ',
      secondary: '#d6ae7b',
    },
    {
      primary: '#02aab0 ',
      secondary: '#00cdac',
    },
    {
      primary: '#d66d75',
      secondary: '#e29587',
    },
    {
      primary: '#000428 ',
      secondary: '#004e92',
    },
    {
      primary: '#ddd6f3',
      secondary: '#004e92',
    },
    {
      primary: '#000428 ',
      secondary: '#ddd6f3',
    },
    {
      primary: '#ddd6f3',
      secondary: ' #faaca8 ',
    },
    {
      primary: '#211F2F',
      secondary: ' #faaca8 ',
    },
    {
      primary: '#ddd6f3',
      secondary: ' #211F2F ',
    },
    {
      primary: '#918CA9',
      secondary: ' #211F2F',
    },
    {
      primary: '#432371',
      secondary: ' #faaca8 ',
    },
    {
      primary: '#ddd6f3',
      secondary: ' #714674 ',
    },
    {
      primary: '#ddd6f3',
      secondary: ' #432371 ',
    },
    {
      primary: '#07C8F9',
      secondary: ' #FB76C1 ',
    },
    {
      primary: '#FB76C1',
      secondary: ' #07C8F9 ',
    },
    {
      primary: '#ddd6f3',
      secondary: ' #FB76C1 ',
    },
    {
      primary: '#0A85ED',
      secondary: ' #B2A496',
    },
  ];

  const selectedColor = colors[Math.floor(Math.random() * colors.length)];
  return selectedColor;
};
