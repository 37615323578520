import React from 'react';

// Components
import ContentPreview from './ContentPreview';
import DefaultThumbnail from './DefaultThumbnail';

export default function SharedPostSnippet({ content, content_id, users }) {
  //   const { feeds } = useSelector((state) => state.home);
  //   console.log(`content`, feeds);
  //   const data = feeds?.filter((item) => item?._id === content_id);
  // const sharedContent = data[0];
  const user = users?.find((user) => user?._id === `${content?.userID}`);

  return (
    <div className='w-full h-full overflow-auto bg-white box-border  border border-linecolor py-2  flex flex-col'>
      <div className='pl-4 flex items-center h-1/5 '>
        <div className='flex items-center'>
          <img
            src={
              user?.profilePicture?.publicUrl ||
              `https://ui-avatars.com/api/?name=${
                content?.author || user?.firstname + ' ' + user?.lastname
              } &bold=true`
            }
            alt='user'
            className='rounded-full h-10 w-10 flex items-center justify-center object-cover mr-1'
          />
          <div className='flex flex-col pl-3.5 pr-12'>
            <div>
              <p className='flex-1 w-32 text-sm font-semibold font-body text-charcoal'>
                {content?.author || user?.firstname + ' ' + user?.lastname}
              </p>
            </div>
            <div>
              <p className='text-xs font-body text-textparagraph'>
                {content?.title || content?.postText}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full h-full text-xs font-body text-charcoal px-2 pt-2 '>
        <a
          href={
            content?.category === 'Video'
              ? `/video/watch/${content?._id}`
              : content?.category === 'Article'
              ? `/article/read/${content?._id}`
              : `/image/${content?._id}`
          }
        >
          {content?.thumbnailFile ? (
            <ContentPreview fileUrl={content?.thumbnailFile?.publicUrl} />
          ) : (
            <DefaultThumbnail text={content?.title || content?.postText} />
          )}
        </a>
      </div>
    </div>
  );
}
