export const INSTITUTION_LOADING = 'INSTITUTION_LOADING';
export const INSTITUTION_SUCCESS = 'INSTITUTION_SUCCESS';
export const INSTITUTION_ERROR = 'INSTITUTION_ERROR';
export const INSTITUTION_PROFILEPIC = 'INSTITUTION_PROFILEPIC';
export const INSTITUTION_MENTOR_CREATE = 'INSTITUTION_MENTOR_CREATE';
export const INSTITUTION_MENTOR_PROFILEPIC = 'INSTITUTION_MENTOR_PROFILEPIC';
export const YOUR_MENTOR = 'YOUR_MENTOR';
export const GET_INSTITUTIONS = 'GET_INSTITUTIONS';
export const GET_INVITED_MENTORS = 'GET_INVITED_MENTORS';
export const GET_SPECIFIC_INSTITUTION = 'GET_SPECIFIC_INSTITUTION';
export const YOUR_SPECIFIC_MENTOR = 'YOUR_SPECIFIC_MENTOR';
export const INSTITUTION_RESET = 'INSTITUTION_RESET';
export const INSTITUTION_ABOUT = 'INSTITUTION_ABOUT';
export const INSTITUTION_HOME = 'INSTITUTION_HOME';
export const INSTITUTION_MENTOR_INVITE = 'INSTITUTION_MENTOR_INVITE';
export const INSTITUTION_LEARNER = 'INSTITUTION_LEARNER';
export const ADDED_INSTITUTION_LEARNER = 'ADDED_INSTITUTION_LEARNER';
