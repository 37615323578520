import React, { Component } from 'react';

// components
import Footer from '../components/NewFooter';
import NavHeaderLanding from '../components/NewNavbar';
import bg_img from '../assets/external_css/bg1.jpg';

class JobSearch extends Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
    window.addEventListener('load', window['get_results']);
  }

  render() {
    return (
      <div>
        <div className='flex flex-col'>
          <NavHeaderLanding background={'bg-newMain'} />
          <div className='w-full h-full bg-lightgraybg'>
            <Header />
          </div>

          <div>
            <div className='w-full mb-4 sm:text-left'>
              <div
                id='list_title'
                style={{
                  textAlign: 'center',
                  paddingTop: '40px',
                  paddingBottom: '40px',
                }}
              ></div>

              <section id='jobListing'></section>
            </div>
          </div>

          <div className='w-full'>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

const Header = () => {
  return (
    <div className='relative'>
      <div className='landingBannerWrapper'>
        <div className='landingBanner'>
          <img src={bg_img} alt={bg_img} className='h-full' />

          <div className='landing-content'>
            <div className='landingBannerContent w-full mx-10 md:mx-0 md:w-1/2 flex flex-col justify-center md:justify-start mx-auto md:mx-0 md:ml-10'>
              <div className='md:ml-20 lg:ml-28 px-6 md:px-1'>
                <div className='w-full pt-40 md:pt-24 lg:pt-44 xl:pt-56'>
                  <h1 className='text-5xl center-left rethink font-bold md:text-7xl md:mr-16 lg:mr-1  my-4 Cabin'>
                    Job Search
                  </h1>
                  <h1
                    style={{ marginTop: '40px' }}
                    className='center-left mentors text-xl lg:text-3xl md:pr-4 lg:pr-10 md:px-1 font-display mb-4'
                  >
                    Your one stop source for jobs and career opportunities
                    curated from top search engines and career pages.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='block-hidden w-full formWrappersm'>
          <div className='w-full py-8 px-2 bg-white'>
            <div className='mx-auto px-2 md:px-10'>
              <form method='get'>
                <div>
                  <div
                    style={{
                      fontSize: '20px',
                      width: '40%',
                      marginTop: '14px',
                    }}
                  >
                    Search by
                  </div>

                  <div>
                    <input
                      className='searchInput w-full px-3 rounded-input Cabin'
                      placeholder='Keyword'
                      name='skeyword'
                      style={{ paddingLeft: '6%' }}
                      type='text'
                    />
                  </div>

                  <div>
                    <input
                      className='searchInput w-full px-3 rounded-input Cabin'
                      placeholder='Location'
                      name='slocation'
                      style={{ paddingLeft: '8%' }}
                      type='text'
                    />
                  </div>

                  <div style={{ width: '100%', marginTop: '16px' }}>
                    <button
                      style={{
                        backgroundColor: '#4b2aad',
                        padding: '13px 20px 13px 20px',
                        borderRadius: '25px',
                        marginTop: '-15px',
                      }}
                    >
                      <i
                        className='fas fa-lg fa-search'
                        style={{ color: '#fff' }}
                      ></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className='formWrapper w-full'>
          <div className='hidden-block w-full'>
            <div className='w-10/12 mx-auto formSubWrapper bg-white py-10'>
              <div className='topBannerForm mx-auto px-10'>
                <form method='get'>
                  <div className='flex flex-row'>
                    <div
                      className='flex flex-col'
                      style={{
                        fontSize: '20px',
                        width: '40%',
                        marginTop: '14px',
                      }}
                    >
                      Search by
                    </div>

                    <div className='flex flex-col w-full'>
                      <input
                        className='searchInput w-full px-3 rounded-input Cabin'
                        placeholder='Keyword'
                        name='skeyword'
                        style={{ paddingLeft: '6%' }}
                        type='text'
                      />
                    </div>

                    <div className='flex flex-col w-full'>
                      <input
                        className='searchInput w-full px-3 rounded-input Cabin'
                        placeholder='Location'
                        name='slocation'
                        style={{ paddingLeft: '8%' }}
                        type='text'
                      />
                    </div>

                    <div
                      className='flex flex-col'
                      style={{ width: '30%', marginTop: '16px' }}
                    >
                      <button
                        style={{
                          backgroundColor: '#4b2aad',
                          padding: '13px 20px 13px 20px',
                          borderRadius: '25px',
                          marginTop: '-15px',
                        }}
                      >
                        <i
                          className='fas fa-lg fa-search'
                          style={{ color: '#fff' }}
                        ></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSearch;
