import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { Edit, ExpandMore } from '@material-ui/icons';
import Twitter from '../assets/twitter.svg';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Room, PlayCircleFilledWhite } from '@material-ui/icons';
import * as userActions from '../redux/actions/users';
import * as institutionActions from '../redux/actions/institution';
import * as commentActions from '../redux/actions/comments';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from '../helpers';
import Truncate from 'react-truncate';
import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import { DropzoneArea } from 'material-ui-dropzone';
import ButtonLoader from '../components/ButtonLoader';
import ModalWrapper from '../components/ModalWrapper';
import ReviewItem from '../components/ReviewItem';

const InstitutionAbout = () => {
  const dispatch = useDispatch();
  const institutions = useSelector((state) => state.institution);
  const loading = useSelector((state) => state.institution?.loading);
  const user = useSelector((state) => state?.user?.user);
  const comments = useSelector((state) => state.comments.data);

  const institution = institutions?.data;
  const institute = institution.find(
    (inst) => inst.admins[0] === `${user._id}`,
  );
  const institutionID = institute?._id;

  const selectedInstitution = institution.find(
    (value) => value?._id === institutionID,
  );

  useEffect(() => {
    dispatch(institutionActions.getInstitutions({}));
    dispatch(institutionActions.getSpecificInstitution(institutionID));
    dispatch(userActions?.getUsers());
    dispatch(commentActions.getAllComments());
  }, []);

  const mentorComments = comments?.filter(
    (item) => item.idOfCommentedAbout === institutionID,
  );

  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);
  return (
    <>
      <Nav
        aboutActive={true}
        userType='institution'
        institutions={institution}
      />
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <>
          <div className='bg-lightgraybg h-full'>
            <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
                <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                  <div className='flex flex-col  container mx-auto'>
                    <div className='  lg:w-full block mt-1 text-headingtext z-20'>
                      <div className='p-8 flex  flex-col lg:flex-row justify-center lg:justify-between  bg-white rounded filter drop-shadow  md:text-left sm:text-md'>
                        <div className=' mx-auto lg:mx-0 flex flex-col lg:flex-row justify-start'>
                          <div className='mx-auto lg:mx-0 h-28 w-28  sm:h-28 sm:w-28  md:h-36 md:w-36 lg:h-48 lg:w-48  mb-4 lg:mb-0  '>
                            <img
                              src={
                                selectedInstitution?.profilePicture
                                  ?.publicUrl ||
                                `https://ui-avatars.com/api/?name=${selectedInstitution?.institutionName}&bold=true`
                              }
                              alt={selectedInstitution?.institutionName}
                              className='rounded-full object-cover h-full w-full shadow-md'
                            />
                          </div>
                          <div className='lg:2 px-4 text-center lg:text-left lg:w-8/12 w-full'>
                            <h5 className='mb-3 text-xl lg:text-5xl font-bold leading-none text-charcoal'>
                              {`${selectedInstitution?.institutionName} `}
                            </h5>
                            <p className='mb-5 text-textsecondary opacity-70 text-sm lg:text-2xl text-left'>
                              {selectedInstitution?.bio ||
                                'One of the oldest and most prestigious Universities in Africa. A top research university in Africa and beyond.'}
                            </p>
                          </div>
                        </div>

                        <div className=' px-4  sm:px-2 '>
                          <div className='flex flex-col justify-around w-full lg:w-60  h-full '>
                            <button
                              className={`inline-flex items-center justify-center w-full h-12 px-6 
                                  text-white bg-darkerblue  hover:bg-navyblue
                               font-medium tracking-wide  transition duration-200 rounded shadow-md md:w-auto  focus:shadow-outline focus:outline-none`}
                              onClick={() => {
                                setShowUpdateProfileModal(true);
                              }}
                            >
                              <Edit />

                              <span className='ml-2'>Edit Profile</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='lg:w-full block h-full text-headingtext z-10'>
                      <div className=' flex flex-col lg:flex-row justify-between  bg-white  lg:text-left text-md'>
                        <div className=' flex justify-start lg:w-8/12 '>
                          <div className='flex flex-col justify-start pt-6 lg:py-6 lg:pl-6 w-full'>
                            <TabLayout
                              institution={selectedInstitution}
                              comments={mentorComments?.reverse()}
                            />
                          </div>
                        </div>

                        <div className='lg:w-4/12 justify-around border-l-2 border-lightGrayBg2'>
                          <div className='flex lg:flex-col justify-start p-6 '>
                            <div className=' bg-upskillingAlt flex justify-center mb-9 bg-cover bg-center'>
                              <div
                                className=''
                                style={{
                                  background:
                                    'linear-gradient(0deg, rgba(0,0,0,0.83) 0%, rgba(0,0,0,0.2) 38%, rgba(255,255,255,0) 100%)',
                                }}
                              >
                                <div className='flex flex-col justify-around py-20 lg:py-48 px-12 text-white'>
                                  <span className='text-xl lg:text-3xl  font-bold text-center mb-10'>{`Career Guidance after 12th science session`}</span>
                                  <button
                                    // type='submit'
                                    className='inline-flex justify-center w-full px-2 lg:px-8 py-2 lg:py-2 border border-white font-semibold text-white text-lg lg:text-xl  transition duration-200 rounded-lg shadow-md bg-none hover:bg-white hover:text-graybg2 focus:shadow-outline focus:outline-none'
                                  >
                                    Join Session
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UpdateProfileModal
            data={selectedInstitution}
            setShowUpdateProfileModal={setShowUpdateProfileModal}
            showUpdateProfileModal={showUpdateProfileModal}
          />
        </>
      )}
    </>
  );
};

const NavigationHeader = ({ activeStatus, setActiveStatus }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let tabLabel;
  if (activeStatus === 1) tabLabel = 'Home';
  if (activeStatus === 2) tabLabel = 'About';
  if (activeStatus === 3) tabLabel = 'Reviews';

  const [selectedTab, setSelectedTab] = useState(tabLabel);
  return (
    <div className='px-2 flex items-center justify-center lg:justify-start lg:items-start w-full'>
      <ul className='w-full hidden md:flex items-center  border-b border-linecolor'>
        <li
          onClick={() => {
            setActiveStatus(1);
          }}
          className={
            activeStatus === 1
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold  text-base   leading-none text-center '
              : 'py-2 px-2 cursor-pointer  bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Home
        </li>
        <li
          onClick={() => setActiveStatus(2)}
          className={
            activeStatus === 2
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          About
        </li>
        <li
          onClick={() => setActiveStatus(3)}
          className={
            activeStatus === 3
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Reviews
        </li>
      </ul>
      <div className='md:hidden relative w-11/12 mx-auto bg-white rounded'>
        <div className='absolute inset-0 m-auto mr-4 z-0 w-6 h-6'></div>
        <div className='lg:hidden w-full flex justify-center'>
          <div className='flex flex-row lg:hidden py-1 w-full'>
            <button
              aria-label='Open Menu'
              title='Open Menu'
              className='px-2 py-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semiboldtext-base   leading-none text-center  flex flex-row w-full justify-between  transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50'
              onClick={() => setIsMenuOpen(true)}
            >
              <span className=''>{selectedTab}</span>
              <div>
                <ExpandMore fontSize='inherit' />
              </div>
            </button>
          </div>

          {isMenuOpen && (
            <div className='absolute top-0 left-0 w-full z-50'>
              <div className='p-5 bg-white border rounded shadow-sm'>
                <nav>
                  <ul className='space-y-4'>
                    <li
                      onClick={() => {
                        setActiveStatus(1);
                        setIsMenuOpen(false);
                        setSelectedTab('Home');
                      }}
                      className={
                        activeStatus === 1
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold  text-base   leading-none text-center '
                          : 'py-2 px-2 cursor-pointer  bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      Home
                    </li>
                    <li
                      onClick={() => {
                        setActiveStatus(2);
                        setIsMenuOpen(false);
                        setSelectedTab('About');
                      }}
                      className={
                        activeStatus === 2
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-main text-darkerblue font-semibold    text-base   leading-none text-center'
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
                      }
                    >
                      About
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
        <form>
          <select
            aria-label='Selected tab'
            className='form-select block w-full p-3 border border-charcoal rounded text-gray5s font-semibold appearance-none bg-white relative z-10 mb-8'
          >
            <option
              selected
              className='text-base text-gray5'
              onClick={(e) => {
                e.preventDefault();
                setActiveStatus(1);
              }}
            >
              Home
            </option>
            <option
              className='text-base text-gray5'
              onClick={(e) => {
                e.preventDefault();
                setActiveStatus(2);
              }}
            >
              About{' '}
            </option>
            <option
              className='text-base text-gray5'
              onClick={(e) => {
                e.preventDefault();
                setActiveStatus(3);
              }}
            >
              Reviews
            </option>
          </select>
        </form>
      </div>
    </div>
  );
};

const TabLayout = ({ institution, comments }) => {
  const { institutionID } = useParams();
  const [activeStatus, setActiveStatus] = useState(1);

  const dispatch = useDispatch();
  const content = useSelector((state) => state.content.data);
  const institutions = useSelector((state) => state?.institution?.data);

  const selectedInstitution = institutions.filter(
    (institute) => institute._id === institutionID,
  );

  const adminId = selectedInstitution[0]?.admins[0];

  // const mentor = useSelector((state) => state.mentors.mentor);
  const mentors = useSelector((state) => state?.user?.data);

  useEffect(() => {
    dispatch(userActions.getMentors({}));
  }, []);
  const mentorss = [];
  mentors?.map((value) => mentorss.push(value));
  const mentor = mentorss.find((ment) => ment._id === `${institutionID}`);
  const users = useSelector((state) => state?.user?.data);

  const myContent = content?.filter((item) => item.userID === adminId);
  return (
    <>
      <div className='text-center lg:text-left'>
        <NavigationHeader
          activeStatus={activeStatus}
          setActiveStatus={setActiveStatus}
        />
        {activeStatus === 1 && (
          <Home
            mentor={mentor}
            myContent={myContent}
            users={users}
            setActiveStatus={setActiveStatus}
            institution={institution}
          />
        )}
        {activeStatus === 2 && <About institution={institution} />}
        {activeStatus === 3 && (
          <Reviews
            institution={institution}
            comments={comments}
            userData={users}
          />
        )}
      </div>
    </>
  );
};

const About = ({ institution }) => {
  return (
    <div className='max-w-full  lg:max-w-full md:mb-12 pt-4'>
      <div className='border-b border-linecolor pb-10 mt-6'>
        <h1 className='font-bold text-charcoal mb-3'>Overview</h1>
        <span className='font-base text-charcoal'>{institution?.bio}</span>
      </div>
      <div className=' flex flex-col justify-around py-2 lg:p-8 rounded-lg mb-9'>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Type</span>
          <div className=' lg:w-9/12'>
            <span className='font-base text-charcoal  lg:w-9/12'>
              {`Education`}
            </span>
          </div>
        </div>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Specialities</span>
          <div className=' lg:w-9/12'>
            <span className='font-base text-charcoal  lg:w-9/12'>
              {institution?.specialities?.map((value) => (
                <span className='lg:mr-1' key={value}>
                  {value}
                  {', '}
                </span>
              ))}
            </span>
          </div>
        </div>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Location</span>
          <div className=' lg:w-9/12'>
            <div className='py-1 px-3 lg:py-2 lg:px-6 font-base text-lightBlue bg-linecolor rounded-lg w-max'>
              <Room fontSize='small' />{' '}
              <span className='ml-2'>
                {' '}
                {institution?.location || `Kampala, Uganda`}
              </span>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Website</span>
          <div className='ml-4 lg:ml-0 lg:w-9/12'>
            <a
              href='/'
              className='font-base text-darkerblue lg:w-9/12 text-right'
            >
              {institution?.website || 'Not Avaliable'}
            </a>
          </div>
        </div>

        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Social Media</span>
          <div className=' lg:w-9/12'>
            <div className='flex justify-start'>
              <a href='/'>
                <img src={Twitter} alt='twitter-link' className='pr-6' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Reviews = ({ setShowReviewModal, isMentor, comments }) =>
{
  const institutions = useSelector(state => state?.institution.data)
  const [slice, setSlice] = useState(5);
  const handleMoreReview = () => {
    setSlice(slice + 4);
  };
  return (
    <>
      {' '}
      <div className='flex flex-col mt-4 px-2'>
        <div className='w-full flex lg:flex-row flex-col justify-between pt-6 pb-2'>
          <span className='text-lg text-gray font-semibold px-2  mb-4 '>
            {' '}
            Recent reviews from users on Delv.
          </span>
        </div>
        <div className='flex lg:justify-start pb-4'>
          <select className='appearance-none ainline-flex justify-center w-max  px-2 lg:px-6 py-3 font-normal text-headingtext text-sm focus:outline-noone border border-lightGrayBg2  focus:border-linecolor lg:text-base transition duration-200 rounded-lg bg-lightGrayBg2 hover:bg-white hover:text-graybg2 focus:shadow-outline focus:outline-none'>
            <option>Sort By: Time</option>
            <option>Rating Ascending</option>
            <option>Rating Descending</option>
          </select>
        </div>
        <div>
          {comments?.slice(0, slice)?.map((user, key) => (
            <ReviewItem key={user._id} data={user} institutions={institutions}/>
          ))}
        </div>
        <div className='flex justify-center py-3'>
          <span
            className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
            onClick={() => handleMoreReview()}
            disabled={slice >= comments?.length}
          >
            {slice >= comments?.length
              ? 'No more reviews'
              : 'Show more reviews'}
          </span>
        </div>
      </div>
    </>
  );
};
const Home = ({
  setActiveStatus,
  institution,
  myContent,
  users,
  setSelectedPost,
  setShowSendPost,
  setShowCommentPost,
  setShowSharePost,
}) => {
  const history = useHistory();
  const [hideDescription, toggleHideDescription] = useToggle(true);
  const latestVideo = myContent.filter((item) => item?.category === 'Video');
  let length = latestVideo.length > 0 ? latestVideo.length - 1 : 0;
  const video = latestVideo[length];
  return (
    <div className='max-w-full  lg:max-w-full md:mb-12 pt-4'>
      <div className='border-b border-linecolor pb-10 mt-6'>
        <h1 className='font-bold text-charcoal mb-3'>About</h1>
        <span className='font-base text-charcoal'>
          {hideDescription && (
            <Truncate lines={3} ellipsis={<span>...</span>}>
              {institution?.bio}
            </Truncate>
          )}
        </span>
        <span className='font-base text-charcoal ' hidden={hideDescription}>
          {institution?.bio}
        </span>
        <span
          className='cursor-pointer text-main font-semibold hover:text-navyblue px-4'
          aria-label='see more'
          aria-controls='see more'
          aria-haspopup='true'
          onClick={() => toggleHideDescription()}
        >
          see {hideDescription ? 'more' : 'less'}
        </span>
        <p
          className='text-center font-semibold text-darkerblue hover:text-navyblue cursor-pointer pt-8 hover:underline'
          onClick={() => {
            setActiveStatus(2);
          }}
        >
          Show more details
        </p>
      </div>

      <div className='border-b border-linecolor pb-10 mt-6'>
        <h1 className='font-bold text-charcoal mb-3'>Recently Posted Videos</h1>
        <div className='flex flex-col lg:flex-row justify-between'>
          <div className='relative w-full lg:w-3/6 transition-shadow duration-300 hover:shadow-xl'>
            <img
              className='object-cover w-full h-48 rounded shadow-lg'
              src={video?.thumbnailFile?.publicUrl}
              alt='Video Thumb'
            />
            <span
              onClick={() => history.push(`/video/watch/${video?._id}`)}
              aria-label='Play Video'
              className='cursor-pointer absolute inset-0 flex items-center justify-center w-full h-full transition-colors duration-300 bg-charcoal bg-opacity-50 group hover:bg-opacity-25'
            >
              <div className='flex items-center justify-center text-white text-5xl w-16 h-16 transition duration-300 transform rounded-full shadow-2xl group-hover:scale-110'>
                <PlayCircleFilledWhite fontSize='inherit' />
              </div>
            </span>
          </div>

          <div className='w-full lg:w-3/6 mx-auto px-4'>
            <span className='font-base text-charcoal '>
              {video?.description}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const UpdateProfileModal = ({
  setShowUpdateProfileModal,
  showUpdateProfileModal,
  data,
  loading,
  success,
}) => {
  const currentSpecialities = [];
  data?.specialities.forEach((item) => {
    currentSpecialities.push({
      id: item,
      text: item,
    });
  });
  const [specialities, setSpecialities] = useState(currentSpecialities);
  const [profilePicture, setProfilePicture] = useState([]);

  const initialValues = {
    website: data?.website,
    city: data?.city,
    linkFacebook: data?.linkFacebook,
    country: data?.country,
    institutionType: '',
    institutionName: data?.institutionName,
    areacode: data?.area,
    postalcode: data?.postalcode,
    linkTwitter: data?.linkTwitter,
    linkLinkedin: data?.linkLinkedin,
    bio: data?.bio,
  };

  const validationSchema = Yup.object({
    country: Yup.string().required('Country is required'),
    city: Yup.string().required('City is required'),
    website: Yup.string(),
    institutionName: Yup.string().required('Institution name is required'),
    institutionType: Yup.string().required('Institution type is required'),
    linkFacebook: Yup.string(),
    linkLinkedin: Yup.string(),
    linkTwitter: Yup.string(),
    bio: Yup.string().required('Institution Bio is required'),
  });

  const KeyCodes = {
    tab: 9,
    enter: 13,
    // space: 32,
    comma: 188,
  };
  const delimiters = [
    KeyCodes.tab,
    KeyCodes.enter,
    // KeyCodes.space,
    KeyCodes.comma,
  ];

  const handleDelete = (i) => {
    const newTags = specialities.filter((tag, index) => index !== i);
    setSpecialities(newTags);
  };

  const handleAddition = (tag) => {
    setSpecialities([...specialities, tag]);
  };

  const onSubmit = (values, onSubmitProps) => {
    // const tagsText = specialities.map((tag) => tag.text);
    // const payload = {};
    console.log(`profilePicture`, profilePicture);
    // const myForm = new FormData();

    onSubmitProps.resetForm();
    setSpecialities([]);
    setProfilePicture([]);
  };

  return (
    <>
      {showUpdateProfileModal ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, getFieldProps, isValid }) => (
              <Form>
                <ModalWrapper
                  title={'Update Institution profile'}
                  closeModal={() => setShowUpdateProfileModal(false)}
                  bodyContent={
                    <div className='relative'>
                      <div className='pt-6'>
                        <h3 className='uppercase font-body text-textparagraph font-semibold '>
                          Institution name
                        </h3>
                        <input
                          type='text'
                          className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                          id='phone_number'
                          name='institutionName'
                          {...getFieldProps('institutionName')}
                        />
                        {touched.institutionName && errors.institutionName ? (
                          <span className='text-sm text-red'>
                            {errors.institutionName}
                          </span>
                        ) : null}
                      </div>
                      <div className='grid sm:grid-cols-2 grid-cols-1 gap-4 pt-6'>
                        {' '}
                        <div className='form-control'>
                          <h3 className='uppercase font-body text-textparagraph font-semibold '>
                            country
                          </h3>
                          <input
                            type='text'
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='country'
                            name='country'
                            {...getFieldProps('country')}
                          />
                          {touched.country && errors.country ? (
                            <span className='text-sm text-red'>
                              {errors.country}
                            </span>
                          ) : null}
                        </div>
                        <div className='form-control'>
                          <h3 className='uppercase font-body text-textparagraph font-semibold'>
                            City
                          </h3>
                          <input
                            type='text'
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='city'
                            ml-12
                            name='city'
                            {...getFieldProps('city')}
                          />
                          {touched.city && errors.city ? (
                            <span className='text-sm text-red'>
                              {errors.city}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className='pt-6'>
                        <h3 className='uppercase font-body text-textparagraph font-semibold '>
                          Website
                        </h3>
                        <input
                          type='text'
                          className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                          id='website'
                          name='website'
                          {...getFieldProps('website')}
                        />
                        {touched.website && errors.website ? (
                          <span className='text-sm text-red'>
                            {errors.website}
                          </span>
                        ) : null}
                      </div>
                      <h3 className='uppercase font-body text-textparagraph font-semibold  pt-6 pb-2'>
                        Social Media Links
                      </h3>
                      <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 '>
                        {' '}
                        <div className='form-control'>
                          <h3 className='uppercase font-body text-textparagraph font-semibold '>
                            Facebook
                          </h3>
                          <input
                            type='text'
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='linkFacebook'
                            name='linkFacebook'
                            {...getFieldProps('linkFacebook')}
                          />
                          {touched.linkFacebook && errors.linkFacebook ? (
                            <span className='text-sm text-red'>
                              {errors.linkFacebook}
                            </span>
                          ) : null}
                        </div>
                        <div className='form-control'>
                          <h3 className='uppercase font-body text-textparagraph font-semibold'>
                            LinkedIn
                          </h3>
                          <input
                            type='text'
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='linkLinkedin'
                            ml-12
                            name='linkLinkedin'
                            {...getFieldProps('linkLinkedin')}
                          />
                          {touched.linkLinkedin && errors.linkLinkedin ? (
                            <span className='text-sm text-red'>
                              {errors.linkLinkedin}
                            </span>
                          ) : null}
                        </div>
                        <div className='form-control'>
                          <h3 className='uppercase font-body text-textparagraph font-semibold'>
                            Twitter
                          </h3>
                          <input
                            type='text'
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='linkTwitter'
                            ml-12
                            name='linkTwitter'
                            {...getFieldProps('linkTwitter')}
                          />
                          {touched.linkTwitter && errors.linkTwitter ? (
                            <span className='text-sm text-red'>
                              {errors.linkTwitter}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className='pt-6'>
                        <h3 className='uppercase font-body text-textparagraph font-semibold'>
                          Type
                        </h3>
                        <select
                          className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                          id='institutionType'
                          name='institutionType'
                          {...getFieldProps('institutionType')}
                        >
                          <option value='select'>Select a type</option>
                          <option value='Higher Education'>
                            Higher Education
                          </option>
                          <option value='Education / Learning'>
                            Education / Learning
                          </option>
                          <option value='Finance'>Finance</option>
                          <option value='Manufacturing'>Manufacturing</option>
                          <option value='Commerce / trade'>
                            Commerce / trade
                          </option>
                          <option value='Distribution'>Distribution</option>
                          <option value='Information Technology'>
                            Information Technology
                          </option>
                          <option value='Health care / Medicine'>
                            Health care / Medicine
                          </option>
                          <option value='Construction'>Construction</option>
                          <option value='Media'>Media</option>
                          <option value='Banking'>Banking</option>
                          <option value='Social Entreprise'>
                            Social Entreprise
                          </option>
                          <option value='Other'>Other</option>
                        </select>
                        {touched.institutionType && errors.institutionType ? (
                          <span className='text-sm text-red'>
                            {errors.institutionType}
                          </span>
                        ) : null}
                      </div>

                      <div className='pt-6'>
                        <h3 className='uppercase font-body text-textparagraph font-semibold pb-2'>
                          SPECIALITIES
                        </h3>
                        <p className='font-body text-textparagraph font-normal mb-2 '>
                          Comma-separated list of your skills (keep it below
                          10).
                        </p>
                        <ReactTags
                          tags={specialities}
                          suggestions={[]}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          delimiters={delimiters}
                          autocomplete={true}
                          placeholder={
                            'Enter a collection of your specialities'
                          }
                          maxLength={40}
                          classNames={{
                            tagInputField:
                              'px-3 py-3 text-charcoal relative bg-none rounded text-sm border-b border-linecolor outline-none focus:outline-noone  focus:border-darkerblue w-full',
                          }}
                        />
                      </div>

                      <div className='pt-6'>
                        <h3 className='font-body text-textparagraph font-semibold pb-4'>
                          Institution BIO
                        </h3>
                        <textarea
                          className='flex-grow w-full p-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                          id='bio'
                          name='bio'
                          placeholder={
                            'Provide a description of your institution...'
                          }
                          rows='8'
                          {...getFieldProps('bio')}
                        />
                        {touched.bio && errors.bio ? (
                          <span className='text-sm text-red'>{errors.bio}</span>
                        ) : null}
                      </div>
                      <div className='py-6'>
                        <h3 className='uppercase font-body text-textparagraph font-semibold'>
                          PROFILE PICTURE
                        </h3>

                        <p className='font-body pt-3.5 pb-3 text-textparagraph font-normal'>
                          Image should be at least 400x400 pixels big.
                        </p>

                        <div>
                          <DropzoneArea
                            filesLimit={1}
                            showAlerts={false}
                            maxFileSize={5000000}
                            acceptedFiles={['image/*']}
                            onChange={(files) => setProfilePicture(files)}
                          />
                        </div>
                      </div>
                    </div>
                  }
                  footer={true}
                  footerContent={
                    <button
                      className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                      type='submit'
                      disabled={!isValid || loading}
                    >
                      {loading ? <ButtonLoader /> : <> Save Changes </>}
                    </button>
                  }
                />
              </Form>
            )}
          </Formik>
        </>
      ) : null}
    </>
  );
};

export default InstitutionAbout;
