import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Edit,
  LocalLibrary,
  MenuBook,
  School,
  Clear,
  VideoCall,
  Description,
  InsertDriveFileRounded,
  Cached,
} from '@material-ui/icons';

import Loading from './Loading';
import ReactSwitch from './Switch';
import DefaultThumbnail from './DefaultThumbnail';
import {
  UploadNewContent,
  UpdateContentFile,
  UpdateContentDetails,
} from './UploadContent';

import * as helpers from '../helpers';
import * as contentActions from '../redux/actions/content';

const ContentTableRow = (props) => {
  const dispatch = useDispatch();
  const [switchState, setSwitchState] = useState(true);
  const { content } = props;

  const demoToggle = () => {
    setSwitchState(!switchState);
  };

  const resourceURL = () => {
    if (content.category === 'Video') return `/video/watch/${content?._id}`;
    if (content.category === 'Article') return `/article/read/${content?._id}`;
    if (content.category === 'Image') return `'/image/${content?._id}`;
  };

  return (
    <tr className='border-b-2 border-linecolor mx-4 text-sm pr-6 text-center font-bold whitespace-normal text-black tracking-normal leading-4 cursor-pointer'>
      <td className=' w-36 text-left whitespace-no-wrap  text-gray tracking-normal leading-4'>
        <div className='relative p-2  h-16 w-16 sm:h-16 sm:w-24 md:h-16 md:w-32  lg:h-28 lg:w-36 sm:p-2'>
          <a href={resourceURL()}>
            {content?.thumbnailFile?.publicUrl ? (
              <img
                src={content?.thumbnailFile?.publicUrl}
                alt={content?.title}
                className='object-cover w-full h-full'
              />
            ) : (
              <div className='object-cover w-full h-full'>
                <DefaultThumbnail text={content.title} />
              </div>
            )}
          </a>
        </div>
      </td>
      <td className=' text-left'>{content?.title}</td>
      <td className=''>{helpers.dateFormatter(content?.createdAt)}</td>
      <td className=''>{content?.view || 0}</td>
      <td className=''>{content?.comments?.length || 0}</td>
      <td className='py-4 my-2 '>
        <ReactSwitch
          toggleFunction={demoToggle}
          toggleState={content.visible || true}
          height={18}
          width={48}
        />
      </td>
      <td className='pr-8 relative'>
        <a href={resourceURL()}>
          <button
            title={`View ${content.category}`}
            className='ml-2 p-1 m-1 text-white bg-buttonGreen rounded-md cursor-pointer hover:bg-navyblue'
          >
            <InsertDriveFileRounded style={{ fontSize: '18px' }} />
          </button>
        </a>
        <button
          title={`Edit ${content.category} details`}
          onClick={() => {
            props.setContent(content);
            props.setShowUpdateDetailsModal(true);
          }}
          className='ml-2 p-1 m-1 text-white bg-starsorange rounded-md cursor-pointer hover:bg-navyblue'
        >
          <Edit style={{ fontSize: '18px' }} />
        </button>
        <button
          title={`Update ${content.category}`}
          onClick={() => {
            props.setContent(content);
            props.setShowUpdateFileModal(true);
          }}
          className='ml-2 p-1 m-1 text-white bg-starsorange rounded-md cursor-pointer hover:bg-navyblue'
        >
          <Cached style={{ fontSize: '18px' }} />
        </button>
        <button
          title={`Delete ${content.category}!`}
          className='ml-2 p-1 m-1 text-white bg-buttonRed rounded-md cursor-pointer hover:bg-navyblue'
          onClick={() => dispatch(contentActions.deleteContent(content))}
        >
          <Clear style={{ fontSize: '18px' }} />
        </button>
      </td>
    </tr>
  );
};

export default function ContentTable({
  count,
  loadPage,
  myContent,
  contentType,
  userType,
}) {
  const [contentToEdit, setContent] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showUpdateFileModal, setShowUpdateFileModal] = useState(false);
  const [showUpdateDetailsModal, setShowUpdateDetailsModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {' '}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <>
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
              <div className='flex flex-col mb-2 lg:justify-between lg:flex-row md:mb-4'>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>
                    {userType ? `Institution ` : 'My '}
                    {contentType}s ({myContent?.length})
                  </span>
                  <div className='h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100' />
                </h2>
                <div className='mb-6 text-center md:mb-4 lg:mb-4'>
                  <button
                    className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'
                    onClick={() => setShowUploadModal(true)}
                  >
                    {contentType === 'Video' ? <VideoCall /> : null}
                    {contentType === 'IndividualCourse' ? <School /> : null}
                    {contentType === 'Article' ? <Description /> : null}
                    {contentType === 'Book' ? <MenuBook /> : null}
                    {contentType === 'Course' ? <School /> : null}
                    {contentType === 'Open Librarie' ? <LocalLibrary /> : null}
                    <span className='ml-2'>Upload</span>
                  </button>
                </div>
              </div>
              {/* Content Table */}
            </div>
          </div>

          <div className='mx-auto sm:w-screen md:w-full lg:max-w-screen-xl lg:px-8'>
            <div className=' pb-4 container mx-auto sm:max-w-full sm:px-2 md:max-w-full lg:max-w-full md:px-8 lg:px-8 '>
              <div className='flex flex-col mb-6 lg:justify-between md:mb-8 shadow  overflow-x-scroll xl:overflow-x-hidden'>
                <table className='min-w-full bg-white '>
                  <thead>
                    <tr className='w-full h-16 border-linecolor  border-b py-8'>
                      <th className='text-left'></th>
                      <th className='text-gray font-bold pr-6 text-left text-base tracking-normal leading-4'>
                        Title
                      </th>
                      <th className='text-gray dark:text-gray-400 font-bold pr-6 text-center text-base tracking-normal leading-4'>
                        Date
                      </th>
                      <th className='text-gray dark:text-gray-400 font-bold pr-6 text-center text-base tracking-normal leading-4'>
                        Views
                      </th>
                      <th className='text-gray dark:text-gray-400 font-bold pr-6 text-center text-base tracking-normal leading-4'>
                        Comments
                      </th>
                      <th className='text-gray dark:text-gray-400 font-bold pr-6 text-center text-base tracking-normal leading-4'>
                        Publish
                      </th>
                      <th className='text-gray dark:text-gray-400 font-bold pr-8 text-center text-base tracking-normal leading-4'>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {myContent?.map((value) => (
                      <ContentTableRow
                        content={value}
                        key={value?._id}
                        setContent={setContent}
                        setShowUpdateFileModal={setShowUpdateFileModal}
                        setShowUpdateDetailsModal={setShowUpdateDetailsModal}
                      />
                    ))}
                  </tbody>
                </table>
                <div className='py-1'>
                  <div className='  bg-white  '>
                    <div className='w-full overflow-x-scroll xl:overflow-x-hidden'></div>
                    <div className='flex flex-col lg:flex-row p-4 justify-between items-start w-full'>
                      <div className='w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center'>
                        {/* Space between  */}
                        <div className='flex items-center'> </div>
                      </div>
                      <div className='w-full lg:w-2/3 flex flex-col lg:flex-row items-start lg:items-center justify-end'>
                        <div className='flex py-3 lg:py-0 mr-5'>
                          <p
                            className='text-base text-right text-gray'
                            id='page-view'
                          >
                            `Viewing {myContent?.length > 0 ? skip + 1 : 0} -`
                            {count > 30 ? skip + 30 : myContent?.length} of{' '}
                            {count || 0}
                          </p>
                        </div>
                        <div className='flex items-center py-3 lg:py-0 lg:px-6 mr-5'>
                          {skip > 30 ? (
                            <button
                              onClick={() => {
                                if (skip > 30) setSkip(skip - 32);
                                loadPage(skip, 30);
                              }}
                              className='text-gray dark:text-gray-400 ml-2 cursor-pointer rounded hover:text-navyblue'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='icon icon-tabler icon-tabler-chevrons-left'
                                width={20}
                                height={20}
                                viewBox='0 0 24 24'
                                strokeWidth='1.5'
                                stroke='currentColor'
                                fill='none'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' />
                                <polyline points='15 6 9 12 15 18' />
                              </svg>
                            </button>
                          ) : null}
                          {skip < count ? (
                            <>
                              <button
                                onClick={() => {
                                  setSkip(skip + 30);
                                  loadPage(skip, 30);
                                }}
                                className='text-gray dark:text-gray-400 ml-8 cursor-pointer rounded hover:text-navyblue'
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='icon icon-tabler icon-tabler-chevron-right'
                                  width={20}
                                  height={20}
                                  viewBox='0 0 24 24'
                                  strokeWidth='1.5'
                                  stroke='currentColor'
                                  fill='none'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' />
                                  <polyline points='9 6 15 12 9 18' />
                                </svg>
                              </button>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <UploadNewContent
                contentType={contentType}
                showUploadContent={showUploadModal}
                setShowUploadContent={setShowUploadModal}
              />
              <UpdateContentFile
                content={contentToEdit}
                showUpdateContentFile={showUpdateFileModal}
                setShowUpdateContentFile={setShowUpdateFileModal}
              />
              <UpdateContentDetails
                content={contentToEdit}
                showUpdateContentDetails={showUpdateDetailsModal}
                setShowUpdateContentDetails={setShowUpdateDetailsModal}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
