import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { VideoLibrary } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import VideoCard from '../components/VideoCard';

import * as contentActions from '../redux/actions/content';
import NoContentPlaceholder from '../components/NoContentPlaceholder';

const PageBody = () => {
  const dispatch = useDispatch();
  const { data, loading, error, errorMessage, count } = useSelector(
    (state) => state.content,
  );
  const [skip, setSkip] = useState(0);
  const limit = 32;

  const [videoName, setVideoName] = useState('');
  const [myFilteredVideo, setMyFilteredVideos] = useState([]);

  useEffect(() => {
    dispatch(contentActions.getVideos({ limit }));
  }, []);

  useEffect(() => {
    if (error) console.log(errorMessage);
  }, [error]);

  useEffect(() => {
    if (videoName !== '') {
      let myFilteredVideoArray = [];
      data
        ?.filter((item) => item.title.toLowerCase().includes(videoName))
        .map((item) => {
          return myFilteredVideoArray.push(item);
        });
      setMyFilteredVideos(myFilteredVideoArray);
    } else {
      setMyFilteredVideos(data);
    }
  }, [videoName]);

  return (
    <>
      {' '}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div
          className={`bg-lightgraybg w-full ${
            data.length < 5 ? 'h-screen' : 'h-full'
          }`}
        >
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
              <div className='flex flex-col lg:justify-between lg:flex-row'>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4 font-display'>
                    All Videos (
                    {myFilteredVideo.length === 0
                      ? count
                      : myFilteredVideo.length}
                    )
                  </span>
                </h2>
                <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                  <Link to='/videos/mylist'>
                    <button className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'>
                      <VideoLibrary />
                      <span className='ml-2 font-display'>My Videos</span>
                    </button>
                  </Link>
                </div>
              </div>

              <input
                value={videoName}
                onChange={(e) => setVideoName(e.target.value)}
                style={{ border: '1px solid grey', width: '33%' }}
                className='rounded-input px-6 md:mb-8 mb-8'
                placeholder='Search Videos'
                type='search'
              />

              {myFilteredVideo.length === 0 ? (
                <VideoCategory videos={data} />
              ) : (
                <VideoCategory videos={myFilteredVideo} />
              )}

              <div>
                `Viewing {data.length > 0 ? skip + 1 : 0} -`
                {count > limit ? skip + limit : data.length} of {count || 0}`
              </div>
              {skip > 30 ? (
                <button
                  onClick={() => {
                    if (skip > 30) setSkip(skip - limit);
                    dispatch(
                      contentActions.getArticles({ skip, limit: limit }),
                    );
                  }}
                >
                  {'<< Previous'}
                </button>
              ) : null}
              {skip + limit < count ? (
                <>
                  <span> | </span>
                  <button
                    onClick={() => {
                      setSkip(skip + limit);
                      dispatch(
                        contentActions.getArticles({ skip, limit: limit }),
                      );
                    }}
                  >
                    {'Next >>'}
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const VideoCategory = ({ videos }) => {
  return (
    <>
      <div className='flex flex-col mb-6 lg:justify-between md:mb- shadow '>
        {videos.length > 0 ? (
          <div className='grid grid-cols-1 gap-6 row-gap-4 mb-8 lg:grid-cols-4 sm:grid-cols-2 font-display'>
            {videos?.map((video) => (
              <VideoCard video={video} key={video?._id} />
            ))}
          </div>
        ) : (
          <NoContentPlaceholder content='Videos' generic />
        )}
      </div>
    </>
  );
};

export default function data() {
  return (
    <>
      <Nav videosActive={true} />
      <PageBody />
    </>
  );
}
