import * as homeTypes from '../types/home';
import * as fetchFunctions from '../../api/index';
import homeEndpoints from '../endpoints/home';

export const getFeeds =
  ({ skip = 0, limit = 30 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: homeTypes.HOME_LOADING,
    });
    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${homeEndpoints.get_feeds}/?skip=${skip}&limit=${limit}`,
      token,
    );

    if (result.success) {
      dispatch({
        type: homeTypes.HOME_FEEDS,
        payload: result.data,
      });
      dispatch({
        type: homeTypes.HOME_RESET,
      });
    } else {
      dispatch({
        type: homeTypes.HOME_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };
