import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LibraryBooks,
  LocalLibrary,
  MenuBook,
  School,
  VideoLibrary,
} from '@material-ui/icons';

import Loading from '../components/Loading';
import Nav from '../components/NavBarR';

// Actions
import * as contentActions from '../redux/actions/content';
import * as institutionActions from '../redux/actions/institution';

import { CarouselController } from '../components/CarouselController';
import LibraryContentCard from '../components/LibraryContentCard';
import { Link } from 'react-router-dom';
import NoContentPlaceholder from '../components/NoContentPlaceholder';

const PageBody = () => {
  const PageLoading = useSelector((state) => state.content.loading);
  const dispatch = useDispatch();
  const myContent = useSelector((state) => state.content.myContent);
  const error = useSelector((state) => state.content.error);
  const errorMessage = useSelector((state) => state.content.errorMessage);

  useEffect(() => {
    if (error) console.log(errorMessage);
  }, [error]);

  useEffect(() => {
    dispatch(contentActions.getMyContent());
    dispatch(institutionActions.getInstitutions({}));
  }, []);

  const myVideos = [];
  myContent?.map((value) =>
    value?.category === 'Video' ? myVideos.push(value) : null,
  );

  const myArticles = [];
  myContent?.map((value) =>
    value?.category === 'Article' ? myArticles.push(value) : null,
  );
  const myBooks = [];
  myContent?.map((value) =>
    value?.category === 'Book' ? myBooks.push(value) : null,
  );
  const myCourses = [];
  myContent?.map((value) =>
    value?.category === 'Course' ? myCourses.push(value) : null,
  );
  const myOpenlibs = [];
  myContent?.map((value) =>
    value?.category === 'OpenLib' ? myOpenlibs.push(value) : null,
  );

  const institutions = useSelector((state) => state.institution);
  const institution = institutions?.data;

  return (
    <>
      <Nav
        libraryActive={true}
        userType='institution'
        institutions={institution}
      />
      {PageLoading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div className='bg-lightgraybg h-full'>
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:pt-14'>
              <div className='flex flex-col  lg:justify-between '>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>Overview</span>
                </h2>
                <div className='relative grid gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mb-6 lg:mb-2'>
                  <OverviewCard
                    itemName='Courses'
                    icon={<School fontSize={'inherit'} />}
                    itemSize={myCourses?.length || 0}
                  />
                  <OverviewCard
                    itemName='Articles'
                    icon={<LibraryBooks fontSize={'inherit'} />}
                    itemSize={myArticles?.length || 0}
                  />

                  <OverviewCard
                    itemName='Books'
                    icon={<MenuBook fontSize={'inherit'} />}
                    itemSize={myBooks?.length || 0}
                  />
                  <OverviewCard
                    itemName='Videos'
                    icon={<VideoLibrary fontSize={'inherit'} />}
                    itemSize={myVideos?.length || 0}
                  />
                  <OverviewCard
                    itemName='Open Libraries'
                    icon={<LocalLibrary fontSize={'inherit'} />}
                    itemSize={myOpenlibs?.length || 0}
                  />
                </div>
              </div>
            </div>
            <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:pt-14'>
              <div className='flex flex-col  md:justify-between md:flex-row '>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>
                    Videos ({myVideos?.length || 0})
                  </span>
                </h2>
                <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                  <Link to={'/institution/videos'}>
                    <button className='lg:w-32 inline-flex items-center justify-center w-full h-12  font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'>
                      <span className='mx-auto'>
                        {myVideos?.length === 0 ? 'Add video' : 'View all'}
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
              {myVideos?.length > 0 ? (
                <CarouselController
                  type='default'
                  content={myVideos.map((value, key) => (
                    <LibraryContentCard
                      content={value}
                      type='Video'
                      key={key}
                    />
                  ))}
                />
              ) : (
                <NoContentPlaceholder content={'Videos'} generic />
              )}
            </div>
            <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:pt-14'>
              <div className='flex flex-col  md:justify-between md:flex-row '>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>
                    Articles ({myArticles?.length || 0})
                  </span>
                </h2>
                <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                  <Link to={'/institution/articles'}>
                    <button className='lg:w-32 inline-flex items-center justify-center w-full h-12  font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'>
                      <span className='mx-auto'>
                        {myArticles?.length === 0 ? 'Add Article' : 'View all'}
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
              {myArticles?.length > 0 ? (
                <CarouselController
                  type='default'
                  content={myArticles.map((value, key) => (
                    <LibraryContentCard
                      content={value}
                      type='Article'
                      key={key}
                    />
                  ))}
                />
              ) : (
                <NoContentPlaceholder content={'Article'} generic />
              )}
            </div>
            <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:pt-14'>
              <div className='flex flex-col  md:justify-between md:flex-row '>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>
                    Courses ({myCourses?.length || 0})
                  </span>
                </h2>
                <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                  <Link to={'/institution/courses'}>
                    <button className='lg:w-32 inline-flex items-center justify-center w-full h-12  font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'>
                      <span className='mx-auto'>
                        {myCourses?.length === 0 ? 'Add Course' : 'View all'}
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
              {myCourses?.length > 0 ? (
                <CarouselController
                  type='default'
                  content={myCourses.map((value, key) => (
                    <LibraryContentCard
                      content={value}
                      type='Course'
                      key={key}
                    />
                  ))}
                />
              ) : (
                <NoContentPlaceholder content={'Course'} generic />
              )}
            </div>
            <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:pt-14'>
              <div className='flex flex-col  md:justify-between md:flex-row '>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>
                    Books ({myBooks?.length || 0})
                  </span>
                </h2>
                <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                  <Link to={'/institution/books'}>
                    <button className='lg:w-32 inline-flex items-center justify-center w-full h-12  font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'>
                      <span className='mx-auto'>
                        {myBooks?.length === 0 ? 'Add Book' : 'View all'}
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
              {myBooks?.length > 0 ? (
                <CarouselController
                  type='default'
                  content={myBooks.map((value, key) => (
                    <LibraryContentCard content={value} type='Book' key={key} />
                  ))}
                />
              ) : (
                <NoContentPlaceholder content={'Book'} generic />
              )}
            </div>
            <div className='px-2  container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:pt-14'>
              <div className='flex flex-col  md:justify-between md:flex-row '>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>
                    Open Library Resources ({myOpenlibs?.length || 0})
                  </span>
                </h2>
                <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                  <Link to={'/institution/openlibrary'}>
                    <button className='lg:w-32 inline-flex items-center justify-center w-full h-12  font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'>
                      <span className='mx-auto'>
                        {myOpenlibs?.length === 0 ? 'Add Resource' : 'View all'}
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
              {myOpenlibs?.length > 0 ? (
                <CarouselController
                  type='default'
                  content={myOpenlibs.map((value, key) => (
                    <LibraryContentCard
                      content={value}
                      type='OpenURL'
                      key={key}
                    />
                  ))}
                />
              ) : (
                <NoContentPlaceholder
                  content={'Open Libray Resources'}
                  generic
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default function Library() {
  return (
    <>
      <PageBody />
    </>
  );
}

const OverviewCard = ({ icon, itemName, itemSize }) => {
  return (
    <div className='flex justify-start overflow-hidden text-left transition-shadow duration-200 bg-none rounded-xl shadow-md3 group p-4 lg:p-2'>
      <div className='p-px lg:py-5 lg:px-2'>
        <div className='flex flex-row justify-start px-4'>
          <div className='flex flex-col items-center justify-between w-10 text-textparagraph text-5xl mr-2'>
            {icon}
            <p className='leading-5 text-main text-2xl font-semibold pt-2'>
              {itemSize}
            </p>
          </div>
          <div className='flex '>
            <p className='mb-2 font-semibold text-headingtext text-lg capitalize'>
              {itemName}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
