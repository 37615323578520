import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../components/Loading';

import * as contentActions from '../redux/actions/sessions';
import UpcomingEventCard from '../components/UpcomingEventCard';

const PageBody = () => {
  const dispatch = useDispatch();
  const { data, loading, error, errorMessage } = useSelector(
    (state) => state.sessions,
  );

  useEffect(() => {
    dispatch(contentActions.getAllUnauthSessions());
  }, []);

  useEffect(() => {
    if (error) console.log(errorMessage);
  }, [error]);

  return (
    <>
      {' '}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div
          style={{ padding: '10px' }}
          className='m-4 md:m-16 lg:m-10 rounded-md shadow-sm'
        >
          <div className='px-5 md:px-0 w-full'>
            <h1
              style={{ textAlign: 'center' }}
              className=' my-8 md:text-left font-display text-3xl md:text-4xl'
            >
              UPCOMING EVENTS
            </h1>

            <div className='dates-wrapper w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-2  mb-8 my-10'>
              {data.length > 0 ? (
                <>
                  {data?.map((event) => (
                    <UpcomingEventCard event={event} key={event._id} />
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default function data() {
  return (
    <>
      <PageBody />
    </>
  );
}
