import React from 'react';
import Logo from '../assets/new_delvLogoblue.png';
import { BarLoader } from 'react-spinners';

const LoadingState = () => {
  return (
    <div className='px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 '>
      <div className='max-w-xl mb-10 md:mx-auto lg:max-w-2xl md:mb-12 flex justify-center'>
        <div className='flex flex-col max-w-lg my-10 justify-start '>
          <img
            src={Logo}
            className='h-20 w-24 -mb-2 object-scale-down mx-auto'
            alt='logo'
          />
          <BarLoader height={2} width={100} color='#4b2aad' />
        </div>
      </div>
    </div>
  );
};

export default LoadingState;
