import React from 'react';
import Switch from 'react-switch';

const ReactSwitch = ({ toggleFunction, toggleState, height, width }) => {
  return (
    <Switch
      onChange={toggleFunction}
      checked={toggleState}
      onHandleColor='#27AE60'
      offHandleColor='#C4C4C4'
      offColor='#EBEBEB'
      onColor='#6FCF97'
      uncheckedIcon={false}
      checkedIcon={false}
      className='text-base'
      // handleDiameter={16}
      height={height}
      width={width}
    />
  );
};

export default ReactSwitch;
