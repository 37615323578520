import * as disciplinesTypes from '../types/disciplines';
import * as fetchFunctions from '../../api/index';
import disciplinesEndpoints from '../endpoints/disciplines';

const keywordList = (disciplines) => {
  let keywords = [];
  for (let i = 0; i < disciplines.length; i++) {
    for (let j = 0; j < disciplines[i].keywords.length; j++) {
      let newWord = {
        id: `${i}${j}`,
        text: disciplines[i].keywords[j],
      };
      keywords = [...keywords, newWord];
    }
  }
  return keywords;
};

export const getDisciplines = () => async (dispatch, getState) => {
  dispatch({
    type: disciplinesTypes.DISCIPLINES_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    disciplinesEndpoints.get_disciplines,
    token,
  );
  if (result.success) {
    const keywords = await keywordList(result.data);
    dispatch({
      type: disciplinesTypes.GET_DISCIPLINES,
      payload: {
        disciplines: result.data,
        keywordList: keywords,
      },
    });
    dispatch({
      type: disciplinesTypes.DISCIPLINES_RESET,
    });
  } else {
    dispatch({
      type: disciplinesTypes.DISCIPLINES_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
