import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import { PopupButton } from 'react-calendly';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import NavHeader from '../components/NavBarHeader';
import DisplayStarRating from '../components/StarRating';
import { MissingInformation } from '../components/CallToActionModal';
import UpcomingSessionsModal from '../components/UpcomingSessionsModal';

import * as userActions from '../redux/actions/users';
import * as commentActions from '../redux/actions/comments';
import * as sessionsActions from '../redux/actions/sessions';

import { isLinkValid } from '../helpers';

const Filters = ({ mentorsList, filteredMentors, setFilteredMentors }) => {
  const uganda = mentorsList?.filter((value) => value.country === 'Uganda');
  const kenya = mentorsList?.filter((value) => value.country === 'Kenya');
  const rwanda = mentorsList?.filter((value) => value.country === 'Rwanda');
  const tanzania = mentorsList?.filter((value) => value.country === 'Tanzania');
  const burundi = mentorsList?.filter((value) => value.country === 'Burundi');
  const others = mentorsList?.filter(
    (value) =>
      value.country !== 'Uganda' &&
      value.country !== 'Burundi' &&
      value.country !== 'Kenya' &&
      value.country !== 'Rwanda' &&
      value.country !== 'Tanzania',
  );

  const countryCheckbox = () => {
    setFilteredMentors([]);
    const allCheckbox = document.getElementById('all').checked;
    const ugandaCheckbox = document.getElementById('uganda').checked;
    const kenyaCheckbox = document.getElementById('kenya').checked;
    const rwandaCheckbox = document.getElementById('rwanda').checked;
    const tanzaniaCheckbox = document.getElementById('tanzania').checked;
    const burundiCheckbox = document.getElementById('burundi').checked;
    const othersCheckbox = document.getElementById('others').checked;

    if (allCheckbox) setFilteredMentors(mentorsList);
    if (ugandaCheckbox) setFilteredMentors([].concat(uganda));
    if (kenyaCheckbox) setFilteredMentors([].concat(kenya));
    if (tanzaniaCheckbox) setFilteredMentors([].concat(tanzania));
    if (rwandaCheckbox) setFilteredMentors([].concat(rwanda));
    if (burundiCheckbox) setFilteredMentors([].concat(burundi));
    if (othersCheckbox) setFilteredMentors([].concat(others));
  };

  const relevanceCheckbox = () => {
    const latestCheckbox = document.getElementById('latest').checked;
    const popularCheckbox = document.getElementById('popular').checked;
    const trendingCheckbox = document.getElementById('trending').checked;

    if (latestCheckbox)
      setFilteredMentors([
        ..._.orderBy(filteredMentors, [(i) => i.createdAt || ''], ['desc']),
      ]);
    else if (popularCheckbox)
      setFilteredMentors([
        ..._.orderBy(filteredMentors, [(i) => i.avgRating || ''], ['desc']),
      ]);
    else if (trendingCheckbox)
      setFilteredMentors([
        ..._.orderBy(filteredMentors, [(i) => i.views || ''], ['desc']),
      ]);
    else setFilteredMentors(filteredMentors);
  };

  return (
    <>
      <div className='filter-group mt-2 font-display'>
        {/* By Country*/}
        <h1 className='text-base text-charcoal mt-2 lg:mt-9'>By Country</h1>

        <div className='flex items-center mt-5'>
          <input
            id='all'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>All</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='uganda'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Uganda</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='kenya'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Kenya</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='rwanda'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Rwanda</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='tanzania'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Tanzania</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='burundi'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Burundi</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='others'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Others</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
      </div>

      {/* Relevance */}
      <div className='filter-group mt-2 font-display'>
        <h1 className='text-base text-charcoal mt-9'>By Relevance</h1>

        <div className='flex items-center mt-5'>
          <input
            id='latest'
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={relevanceCheckbox}
          />
          <p className='ml-4 mr-10 text-base text-charcoal'>Latest</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-base'> </span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='popular'
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={relevanceCheckbox}
          />
          <p className='ml-4 mr-10 text-base text-charcoal'>Popular</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-base'> </span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='trending'
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={relevanceCheckbox}
          />
          <p className='ml-4 mr-10 text-base text-charcoal'>Trending</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-base'> </span>
          </div>
        </div>
      </div>
    </>
  );
};

const MobileFilters = ({ showFilters, setShowFilters }) => {
  return (
    <div
      className={
        showFilters
          ? 'w-full h-screen shadow-md absolute z-40  transform  translate-x-0 lg:hidden'
          : '   w-full h-full absolute z-40  transform -translate-x-full'
      }
    >
      <div
        className='bg-black opacity-50 w-full h-screen absolute  '
        onClick={() => setShowFilters(!showFilters)}
      ></div>
      <div className='w-64 md:w-72 absolute z-40 bg-white shadow h-full flex-col justify-between lg:hidden pb-4 transition duration-150 ease-in-out'>
        <div className='flex flex-col justify-between h-full'>
          <div>
            <div className='flex items-center justify-between px-8'>
              <div className='h-16 w-full flex items-center'>
                <span className='xl:text-base font-display md:text-2xl text-base ml-2 text-main'>
                  Mentors Filters
                </span>
              </div>
              <div
                id='closeSideBar'
                className='flex items-center justify-center h-10 w-10'
                onClick={() => setShowFilters(!showFilters)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='icon icon-tabler icon-tabler-x'
                  width={20}
                  height={20}
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' />
                  <line x1={18} y1={6} x2={6} y2={18} />
                  <line x1={6} y1={6} x2={18} y2={18} />
                </svg>
              </div>
            </div>
            <div className='px-8 overflow-y-auto overscroll-contain h-screen bottom-5'>
              <div className='block  sm:ml-8 mb-10 text-headingtext  sm:text-sm'>
                <Filters />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MentorsCard = ({
  mentor,
  to,
  setMentorEvaluate,
  user,
  setSelectedMentor,
  setShowNotification,
  setShowUpcomingSessionsModal,
  isInstitution,
}) => {
  const currExperience = mentor?.experience?.pop();
  const overlalySettings = {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: false,
    hideGdprBanner: true,
    hideLandingPageDetails: true,
    primaryColor: '0057FF',
    textColor: '4d5055',
  };

  const prefillData = {
    date: new Date(),
    email: user?.email || '',
    firstName: user?.firstname || '',
    lastName: user?.lastname || '',
    name: `${user?.firstname || ''} ${user?.lastname || ''}`,
  };
  const comments = useSelector((state) => state.comments.data);
  const mentorComments = comments?.filter(
    (item) => item.idOfCommentedAbout === mentor._id,
  );

  const isMentorCalendlyMissing =
    mentor?.usercategory[0] === 'mentor' && !isLinkValid(mentor?.calendly);

  return (
    <div className=' py-2 mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-0 lg:px-0 font-display lg:py-4 min-h-80 mb-4'>
      <div className=' max-w-full gap-8 row-gap-5 md:row-gap-8 sm:mx-auto'>
        <div className='p-4   bg-white rounded shadow-sm hover:shadow md:text-left sm:text-md'>
          <div className='flex flex-col lg:flex-row w-full items-start lg:items-center'>
            <div className=' w-full lg:w-9/12 flex flex-col lg:flex-row justfify-around lg:justify-start'>
              <div className=' flex justify-around'>
                <div className=' h-16 w-16 sm:h-16 sm:w-16 md:h-20 md:w-20  lg:h-28 lg:w-28 '>
                  <img
                    src={
                      mentor?.profilePicture?.publicUrl ||
                      `https://ui-avatars.com/api/?name=${mentor?.firstname}+${mentor?.lastname}&bold=true`
                    }
                    alt={mentor?.firstname}
                    className='rounded-full object-cover h-full w-full shadow-md'
                  />
                </div>
              </div>
              <div className='px-4 py-2 text-center lg:text-justify text-base sm:text-sm text-charcoal lg:w-6/12'>
                <h5 className='mb-3 text-xl font-bold leading-none capitalize'>
                  {mentor?.firstname} {mentor?.lastname}
                </h5>
                <p
                  className='mb-5 text-headingtext'
                  style={{ fontSize: '12px' }}
                >
                  {currExperience || mentor?.about}
                </p>
                <p className='mb-3 text-charcoal font-semibold text-base'>
                  Specialities
                </p>
                <div className='mb-6 '>
                  <ul
                    className='grid grid-flow-row grid-cols-2   gap-x-6 lg:gap-x-2  gap-y-px text-headingtext  text-center lg:text-justify'
                    style={{ fontSize: '12px' }}
                  >
                    {mentor?.interests?.map((value) => (
                      <li className='lg:w-11/12' key={value}>
                        <span className='lg:mr-1'>*</span>
                        {value}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='mx-auto lg:mx-0 items-center mb-2 w-full'>
                  <Link to={to}>
                    <button className='inline-flex items-center sm:max-w-full justify-center h-12 px-4 sm:mr-0 font-medium border-2 border-darkerblue tracking-wide text-darkerblue hover:text-white transition duration-200 rounded-full shadow-md bg-white hover:bg-darkerblue focus:shadow-outline focus:outline-none'>
                      Find Out More
                    </button>
                  </Link>
                </div>
              </div>
              <div className='flex flex-row px-4 lg:px-0 justify-between lg:flex-nowrap lg:justify-start  sm:text-sm w-full lg:w-4/12 lg:border-r-2  border-gray3'>
                <div>
                  <DisplayStarRating selectStars={mentor?.avgRating || 0} />
                </div>
                <span
                  className='mx-2 text-linecolor'
                  style={{ fontSize: '12px' }}
                >
                  ({mentorComments?.length} Reviews)
                </span>
              </div>
            </div>
            <div className=' lg:ml-2 sm:px-2 py-4 lg:py-2 w-full lg:w-3/12 lg:h-full'>
              <div className='flex flex-col  lg:justify-between'>
                {!isInstitution &&
                  (isMentorCalendlyMissing ? (
                    <button
                      onClick={() => {
                        setMentorEvaluate(
                          `${mentor?.firstname} ${mentor?.lastname}`,
                        );
                        setShowNotification(true);
                      }}
                      className='inline-flex items-center justify-center w-full h-10 px-8 py-6 mx-2 lg:mx-px border-2 border-darkerblue tracking-wide font-bold text-darkerblue text-sm mb-2 lg:mb-4 transition duration-200 rounded-full shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                    >
                      Book Session
                    </button>
                  ) : (
                    <PopupButton
                      className='inline-flex items-center justify-center w-full h-10 px-8 py-6 mx-2 lg:mx-px border-2 border-darkerblue tracking-wide font-bold text-darkerblue text-sm mb-2 lg:mb-4 transition duration-200 rounded-full shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                      pageSettings={overlalySettings}
                      prefill={prefillData}
                      text='Book Session'
                      url={
                        isLinkValid(mentor?.calendly)
                          ? mentor?.calendly
                          : 'https://calendly.com/'
                      }
                    />
                  ))}
                <Link to={'/comingsoon/livechat'}>
                  <button className='inline-flex items-center justify-center w-full h-10 px-8 py-6 mx-2 lg:mx-px  border-2 border-darkerblue tracking-wide font-bold  text-darkerblue text-sm  mb-2 lg:mb-4 transition duration-200 rounded-full shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'>
                    Live Chat
                  </button>
                </Link>

                <button
                  onClick={() => {
                    setSelectedMentor(mentor);
                    setShowUpcomingSessionsModal(true);
                  }}
                  className='inline-flex items-center justify-center w-full h-10 px-8 py-6 mx-2 lg:mx-px  border-2 border-darkerblue tracking-wide font-bold  text-darkerblue text-sm mb-2 lg:mb-4 transition duration-200 rounded-full shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                >
                  Upcoming Sessions
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PageBody = ({ user, isInstitution }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.user?.loading);
  const mentors = useSelector((state) => state?.user?.data);
  const [show, setShow] = useState(false);
  const [filteredMentors, setFilteredMentors] = useState([]);
  const [selectedMentor, setSelectedMentor] = useState('');
  const [mentorEvaluate, setMentorEvaluate] = useState(null);
  const [showUpcomingSessionsModal, setShowUpcomingSessionsModal] =
    useState(false);

  const [showNotification, setShowNotification] = useState(false);

  const sessions = useSelector((state) => state?.sessions?.data);
  const userSessions = sessions.filter(
    (item) => item.userID === selectedMentor._id,
  );

  useEffect(() => {
    dispatch(userActions.getMentors({}));
    dispatch(sessionsActions.getAllSessions());
    dispatch(commentActions.getAllComments());
  }, []);

  useEffect(() => {
    setFilteredMentors([...mentors]);
  }, [mentors]);

  return (
    <>
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed '>
          <Loading />
        </div>
      ) : (
        <div className='bg-lightgraybg w-full h-full '>
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-2 lg:px-8'>
              <div className='flex flex-row justify-around'>
                <div className='hidden lg:w-2/12 border-r-2  border-gray3 pr-6  lg:flex'>
                  <div className='block mt-5 mb-10 text-headingtext  sm:text-sm fixed'>
                    {isInstitution && (
                      <div
                        className='flex justify-start w-full mb-6 text-black  cursor-pointer hover:text-headingtext'
                        onClick={() => window.history.back()}
                      >
                        <KeyboardBackspace fontSize='medium' />{' '}
                        <span className='ml-2 flex flex-col justify-center font-semibold'>
                          Back
                        </span>
                      </div>
                    )}
                    <span className='text-base text-charcoal'>Filter</span>
                    <Filters
                      mentorsList={mentors}
                      filteredMentors={filteredMentors}
                      setFilteredMentors={setFilteredMentors}
                    />
                  </div>
                </div>
                <MobileFilters
                  showFilters={show}
                  setShowFilters={setShow}
                  mentorsList={mentors}
                  filteredMentors={filteredMentors}
                  setFilteredMentors={setFilteredMentors}
                />
                <div className='flex flex-col pb-5 md:px-2 lg:pl-8 mt-5 mb-10 text-headingtext  w-full md:w-10/12'>
                  <div className='flex flex-row justify-between'>
                    <span className='text-lg lg:text-xl text-gray-700 font-display'>
                      Showing {filteredMentors.length} results
                    </span>
                    <button
                      onClick={() => {
                        setShow(!show);
                      }}
                      className='lg:hidden
                inline-flex items-center justify-center h-10 px-8 py- mx-2 border border-darkerblue tracking-wide font-bold text-white text-sm mb-2 transition duration-200 rounded-sm shadow-md bg-darkerblue hover:bg-bannerBlue focus:shadow-outline focus:outline-none'
                    >
                      Filter
                    </button>
                  </div>
                  <div className='w-full '>
                    {filteredMentors?.map((value, key) => (
                      <MentorsCard
                        user={user}
                        key={value?._id}
                        setShowNotification={setShowNotification}
                        setMentorEvaluate={setMentorEvaluate}
                        mentor={value}
                        isInstitution={isInstitution}
                        setSelectedMentor={setSelectedMentor}
                        setShowUpcomingSessionsModal={
                          setShowUpcomingSessionsModal
                        }
                        to={`/mentors/more/${value?._id}`}
                      />
                    ))}

                    <button
                      onClick={() => {
                        dispatch(
                          userActions.getMentors({ skip: 0, limit: 30 }),
                        );
                      }}
                    >
                      {'<< Previous'}
                    </button>
                    {mentors.length > 0 ? (
                      <>
                        <span> | </span>
                        <button
                          onClick={() => {
                            dispatch(userActions.getMentors({ skip: 30 }));
                          }}
                        >
                          {'Next >>'}
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <UpcomingSessionsModal
        mentor={selectedMentor}
        data={userSessions}
        showUpcomingSessionsModal={showUpcomingSessionsModal}
        setShowUpcomingSessionsModal={setShowUpcomingSessionsModal}
      />
      <MissingInformation
        mentorName={mentorEvaluate}
        showCallToActionModal={showNotification}
        setShowCallToActionModal={setShowNotification}
      />
    </>
  );
};

export default function MentorsPage() {
  const institutions = useSelector((state) => state?.institution?.data);
  const user = useSelector((state) => state?.user?.user);
  const isInstitution = user?.usercategory[0] === 'institution';

  return (
    <>
      {isInstitution ? (
        <NavHeader institutions={institutions} user={user} />
      ) : (
        <Nav mentorsActive={true} />
      )}
      <PageBody isInstitution={isInstitution} user={user} />
    </>
  );
}
