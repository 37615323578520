import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { WithContext as ReactTags } from 'react-tag-input';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MultiSelect from '@kenshooui/react-multi-select';
import '@kenshooui/react-multi-select/dist/style.css';

import * as institutionActions from '../redux/actions/institution';
import * as institutionTypes from '../redux/types/institution';
import * as userActions from '../redux/actions/users';
import * as affiliationsActions from '../redux/actions/affiliations';

import Loading from '../components/Loading';
import Nav from '../components/NavBarR';

import { ItemOption, SelecteItemOption } from '../components/Multiselect';
import ButtonLoader from '../components/ButtonLoader';
import CustomAlert from '../components/CustomAlert';
import ModalWrapper from '../components/ModalWrapper';
import NoContentPlaceholder from '../components/NoContentPlaceholder';

const PageBody = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const users = useSelector((state) => state?.user?.data);
  const { success, error, errorMessage, loading } = useSelector(
    (state) => state.institution,
  );
  const institutionLearners = useSelector(
    (state) => state?.institution?.institutionLearners,
  );
  const PageLoading = useSelector((state) => state.mentors.loading);
  const [showCreateMentorModal, setShowCreateMentorModal] = useState(false);
  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);

  const { data } = useSelector((state) => state.institution);
  const institution = data;
  const institute = institution.find((inst) => inst.admins.includes(user._id));
  const institutionID = institute?._id;

  useEffect(() => {
    dispatch(institutionActions.getInstitutions({}));
    dispatch(userActions?.getUsers());
    dispatch(institutionActions.getInstitutionLearners(institutionID));
  }, []);

  return (
    <>
      <Nav
        membersActive={true}
        userType='institution'
        institutions={institution}
      />
      {PageLoading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div
          className={`bg-lightgraybg h-full ${
            institutionLearners.length > 5 ? 'lg:h-full' : 'lg:h-screen'
          }`}
        >
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
              <div>
                <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                  <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                    <span className='inline-block mb-1 sm:mb-4'>
                      Institution Members ({institutionLearners.length})
                    </span>
                  </h2>
                  <div className='mb-10 text-center md:mb-4 lg:mb-4 grid grid-cols-1 lg:grid-cols-1 gap-4'>
                    <button
                      className='inline-flex items-center text-sm sm:max-w-full justify-center h-12 w-full px-8 sm:mr-0 font-medium border-2 border-darkerblue tracking-wide text-darkerblue transition duration-200 rounded-md bg-none hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                      onClick={() => setShowInviteMemberModal(true)}
                    >
                      Invite Member
                    </button>
                    {/*<button
                      className='inline-flex items-center text-sm sm:max-w-full justify-center h-12 w-full px-8 sm:mr-0 font-medium border-2 border-darkerblue tracking-wide text-white transition duration-200 rounded-md bg-darkerblue hover:bg-navyblue hover:border-navyblue hover:text-white focus:shadow-outline focus:outline-none'
                      onClick={() => setShowCreateMentorModal(true)}
                    >
                      Create Mentor
                    </button>*/}
                  </div>
                </div>
                <div className='flex flex-col mb-6 mt-20 lg:justify-between md:mb shadow items-center'>
                  {institutionLearners.length > 0 ? (
                    <div className='grid gap-5 sm:row-gap-8 mb-8 lg:grid-cols-3 sm:grid-cols-2'>
                      {institutionLearners?.map((value) => (
                        <MemberCard
                          users={users}
                          key={value?._id}
                          member={value?.learner}
                          to={`/users/profile/${value?.learner?._id}`}
                        />
                      ))}
                    </div>
                  ) : (
                    <NoContentPlaceholder content='users' generic />
                  )}
                </div>
              </div>

              <CreateMentorModal
                setShowCreateMentorModal={setShowCreateMentorModal}
                showCreateMentorModal={showCreateMentorModal}
                institutionID={institutionID}
                loading={loading}
                success={success}
                error={error}
                errorMessage={errorMessage}
              />
              <InviteMemberModal
                setShowInviteMemberModal={setShowInviteMemberModal}
                showInviteMemberModal={showInviteMemberModal}
                institution={institute}
                excludeMentors={institutionLearners}
                users={users}
                loading={loading}
                success={success}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const InviteMemberModal = ({
  setShowInviteMemberModal,
  showInviteMemberModal,
  institution,
  excludeMentors,
  users,
}) => {
  const dispatch = useDispatch();
  const { loading, success } = useSelector((state) => state?.institution);

  // const exclusionIds = [];
  // excludeMentors?.forEach((mentor) => exclusionIds.push(mentor._id));

  const membersList = users?.filter(
    (value) => value?.usercategory[0] !== 'mentor',
  );

  // This extracts the institution created mentors from the other mentors self registered
  /*const filteredList = mentorsList?.filter(
    (mentor) => !exclusionIds.includes(mentor._id),
  );*/

  const members = [];

  membersList.forEach((element, index) => {
    members.push({
      id: index,
      user_id: element?._id,
      label: `${element?.firstname} ${element?.lastname}`,
      image: element?.profilePicture?.publicUrl,
    });
  });

  const [selectedItems, setSeletecteItems] = useState([]);

  const multiSelectMessages = {
    searchPlaceholder: `Search for mentors..`,
    noItemsMessage: 'No selections...',
    noneSelectedMessage: 'No selection',
    selectedMessage: 'selected',
    selectAllMessage: 'Select All',
    clearAllMessage: 'Clear',
  };

  const handleMemberInvitation = () => {
    selectedItems.forEach((member) => {
      // Sending a join request that can be accepted by the invited user. Request to join as staff.
      dispatch(
        institutionActions.createInstitutionLearner({
          id: member.user_id,
          institution_id: institution._id,
        }),
      );
    });
    setSeletecteItems([]);
  };

  useEffect(() => {
    if (success.message === 'Institution Learner added successfully') {
      dispatch(institutionActions.getInstitutionLearners(institution._id));
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Invitation sent successfully ',
      });
      setShowInviteMemberModal(false);
      dispatch({ type: institutionTypes.INSTITUTION_RESET });
    }
  }, [success]);

  return (
    <>
      {showInviteMemberModal ? (
        <>
          <ModalWrapper
            title={'Invite Members'}
            closeModal={() => setShowInviteMemberModal(false)}
            bodyContent={
              <>
                <div className='relative'>
                  <div className='w-full'>
                    <MultiSelect
                      messages={multiSelectMessages}
                      items={members}
                      showSelectAll={false}
                      selectedItems={selectedItems}
                      showSelectedItems
                      itemHeight={50}
                      selectedItemHeight={50}
                      onChange={(values) => setSeletecteItems(values)}
                      itemRenderer={(item) => <ItemOption item={item} />}
                      selectedItemRenderer={(item) => (
                        <SelecteItemOption item={item} />
                      )}
                    />
                  </div>
                </div>
              </>
            }
            footer={true}
            footerContent={
              <>
                <button
                  className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                  type='submit'
                  onClick={handleMemberInvitation}
                  disabled={loading}
                >
                  {loading ? <ButtonLoader /> : <> Send Invites </>}
                </button>
              </>
            }
          />
        </>
      ) : null}
    </>
  );
};

const Members = () => {
  return (
    <div>
      <PageBody />
    </div>
  );
};

const CreateMentorModal = ({
  setShowCreateMentorModal,
  showCreateMentorModal,
  institutionID,
  loading,
  success,
  error,
  errorMessage,
}) => {
  const dispatch = useDispatch();

  const GENERIC_PASSWORD = '123456789';

  const [skills, setSkills] = useState([]);

  const initialValues = {
    lastname: '',
    firstname: '',
    specialization: '',
    phone: '',
    discipline: '',
    email: '',
    bio: '',
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    specialization: Yup.string().required('Specilization is required'),
    phone: Yup.string().required('Phone is required'),
    discipline: Yup.string().required('Discipline is required'),
    bio: Yup.string().required('Bio is required'),
  });

  const KeyCodes = {
    tab: 9,
    enter: 13,
    // space: 32,
    comma: 188,
  };
  const delimiters = [
    KeyCodes.tab,
    KeyCodes.enter,
    KeyCodes.space,
    KeyCodes.comma,
  ];

  const handleDelete = (i) => {
    const newTags = skills.filter((tag, index) => index !== i);
    setSkills(newTags);
  };

  const handleAddition = (tag) => {
    setSkills([...skills, tag]);
  };

  const onSubmit = (values, onSubmitProps) => {
    const tagsText = skills.map((tag) => tag.text);
    const payload = {
      ...values,
      skills: tagsText,
      title: 'Mentor',
      userId: '',
      country: 'Uganda',
      institution: institutionID,
      password: GENERIC_PASSWORD,
      usercategory: 'mentor',
    };

    dispatch(institutionActions.createMentor(payload));
    setSkills([]);
    onSubmitProps.resetForm();
  };

  useEffect(() => {
    if (success.message === 'Mentor profile successfully created') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Mentor profile created',
      });
      setShowCreateMentorModal(false);
      dispatch(userActions.getUsers());
      dispatch(affiliationsActions.getAllAffiliations());
    } else if (error && errorMessage === 'Failed to create mentor profile') {
      CustomAlert({
        alertType: 'error',
        alertMessage: 'Failed to create Mentor Profile, try again',
      });
      setShowCreateMentorModal(false);
      dispatch(userActions.getUsers());
      dispatch(affiliationsActions.getAllAffiliations());
      dispatch({ type: institutionTypes.INSTITUTION_RESET });
    }
  }, [success]);

  return (
    <>
      {showCreateMentorModal ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, getFieldProps, isValid }) => (
              <Form>
                <ModalWrapper
                  title={' Create Mentor'}
                  closeModal={() => setShowCreateMentorModal(false)}
                  bodyContent={
                    <>
                      <div className='relative'>
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-4 pt-6'>
                          {' '}
                          <div className='form-control'>
                            <h3 className='font-body text-textparagraph font-semibold'>
                              FIRSTNAME
                            </h3>
                            <input
                              type='text'
                              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='firstName'
                              name='firstname'
                              {...getFieldProps('firstname')}
                            />
                            {touched.firstname && errors.firstname ? (
                              <span className='text-sm text-red'>
                                {errors.firstname}
                              </span>
                            ) : null}
                          </div>
                          <div className='form-control'>
                            <h3 className='font-body text-textparagraph font-semibold'>
                              LASTNAME
                            </h3>
                            <input
                              type='text'
                              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='lastName'
                              ml-12
                              name='lastname'
                              {...getFieldProps('lastname')}
                            />
                            {touched.lastname && errors.lastname ? (
                              <span className='text-sm text-red'>
                                {errors.lastname}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className='pt-6'>
                          <h3 className='font-body text-textparagraph font-semibold'>
                            MENTOR&apos;S PHONE NUMBER
                          </h3>
                          <input
                            type='text'
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='phone_number'
                            name='phone'
                            {...getFieldProps('phone')}
                          />
                          {touched.phone && errors.phone ? (
                            <span className='text-sm text-red'>
                              {errors.phone}
                            </span>
                          ) : null}
                        </div>
                        <div className='pt-6'>
                          <h3 className='font-body text-textparagraph font-semibold'>
                            MENTOR&apos;S EMAIL
                          </h3>
                          <input
                            type='email'
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='email'
                            name='email'
                            {...getFieldProps('email')}
                          />
                          {touched.email && errors.email ? (
                            <span className='text-sm text-red'>
                              {errors.email}
                            </span>
                          ) : null}
                        </div>

                        <div className='pt-6'>
                          <h3 className='font-body text-textparagraph font-semibold'>
                            Enter Mentor&apos;s DISCIPLINE
                          </h3>
                          <input
                            type='text'
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='discipline'
                            name='discipline'
                            {...getFieldProps('discipline')}
                          />
                          {touched.discipline && errors.discipline ? (
                            <span className='text-sm text-red'>
                              {errors.discipline}
                            </span>
                          ) : null}
                        </div>

                        <div className='pt-6'>
                          <h3 className='font-body text-textparagraph font-semibold'>
                            CATEGORY
                          </h3>
                          <select
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='specialization'
                            name='specialization'
                            {...getFieldProps('specialization')}
                          >
                            <option value='select'>Select a category</option>
                            <option value='Higher Education'>
                              Higher Education
                            </option>
                            <option value='Education / Learning'>
                              Education / Learning
                            </option>
                            <option value='Finance'>Finance</option>
                            <option value='Manufacturing'>Manufacturing</option>
                            <option value='Commerce / trade'>
                              Commerce / trade
                            </option>
                            <option value='Distribution'>Distribution</option>
                            <option value='Information Technology'>
                              Information Technology
                            </option>
                            <option value='Health care / Medicine'>
                              Health care / Medicine
                            </option>
                            <option value='Construction'>Construction</option>
                            <option value='Media'>Media</option>
                            <option value='Banking'>Banking</option>
                            <option value='Social Entreprise'>
                              Social Entreprise
                            </option>
                            <option value='Other'>Other</option>
                          </select>
                          {touched.specialization && errors.specialization ? (
                            <span className='text-sm text-red'>
                              {errors.specialization}
                            </span>
                          ) : null}
                        </div>

                        <div className='pt-6'>
                          <h3 className='font-body text-textparagraph font-semibold pb-2'>
                            SPECIALITIES
                          </h3>

                          <ReactTags
                            tags={skills}
                            suggestions={[]}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            delimiters={delimiters}
                            autocomplete={true}
                            placeholder={
                              'Comma-separated list of your skills (keep it below 10).'
                            }
                            maxLength={40}
                            classNames={{
                              tagInputField:
                                'px-3 py-3 text-charcoal relative bg-none  text-sm border-b border-linecolor outline-none focus:outline-noone  focus:border-darkerblue w-full',
                            }}
                          />
                        </div>

                        <div className='pt-6'>
                          <h3 className='font-body text-textparagraph font-semibold pb-4'>
                            BIO
                          </h3>
                          <textarea
                            className='flex-grow w-full p-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='bio'
                            name='bio'
                            placeholder={
                              'Give a brief summary about the mentor..'
                            }
                            rows='8'
                            {...getFieldProps('bio')}
                          />
                          {touched.bio && errors.bio ? (
                            <span className='text-sm text-red'>
                              {errors.bio}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </>
                  }
                  footer={true}
                  footerContent={
                    <>
                      <button
                        className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                        type='submit'
                        disabled={!isValid || loading}
                      >
                        {loading ? <ButtonLoader /> : <> Create Mentor </>}
                      </button>
                    </>
                  }
                />
              </Form>
            )}
          </Formik>
        </>
      ) : null}
    </>
  );
};

const MemberCard = ({ member, to, users }) => {
  const memberName = `${member.firstName} ${member.lastName}`;

  return (
    <Link to={to} aria-label='Mentor More info'>
      <div className='bg-white rounded-md shadow-md text-center border-b-4 border-white w-72 h-52 pb-4 px-2 inline-block overflow-hidden duration-300 transform hover:-translate-y-2 hover:border-darkerblue mb-3 '>
        <div className='w-20 h-20 m-auto mt-6 mb-3.5 '>
          <img
            src={
              member?.profilePicture?.publicUrl ||
              `https://ui-avatars.com/api/?name=${memberName}&bold=true`
            }
            className='object-cover w-full h-full rounded-full'
            alt={member?.sessionTitle}
          />
        </div>
        <div className='bg-gray '></div>
        <h2 className='text-xl font-bold m-auto mb-2  capitalize text-charcoal'>
          {/* {name} */}
          {memberName}
        </h2>
        <p className=' block mb-3 font-base capitalize text-charcoal text-opacity-70'>
          Learner
        </p>
      </div>
    </Link>
  );
};

export default Members;
