import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import NavHeader from '../components/NavBarHeader';
import DefaultThumbnail from '../components/DefaultThumbnail';
import ContentPreview from '../components/ContentPreview';

import * as userActions from '../redux/actions/users';
import * as contentActions from '../redux/actions/content';

const SharedImageFrame = ({ image }) => {
  const dispatch = useDispatch();
  const mentor = [];
  const users = useSelector((state) => state?.user);

  useEffect(() => {
    dispatch(userActions.getUsers());
  }, []);

  users?.data
    ?.filter((user) => user._id === image?.userID)
    .map((mapItem) => {
      return mentor.push(mapItem);
    });

  return (
    <>
      {mentor.length > 0 ? (
        <div className='max-w-3xl overflow-auto bg-white box-border border border-linecolor py-5  flex flex-col mb-8'>
          <div className='pl-8 flex items-center w-full flex-row justify-between'>
            <div className='flex items-center'>
              <img
                src={
                  mentor[0]?.profilePicture?.publicUrl ||
                  `https://ui-avatars.com/api/?name=${`${mentor[0]?.firstname} ${mentor[0]?.lastname}`}&bold=true`
                }
                alt={mentor[0]?.usercategory}
                className='rounded-full h-10 w-10 flex items-center justify-center mr-2 ml-4'
              />
              <div className='flex flex-col pl-3.5 pr-12'>
                <div>
                  <p className='flex-1 w-32 text-sm font-semibold font-body text-charcoal'>
                    {image?.author ||
                      `${mentor[0]?.firstname} ${mentor[0]?.lastname}`}
                  </p>
                </div>
                <div>
                  <p className='text-xs font-body text-textparagraph'>
                    {image?.title || image?.postText}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='w-11/12 h-96 text-xs font-body text-charcoal pl-8 pt-5 '>
            {image?.postText ? (
              image?.link?.length > 0 ? (
                !image?.contentFile?.mimeType.includes('image') &&
                (image?.contentFile?.mimeType.includes('pdf') ||
                  image?.contentFile?.mimeType.includes('video')) ? (
                  <DefaultThumbnail text={image?.title || image?.postText} />
                ) : (
                  <iframe
                    width='100%'
                    height='100%'
                    title='post-attachment'
                    src={image?.link}
                  />
                )
              ) : (
                <DefaultThumbnail text={image?.title || image?.postText} />
              )
            ) : image?.thumbnailFile?.publicUrl ? (
              <ContentPreview
                fileUrl={
                  image?.thumbnailFile?.publicUrl ||
                  image?.contentFile?.publicUrl
                }
                profile_post
              />
            ) : (
              <DefaultThumbnail text={image.title || image?.postText} />
            )}
          </div>
          <div className='flex'>
            <div className='w-11/12 border border-r border-lightgraybg m-auto  mt-12 '></div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default function ViewSharedImage() {
  const { imageID } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const image = useSelector((state) => state.content.data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (image.length === 0) {
      dispatch(contentActions.getSpecificContent(imageID));
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [image, imageID]);

  return (
    <>
      {user ? <Nav /> : <NavHeader />}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div className='h-screen'>
          <div className='bg-lightgraybg w-full h-full '>
            <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-2 lg:px-8'>
                <div className='flex justify-around'>
                  <div className='pt-3'>
                    <SharedImageFrame image={image} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
