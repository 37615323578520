export const USERS_LOADING = 'USERS_LOADING';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_PROFILE = 'USER_PROFILE';
export const GET_USERS = 'GET_USERS';
export const REJECTED_POINTS = 'REJECTED_POINTS';
export const USER_POINTS = 'USER_POINTS';
export const USER_CONFIRMED = 'USER_CONFIRMED';
export const ADD_FOLLOW = 'ADD_FOLLOW';
export const GET_FOLLOW = 'GET_FOLLOW';
export const GET_A_USER = 'GET_A_USER';
export const SPECIFIC_USER = 'SPECIFIC_USER';
export const GET_MENTORS = 'GET_MENTORS';
export const USER_UPDATE_PROFILE = 'USER_UPDATE_PROFILE';
export const USERS_ERROR = 'USERS_ERROR';
export const USERS_RESET = 'USERS_RESET';
export const USERS_SUCCESS = 'USERS_SUCCESS';
