import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dashboard, People } from '@material-ui/icons';

// assets
// import courseImage from '../assets/courseimage.svg';

// components
import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import CustomAlert from '../components/CustomAlert';
import PopularCard from '../components/PopularCard';
// import Recommended from '../components/Recommended';
import SendPostModal from '../components/SendPostModal';
import SharePostModal from '../components/SharePostModal';
import FollowerSideMenu from '../components/FollowSideMenu';
import UploadSessionModal from '../components/UploadSession';
import UserInfoSideMenu from '../components/UserInfoSideMenu';
import CommentPostModal from '../components/CommentPostModal';
import { NotificationBanner } from '../components/NotificationBanner';
import ModalWrapper from '../components/ModalWrapper';

import * as invitationActions from '../redux/actions/invitation';
import * as homeActions from '../redux/actions/home';
import * as userActions from '../redux/actions/users';
import * as commentsActions from '../redux/actions/comments';
import * as likesActions from '../redux/actions/likes';

export default function Feeds() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user);
  const mentors = useSelector((state) => state?.user?.data);
  const { feeds, loading } = useSelector((state) => state.home);
  const myContent = useSelector((state) => state.content.myContent);
  const mySessions = useSelector((state) => state.sessions.mySessions);
  const institutionData = useSelector((state) => state.institution.data);
  const contentComments = useSelector((state) => state.comments?.data);
  const contentLikes = useSelector((state) => state.like?.data);
  const post = useSelector((state) => state.post);

  const isLinkValid = (link) => {
    let regex = /^https:\/\/calendly\.com\//g;
    return regex.test(link);
  };

  const isMentorCalendlyMissing =
    user?.usercategory[0] === 'mentor' && !isLinkValid(user?.calendly);

  const [showCommentPost, setShowCommentPost] = useState(false);
  const [showSendPost, setShowSendPost] = useState(false);
  const [showSharePost, setShowSharePost] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const [showFollower, setShowFollowers] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showSendInvitationModal, setShowSendInvitationModal] = useState(false);
  const [showCreateSession, setShowCreateSession] = useState(false);
  const [showNotification, setShowNotification] = useState(
    isMentorCalendlyMissing,
  );
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(30);

  useEffect(() => {
    if (showCommentPost === false) {
      dispatch(commentsActions.getAllComments());
      dispatch(likesActions.getAllLikes());
    }
  }, [showCommentPost]);

  useEffect(() => {
    dispatch(homeActions.getFeeds({ limit: 30 }));
    dispatch(userActions.getUsers());

    setSkip(skip + 30);
    setLimit(limit + 30);
  }, []);

  function moreFeeds() {
    dispatch(homeActions.getFeeds({ skip: skip, limit: limit }));
  }

  useEffect(() => {
    if (post?.update_success) {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Success: Post has been pulled down',
      });
      dispatch(homeActions.getFeeds({ limit: 30 }));
    }
  }, [post?.update_success]);

  return (
    <div>
      <SendInvitationModal
        setShowSendInvitationModal={setShowSendInvitationModal}
        showSendInvitationModal={showSendInvitationModal}
      />
      <Nav feedsActive={true} />
      {showNotification && (
        <NotificationBanner setShowNotification={setShowNotification} />
      )}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div className='bg-lightgraybg'>
          <div className=' text-center md:mb-0  w-full fixed  mb-4 lg:hidden'>
            <div className='inline-flex items-center justify-between w-full h-12 px-6 font-medium font-display tracking-wide text-headingtext transition   bg-white '>
              <span
                className='hover:text-darkerblue focus:text-darkerblue font-display'
                onClick={() => {
                  setShowUserInfo(!showUserInfo);
                  setShowFollowers(false);
                }}
              >
                <Dashboard />
                <span className='ml-2'> {' User Info '} </span>
              </span>
              <span
                className='hover:text-darkerblue focus:text-darkerblue '
                onClick={() => {
                  setShowFollowers(!showFollower);
                  setShowUserInfo(false);
                }}
              >
                <span className='mr-2'>{'Follow'} </span>
                <People />
              </span>
            </div>
          </div>
          <FollowerSideMenu
            users={mentors}
            institutions={institutionData}
            showFollower={showFollower}
            setShowFollowers={setShowFollowers}
          />

          <UserInfoSideMenu
            user={user}
            myContent={myContent}
            mySessions={mySessions}
            showUserInfo={showUserInfo}
            setShowUserInfo={setShowUserInfo}
            setShowSendInvitationModal={setShowSendInvitationModal}
            showSendInvitationModal={showSendInvitationModal}
          />
          <div className='px-2 mx-auto sm:w-xl md:w-full lg:max-w-screen-2xl md:px-0  '>
            <div className='px-2  container mx-auto sm:max-w-full md:max-w-screen-2xl lg:max-w-full md:px-4  '>
              <div className='w-full grid  grid-cols-1 lg:grid-cols-12 sm:gap-5  lg:px-10'>
                <aside className='h-screen sticky lg:top-32 lg:col-span-2 hidden font-display lg:flex'></aside>
                <main className='lg:col-span-7 flex flex-col mt-16 lg:mt-4'>
                  <div className='pt-7 mb-20 font-display'>
                    {feeds?.map((value) => (
                      <PopularCard
                        contentComments={contentComments}
                        contentLikes={contentLikes}
                        users={mentors}
                        institutions={institutionData}
                        key={value._id}
                        content={value}
                        toShow={true}
                        sendPostClick={() => {
                          setSelectedPost(value);
                          setShowSendPost(true);
                        }}
                        commentPostClick={() => {
                          setSelectedPost(value);
                          setShowCommentPost(true);
                        }}
                        sharePostClick={() => {
                          setSelectedPost(value);
                          setShowSharePost(true);
                        }}
                      />
                    ))}
                    {/* <Recommended
                      heading='Recommended Courses'
                      courseImage={courseImage}
                      sendPostClick={() => setShowSendPost(true)}
                      commentPostClick={() => setShowCommentPost(true)}
                      sharePostClick={() => setShowSharePost(true)}
                    /> */}
                  </div>
                  <SendPostModal
                    setShowSendPost={setShowSendPost}
                    showSendPost={showSendPost}
                    postSnippet={selectedPost}
                    users={mentors}
                  />
                  <CommentPostModal
                    setShowCommentPost={setShowCommentPost}
                    showCommentPost={showCommentPost}
                    postSnippet={selectedPost}
                    users={mentors}
                  />
                  <SharePostModal
                    setShowSharePost={setShowSharePost}
                    showSharePost={showSharePost}
                    postSnippet={selectedPost}
                    users={mentors}
                  />
                  <UploadSessionModal
                    setShowCreateEventModal={setShowCreateSession}
                    showCreateEventModal={showCreateSession}
                    userId={user._id}
                  />
                  <button
                    onClick={() => {
                      setSkip(skip + 30);
                      setLimit(limit + 30);
                      moreFeeds();
                    }}
                  >
                    SHOW MORE FEEDS
                  </button>
                </main>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const SendInvitationModal = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const user = useSelector((state) => state.user.user);
  const invitation = useSelector((state) => state?.invitation);

  const handleInvitation = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      sentBy: user._id,
      sentUser: `${user.firstname} ${user.lastname}`,
      category: 'general',
    };

    dispatch(invitationActions.createInvitation(payload));
  };

  useEffect(() => {
    if (invitation?.success?.status) {
      setEmail('');
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Email Invitation has been sent',
      });
    }
  }, [invitation]);

  return (
    <>
      {props.showSendInvitationModal ? (
        <>
          <ModalWrapper
            title={'Invite a friend'}
            closeModal={() => props.setShowSendInvitationModal(false)}
            bodyContent={
              <div className='relative'>
                <form onSubmit={handleInvitation}>
                  <label className='font-semibold text-textparagraph text-sm'>
                    Add Email
                  </label>
                  <div className='grid grid-cols-6'>
                    <div className='col-span-4'>
                      <input
                        required
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                      />
                    </div>
                    <div className='col-span-2'>
                      <button
                        style={{ float: 'right' }}
                        className='w-40 h-12 falsefocus:shadow-outline  bg-darkerblue text-white text-xl px-2 py-2 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                        type='submit'
                      >
                        Invite
                      </button>
                    </div>
                  </div>
                  <br />
                </form>

                {invitation?.data?._id ? (
                  <>
                    <label className='font-light text-textparagraph text-sm'>
                      <b>Copy invitation link :</b>
                      <br /> {process.env.REACT_APP_HOST}/invitation/
                      {invitation.data._id}
                    </label>
                    <div className='col-span-12'>
                      <br />
                    </div>
                  </>
                ) : null}
              </div>
            }
          />
        </>
      ) : null}
    </>
  );
};
