import * as institutionTypes from '../types/institution';

const initialState = {
  data: [],
  count: null,
  yourMentor: [],
  institutionLearners: [],
  institution: {},
  loading: false,
  error: false,
  errorMessage: null,
  institutionCreation: false,
  createMentor: false,
  menter: [],
  invitedMentorsList: [],
  success: {
    status: false,
    message: 'All set!',
  },
};

const institutionReducer = (state = initialState, action) => {
  switch (action.type) {
    case institutionTypes.INSTITUTION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case institutionTypes.INSTITUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        institutionCreation: true,
        institution: action.payload.institution,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case institutionTypes.ADDED_INSTITUTION_LEARNER:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case institutionTypes.INSTITUTION_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case institutionTypes.INSTITUTION_PROFILEPIC:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case institutionTypes.INSTITUTION_MENTOR_CREATE:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        createMentor: true,
        menter: action.payload.menter,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case institutionTypes.INSTITUTION_MENTOR_PROFILEPIC:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case institutionTypes.YOUR_MENTOR:
      return {
        ...state,
        yourMentor: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case institutionTypes.INSTITUTION_LEARNER:
      return {
        ...state,
        institutionLearners: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case institutionTypes.GET_INVITED_MENTORS:
      return {
        ...state,
        invitedMentorsList: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case institutionTypes.GET_INSTITUTIONS:
      return {
        ...state,
        data: action.payload.institutions,
        count: action.payload.count,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case institutionTypes.GET_SPECIFIC_INSTITUTION:
      return {
        ...state,
        institution: action.payload.institution,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case institutionTypes.YOUR_SPECIFIC_MENTOR:
      return {
        ...state,
        menter: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case institutionTypes.INSTITUTION_MENTOR_INVITE:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case institutionTypes.INSTITUTION_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: false,
          message: 'All set!',
        },
      };
    default:
      return state;
  }
};

export default institutionReducer;
