import React from 'react';
import { useDispatch } from 'react-redux';
import * as chatActions from '../redux/actions/chat';

const ChatRequest = (props) => {
  const dispatch = useDispatch();
  // console.log(props)

  const updateRequestStatus = (props, status) => {
    dispatch(
      chatActions.submitChatRequestStatus({
        userId: props.data.userId,
        requestId: props.data._id,
        chatId: props.data.idOfWhatToJoin,
        requestStatus: status,
      }),
    );
  };

  return (
    <div className='message-row'>
      <div
        className='message'
        style={{
          backgroundColor: 'lightblue',
          color: '#000',
          marginBottom: '4px',
        }}
      >
        <span style={{ textTransform: 'capitalize' }}>
          {props.data.fullName + ' '}
        </span>
        has sent a join request.
        <button
          onClick={() => updateRequestStatus(props, 'accepted')}
          style={{
            border: '1px solid green',
            color: 'green',
            marginRight: '2px',
          }}
          className='inline-flex items-center font-medium text-sm sm:max-w-full justify-center h-8 w-20 px-0 sm:mr-0 tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:text-white focus:shadow-outline focus:outline-none'
        >
          Approve
        </button>
        <button
          onClick={() => updateRequestStatus(props, 'rejected')}
          style={{ border: '1px solid red', color: 'red' }}
          className='inline-flex items-center font-medium text-sm sm:max-w-full justify-center h-8 w-20 px-0 sm:mr-0 tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:text-white focus:shadow-outline focus:outline-none'
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default ChatRequest;
