import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowBack } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';
import { WithContext as ReactTags } from 'react-tag-input';
import ClipLoader from 'react-spinners/ClipLoader';

import * as helpers from '../helpers';

// Actions
import * as sessionsActions from '../redux/actions/sessions';
import { Link } from 'react-router-dom';
import NavHeader from '../components/NavBarHeader';

const SessionCreatePage = () => {
  return (
    <>
      <NavHeader />
      <PageBody />
    </>
  );
};

const PageBody = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const success = useSelector((state) => state.sessions.success);
  const session = useSelector((state) => state.sessions);

  const [loading, toggleLoading] = helpers.useToggle(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, SetStartTime] = useState('');
  const [duration, setDuration] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [speaker, setSpeaker] = useState('');
  const [price, setPrice] = useState('');
  const [photo, setPhoto] = useState('');

  const [tags, setTags] = useState([]);

  // Tag controllers
  const KeyCodes = {
    tab: 9,
    enter: 13,
    space: 32,
    comma: 188,
  };
  const delimiters = [
    KeyCodes.tab,
    KeyCodes.enter,
    KeyCodes.space,
    KeyCodes.comma,
  ];

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tagsText = tags.map((tag) => tag.text);
    const myForm = new FormData();
    myForm.append('sessionTitle', title);
    myForm.append('description', description);
    // Miriam, make sure to add the right tags that are meant to be here. Refer to the upload video
    // modal in the videosPage
    myForm.append('tags', `${tagsText.toString()}`);
    myForm.append('sessionDate', startDate);
    myForm.append('endDate', endDate);
    myForm.append('startTime', startTime);
    myForm.append('duration', duration);
    myForm.append('organiser', organizer);
    myForm.append('presenter', speaker);
    myForm.append('price', price);
    myForm.append('photo', photo[0]);

    dispatch(sessionsActions.createSession(myForm));
    // Updating the sessions data
    dispatch(sessionsActions.getMySessions());
  };

  useEffect(() => {
    if (success.message === 'Session Successfully created') {
      history.push('/session/all');
      if (session.length === 0) {
        dispatch(sessionsActions.getAllSessions());
      }
    }
  }, [history, success, session, dispatch]);

  return (
    <div className='bg-white h-full'>
      <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
        <div className=' py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
          <div className='flex justify-start'>
            <div className='mb-3 text-center md:mb-4 lg:mb-4'>
              <Link
                to='/feeds'
                className='inline-flex items-center justify-center w-full h-12 font-medium tracking-wide text-textsecondary transition duration-200  md:w-auto bg-none hover:text-navyblue focus:outline-none'
              >
                <ArrowBack />
                <span className='ml-2'>Back</span>
              </Link>
            </div>
          </div>
          <div className='flex flex-col mb-1 lg:justify-center lg:flex-row'>
            <div className='justify-center py-10 items-center flex overflow-x-hidden overflow-y-auto  outline-none focus:outline-none w-full'>
              <div className='relative w-auto my-auto mx-auto max-w-5xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-none outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-start justify-between p-5 rounded-t'>
                    <h2 className='max-w-lg mb-1 font-sans text-4xl font-bold tracking-tight text-black sm:text-4xl sm:leading-none group'>
                      <span className='inline-block'>Create a Session</span>
                    </h2>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <div className='mb-5 pt-0 w-full'>
                      <label className='font-semibold text-textparagraph text-sm'>
                        Title
                      </label>
                      <input
                        required
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        type='text'
                        className='mt-2 px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-linecolor outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                      />
                    </div>
                    <div className='mb-5 pt-0'>
                      <label className='font-semibold text-textparagraph text-sm'>
                        TAGS
                      </label>
                      <ReactTags
                        tags={tags}
                        suggestions={[]}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        delimiters={delimiters}
                        autocomplete={true}
                        placeholder={'Enter keywords relating to this article'}
                        maxLength={40}
                        classNames={{
                          tagInputField:
                            'px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full',
                        }}
                      />
                    </div>
                    <div className='mb-5 pt-0'>
                      <label className='font-semibold text-textparagraph text-sm'>
                        Description
                      </label>
                      <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={'Tell your viewers about the session'}
                        rows='5'
                        className='mt-2 px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-linecolor outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                      />
                    </div>
                    <div className='flex flex-row w-full justify-between mt-2 text-md text-darkerblue'>
                      <div className='mb-5 pt-0 w-full justify-center'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          Start Date
                        </label>
                        <input
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          type='date'
                          className='mt-2 px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-linecolor outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                      <div className='mb-5 pt-0 justify-center ml-3 w-full'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          End Date
                        </label>
                        <input
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          type='date'
                          className='mt-2 px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-linecolor outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                    </div>
                    <div className='flex flex-row w-full justify-between mt-2 text-md text-darkerblue'>
                      <div className='mb-5 pt-0 w-full justify-center'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          Start Time
                        </label>
                        <input
                          value={startTime}
                          onChange={(e) => SetStartTime(e.target.value)}
                          type='time'
                          className='mt-2 px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-linecolor outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                      <div className='mb-5 pt-0 justify-center ml-3 w-full'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          DURATION
                        </label>
                        <select
                          className='mt-2 px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-linecolor outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                        >
                          <option value='-----' selected>
                            {' '}
                            -----{' '}
                          </option>
                          <option value='1 Hr (60 mins)'>1 Hr (60 mins)</option>
                          <option value='2 Hrs (120 mins)'>
                            2 Hrs (120 mins)
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className='mb-5 pt-0 w-full'>
                      <label className='font-semibold text-textparagraph text-sm'>
                        Organizer
                      </label>
                      <input
                        value={organizer}
                        onChange={(e) => setOrganizer(e.target.value)}
                        type='text'
                        className='mt-2 px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-linecolor outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                      />
                    </div>
                    <div className='mb-5 pt-0 w-full'>
                      <label className='font-semibold text-textparagraph text-sm'>
                        Speaker
                      </label>
                      <input
                        value={speaker}
                        onChange={(e) => setSpeaker(e.target.value)}
                        type='text'
                        className='mt-2 px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-linecolor outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                      />
                    </div>
                    <div className='mb-5 pt-0 w-full'>
                      <label className='font-semibold text-textparagraph text-sm'>
                        Price
                      </label>
                      <p className='font-base text-textparagraph text-sm mb-2'>
                        Enter price for this session
                      </p>
                      <input
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        type='number'
                        min={0}
                        className='mt-2 px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-linecolor outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                      />
                    </div>

                    <div className='mb-5 pt-0'>
                      <label className='font-semibold text-textparagraph text-sm'>
                        Image
                      </label>
                      <DropzoneArea
                        filesLimit={1}
                        showAlerts={false}
                        maxFileSize={5000000}
                        acceptedFiles={['image/*']}
                        onChange={(files) => setPhoto(files)}
                      />
                    </div>
                  </div>
                  {/*footer*/}
                  <div className='items-center justify-end p-6 w-full'>
                    <button
                      className='w-full   focus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-6 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={(e) => {
                        toggleLoading();
                        handleSubmit(e);
                      }}
                      disabled={
                        loading ||
                        title === '' ||
                        description === '' ||
                        startDate === '' ||
                        endDate === '' ||
                        startTime === '' ||
                        duration === '' ||
                        organizer === '' ||
                        speaker === ''
                      }
                    >
                      {loading ? (
                        <ClipLoader color='navyblue' />
                      ) : (
                        'Create Session'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionCreatePage;
