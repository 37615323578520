import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonLoader from './ButtonLoader';
import PostSnippet from './PostSnippet';
import SessionSnippet from './SessionSnippet';
import TagsInput from './TagsInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as shareActions from '../redux/actions/shared';
import * as contentActions from '../redux/actions/content';
import ModalWrapper from './ModalWrapper';
import CustomAlert from './CustomAlert';

const SharePostModal = ({
  content,
  setShowSharePost,
  showSharePost,
  postSnippet,
  sessionSnippet,
  users,
}) => {
  const dispatch = useDispatch();
  const [showUserField, setShowUserField] = useState(true);
  const { success, error, errorMessage, loading } = useSelector(
    (state) => state.shared,
  );

  const isSession = content === 'Session';

  const [tags, setTags] = useState([]);

  const usersList = users?.filter(
    (user) => !user.usercategory?.includes('institution'),
  );

  const suggestions = [];

  usersList?.forEach((element, index) => {
    suggestions.push({
      id: element?._id,
      text: `${element?.firstname} ${element?.lastname}`,
    });
  });

  const initialValues = {
    sharewith: 'Everyone',
    shareText: `Hey, come check out this ${isSession ? 'session' : 'post'}.`,
  };

  const validationSchema = Yup.object({
    sharewith: Yup.string().required('Share with someone is required'),
    shareText: Yup.string().required('Post message is required'),
  });

  const onSubmit = (values, onSubmitProps) => {
    const shareList = tags.map((tag) => tag.id);
    const payload = {
      shareText: values.shareText,
      sharedModel: isSession ? 'Session' : 'Content',
      shared: isSession ? sessionSnippet : postSnippet,
      to: shareList,
    };

    dispatch(shareActions.shareContent(payload));
    onSubmitProps.resetForm();
    setTags([]);
  };

  useEffect(() => {
    if (success.message === 'Content share successful') {
      dispatch(contentActions.getAllContent());
      CustomAlert({
        alertType: 'success',
        alertMessage: `${isSession ? 'Session' : 'Post'} shared successfully`,
      });
    }
    if (error && errorMessage === 'Failed to share post') {
      dispatch(contentActions.getAllContent());

      CustomAlert({
        alertType: 'error',
        alertMessage: `Failed to share ${isSession ? 'session' : 'post'}`,
      });
    }
    setShowSharePost(false);
  }, [success, errorMessage]);

  return (
    <>
      {showSharePost ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, getFieldProps, isValid, isSubmitting }) => (
              <Form>
                <ModalWrapper
                  title={'Share'}
                  closeModal={() => setShowSharePost(false)}
                  bodyContent={
                    <>
                      {' '}
                      <div className='relative'>
                        <div className=''>
                          <div className=''>
                            <div>
                              <label className='font-semibold text-textparagraph text-sm'>
                                Share with
                              </label>
                              <div>
                                <select
                                  onChange={(e) =>
                                    e.target.value === 'Specific'
                                      ? setShowUserField(false)
                                      : setShowUserField(true)
                                  }
                                  className='rounded-full  py-3 px-6 mr-4 w-6/12 text-textparagraph  bg-lightgraybg justify-start text-sm font-base border border-linecolor  ring-opacity-50 hover:border-darkerblue focus:outline-noone'
                                >
                                  <option value='Everyone'>Everyone</option>

                                  <option value='Specific'>
                                    Specific users
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className='pt-6' hidden={showUserField}>
                            <label className='font-semibold text-textparagraph text-sm'>
                              Enter users to share with
                            </label>

                            <TagsInput
                              tags={tags}
                              setTags={setTags}
                              suggestions={suggestions || []}
                              placeholder={
                                'Select users you would like to share with...'
                              }
                            />
                          </div>

                          <div className='pt-6'>
                            <textarea
                              className='flex-grow w-full p-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='shareText'
                              name='shareText'
                              placeholder={'Type your post here..'}
                              rows='3'
                              {...getFieldProps('shareText')}
                            />
                            {touched.shareText && errors.shareText ? (
                              <span className='text-sm text-red'>
                                {errors.shareText}
                              </span>
                            ) : null}
                          </div>

                          <div className='pt-6'>
                            {isSession ? (
                              <SessionSnippet
                                key={sessionSnippet?._id}
                                session={sessionSnippet}
                                users={users}
                              />
                            ) : (
                              <PostSnippet
                                key={postSnippet?._id}
                                content={postSnippet}
                                users={users}
                              />
                            )}

                            {/*  */}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  footer={true}
                  footerContent={
                    <>
                      <button
                        className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                        type='submit'
                        disabled={!isValid || loading}
                      >
                        {loading ? <ButtonLoader /> : 'Save'}
                      </button>
                    </>
                  }
                />
              </Form>
            )}
          </Formik>
        </>
      ) : null}
    </>
  );
};

export default SharePostModal;
