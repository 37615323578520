import { useState, useCallback } from 'react';
import { embedUrlParserService } from './embedUrlParserService';

// Toggles options
export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => {
    setValue((v) => !v);
  }, []);
  return [value, toggle];
};

// Capitalises a strings first character
export const capitalizeFirst = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

// Converts the ISOdate to '01 Aug 2021' format
export const dateFormatter = (ISOdate) =>
  new Date(ISOdate).toUTCString().split(' ').slice(1, 4).join(' ');

// Sanitizes the embed url
export const embedUrlSanitizer = (link) => embedUrlParserService(link);

export const isLinkValid = (link) => {
  let regex = /^https:\/\/calendly\.com\//g;
  return regex.test(link);
};
