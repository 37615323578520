const base = `${process.env.REACT_APP_BACKEND_URL}`;
const endpoints = {
  create_user: `${base}/user`,
  confirm_user: `${base}/user/confirm`,
  log_in: `${base}/auth/login`,
  google_log_in: `${base}/auth/google-login`,
  log_out: `${base}/auth/logout`,
  update_user: `${base}/user`,
  delete_user_account: `${base}/user`,
  update_image: `${base}/user/image`,
  add_interests: `${base}/user/interests`,
  user_profile: `${base}/user/profile`,
  get_users: `${base}/user`,
  get_user_by_ID: `${base}/user`,
  get_mentors: `${base}/user/mentors`,
  reset_password: `${base}/auth/resetpassword`,
  user_follow: `${base}/user/follow`,
  user_points: `${base}/user/points`,
  rejected_points: `${base}/points/reject_post`,
};

export default endpoints;
