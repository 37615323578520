import * as sessionsTypes from '../types/sessions';

const initialState = {
  data: [],
  mySessions: [],
  sessionCreation: false,
  session: [],
  loading: false,
  error: false,
  errorMessage: null,
  success: {
    status: false,
    message: 'All set!',
  },
};

const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case sessionsTypes.SESSIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case sessionsTypes.SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        sessionCreation: true,
        session: action.payload.session,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case sessionsTypes.SESSIONS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case sessionsTypes.ALL_SESSIONS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case sessionsTypes.ALL_UNAUTH_SESSIONS:
      return {
        ...state,
        data: action.payload,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case sessionsTypes.MY_SESSIONS:
      return {
        ...state,
        mySessions: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case sessionsTypes.SESSIONS_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: false,
          message: 'All set!',
        },
      };
    case sessionsTypes.SPECIFIC_SESSION:
      return {
        ...state,
        session: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case sessionsTypes.REGISTER_FOR_SESSION:
      return {
        ...state,
        registrationStatus: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default sessionsReducer;
