import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { People, Message } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import '@kenshooui/react-multi-select/dist/style.css';

import Rejected from '../assets/Rejected.svg';

import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import CustomAlert from '../components/CustomAlert';
import ButtonLoader from '../components/ButtonLoader';
import TagsInput from '../components/TagsInput';

// Actions
import * as userActions from '../redux/actions/users';
import * as discussionGroupsActions from '../redux/actions/discussiongroups';

const PageBody = () => {
  const dispatch = useDispatch();
  const { loading, success, count, discussionGroupsList } = useSelector(
    (state) => state?.discussiongroups,
  );
  const PageLoading = useSelector((state) => state?.institution.loading);
  const { user, data } = useSelector((state) => state?.user);
  const userList = data?.filter(
    (value) => value.usercategory[0] !== 'institution',
  );

  const [skip, setSkip] = useState(0);
  const [showCreateNewGroup, setShowCreateNewGroup] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [myFilteredGroups, setMyFilteredGroups] = useState([]);
  const limit = 30;

  useEffect(() => {
    dispatch(discussionGroupsActions.getAllDiscussionGroups({ limit }));
    dispatch(userActions.getUsers());
  }, []);

  const filteredGroups = discussionGroupsList.filter((group) =>
    group.admins.includes(user._id),
  );

  useEffect(() => {
    if (groupName !== '') {
      let myFilteredGroupArray = [];
      discussionGroupsList
        ?.filter((item) => item.groupName.toLowerCase().includes(groupName))
        .map((item) => {
          return myFilteredGroupArray.push(item);
        });
      setMyFilteredGroups(myFilteredGroupArray);
    } else {
      setMyFilteredGroups(discussionGroupsList);
    }

    //console.log(myFilteredGroups.length)
  }, [groupName]);

  return (
    <>
      <Nav groupsActive={true} />
      {PageLoading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div
          className={`bg-lightgraybg ${
            filteredGroups.length < 6 ? 'h-screen' : 'h-full'
          }`}
        >
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  font-display'>
            <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
              <div className='flex flex-col lg:justify-between lg:flex-row'>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block'>
                    {`All Groups`} (
                    {myFilteredGroups.length === 0
                      ? discussionGroupsList.length
                      : myFilteredGroups.length || '0'}
                    )
                  </span>
                </h2>

                <div className='text-center'>
                  <button
                    className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'
                    onClick={() => setShowCreateNewGroup(true)}
                  >
                    <People />
                    <span className='ml-2'>Create</span>
                  </button>
                </div>
              </div>

              <input
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                style={{ border: '1px solid grey', width: '33%' }}
                className='rounded-input px-6 md:mb-8 mb-8'
                placeholder='Search Groups'
                type='search'
              />

              {myFilteredGroups.length === 0 ? (
                <GroupsSection
                  groupList={discussionGroupsList}
                  users={userList}
                  user={user}
                  loading={loading}
                  success={success}
                />
              ) : (
                <GroupsSection
                  groupList={myFilteredGroups}
                  users={userList}
                  user={user}
                  loading={loading}
                  success={success}
                />
              )}

              {skip > limit ? (
                <button
                  onClick={() => {
                    if (skip > limit) setSkip(skip - limit);
                    dispatch(
                      discussionGroupsActions.getAllDiscussionGroups({
                        skip,
                        limit,
                      }),
                    );
                  }}
                >
                  {'<< Previous'}
                </button>
              ) : null}
              {skip + limit < count ? (
                <>
                  <span> | </span>
                  <button
                    onClick={() => {
                      setSkip(skip + limit);
                      dispatch(
                        discussionGroupsActions.getAllDiscussionGroups({
                          skip,
                          limit,
                        }),
                      );
                    }}
                  >
                    {'Next >>'}
                  </button>
                </>
              ) : null}

              <CreateNewGroup
                setShowCreateNewGroup={setShowCreateNewGroup}
                showCreateNewGroup={showCreateNewGroup}
                userList={userList}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CreateNewGroup = (props) => {
  const dispatch = useDispatch();
  const { success, error, errorMessage } = useSelector(
    (state) => state.discussiongroups,
  );

  const user = useSelector((state) => state.user.user);
  const keywords = useSelector((state) => state.disciplines.keywordList);
  const userId = user?._id;
  const [groupName, setGroupName] = useState('');
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState('');
  const admin = [`${userId}`];

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tagsText;

    tagsText = tags.map((tag) => tag.text);

    dispatch(
      discussionGroupsActions.createDiscussionGroup({
        groupName: groupName,
        hashTag: tagsText.toString(),
        description: description,
        admins: admin,
        members: admin,
      }),
    );

    setTags([]);
    setGroupName('');
    setDescription('');
  };

  useEffect(() => {
    const limit = 30;
    props.setShowCreateNewGroup(false);
    if (success.message === 'Discussion group Successfully created') {
      dispatch(discussionGroupsActions.getAllDiscussionGroups({ limit }));
      if (error)
        if (!`${errorMessage}`.includes('NetworkError')) {
          CustomAlert({
            alertType: 'error',
            alertMessage: 'Network error!',
          });
        }
    }
  }, [success, error]);

  return (
    <>
      {props.showCreateNewGroup ? (
        <>
          <div
            style={{
              width: '70%',
              height: '100vh',
              overflowY: 'hidden',
              marginLeft: '15%',
            }}
            className='modalClass items-start flex overflow-x-hidden overflow-y-auto lg:pt-4 fixed inset-0  '
          >
            <div className='relative w-auto lg:w-8/12 mx-auto max-w-5xl  '>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none h-modal '>
                {/*header*/}
                <div className='h-full'>
                  <div className='flex items-start justify-between px-8 pb-2 pt-8 rounded-t h-2/12 border-b border-linecolor'>
                    <h3 className='text-1xl font-display font-bold text-charcoal'>
                      Create a new group
                    </h3>
                    <button
                      className='p-1 ml-auto  text-black float-right text-3xl leading-none font-semibold '
                      onClick={() => props.setShowCreateNewGroup(false)}
                    >
                      <span className='text-charcoal h-6 w-6 text-2xl  hover:text-reddark'>
                        ×
                      </span>
                    </button>
                  </div>

                  {/* </div>  */}
                  <div style={{ padding: '20px' }}>
                    <form onSubmit={handleSubmit}>
                      <div className=''>
                        <label className='font-semibold text-textparagraph text-sm'>
                          Group Name
                        </label>
                        <input
                          required
                          type='text'
                          value={groupName}
                          onChange={(e) => setGroupName(e.target.value)}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                      <div className=''>
                        <label className='font-semibold text-textparagraph text-sm'>
                          TAGS
                        </label>
                        <TagsInput
                          tags={tags}
                          setTags={setTags}
                          suggestions={keywords || []}
                          placeholder={`Enter keywords relating to this group`}
                        />
                      </div>

                      <div className='pt-6'>
                        <label className='font-semibold text-textparagraph text-sm'>
                          Description
                        </label>
                        <textarea
                          rows='5'
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder='Description'
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>

                      <br />

                      <button
                        className='w-full h-14 falsefocus:shadow-outline  bg-darkerblue text-white text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                        type='submit'
                        onClick={(e) => handleSubmit(e)}
                      >
                        Create
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-60 modalOverlayClass fixed inset-0 bg-black  flex justify-center '></div>
        </>
      ) : null}
    </>
  );
};

const GroupsSection = (props) => {
  const [showMembersModal, setShowMembersModal] = useState(false);
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (props.success.message === 'Request to join submitted') {
      dispatch(discussionGroupsActions.getAllDiscussionGroups({}));
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Join Request submitted successfully',
      });
    }
  }, [props.success]);

  return (
    <>
      <div className='flex flex-col mb-6 lg:justify-between md:mb- shadow '>
        <div className='grid grid-cols-1 gap-6 row-gap-4 mb-8'>
          <div className=' pb-10 mt-6 p-4'>
            <div className='grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3'>
              {props.groupList?.map((value, key) => (
                <GroupsCard
                  users={props.users}
                  group={value}
                  key={key}
                  setShowMembersModal={() => {
                    setShowMembersModal(true);
                    setSelectedGroup(value);
                  }}
                />
              ))}
            </div>
          </div>
        </div>

        <ViewMembersList
          setShowMembersModal={setShowMembersModal}
          showMembersModal={showMembersModal}
          userList={props.users}
          currentUser={props.user}
          loading={props.loading}
          selectedGroup={selectedGroup}
          members={props.groupList}
        />
      </div>
    </>
  );
};

const GroupsCard = ({ group, setShowMembersModal, users }) => {
  //const user = useSelector((state) => state.user.user);

  const overviewProfilePics = [];
  if (group?.members.length > 5) {
    for (let index = 0; index < 5; index++) {
      overviewProfilePics.push(group?.members[index]);
    }
  } else {
    group?.members.map((value) => overviewProfilePics.push(value));
  }

  // This extracts the institution created mentors from the other mentors self registered
  const filteredList = users?.filter((user) =>
    overviewProfilePics.includes(user._id),
  );

  //var userFirstname = user?.firstname;
  //var userId = user?._id;
  var groupId = group._id;

  return (
    <div className='px-5 py-6 duration-300 transform bg-white border border-linecolor rounded-lg hover:-translate-y-2'>
      <div className='flex flex-col mb-4 justify-between h-full'>
        <h6 className='mb-2 font-semibold leading-5 text-xl text-charcoal'>
          {group?.groupName}
        </h6>
        <p className='text-headingtext text-base'>{`${
          group?.members.length
        } member${group?.members.length === 1 ? '' : 's'}`}</p>

        <div className='mt-4'>
          <div className='flex items-center pl-4'>
            {filteredList.map((pic, key) => (
              <img
                key={pic._id}
                src={
                  pic?.profilePicture?.publicUrl ||
                  `https://ui-avatars.com/api/?background=random&name=${pic?.firstname}+${pic?.lastname}&bold=true`
                }
                className='shadow-md w-8 h-8 object-cover rounded-full -ml-4 ring-2 ring-white'
                alt='Profile-img'
              />
            ))}
          </div>
        </div>
        <div className='mt-4 flex flex-row justify-between'>
          <span
            onClick={() => setShowMembersModal(true)}
            className='text-4xl cursor-pointer text-darkerblue bg-none hover:text-navyblue inline-flex items-center justify-center mr-8 lg:mr-4'
          >
            <People fontSize='inherit' />
          </span>

          <a
            href={`/ChatRoom/${groupId}`}
            className='text-4xl cursor-pointer text-darkerblue bg-none hover:text-navyblue inline-flex items-center justify-center mr-8 lg:mr-4'
          >
            <Message style={{ fontSize: '35px', marginRight: '-20px' }} />
          </a>
        </div>
      </div>
    </div>
  );
};

const ViewMembersList = (props) => {
  const group = props.members?.filter(
    (value) => value.groupName === props.selectedGroup?.groupName,
  );
  const members = group[0]?.members;

  let memberData = [];
  members?.forEach((element) => {
    const user = props.userList?.filter((item) => item._id === element);
    memberData.push(user);
  });

  const dispatch = useDispatch();

  const { success } = useSelector((state) => state?.discussiongroups);
  const [sendJoinRequest, setSendJoinRequest] = useState(false);

  const handleSendRequest = () => {
    if (props.selectedGroup !== null) {
      setSendJoinRequest(true);
      dispatch(
        discussionGroupsActions.joinDiscussionGroup({
          userId: props.currentUser._id,
          fullName: `${props.currentUser.firstname} ${props.currentUser.lastname}`,
          whatToJoin: 'discoussiongroup',
          idOfWhatToJoin: props.selectedGroup._id,
          nameOfWhatToJoin: props.selectedGroup.groupName,
          toJoinAs: 'member',
          canBeAcceptedBy: [...props.selectedGroup.admins],
        }),
      );
    }
  };

  useEffect(() => {
    if (success.message === 'Request to join submitted') {
      setSendJoinRequest(false);
    }
  }, [success]);

  return (
    <>
      {props.showMembersModal ? (
        <>
          <div
            style={{ width: '70%', overflowY: 'hidden', marginLeft: '15%' }}
            className='modalClass items-start flex overflow-x-hidden overflow-y-auto lg:pt-4 fixed inset-0  '
          >
            <div className='relative w-auto lg:w-8/12 mx-auto max-w-5xl  '>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none h-modal '>
                {/*header*/}
                <div className='h-full'>
                  <div className='flex items-start justify-between px-8 pb-2 pt-8 rounded-t h-2/12 border-b border-linecolor'>
                    <h3 className='text-1xl font-display font-bold text-charcoal'>
                      {memberData.length > 0 && `(${memberData?.length})`}
                      {` Members of `}
                      {props.selectedGroup.groupName}
                    </h3>

                    <button
                      onClick={() => {
                        handleSendRequest();
                      }}
                      style={{ marginLeft: '20px', marginTop: '-5px' }}
                      className='inline-flex items-center font-medium text-sm sm:max-w-full justify-center h-8 w-20 px-1 sm:mr-0  border border-darkerblue tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                    >
                      {sendJoinRequest ? (
                        <ButtonLoader color='primary' />
                      ) : (
                        <div>
                          <span>Join</span>
                        </div>
                      )}
                    </button>

                    <button
                      className='p-1 ml-auto  text-black float-right text-3xl leading-none font-semibold '
                      onClick={() => props.setShowMembersModal(false)}
                    >
                      <span className='text-charcoal h-6 w-6 text-2xl  hover:text-reddark'>
                        ×
                      </span>
                    </button>
                  </div>

                  {/* </div>  */}
                  <div className=' overflow-y-auto h-10/12 '>
                    <div className='px-4 sm:px-12 md:px-12 pb-8  relative'>
                      <div className='pt-6'>
                        {memberData.length > 0 ? (
                          memberData.map((value, key) => (
                            <MemberCard key={value._id} member={value} />
                          ))
                        ) : (
                          <NoMembers />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-60 modalOverlayClass fixed inset-0 bg-black  flex justify-center '></div>
        </>
      ) : null}
    </>
  );
};

const NoMembers = () => {
  return (
    <div className='max-w-screen-sm sm:text-center sm:mx-auto mt-8'>
      <span
        aria-label='No upcoming events'
        className='inline-block  rounded-full sm:mx-auto'
      >
        <div className='flex items-center justify-center w-32 h-32 mb-8 rounded-full'>
          <img
            src={Rejected}
            alt='No requests'
            className='object-cover h-full w-full mb-2'
          />
        </div>
      </span>
      <h2 className='mb-4 font-sans text-2xl font-bold tracking-tight text-headingtext sm:text-xl sm:leading-none'>
        {`No members registered yet`}
      </h2>
    </div>
  );
};

const MemberCard = ({ member }) => {
  const history = useHistory();
  return (
    <div
      className={`relative p-px mb-4 overflow-hidden transition duration-300 transform border-l-4 border-white group hover:shadow-sm hover:border-darkerblue `}
    >
      <div>
        <div className='relative flex flex-col h-full p-2 bg-white rounded-sm lg:items-center lg:flex-row lg:justify-between w-full '>
          <div className='flex flex-row justify-start '>
            <div className='flex items-center justify-between'>
              <div className='flex'>
                <img
                  src={
                    member[0]?.profilePicture?.publicUrl ||
                    `https://ui-avatars.com/api/?name=${member[0]?.firstname}+${member[0]?.lastname}&bold=true`
                  }
                  alt='Event Thumb'
                  className='object-cover w-10 h-10 mr-1 lg:mr-4 rounded-full shadow'
                />
              </div>
            </div>
            <div className='flex flex-col justify-center lg flex-grow text-charcoal'>
              <div>
                <h2 className='text-sm font-body text-charcoal capitalize'>
                  {`${member[0]?.firstname} ${member[0]?.lastname}`}
                </h2>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 gap-5'>
            <button
              onClick={() => history.push(`/users/profile/${member[0]._id}`)}
              className='inline-flex items-center font-medium text-sm sm:max-w-full justify-center h-8 w-30 px-2 sm:mr-0  border border-darkerblue tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
            >
              Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Groups() {
  return (
    <>
      <PageBody />
    </>
  );
}
