/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import logo from '../assets/delv_bold2.svg';

import { ReactComponent as Facebook } from '../assets/facebookfooter.svg';
import { ReactComponent as Twitter } from '../assets/twitterfooter.svg';
import { ReactComponent as Linkedin } from '../assets/linkedinfooter.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  const date = new Date();
  return (
    <div className='px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 bg-black2 text-white'>
      <div className='grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4'>
        <div className='sm:col-span-2'>
          <a
            href='/'
            aria-label='Go home'
            title='Delv Careers'
            className='inline-flex items-center'
          >
            <img
              src={logo}
              alt='Delv-logo'
              className='object-scale-down w-20 sm:w-32 '
            />
          </a>
          <div className='mt-6 lg:max-w-sm'>
            <p className='text-sm text-gray6'>
              {date.getFullYear()} Delv all rights reserved
            </p>
          </div>
        </div>
        <div className='space-y-2 text-sm'>
          <p className='text-base font-bold tracking-wide text-gray-900'>
            Company
          </p>
          <Link to='/aboutus'>
            <div className='flex text-gray6 mb-4'>
              <div
                aria-label='About us'
                title='About us'
                className='transition-colors duration-300 hover:text-white'
              >
                About Us
              </div>
            </div>
          </Link>

          {/* <div className='flex text-gray6 mb-4'>
            <a
              href='/'
              aria-label='Our phone'
              title='Our phone'
              className='transition-colors duration-300 hover:text-white'
            >
              Contact Us
            </a>
          </div>
          <div className='flex text-gray6 mb-4'>
            <a
              href='tel:850-123-5021'
              aria-label='Our phone'
              title='Our phone'
              className='transition-colors duration-300 hover:text-white'
            >
              Blog
            </a>
          </div> 
          <div className='flex text-gray6 mb-4'>
            <a
              href='tel:850-123-5021'
              aria-label='Our phone'
              title='Our phone'
              className='transition-colors duration-300 hover:text-white'
            >
              Help and Support
            </a>
          </div>
          <div className='flex text-gray6 mb-4'>
            <a
              href='tel:850-123-5021'
              aria-label='Our phone'
              title='Our phone'
              className='transition-colors duration-300 hover:text-white'
            >
              Careers
            </a>
          </div>*/}
        </div>
        <div>
          <span className='text-base font-bold tracking-wide text-gray-900'>
            Social
          </span>
          <div className='flex items-center mt-1 space-x-3'>
            <a
              href='/'
              target='_blank'
              rel='noreferrer noopener'
              className='text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400'
            >
              <span className='rounded-full h-8 w-8 flex items-center justify-center bg-charcoal text-white'>
                <Facebook />
              </span>
            </a>
            <a
              href='https://twitter.com/intent/follow?screen_name=DELV_careers'
              target='_blank'
              rel='noreferrer noopener'
              className='text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400'
            >
              <span className='rounded-full h-8 w-8 flex items-center justify-center bg-charcoal text-white'>
                <Twitter />
              </span>
            </a>
            <a
              href='https://www.linkedin.com/company/delvcareers'
              target='_blank'
              rel='noreferrer noopener'
              className='text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400'
            >
              <span className='rounded-full h-8 w-8 flex items-center justify-center bg-charcoal text-white'>
                <Linkedin />
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className='flex flex-col-reverse justify-between pt-5 pb-10 border-t border-gray6 lg:flex-row'>
        <p className='text-sm text-gray6'>
          © Copyright {date.getFullYear()} Delv Career Pathways Inc. All rights
          reserved.
        </p>
        <ul className='flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row'>
          <li>
            <a
              href='/'
              className='text-sm text-gray6 transition-colors duration-300 hover:text-white'
            >
              F.A.Q
            </a>
          </li>
          <li>
            <a
              href='/'
              className='text-sm text-gray6 transition-colors duration-300 hover:text-white'
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href='/'
              className='text-sm text-gray6 transition-colors duration-300 hover:text-white'
            >
              Terms &amp; Conditions
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
