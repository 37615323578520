import * as fetchFunctions from '../../api/index';
import * as claimTypes from '../types/claim';
import claimEndpoints from '../endpoints/claim';

export const getAllClaims = () => async (dispatch, getState) => {
  dispatch({
    type: claimTypes.CLAIM_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    claimEndpoints.get_all_claims,
    token,
  );

  if (result.success) {
    dispatch({
      type: claimTypes.ALL_CLAIMS,
      payload: result.data,
    });
    dispatch({
      type: claimTypes.CLAIM_RESET,
    });
  } else {
    dispatch({
      type: claimTypes.CLAIM_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getAllClaimedPoints = () => async (dispatch, getState) => {
  dispatch({
    type: claimTypes.CLAIM_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    claimEndpoints.get_all_claimed_points,
    token,
  );

  if (result.success) {
    dispatch({
      type: claimTypes.CLAIMED_POINTS,
      payload: result.data,
    });
    dispatch({
      type: claimTypes.CLAIM_RESET,
    });
  } else {
    dispatch({
      type: claimTypes.CLAIM_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const updateClaim = (payload) => async (dispatch, getState) => {
  dispatch({
    type: claimTypes.CLAIM_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    claimEndpoints.update_claim,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: claimTypes.UPDATE_CLAIM,
      payload: {
        status: true,
        message: 'Claim Successfully updated',
      },
    });
    dispatch({
      type: claimTypes.CLAIM_RESET,
    });
  } else {
    dispatch({
      type: claimTypes.CLAIM_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const addClaim = (payload) => async (dispatch, getState) => {
  dispatch({
    type: claimTypes.CLAIM_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    claimEndpoints.add_claim,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: claimTypes.CLAIM_SUCCESS,
      payload: {
        status: true,
        message: 'Claim added successfully',
      },
    });
    dispatch({
      type: claimTypes.CLAIM_RESET,
    });
  } else {
    dispatch({
      type: claimTypes.CLAIM_ERROR,
      payload: {
        error: true,
        errorMessage: 'Failed to submit claim',
      },
    });
  }
};
