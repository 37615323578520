import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import VideoPlaceholder from '../assets/video1.svg';
import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import NavHeader from '../components/NavBarHeader';
import * as userActions from '../redux/actions/users';
import * as sessionActions from '../redux/actions/sessions';
import { Cast } from '@material-ui/icons';
import moment from 'moment';
import NoContentPlaceholder from '../components/NoContentPlaceholder';

const Filters = ({
  sessionsList,
  mentorsList,
  filteredSessions,
  setFilteredSessions,
  setShowRegisteredSession,
  showRegisteredSession,
  user,
}) => {
  const [allSessionsFilter, setAllSessionsFilter] = useState(true);

  const allSessionsCheckbox = () => {
    setFilteredSessions([]);
    const allCheckbox = document.getElementById('all').checked;
    if (allCheckbox) setFilteredSessions(sessionsList);
    setAllSessionsFilter(true);
    setShowRegisteredSession(false);
  };

  const chooseMentor = (e) => {
    let mentor = e.target.value;
    const sessions = sessionsList?.filter(
      (value) => value?.presenter === mentor,
    );
    setAllSessionsFilter(false);
    setFilteredSessions(sessions);
    setShowRegisteredSession(false);
  };

  const showSubscribed = (e) => {
    const sessions = sessionsList?.filter((value) =>
      value?.registeredAttendees?.includes(user?._id),
    );

    setShowRegisteredSession(!showRegisteredSession);
    // setAllSessionsFilter(false);
    setFilteredSessions(sessions);
  };

  return (
    <>
      <div className='filter-group mt-2'>
        {/* By Country*/}
        <h1 className='text-base text-charcoal mt-2 lg:mt-9 font-display'>
          By Mentor
        </h1>

        <div className='flex items-center mt-5 font-display'>
          <input
            id='all'
            type='checkbox'
            name='country'
            className='form-checkbox text-charcoal font-display'
            onChange={(e) => {
              allSessionsCheckbox(e);
            }}
            checked={allSessionsFilter}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>All Mentors</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>

        <div className='flex items-center mt-5 font-display'>
          <select
            className='flex-grow capitalize w-full h-10 px-4 mb-2 transition duration-200 bg-white border border-linecolor rounded  appearance-none font-darkerblue focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
            id='specialization'
            name='specialization'
            onChange={chooseMentor}
          >
            <option>Select mentor</option>
            {mentorsList?.map((value, key) => (
              <option
                key={key}
                value={`${value.firstname} ${value.lastname}`}
                className='capitalize'
              >{`${value.firstname} ${value.lastname}`}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Relevance */}
      <div className='filter-group mt-2'>
        <h1 className='text-base text-charcoal mt-9 font-display'>
          By Session
        </h1>
        <div className='flex items-center mt-5'>
          <input
            id='registeredSessions'
            type='checkbox'
            name='registered'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={(e) => {
              e.target.checked ? showSubscribed(e) : allSessionsCheckbox(e);
            }}
            checked={showRegisteredSession}
          />
          <p className='ml-4 mr-10 text-base text-charcoal font-display'>
            Subscribed
          </p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-base'> </span>
          </div>
        </div>

        <div className='flex items-center mt-5'>
          <input
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={(e) => {
              e.target.checked
                ? setFilteredSessions([
                    ..._.orderBy(filteredSessions, ['sessionDate'], ['desc']),
                  ])
                : allSessionsCheckbox();
            }}
          />
          <p className='ml-4 mr-10 text-base text-charcoal font-display'>
            Latest
          </p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-base'> </span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={(e) => {
              e.target.checked
                ? setFilteredSessions([
                    ..._.orderBy(
                      filteredSessions,
                      function (session) {
                        return session.registeredAttendees.length;
                      },
                      ['desc'],
                    ),
                  ])
                : allSessionsCheckbox();
            }}
          />
          <p className='ml-4 mr-10 text-base text-charcoal font-display'>
            Most popular
          </p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-base'> </span>
          </div>
        </div>

        <div className='flex items-center mt-10'>
          <div className='mx-auto lg:mx-0 items-center mb-2 w-full'>
            {user?.usercategory[0] === 'mentor' ? (
              <Link to='/sessions/mylist'>
                <button className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'>
                  <Cast />
                  <span className='ml-2'>My Sessions</span>
                </button>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const MobileFilters = ({ showFilters, setShowFilters }) => {
  return (
    <div
      className={
        showFilters
          ? 'w-full h-screen shadow-md absolute z-40  transform  translate-x-0 lg:hidden'
          : '   w-full h-full absolute z-40  transform -translate-x-full'
      }
    >
      <div
        className='bg-black opacity-50 w-full h-screen absolute  '
        onClick={() => setShowFilters(!showFilters)}
      ></div>
      <div className='w-64 md:w-72 absolute z-40 bg-white shadow h-full flex-col justify-between lg:hidden pb-4 transition duration-150 ease-in-out'>
        <div className='flex flex-col justify-between h-full'>
          <div>
            <div className='flex items-center justify-between px-8'>
              <div className='h-16 w-full flex items-center'>
                <span className='xl:text-base md:text-2xl text-base ml-2 text-main'>
                  Filter Sessions
                </span>
              </div>
              <div
                id='closeSideBar'
                className='flex items-center justify-center h-10 w-10'
                onClick={() => setShowFilters(!showFilters)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='icon icon-tabler icon-tabler-x'
                  width={20}
                  height={20}
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' />
                  <line x1={18} y1={6} x2={6} y2={18} />
                  <line x1={6} y1={6} x2={18} y2={18} />
                </svg>
              </div>
            </div>
            <div className='px-8 overflow-y-auto overscroll-contain h-screen bottom-5'>
              <div className='block  sm:ml-8 mb-10 text-headingtext font-sans sm:text-sm'>
                <Filters />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function SessionsPage() {
  const [showRegisteredSession, setShowRegisteredSession] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const mentors = useSelector((state) => state?.user?.data);
  const { data, loading } = useSelector((state) => state.sessions);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const sessions = data?.filter(
    (session) => moment(session.sessionDate) >= moment(),
  );
  console.log(sessions);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(userActions.getMentors({}));
    dispatch(sessionActions.getAllSessions());
  }, []);

  useEffect(() => {
    setFilteredSessions([...sessions]);
  }, [data]);

  return (
    <>
      {user ? <Nav sessionsActive={true} /> : <NavHeader />}
      {loading ? (
        <div className='bg-lightgraybg w-full h-screen '>
          <Loading />
        </div>
      ) : (
        <div
          className={`bg-lightgraybg w-full ${
            filteredSessions.length < 4 ? 'h-screen' : 'h-full'
          }`}
        >
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-2 lg:px-8'>
              <div className='flex flex-row justify-around '>
                <div className='hidden lg:w-2/12 border-r-2  border-gray3 pr-6  lg:flex'>
                  <div className=' py-6 lg:py-16 block mt-5 mb-10 text-headingtext font-sans sm:text-sm fixed'>
                    <span className='text-base text-charcoal font-display'>
                      Filter Sessions
                    </span>
                    <Filters
                      sessionsList={sessions}
                      mentorsList={mentors}
                      filteredSessions={filteredSessions}
                      setFilteredSessions={setFilteredSessions}
                      user={user}
                      setShowRegisteredSession={setShowRegisteredSession}
                      showRegisteredSession={showRegisteredSession}
                    />
                  </div>
                </div>
                <MobileFilters showFilters={show} setShowFilters={setShow} />
                <div className=' py-6 lg:py-16 flex flex-col pb-5 md:px-2 lg:pl-8 mt-5 mb-10 text-headingtext font-sans w-full md:w-10/12'>
                  <div className='flex flex-row justify-between'>
                    <div className='w-full flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                      <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                        <span className='text-textsecondary inline-block mb-1 sm:mb-4 font-display'>
                          Upcoming Sessions ({filteredSessions.length})
                        </span>
                      </h2>
                      <div className='mb-10 text-center md:mb-4 lg:mb-4 flex justify-between flex-row'>
                        <button
                          onClick={() => {
                            setShow(!show);
                          }}
                          className='lg:hidden
                inline-flex items-center justify-center h-12 px-8 py- mx-2 border font-display border-darkerblue tracking-wide font-bold text-darkerblue text-sm mb-2 transition duration-200 rounded-sm shadow-md bg-none hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                        >
                          Filters
                        </button>
                      </div>
                    </div>
                  </div>

                  {filteredSessions.length > 0 ? (
                    <div className='w-full grid grid-cols-1 gap-6 row-gap-4 font-display mb-8 lg:grid-cols-3 sm:grid-cols-2'>
                      {filteredSessions?.map((value) => (
                        <SessionCard
                          key={value?._id}
                          session={value}
                          to={
                            showRegisteredSession
                              ? `/session/${value?._id}`
                              : `/joinsession/${value?._id}`
                          }
                        />
                      ))}
                    </div>
                  ) : (
                    <NoContentPlaceholder type='Upcoming' content='sessions' />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const SessionCard = ({ session, to }) => {
  return (
    <Link
      to={to}
      aria-label='Video Item'
      className='inline-block overflow-hidden duration-300 transform bg-white rounded shadow-sm hover:-translate-y-2 border-b-4 border-white hover:border-darkerblue sm:m-3 w-full p-2'
    >
      <div className='flex flex-col h-full'>
        <img
          src={session?.thumbnailFile?.publicUrl || VideoPlaceholder}
          className='object-cover w-full h-48'
          alt={session?.sessionTitle}
        />

        <div className='flex flex-col justify-between  rounded-b px-2'>
          <div className='flex flex-row w-full justify-between px-2 mt-2'>
            <p className='text-sm text-gray'>{session?.sessionDate || '--'}</p>
            <p className='text-sm text-green3 font-bold'>
              {session?.price >= 1 ? `Ugx ${session?.price}` : 'FREE'}
            </p>
          </div>
          <div className='py-5 px-2 '>
            <h6 className='text-charcoal font-bold text-lg leading-5'>
              {session?.sessionTitle}
            </h6>
          </div>
          <div className='flex flex-row w-full justify-between px-2 mt-2'>
            <p className='text-md text-gray capitalize'>
              {session?.presenter || '--'}
            </p>
            <p className='text-md text-darkerblue font-semibold '>
              {session?.registeredAttendees.length || 0}
              <span className='text-xs'>{` attendees`}</span>
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};
