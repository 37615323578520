import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Input = ({
  placeholder,
  height = 'h-11',
  borderColor = 'border-linecolor focus:border-main',
  backgroundColor = 'bg-lightgraybg opacity-1',
  contentColor = 'text-gray opacity-1',
  placeholderColor,
  contentSize,
  focus = 'focus:text-textsecondary',
  outline = 'focus:outline-noone ',
  margin,
  disabled = false,
  type,
  icon,
  name,
  ref,
  submitInput,
  passwordField,
  value,
  width = 'w-9/12',
  padding = 'px-4',
}) => {
  const [content, setContent] = useState('');

  const getInputValue = ({ target: { value } }) => {
    setContent(value);
    if (submitInput) {
      submitInput(value);
    }
  };

  useEffect(() => {
    if (value && value !== content) {
      setContent(value);
    }
  }, [value, content]);

  return (
    <div>
      <input
        onChange={getInputValue}
        value={content}
        type={type}
        placeholder={placeholder}
        className={`${backgroundColor} ${outline} border ${borderColor} box-border rounded h-11 ${margin} ${width} ${padding} ${height} font-body ${contentColor} ${placeholderColor} ${contentSize} ${focus}`}
      />
    </div>
  );
};

Input.protoTypes = {
  placeholder: PropTypes.string.isRequired,
  // backgroundColor: PropTypes.string,
  // loading: PropTypes.boolean,
  // contentColor: PropTypes.string,
  // borderColor: PropTypes.string,
  // borderRadius: PropTypes.string,
  submitInput: PropTypes.func.isRequired,
  // disabled: PropTypes.boolean,
};
export default Input;
