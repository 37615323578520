import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Ring } from 'react-css-spinners';
import { DropzoneArea } from 'material-ui-dropzone';
// components
import Select from '../components/Select';
import Input from '../components/Input';
import Button from '../components/Button';

//SVG
import { ReactComponent as Logo } from '../assets/delv_blue_bold1.svg';

// Actions
import * as institutionActions from '../redux/actions/institution';

const OnboardingInstitute = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // dispatch(disciplinesActions.getDisciplines());
  // const keywords = useSelector((state) => state.disciplines.keywordList);

  const institutionState = useSelector((state) => state.institution);
  const { loading, success, institution } = institutionState;
  const user = useSelector((state) => state.user.user);

  // Initial state
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [industry, setIndustry] = useState('');
  const [website, setWebsite] = useState('');
  const [bio, setBio] = useState('');
  const [specialities, setSpecialities] = useState([]);
  const [profilePicture, setProfilePicture] = useState([]);
  const [admin, setAdmin] = useState(`${user?._id}`);

  // Tag controllers

  // remove tags
  const removeTags = (indexToRemove) => {
    setSpecialities([
      ...specialities.filter((_, index) => index !== indexToRemove),
    ]);
  };
  //Add Chips tags
  const addTags = (event) => {
    if (
      ['Enter', ',', 'Tab'].includes(event.key) &&
      event.target.value !== ''
    ) {
      event.preventDefault();
      setSpecialities([...specialities, event.target.value.trim()]);
      event.target.value = '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // let tagsText = admin.map((tag) => tag.text);
    const payload = {
      profilePicture: '',
      country,
      city,
      institutionName,
      industry,
      website,
      bio,
      specialities,
      admin,
    };
    // console.log(payload);
    // console.log(user?._id);
    dispatch(institutionActions.createInstitution(payload));
    const myForm = new FormData();
    myForm.append('profilePicture', profilePicture[0]);
    // console.log(institution._id);
    const institutionID = institution._id;
    dispatch(institutionActions.profilePic(myForm, institutionID));
  };

  useEffect(() => {
    if (success.message === 'Institution profile successfully created') {
      // Redirect to /feeds
      history.push('/institution/feeds');
    }
  }, [success, history]);

  return (
    <div className='flex flex-col'>
      <div className='bg-white h-16  border-b-2 w-full border-linecolor pl-4 sm:pl-28 md:pl-28 py-3.5'>
        <Logo />
      </div>
      <div
        className={`flex flex-col items-center ${
          loading ? 'items-center justify-center' : ''
        }
        `}
      >
        {loading ? (
          <div
            className={`w-96 my-52 ${
              loading ? 'flex items-center justify-center' : ''
            }`}
          >
            <Ring color='#007EFF' size={100} thickness={7} />
          </div>
        ) : (
          <form
            className='px-4 sm:px-28 md:px-28 pb-80 '
            onSubmit={handleSubmit}
          >
            <h1 className='pt-6 text-textsecondary text-4xl font-semibold  font-body'>
              About you
            </h1>
            <p className='font-body pt-6 text-textparagraph font-semibold'>
              PROFILE PICTURE
            </p>
            <p className='font-body pt-6 pb-3 text-textparagraph font-normal'>
              Share yout institution image - at least 400x400 pixels big.
            </p>
            <div className=' bg-lightgraybg rounded-xl max-w-xl '>
              <DropzoneArea
                filesLimit={1}
                showAlerts={false}
                maxFileSize={5000000}
                acceptedFiles={['image/*']}
                onChange={(files) => setProfilePicture(files)}
              />
            </div>

            <div className='flex flex-col sm:flex-row sm:justify-between md:justify-between md:flex-row pt-6'>
              <div className=''>
                <h3 className='font-body text-textparagraph font-semibold'>
                  COUNTRY
                </h3>
                <Select
                  name='Country'
                  margin='mb-4 mr-6'
                  width='w-full sm:w-64 md:w-64 '
                  data={[
                    { value: 'Uganda', label: 'Uganda' },
                    { value: 'Kenya', label: 'Kenya' },
                    { value: 'Rwanda', label: 'Rwanda' },
                    { value: 'Burundi', label: 'Burundi' },
                    { value: 'Tanzania', label: 'Tanzania' },
                  ]}
                  placeholder='-----------'
                  submitInput={setCountry}
                  id='country'
                  value={country}
                />
              </div>
              <div>
                <h3 className='font-body text-textparagraph font-semibold'>
                  CITY
                </h3>
                <Input
                  type='text'
                  width='w-full sm:w-64 md:w-64'
                  submitInput={setCity}
                  id='city'
                  value={city}
                />
              </div>
            </div>
            <div className='pt-10'>
              <h3 className='font-body text-textparagraph font-semibold'>
                NAME OF INSTITUTION
              </h3>
              <Input
                type='text'
                width='w-full md:w-full'
                submitInput={setInstitutionName}
                id='institutionName'
                value={institutionName}
              />
            </div>
            <div className='pt-10'>
              <h3 className='font-body text-textparagraph font-semibold'>
                INDUSTRY
              </h3>
              <Select
                name='Higher Education'
                margin='mb-4 mr-6'
                width='w-full sm:w-full md:w-full '
                data={[
                  { value: 'Higher Education', label: 'Higher Education' },
                  {
                    value: 'Education / Learning',
                    label: 'Education / Learning',
                  },
                  { value: 'Finance', label: 'Finance' },
                  { value: 'Manufacturing', label: 'Manufacturing' },
                  { value: 'Commerce / trade', label: 'Commerce / trade' },
                  { value: 'Distribution', label: 'Distribution' },
                  {
                    value: 'Information Technology',
                    label: 'Information Technology',
                  },
                  {
                    value: 'Health care / Medicine',
                    label: 'Health care / Medicine',
                  },
                  { value: 'Construction', label: 'Construction' },
                  { value: 'Media', label: 'Media' },
                  { value: 'Banking', label: 'Banking' },
                  { value: 'Social Entreprise', label: 'Social Entreprise' },
                  { value: 'Financial services', label: 'Financial services' },
                  { value: 'Agriculture', label: 'Agriculture' },
                  { value: 'Human Ressources', label: 'Human Ressources' },
                  { value: 'Consulting', label: 'Consulting' },
                  { value: 'Government', label: 'Government' },
                  { value: 'Development sector', label: 'Development sector' },
                  { value: 'Philantropy', label: 'Philantropy' },
                  { value: 'Other,…………', label: 'Other,…………' },
                ]}
                placeholder='Higher Education'
                submitInput={setIndustry}
                id='industry'
                value={industry}
              />
            </div>
            <div className='pt-10'>
              <h3 className='font-body text-textparagraph font-semibold'>
                WEBSITE (optional)
              </h3>
              <Input
                type='text'
                width='w-full md:w-full'
                submitInput={setWebsite}
                id='website'
                value={website}
              />
            </div>
            <div className='pt-10'>
              <h3 className='font-body text-textparagraph font-semibold pb-2'>
                SPECIALITIES
              </h3>
              <p className='font-body text-textparagraph font-normal '>
                Comma-separated list of your skills (keep it below 10).
              </p>

              <div className='bg-lightgraybg rounded max-w-xl h-72  py-4 pl-3'>
                <div className='tags-input flex items-start flex-wrap w-full  bg-lightgraybg rounded   pl-3 '>
                  <ul
                    id='tags'
                    className='flex flex-wrap mt-2 text-white  pl-1 '
                  >
                    {specialities.map((tag, index) => (
                      <li
                        key={index}
                        className='tag px-1 h-6 flex bg-main rounded justify-center mr-1 mb-1 '
                      >
                        <span className='tag-title font-medium '>{tag}</span>
                        <span
                          className='tag-close-icon ml-2 my-1 text-center h-4 w-4 rounded-full bg-white text-main text-sm cursor-pointer'
                          onClick={() => removeTags(index)}
                        >
                          x
                        </span>
                      </li>
                    ))}
                  </ul>
                  <input
                    className='bg-lightgraybg rounded  text-sm border-0  mt-2 h-8'
                    type='text'
                    placeholder='Put a comma to add tags'
                    onKeyDown={addTags}
                  />
                </div>
              </div>
            </div>
            <div className='pt-10'>
              <h3 className='font-body text-textparagraph font-semibold pb-4'>
                BIO
              </h3>

              <Input
                type='text'
                width='w-full md:w-full '
                submitInput={setBio}
                id='bio'
                value={bio}
                height='h-32'
                backgroundColor='bg-lightgraybg'
              />
            </div>

            <div className='pt-10'>
              <h3 className='font-body text-textparagraph font-semibold pb-2'>
                ADMINISTRATION ID for your Institution
              </h3>
              <Input
                type='text'
                width='w-full md:w-full '
                submitInput={setAdmin}
                id='bio'
                value={admin}
                height='h-32'
                backgroundColor='bg-lightgraybg'
              />
            </div>
            <Button
              content='Finish'
              backgroundColor='bg-main'
              contentColor='text-white'
              borderRadius='rounded-lg'
              // padding='-mx-2.5'
              borderColor='border-white'
              width='w-8'
              height='h-20'
              margin='mt-6'
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default OnboardingInstitute;
