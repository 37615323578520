import React from 'react';
import AI_icon from '../assets/ai_icon.jpg';
import { Button } from '@material-ui/core';

const ChatGPTButton = (props) => {
  return (
    <>
      <Button
        style={{
          color: '#4b2aad',
          textAlign: 'center',
          fontSize: 14,
          textDecoration: 'underline',
          textTransform: 'lowercase',
          marginLeft: props.marginLeft,
          borderRadius: 20,
        }}
        onClick={() => {
          props.setShowChatGPTModal(true);
        }}
      >
        <img
          style={{ width: 30 }}
          className='rounded-full object-cover h-full w-25 shadow-md'
          src={AI_icon}
          alt='user-profile-pictue'
        />
        ask{' '}
        <span style={{ textTransform: 'uppercase', marginLeft: 5 }}>ai</span>
      </Button>
    </>
  );
};

export default ChatGPTButton;
