import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add } from '@material-ui/icons';
import Loading from '../components/Loading';
import Nav from '../components/NavBarR';
// Actions
import * as sessionsActions from '../redux/actions/sessions';
import * as institutionActions from '../redux/actions/institution';
import * as userActions from '../redux/actions/users';
import * as affiliationsActions from '../redux/actions/affiliations';

import UploadSessionModal from '../components/UploadSession';

import EventsSection from '../components/EventsSection';

const PageBody = () => {
  const dispatch = useDispatch();
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const sessions = useSelector((state) => state.sessions);
  const allSessions = sessions?.data;

  const PageLoading = useSelector((state) => state.institution.loading);

  const user = useSelector((state) => state?.user?.user);
  const institutions = useSelector((state) => state.institution);
  const institution = institutions?.data;
  const institute = institution.find(
    (inst) => inst.admins[0] === `${user._id}`,
  );

  const users = useSelector((state) => state?.user?.data);
  const affiliations = useSelector((state) => state?.affiliations?.data);

  const institutionID = institute?._id;

  useEffect(() => {
    dispatch(institutionActions.getInstitutions({}));
    dispatch(sessionsActions.getAllSessions());
    dispatch(userActions?.getUsers());
    dispatch(affiliationsActions.getAllAffiliations());
  }, []);

  const mySession = allSessions.filter((value) => value?.userID === user?._id);
  // console.log(`mySession`, mySession);

  const affiliatedMentors = [];
  affiliations
    ?.filter(
      (item) =>
        item.institution === institutionID && item.affiliateType === 'mentor',
    )
    .map((item) => affiliatedMentors.push(item.affiliate));

  const mentorsList = users?.filter(
    (value) => value?.usercategory[0] === 'mentor',
  );

  const myMentors = mentorsList?.filter((mentor) =>
    affiliatedMentors.includes(mentor._id),
  );
  return (
    <>
      <Nav
        eventsActive={true}
        userType='institution'
        institutions={institution}
      />
      {PageLoading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div className='bg-lightgraybg h-full overflow-y-hidden'>
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
              <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>
                    Sessions ({mySession?.length || '0'})
                  </span>
                </h2>
                <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                  <button
                    className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'
                    onClick={() => setShowCreateEventModal(true)}
                  >
                    <Add />
                    <span className='ml-2'>Create Session</span>
                  </button>
                </div>
              </div>
              <EventsSection eventList={mySession} users={users} />
            </div>
          </div>
          <UploadSessionModal
            institution
            mentors={myMentors}
            setShowCreateEventModal={setShowCreateEventModal}
            showCreateEventModal={showCreateEventModal}
            userId={institutionID}
          />
        </div>
      )}
    </>
  );
};

export default function Events() {
  return (
    <>
      <PageBody />
    </>
  );
}
