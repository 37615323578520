import { StarRate } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';

const ReviewItem = ({ data, institutions }) => {
  const currentInstitution = institutions.find((inst) =>
    inst.admins.includes(data.commentedBy._id),
  );
  return (
    <div className='py-2'>
      <div className='px-4 py-6 flex bg-lightGrayBg hover:bg-lightgraybg flex-col lg:flex-row w-full justify-between  mb-1 text-md border rounded-md border-none'>
        <div className='h-16 w-16'>
          <img
            src={
              data?.commentedBy?.profilePicture?.publicUrl ||
              `https://ui-avatars.com/api/?background=random&name=${data?.commentedBy?.firstname}+${data?.commentedBy?.lastname}&bold=true`
            }
            alt={'comment'}
            className='rounded-full object-cover w-10 h-10 shadow-md'
          />
        </div>

        <div className='flex flex-col  justify-start ml-4 w-full'>
          <p className=' text-charcoal text-base font-bold '>
            {data?.commentedBy.usercategory[0] === 'institution'
              ? `${currentInstitution?.institutionName}`
              : `${data?.commentedBy.firstname}  ${data?.commentedBy.lastname}`}
            <span className='ml-4 text-xs text-headingtext font-normal'>
              {moment(data?.createdAt).local().fromNow()}
            </span>
          </p>
          <div className='font-normal text-sm text-headingtext text-left '>
            <p className='pt-1 text-sm'>{data?.commentText}</p>
          </div>
        </div>
        <p className='text-starsorange pl-4 lg:w-3/12 text-right'>
          <StarRate fontSize='small' />
          <StarRate fontSize='small' />
          <StarRate fontSize='small' />
          <StarRate fontSize='small' />
          <StarRate fontSize='small' />
        </p>
      </div>
    </div>
  );
};

export default ReviewItem;
