import React from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const VisibilityHandler = ({ visibility, setVisibility }) => {
  return (
    <div className='absolute flex left-0 top-0 h-full w-10'>
      <div
        className='flex items-center justify-center cursor-pointer rounded-lg z-10 bg-none text-headindtext text-lg h-full w-full'
        onClick={() => setVisibility(!visibility)}
      >
        {visibility ? (
          <VisibilityOff fontSize='inherit' />
        ) : (
          <Visibility fontSize='inherit' />
        )}
      </div>
    </div>
  );
};
export default VisibilityHandler;
