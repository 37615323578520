import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { StarRate } from '@material-ui/icons';
import Truncate from 'react-truncate';

import DefaultThumbnail from './DefaultThumbnail';

import * as contentActions from '../redux/actions/content';

const ContentCard = ({ value, type }) => {
  let action;
  if (type === 'Article') action = 'read';
  if (type === 'Video') action = 'watch';
  if (type === 'Book') action = 'open';
  if (type === 'Course') action = 'course/review';
  if (type === 'OpenLib') action = 'link';
  if (type === 'Session') action = '';

  return (
    <a
      href={
        type === 'Session'
          ? `/session/${value?._id}`
          : `/${type.toLowerCase()}/${action}/${value?._id}`
      }
    >
      <div className='inline-block overflow-hidden duration-300 transform bg-white rounded shadow-sm hover:-translate-y-2 border-b-4 border-white hover:border-darkerblue m-3 w-11/12 p-2'>
        {value?.thumbnailFile?.publicUrl ? (
          <img
            src={value?.thumbnailFile?.publicUrl}
            alt={value?.title}
            className='object-cover w-full h-48 rounded'
          />
        ) : (
          <div className='w-full h-48'>
            <DefaultThumbnail text={value.title} />
          </div>
        )}
        <div className='flex flex-col h-36'>
          <div className='flex flex-col flex-grow justify-between  rounded-b'>
            <div className='py-5 px-2 flex flex-col'>
              {type === ('Course' || 'Session') && (
                <div className='flex flex-row w-full justify-between px-2 mb-5 font-semibold text-sm text-gray'>
                  {type === 'Session' ? (
                    <p className='capitalize'>
                      {value?.sessionDate || '- - -'}
                    </p>
                  ) : (
                    <p className=''>
                      {value?.noOfStudents || 200} {`students`}
                    </p>
                  )}

                  {type === 'Session' ? (
                    <p>
                      <div className=' text-sm text-buttonGreen'>
                        {value?.price || `FREE`}
                      </div>
                    </p>
                  ) : (
                    <p>
                      <div className=' text-sm'>
                        {value?.duration || 5} {'hrs'}
                      </div>
                    </p>
                  )}
                </div>
              )}
              <h6 className='font-semibold leading-5 capitalize'>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {value?.title || value?.sessionTitle}
                </Truncate>
              </h6>
            </div>
            {type === 'Book' ? (
              <div className='flex flex-col w-full justify-between px-2 mb-5 font-semibold text-sm text-gray'>
                <p className='capitalize'>{value?.author}</p>
                <p>
                  <div className='text-darkerblue text-base '>
                    <span className=''>{value?.publisher || 'TBD'}</span>
                  </div>
                </p>
              </div>
            ) : (
              <div className='flex flex-row w-full justify-between px-2 mb-5 font-semibold text-sm text-gray'>
                <p className='capitalize'>
                  {value?.author || value?.presenter}
                </p>
                <p>
                  {type === 'OpenURL' ? (
                    <div className='text-darkerblue text-base '>
                      <span>{value?.venue || 'TBD'}</span>
                    </div>
                  ) : (
                    <div className='text-starsorange text-sm'>
                      <StarRate fontSize='small' />
                      <span className=' mx-1  font-semibold text-sm text-starsorange '>
                        {parseFloat(value?.avgRating || 0).toFixed(1)}
                      </span>
                      <span className='text-linecolor'>
                        (
                        {value?.organiser
                          ? value?.organiser
                          : value?.views || 0}
                        )
                      </span>
                    </div>
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

export default function RelatedContent({ type, typeID }) {
  const dispatch = useDispatch();
  const relatedContent = useSelector((state) => state.content.relatedContent);

  useEffect(() => {
    if (typeID) dispatch(contentActions.getRelatedContent(typeID));
  }, [typeID]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className='flex flex-col text-charcoal'>
      <div className='w-full bg-none mb-8'>
        <div className=' py-8 sm:max-w-full md:max-w-full lg:max-w-full'>
          <div className='max-w-full mb-5 flex flex-row justify-between '>
            <span className='font-sans text-3xl font-bold leading-tight tracking-tight text-charcoal sm:text-4xl '>
              Other related <span className='capitalize'>{type}</span>s
            </span>
          </div>
          <div className='py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-full lg:py-4'>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={5000}
              transitionDuration={500}
              removeArrowOnDeviceType={[]}
            >
              {relatedContent?.map((value) =>
                value.category === type ? (
                  <ContentCard value={value} type={type} />
                ) : null,
              )}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
