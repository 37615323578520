import * as fetchFunctions from '../../api/index';
import * as chatTypes from '../types/chat';
import chatEndpoints from '../endpoints/chat';

export const createNewChatDiscussion =
  (payload) => async (dispatch, getState) => {
    dispatch({
      type: chatTypes.CHAT_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.postData(
      chatEndpoints.create_New_ChatDiscussion,
      payload,
      token,
    );

    if (result.success) {
      dispatch({
        type: chatTypes.CHAT_SUCCESS,
        payload: {
          status: true,
          message: 'Upload Complete',
        },
      });
      dispatch({
        type: chatTypes.CHAT_RESET,
      });
    } else {
      dispatch({
        type: chatTypes.CHAT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const createNewChat = (payload) => async (dispatch, getState) => {
  dispatch({
    type: chatTypes.CHAT_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    chatEndpoints.create_New_Chat,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: chatTypes.CHAT_SUCCESS,
      payload: {
        status: true,
        message: 'Upload Complete',
      },
    });
    dispatch({
      type: chatTypes.CHAT_RESET,
    });
  } else {
    dispatch({
      type: chatTypes.CHAT_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getAllChats =
  ({ groupID }) =>
  async (dispatch) => {
    dispatch({
      type: chatTypes.CHAT_LOADING,
    });

    const result = await fetchFunctions.getData(
      `${chatEndpoints.get_all_chats}/${groupID}`,
    );
    if (result.success) {
      dispatch({
        type: chatTypes.ALL_CHATS,
        payload: result.data,
      });
      dispatch({
        type: chatTypes.CHAT_RESET,
      });
    } else {
      dispatch({
        type: chatTypes.CHAT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getAllChatDiscussions =
  ({ chatId, groupId }) =>
  async (dispatch) => {
    dispatch({
      type: chatTypes.CHAT_LOADING,
    });

    const result = await fetchFunctions.getData(
      `${chatEndpoints.get_all_chatDiscussions}/${groupId}/${chatId}`,
    );

    if (result.success) {
      dispatch({
        type: chatTypes.ALL_CHAT_DISCUSSIONS,
        payload: result.data,
      });
      dispatch({
        type: chatTypes.CHAT_RESET,
      });
    } else {
      dispatch({
        type: chatTypes.CHAT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const checkMemberInChat =
  ({ chatId, groupId, userId }) =>
  async (dispatch, getState) => {
    dispatch({
      type: chatTypes.CHAT_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${chatEndpoints.check_member_in_chat}/${groupId}/${chatId}/${userId}`,
      token,
    );

    if (result.success) {
      dispatch({
        type: chatTypes.CHECK_MEMBER_IN_CHAT,
        payload: result.data,
      });
      dispatch({
        type: chatTypes.CHAT_RESET,
      });
    } else {
      dispatch({
        type: chatTypes.CHAT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getGroupChatRequests =
  ({ chatId, userId }) =>
  async (dispatch, getState) => {
    dispatch({
      type: chatTypes.CHAT_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${chatEndpoints.get_group_chat_requests}/${chatId}/${userId}`,
      token,
    );

    if (result.success) {
      dispatch({
        type: chatTypes.GET_GROUP_CHAT_REQUESTS,
        payload: result.data,
      });
      dispatch({
        type: chatTypes.CHAT_RESET,
      });
    } else {
      dispatch({
        type: chatTypes.CHAT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const submitChatRequestStatus =
  (payload) => async (dispatch, getState) => {
    dispatch({
      type: chatTypes.CHAT_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.postData(
      `${chatEndpoints.submit_chat_request_status}`,
      payload,
      token,
      true,
    );

    if (result.success) {
      dispatch({
        type: chatTypes.SUBMIT_CHAT_REQUEST_STATUS,
        payload: result.data,
      });
      dispatch({
        type: chatTypes.CHAT_RESET,
      });
    } else {
      dispatch({
        type: chatTypes.CHAT_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };
