import * as fetchFunctions from '../../api/index';
import * as likesTypes from '../types/likes';
import likesEndpoints from '../endpoints/likes';

export const getAllLikes = () => async (dispatch, getState) => {
  dispatch({
    type: likesTypes.LIKES_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    likesEndpoints.get_all_likes,
    token,
  );

  if (result.success) {
    dispatch({
      type: likesTypes.ALL_LIKES,
      payload: result.data,
    });
    dispatch({
      type: likesTypes.LIKES_RESET,
    });
  } else {
    dispatch({
      type: likesTypes.LIKES_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const addLike = (payload) => async (dispatch, getState) => {
  dispatch({
    type: likesTypes.LIKES_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    likesEndpoints.add_like,
    payload,
    token,
  );
  console.log(result);

  if (result.success) {
    dispatch({
      type: likesTypes.LIKES_SUCCESS,
      payload: {
        status: true,
        message: 'Like added successfully',
      },
    });
  } else {
    dispatch({
      type: likesTypes.LIKES_ERROR,
      payload: {
        error: true,
        errorMessage: 'Failed to submit like',
      },
    });
  }
};

export const itemLikes = (itemID) => async (dispatch, getState) => {
  dispatch({
    type: likesTypes.LIKES_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    `${likesEndpoints.item_likes}/${itemID}`,
    token,
  );

  if (result.success) {
    dispatch({
      type: likesTypes.ITEM_LIKES,
      payload: result.data,
    });
    dispatch({
      type: likesTypes.LIKES_RESET,
    });
  } else {
    dispatch({
      type: likesTypes.LIKES_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
