import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link, StarRate } from '@material-ui/icons';

import Footer from '../components/Footer';
import Loading from '../components/Loading';
import NavHeader from '../components/NavBarHeader';
import RelatedContent from '../components/RelatedContent';

import * as helpers from '../helpers';
import * as contentActions from '../redux/actions/content';
import * as institutionActions from '../redux/actions/institution';
import ContentViewNav from '../components/ContentViewNav';

const ArticleMain = ({ article }) => {
  const [hideDescription, toggleHideDescription] = helpers.useToggle(true);

  return (
    <>
      {article ? (
        <>
          <div className='w-full'>
            <div className='px-4 lg:px-16'>
              <div className='flex flex-row lg:justify-between justify-start'>
                <h5 className='mb-2 text-3xl font-bold leading-none md:text-2xl text-black '>
                  {article?.title}
                </h5>
                <a
                  href={article.sourceLink}
                  target='_blank'
                  rel='noreferrer'
                  className='text-3xl font-bold leading-none md:text-5xl text-darkerblue hover:text-navyblue'
                >
                  <Link fontSize='inherit' />
                  {/* <p className='text-gray4 mb-8'>{article.sourceLink}</p> */}
                </a>
              </div>

              <p className='text-headingtext mb-1 text-lg'>
                {article?.author || article?.postedBy}
              </p>
              <p className='text-gray4 mb-8'>
                {helpers.dateFormatter(article?.createdAt)}
              </p>
            </div>

            <div className='text-charcoal text-lg py- lg:py-6'>
              <div>
                <embed
                  className='object-contain  mb-6  px-4 lg:px-16  rounded-md'
                  src={article?.contentFile?.publicUrl}
                  title={article?.title}
                  width='100%'
                  height='1300'
                />
              </div>
            </div>
            <div className='flex flex-row w-full justify-between py-4 px-2 sm:px-0 mb-5 font-semibold text-sm text-gray'>
              <div className='items-center '>
                <span
                  className='cursor-pointer inline-flex items-center justify-center h-10 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-headingtext hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                  aria-label='Read Description'
                  title='Click to view description'
                  aria-controls='Read Description'
                  aria-haspopup='true'
                  onClick={() => toggleHideDescription()}
                >
                  Read Description
                </span>
              </div>
              <div className='text-starsorange text-xl sm:text-4xl '>
                <StarRate style={{ fontSize: '40px' }} />
                <span className=' font-semibold  text-starsorange mb-6'>
                  {parseFloat(article?.avgRating || 0).toFixed(1)}
                </span>
              </div>
            </div>
            <div
              hidden={hideDescription}
              className='bg-lightGrayBg2 border-b pb-11 border-linecolor leading-6 px-4'
            >
              <p className='inline-block mb-1 sm:mb-2 '>
                {article?.description}
              </p>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const ArticleBody = ({ article }) => {
  return (
    <div className=' bg-gray3'>
      <div className='container mx-auto md:px-14 py-8'>
        <div className=' w-full'>
          <ArticleMain article={article} />
        </div>
        <div className='sm:col-span-12 md:col-span-3  lg:col-span-3 '>
          {/* <ArticleOther article={article} /> */}
          <RelatedContent type={'Article'} typeID={article?._id} />
        </div>
      </div>
    </div>
  );
};

export default function ArticleRead() {
  const dispatch = useDispatch();
  const { articleID } = useParams();
  const user = useSelector((state) => state?.user?.user);
  const article = useSelector((state) => state.content.data);
  const institutions = useSelector((state) => state?.institution?.data);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Article is stored in an array data type
    if (article?.length === 0) {
      dispatch(contentActions.getSpecificContent(articleID));
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [article, articleID]);
  useEffect(() => {
    dispatch(institutionActions.getInstitutions({}));
  }, []);

  return (
    <>
      <NavHeader institutions={institutions} user={user} />
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <>
          <ContentViewNav contentType='article' content={article} />
          <ArticleBody article={article} />
          <div className='bg-black2'>
            <Footer />
          </div>
        </>
      )}
    </>
  );
}
