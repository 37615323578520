import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

export default function TagsInput({
  tags,
  isInstitution,
  setTags,
  singleTag,
  suggestions,
  noSpaceDelimiter,
  placeholder = 'Enter a comma, tab, or space to seperate the tags',
}) {
  const displayNone = [];
  const KeyCodes = {
    tab: 9,
    enter: 13,
    space: 32,
    comma: 188,
  };

  const delimiters = [
    KeyCodes.tab,
    KeyCodes.enter,
    !noSpaceDelimiter && KeyCodes.space,
    KeyCodes.comma,
  ];

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
  };

  const handleAddition = (tag) => {
    if (singleTag) {
      setTags([tag]);
    } else {
      setTags([...tags, tag]);
    }
  };

  return (
    <>
      <ReactTags
        tags={singleTag ? displayNone : tags}
        suggestions={suggestions}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        delimiters={delimiters}
        autocomplete={true}
        placeholder={isInstitution ? tags[0]?.text : placeholder}
        maxLength={40}
        classNames={{
          tagInputField:
            'px-3 py-3 text-charcoal relative bg-none  text-sm border-b border-linecolor outline-none focus:outline-noone focus:border-darkerblue w-full',
          activeSuggestion: `bg-darkerblue text-white`,
          suggestions: ` border border-darkerblue`,
        }}
      />
    </>
  );
}
