import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as discussionGroupsActions from '../redux/actions/discussiongroups';

import '@kenshooui/react-multi-select/dist/style.css';
import Nav from '../components/NavBarR';
import GroupPeople from '../components/GroupPeople';
import GroupChat from '../components/GroupChat';
import ChatFeed from '../components/ChatFeed';
import Loading from '../components/Loading';
//import CustomAlert from '../components/CustomAlert';

import '../assets/external_css/chatroom.css';

const PageBody = () => {
  const { groupID } = useParams();
  const dispatch = useDispatch();
  const { groupName, isMember } = useSelector(
    (state) => state?.discussiongroups,
  );

  const { oneGroupDiscussionLoading } = useSelector(
    (state) => state?.discussiongroups,
  );
  const user = useSelector((state) => state.user.user);
  const userID = user._id;

  useEffect(() => {
    dispatch(discussionGroupsActions.getOneDiscussionGroup({ groupID }));
    dispatch(discussionGroupsActions.checkMemberInGroup({ groupID, userID }));

    /*if (isMember === 0) {
      CustomAlert({
        alertType: 'error',
        alertMessage:
          "You're not registered under this group. First Join Group",
      });
      setTimeout(function () {
        window.history.back();
      }, 500);
    }*/
  }, [groupID, userID, isMember]);

  return (
    <>
      <Nav groupsActive={true} />
      <>
        {oneGroupDiscussionLoading === false ? (
          <div className='bg-lightGrayBg h-64 w-full p-auto'>
            <Loading />
          </div>
        ) : (
          <>
            <>
              <h2
                style={{
                  textAlign: 'center',
                  padding: '10px 0px 10px 0px',
                  border: '1px solid #CABCDC',
                  backgroundColor: '#CABCDC',
                }}
                className='font-display sm:text-3xl '
              >
                <span className=''>{groupName}</span>
              </h2>

              <div
                style={{ width: '100%', height: '70vh' }}
                className=' grid md:gap-x-2 md:grid-cols-5 bg-lightgraybg'
              >
                <div className='flex flex-col w-full md:col-span-1'>
                  <div className='bg-white md:m-2 md:p-2 text-sm'>
                    <GroupPeople groupID={groupID} groupName={groupName} />
                  </div>
                </div>

                <div className='flex flex-col w-full md:col-span-2'>
                  <div className='bg-white md:m-2 md:p-2 text-sm'>
                    <GroupChat groupID={groupID} />
                  </div>
                </div>

                <div className='flex flex-col w-full md:col-span-2'>
                  <div className='bg-white md:m-2 md:p-2 text-sm'>
                    <ChatFeed groupID={groupID} />
                  </div>
                </div>
              </div>
            </>
          </>
        )}
      </>
    </>
  );
};

export default function ChatRoom() {
  return (
    <>
      <PageBody />
    </>
  );
}
