import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  PhotoCamera,
  Description,
  //VideoLibrary,
  Cancel,
  Event,
  Link,
} from '@material-ui/icons';

import CustomAlert from './CustomAlert';
import ButtonLoader from './ButtonLoader';

import * as helpers from '../helpers';
import * as postActions from '../redux/actions/post';
import * as homeActions from '../redux/actions/home';
import * as userActions from '../redux/actions/users';

export default function Sharepost({ user, createSessionClick, institutions }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.post.loading);

  const [file, setFile] = useState([]);
  const [link, setLink] = useState('');
  const [cleanLink, setCleanLink] = useState('');
  const [upload, setUpload] = useState(false);
  const [addLink, toggleAddLink] = helpers.useToggle(false);
  const [photoUpload, setPhotoUpload] = useState(false);
  //const [videoUpload, setVideoUpload] = useState(false);
  const [articleUpload, setArticleUpload] = useState(false);
  const [postText, setPostText] = useState('');
  const [contentType, setContentType] = useState('');

  const fileType = () => {
    if (contentType === 'image') return ['image/*'];
    if (contentType === 'video') return ['video/mp4'];
    if (contentType === 'pdf') return ['application/pdf'];
  };
  const reset = () => {
    setUpload(false);
    setPhotoUpload(false);
    // setVideoUpload(false);
    setArticleUpload(false);
  };

  const handleSubmitPost = (e) => {
    e.preventDefault();
    if (file[0]) {
      const fileForm = new FormData();
      fileForm.append('link', cleanLink);
      fileForm.append('postText', postText);
      fileForm.append('attachment', file[0]);
      dispatch(postActions.makePost(fileForm));
    } else {
      dispatch(
        postActions.makePost({
          postText,
          link: cleanLink,
        }),
      );
    }

    setTimeout(function () {
      dispatch(userActions.getUserPoints({ userId: user?._id }));
      dispatch(homeActions.getFeeds({ limit: 30 }));
    }, 1000);

    reset();
    setFile([]);
    setLink('');
    setPostText('');
    setCleanLink('');

    CustomAlert({
      alertType: 'success',
      alertMessage: 'Success: Points Earned',
    });
  };

  const isInstitution = user?.usercategory[0] === 'institution';
  let instituteRequired;
  // dispatch(institutionActions.getInstitutions());

  if (isInstitution) {
    const institutionList = [];
    institutions?.map((value) => institutionList.push(value));
    instituteRequired = institutionList.find(
      (inst) => inst.admins[0] === `${user?._id}`,
    );
  }
  return (
    <div className='max-w-full bg-white  border border-linecolor flex flex-col text-sm px-3 py-2'>
      <div className='body'>
        <div className='flex mb-4'>
          <img
            className='object-cover w-12 h-12 mr-4 rounded-full shadow'
            src={
              user?.profilePicture?.publicUrl ||
              `https://ui-avatars.com/api/?name=${
                isInstitution
                  ? `${instituteRequired?.institutionName}`
                  : `${user?.firstname} ${user?.lastname}`
              }&bold=true`
            }
            alt='Person'
          />
          <div className='flex flex-col justify-center h-12'>
            <p className='text-lg font-bold text-charcoal font-display'>
              {isInstitution
                ? `${instituteRequired?.institutionName}`
                : `${user?.firstname} ${user?.lastname}`}
            </p>
          </div>
        </div>
        <div className=''>
          <textarea
            rows='1'
            maxLength='280'
            type='textarea'
            value={postText}
            onChange={(e) => setPostText(e.target.value)}
            placeholder='Share your thoughts...'
            className='flex text-charcoal text-base w-full bg-white rounded-md appearance-none font-display focus:outline-noone px-1'
          />
        </div>
        {upload && (
          <div className='mt-4 relative'>
            <DropzoneArea
              filesLimit={1}
              showAlerts={false}
              showFileNames={true}
              maxFileSize={20000000} // 20mb limit
              acceptedFiles={fileType()}
              onChange={(files) => setFile(files)}
              dropzoneText={`Drag and drop ${contentType} here or click`}
              dropzoneClass={'text-headingtext'}
              onAlert={(message, variant) => {
                if (variant === 'error')
                  CustomAlert({
                    alertType: 'error',
                    alertMessage:
                      'File is larger than post file size limit(20 MBs)',
                  });
              }}
            />
            <div className='absolute top-0 right-0  m-2'>
              <button
                title='Close'
                onClick={reset}
                disabled={file[0] ? true : false}
                className={`${
                  file[0] ? 'text-gray2' : 'hover:text-navyblue text-buttonRed'
                } cursor-pointer text-3xl`}
              >
                <Cancel fontSize='inherit' />
              </button>
            </div>
          </div>
        )}
        <iframe
          width='100%'
          height='315'
          title='test'
          src={cleanLink}
          hidden={addLink && cleanLink !== '' ? false : true}
        />
        <input
          size='75'
          type='text'
          value={link}
          hidden={addLink ? false : true}
          onChange={(e) => setLink(e.target.value)}
          onMouseOut={() => {
            const sanitizedLink = helpers.embedUrlSanitizer(link);
            if (sanitizedLink === '') setCleanLink('');
            else setCleanLink(sanitizedLink);
          }}
          placeholder={'Embed Youtube, Vimeo, Spotify, or SoundCloud.'}
        />
      </div>
      <div className='mt-6 w-full grid grid-cols-2 justify-between'>
        <div className='grid grid-cols-5 lg:grid-cols-8 text-2xl text-charcoal '>
          <button
            title='Add image'
            className={`${
              photoUpload ? 'text-blue' : 'text-headingtext'
            } cursor-pointer`}
            onClick={() => {
              setContentType('image');
              reset();
              setUpload(true);
              setPhotoUpload(true);
            }}
            disabled={file[0] || addLink ? true : false}
          >
            <PhotoCamera fontSize='inherit' />
          </button>
          {/*<button
            title='Add video'
            className={`${
              videoUpload ? 'text-darkerblue' : 'text-headingtext'
            } cursor-pointer`}
            onClick={() => {
              setContentType('video');
              reset();
              setUpload(true);
              setVideoUpload(true);
            }}
            disabled={file[0] || addLink ? true : false}
          >
            <VideoLibrary fontSize='inherit' />
          </button>*/}
          <button
            title='Add PDF'
            className={`${
              articleUpload ? 'text-darkerblue' : 'text-headingtext'
            } cursor-pointer`}
            onClick={() => {
              setContentType('pdf');
              reset();
              setUpload(true);
              setArticleUpload(true);
            }}
            disabled={file[0] || addLink ? true : false}
          >
            <Description fontSize='inherit' />
          </button>
          <button
            title='Share session'
            onClick={() => {
              reset();
              createSessionClick();
            }}
            className='text-headingtext cursor-pointer'
            disabled={file[0] || addLink ? true : false}
          >
            <Event fontSize='inherit' />
          </button>
          <button
            title='Add video/podcast link'
            className={`${
              addLink ? 'text-darkerblue' : 'text-headingtext'
            } cursor-pointer`}
            onClick={() => {
              reset();
              toggleAddLink();
              if (!addLink) {
                setCleanLink('');
                setLink('');
              }
            }}
            disabled={file[0] ? true : false}
          >
            <Link fontSize='inherit' />
          </button>
        </div>
        <div className='flex justify-end'>
          <button
            onClick={(e) => handleSubmitPost(e)}
            disabled={postText === '' ? true : false}
            className={`${
              postText === ''
                ? 'bg-darkerblue'
                : 'hover:bg-navyblue  bg-darkerblue'
            } inline-flex items-center font-medium text-sm sm:max-w-full justify-center h-11 w-24 px-4 sm:mr-0  border  tracking-wide text-white transition duration-200 rounded-full font-display focus:shadow-outline focus:outline-none`}
          >
            {loading ? <ButtonLoader size='default' /> : 'Post'}
          </button>
        </div>
      </div>
    </div>
  );
}
