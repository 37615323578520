import React from 'react';
import FollowCard from './FollowCard';

const FollowerSideMenu = ({
  showFollower,
  setShowFollowers,
  users,
  institutions,
}) => {
  return (
    <div
      className={
        showFollower
          ? 'w-full h-screen shadow-md absolute z-40  transform  translate-x-0 lg:hidden'
          : '   w-full h-full absolute z-40  transform -translate-x-full'
      }
    >
      <div
        className='bg-black opacity-50 w-full h-screen absolute lg:hidden '
        onClick={() => setShowFollowers(!showFollower)}
      ></div>
      <div className='w-72 md:w-72 absolute z-40 bg-white shadow h-full flex-col justify-between lg:hidden pb-4 transition duration-150 ease-in-out'>
        <div className='flex flex-col justify-between h-full'>
          <div>
            <div className='px-4 overflow-y-auto overscroll-contain h-screen top-0'>
              <div className='block   mb-10 text-headingtext font-sans sm:text-sm'>
                <div className=' w-full  '>
                  <div className='flex flex-col border border-linecolor'>
                    <div className='bg-white w-full overflow-auto mb-10'>
                      <div className=''>
                        <h1 className='p-4  font-body font-semibold text-sm border-b border-linecolor mb-5 text-center '>
                          Follow Mentors / Trainers / Counsellors
                        </h1>

                        <div>
                          {users?.map((value) =>
                            value.usercategory[0] === 'mentor' ? (
                              <FollowCard
                                key={value._id}
                                pic={value.profilePicture?.publicUrl}
                                name={`${value.firstname} ${value.lastname}`}
                                content={value.usercategory[0]}
                                button='Follow'
                                userId={value?._id}
                              />
                            ) : null,
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='bg-white w-full overflow-auto mb-10'>
                      <div className=''>
                        <h1 className='p-4  font-body font-semibold text-sm border-b border-linecolor mb-5 '>
                          Follow Institutions
                        </h1>
                        <div>
                          {institutions?.map((value) => (
                            <FollowCard
                              key={value._id}
                              pic={value?.profilePicture?.publicUrl}
                              name={`${value.institutionName}`}
                              content={value.usercategory}
                              button='Follow'
                              userId={value._id}
                              userType={'institution'}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* <div className='bg-white w-full overflow-auto'>
                      <div className=' '>
                        <h1 className='p-4  font-body font-semibold text-sm border-b border-t border-linecolor mb-5 text-center'>
                          Follow Groups / Communities
                        </h1>

                        <div>
                          <FollowCard
                            pic={Pic}
                            name='Coding girls'
                            content='Python'
                            button='Join'
                          />
                          <FollowCard
                            pic={Pic}
                            name='Business Startup'
                            // content='LLM Antoney'
                            button='Join'
                          />
                          <FollowCard
                            pic={Pic}
                            name='Dev Ops'
                            // content='LLM Antoney'
                            button='Join'
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowerSideMenu;
