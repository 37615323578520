import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddBox } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import CustomAlert from '../components/CustomAlert';
import * as userActions from '../redux/actions/users';
import * as discussionGroupsActions from '../redux/actions/discussiongroups';
import * as invitationActions from '../redux/actions/invitation';

const GroupPeople = ({ groupID, groupName }) => {
  const [showNewMember, setShowNewMember] = useState(false);
  const dispatch = useDispatch();
  const { members, success, error } = useSelector(
    (state) => state?.discussiongroups,
  );
  const user = useSelector((state) => state.user.user);
  const userId = user?._id;

  const { data } = useSelector((state) => state?.user);
  const userList = data?.filter(
    (value) => value.usercategory[0] !== 'institution',
  );

  useEffect(() => {
    dispatch(discussionGroupsActions.getOneDiscussionGroup({ groupID }));
    dispatch(userActions.getUsers());
  }, []);

  let memberData = [];
  members?.forEach((element) => {
    const user = userList?.filter((item) => item._id === element);
    memberData.push(user);
  });

  useEffect(() => {
    if (success.message === 'Members successfully added')
      dispatch(discussionGroupsActions.getOneDiscussionGroup({ groupID }));
  }, [success, error]);

  return (
    <>
      <div style={{}}>
        <h1 className='p-4  font-body font-semibold text-sm border-b border-linecolor mb-5 '>
          Members ({memberData.length})
          {members[0] === userId ? (
            <div style={{ float: 'right' }}>
              <span onClick={() => setShowNewMember(true)}>
                <AddBox
                  className='text-darkerblue'
                  style={{
                    fontSize: '30px',
                    cursor: 'pointer',
                    marginTop: '-0.5vh',
                  }}
                />
              </span>
            </div>
          ) : null}
        </h1>

        <div
          className='gen-group-people'
          style={{ height: '50vh', overflowY: 'scroll' }}
        >
          {memberData.length > 0
            ? memberData.map((value, key) => (
                <MemberCard key={value._id} member={value} />
              ))
            : null}
        </div>

        <ViewAddNewMember
          setShowNewMember={setShowNewMember}
          showNewMember={showNewMember}
          groupId={groupID}
          groupName={groupName}
        />
      </div>
    </>
  );
};

const ViewAddNewMember = (props) => {
  const dispatch = useDispatch();
  const [groupMembers, setGroupMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [memberName, setMemberName] = useState('');
  const [email, setEmail] = useState('');
  const groupId = props.groupId;
  const user = useSelector((state) => state.user.user);

  const { data } = useSelector((state) => state?.user);
  const invitation = useSelector((state) => state?.invitation);

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      discussionGroupsActions.updateGroupMembers({
        groupId: groupId,
        groupMembers: groupMembers,
      }),
    );

    props.setShowNewMember(false);
    setMemberName('');
    setGroupMembers([]);
  };

  const handleInvitation = async (e) => {
    e.preventDefault();

    const payload = {
      email: email,
      sentBy: user._id,
      sentUser: `${user.firstname} ${user.lastname}`,
      category: 'group',
      groupName: props.groupName,
      categoryID: props.groupId,
    };

    dispatch(invitationActions.createInvitation(payload));
  };

  useEffect(() => {
    if (invitation?.success?.status) {
      setEmail('');
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Email Invitation has been sent',
      });
    }
  }, [invitation]);

  const userList = data?.filter(
    (value) => value.usercategory[0] !== 'institution',
  );

  useEffect(() => {
    dispatch(userActions.getUsers());
  }, []);

  useEffect(() => {
    let filteredUsers = [];
    if (memberName !== '') {
      userList
        ?.filter(
          (item) =>
            item.firstname.toLowerCase().includes(memberName) ||
            item.lastname.toLowerCase().includes(memberName),
        )
        .map((item) => {
          return filteredUsers.push(item);
        });
    }
    setFilteredMembers(filteredUsers);
  }, [memberName]);

  return (
    <>
      {props.showNewMember ? (
        <>
          <div
            style={{ width: '70%', overflowY: 'hidden', marginLeft: '15%' }}
            className='modalClass items-start flex overflow-x-hidden overflow-y-auto lg:pt-4 fixed inset-0  '
          >
            <div className='relative w-auto lg:w-8/12 mx-auto max-w-5xl  '>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none h-modal '>
                {/*header*/}
                <div className='h-full'>
                  <div className='flex items-start justify-between px-8 pb-2 pt-8 rounded-t h-2/12 border-b border-linecolor'>
                    <h3 className='text-1xl font-display font-bold text-charcoal'>
                      Invite a friend
                    </h3>
                    <button
                      className='p-1 ml-auto  text-black float-right text-3xl leading-none font-semibold '
                      onClick={() => props.setShowNewMember(false)}
                    >
                      <span className='text-charcoal h-6 w-6 text-2xl  hover:text-reddark'>
                        ×
                      </span>
                    </button>
                  </div>

                  {/* </div>  */}
                  <div style={{ padding: '20px' }}>
                    <form onSubmit={handleInvitation}>
                      <label className='font-semibold text-textparagraph text-sm'>
                        Add Email
                      </label>
                      <div className='grid grid-cols-6'>
                        <div className='col-span-4'>
                          <input
                            required
                            type='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                          />
                        </div>
                        <div className='col-span-2'>
                          <button
                            style={{ float: 'right' }}
                            className='w-40 h-12 falsefocus:shadow-outline  bg-darkerblue text-white text-xl px-2 py-2 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                            type='submit'
                          >
                            Invite
                          </button>
                        </div>
                      </div>
                      <br />
                    </form>

                    {invitation?.data?._id ? (
                      <>
                        <label className='font-light text-textparagraph text-sm'>
                          <b>Copy invitation link :</b>
                          <br /> {process.env.REACT_APP_HOST}/invitation/
                          {invitation.data._id}
                        </label>
                        <div className='col-span-12'>
                          <br />
                        </div>
                      </>
                    ) : null}

                    <form onSubmit={handleSubmit}>
                      <label className='font-semibold text-textparagraph text-sm'>
                        Search for a member
                      </label>
                      <div className='grid grid-cols-6'>
                        <div className='col-span-4'>
                          <input
                            required
                            type='text'
                            value={memberName}
                            onChange={(e) => setMemberName(e.target.value)}
                            className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                          />
                        </div>
                        <div className='col-span-2'>
                          <button
                            style={{ float: 'right' }}
                            className='w-40 h-12 falsefocus:shadow-outline  bg-darkerblue text-white text-xl px-2 py-2 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                            type='submit'
                            onClick={(e) => handleSubmit(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>

                      <br />
                      <label className='font-semibold text-textparagraph text-sm'>
                        To add member check box on the right
                      </label>
                      <div
                        className='gen-group-people'
                        style={{ overflowY: 'scroll' }}
                      >
                        {filteredMembers.length > 0
                          ? filteredMembers.map((value, key) => (
                              <MemberChatCard
                                key={value._id}
                                member={value}
                                setGroupMembers={setGroupMembers}
                                groupMembers={groupMembers}
                              />
                            ))
                          : null}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-60 modalOverlayClass fixed inset-0 bg-black  flex justify-center '></div>
        </>
      ) : null}
    </>
  );
};

const MemberChatCard = ({ member, setGroupMembers, groupMembers }) => {
  return (
    <div
      className={`relative p-px mb-4 overflow-hidden transition duration-300 transform border-l-4 border-white group hover:shadow-sm hover:border-darkerblue `}
    >
      <div>
        <div className='relative flex flex-col h-full p-2 bg-white rounded-sm lg:items-center lg:flex-row lg:justify-between w-full '>
          <div className='flex flex-row justify-start '>
            <div className='flex items-center justify-between'>
              <div className='flex'>
                <img
                  src={
                    member?.profilePicture?.publicUrl ||
                    `https://ui-avatars.com/api/?name=${member?.firstname}+${member?.lastname}&bold=true`
                  }
                  alt='Event Thumb'
                  className='object-cover w-10 h-10 mr-1 lg:mr-4 rounded-full shadow'
                />
              </div>
            </div>
            <div className='flex flex-col justify-center lg flex-grow text-charcoal'>
              <div>
                <h2 className='text-sm font-body text-charcoal capitalize'>
                  {`${member?.firstname} ${member?.lastname}`}
                </h2>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 gap-5'>
            <Checkbox
              onChange={(e) =>
                setGroupMembers([...groupMembers, e.target.value])
              }
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
              value={`${member._id}`}
              id={`${member._id}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MemberCard = ({ member }) => {
  return (
    <div
      className={`relative  overflow-hidden transition duration-300 transform border-l-4 border-white group hover:shadow-sm hover:border-darkerblue `}
    >
      <div>
        <div className='relative flex flex-col h-full p-0 bg-white rounded-sm lg:items-center lg:flex-row lg:justify-between w-full '>
          <div className='flex flex-row justify-start '>
            <div className='mb-2 mr-2 lg:mb-0'>
              <div className='flex items-center justify-center w-16 h-16 rounded-full lg:w-16 lg:h-16  lg:flex'>
                <img
                  src={
                    member[0]?.profilePicture?.publicUrl ||
                    `https://ui-avatars.com/api/?name=${member[0]?.firstname}+${member[0]?.lastname}&bold=true&background=CABCDC`
                  }
                  alt='Event Thumb'
                  style={{
                    width: '65%',
                    height: '65%',
                    margin: '1px',
                  }}
                  className='object-cover rounded-full shadow-sm'
                />
              </div>
            </div>
            <div className='flex flex-col justify-center lg flex-grow text-charcoal'>
              <div>
                <h2 className=' font-display text-1xl leading-5 capitalize'>
                  {`${member[0]?.firstname} ${member[0]?.lastname}`}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupPeople;
