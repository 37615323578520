import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Nav from '../components/NavBarR';
import ContentTable from '../components/MyContentTable';

import * as contentActions from '../redux/actions/content';

export default function ArticlesMyArticles() {
  const dispatch = useDispatch();
  const { myContent, count, error, errorMessage } = useSelector(
    (state) => state.content,
  );

  useEffect(() => {
    if (error) console.log(errorMessage);
  }, [error]);

  useEffect(() => {
    dispatch(contentActions.getMyArticles({ skip: 0, limit: 30 }));
  }, []);

  const loadPage = (skip, limit) =>
    dispatch(contentActions.getMyArticles({ skip, limit }));

  return (
    <>
      <Nav />
      <div className='bg-lightGrayBg2 w-full h-screen'>
        <ContentTable
          count={count}
          loadPage={loadPage}
          myContent={myContent}
          contentType={'Article'}
        />
      </div>
    </>
  );
}
