import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import logo from '../assets/delv_blue_bold1.svg';

import * as helpers from '../helpers';

import * as disciplinesActions from '../redux/actions/disciplines';
import * as usersActions from '../redux/actions/users';

const OnboardingNav = () => {
  return (
    <div className='sticky top-0 z-50'>
      <div className='flex items-center space-x-8'>
        <div className='bg-white h-20 w-screen py-5 flex'>
          <div className='flex justify-start items-center'>
            <a href='/'>
              <img src={logo} alt='delv-logo' className='pl-10' />
            </a>
          </div>
          <div className='flex flex-1 justify-end items-center'>
            <div className='flex items-center'>
              <a
                href='/feeds'
                aria-label='To login form'
                className='text-darkerblue font-semibold px-6'
              >
                {'Go to Feeds'}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='border-t-2 border-linecolor'></div>
    </div>
  );
};

const Interest = (props) => {
  const [selected, toggleSelected] = helpers.useToggle();

  useEffect(() => {
    selected
      ? props.handleAddition(props.name)
      : props.handleDelete(props.name);
  }, [selected, props]);

  return (
    <>
      <button
        className={
          'p-5 duration-300 transform border border-linecolor rounded-lg  hover:-translate-y-2 hover:bg-bannerblue h-32 inline-flex justify-around items-center font-semibold text-lg text-center ' +
          (selected ? 'bg-bannerBlue' : 'bg-white ')
        }
        onClick={toggleSelected}
      >
        {props.name}
      </button>
    </>
  );
};

const OnboardingGroups = () => {
  return (
    <>
      <OnboardingNav />
      <div className='px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
        <div className='max-w-xl mb-10 text-left lg:max-w-2xl md:mb-12'>
          <p className='text-base text-headingtext md:text-lg uppercase'>
            Customize your profile to receive relevant content
          </p>
          <h2 className='mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-textsecondary sm:text-4xl '>
            <span className='relative inline-block'>
              <span className='relative'>Select </span>
            </span>{' '}
            any groups or communities you are interested in
          </h2>
        </div>
        <div className='grid gap-5 mb-8 md:grid-cols-3 lg:grid-cols-5'>
          <Interest name='Web Development' />
          <Interest name='Data Science' />
          <Interest name='Computer Science' />
          <Interest name='Machine Learning' />
          <Interest name='Web Design' />
          <Interest name='Career Services' />
          <Interest name='Sales' />
          <Interest name='Social Media' />
          <Interest name='Business' />
          <Interest name='Cloud Computing' />
        </div>
        <div className='text-center'>
          <button className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md  bg-main hover:bg-navyblue focus:shadow-outline focus:outline-noone'>
            Create Profile
          </button>
        </div>
      </div>
    </>
  );
};

const OnboardingInterests = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const success = useSelector((state) => state.user.success);
  const disciplines = useSelector((state) => state.disciplines.disciplines);

  const [interests, setInterests] = useState([]);
  const [disableNextPage, setDisableNextPage] = useState(true);

  const handleDelete = (interest) => {
    const index = interests.indexOf(interest);
    if (index > -1) {
      interests.splice(index, 1);
    }
  };
  const handleAddition = (interest) => {
    if (interests.indexOf(interest) === -1) {
      setInterests([...interests, interest]);
    }
  };

  const handleSubmit = () => {
    dispatch(usersActions.addInterests({ interests: interests.toString() }));
  };

  useEffect(() => {
    if (disciplines.length === 0) dispatch(disciplinesActions.getDisciplines());
    if (interests.length < 3) setDisableNextPage(true);
    else setDisableNextPage(false);
    if (success.message === 'User interests added') history.push('/profile');
  }, [disciplines, interests, success]);

  return (
    <>
      <OnboardingNav />
      <div className='px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
        <div className='max-w-xl mb-10 text-left lg:max-w-2xl md:mb-12'>
          <p className='text-base text-headingtext md:text-lg uppercase'>
            Customize your profile to receive relevant content
          </p>
          <h2 className='mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-textsecondary sm:text-4xl '>
            <span className='relative inline-block'>
              <span className='relative'>Select </span>
            </span>{' '}
            at least 3 areas of interest to you
          </h2>
        </div>
        <div className='grid gap-5 mb-8  grid-cols-2  md:grid-cols-3 lg:grid-cols-5'>
          {disciplines?.map((value, index, disciplines) =>
            index % 5 === 0 ? (
              <>
                <Interest
                  name={disciplines[index]?.discipline}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                />
                <Interest
                  name={disciplines[index + 1]?.discipline}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                />
                <Interest
                  name={disciplines[index + 2]?.discipline}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                />
                <Interest
                  name={disciplines[index + 3]?.discipline}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                />
                <Interest
                  name={disciplines[index + 4]?.discipline}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                />
              </>
            ) : null,
          )}
        </div>
        <div className='text-center'>
          <button
            className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md  bg-main hover:bg-navyblue focus:shadow-outline focus:outline-noone'
            disabled={disableNextPage}
            onClick={(e) => handleSubmit()}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export { OnboardingInterests, OnboardingGroups };
