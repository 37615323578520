import * as fetchFunctions from '../../api/index';
import * as discussionGroupsTypes from '../types/discussiongroups';
import discussionGroupsEndpoints from '../endpoints/discussiongroups';

export const createDiscussionGroup =
  (payload) => async (dispatch, getState) => {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.postData(
      //`${process.env.REACT_APP_CORS_PROXY}/${discussionGroupsEndpoints.create_discussion_groups}`,
      discussionGroupsEndpoints.create_discussion_groups,
      payload,
      token,
    );

    if (result.success) {
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_SUCCESS,
        payload: {
          status: true,
          message: 'Discussion group Successfully created',
        },
      });
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_RESET,
      });
    } else {
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_ERROR,
        payload: {
          error: true,
          errorMessage: 'Group creation failed',
        },
      });
    }
  };

export const updateGroupMembers = (payload) => async (dispatch, getState) => {
  dispatch({
    type: discussionGroupsTypes.DISCUSSION_GROUPS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    discussionGroupsEndpoints.update_group_members,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: discussionGroupsTypes.ADDED_GROUP_MEMBERS,
      payload: result.response,
    });
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_RESET,
    });
  } else {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_ERROR,
      payload: {
        error: true,
        errorMessage: 'Group member Addition failed',
      },
    });
  }
};

export const joinDiscussionGroup = (payload) => async (dispatch, getState) => {
  dispatch({
    type: discussionGroupsTypes.DISCUSSION_GROUPS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    discussionGroupsEndpoints.request_join_discussion_group,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_SUCCESS,
      payload: {
        status: true,
        message: 'Request to join submitted',
      },
    });
    dispatch({
      type: discussionGroupsTypes.GET_DISCUSSION_GROUPS,
    });
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_SUCCESS,
    });
  } else {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_ERROR,
      payload: {
        error: true,
        errorMessage: 'Failed to submit join request',
      },
    });
  }
};

export const acceptRequestJoinDiscussionGroup =
  (payload) => async (dispatch, getState) => {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.postData(
      // `${process.env.REACT_APP_CORS_PROXY}/${discussionGroupsEndpoints.accept_join_request}`,
      discussionGroupsEndpoints.accept_join_request,
      payload,
      token,
    );

    if (result.success) {
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_SUCCESS,
        payload: {
          status: true,
          message: 'Request to join Accepted',
        },
      });
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_RESET,
      });
    } else {
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_ERROR,
        payload: {
          error: true,
          errorMessage: 'Failed to approve request, try again',
        },
      });
    }
  };

export const rejectRequestJoinDiscussionGroup =
  (payload) => async (dispatch, getState) => {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.postData(
      `${process.env.REACT_APP_CORS_PROXY}/${discussionGroupsEndpoints.reject_join_request}`,
      payload,
      token,
    );

    if (result.success) {
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_SUCCESS,
        payload: {
          status: true,
          message: 'Request to join Rejected',
        },
      });
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_RESET,
      });
    } else {
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getAllJoinRequests = (userID) => async (dispatch, getState) => {
  dispatch({
    type: discussionGroupsTypes.DISCUSSION_GROUPS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    discussionGroupsEndpoints.get_all_join_requests,
    token,
  );

  if (result.success) {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_GET_JOIN_REQUESTS,
      payload: result.data,
    });
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_SUCCESS,
    });
  } else {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_ERROR,
      payload: {
        error: true,
        errorMessage: 'Failed to reject request, try again',
      },
    });
  }
};

export const getAllDiscussionGroups =
  ({ skip = 0, limit = 30 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${discussionGroupsEndpoints.get_all_discussion_groups}/?skip=${skip}&limit=${limit}`,
      token,
    );
    if (result.success) {
      dispatch({
        type: discussionGroupsTypes.GET_DISCUSSION_GROUPS,
        payload: result.data,
      });
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_SUCCESS,
      });
    } else {
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getOneDiscussionGroup =
  ({ groupID }) =>
  async (dispatch, getState) => {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_LOADING,
    });

    const result = await fetchFunctions.getData(
      `${discussionGroupsEndpoints.get_one_discussion_group}/${groupID}`,
    );
    if (result.success) {
      dispatch({
        type: discussionGroupsTypes.GET_ONE_DISCUSSION_GROUP,
        payload: result.data,
      });
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_SUCCESS,
      });
    } else {
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const checkMemberInGroup =
  ({ groupID, userID }) =>
  async (dispatch, getState) => {
    dispatch({
      type: discussionGroupsTypes.DISCUSSION_GROUPS_LOADING,
    });

    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${discussionGroupsEndpoints.check_member_in_group}/${groupID}/${userID}`,
      token,
    );
    if (result.success) {
      dispatch({
        type: discussionGroupsTypes.CHECK_MEMBER_IN_GROUP,
        payload: result.data,
      });
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_SUCCESS,
      });
    } else {
      dispatch({
        type: discussionGroupsTypes.DISCUSSION_GROUPS_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };
