import * as usersTypes from '../types/users';

const initialState = {
  data: [],
  totalUsers: null,
  token: null,
  user: null,
  userData: null,
  loading: false,
  error: false,
  errorMessage: null,
  loggedIn: false,
  success: {
    status: false,
    message: 'All set!',
  },
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case usersTypes.USERS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case usersTypes.USER_LOGIN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        loggedIn: true,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case usersTypes.USER_LOGOUT:
      return {
        ...state,
        data: [],
        user: null,
        token: null,
        error: false,
        loading: false,
        loggedIn: false,
        errorMessage: null,
        success: {
          status: false,
          message: 'All set!',
        },
      };
    case usersTypes.USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case usersTypes.REJECTED_POINTS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        user_rejected_success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case usersTypes.ADD_FOLLOW:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        follow_success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case usersTypes.GET_FOLLOW:
      return {
        ...state,
        follow_data: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case usersTypes.USERS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case usersTypes.GET_USERS:
      return {
        ...state,
        data: action.payload.users,
        totalUsers: action.payload.count,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case usersTypes.USER_CONFIRMED:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        user_confirm: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case usersTypes.GET_A_USER:
      return {
        ...state,
        userData: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case usersTypes.SPECIFIC_USER:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case usersTypes.GET_MENTORS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case usersTypes.USER_POINTS:
      return {
        ...state,
        user_points: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case usersTypes.USERS_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        user_confirm: {
          status: false,
          message: 'All set!',
        },
        follow_success: {
          status: false,
          message: 'All set!',
        },
        success: {
          status: false,
          message: 'All set!',
        },
      };
    case usersTypes.USER_PROFILE:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default usersReducer;
