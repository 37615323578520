import React from 'react';

// components
import Button from '../components/Button';
import Video from '../components/Video';

import CourseCarddata from '../components/CourseCarddata';
import MentorCard from '../components/MentorCard';

import Sidebar from '../components/Sidebar';

//SVG
import vector from '../assets/Vector.svg';
import vector2 from '../assets/Vector2.svg';
import vector3 from '../assets/Vector3.svg';
import vector4 from '../assets/Vector4.svg';
import video1 from '../assets/video1.svg';
import video2 from '../assets/video2.svg';
import video3 from '../assets/video3.svg';
import playarrow from '../assets/playarrow.svg';
import leftarrow from '../assets/leftarrow.svg';
import rightarrow from '../assets/rightarrow.svg';
import Nav from '../components/NavBarR';

const Calendar = () => {
  return (
    <>
      {/* <i class='fas fa-angle-left'></i>
      <i class='fas fa-angle-right'></i> */}
      <table className='w-56 mb-12 mt-14'>
        <caption>
          <div className='flex flex-row justify-between mb-10 '>
            <div id='month' className='flex flex-1 text-headingtext font-body '>
              <strong>
                <h1 className='font-semibold'>Feburary</h1>
              </strong>
            </div>
            <div className='mr-8'>
              <span id='l-arrow' className='pr-1'>
                {' '}
                <i className='fas fa-angle-left text-headingtext '></i>
              </span>
              <span id='r-arrow'>
                {' '}
                <i className='fas fa-angle-right text-headingtext'></i>
              </span>
            </div>
          </div>
        </caption>
        <thead>
          <tr className='text-headingtext p-8'>
            <th className='h-8 w-8'>Mo</th>
            <th className='h-8 w-8'>Tu</th>
            <th className='h-8 w-8'>We</th>
            <th className='h-8 w-8'>Th</th>
            <th className='h-8 w-8'>Fr</th>
            <th className='h-8 w-8'>Sa</th>
            <th className='h-8 w-8'>Su</th>
          </tr>
        </thead>
        <tbody>
          <tr className='items-center text-headingtext p-4 '>
            <td>
              <button className='h-8 w-8'>1</button>
            </td>
            <td>
              <button className='h-8 w-8'>2</button>
            </td>
            <td>
              <button className='h-8 w-8'>3</button>
            </td>
            <td>
              <button className='h-8 w-8 rounded-full bg-darkerblue text-white'>
                4
              </button>
            </td>
            <td>
              <button className='h-8 w-8'>5</button>
            </td>
            <td>
              <button className='h-8 w-8'>6</button>
            </td>
            <td>
              <button className='h-8 w-8'>7</button>
            </td>
          </tr>
          <tr className='items-center text-headingtext p-4'>
            <td>
              <button className='h-8 w-8'>8</button>
            </td>
            <td>
              <button className='h-8 w-8'>9</button>
            </td>
            <td>
              <button className='h-8 w-8'>10</button>
            </td>
            <td>
              <button className='h-8 w-8'>11</button>
            </td>
            <td>
              <button className='h-8 w-8'>12</button>
            </td>
            <td>
              <button className='h-8 w-8'>13</button>
            </td>
            <td>
              <button className='h-8 w-8'>14</button>
            </td>
          </tr>
          <tr className='items-center text-headingtext p-4'>
            <td>
              <button className='h-8 w-8'>15</button>
            </td>
            <td>
              <button className='h-8 w-8'>16</button>
            </td>
            <td>
              <button className='h-8 w-8'>17</button>
            </td>
            <td>
              <button className='h-8 w-8'>18</button>
            </td>
            <td>
              <button className='h-8 w-8'>19</button>
            </td>
            <td>
              <button className='h-8 w-8'>20</button>
            </td>
            <td>
              <button className='h-8 w-8'>21</button>
            </td>
          </tr>
          <tr className='items-center text-headingtext p-4'>
            <td>
              <button className='h-8 w-8'>22</button>
            </td>
            <td>
              <button className='h-8 w-8'>23</button>
            </td>
            <td>
              <button className='h-8 w-8 rounded-full bg-reddark text-white'>
                24
              </button>
            </td>
            <td>
              <button className='h-8 w-8'>25</button>
            </td>
            <td>
              <button className='h-8 w-8'>26</button>
            </td>
            <td>
              <button className='h-8 w-8'>27</button>
            </td>
            <td>
              <button className='h-8 w-8'>28</button>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const OverviewIcon = (props) => {
  return (
    <div>
      <div className='my-6 ml-2.5 '>
        <img src={props.vector} alt='vector' />
      </div>
    </div>
  );
};

const OverviewCard = (props) => {
  return (
    <div>
      <div className='flex flex-col ml-4 '>
        <div className='flex-1 mt-4 text-headingtext font-body text-xs '>
          {props.ongoingcourse}
        </div>
        <div className='text-2xl font-body text-main mt-2'>{props.number}</div>
      </div>
    </div>
  );
};

export default function Dashboard() {
  return (
    <div>
      <Nav dashboardActive={true} />

      <div className='flex flex-row w-screen'>
        <>
          <div className='border-r border-linecolor w-64'>
            <Sidebar />
          </div>
          <main className='flex flex-1 bg-lightgraybg  min-h-screen justify-center'>
            <div className='px-5'>
              <div className='text-lg font-bold text-black font-body mt-6 mb-5 flex'>
                <h1>Overview</h1>
              </div>
              <div className='flex flex-row  justify-between'>
                <div className='w-48 h-24 shadow-md rounded-xl flex flex-row '>
                  <OverviewIcon vector={vector} />
                  <OverviewCard ongoingcourse='On going courses' number='6' />
                </div>
                <div className='w-48 h-24 shadow-md rounded-xl flex flex-row '>
                  <OverviewIcon vector={vector2} />
                  <OverviewCard ongoingcourse='Upcoming Events' number='10' />
                </div>
                <div className='w-48 h-24 shadow-md rounded-xl flex flex-row  '>
                  <OverviewIcon vector={vector3} />
                  <OverviewCard ongoingcourse='My Mentors' number='24' />
                </div>
                <div className='w-48 h-24 shadow-md rounded-xl flex flex-row  '>
                  <OverviewIcon vector={vector4} />
                  <OverviewCard ongoingcourse='My Community' number='10' />
                </div>
              </div>
              <div className='flex flex-row w-full mt-20 mb-10 justify-between'>
                <div className='flex-1 text-lg font-bold text-black font-body '>
                  <h1>My Courses</h1>
                </div>
                <div>
                  <Button content='View All' backgroundColor='bg-darkerblue' />
                </div>
              </div>
              <div className='flex justify-between relative'>
                <div className='bg-white rounded-md shadow-md w-64 h-80  mb-14 '>
                  <Video url={video1} />
                  <div className='top-36 h-12 w-12 rounded-full -left-4 items-center bg-white shadow-sm  absolute'>
                    <img
                      src={leftarrow}
                      alt='playbutton'
                      className='justify-center mt-4 ml-4 '
                    />
                  </div>
                  <CourseCarddata
                    students='231 students'
                    hours='2h 27m'
                    description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                    author='Richard'
                    star='4.6'
                    value='(100)'
                  />
                </div>

                <div className='bg-white rounded-md shadow-md w-64 h-80 mb-14 relative hover:bg-blueshadow'>
                  <Video url={video2} className='' />

                  <div className='top-36 h-12 w-12 rounded-full  left-28 items-center bg-white shadow-sm absolute'>
                    <img
                      src={playarrow}
                      alt='playbutton'
                      className='justify-center mt-4 ml-4 '
                    />
                  </div>
                  <CourseCarddata
                    students='231 students'
                    hours='2h 27m'
                    description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                    author='Richard'
                    star='4.6'
                    value='(100)'
                  />
                </div>

                <div className='bg-white rounded-md shadow-md w-64 h-80 mb-14'>
                  <Video url={video3} />
                  <div className='top-36 h-12 w-12 rounded-full -right-4 items-center bg-white shadow-sm absolute'>
                    <img
                      src={rightarrow}
                      alt='playbutton'
                      className='justify-center mt-4 ml-4 '
                    />
                  </div>
                  <CourseCarddata
                    students='231 students'
                    hours='2h 27m'
                    description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                    author='Richard'
                    star='4.6'
                    value='(100)'
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className='flex flex-row w-full justify-between mb-8'>
                    <div className='flex-1 text-lg font-bold text-black font-body'>
                      <h1>My Mentors</h1>
                    </div>
                    <div>
                      <Button
                        content='View All'
                        backgroundColor='bg-darkerblue'
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex justify-between pb-32'>
                <MentorCard
                  name='Jonnathan Doe'
                  course='BA and Associate Degree'
                  star='4.6'
                />
                <MentorCard
                  name='Jonnathan Doe'
                  course='BA and Associate Degree'
                  star='4.6'
                />
                <MentorCard
                  name='Jonnathan Doe'
                  course='BA and Associate Degree'
                  star='4.6'
                />
              </div>
            </div>
          </main>
          <sidebar className='left_rec w-64 min-h-screen border-l border-linecolor px-2 mr-4'>
            <div className='text-headingtext font-body font-semibold pt-11 pb-8'>
              MY EVENTS
            </div>

            <Calendar />

            <div className='w-56 h-14 px-5 bg-lightgraybg border-l-2 border-darkerblue'>
              <p className='text-textparagraph font-body font-medium text-xs  pt-2.5'>
                <strong>Zoom meeting with my mentor</strong>
              </p>
              <p className='pt-1.5 pb-1.5 text-xs font-body text-textparagraph font-light'>
                4th Feb
              </p>
            </div>
            <div className='w-56 h-14 px-5 mt-5 bg-pink border-l-2 border-red'>
              <p className='text-lightgraybg font-body font-medium text-xs  pt-2.5'>
                <strong>Zoom meeting with my mentor</strong>
              </p>
              <p className='pt-1.5 pb-1.5 text-xs font-body text-lightgraybg font-light'>
                4th Feb
              </p>
            </div>
          </sidebar>
        </>
      </div>
    </div>
  );
}
