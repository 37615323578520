import * as discussionGroupsTypes from '../types/discussiongroups';

const initialState = {
  count: null,
  loading: false,
  oneGroupDiscussionLoading: false,
  error: false,
  isMember: 3,
  payload: null,
  errorMessage: null,
  groupName: null,
  members: [],
  discussionGroupsList: [],
  joinRequestsList: [],
  success: {
    status: false,
    message: 'All set!',
  },
};

const discussionGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case discussionGroupsTypes.DISCUSSION_GROUPS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case discussionGroupsTypes.DISCUSSION_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload?.status,
          message: action.payload?.message,
        },
      };
    case discussionGroupsTypes.ADDED_GROUP_MEMBERS:
      console.log(action.payload?.message);
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: true,
          message: action.payload?.message,
        },
      };
    case discussionGroupsTypes.DISCUSSION_GROUPS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case discussionGroupsTypes.GET_DISCUSSION_GROUPS:
      return {
        ...state,
        count: action.payload.count,
        discussionGroupsList: action.payload.discussionGroups,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case discussionGroupsTypes.CHECK_MEMBER_IN_GROUP:
      return {
        ...state,
        isMember: action.payload,
        loading: true,
        error: false,
        errorMessage: null,
      };

    case discussionGroupsTypes.GET_ONE_DISCUSSION_GROUP:
      return {
        ...state,
        groupName: action.payload.groupName,
        members: action.payload.members,
        oneGroupDiscussionLoading: true,
        error: false,
        errorMessage: null,
      };

    case discussionGroupsTypes.DISCUSSION_GROUPS_GET_JOIN_REQUESTS:
      return {
        ...state,
        joinRequestsList: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case discussionGroupsTypes.DISCUSSION_GROUPS_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: false,
          message: 'All set!',
        },
      };
    default:
      return state;
  }
};

export default discussionGroupsReducer;
