import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Assets
// import logo from '../assets/new_delvLogo.png';
import { ReactComponent as Search } from '../assets/search.svg';
import { ReactComponent as NotificationBellIcon } from '../assets/notification.svg';

//Components
import Input from './Input';
import Button from './Button';
import UserMenu from './UserDropdown';
import BrowseMenu from './BrowseDropdown_new';

// Actions
import * as searchActions from '../redux/actions/search';

export default function Navbar({ userType, institutions }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);

  let [keywords, setKeywords] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(searchActions.search({ keywords, skip: 0, limit: 30 }));
    history.push('/search');
  };

  let instituteRequired;

  const isInstitution = userType === 'institution';

  if (isInstitution) {
    const institutionList = [];
    institutions?.map((value) => institutionList.push(value));
    instituteRequired = institutionList.find(
      (inst) => inst.admins[0] === `${user?._id}`,
    );
  }

  return (
    <div className='w-full pt-2 pb-3 bg-newMain'>
      <div className='px-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl'>
        <div className='relative flex items-center justify-between '>
          <div className='flex justify-start items-center'>
            <div className='h-10 w-auto'>
              <Link to='/'>
                <p
                  style={{
                    height: '48px',
                    width: '130px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundImage: `url('https://irp.cdn-website.com/09b19d04/dms3rep/multi/delv-web-logo-01.svg')`,
                  }}
                  className='delv cursor-pointer'
                ></p>
              </Link>
            </div>

            <div className='pl-8 font-extrabold text-white text-base flex items-center'>
              <div>
                <BrowseMenu />
              </div>
            </div>
            <div className='p-1 bg-newMain ml-8 rounded-2xl flex items-center border border-white max-w-md'>
              <Search className='mx-2' onClick={handleSubmit} />
              <form method='get' onSubmit={handleSubmit}>
                <Input
                  placeholder='Search for courses, mentors, jobs, etc ....'
                  backgroundColor='bg-newMain'
                  contentColor='text-white'
                  placeholderColor='placeholder-white'
                  borderColor='border-newMain'
                  focus='focus:text-white'
                  width='w-80'
                  padding='px-2'
                  contentSize='text-sm'
                  value={keywords}
                  submitInput={setKeywords}
                />
              </form>
            </div>
          </div>
          {user ? (
            <div className='flex flex-1 justify-end items-center'>
              <span className='rounded-full h-10 w-10 flex items-center justify-center bg-newMain mx-4 text-white'>
                <NotificationBellIcon />
              </span>
              <img
                src={
                  user?.profilePicture?.publicUrl ||
                  `https://ui-avatars.com/api/?name=${
                    isInstitution
                      ? `${instituteRequired?.institutionName}`
                      : `${user?.firstname} ${user?.lastname}`
                  }&bold=true`
                }
                alt='profile'
                className='rounded-full h-10 w-10 flex items-center object-cover justify-center mr-2 ml-4'
              />
              <div className='flex items-center'>
                <UserMenu
                  user={
                    isInstitution
                      ? `${instituteRequired?.institutionName}`
                      : `${user?.firstname} ${user?.lastname}`
                  }
                />
              </div>
              <div className=''>
                <Button
                  content='GO PREMIUM'
                  backgroundColor='rgba(75,42,173,1)'
                  borderRadius='rounded-full'
                  font-weight='lighter'
                />
              </div>
            </div>
          ) : (
            <div className='flex flex-1 justify-end items-center'>
              <p className='text-body text-white font-medium text-base'>
                <Link to='/login'>Login</Link>
              </p>
              <div className='ml-3'>
                <Button
                  content='Get started today'
                  backgroundColor='bg-white'
                  contentColor='text-darkerblue'
                  borderRadius='rounded-full'
                  onClick={() => history.push('/signup')}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
