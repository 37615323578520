import React from 'react';
import { Star, StarOutline } from '@material-ui/icons';

export default function DisplayStarRating({ selectStars = 0 }) {
  const emptyStars = (frequency) => {
    let stars = [];
    for (let i = 0; i < frequency; i++) {
      stars.push(<StarOutline fontSize='small' style={{ color: '#FFBF00' }} />);
    }
    return stars;
  };

  const fullStars = (frequency) => {
    let stars = [];
    for (let i = 0; i < frequency; i++) {
      stars.push(<Star fontSize='small' style={{ color: '#FFBF00' }} />);
    }
    return stars;
  };

  const stars = (rating) => {
    switch (rating) {
      case 1:
        return (
          <>
            {fullStars(1)}
            {emptyStars(4)}
          </>
        );
      case 2:
        return (
          <>
            {fullStars(2)}
            {emptyStars(3)}
          </>
        );
      case 3:
        return (
          <>
            {fullStars(3)}
            {emptyStars(2)}
          </>
        );
      case 4:
        return (
          <>
            {fullStars(4)}
            {emptyStars(1)}
          </>
        );
      case 5:
        return <>{fullStars(5)}</>;
      default:
        return <>{emptyStars(5)}</>;
    }
  };

  return (
    <div className='inline-grid grid-cols-5 gap-x-0'>{stars(selectStars)}</div>
  );
}
