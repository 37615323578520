import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  AccessAlarm,
  GroupWorkOutlined,
  ListAlt,
  PlayCircleOutline,
} from '@material-ui/icons';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function DashboardSidebar({
  user,
  myContent,
  mySessions,
  userType,
  institutions,
  setShowSurveyModal,
  userAffiliations,
  setShowSendInvitationModal,
  setShowUserPointsModal,
  showSendInvitationModal,
}) {
  const history = useHistory();
  // const [total, setTotal] = useState(0);
  let screen_width = getWindowDimensions().width;
  const currentUser = useSelector((state) => state.user.user);
  const points = useSelector((state) => state.user?.user_points);
  let totalArr = [];
  points?.points.map((value) => totalArr.push(value?.point));

  const myVideos = myContent?.filter((item) => item.category === 'Video');
  const myArticles = myContent?.filter((item) => item.category === 'Article');

  let instituteRequired;

  if (userType === 'institution') {
    const institutionList = [];
    institutions?.map((value) => institutionList.push(value));
    instituteRequired = institutionList.find(
      (inst) => inst.admins[0] === `${user?._id}`,
    );
  }

  /*
  function getSum(total, num) {
    return total + Math.round(num);
  }

  useEffect(() => {
    if (totalArr.length > 0) {
      setTotal(totalArr.reduce(getSum, 0));
    }
  }, [totalArr]);*/

  return (
    <div className='h-screen sticky w-full z-40'>
      <div className='bg-white  w-full pb-10 mb-6'>
        <div className='w-full'>
          <div
            onClick={() => {
              screen_width <= 757
                ? history.push('/updateprofile')
                : history.push('/profile');
            }}
            className='flex flex-col items-center justify-center h-60 border-b border-linecolor'
          >
            <img
              src={
                user?.profilePicture?.publicUrl ||
                `https://ui-avatars.com/api/?name=${
                  userType === 'institution'
                    ? instituteRequired?.institutionName
                    : `${user?.firstname}+${user?.lastname}`
                }&bold=true`
              }
              alt='profile'
              className='object-cover rounded-full h-24 w-24 flex items-center justify-center mx-auto'
            />
            <p className='pt-8 font-bold text-lg text-charcoal capitalize'>
              {userType === 'institution'
                ? `${instituteRequired?.institutionName}`
                : `${user?.firstname} ${user?.lastname}`}
            </p>
            <p className='pt-4 font-normal text-sm text-gray4 capitalize'>
              {user?.usercategory[user?.usercategory?.length - 1]}
            </p>
          </div>
          <div className='flex flex-col items-center justify-center h-20 border-b border-linecolor'>
            <button
              onClick={() => setShowSendInvitationModal(true)}
              className={`inline-flex items-center justify-center w-full h-12 px-6 
                                    text-white bg-darkerblue  hover:bg-navyblue
                                 font-medium tracking-wide  transition duration-200 rounded-full shadow-md md:w-auto  focus:shadow-outline focus:outline-none`}
            >
              <span className='ml-2 font-display'>Invite a friend</span>
            </button>
          </div>
          {/*<div
            onClick={() => setShowUserPointsModal(true)}
            style={{ cursor: 'pointer' }}
            className='flex flex-col text-gray items-center justify-center h-10 border-b border-linecolor'
          >
            Points : {`${total}`}
          </div>*/}

          <div className='space-y-8 mx-4 mt-8'>
            <div>
              <p className='uppercase text-headingtext font-semibold'>
                {userType === 'institution' ? 'Library' : 'My content'}
              </p>

              <Link
                to={
                  currentUser.usercategory[0] === 'institution'
                    ? `/institution/articles`
                    : `/articles/mylist`
                }
              >
                <div className='flex flex-row justify-between w-full  font-base text-sm tracking-wide text-gray   bg-none hover:bg-lightGrayBg hover:text-charcoal mt-5 '>
                  <div>
                    <ListAlt fontSize='small' />
                    <span className='ml-4'>Articles</span>
                  </div>
                  <span className='ml-2'>
                    {/* Number of articles */}
                    {myArticles?.length}
                  </span>
                </div>
              </Link>

              <Link
                to={
                  currentUser.usercategory[0] === 'institution'
                    ? `/institution/videos`
                    : `/videos/mylist`
                }
              >
                <div className='flex flex-row justify-between w-full  font-base text-sm tracking-wide text-gray   bg-none hover:bg-lightGrayBg hover:text-charcoal mt-5 '>
                  <div>
                    <PlayCircleOutline fontSize='small' />
                    <span className='ml-4'>Videos</span>
                  </div>
                  <span className='ml-2'>{myVideos?.length}</span>
                </div>
              </Link>
              <Link
                to={
                  currentUser.usercategory[0] === 'institution'
                    ? `/institution/events`
                    : `/sessions/mylist`
                }
              >
                <div className='flex flex-row justify-between w-full  font-base text-sm tracking-wide text-gray   bg-none hover:bg-lightGrayBg hover:text-charcoal mt-5 '>
                  <div>
                    <AccessAlarm fontSize='small' />
                    <span className='ml-4'>Sessions</span>
                  </div>
                  <span className='ml-2'>
                    {/* Number of Sessions */}
                    {mySessions?.length}
                  </span>
                </div>
              </Link>
            </div>

            {currentUser.usercategory[0] !== 'institution' &&
            currentUser.usercategory[0] !== 'mentor' ? (
              <div className='mt-4 border-t border-linecolor'>
                <Link to='/sessions/mylist'>
                  <div className='flex flex-row justify-between w-full  font-base text-sm tracking-wide text-gray   bg-none hover:bg-lightGrayBg hover:text-charcoal mt-5 '>
                    <div>
                      <AccessAlarm fontSize='small' />
                      <span className='ml-4'>My Sessions</span>
                    </div>
                    <span className='ml-2'>
                      {/* Number of Sessions */}
                      {mySessions?.length || 0}
                    </span>
                  </div>
                </Link>
              </div>
            ) : null}

            {currentUser.usercategory[0] === 'mentor' ? (
              <div className='mt-4 '>
                <Link to='/affiliations/mylist'>
                  <div className='flex flex-row justify-between w-full  font-base text-sm tracking-wide text-gray   bg-none hover:bg-lightGrayBg hover:text-charcoal mt-5 '>
                    <div>
                      <GroupWorkOutlined fontSize='small' />
                      <span className='ml-4'>My Affiliations</span>
                    </div>
                    {userAffiliations?.requests > 0 ? (
                      <span
                        className={`inline-flex items-center justify-center px-2 py-1 text-xs 
                      font-bold leading-none text-white bg-buttonGreen rounded-full w-11 h-5`}
                      >
                        NEW
                      </span>
                    ) : (
                      <span className='ml-2'>
                        {userAffiliations?.affiliations}
                      </span>
                    )}
                  </div>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {currentUser.usercategory[0] === 'institution' && (
        <div className='bg-white  w-full  px-4 py-6 flex flex-col justify-center'>
          <div className=''>
            <div className=''>
              <span
                className='cursor-pointer inline-flex items-center justify-center text-sm w-full h-10 px-2 font-medium tracking-wide text-darkerblue border-2 border-darkerblue transition duration-200 rounded-lg  shadow-md bg-none hover:bg-darkerblue  hover:text-white focus:shadow-outline focus:outline-noone'
                aria-label=''
                title='Submit survey'
                aria-controls=''
                aria-haspopup='true'
                onClick={() => {
                  setShowSurveyModal(true);
                }}
              >
                Send Survey
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
