export const CHAT_LOADING = 'CHAT_LOADING';
export const ALL_CHATS = 'ALL_CHATS';
export const ALL_CHAT_DISCUSSIONS = 'ALL_CHAT_DISCUSSIONS';
export const CHECK_MEMBER_IN_CHAT = 'CHECK_MEMBER_IN_CHAT';
export const GET_GROUP_CHAT_REQUESTS = 'GET_GROUP_CHAT_REQUESTS';
export const SUBMIT_CHAT_REQUEST_STATUS = 'SUBMIT_CHAT_REQUEST_STATUS';

export const CHAT_ERROR = 'CHAT_ERROR';
export const CHAT_RESET = 'CHAT_RESET';
export const CHAT_SUCCESS = 'CHAT_SUCCESS';
