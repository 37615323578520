import * as fetchFunctions from '../../api/index';
import * as sharedTypes from '../types/shared';
import sharedEndpoints from '../endpoints/shared';

export const shareContent = (payload) => async (dispatch, getState) => {
  dispatch({
    type: sharedTypes.SHARED_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    sharedEndpoints.share_content,
    payload,
    token,
    true,
  );

  if (result.success) {
    dispatch({
      type: sharedTypes.SHARED_SUCCESS,
      payload: {
        status: true,
        message: 'Content share successful',
      },
    });
    dispatch({
      type: sharedTypes.SHARED_RESET,
    });
  } else {
    dispatch({
      type: sharedTypes.SHARED_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
