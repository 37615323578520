import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DropzoneArea } from 'material-ui-dropzone';
import { ClipLoader } from 'react-spinners';
import countryList from 'react-select-country-list';
// import { AcUnit } from '@material-ui/icons';

// import Nav from '../components/NavBarR';
import Select from '../components/Select';
import CustomAlert from '../components/CustomAlert';

import * as userActions from '../redux/actions/users';
import NavHeader from '../components/NavBarHeader';
import ModalWrapper from '../components/ModalWrapper';
import UserPointsModal from '../components/UserPointsModal';

const NavigationHeader = ({ activeStatus, setActiveStatus }) => {
  return (
    <div className='px-2 flex items-center justify-center w-full'>
      <ul className='w-full hidden md:flex items-center  border-b-2 border-linecolor'>
        <li
          onClick={() => {
            setActiveStatus(1);
          }}
          className={
            activeStatus === 1
              ? 'py-2 px-2 cursor-pointer  border-b-4 border-main text-charcoal  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer  bg-transparent hover:bg-lightGrayBg   text-base   leading-none text-gray5'
          }
        >
          Profile
        </li>
        <li
          onClick={() => setActiveStatus(2)}
          className={
            activeStatus === 2
              ? 'py-2 px-2 cursor-pointer  border-b-4 border-main text-charcoal   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg   text-base   leading-none text-gray5'
          }
        >
          Photo
        </li>
        <li
          onClick={() => setActiveStatus(3)}
          className={
            activeStatus === 3
              ? 'py-2 px-2 cursor-pointer  border-b-4 border-main text-charcoal   ml-12  text-base   leading-none text-center '
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg   text-base   leading-none text-gray5'
          }
        >
          Reset Password
        </li>
        <li
          onClick={() => setActiveStatus(4)}
          className={
            activeStatus === 4
              ? 'py-2 px-2 cursor-pointer  border-b-4 border-main text-charcoal   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg   text-base   leading-none text-gray5'
          }
        >
          Close account
        </li>
      </ul>
      <div className='md:hidden relative w-11/12 mx-auto bg-white rounded'>
        <div className='absolute inset-0 m-auto mr-4 z-0 w-6 h-6'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='icon icon-tabler icon-tabler-selector'
            width={24}
            height={24}
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='#A0AEC0'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path stroke='none' d='M0 0h24v24H0z' />
            <polyline points='8 9 12 5 16 9' />
            <polyline points='16 15 12 19 8 15' />
          </svg>
        </div>
        <select
          aria-label='Selected tab'
          className='form-select block w-full p-3 border border-linecolor rounded text-gray5 appearance-none bg-white relative z-10'
        >
          <option
            selected
            className='text-base text-gray5'
            onClick={() => {
              setActiveStatus(1);
            }}
          >
            Profile
          </option>
          <option
            className='text-base text-gray5'
            onClick={() => {
              setActiveStatus(2);
            }}
          >
            Photo{' '}
          </option>
          <option
            className='text-base text-gray5'
            onClick={() => {
              setActiveStatus(3);
            }}
          >
            Reset Password{' '}
          </option>
          <option
            className='text-base text-gray5'
            onClick={() => {
              setActiveStatus(4);
            }}
            onSelect={() => {
              setActiveStatus(4);
            }}
          >
            Close account{' '}
          </option>
        </select>
      </div>
    </div>
  );
};

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user);
  const loading = useSelector((state) => state.user.loading);
  const error = useSelector((state) => state.user.error);
  const errorMessage = useSelector((state) => state.user.errorMessage);

  const [firstname, setFirstname] = useState(user?.firstname);
  const [lastname, setLastname] = useState(user?.lastname);
  const [email, setEmail] = useState(user?.email);
  const [country, setCountry] = useState(user?.country);
  const [category, setCategory] = useState(
    user?.usercategory[user.usercategory.length - 1],
  );
  const [linkedIn, setLinkedIn] = useState(user?.linkedIn || '');
  const countryOptions = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    // Changing country values to country names
    countryOptions.map((country) => (country.value = country.label));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      userActions.updateUser({
        email,
        firstname,
        lastname,
        country,
        category,
        linkedIn,
      }),
    );
  };

  useEffect(() => {
    if (error) console.log(errorMessage);
  }, [error]);

  return (
    <div className='max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 pt-4'>
      <h2 className='max-w-lg mb-2   text-xl font-bold leading-none tracking-tight text-charcoal sm:text-2xl md:mx-auto'>
        <span className='relative inline-block'>Public profile</span>
      </h2>
      <div>
        <p className='inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-textsecondary uppercase rounded-full'>
          Update information about yourself.
        </p>
      </div>
      <div className='bg-white rounded shadow-2xl p-7 sm:p-10 text-left text-charcoal font-semibold'>
        <form onSubmit={handleSubmit}>
          <h3 className='mb-4 text-lg font-semibold sm:text-center sm:mb-4 sm:text-xl'>
            Basics
          </h3>
          <div className='mb-1 sm:mb-2'>
            <label htmlFor='firstName' className='inline-block mb-1 '>
              First name
            </label>
            <input
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
              type='text'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
              id='firstName'
              name='firstName'
              readOnly={loading}
            />
          </div>
          <div className='mb-1 sm:mb-2'>
            <label htmlFor='lastName' className='inline-block mb-1 '>
              Last name
            </label>
            <input
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              required
              type='text'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
              id='lastName'
              name='lastName'
              readOnly={loading}
            />
          </div>
          <div className='mb-1 sm:mb-2'>
            <label htmlFor='email' className='inline-block mb-1 '>
              E-mail
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              type='text'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
              id='email'
              name='email'
              readOnly={loading}
            />
          </div>
          <div className='mb-1 sm:mb-2'>
            <label htmlFor='email' className='inline-block mb-1 '>
              Country
            </label>
            <Select
              name='Country'
              margin='mb-4'
              width='w-full'
              data={countryOptions}
              placeholder={country}
              submitInput={setCountry}
              id='country'
              value={country}
              disabled={loading}
            />
          </div>
          <div className='mb-1 sm:mb-2'>
            <label htmlFor='email' className='inline-block mb-1 '>
              Category
            </label>
            <Select
              name='Category'
              margin='mb-4'
              width='w-full'
              data={[
                { value: 'student', label: 'Student' },
                { value: 'graduate', label: 'Graduate' },
                { value: 'employee', label: 'Employed' },
                { value: 'mentor', label: 'Mentor' },
                { value: 'trainer', label: 'Trainer' },
                { value: 'institution', label: 'Institution' },
              ]}
              placeholder={category}
              submitInput={setCategory}
              id='country'
              value={category}
              disabled={loading}
            />
          </div>
          <div className='mb-1 sm:mb-2'>
            <label htmlFor='linkedInHandle' className='inline-block mb-1 '>
              LinkedIn
            </label>
            <input
              value={linkedIn}
              onChange={(e) => setLinkedIn(e.target.value)}
              type='text'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
              id='linkedInHandle'
              name='linkedInHandle'
              readOnly={loading}
            />
          </div>
          <div className='mt-4 mb-2 sm:mb-4 '>
            <button
              type='submit'
              disabled={loading}
              className='inline-flex items-center text-lg justify-center w-full h-12 px-6 py-2 font-bold tracking-wide text-white transition duration-200 rounded-md shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone '
            >
              {loading ? <ClipLoader color='navyblue' /> : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Photo = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loading);
  const error = useSelector((state) => state.user.error);
  const errorMessage = useSelector((state) => state.user?.errorMessage);
  const [photo, setPhoto] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.append('profilePicture', photo[0]);
    dispatch(userActions.updateImage(myForm));
  };

  useEffect(() => {
    if (error) console.log(errorMessage);
  }, [error]);

  return (
    <div className='max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 pt-4'>
      <h2 className='max-w-lg mb-2   text-xl font-bold leading-none tracking-tight text-charcoal sm:text-2xl md:mx-auto'>
        <span className='relative inline-block'>Photo</span>{' '}
      </h2>
      <div>
        <p className='inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-textsecondary uppercase rounded-full'>
          Add a profile photo
        </p>
      </div>
      <div className='bg-white rounded shadow-2xl p-7 sm:p-10 text-left'>
        <form onSubmit={handleSubmit}>
          <div className='mb-1 sm:mb-2'>
            <label
              htmlFor='imagePreview'
              className='inline-block mb-1 font-medium'
            >
              Upload Photo
            </label>
            <DropzoneArea
              filesLimit={1}
              showAlerts={false}
              maxFileSize={5000000}
              acceptedFiles={['image/*']}
              dropzoneText={'Upload Photo'}
              dropzoneClass={
                'MuiDropzoneArea-root bg-lightgraybg py-10 px-8 text-center text-sm  text-gray5 border border-dashed border-charcoal rounded-md'
              }
              dropzoneTextClass={'font-semfibold'}
              onChange={(files) => setPhoto(files)}
            />
          </div>
          <div className='mt-4 mb-2 sm:mb-4 '>
            <button
              type='submit'
              disabled={loading}
              className='inline-flex items-center text-lg justify-center w-full h-12 px-6 py-2 font-bold tracking-wide text-white transition duration-200 rounded-md shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone '
            >
              {loading ? <ClipLoader color='navyblue' /> : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ResetPassword = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user);
  const success = useSelector((state) => state.user.success);
  const loading = useSelector((state) => state.user.loading);
  const errorMessage = useSelector((state) => state.user?.errorMessage);

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      userActions.resetPassword({
        email: user?.email,
        password,
        newPassword,
      }),
    );
  };

  useEffect(() => {
    if (`${errorMessage}`.includes('incorrect'))
      CustomAlert({
        alertType: 'error',
        alertMessage: errorMessage,
      });
    if (`${success}`.includes('Password has been reset'))
      CustomAlert({
        alertType: 'success',
        alertMessage: errorMessage,
      });
  }, [success, errorMessage]);

  return (
    <div className='max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 pt-4'>
      <h2 className='max-w-lg mb-2   text-xl font-bold leading-none tracking-tight text-charcoal sm:text-2xl md:mx-auto'>
        <span className='relative inline-block'>Password Reset</span>
      </h2>
      <div>
        <p className='inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-textsecondary uppercase rounded-full'>
          change your password here.
        </p>
      </div>
      <div className='bg-white rounded shadow-2xl p-7 sm:p-10 text-left'>
        <form autoComplete='new-password' onSubmit={handleSubmit}>
          <div className='mb-1 sm:mb-2'>
            <label
              htmlFor='currentPassword'
              className='inline-block mb-1 font-medium'
            >
              Current Password
            </label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Current Password'
              required
              autoComplete='new-password'
              type='password'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
              id='currentPassword'
              name='currentPassword'
            />
          </div>
          <div className='mb-1 sm:mb-2'>
            <label
              htmlFor='newPassword'
              className='inline-block mb-1 font-medium'
            >
              New Password
            </label>
            <input
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder='New password'
              required
              autoComplete='new-password'
              type='password'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
              id='newPassword'
              name='newPassword'
            />
          </div>
          <div
            hidden={newPassword !== '' && newPassword.length < 8 ? false : true}
            style={{ color: 'red' }}
          >
            Password must be atleast 8 characters long
          </div>
          <div className='mb-1 sm:mb-2'>
            <label
              htmlFor='passwordConfirmation'
              className='inline-block mb-1 font-medium'
            >
              Confirm New Password
            </label>
            <input
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder='New Password'
              required
              autoComplete='new-password'
              type='password'
              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
              id='passwordConfirmation'
              name='passwordConfirmation'
            />
            <div
              hidden={newPassword !== confirmNewPassword ? false : true}
              style={{ color: 'red' }}
            >
              New passwords do not match
            </div>
          </div>
          <div className='mt-4 mb-2 sm:mb-4 '>
            <button
              type='submit'
              disabled={
                newPassword !== confirmNewPassword || newPassword.length < 8
              }
              className='inline-flex items-center text-lg justify-center w-full h-12 px-6 py-2 font-bold tracking-wide text-white transition duration-200 rounded-md shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone '
            >
              {loading ? <ClipLoader color='navyblue' /> : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const DeleteAccount = () => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  return (
    <div className='max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 pt-4'>
      <h2 className='max-w-lg mb-2   text-xl font-bold leading-none tracking-tight text-charcoal sm:text-2xl md:mx-auto'>
        <span className='relative inline-block'>Delete Account</span>{' '}
      </h2>
      <div>
        <p className='inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-textsecondary uppercase rounded-full'>
          Delete your account permanently
        </p>
      </div>
      <div className='bg-white rounded shadow-2xl p-7 sm:p-10 text-left'>
        <form>
          <div className='mb-1 sm:mb-2'>
            <label
              htmlFor='warningMessage'
              className='inline-block mb-1 font-medium text-xs sm:text-sm'
            >
              <span className='text-red'>Warning:{` `}</span> If you delete your
              account, you will be unsubscribed from all your courses, and will
              lose access forever.
            </label>
            <div className='mt-4 md:mt-8 mb-2 sm:mb-4'>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowDeleteConfirmationModal(true);
                }}
                className='inline-flex items-center justify-center w-full h-12 px-6 py-2 text-lg  tracking-wide text-white transition duration-200 rounded-md font-semibold shadow-md bg-red hover:bg-pink focus:shadow-outline focus:outline-noone '
              >
                Delete Account
              </button>
            </div>
            <DeleteConfirmationModal
              setShowDeleteConfirmationModal={setShowDeleteConfirmationModal}
              showDeleteConfirmationModal={showDeleteConfirmationModal}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const DeleteConfirmationModal = ({
  setShowDeleteConfirmationModal,
  showDeleteConfirmationModal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user?.user);
  const loading = useSelector((state) => state.user.loading);
  const success = useSelector((state) => state.user.success);
  const error = useSelector((state) => state.user.error);
  const errorMessage = useSelector((state) => state.user.errorMessage);

  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      userActions.logIn({
        email: user?.email,
        password,
      }),
    );
  };

  useEffect(() => {
    if (error)
      CustomAlert({
        alertType: 'error',
        alertMessage: errorMessage,
      });
    if (success.message === 'User logged in')
      dispatch(userActions.deleteAccount());
    if (success.message === 'Account deleted') {
      CustomAlert({
        alertType: 'success',
        alertMessage: success.message,
      });
      history.push('/');
    }
  }, [success, error]);

  return (
    <>
      {showDeleteConfirmationModal ? (
        <>
          <form>
            <ModalWrapper
              title={'Delete your Account'}
              closeModal={() => setShowDeleteConfirmationModal(false)}
              bodyContent={
                <div className='relative flex-auto'>
                  <span className='font-semibold text-charcoal text-xl sm:text-3xl w-full mt-1 flex justify-start mb-10'>
                    Are you sure? This action is not reversible.
                  </span>

                  <div className='mb-1 sm:mb-2'>
                    <input
                      placeholder='Enter your password to confirm'
                      required
                      disabled={loading}
                      autoComplete='new-password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type='password'
                      className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                      id='currentPassword'
                      name='currentPassword'
                    />
                  </div>
                  <button
                    type='button'
                    disabled={loading}
                    onClick={(e) => handleSubmit(e)}
                    className='w-full mt-6 h-12 falsefocus:shadow-outline  bg-red text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-pink outline-none focus:outline-noone  mr-1 mb-1 ease-linear transition-all duration-150'
                  >
                    {loading ? <ClipLoader color='navyblue' /> : 'Delete'}
                  </button>
                </div>
              }
            />
          </form>
        </>
      ) : null}
    </>
  );
};

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showUserPointsModal, setShowUserPointsModal] = useState(false);
  const points = useSelector((state) => state.user?.user_points);
  const user = useSelector((state) => state.user?.user);
  const [activeStatus, setActiveStatus] = useState(1);

  const follow = useSelector((state) => state.user?.follow_data);

  useEffect(() => {
    dispatch(userActions.getFollow({ userID: user?._id }));
  }, []);

  return (
    <>
      <NavHeader />
      <UserPointsModal
        setShowUserPointsModal={setShowUserPointsModal}
        showUserPointsModal={showUserPointsModal}
        points={points}
      />
      <div className='w-full bg-lightGrayBg2'>
        <div className='px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8  '>
          <button
            type='button'
            onClick={() => history.push('/feeds')}
            className='inline-flex items-center font-medium sm:max-w-full justify-center h-8 w-24 px-4 sm:mr-0  border border-darkerblue tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
          >
            {'<'} Back
          </button>
          <div className='max-w-2xl mx-auto sm:max-w-xl md:max-w-2xl bg-white p-4 rounded-md border border-linecolor '>
            <div className='text-center'>
              <div className='flex flex-col items-center mb-5'>
                <img
                  className='object-cover w-28 h-28 mb-2 rounded-full shadow'
                  src={
                    user?.profilePicture?.publicUrl ||
                    `https://ui-avatars.com/api/?name=${user?.firstname}+${user?.lastname}&bold=true`
                  }
                  alt='Profile pictue'
                />
                <div className='flex flex-col items-center'>
                  <p className='text-lg font-bold'>{`${user?.firstname} ${user?.lastname}`}</p>
                  <p className='text-sm text-gray-800'>{user?.email}</p>
                  <br />
                  <p
                    style={{
                      fontSize: '12px',
                    }}
                    className=' text-gray-800'
                  >
                    {follow?.following.length} Following &nbsp;&nbsp;&nbsp;{' '}
                    {follow?.followers.length} Followers
                  </p>
                </div>
                {/*
                <button
                  onClick={() => {
                    setShowUserPointsModal(true);
                  }}
                  style={{ marginTop: '20px' }}
                  className={`inline-flex items-center justify-center w-100 h-12 px-6 
                                    text-white bg-darkerblue  hover:bg-navyblue
                                 font-medium tracking-wide  transition duration-200 rounded-full shadow-md md:w-auto  focus:shadow-outline focus:outline-none`}
                >
                  <AcUnit />
                  <span className='ml-2 font-display'>My Points</span>
                </button>
                */}
              </div>
              <NavigationHeader
                activeStatus={activeStatus}
                setActiveStatus={setActiveStatus}
              />

              {activeStatus === 1 && <Profile />}
              {activeStatus === 2 && <Photo />}
              {activeStatus === 3 && <ResetPassword />}
              {activeStatus === 4 && <DeleteAccount />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
