import React from 'react';
import Calendar from '../assets/calendar.svg';
import Articles from '../assets/articles.svg';
import Videos from '../assets/videos.svg';
import Sessions from '../assets/sessionContent.svg';
import Reviews from '../assets/notification.svg';
import Chats from '../assets/chat.svg';
import Generic from '../assets/noContent.svg';

const NoContentPlaceholder = ({ type, content, generic }) => {
  let image = Calendar;
  if (content === 'Sessions') image = Sessions;
  if (content === 'Articles') image = Articles;
  if (content === 'Videos') image = Videos;
  if (content === 'Course') image = Articles;
  if (content === 'Reviews') image = Reviews;
  if (content === 'Chats') image = Chats;
  if (content === 'Discussions') image = Chats;

  if (generic === true) image = Generic;

  return (
    <div className='max-w-screen-sm text-center mx-auto'>
      <span
        aria-label='No upcoming events'
        className='inline-block  rounded-full mx-auto'
      >
        <div className='flex items-center justify-center w-32 h-32 mb-1 rounded-full'>
          <img
            src={image}
            alt='No content'
            className='object-cover h-full w-full mb-2'
          />
        </div>
      </span>
      <h2 className='mb-4 font-display text-lg font-bold tracking-tight text-headingtext sm:text-xl sm:leading-none'>
        {` ${
          content === 'Chats'
            ? 'First Select Discussion'
            : `No ${content} ${content === 'Sessions' ? type : ' available'} `
        } `}
      </h2>
    </div>
  );
};

export default NoContentPlaceholder;
