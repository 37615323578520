import React from 'react';
import moment from 'moment';

export default function PostComment({ comment }) {
  return (
    <div className='w-full mb-2'>
      <div className=' flex flex-row justify-start'>
        <img
          src={
            comment?.commentedBy?.profilePicture?.publicUrl ||
            `https://ui-avatars.com/api/?name=${`${comment?.commentedBy?.firstname} ${comment?.commentedBy?.lastname}`}&bold=true`
          }
          alt='mentor'
          className='rounded-full h-10 w-10 flex items-center justify-center mr-2 ml-4'
        />
        <div className='flex flex-col items-start bg-lightgraybg box-border rounded-lg p-3 w-11/12 mr-2'>
          <div className='flex justify-between text-sm text-charcoal w-full'>
            <div>
              <span className='font-bold'>
                {comment?.commentedBy?.firstname}{' '}
                {comment?.commentedBy?.lastname}
              </span>
            </div>
            <div>
              <span className='font-normal'>
                {moment(comment?.createdAt).local().fromNow()}
              </span>
            </div>
          </div>
          <div className='h-auto text-xs font-body text-charcoal pt-5 '>
            <p> {comment?.commentText}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
