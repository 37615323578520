import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Nav from '../components/NavBarR';
import ContentTable from '../components/MyContentTable';
//
// Actions
import * as contentActions from '../redux/actions/content';

const InstitutionVideos = () => {
  const dispatch = useDispatch();
  const myContent = useSelector((state) => state.content.myContent);
  const error = useSelector((state) => state.content.error);
  const errorMessage = useSelector((state) => state.content.errorMessage);

  useEffect(() => {
    if (error) console.log(errorMessage);
  }, [error]);

  useEffect(() => {
    dispatch(contentActions.getMyContent());
  }, []);

  const myVideos = [];
  myContent?.map((value) =>
    value?.category === 'Video' ? myVideos.push(value) : null,
  );

  return (
    <>
      <Nav videosActive={true} userType='institution' />
      <div className='bg-lightGrayBg2 w-full h-screen'>
        <ContentTable
          myContent={myVideos}
          contentType={'Video'}
          userType={'Institution'}
        />
      </div>
    </>
  );
};

export default InstitutionVideos;
