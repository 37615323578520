import { StarRate } from '@material-ui/icons';
import React from 'react';
import Truncate from 'react-truncate';
import DefaultThumbnail from './DefaultThumbnail';

const CourseCard = ({ course }) => {
  return (
    <a href={`${course?.sourceLink}`} target='_blank' rel='noreferrer'>
      <div
        aria-label='video Item'
        className='inline-block overflow-hidden duration-300 transform bg-white rounded shadow-sm hover:-translate-y-2 border-b-4 border-white hover:border-darkerblue sm:m-3 w-full p-2'
      >
        {course?.thumbnailFile?.publicUrl ? (
          <img
            src={course?.thumbnailFile?.publicUrl}
            alt={course?.courseCategory}
            className='object-cover w-full h-full'
            style={{ height: '200px' }}
          />
        ) : (
          <div
            className='object-cover w-full h-full'
            style={{ height: '200px' }}
          >
            <DefaultThumbnail text={course.courseCategory} />
          </div>
        )}

        <div className='flex flex-col h-36'>
          <div className='flex flex-col flex-grow justify-between  rounded-b'>
            <div className='py-5 px-2'>
              <h6 className='font-semibold leading-5 capitalize'>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {course?.title}
                </Truncate>
                <br />
                <br />
              </h6>
            </div>
            <div className='flex flex-row w-full justify-between px-2 mb-5 font-semibold text-sm text-gray'>
              <p className='capitalize'>{course?.author}</p>
              <p c>
                <div className='flex flex-wrap justify-end '>
                  <span className='text-starsorange text-sm font-semibold '>
                    <StarRate fontSize='inherit' />
                    <span className=' ml-1 mr-2 mb-6'>
                      {parseFloat(course?.avgRating || 0).toFixed(1)}
                    </span>
                  </span>

                  <span className='text-linecolor'>0 views</span>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default CourseCard;
