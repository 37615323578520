import { Send } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import PostComment from './PostComment';
import PostSnippet from './PostSnippet';

import * as commentsActions from '../redux/actions/comments';
import ModalWrapper from './ModalWrapper';

export default function CommentPostModal({
  setShowCommentPost,
  showCommentPost,
  postSnippet,
  users,
}) {
  const dispatch = useDispatch();
  const contentComments = useSelector((state) => state.comments?.itemComments);
  const success = useSelector((state) => state.comments?.success);
  const error = useSelector((state) => state.comments?.error);
  const errorMessage = useSelector((state) => state.comments?.errorMessage);
  const user = useSelector((state) => state.user?.user);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      commentsActions.addComment({
        commentAbout: 'content',
        commentText: comment,
        idOfCommentedAbout: postSnippet?._id,
      }),
    );
  };

  useEffect(() => {
    if (showCommentPost && postSnippet?._id)
      dispatch(commentsActions.itemComments(postSnippet?._id));
  }, [showCommentPost]);

  useEffect(() => {
    if (success.message === 'Comment added successfully') {
      dispatch(commentsActions.itemComments(postSnippet?._id));
      setLoading(false);
      setComment('');
    }
    if (error) {
      if (`${errorMessage}`.includes('NetworkError')) {
        dispatch(commentsActions.itemComments(postSnippet?._id));
      } else {
        console.log(errorMessage);
      }
      setLoading(false);
      setComment('');
    }
  }, [success, error]);

  return (
    <>
      {showCommentPost ? (
        <>
          <form onSubmit={'/'}>
            <ModalWrapper
              title={'Comment'}
              closeModal={() => setShowCommentPost(false)}
              bodyContent={
                <>
                  <PostSnippet
                    key={postSnippet?._id}
                    content={postSnippet}
                    users={users}
                  />
                  {/*  */}
                  <div className='my-5 pt-0 w-full flex justify-start'>
                    <img
                      src={
                        user?.profilePicture?.publicUrl ||
                        `https://ui-avatars.com/api/?name=${`${user?.firstname} ${user?.lastname}`}&bold=true`
                      }
                      alt='profile'
                      className='rounded-full h-10 w-10 flex items-center justify-center mr-2'
                    />
                    <input
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      className='px-4 py-3 text-textparagraph appearance-none bg-lightgraybg rounded-full text-sm border border-linecolor outline-none focus:outline-none focus:ring focus:ring-offset-headingtext focus:ring-opacity-50 w-11/12'
                      placeholder='Add comment...'
                    />
                    <button
                      className='  bg-main text-white font-semibold text-xl px-5 py-2 rounded-full shadow hover:bg-navyblue outline-none focus:outline-none ml-1 ease-linear transition-all duration-150'
                      type='submit'
                      onClick={(e) => {
                        setLoading(true);
                        handleSubmit(e);
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <ClipLoader color='navyblue' />
                      ) : (
                        <Send fontSize='default' />
                      )}
                    </button>
                  </div>

                  <div className='mb-2 pt-0 w-full flex flex-col'>
                    <label className='font-semibold text-charcoal text-sm w-full mb-2'>
                      {contentComments.length < 1
                        ? 'Be the first person to comment'
                        : 'Recent Comments'}
                    </label>

                    {/* Displaying content comments  */}
                    <div className='overflow-y-auto h-auto max-h-72'>
                      {contentComments?.map((value) => (
                        <PostComment key={value._id} comment={value} />
                      ))}
                    </div>
                  </div>
                </>
              }
            />
          </form>
        </>
      ) : null}
    </>
  );
}
