import React from 'react';
import Rejected from '../assets/Rejected.svg';

const NoRequests = ({ invitation }) => {
  return (
    <div className='max-w-screen-sm sm:text-center sm:mx-auto mt-8'>
      <span
        aria-label='No upcoming events'
        className='inline-block  rounded-full sm:mx-auto'
      >
        <div className='flex items-center justify-center w-32 h-32 mb-8 rounded-full'>
          <img
            src={Rejected}
            alt='No requests'
            className='object-cover h-full w-full mb-2'
          />
        </div>
      </span>
      <h2 className='mb-4 font-sans text-2xl font-bold tracking-tight text-headingtext sm:text-xl sm:leading-none'>
        {`No ${invitation ? 'Invitations' : 'Join Requests'}  avalilable`}
      </h2>
    </div>
  );
};
export default NoRequests;
