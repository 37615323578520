import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import IconsRole from '../components/IconsRole';
import ActivityDetailModal from '../components/ActivityDetailModal';
import { Send } from '@material-ui/icons';

import * as claimsActions from '../redux/actions/claim';
import * as userActions from '../redux/actions/users';
import * as homeActions from '../redux/actions/home';

export default function PendingClaims() {
  const dispatch = useDispatch();
  const { data, loading, claimed_points } = useSelector((state) => state.claim);
  const { feeds } = useSelector((state) => state.home);
  const allUsers = useSelector((state) => state.user.data);
  const user = useSelector((state) => state.user?.user);
  const wholeUser = useSelector((state) => state.user);
  const points = useSelector((state) => state.user?.user_points);
  let x = 1;

  const [showActivityDetail, setShowActivityDetail] = useState(false);
  const [selectedPointID, setSelectedPointID] = useState();
  const [selectedActivityPoint, setSelectedActivityPoint] = useState();
  const [selectedPointPost, setSelectedPointPost] = useState();
  const [selectedClaim, setSelectedClaim] = useState();
  const [status, setStatus] = useState();
  const [claimId, setClaimId] = useState();
  const [feedback, setFeedback] = useState('');

  const handleFeedback = (e) => {
    e.preventDefault();
    const payload = {
      feedback: feedback,
      postId: selectedPointPost._id,
      claimId: selectedClaim._id,
      userId: user?._id,
    };
    dispatch(userActions.rejectedUserPoints(payload));
  };

  useEffect(() => {
    if (status) {
      const payload = {
        claimId: claimId,
        status: status,
        statusBy: user?._id,
      };
      dispatch(claimsActions.updateClaim(payload));
      setTimeout(function () {
        dispatch(claimsActions.getAllClaims());
      }, 500);
    }
  }, [status]);

  useEffect(() => {
    if (selectedPointID) {
      const myPoint = claimed_points.points.find((item) =>
        item._id.includes(selectedPointID),
      );
      setSelectedActivityPoint(
        points.activityPoints.find(
          (item) => item.activityPointId === myPoint?.activityPointId,
        ),
      );

      setSelectedPointPost(feeds.find((item) => item._id === myPoint?.postId));
    }
  }, [selectedPointID]);

  useEffect(() => {
    if (wholeUser?.user_rejected_success?.status) {
      setShowActivityDetail(false);
      setFeedback('');
      dispatch(claimsActions.getAllClaims());
    }
  }, [wholeUser]);

  useEffect(() => {
    dispatch(claimsActions.getAllClaims());
    dispatch(claimsActions.getAllClaimedPoints());
    dispatch(userActions.getUsers());
    dispatch(userActions.getUserPoints({ userId: user?._id }));
    dispatch(homeActions.getFeeds({ limit: 30 }));
  }, []);

  return (
    <>
      <Nav />
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <>
          <ActivityDetailModal
            setShowActivityDetail={setShowActivityDetail}
            showActivityDetail={showActivityDetail}
            selectedActivityPoint={selectedActivityPoint}
            selectedPointPost={selectedPointPost}
            setFeedback={setFeedback}
            feedback={feedback}
            handleFeedback={handleFeedback}
          />
          <div className='bg-lightGrayBg2 w-full h-screen'>
            <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
                <div className='flex flex-col mb-2 lg:justify-between lg:flex-row md:mb-4'>
                  <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                    <span className='inline-block mb-1 sm:mb-4'>
                      Pending Claims ({data?.length || 0})
                    </span>
                    <div className='h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100' />
                  </h2>
                </div>
                {/* Content Table */}
              </div>
            </div>

            <div className='mx-auto sm:w-screen md:w-full lg:max-w-screen-xl lg:px-8'>
              <div className=' pb-4 container mx-auto sm:max-w-full sm:px-2 md:max-w-full lg:max-w-full md:px-8 lg:px-8 '>
                <div className='flex flex-col mb-6 lg:justify-between md:mb-8 shadow  overflow-x-scroll xl:overflow-x-hidden'>
                  <table className='min-w-full bg-white '>
                    <thead>
                      <tr className='w-full h-16 border-linecolor  border-b py-8'>
                        <th className='text-gray font-bold  text-left text-base tracking-normal leading-4'>
                          No
                        </th>
                        <th className='text-gray font-bold pr-6 text-left text-base tracking-normal leading-4'>
                          Date
                        </th>
                        <th className='text-gray dark:text-gray-400 font-bold pr-6 text-left  text-base tracking-normal leading-4'>
                          Institution
                        </th>
                        <th className='text-gray dark:text-gray-400 font-bold pr-6 text-left  text-base tracking-normal leading-4'>
                          User
                        </th>
                        <th className='text-gray dark:text-gray-400 font-bold pr-6 text-left  text-base tracking-normal leading-4'>
                          Activities
                        </th>
                        <th className='text-gray dark:text-gray-400 font-bold pr-6 text-left text-base tracking-normal leading-4'>
                          Redeemable As
                        </th>
                        <th className='text-gray dark:text-gray-400 font-bold pr-6 text-left  text-base tracking-normal leading-4'>
                          Phone No
                        </th>
                        <th className='text-gray dark:text-gray-400 font-bold pr-8 text-left  text-base tracking-normal leading-4'>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((value, key) => (
                        <tr
                          key={value._id}
                          className='border-b-2 border-linecolor mx-4 text-sm pr-6 text-center  whitespace-normal text-black tracking-normal leading-4 cursor-pointer'
                        >
                          <td className='w-36 py-5 text-left whitespace-no-wrap  text-gray tracking-normal leading-4'>
                            {x++}
                          </td>
                          <td className=' w-36 text-left whitespace-no-wrap  text-gray tracking-normal leading-4'>
                            {(value?.createdAt).split('T')[0]}
                          </td>
                          <td className=' w-36 text-left whitespace-no-wrap  text-gray tracking-normal leading-4'>
                            {value?.institution}
                          </td>
                          <td className=' w-36 text-left whitespace-no-wrap  text-gray tracking-normal leading-4'>
                            {
                              allUsers.find((item) =>
                                item._id.includes(value?.userId),
                              )?.firstname
                            }{' '}
                            {
                              allUsers.find((item) =>
                                item._id.includes(value?.userId),
                              )?.lastname
                            }
                          </td>
                          <td className=' w-36 text-left whitespace-no-wrap  text-gray tracking-normal leading-4'>
                            {value?.activities?.map((item, key) => (
                              <IconsRole
                                key={item}
                                icon={<Send fontSize='small' />}
                                role='Activity'
                                Action={() => {
                                  setSelectedPointID(item);
                                  setShowActivityDetail(true);
                                  setSelectedClaim(value);
                                }}
                              />
                            ))}
                          </td>

                          <td className=' w-36 text-left whitespace-no-wrap  text-gray tracking-normal leading-4'>
                            {value?.redeemableAs} of {value?.amount}
                          </td>
                          <td className=' w-36 text-left whitespace-no-wrap  text-gray tracking-normal leading-4'>
                            {value?.phone}
                          </td>
                          <td>
                            <select
                              onChange={(e) => {
                                setStatus(e.target.value);
                                setClaimId(value?._id);
                              }}
                              value={status}
                              className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                            >
                              <option value=''>Status</option>
                              <option value='approved'>Approved</option>
                              <option value='rejected'>Rejected</option>
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='py-1'>
                    <div className='  bg-white  '>
                      <div className='w-full overflow-x-scroll xl:overflow-x-hidden'></div>
                      <div className='flex flex-col lg:flex-row p-4 justify-between items-start w-full'>
                        <div className='w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center'>
                          {/* Space between  */}
                          <div className='flex items-center'> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
