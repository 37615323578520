import { StarRate } from '@material-ui/icons';
import React from 'react';
import Truncate from 'react-truncate';
import DefaultThumbnail from './DefaultThumbnail';

const VideoCard = ({ video }) => {
  console.log(video?.thumbnailFile?.publicUrl);
  return (
    <a href={`/video/watch/${video?._id}`}>
      <div
        aria-label='video Item'
        className='inline-block overflow-hidden duration-300 transform bg-white rounded shadow-sm hover:-translate-y-2 border-b-4 border-white hover:border-darkerblue sm:m-3 w-full p-2'
      >
        {video?.thumbnailFile?.publicUrl !== undefined &&
        video?.thumbnailFile?.publicUrl !== null ? (
          <>
            <img
              src={video?.thumbnailFile?.publicUrl}
              alt={video?.title}
              className='object-cover w-full h-48 rounded'
            />
          </>
        ) : (
          <div className='h-48'>
            <DefaultThumbnail text={video?.title} className='font-display' />
          </div>
        )}

        <div className='flex flex-col h-36'>
          <div className='flex flex-col flex-grow justify-between  rounded-b'>
            <div className='py-5 px-2'>
              <h6 className='font-semibold font-display leading-5 capitalize'>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {video?.title}
                </Truncate>
              </h6>
            </div>
            <div className='flex flex-row w-full justify-between px-2 mb-5 font-semibold text-sm text-gray'>
              <p className='capitalize'>{video?.author}</p>
              <p c>
                <div className='flex flex-wrap justify-end '>
                  <span className='text-starsorange text-sm font-semibold '>
                    <StarRate fontSize='inherit' />
                    <span className=' ml-1 mr-2 mb-6'>
                      {parseFloat(video?.avgRating || 0).toFixed(1)}
                    </span>
                  </span>

                  <span className='text-linecolor'>
                    ({video?.views || 0}) views
                  </span>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default VideoCard;
