import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';

// Assets
import logo2 from '../assets/new_delvLogo.png';
import AvatarPlaceholder from '../assets/avatar-placeholder.svg';
import { ReactComponent as Search } from '../assets/search.svg';
import { ReactComponent as NotificationBellIcon } from '../assets/notification.svg';

// Components
import Input from './Input';
import Navbar from './Navbar';
import UserMenu from './UserDropdown';
import BrowseMenu from './BrowseDropdown';
import FeedbackModal from './FeedbackModal';
import ContentWrapper from './ContentWrapper';

// Views
import Feeds from '../views/Feeds';
import Courses from '../views/Courses';
import AllIndividualCourses from '../views/AllIndividualCourses';
import AllVideos from '../views/AllVideos';
import Dashboard from '../views/Dashboard';
import MentorsPage from '../views/MentorsPage';
import SessionShowAll from '../views/MySessions';
import AllArticlesPage from '../views/AllArticles';
import IndividualAbout from '../views/IndividualAbout';
// Institution
import Events from '../views/Events';
import Library from '../views/Library';
import Home from '../views/InstitutionHome';
import About from '../views/InstitutionAbout';
import YourMentors from '../views/YourMentors';
import Members from '../views/Members';
import Groups from '../views/InstitutionGroups';
import InstitutionFeeds from '../views/InstitutionFeeds';
import InstitutionAbout from '../views/InstitutionAbout';

// Actions
import * as searchActions from '../redux/actions/search';

export default function Nav({
  feedsActive,
  affiliationsActive,
  coursesActive,
  mentorsActive,
  membersActive,
  articlesActive,
  videosActive,
  IndividualCoursesActive,
  eventsActive,
  sessionsActive,
  aboutActive,
  groupsActive,
  libraryActive,
  booksActive,
  openLibActive,
  userType,
  //institutions,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const institutions = useSelector((state) => state.institution.data);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [feedsSelected, setFeedsSelected] = useState(feedsActive || false);

  const [coursesSelected, setCoursesSelected] = useState(
    coursesActive || false,
  );
  const [mentorsSelected, setMentorsSelected] = useState(
    mentorsActive || false,
  );
  const [membersSelected, setMembersSelected] = useState(
    membersActive || false,
  );
  const [articlesSelected, setArticlesSelected] = useState(
    articlesActive || false,
  );
  const [videosSelected, setVideosSelected] = useState(videosActive || false);
  const [IndividualCoursesSelected, setIndividualCoursesSelected] = useState(
    IndividualCoursesActive || false,
  );
  const [sessionsSelected, setSessionsSelected] = useState(
    sessionsActive || false,
  );
  const [eventsSelected, setEventsSelected] = useState(eventsActive || false);
  const [aboutSelected, setAboutSelected] = useState(aboutActive || false);
  const [groupsSelected, setGroupsSelected] = useState(groupsActive || false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [booksSelected, setBooksSelected] = useState(booksActive || false);
  const [openLibSelected, setOpenLibSelected] = useState(
    openLibActive || false,
  );

  const [librarySelected, setLibrarySelected] = useState(
    libraryActive || false,
  );

  const [affiliationsSelected, setAffiliationsSelected] = useState(
    affiliationsActive || false,
  );

  let [keywords, setKeywords] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(searchActions.search({ keywords, skip: 0, limit: 30 }));
    history.push('/search');
  };

  const { path } = useRouteMatch();

  const selectedStyle =
    '    border-b-4 border-darkerblue  outline-none text-charcoal';

  return (
    <>
      <div className='sticky top-0 z-50'>
        <div className='flex items-center hidden space-x-8 lg:flex'>
          <Navbar userType={userType} institutions={institutions} />
        </div>
        <div className='w-full lg:bg-white bg-main bg-darkerblue'>
          <div className='px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 '>
            <div className='relative flex items-center justify-between '>
              <div className='flex items-center'>
                <Link to='/'>
                  <div className='inline-flex items-center mr-8 lg:hidden'>
                    <img
                      src={logo2}
                      className='w-auto h-7 object-scale-down'
                      alt='logo'
                    />
                  </div>
                </Link>

                <div className='pl-8 font-bold text-white text-base flex items-center lg:hidden'>
                  <div>
                    <BrowseMenu />
                  </div>
                </div>
                <div className='flex items-center hidden space-x-8 lg:flex'>
                  {userType === 'institution' ? (
                    <>
                      <Link
                        to='/institution/about'
                        onClick={() => {
                          // setHomeSelected(false);
                          setGroupsSelected(false);
                          setLibrarySelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(false);
                          setMembersSelected(false);
                          setEventsSelected(false);
                          setAboutSelected(true);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out' +
                          (aboutSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Profile
                      </Link>
                      <Link
                        to='/institution/feeds'
                        onClick={() => {
                          // setHomeSelected(false);
                          setLibrarySelected(false);
                          setFeedsSelected(true);
                          setMentorsSelected(false);
                          setMembersSelected(false);
                          setEventsSelected(false);
                          setGroupsSelected(false);

                          // setAboutSelected(false);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out' +
                          (feedsSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Feeds
                      </Link>
                      <Link
                        to='/institution/mentors'
                        onClick={() => {
                          // setHomeSelected(false);
                          setLibrarySelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(true);
                          setMembersSelected(false);
                          setEventsSelected(false);
                          setGroupsSelected(false);
                          // setAboutSelected(false);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out' +
                          (mentorsSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Mentors
                      </Link>
                      <Link
                        to='/institution/members'
                        onClick={() => {
                          // setHomeSelected(false);
                          setLibrarySelected(false);
                          setFeedsSelected(false);
                          setMembersSelected(true);
                          setMentorsSelected(false);
                          setEventsSelected(false);
                          setGroupsSelected(false);
                          // setAboutSelected(false);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out' +
                          (membersSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Members
                      </Link>
                      <Link
                        to='/institution/library'
                        onClick={() => {
                          // setHomeSelected(false);
                          setLibrarySelected(true);
                          setFeedsSelected(false);
                          setMentorsSelected(false);
                          setMembersSelected(false);
                          setEventsSelected(false);
                          setGroupsSelected(false);
                          // setAboutSelected(false);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out' +
                          (librarySelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Library
                      </Link>
                      <Link
                        to='/institution/events'
                        onClick={() => {
                          // setHomeSelected(false);
                          setLibrarySelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(false);
                          setMembersSelected(false);
                          setEventsSelected(true);
                          setGroupsSelected(false);
                          // setAboutSelected(false);
                        }}
                        className={
                          `flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out` +
                          (eventsSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Sessions
                      </Link>
                      <Link
                        to='/institution/groups'
                        onClick={() => {
                          // setHomeSelected(false);
                          setLibrarySelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(false);
                          setEventsSelected(false);
                          setMembersSelected(false);
                          setGroupsSelected(true);
                          // setAboutSelected(false);
                        }}
                        className={
                          `flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out` +
                          (groupsSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Groups
                      </Link>
                      {videosActive && (
                        <Link
                          to='/institution/videos'
                          onClick={() => {
                            // setHomeSelected(false);
                            setLibrarySelected(false);
                            setFeedsSelected(false);
                            setMentorsSelected(false);
                            setMembersSelected(false);
                            setEventsSelected(false);
                            setGroupsSelected(false);
                            setVideosSelected(true);
                            // setAboutSelected(false);
                          }}
                          className={`px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out border-b-4 border-main  outline-none text-charcoal `}
                        >
                          Videos
                        </Link>
                      )}
                      {articlesActive && (
                        <a
                          href='/institution/videos'
                          onClick={() => {
                            // setHomeSelected(false);
                            setLibrarySelected(false);
                            setFeedsSelected(false);
                            setMentorsSelected(false);
                            setMembersSelected(false);
                            setEventsSelected(false);
                            setGroupsSelected(false);
                            setArticlesSelected(true);
                            // setAboutSelected(false);
                          }}
                          className={`flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out border-b-4 border-main  outline-none text-charcoal`}
                        >
                          Articles
                        </a>
                      )}
                      {booksActive && (
                        <Link
                          to='/institution/videos'
                          onClick={() => {
                            // setHomeSelected(false);
                            setLibrarySelected(false);
                            setFeedsSelected(false);
                            setMentorsSelected(false);
                            setMembersSelected(false);
                            setEventsSelected(false);
                            setGroupsSelected(false);
                            setBooksSelected(true);
                            // setAboutSelected(false);
                          }}
                          className={`${booksSelected}  flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out border-b-4 border-main  outline-none text-charcoal`}
                        >
                          Books
                        </Link>
                      )}
                      {openLibActive && (
                        <Link
                          to='/institution/videos'
                          onClick={() => {
                            // setHomeSelected(false);
                            setLibrarySelected(false);
                            setFeedsSelected(false);
                            setMentorsSelected(false);
                            setMembersSelected(false);
                            setEventsSelected(false);
                            setGroupsSelected(false);
                            setOpenLibSelected(true);
                            // setAboutSelected(false);
                          }}
                          className={`${openLibSelected} flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out border-b-4 border-main  outline-none text-charcoal`}
                        >
                          Open Libraries
                        </Link>
                      )}
                      {coursesActive && (
                        <Link
                          to='/institution/videos'
                          onClick={() => {
                            // setHomeSelected(false);
                            setLibrarySelected(false);
                            setFeedsSelected(false);
                            setMentorsSelected(false);
                            setMembersSelected(false);
                            setEventsSelected(false);
                            setGroupsSelected(false);
                            setCoursesSelected(true);
                            // setAboutSelected(false);
                          }}
                          className={`${coursesSelected} flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base  duration-150 ease-in-out border-b-4 border-main  outline-none text-charcoal`}
                        >
                          Courses
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      <Link
                        to='/profile'
                        onClick={() => {
                          // setHomeSelected(false);
                          setGroupsSelected(false);
                          setLibrarySelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(false);
                          setEventsSelected(false);
                          setAboutSelected(true);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base font-display  duration-150 ease-in-out' +
                          (aboutSelected ? selectedStyle : '')
                        }
                      >
                        Profile
                      </Link>
                      <Link
                        to='/feeds'
                        onClick={() => {
                          setGroupsSelected(false);
                          setArticlesSelected(false);
                          setAffiliationsSelected(false);
                          setFeedsSelected(true);
                          setFeedsSelected(true);
                          setMentorsSelected(false);
                          setSessionsSelected(false);
                          setIndividualCoursesSelected(false);
                          setVideosSelected(false);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base font-display ml-6 duration-150 ease-in-out' +
                          (feedsSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Feeds
                      </Link>

                      <a
                        href='/articles'
                        onClick={() => {
                          setGroupsSelected(false);
                          setArticlesSelected(true);
                          setAffiliationsSelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(false);
                          setSessionsSelected(false);
                          setIndividualCoursesSelected(false);
                          setVideosSelected(false);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base font-display ml-6 duration-150 ease-in-out' +
                          (articlesSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Articles
                      </a>
                      <a
                        href='/videos'
                        onClick={() => {
                          setGroupsSelected(false);
                          setArticlesSelected(false);
                          setAffiliationsSelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(false);
                          setSessionsSelected(false);
                          setIndividualCoursesSelected(false);
                          setVideosSelected(true);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base font-display ml-6 duration-150 ease-in-out' +
                          (videosSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Videos
                      </a>
                      <a
                        href='/IndividualCourses'
                        onClick={() => {
                          setGroupsSelected(false);
                          setArticlesSelected(false);
                          setAffiliationsSelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(false);
                          setSessionsSelected(false);
                          setIndividualCoursesSelected(true);
                          setVideosSelected(false);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base font-display ml-6 duration-150 ease-in-out' +
                          (IndividualCoursesSelected
                            ? selectedStyle
                            : ' text-gray5')
                        }
                      >
                        Courses
                      </a>
                      <Link
                        to='/session/all'
                        onClick={() => {
                          setGroupsSelected(false);
                          setArticlesSelected(false);
                          setAffiliationsSelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(false);
                          setVideosSelected(false);
                          setIndividualCoursesSelected(false);
                          setSessionsSelected(true);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base font-display ml-6 duration-150 ease-in-out' +
                          (sessionsSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Sessions
                      </Link>
                      <Link
                        to='/mentors'
                        onClick={() => {
                          setGroupsSelected(false);
                          setArticlesSelected(false);
                          setAffiliationsSelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(true);
                          setSessionsSelected(false);
                          setVideosSelected(false);
                          setIndividualCoursesSelected(false);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base font-display ml-6 duration-150 ease-in-out' +
                          (mentorsSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Mentors
                      </Link>
                      <Link
                        to='/groups'
                        onClick={() => {
                          setGroupsSelected(false);
                          setArticlesSelected(false);
                          setAffiliationsSelected(false);
                          setFeedsSelected(false);
                          setMentorsSelected(false);
                          setSessionsSelected(true);
                          setGroupsSelected(false);
                          setCoursesSelected(false);
                        }}
                        className={
                          ' flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base font-display ml-6 duration-150 ease-in-out' +
                          (groupsSelected ? selectedStyle : ' text-gray5')
                        }
                      >
                        Groups
                      </Link>
                      {affiliationsActive && (
                        <Link
                          to='/institution/videos'
                          onClick={() => {
                            setGroupsSelected(false);
                            setArticlesSelected(false);
                            setAffiliationsSelected(true);
                            setFeedsSelected(false);
                            setMentorsSelected(false);
                            setSessionsSelected(false);
                            setVideosSelected(false);
                          }}
                          className={`${affiliationsSelected} flex px-6 items-center py-4 text-base leading-5  hover:bg-lightGrayBg2 transition font-base font-display duration-150 ease-in-out border-b-4 border-main  outline-none text-charcoal`}
                        >
                          My Affiliations
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </div>
              <ul className='flex items-center hidden space-x-8 lg:flex'>
                <li>
                  <button
                    aria-label='Leave Feedback'
                    title='Feedback'
                    onClick={() => {
                      setShowFeedbackModal(true);
                    }}
                    className='font-medium font-display tracking-wide text-darkerblue transition-colors duration-200 hover:text-darkerblue'
                  >
                    Feedback
                  </button>
                </li>
              </ul>

              <div className='lg:hidden'>
                <div className='flex flex-row lg:hidden py-1'>
                  <span className='rounded-full h-9 w-9 flex items-center justify-center bg-lightBlue mx-4 text-white'>
                    <NotificationBellIcon />
                  </span>

                  <button
                    aria-label='Open Menu'
                    title='Open Menu'
                    className='p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50'
                    onClick={() => setIsMenuOpen(true)}
                  >
                    <svg className='w-5 text-white' viewBox='0 0 24 24'>
                      <path
                        fill='currentColor'
                        d='M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z'
                      />
                      <path
                        fill='currentColor'
                        d='M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z'
                      />
                      <path
                        fill='currentColor'
                        d='M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z'
                      />
                    </svg>
                  </button>
                </div>

                {isMenuOpen && (
                  <div className='absolute top-0 left-0 w-full z-50'>
                    <div className='p-5 bg-white border rounded shadow-sm'>
                      <div className='flex items-center justify-between mb-4'>
                        <div className='flex flex-row justify-start'>
                          <img
                            src={
                              user?.profilePicture?.publicUrl ||
                              AvatarPlaceholder
                            }
                            alt='profile'
                            className='rounded-full h-10 w-10 flex object-cover items-center justify-center mr-2 ml-4'
                          />
                          <div className='font-bold text-base flex items-center'>
                            <div className='text-headingtext '>
                              <UserMenu user={user?.firstname} />
                            </div>
                          </div>
                        </div>

                        <div>
                          <button
                            aria-label='Close Menu'
                            title='Close Menu'
                            className='p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <svg
                              className='w-5 text-gray-600'
                              viewBox='0 0 24 24'
                            >
                              <path
                                fill='currentColor'
                                d='M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z'
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <nav>
                        <ul className='space-y-4'>
                          <li className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400 mb-4'>
                            <div className='w-full flex flex-row'>
                              <form
                                method='get'
                                className='w-10/12'
                                onSubmit={handleSubmit}
                              >
                                <Input
                                  placeholder='Search for courses, mentors, jobs, etc ....'
                                  backgroundColor='bg-white'
                                  contentColor='text-headingtext'
                                  placeholderColor='text-headingtext'
                                  borderColor='border-lightBlue'
                                  width='w-full'
                                  padding='px-0'
                                  contentSize='text-xs'
                                  value={keywords}
                                  submitInput={setKeywords}
                                />
                              </form>
                              <div className='flex justify-center w-2/12 mb-3 h-11 ml-2'>
                                <span className='inline-flex items-center justify-center w-full px-6 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md md:w-auto bg-main hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none bg-darkerblue'>
                                  <div className='flex items-center'>
                                    <Search />
                                  </div>
                                </span>
                              </div>
                            </div>
                          </li>

                          {userType === 'institution' ? (
                            <>
                              <li>
                                <Link
                                  to={'/institution/feeds'}
                                  aria-label='Courses'
                                  title='Courses'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Feeds
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={'/institution/mentors'}
                                  aria-label='Mentors'
                                  title='Mentors'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Mentors
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={'/institution/library'}
                                  aria-label='Articles'
                                  title='Library'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Library{' '}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={'/institution/events'}
                                  aria-label='Events'
                                  title='Events'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Events
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={'/institution/groups'}
                                  aria-label='groups'
                                  title='Groups'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Groups
                                </Link>
                              </li>
                            </>
                          ) : (
                            <>
                              <li>
                                <Link
                                  to={'/feeds'}
                                  aria-label='Feeds'
                                  title='Feeds'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Feeds
                                </Link>
                              </li>
                              <li>
                                <a
                                  href={'/articles'}
                                  aria-label='Articles'
                                  title='Articles'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Articles{' '}
                                </a>
                              </li>
                              <li>
                                <a
                                  href={'/videos'}
                                  aria-label='Videos'
                                  title='Videos'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Videos{' '}
                                </a>
                              </li>
                              <li>
                                <a
                                  href={'/IndividualCourses'}
                                  aria-label='IndividualCourses'
                                  title='IndividualCourses'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Courses{' '}
                                </a>
                              </li>
                              <li>
                                <Link
                                  to={'/session/all'}
                                  aria-label='sessions'
                                  title='sessions'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Sessions{' '}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={'/mentors'}
                                  aria-label='Mentors'
                                  title='Mentors'
                                  className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                                >
                                  Mentors
                                </Link>
                              </li>{' '}
                            </>
                          )}

                          <li>
                            <div className='border-t-2 border-linecolor'></div>
                          </li>
                          <li>
                            <button
                              aria-label='Leave Feedback'
                              title='Feedback'
                              className='font-medium tracking-wide text-charcoal transition-colors duration-200 hover:text-deep-purple-accent-400'
                              onClick={() => {
                                setIsMenuOpen(false);
                                setShowFeedbackModal(true);
                              }}
                            >
                              Feedback
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='border-t-2 border-linecolor'></div>
          <Switch>
            <Route path={`${path}/profile`}>
              <ContentWrapper tab={<IndividualAbout />} />
            </Route>
            <Route path={`${path}/dashboard`}>
              <ContentWrapper tab={<Dashboard />} />
            </Route>
            <Route path={`${path}/feeds`}>
              <ContentWrapper tab={<Feeds />} />
            </Route>
            <Route path={`${path}/articles`}>
              <ContentWrapper tab={<AllArticlesPage />} />
            </Route>
            <Route path={`${path}/videos`}>
              <ContentWrapper tab={<AllVideos />} />
            </Route>
            <Route path={`${path}/IndividualCourses`}>
              <ContentWrapper tab={<AllIndividualCourses />} />
            </Route>
            <Route path={`${path}/session/all`}>
              <ContentWrapper tab={<SessionShowAll />} />
            </Route>
            <Route path={`${path}/courses`}>
              <ContentWrapper tab={<Courses />} />
            </Route>
            <Route path={`${path}/mentors`}>
              <ContentWrapper tab={<MentorsPage />} />
            </Route>
            <Route path={`${path}/institution/events`}>
              <ContentWrapper tab={<Events />} />
            </Route>
            <Route path={`${path}/institution/about`}>
              <ContentWrapper tab={<InstitutionAbout />} />
            </Route>
            <Route path={`${path}/institution/groups`}>
              <ContentWrapper tab={<Groups />} />
            </Route>
            <Route path={`${path}/home`}>
              <ContentWrapper tab={<Home />} />
            </Route>
            <Route path={`${path}/institution/feeds`}>
              <ContentWrapper tab={<InstitutionFeeds />} />
            </Route>
            <Route path={`${path}/institution/library`}>
              <ContentWrapper tab={<Library />} />
            </Route>
            <Route path={`${path}/institution/about`}>
              <ContentWrapper tab={<About />} />
            </Route>
            <Route path={`${path}/institution/mentors`}>
              <ContentWrapper tab={<YourMentors />} />
            </Route>
            <Route path={`${path}/institution/members`}>
              <ContentWrapper tab={<Members />} />
            </Route>
          </Switch>
        </div>
      </div>
      <FeedbackModal
        showFeedbackModal={showFeedbackModal}
        setShowFeedbackModal={setShowFeedbackModal}
      />
    </>
  );
}
