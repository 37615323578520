import * as homeTypes from '../types/home';

const initialState = {
  feeds: [],
  loading: false,
  error: false,
  errorMessage: null,
  loggedIn: false,
  success: {
    status: false,
    message: 'All set!',
  },
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case homeTypes.HOME_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case homeTypes.HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case homeTypes.HOME_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case homeTypes.HOME_FEEDS:
      return {
        ...state,
        feeds: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case homeTypes.HOME_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: false,
          message: 'All set!',
        },
      };
    default:
      return state;
  }
};

export default homeReducer;
