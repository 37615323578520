import * as contentTypes from '../types/content';

const initialState = {
  videos: [],
  articles: [],
  data: [],
  count: null,
  myContent: [],
  relatedContent: [],
  latestContent: [],
  popularContent: [],
  trendingContent: [],
  loading: false,
  error: false,
  errorMessage: null,
  success: {
    status: false,
    message: 'All set!',
  },
};

const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case contentTypes.CONTENT_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case contentTypes.CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case contentTypes.CONTENT_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case contentTypes.ALL_CONTENT:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.VIDEOS:
      return {
        ...state,
        data: action.payload.videos,
        videos: action.payload.videos,
        count: action.payload.count,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.COURSES:
      return {
        ...state,
        data: action.payload.courses,
        courses: action.payload.courses,
        count: action.payload.count,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.COURSEUNITS:
      return {
        ...state,
        data: action.payload.courses,
        courses: action.payload.courses,
        count: action.payload.count,
        loading: false,
        error: false,
        errorMessage: null,
      };

    case contentTypes.ARTICLES:
      return {
        ...state,
        data: action.payload.articles,
        articles: action.payload.articles,
        count: action.payload.count,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.MY_CONTENT:
      return {
        ...state,
        myContent: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.MY_VIDEOS:
      return {
        ...state,
        myContent: action.payload.videos,
        count: action.payload.count,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.MY_COURSES:
      return {
        ...state,
        myContent: action.payload.courses,
        count: action.payload.count,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.MY_ARTICLES:
      return {
        ...state,
        myContent: action.payload.articles,
        count: action.payload.count,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.LATEST_CONTENT:
      return {
        ...state,
        latestContent: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.POPULAR_CONTENT:
      return {
        ...state,
        popularContent: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.TRENDING_CONTENT:
      return {
        ...state,
        trendingContent: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.SPECIFIC_CONTENT:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.RELATED_CONTENT:
      return {
        ...state,
        relatedContent: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case contentTypes.CONTENT_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: false,
          message: 'All set!',
        },
      };
    default:
      return state;
  }
};

export default contentReducer;
