import React from 'react';
import EventCard from './EventCard';
import ModalWrapper from './ModalWrapper';
import NoContentPlaceholder from './NoContentPlaceholder';

const UpcomingSessionsModal = (props) => {
  const upcomingSessions = props?.data;
  return (
    <>
      {props.showUpcomingSessionsModal ? (
        <>
          <ModalWrapper
            title={`${props.mentor.firstname}'s upcoming Sessions.`}
            closeModal={() => props.setShowUpcomingSessionsModal(false)}
            bodyContent={
              <div className='relative'>
                <div className=' bg-white pb-10 mt-6 r p-4'>
                  <div className='grid gap-8 row-gap-5 md:grid-cols-1'>
                    {upcomingSessions.length > 0 ? (
                      <div>
                        {upcomingSessions?.reverse().map((value, key) => (
                          <EventCard event={value} key={key} past={false} />
                        ))}
                      </div>
                    ) : (
                      <NoContentPlaceholder
                        type='Upcoming'
                        content='Sessions'
                      />
                    )}
                  </div>
                </div>
              </div>
            }
          />
        </>
      ) : null}
    </>
  );
};

export default UpcomingSessionsModal;
