import React, { useState } from 'react';

import { Switch, Link, Route, useRouteMatch } from 'react-router-dom';

// views
import ContentWrapper from './ContentWrapper';
import Feeds from '../views/Feeds';

// Components

import BrowseMenu from './BrowseDropdown';

// Assets
import logo2 from '../assets/delv_bold.png';

import Button from './Button';

const NavHeaderLanding = ({ user, background }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { path } = useRouteMatch();

  return (
    <>
      <div className='sticky top-0 z-50'>
        <div className='flex items-center hidden space-x-8 lg:flex'>
          <div className={`w-full ${background || 'bg-darkerblue'} py-2`}>
            <div className='px-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8 '>
              <div className='relative flex items-center justify-between '>
                <div className='flex justify-start items-center'>
                  <Link to='/'>
                    <img
                      src={logo2}
                      alt='delv-logo'
                      className='cursor-pointer'
                      // onClick={() => history.push('/')}
                    />
                  </Link>

                  <div className='pl-8 font-extrabold text-white text-base flex items-center'>
                    <div>
                      <BrowseMenu />
                    </div>
                  </div>
                </div>
                <div className='flex flex-1 justify-end items-center'>
                  <div className='flex items-center'>
                    <Link to='/login'>
                      <div
                        aria-label='To login form'
                        className='text-white font-semibold px-6'
                      >
                        {'Login'}
                      </div>
                    </Link>
                  </div>
                  <div className=''>
                    <Link to='/signup'>
                      <div>
                        <Button
                          content='Get Started today'
                          backgroundColor='bg-white'
                          contentColor='text-darkerblue'
                          borderRadius='rounded-full'
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`w-full lg:bg-white ${background || 'bg-darkerblue'}`}>
          <div className='px-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8 '>
            <div className='relative flex items-center justify-between '>
              <div className='flex items-center'>
                <Link to='/'>
                  <div
                    aria-label='Company'
                    title='Company'
                    className='inline-flex items-center mr-8 lg:hidden'
                  >
                    <img
                      src={logo2}
                      className='w-auto h-7 object-scale-down'
                      alt='logo'
                    />
                  </div>
                </Link>

                <div className='pl-8 font-bold text-white text-base flex items-center lg:hidden'>
                  <div>
                    <BrowseMenu />
                  </div>
                </div>
                <div className='flex items-center hidden space-x-8 lg:flex'></div>
              </div>

              <div className='lg:hidden'>
                <div className='flex flex-row lg:hidden py-1'>
                  <button
                    aria-label='Open Menu'
                    title='Open Menu'
                    className='p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50'
                    onClick={() => setIsMenuOpen(true)}
                  >
                    <svg className='w-5 text-white' viewBox='0 0 24 24'>
                      <path
                        fill='currentColor'
                        d='M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z'
                      />
                      <path
                        fill='currentColor'
                        d='M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z'
                      />
                      <path
                        fill='currentColor'
                        d='M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z'
                      />
                    </svg>
                  </button>
                </div>

                {isMenuOpen && (
                  <div className='absolute top-0 left-0 w-full z-50'>
                    <div className='p-5 bg-white border rounded shadow-sm'>
                      <div className='flex items-center justify-between mb-4'>
                        <div className='flex flex-row justify-start'>
                          {/* <img
                            src={logo}
                            alt='profile'
                            className=' h-16 w-aauto object-cover flex items-center justify-center mr-2 ml-4'
                          />
                          <div className='font-bold text-4xl flex items-center'>
                            <div className='text-headingtext '>Guest</div>
                          </div> */}
                        </div>

                        <div>
                          <button
                            aria-label='Close Menu'
                            title='Close Menu'
                            className='p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <svg
                              className='w-5 text-gray-600'
                              viewBox='0 0 24 24'
                            >
                              <path
                                fill='currentColor'
                                d='M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z'
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <nav>
                        <ul className='space-y-4'>
                          <li className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400 mb-4'>
                            <div className='w-full flex flex-row'></div>
                          </li>

                          <li>
                            <Link to={'/login'}>
                              <div
                                aria-label='Dashboard'
                                title='Dashboard'
                                className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                              >
                                Log in
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to={'/signup'}>
                              <div
                                aria-label='Feeds'
                                title='Feeds'
                                className='font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-deep-purple-accent-400'
                              >
                                Sign up
                              </div>
                            </Link>
                          </li>

                          <li>
                            <span
                              aria-label='Sign in'
                              title='Sign in'
                              className='font-medium tracking-wide text-charcoal transition-colors duration-200 hover:text-deep-purple-accent-400'
                            >
                              Feedback
                            </span>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='md:border-t-2 border-linecolor'></div>
          <Switch>
            <Route path={`${path}/feeds`}>
              <ContentWrapper tab={<Feeds />} />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default NavHeaderLanding;
