const base = `${process.env.REACT_APP_BACKEND_URL}`;
const endpoints = {
  create_institution: `${base}/institution`,
  institution_profilepic: `${base}/institution/profilepic`,
  create_mentor: `${base}/mentor`,
  mentor_profilepic: `${base}/mentor/profilepic`,
  get_your_mentor: `${base}/mentor`,
  get_institutions: `${base}/institution`,
  get_specific_institution: `${base}/institution`,
  get_your_specific_mentor: `${base}/mentor`,
  invite_mentor: `${base}/institution/mentor/invite`,
  get_invited_mentors: `${base}/mentor`,
  send_survey: `${base}/institution/surveys/send`,
  institution_learners: `${base}/learner/institution`,
};
export default endpoints;
