import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useAnimation, motion } from 'framer-motion/dist/framer-motion';
import { useInView } from 'react-intersection-observer';
import Explore from '../components/Explore';
import Carousel from 'react-multi-carousel';
import { ArrowRightAlt } from '@material-ui/icons';
import '../bright.css';

// components
import Footer from '../components/NewFooter';
import UserPointsModal from '../components/UserPointsModal';
import { EmailModal } from '../components/ContactForms';
import NavHeaderLanding from '../components/NewNavbar';
import ChatGPTModal from '../components/ChatGPT';
import ChatGPTButton from '../components/ChatGPTButton';

// Actions
import * as searchActions from '../redux/actions/search';
import * as userActions from '../redux/actions/users';

const categoryVariants = {
  visible: {
    opacity: 1,
    scale: 1,
    marginBottom: 0,
    transition: { duration: 1 },
  },
  hidden: { opacity: 0.3, scale: 1, marginBottom: 30 },
};

export default function Upskill() {
  const [showChatGPTModal, setShowChatGPTModal] = useState(false);
  window.scrollTo(0, 0);

  return (
    <>
      <ChatGPTModal
        setShowChatGPTModal={setShowChatGPTModal}
        showChatGPTModal={showChatGPTModal}
      />
      <div>
        <div className='flex flex-col '>
          <NavHeaderLanding background={'bg-newMain'} />

          <div className='w-full'>
            <Header />
          </div>

          <div className='bg-lightGrayBg'>
            <CareersUpskilling setShowChatGPTModal={setShowChatGPTModal} />
          </div>

          <div className='bg-lightGrayBg '>
            <CatergoriesUpskilling setShowChatGPTModal={setShowChatGPTModal} />
          </div>

          <div className='w-full'>
            <MiddleBanner />
          </div>

          <div className='bg-lightGrayBg'>
            <Explore setShowChatGPTModal={setShowChatGPTModal} />
          </div>

          <div className='footer'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

function CategoryCard({ title, link }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial='hidden'
      variants={categoryVariants}
      className='my-6 md:my-0'
    >
      <Link to={`/${link}`}>
        <div className='px-4 lg:px-6 py-4 categoryLinkTag  text-white'>
          <div className='flex flex-row justify-center'>
            <p className='text-white'>{title}</p>
            <span className='ml-1'>
              <ArrowRightAlt />
            </span>
          </div>
        </div>
      </Link>
    </motion.div>
  );
}

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [keywords, setKeywords] = useState('');

  const handleSubmit = () => {
    dispatch(searchActions.search({ keywords, skip: 0, limit: 20 }));
    history.push('/search');
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <div className='topBannerWrapper'>
      <div className='topBanner'>
        <div className='topBannerContent w-full px-2 md:px-1 md:w-5/12 lg:w-1/3 flex  mx-auto md:mx-0 md:ml-36 pt-16'>
          <div className='w-full pt-80 md:pt-64'>
            <h4 className='font-bold center-left text-4xl md:text-7xl  upskill Cabin'>
              UpSkill.
            </h4>
            <h4 className='font-bold center-left px-16 md:px-0 text-4xl md:text-7xl  re-invent Cabin'>
              Re-invent yourself
            </h4>
            <motion.h4
              ref={ref}
              animate={controls}
              initial='hidden'
              variants={categoryVariants}
              className='mt-4 md:mt-3 lg:mt-4'
            >
              <h4 className='px-4 md:px-0 md:pr-8 lg:pr-0 center-left leading learning text-xl md:text-3xl font-small open-sans'>
                Learning has never been this easy. It’s just a click away
              </h4>
            </motion.h4>
          </div>
        </div>
      </div>
      <div className='block-hidden w-full formWrappersm font-thin'>
        <div className='w-full py-8 px-2 bg-white'>
          <div className='mx-auto px-2 md:px-10'>
            <form onSubmit={handleSubmit}>
              <input
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                className='searchInput w-full px-3 rounded-input open-sans font-small'
                placeholder='What do you want to learn today?'
              />
            </form>
          </div>
        </div>
      </div>

      <div className='formWrapper w-full font-thin'>
        <div className='hidden-block w-full'>
          <div className='w-10/12 mx-auto formSubWrapper bg-white py-10'>
            <div className='topBannerForm mx-auto px-16'>
              <form onSubmit={handleSubmit}>
                <input
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  className='searchInput w-full px-3 rounded-input focus:rounded-input open-sans'
                  placeholder='What do you want to learn today?'
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CareersUpskilling = ({ setShowChatGPTModal }) => {
  return (
    <div className='px-9 py-3 md:py-4 mx-auto md:max-w-full mt-2 md:mt-32'>
      <div className='w-full mx-auto'>
        <div className='my-6 md:my-10'>
          <div className='mb-4 text-left md:mb-8'>
            <h1 className='font-semibold md:font-bold mb-4 text-3xl md:text-4xl text-center categoriesHeader cabin'>
              Top Careers
            </h1>
            <h3 className='text-center leading mb-2 font-thin open-sans cd'>
              Upskill for the top in demand careers today
            </h3>
          </div>
          <div className='my-6 w-full'>
            <div className='md:grid mt-5 md:mt-6 md:mb-10 md:gap-x-6 lg:gap-x-9 md:gap-y-4 md:grid-cols-3 lg:grid-cols-4'>
              <CategoryCard
                title={'DEVOPS ENGINEER'}
                link={'course/view/Devops Engineer'}
              />
              <CategoryCard
                title={`CLOUD ARCHITECT`}
                link={'course/view/Cloud Architect'}
              />
              <CategoryCard
                title={'DATA SCIENTIST'}
                link={'course/view/Data Scientist'}
              />
              <CategoryCard
                title={'FINANCIAL ANALYST'}
                link={'course/view/Financial Analyst'}
              />
              <CategoryCard
                title={`SCRUM MASTER`}
                link={'course/view/Scrum Master'}
              />

              <CategoryCard
                title={`CYBER SECURITY`}
                link={'course/view/Cyber Security'}
              />
              <CategoryCard
                title={`PRODUCT MANAGER`}
                link={'course/view/Product Manager'}
              />
              <CategoryCard
                title={`UI/UX DESIGNER`}
                link={'course/view/UX Designer'}
              />
            </div>
            <div className='px-5 py-4 mx-6 mt-5 md:mt-4 mx-auto categoryLinkTag w-11/12 md:w-60'>
              <div className='flex flex-row justify-center'>
                <p className='text-white'>
                  <Link className='' to='/IndividualCourses'>
                    DISCOVER MORE
                  </Link>
                </p>
              </div>
            </div>
            <ChatGPTButton
              setShowChatGPTModal={setShowChatGPTModal}
              marginLeft='46%'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const CatergoriesUpskilling = ({ setShowChatGPTModal }) => {
  return (
    <div className='px-9 py-5 mx-auto w-full mt-4 md:mb-32'>
      <div className='w-full my-5 md:my-10 mx-auto'>
        <div className=''>
          <div className='mb-4 text-left md:mb-20'>
            <h1 className='font-semibold md:font-bold mb-4 text-3xl md:text-4xl text-center categoriesHeader cabin'>
              Top Skills
            </h1>
            <h3 className='text-center leading mb-4 font-thin open-sans cd'>
              Explore new skills, deepen existing passions, and get lost in
              creativity.
            </h3>
          </div>
          <div className='my-6 w-full'>
            <div className='md:grid md:mt-6 md:mb-10 md:gap-x-9 md:gap-y-4 md:grid-cols-3 lg:grid-cols-4'>
              <CategoryCard title={'WRITING'} link={'course/view/Writing'} />
              <CategoryCard title={'BUSINESS'} link={'course/view/Business'} />
              <CategoryCard
                title={'PHOTO & FILM'}
                link={'course/view/Photo Film'}
              />
              <CategoryCard
                title={`IT & SOFTWARE`}
                link={'course/view/IT & Software'}
              />
              <CategoryCard
                title={`PUBLIC SPEAKING`}
                link={'course/view/Public Speaking'}
              />
              <CategoryCard title={`DESIGN`} link={'course/view/Design'} />
              <CategoryCard
                title={`ANIMATION`}
                link={'course/view/Animation'}
              />
              <CategoryCard
                title={`ILLUSTRATION`}
                link={'course/view/Illustration'}
              />
            </div>
            <div className='px-5 py-4 mx-6 mt-5 md:mt-4 mx-auto categoryLinkTag w-11/12 md:w-60'>
              <div className='flex flex-row justify-center'>
                <p className='text-white'>
                  <Link className='' to='/IndividualCourses'>
                    DISCOVER MORE
                  </Link>
                </p>
              </div>
            </div>
            <ChatGPTButton
              setShowChatGPTModal={setShowChatGPTModal}
              marginLeft='46%'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// const CategoryCard = ({ title, link }) => {
//   return (
//     <motion.div
//             animate={{ rotate: 360 }}
//             transition={{ duration: 20 }}
//           >
//     <Link to={link}>

//       <div className='px-5 py-4 duration-300 transform categoryLinkTag bg-navyblue text-white rounded-lg text-headingtext hover:-translate-y-2'>

//         <div className='flex flex-row justify-center'>

//           <p className='text-white'>{title}</p>
//           <span className='ml-3'>
//             <ArrowRightAlt />
//           </span>
//         </div>
//       </div>
//     </Link>
//     </motion.div>
//   );
// };

const MiddleBanner = () => {
  const controls = useAnimation();
  const history = useHistory();
  const dispatch = useDispatch();
  const points = useSelector((state) => state.user?.user_points);
  const { user } = useSelector((state) => state?.user);
  const [showUserPointsModal, setShowUserPointsModal] = useState(false);

  const [ref, inView] = useInView();
  const [showEmailModal, setShowEmailModal] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useEffect(() => {
    if (user) {
      dispatch(userActions.getUserPoints({ userId: user?._id }));
    }
  }, [user]);

  function handleChange(user) {
    if (user) {
      setShowUserPointsModal(true);
    } else {
      history.push('/login');
    }
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <UserPointsModal
        setShowUserPointsModal={setShowUserPointsModal}
        showUserPointsModal={showUserPointsModal}
        points={points}
      />
      <div className='middleBannerWrapper'>
        <div className='middleBanner w-full h-full'>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            transitionDuration={500}
            removeArrowOnDeviceType={[
              'mobile',
              'tablet',
              'desktop',
              'superLargeDesktop',
            ]}
            renderButtonGroupOutside={true}
          >
            <div className='w-full middleBannerContent pt-80 md:pt-96 flex flex-col md:justify-start md:ml-24'>
              <h4 className='center-left text-5xl font-bold md:text-7xl join Cabin mt-28 md:mt-24'>
                <h4>Earn Points </h4>
              </h4>
              <p className='mb-5 open-sans mt-2 text-xl md:text-3xl center-left md:mb-6 md:mt-4 trainer'>
                <h5 style={{ paddingLeft: '6%' }}>with Delv</h5>
              </p>
              <div
                style={{ paddingLeft: '1%' }}
                className='w-full flex justify-center align-center md:justify-start md:align-start'
              >
                <p
                  className='categoryLinkTag px-16 tracking-wide py-4 text-md open-sans'
                  onClick={() => {
                    handleChange(user);
                  }}
                >
                  START NOW
                  <span className='mx-2'>
                    <ArrowRightAlt />
                  </span>
                </p>
              </div>
            </div>
            <div className='w-full middleBannerContent pt-80 md:pt-96 flex flex-col justify-center md:justify-start md:ml-24'>
              <h4
                ref={ref}
                animate={controls}
                initial='hidden'
                variants={categoryVariants}
                className='center-left text-5xl font-bold md:text-7xl join Cabin mt-28 md:mt-24'
              >
                <h4>Join Delv</h4>
              </h4>
              <p
                ref={ref}
                animate={controls}
                initial='hidden'
                variants={categoryVariants}
                className='mb-5 open-sans mt-2 text-xl md:text-3xl center-left md:mb-6 md:mt-4 trainer'
              >
                <p>Become a trainer today</p>
              </p>
              <div className='w-full flex justify-center align-center md:justify-start md:align-start'>
                <p
                  className='categoryLinkTag px-16 tracking-wide py-4 text-md open-sans'
                  onClick={() => setShowEmailModal(true)}
                >
                  GET STARTED
                  <span className='mx-2'>
                    <ArrowRightAlt />
                  </span>
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <EmailModal
        showEmailModal={showEmailModal}
        setShowEmailModal={setShowEmailModal}
      />
    </>
  );
};
