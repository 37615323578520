import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import { useParams, useHistory } from 'react-router-dom';
import * as invitationActions from '../redux/actions/invitation';
import Button from '@material-ui/core/Button';

export default function Invitation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const invitation = useSelector((state) => state?.invitation);
  const { invitationID } = useParams();

  useEffect(() => {
    dispatch(invitationActions.checkInvitation({ invitationID }));
  }, [invitationID]);

  if (invitation.count > 0) {
    const myCategory = invitation.data?.invitation[0];

    if (myCategory.category === 'general') {
      if (invitation?.data?.userExist === 1) {
        history.push('/login');
      } else {
        history.push(`/signup`);
      }
    }

    if (myCategory.category === 'group') {
      if (invitation?.data?.userExist === 1) {
        history.push(`/invitation/login/${myCategory.categoryID}`);
      } else {
        history.push(`/signup`);
      }
    }
  }

  return (
    <>
      <div style={{ width: '100%', height: '70vh', textAlign: 'center' }}>
        <div className='bg-white md:m-2 md:p-2 text-sm'>
          <Loading />

          <i style={{ fontSize: '22px' }}>Please wait ... Redirecting</i>

          <br />
          <br />
          <Button
            onClick={() => {
              history.push('/');
            }}
            style={{
              backgroundColor: '#4b2aad',
              color: '#fff',
              fontSize: '16px',
            }}
          >
            Home
          </Button>
        </div>
      </div>
    </>
  );
}
