import React from 'react';

const MyMessage = ({ message }) => {
  /* if (message.attachments && message.attachments.length > 0) {
     return (
       <img
         src={message.attachments[0].file}
         alt='message-attachment'
         className='message-image'
         style={{ float: 'right' }}
       />
     );
   }*/

  return (
    <div
      className='message'
      style={{
        float: 'right',
        marginRight: '18px',
        color: '#000000',
        backgroundColor: '#F2F5F9',
      }}
    >
      {message}
    </div>
  );
};

export default MyMessage;
