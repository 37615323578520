import React, { useState } from 'react';
import ModalWrapper from '../components/ModalWrapper';
import ButtonLoader from './ButtonLoader';

const ChatGPTModal = (props) => {
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const DEFAULT_PARAMS = {
    model: 'text-davinci-003',
    prompt: search,
    temperature: 0,
    max_tokens: 100,
  };

  async function callApi(e) {
    e.preventDefault();
    try {
      const params_ = { ...DEFAULT_PARAMS };
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer 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',
        },
        body: JSON.stringify(params_),
      };
      setLoading(true);
      const response = await fetch(
        'https://api.openai.com/v1/completions',
        requestOptions,
      );
      const data = await response.json();
      setLoading(false);
      setSearch('');
      console.log(data);
      return setQueries((current) => [
        ...current,
        { keyword: search, data: data },
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      {props.showChatGPTModal ? (
        <ModalWrapper
          title={'Ask AI'}
          closeModal={() => {
            props.setShowChatGPTModal(false);
          }}
          bodyContent={
            <div className='relative'>
              <div
                style={{ textAlign: 'left', paddingTop: 10, paddingBottom: 10 }}
              >
                {queries.length > 0 ? (
                  queries.map((value, key) => (
                    <>
                      <p key={key}>
                        <b>{value.keyword}?</b>
                      </p>
                      <p key={key} style={{ marginBottom: 30 }}>
                        {value.data.choices[0].text}
                      </p>
                    </>
                  ))
                ) : (
                  <p style={{ textAlign: 'center' }}>Chat with AI</p>
                )}
              </div>

              <form>
                <div className='grid grid-cols-12'>
                  <div className='col-span-10'>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className='searchInput w-full px-3 rounded-input Cabin'
                      placeholder='What do you want to learn today?'
                    />
                  </div>
                  <div className='col-span-2'>
                    <button
                      className='  bg-darkerblue text-white font-semibold text-xl px-5 py-3 rounded-full shadow hover:bg-navyblue outline-none focus:outline-none ml-1 ease-linear transition-all duration-150'
                      type='submit'
                      onClick={(e) => callApi(e)}
                    >
                      {loading ? <ButtonLoader /> : <> Go </>}
                    </button>
                  </div>
                </div>
                <br />
              </form>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default ChatGPTModal;
