import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import Loading from '../components/Loading';
import NavHeader from '../components/NavBarHeader';
import DisplayStarRating from '../components/StarRating';

import * as institutionActions from '../redux/actions/institution';
import * as commentActions from '../redux/actions/comments';

import { KeyboardBackspace } from '@material-ui/icons';
import Truncate from 'react-truncate';
import { ReviewModal } from '../components/ContactForms';
import CustomAlert from '../components/CustomAlert';

const Filters = ({
  mentorsList,
  filteredInstitutions,
  setFilteredInstitutions,
}) => {
  const uganda = mentorsList?.filter((value) => value.country === 'Uganda');
  const kenya = mentorsList?.filter((value) => value.country === 'Kenya');
  const rwanda = mentorsList?.filter((value) => value.country === 'Rwanda');
  const tanzania = mentorsList?.filter((value) => value.country === 'Tanzania');
  const burundi = mentorsList?.filter((value) => value.country === 'Burundi');
  const others = mentorsList?.filter(
    (value) =>
      value.country !== 'Uganda' &&
      value.country !== 'Burundi' &&
      value.country !== 'Kenya' &&
      value.country !== 'Rwanda' &&
      value.country !== 'Tanzania',
  );

  const countryCheckbox = () => {
    setFilteredInstitutions([]);
    const allCheckbox = document.getElementById('all').checked;
    const ugandaCheckbox = document.getElementById('uganda').checked;
    const kenyaCheckbox = document.getElementById('kenya').checked;
    const rwandaCheckbox = document.getElementById('rwanda').checked;
    const tanzaniaCheckbox = document.getElementById('tanzania').checked;
    const burundiCheckbox = document.getElementById('burundi').checked;
    const othersCheckbox = document.getElementById('others').checked;

    if (allCheckbox) setFilteredInstitutions(mentorsList);
    if (ugandaCheckbox) setFilteredInstitutions([].concat(uganda));
    if (kenyaCheckbox) setFilteredInstitutions([].concat(kenya));
    if (tanzaniaCheckbox) setFilteredInstitutions([].concat(tanzania));
    if (rwandaCheckbox) setFilteredInstitutions([].concat(rwanda));
    if (burundiCheckbox) setFilteredInstitutions([].concat(burundi));
    if (othersCheckbox) setFilteredInstitutions([].concat(others));
  };

  return (
    <>
      <div className='filter-group mt-2'>
        {/* By Country*/}
        <h1 className='text-base text-charcoal mt-2 lg:mt-9'>Country</h1>

        <div className='flex items-center mt-5'>
          <input
            id='all'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>All</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='uganda'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Uganda</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='kenya'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Kenya</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='rwanda'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Rwanda</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='tanzania'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Tanzania</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='burundi'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Burundi</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='others'
            type='radio'
            name='country'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={countryCheckbox}
          />
          <p className='ml-4 mr-10 text-sm text-charcoal'>Others</p>
          <div className='flex flex-1 justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
      </div>

      {/* Relevance */}
    </>
  );
};

const MobileFilters = ({ showFilters, setShowFilters }) => {
  return (
    <div
      className={
        showFilters
          ? 'w-full h-screen shadow-md absolute z-40  transform  translate-x-0 lg:hidden'
          : '   w-full h-full absolute z-40  transform -translate-x-full'
      }
    >
      <div
        className='bg-black opacity-50 w-full h-screen absolute  '
        onClick={() => setShowFilters(!showFilters)}
      ></div>
      <div className='w-64 md:w-72 absolute z-40 bg-white shadow h-full flex-col justify-between lg:hidden pb-4 transition duration-150 ease-in-out'>
        <div className='flex flex-col justify-between h-full'>
          <div>
            <div className='flex items-center justify-between px-8'>
              <div className='h-16 w-full flex items-center'>
                <span className='xl:text-base md:text-2xl text-base ml-2 text-main'>
                  Institutions Filters
                </span>
              </div>
              <div
                id='closeSideBar'
                className='flex items-center justify-center h-10 w-10'
                onClick={() => setShowFilters(!showFilters)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='icon icon-tabler icon-tabler-x'
                  width={20}
                  height={20}
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' />
                  <line x1={18} y1={6} x2={6} y2={18} />
                  <line x1={6} y1={6} x2={18} y2={18} />
                </svg>
              </div>
            </div>
            <div className='px-8 overflow-y-auto overscroll-contain h-screen bottom-5'>
              <div className='block  sm:ml-8 mb-10 text-headingtext  sm:text-sm'>
                <Filters />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InstitutionsCard = ({
  institution,
  to,
  user,
  setSeletctedInstiution,
  setShowReviewModal,
}) => {
  const history = useHistory();
  return (
    <>
      <div className=' py-2 mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-0 lg:px-0 lg:py-4 min-h-80 mb-4'>
        <div className=' max-w-full gap-8 row-gap-5 md:row-gap-8 sm:mx-auto'>
          <div className='p-4   bg-white rounded shadow-sm hover:shadow md:text-left sm:text-md'>
            <div className='flex flex-col lg:flex-row w-full items-start lg:items-center'>
              <div className=' w-full lg:w-9/12 flex flex-col lg:flex-row justfify-around lg:justify-start'>
                <div className='flex justify-around'>
                  <div className=' h-16 w-16 sm:h-16 sm:w-16 md:h-20 md:w-20  lg:h-28 lg:w-28'>
                    <img
                      src={
                        institution?.profilePicture?.publicUrl ||
                        `https://ui-avatars.com/api/?name=${institution?.institutionName}&bold=true`
                      }
                      alt={institution?.institutionName}
                      className='rounded-full object-cover h-full w-full shadow-md'
                    />
                  </div>
                </div>
                <div className='px-4 py-2 text-center lg:text-justify text-base sm:text-sm text-charcoal lg:w-6/12'>
                  <h5 className='mb-3 text-xl font-bold leading-none capitalize'>
                    {institution?.institutionName}
                  </h5>
                  <p
                    className='mb-5 text-headingtext '
                    style={{ fontSize: '12px' }}
                  >
                    <Truncate
                      lines={3}
                      ellipsis={
                        <span
                          className='hover:text-darkerblue cursor-pointer '
                          onClick={() => {
                            history.push(to);
                          }}
                        >
                          ...(visit home)
                        </span>
                      }
                    >
                      {institution?.bio}
                    </Truncate>
                  </p>
                  <p className='mb-3 text-charcoal font-semibold text-base'>
                    Specialities
                  </p>
                  <div className='mb-6 '>
                    <ul
                      className='grid grid-flow-row grid-cols-2   gap-x-6 lg:gap-x-2  gap-y-px text-headingtext  text-center lg:text-justify'
                      style={{ fontSize: '12px' }}
                    >
                      {institution?.specialities?.map((value) => (
                        <li className='lg:w-11/12' key={value}>
                          <span className='lg:mr-1'>*</span>
                          {value}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='mx-auto lg:mx-0 items-center mb-2 w-full'>
                    <Link to={to}>
                      <button className='inline-flex items-center sm:max-w-full justify-center h-12 px-4 sm:mr-0 font-medium border-2 border-darkerblue tracking-wide text-darkerblue hover:text-white transition duration-200 rounded-lg shadow-md bg-white hover:bg-darkerblue focus:shadow-outline focus:outline-none'>
                        Go to Home
                      </button>
                    </Link>
                  </div>
                </div>
                <div className='flex flex-row px-4 lg:px-0 justify-between lg:flex-nowrap lg:justify-start  sm:text-sm w-full lg:w-4/12 lg:border-r-2  border-gray3'>
                  <div>
                    <DisplayStarRating
                      selectStars={institution?.avgRating || 0}
                    />
                  </div>
                  <span
                    className='mx-2 text-linecolor'
                    style={{ fontSize: '12px' }}
                  >
                    ({institution?.rating?.length || 0} Reviews)
                  </span>
                </div>
              </div>
              <div className=' lg:ml-2 sm:px-2 py-4 lg:py-2 w-full lg:w-3/12 lg:h-full'>
                <Link to={'/contact'}>
                  <button className='inline-flex items-center justify-center w-full h-10 px-8 py-6 mx-2 lg:mx-px  border-2 border-darkerblue tracking-wide font-bold  text-darkerblue text-sm  mb-2 lg:mb-4 transition duration-200 rounded-lg shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'>
                    Follow
                  </button>
                </Link>
                <div className='flex flex-col  lg:justify-between'>
                  <a
                    href={`mailto: ${institution?.email}`}
                    aria-label='Send Email'
                    title='Send Email'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <button className='inline-flex items-center justify-center w-full h-10 px-8 py-6 mx-2 lg:mx-px border-2 border-darkerblue tracking-wide font-bold text-darkerblue text-sm mb-2 lg:mb-4 transition duration-200 rounded-lg shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'>
                      Contact Us
                    </button>
                  </a>

                  <button
                    onClick={() => {
                      setSeletctedInstiution(institution);
                      setShowReviewModal(true);
                    }}
                    className='inline-flex items-center justify-center w-full h-10 px-8 py-6 mx-2 lg:mx-px  border-2 border-darkerblue tracking-wide font-bold  text-darkerblue text-sm mb-2 lg:mb-4 transition duration-200 rounded-lg shadow-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                  >
                    Leave a Review
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PageBody = ({ user }) => {
  const dispatch = useDispatch();
  const { loading, count } = useSelector((state) => state?.institution);
  const institutions = useSelector((state) => state?.institution?.data);
  const { success, error, errorMessage } = useSelector(
    (state) => state.comments,
  );
  const [show, setShow] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [selectedInstitution, setSeletctedInstiution] = useState('');
  const [filteredInstitutions, setFilteredInstitutions] = useState([]);
  const [skip, setSkip] = useState(0);
  const limit = 30;

  useEffect(() => {
    dispatch(institutionActions.getInstitutions({ limit }));
    dispatch(commentActions.getAllComments());
  }, []);

  useEffect(() => {
    setFilteredInstitutions([...institutions]);
  }, [institutions]);

  useEffect(() => {
    if (success.message === 'Comment added successfully') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Comment added successfully',
      });
    } else if (error && errorMessage === 'Failed to submit comment') {
      CustomAlert({
        alertType: 'error',
        alertMessage: 'Failed to submit comment',
      });
    }
  }, [success, error]);

  return (
    <>
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed '>
          <Loading />
        </div>
      ) : (
        <>
          <div className='bg-lightgraybg w-full h-full '>
            <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-2 lg:px-8'>
                <div className='flex flex-row justify-around'>
                  <div className='hidden lg:w-2/12 border-r-2  border-gray3 pr-6  lg:flex'>
                    <div className='block mt-5 mb-10 text-headingtext  sm:text-sm fixed'>
                      <div
                        className='flex justify-start w-full mb-6 text-black  cursor-pointer hover:text-headingtext'
                        onClick={() => window.history.back()}
                      >
                        <KeyboardBackspace fontSize='medium' />{' '}
                        <span className='ml-2 flex flex-col justify-center font-semibold'>
                          Back
                        </span>
                      </div>
                      <span className='text-base text-charcoal'>Filters</span>
                      <Filters
                        mentorsList={institutions}
                        filteredInstitutions={filteredInstitutions}
                        setFilteredInstitutions={setFilteredInstitutions}
                      />
                    </div>
                  </div>
                  <MobileFilters
                    showFilters={show}
                    setShowFilters={setShow}
                    mentorsList={institutions}
                    filteredInstitutions={filteredInstitutions}
                    setFilteredInstitutions={setFilteredInstitutions}
                  />
                  <div className='flex flex-col pb-5 md:px-2 lg:pl-8 mt-5 mb-10 text-headingtext  w-full md:w-10/12'>
                    <div className='flex flex-row justify-between'>
                      <span className='text-lg lg:text-xl text-gray-700'>
                        Showing {filteredInstitutions.length > 0 ? skip + 1 : 0}{' '}
                        -{' '}
                        {count > limit
                          ? skip + limit
                          : filteredInstitutions.length}{' '}
                        of {count || 0} registered Institutions
                      </span>
                      <button
                        onClick={() => {
                          setShow(!show);
                        }}
                        className='lg:hidden
                inline-flex items-center justify-center h-10 px-8 py- mx-2 border border-darkerblue tracking-wide font-bold text-white text-sm mb-2 transition duration-200 rounded-sm shadow-md bg-darkerblue hover:bg-bannerBlue focus:shadow-outline focus:outline-none'
                      >
                        Filters
                      </button>
                    </div>
                    <div className='w-full '>
                      {filteredInstitutions?.map((value) => (
                        <InstitutionsCard
                          user={user}
                          key={value?._id}
                          institution={value}
                          to={`/institution/more/${value?._id}`}
                          setShowReviewModal={setShowReviewModal}
                          setSeletctedInstiution={setSeletctedInstiution}
                        />
                      ))}
                    </div>
                    {skip > limit ? (
                      <button
                        onClick={() => {
                          if (skip > limit) setSkip(skip - limit);
                          dispatch(
                            institutionActions.getInstitutions({
                              skip,
                              limit: limit,
                            }),
                          );
                        }}
                      >
                        {'<< Previous'}
                      </button>
                    ) : null}
                    {skip + limit < count ? (
                      <>
                        <span> | </span>
                        <button
                          onClick={() => {
                            setSkip(skip + limit);
                            dispatch(
                              institutionActions.getInstitutions({
                                skip,
                                limit: limit,
                              }),
                            );
                          }}
                        >
                          {'Next >>'}
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReviewModal
            content={selectedInstitution}
            userType={'Institution'}
            showReviewModal={showReviewModal}
            setShowReviewModal={setShowReviewModal}
          />
        </>
      )}
    </>
  );
};

export default function InstitutionsPage() {
  const user = useSelector((state) => state?.user?.user);
  const institutions = useSelector((state) => state?.institution?.data);
  return (
    <>
      <NavHeader institutions={institutions} user={user} />
      <PageBody user={user} />
    </>
  );
}
