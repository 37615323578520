import * as joinRequestTypes from '../types/joinrequests';

const initialState = {
  joinRequestData: [],
  joinRequestCount: 0,
  loading: false,
  error: false,
  errorMessage: null,
  success: {
    status: false,
    message: 'All set!',
  },
};

const joinRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case joinRequestTypes.GET_ALL_JOIN_REQUESTS:
      return {
        ...state,
        joinRequestCount: action.payload.count,
        joinRequestData: action.payload.data,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case joinRequestTypes.UPDATE_REQUEST_STATUS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };

    default:
      return state;
  }
};

export default joinRequestReducer;
