import React, { useEffect, useState } from 'react';

// components
import Button from '../components/Button';
import Video from '../components/Video';

import CourseCarddata from '../components/CourseCarddata';
import { useParams } from 'react-router-dom';

// SVG
import video1 from '../assets/video1.svg';

import leftarrow from '../assets/leftarrow.svg';
import rightarrow from '../assets/rightarrow.svg';
import Nav from '../components/NavBarR';

const CoursesSidebarContent = (props) => {
  return (
    <div className='hover:bg-newMain active:bg-newMain rounded-md cursor-pointer w-48  mx-auto h-10  pl-3 mb-5'>
      <p className='pt-2 text:charcoal active:text-white hover:text-white hover-font-semibold font-body font-medium text-base leading-6'>
        {props.content}
      </p>
    </div>
  );
};

const CoursesSidebar = () => {
  return (
    <div>
      <sidebar className='left_rec px-2 mt-10'>
        <CoursesSidebarContent content='WRITIING' />
        <CoursesSidebarContent content='BUSINESS' />
        <CoursesSidebarContent content='PHOTO & FILM' />
        <CoursesSidebarContent content='IT & SOFTWARE' />
        <CoursesSidebarContent content='PUBLIC SPEAKING' />
        <CoursesSidebarContent content='DESIGN' />
        <CoursesSidebarContent content='ANIMATION' />
        <CoursesSidebarContent content='ILLUSTRATION' />
      </sidebar>
    </div>
  );
};

const Courses = () => {
  const { course } = useParams();

  useEffect(() => {
    console.log(course);
    if (course) {
      if (course.includes('&')) {
        let cs = course.split('&');
        setCasedCourse(
          cs[0].charAt(0).toUpperCase() + cs[1].charAt(0).slice(1),
        );
      } else if (course.icludes('_')) {
        let cs = course.split('_');
        setCasedCourse(
          cs[0].charAt(0).toUpperCase() + cs[1].charAt(0).slice(1),
        );
      } else {
        let c = course.charAt(0).toUpperCase() + course.slice(1);
        setCasedCourse(c);
      }
    }

    return () => {
      // cleanup
    };
  }, [course]);

  const [casedCourse, setCasedCourse] = useState('');

  return (
    <div>
      <Nav coursesActive={true} />

      <div className='flex flex-row w-screen bg-lightgraybg'>
        <div className='border-r border-linecolor w-96 ml-10 '>
          <CoursesSidebar />
        </div>

        <main className='flex flex-1 bg-lightgraybg  min-h-screen max-w-5xl ml-10 mr-16'>
          <div className=''>
            <div className='text-4xl font-bold text-black font-body mt-6 mb-5 flex'>
              <h1>{casedCourse || 'Animation'}</h1>
            </div>
            <div className='flex flex-row '>
              <h1 className='text-gray4 font-body text-xs font-semibold w-8 h-14 not-italic mr-3 mt-10'>
                TAGS
              </h1>
              <div className='flex flex-1 justify-evenly '>
                <Button
                  content='2d Amination'
                  backgroundColor='bg-lightgraybg'
                  border='border'
                  borderColor='border-gray4'
                  contentColor='text-gray4'
                  borderRadius='rounded-sm'
                  width='w-14'
                  // margin='mb-4'
                  padding='pr-2'
                  height='h-6'
                />
                <Button
                  content='2d Amination'
                  backgroundColor='bg-lightgraybg'
                  border='border'
                  borderColor='border-gray4'
                  contentColor='text-gray4'
                  borderRadius='rounded-sm'
                  width='w-14'
                  // margin=' mb-4'
                  padding='pr-2'
                  height='h-6'
                />
                <Button
                  content='2d Amination'
                  backgroundColor='bg-lightgraybg'
                  border='border'
                  borderColor='border-gray4'
                  contentColor='text-gray4'
                  borderRadius='rounded-sm'
                  width='w-14'
                  // margin='mb-4'
                  padding='pr-2'
                  height='h-6'
                />
                <Button
                  content='2d Amination'
                  backgroundColor='bg-lightgraybg'
                  border='border'
                  borderColor='border-gray4'
                  contentColor='text-gray4'
                  borderRadius='rounded-sm'
                  width='w-14'
                  // margin='mb-4'
                  padding='pr-2'
                  height='h-6'
                />
                <Button
                  content='2d Amination'
                  backgroundColor='bg-lightgraybg'
                  border='border'
                  borderColor='border-gray4'
                  contentColor='text-gray4'
                  borderRadius='rounded-sm'
                  width='w-14'
                  // margin='mb-4'
                  padding='pr-2'
                  height='h-6'
                />
                <Button
                  content='2d Amination'
                  backgroundColor='bg-lightgraybg'
                  border='border'
                  borderColor='border-gray4'
                  contentColor='text-gray4'
                  borderRadius='rounded-sm'
                  width='w-14'
                  // margin='mb-4'
                  padding='pr-2'
                  height='h-6'
                />
              </div>
            </div>
            <div className='flex flex-row w-full mt-20 mb-8 justify-between'>
              <div className='flex-1 text-2xl font-bold text-black font-body '>
                <h1>Popular {casedCourse || ''} Courses</h1>
              </div>
              {/* <div>
                <Button
                  content='View All'
                  backgroundColor='bg-lightgraybg'
                  contentColor='text-main'
                  borderColor='border-main'
                  borderRadius='rounded-lg'
                  border='border-2'
                />
              </div> */}
            </div>
            <div className='flex justify-between relative'>
              <div className='bg-white rounded-md shadow-md w-80 h-80  mb-14 mr-4 '>
                <Video url={video1} />
                <div className='top-36 h-12 w-12 rounded-full -left-4 items-center bg-white shadow-sm  absolute'>
                  <img
                    src={leftarrow}
                    alt='playbutton'
                    className='justify-center mt-4 ml-4 '
                  />
                </div>
                <CourseCarddata
                  students='231 students'
                  hours='2h 27m'
                  description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                  author='Richard'
                  star='4.6'
                  value='(100)'
                />
              </div>

              <div className='bg-white rounded-md shadow-md w-80 h-80 mb-14 relative hover:bg-blueshadow mr-4'>
                <Video url={video1} />

                {/* <div className='top-36 h-12 w-12 rounded-full  left-28 items-center bg-white shadow-sm absolute'>
                  <img
                    src={playarrow}
                    alt='playbutton'
                    className='justify-center mt-4 ml-4 '
                  />
                </div> */}
                <CourseCarddata
                  students='231 students'
                  hours='2h 27m'
                  description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                  author='Richard'
                  star='4.6'
                  value='(100)'
                />
              </div>

              <div className='bg-white rounded-md shadow-md w-80 h-80 mb-14'>
                <Video url={video1} />
                <div className='top-36 h-12 w-12 rounded-full -right-4 items-center bg-white shadow-sm absolute'>
                  <img
                    src={rightarrow}
                    alt='playbutton'
                    className='justify-center mt-4 ml-4 '
                  />
                </div>
                <CourseCarddata
                  students='231 students'
                  hours='2h 27m'
                  description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                  author='Richard'
                  star='4.6'
                  value='(100)'
                />
              </div>
            </div>

            <div className='flex flex-row w-full mt-10 mb-8 justify-between'>
              <div className='flex-1 text-2xl font-bold text-black font-body '>
                <h1>Latest {casedCourse || ''} Courses</h1>
              </div>
              {/* <div>
                <Button
                  content='View All'
                  backgroundColor='bg-lightgraybg'
                  contentColor='text-main'
                  borderColor='border-main'
                  borderRadius='rounded-lg'
                  border='border-2'
                />
              </div> */}
            </div>
            <div className='flex justify-between relative'>
              <div className='bg-white rounded-md shadow-md w-80 h-80  mb-14 mr-4 '>
                <Video url={video1} />
                <div className='top-36 h-12 w-12 rounded-full -left-4 items-center bg-white shadow-sm  absolute'>
                  <img
                    src={leftarrow}
                    alt='playbutton'
                    className='justify-center mt-4 ml-4 '
                  />
                </div>
                <CourseCarddata
                  students='231 students'
                  hours='2h 27m'
                  description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                  author='Richard'
                  star='4.6'
                  value='(100)'
                />
              </div>

              <div className='bg-white rounded-md shadow-md w-80 h-80 mb-14 relative hover:bg-blueshadow mr-4'>
                <Video url={video1} />

                {/* <div className='top-36 h-12 w-12 rounded-full  left-28 items-center bg-white shadow-sm absolute'>
                  <img
                    src={playarrow}
                    alt='playbutton'
                    className='justify-center mt-4 ml-4 '
                  />
                </div> */}
                <CourseCarddata
                  students='231 students'
                  hours='2h 27m'
                  description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                  author='Richard'
                  star='4.6'
                  value='(100)'
                />
              </div>

              <div className='bg-white rounded-md shadow-md w-80 h-80 mb-14'>
                <Video url={video1} />
                <div className='top-36 h-12 w-12 rounded-full -right-4 items-center bg-white shadow-sm absolute'>
                  <img
                    src={rightarrow}
                    alt='playbutton'
                    className='justify-center mt-4 ml-4 '
                  />
                </div>
                <CourseCarddata
                  students='231 students'
                  hours='2h 27m'
                  description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                  author='Richard'
                  star='4.6'
                  value='(100)'
                />
              </div>
            </div>

            <div className='flex flex-row w-full mt-10 mb-8 justify-between'>
              <div className='flex-1 text-2xl font-bold text-black font-body '>
                <h1>Trending {casedCourse || ''} Courses</h1>
              </div>
              <div>
                <Button
                  content='View All'
                  backgroundColor='bg-lightgraybg'
                  contentColor='text-main'
                  borderColor='border-main'
                  borderRadius='rounded-lg'
                  border='border-2'
                />
              </div>
            </div>

            <div className='flex justify-between relative'>
              <div className='bg-white rounded-md shadow-md w-80 h-80  mb-14 mr-4 '>
                <Video url={video1} />
                <div className='top-36 h-12 w-12 rounded-full -left-4 items-center bg-white shadow-sm  absolute'>
                  <img
                    src={leftarrow}
                    alt='playbutton'
                    className='justify-center mt-4 ml-4 '
                  />
                </div>
                <CourseCarddata
                  students='231 students'
                  hours='2h 27m'
                  description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                  author='Richard'
                  star='4.6'
                  value='(100)'
                />
              </div>

              <div className='bg-white rounded-md shadow-md w-80 h-80 mb-14 relative hover:bg-blueshadow mr-4'>
                <Video url={video1} />

                {/* <div className='top-36 h-12 w-12 rounded-full  left-28 items-center bg-white shadow-sm absolute'>
                  <img
                    src={playarrow}
                    alt='playbutton'
                    className='justify-center mt-4 ml-4 '
                  />
                </div> */}
                <CourseCarddata
                  students='231 students'
                  hours='2h 27m'
                  description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                  author='Richard'
                  star='4.6'
                  value='(100)'
                />
              </div>

              <div className='bg-white rounded-md shadow-md w-80 h-80 mb-14'>
                <Video url={video1} />
                <div className='top-36 h-12 w-12 rounded-full -right-4 items-center bg-white shadow-sm absolute'>
                  <img
                    src={rightarrow}
                    alt='playbutton'
                    className='justify-center mt-4 ml-4 '
                  />
                </div>
                <CourseCarddata
                  students='231 students'
                  hours='2h 27m'
                  description='Virtual Reality for Artists: How to Bring Illustrations into 3D with Tilt Brush'
                  author='Richard'
                  star='4.6'
                  value='(100)'
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Courses;
