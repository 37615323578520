import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Truncate from 'react-truncate';
import _ from 'lodash';

// Components
import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import AdBanner from '../components/AdBanner';
import NavHeader from '../components/NavBarHeader';
import DisplayStarRating from '../components/StarRating';
import DefaultThumbnail from '../components/DefaultThumbnail';

// Actions
import * as searchActions from '../redux/actions/search';

const Filters = ({ searchResults, filteredResults, setFilteredResults }) => {
  const articles = searchResults?.content?.filter(
    (value) => value.category === 'Article',
  );
  const videos = searchResults?.content?.filter(
    (value) => value.category === 'Video',
  );

  const contentTypeCheckbox = () => {
    setFilteredResults([]);
    const videoCheckbox = document.getElementById('videos').checked;
    const articleCheckbox = document.getElementById('articles').checked;
    const sessionCheckbox = document.getElementById('sessions').checked;

    if (videoCheckbox && articleCheckbox && sessionCheckbox)
      setFilteredResults([...videos, ...articles, ...searchResults?.sessions]);
    else if (articleCheckbox && sessionCheckbox)
      setFilteredResults([...articles, ...searchResults?.sessions]);
    else if (videoCheckbox && articleCheckbox)
      setFilteredResults([...videos, ...articles]);
    else if (videoCheckbox && sessionCheckbox)
      setFilteredResults([...videos, ...searchResults?.sessions]);
    else if (videoCheckbox) setFilteredResults([...videos]);
    else if (articleCheckbox) setFilteredResults([...articles]);
    else if (sessionCheckbox) setFilteredResults([...searchResults?.sessions]);
    else setFilteredResults(Object.values(searchResults).flat());
  };

  return (
    <>
      <div className='filter-group mt-2'>
        {/* By Catergory*/}
        <h1 className='text-base text-charcoal mt-2 lg:mt-9'>Category</h1>

        <div className='flex items-center mt-5'>
          <input
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={(e) => {
              e.target.checked
                ? setFilteredResults([
                    ..._.orderBy(filteredResults, ['parentCourse'], ['asc']),
                  ])
                : contentTypeCheckbox();
            }}
          />
          <p className='ml-4  mr-10 md:mr-5 text-sm text-charcoal'>
            Career Guidance
          </p>
          <div className='flex flex-1 justify-end lg:justify-start xl:justify-end items-center  '>
            <span className='text-charcoal text-sm'></span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={(e) => {
              e.target.checked
                ? setFilteredResults([
                    ..._.orderBy(filteredResults, ['parentCourse'], ['desc']),
                  ])
                : contentTypeCheckbox();
            }}
          />
          <p className='ml-4  mr-10 md:mr-5 text-sm text-charcoal'>
            Upskilling
          </p>
          <div className='flex flex-1 justify-end lg:justify-start xl:justify-end items-center  '>
            <span className='text-charcoal text-sm'></span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            disabled
          />
          <p className='ml-4  mr-10 md:mr-5 text-sm text-charcoal'>
            Job Search
          </p>
          <div className='flex flex-1 justify-end lg:justify-start xl:justify-end items-center  '>
            <span className='text-charcoal text-sm'>{''}</span>
          </div>
        </div>
      </div>

      {/* Content Type */}
      <div className='filter-group mt-2'>
        <h1 className='text-base text-charcoal mt-2 lg:mt-9'>Content Type</h1>
        <div className='flex items-center mt-5'>
          <input
            id='videos'
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={contentTypeCheckbox}
          />
          <p className='ml-4  mr-10 md:mr-5 text-sm text-charcoal'> Video</p>
          <div className='flex flex-1 justify-end lg:justify-start xl:justify-end items-center  '>
            <span className='text-charcoal text-sm'>
              (
              {searchResults?.content?.reduce(
                (counter, { category }) =>
                  category === 'Video' ? (counter += 1) : counter,
                0,
              ) || 0}
              )
            </span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='articles'
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={contentTypeCheckbox}
          />
          <p className='ml-4  mr-10 md:mr-5 text-sm text-charcoal'> Article</p>
          <div className='flex flex-1 justify-end lg:justify-start xl:justify-end items-center  '>
            <span className='text-charcoal text-sm'>
              (
              {searchResults?.content?.reduce(
                (counter, { category }) =>
                  category === 'Article' ? (counter += 1) : counter,
                0,
              ) || 0}
              )
            </span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            disabled
          />
          <p className='ml-4  mr-10 md:mr-5 text-sm text-charcoal'>Course</p>
          <div className='flex flex-1 justify-end lg:justify-start xl:justify-end items-center  '>
            <span className='text-charcoal text-sm'>
              ({searchResults?.courses?.length})
            </span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            id='sessions'
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={contentTypeCheckbox}
          />
          <p className='ml-4  mr-10 md:mr-5 text-sm text-charcoal'>Sessions</p>
          <div className='flex flex-1 justify-end lg:justify-start xl:justify-end items-center  '>
            <span className='text-charcoal text-sm'>
              ({searchResults?.sessions?.length})
            </span>
          </div>
        </div>
      </div>

      {/* Relevance */}
      <div className='filter-group mt-2'>
        <h1 className='text-base text-charcoal mt-9'>Relevance</h1>

        <div className='flex items-center mt-5'>
          <input
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={(e) => {
              if (e.target.checked)
                setFilteredResults([
                  ..._.orderBy(
                    filteredResults,
                    [(i) => i.createdAt || ''],
                    ['desc'],
                  ),
                ]);
            }}
          />
          <p className='ml-4  mr-10 md:mr-5 text-base text-charcoal'>Latest</p>
          <div className='flex flex-1 justify-end lg:justify-start xl:justify-end items-center  '>
            <span className='text-charcoal text-base'> </span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={(e) => {
              if (e.target.checked)
                setFilteredResults([
                  ..._.orderBy(
                    filteredResults,
                    [(i) => i.avgRating || ''],
                    ['desc'],
                  ),
                ]);
            }}
          />
          <p className='ml-4  mr-10 md:mr-5 text-base text-charcoal'>Popular</p>
          <div className='flex flex-1 justify-end lg:justify-start xl:justify-end items-center  '>
            <span className='text-charcoal text-base'> </span>
          </div>
        </div>
        <div className='flex items-center mt-5'>
          <input
            type='checkbox'
            className='form-checkbox text-charcoal h-4 w-4'
            onChange={(e) => {
              if (e.target.checked)
                setFilteredResults([
                  ..._.orderBy(
                    filteredResults,
                    [(i) => i.views || ''],
                    ['desc'],
                  ),
                ]);
            }}
          />
          <p className='ml-4  mr-10 md:mr-5 text-base text-charcoal'>
            Trending
          </p>
          <div className='flex flex-1 justify-end lg:justify-start xl:justify-end items-center  '>
            <span className='text-charcoal text-xl'></span>
          </div>
        </div>
      </div>
    </>
  );
};

const MobileFilters = ({
  showFilters,
  setShowFilters,
  searchResults,
  filteredResults,
  setFilteredResults,
}) => {
  return (
    <div
      className={
        showFilters
          ? 'w-full h-screen shadow-md absolute z-40  transform  translate-x-0 lg:hidden'
          : '   w-full h-full absolute z-40  transform -translate-x-full'
      }
    >
      <div
        className='bg-black opacity-50 w-full h-screen absolute  '
        onClick={() => setShowFilters(!showFilters)}
      ></div>
      <div className='w-64 md:w-72 absolute z-40 bg-white shadow h-full flex-col justify-between lg:hidden pb-4 transition duration-150 ease-in-out'>
        <div className='flex flex-col justify-between h-full'>
          <div>
            <div className='flex items-center justify-between px-8'>
              <div className='h-16 w-full flex items-center'>
                <span className='xl:text-base md:text-2xl text-base ml-2 text-main'>
                  Search Filters
                </span>
              </div>
              <div
                id='closeSideBar'
                className='flex items-center justify-center h-10 w-10'
                onClick={() => setShowFilters(!showFilters)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='icon icon-tabler icon-tabler-x'
                  width={20}
                  height={20}
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' />
                  <line x1={18} y1={6} x2={6} y2={18} />
                  <line x1={6} y1={6} x2={18} y2={18} />
                </svg>
              </div>
            </div>
            <div className='px-8 overflow-y-auto overscroll-contain h-screen bottom-5'>
              <div className='block  sm:ml-8 mb-10 text-headingtext font-sans sm:text-sm'>
                <div className='block mt-5 mb-10 text-headingtext font-sans sm:text-sm'>
                  <span className='text-base text-charcoal'>Filters</span>
                  <Filters
                    searchResults={searchResults}
                    filteredResults={filteredResults}
                    setFilteredResults={setFilteredResults}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ResultsCard = ({ result }) => {
  const url = () => {
    if (result.email) return `/mentors/more/${result?._id}`;
    if (result.sessionTitle) return `/session/${result?._id}`;
    if (result.category === 'Image') return `/image/${result?._id}`;
    if (result.category === 'Video') return `/video/watch/${result?._id}`;
    if (result.category === 'Article') return `/article/read/${result?._id}`;
  };
  return (
    <a href={url()}>
      <div className=' py-2 mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-0 lg:px-0 lg:py-4 min-h-80 mb-4'>
        <div className=' max-w-full gap-8 row-gap-5 md:row-gap-8 sm:mx-auto'>
          <div className='p-4 bg-white rounded shadow-sm hover:shadow md:text-left sm:text-md'>
            <div className='flex flex-col lg:flex-row w-full items-start lg:justify-start'>
              <div className=' h-32 w-full sm:h-36 lg:h-40 lg:w-4/12 flex flex-col  justify-start'>
                {result.category === 'Video' || result.category === 'Image' ? (
                  <img
                    src={
                      result?.thumbnailFile?.publicUrl ||
                      result?.profilePicture?.publicUrl
                    }
                    alt={result?.title || result?.sessionTitle}
                    className=' object-cover lg:object-cover h-full w-full shadow-md'
                  />
                ) : (
                  <DefaultThumbnail text={result?.title} />
                )}
              </div>

              <div className='flex lg:ml-2 sm:px-2 py-4 lg:py-0 w-full lg:w-8/12 lg:h-full'>
                <div className='flex flex-col flex-wrap lg:flex-col justify-between lg:justify-between'>
                  <h5 className='mb-2 text-xl text-charcoal font-bold leading-none sm:text-2xl'>
                    {result?.title ||
                      result?.sessionTitle ||
                      `${result?.firstname} ${result?.lastname}`}
                  </h5>
                  <Truncate
                    lines={1}
                    ellipsis={<span className='text-headingtext'>...</span>}
                  >
                    {result?.description || result?.about}
                  </Truncate>
                  <p className='mb-2 text-gray-700 font-semibold'>
                    {result?.author || result?.presenter}
                  </p>
                  <div>
                    <DisplayStarRating selectStars={result?.avgRating || 0} />
                    <span className='mr-2.5 text-linecolor'>
                      ({result?.rating?.length} Reviews)
                    </span>
                  </div>
                  <p className='mb-2 text-gray-700'>
                    {result?.username ? null : `${result?.views || 0} Views`}
                  </p>
                  <div className='inline-flex items-center justify-center w-44 lg:w-40 h-8 px-8 font-normal tracking-wide text-black bg-badgeYellow  focus:shadow-outline focus:outline-none'>
                    {result?.category ||
                      result?.usercategory?.[
                        result?.usercategory?.length - 1
                      ] ||
                      'Session'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default function SearchPage() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.user);
  const searchResults = useSelector((state) => state.search.data);
  const searchQuery = useSelector((state) => state.search.query);
  const loading = useSelector((state) => state.search.loading);
  const [show, setShow] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    setFilteredResults(Object.values(searchResults).flat());
  }, [searchResults]);

  return (
    <>
      {user ? <Nav /> : <NavHeader />}
      <AdBanner />
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div className='bg-lightgraybg w-full h-full '>
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-2 lg:px-8'>
              <div className='flex flex-row justify-around'>
                <div className='hidden lg:w-2/12 border-r-2  border-gray3 pr-6  lg:flex '>
                  <div className='block mt-5 mb-10 text-headingtext font-sans sm:text-sm'>
                    <span className='text-base text-charcoal'>Filters</span>
                    <Filters
                      searchResults={searchResults}
                      filteredResults={filteredResults}
                      setFilteredResults={setFilteredResults}
                    />
                  </div>
                </div>
                <MobileFilters
                  showFilters={show}
                  setShowFilters={setShow}
                  searchResults={searchResults}
                  filteredResults={filteredResults}
                  setFilteredResults={setFilteredResults}
                />
                <div className='flex flex-col pb-5 md:px-2 lg:pl-8 mt-5 mb-10 text-headingtext font-sans w-full md:w-10/12'>
                  <div className='flex flex-row justify-between'>
                    <span className='text-lg lg:text-xl text-gray-700'>
                      Showing {filteredResults.length} results for “
                      {searchQuery}”
                    </span>
                    <button
                      onClick={() => {
                        setShow(!show);
                      }}
                      className='lg:hidden
                inline-flex items-center justify-center h-10 px-8 py- mx-2 border border-darkerblue tracking-wide font-bold text-white text-sm mb-2 transition duration-200 rounded-sm shadow-md bg-darkerblue hover:bg-bannerBlue focus:shadow-outline focus:outline-none'
                    >
                      Filters
                    </button>
                  </div>
                  <div className='w-full '>
                    {filteredResults?.map((value) =>
                      value.email || value.sessionTitle || value.category ? (
                        <ResultsCard result={value} key={value._id} />
                      ) : null,
                    )}

                    <button
                      onClick={() =>
                        dispatch(
                          searchActions.search({
                            keywords: searchQuery,
                            skip: 0,
                            limit: 30,
                          }),
                        )
                      }
                    >
                      {'<< Previous'}
                    </button>
                    {filteredResults.length > 0 ? (
                      <>
                        <span> | </span>
                        <button
                          onClick={() =>
                            dispatch(
                              searchActions.search({
                                keywords: searchQuery,
                                skip: 30,
                                limit: 30,
                              }),
                            )
                          }
                        >
                          {'Next >>'}
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
