import moment from 'moment';
import React, { useState } from 'react';
import EventCard from './EventCard';
import NoContentPlaceholder from './NoContentPlaceholder';

const EventsSection = ({ eventList }) => {
  const today = moment();

  const upcomingSessions = [];
  const pastSessions = [];

  eventList.forEach((value) => {
    if (moment(value?.sessionDate) < today) {
      pastSessions.push(value);
    } else {
      upcomingSessions.push(value);
    }
  });

  const [slice, setSlice] = useState(3);
  const handleMoreContent = () => {
    setSlice(slice + 2);
  };
  const [slice2, setSlice2] = useState(3);
  const handleMoreContent2 = () => {
    setSlice2(slice2 + 2);
  };

  return (
    <>
      <div className='flex flex-col mb-6 lg:justify-between md:mb- shadow '>
        <div className='grid grid-cols-1 gap-6 row-gap-4 mb-8'>
          <div className='border border-linecolor bg-white pb-10 mt-6 rounded-lg p-4'>
            <h1 className='font-bold text-charcoal mb-6 mt-2  font-sans text-xl  tracking-tight sm:text-2xl sm:leading-none'>
              Upcoming sessions
            </h1>
            <div className='grid gap-8 row-gap-5 md:grid-cols-1'>
              {upcomingSessions.length > 0 ? (
                <div>
                  {upcomingSessions
                    ?.reverse()
                    ?.slice(0, slice)
                    .map((value, key) => (
                      <EventCard event={value} key={key} past={false} />
                    ))}
                </div>
              ) : (
                <NoContentPlaceholder type='Upcoming' content='Sessions' />
              )}
              <div className='flex justify-center pt-1'>
                <span
                  className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
                  onClick={() => handleMoreContent()}
                  disabled={slice >= upcomingSessions?.length}
                >
                  {slice >= upcomingSessions?.length
                    ? upcomingSessions?.length === 0
                      ? ''
                      : 'No more sessions to show'
                    : 'Show more Sessions'}
                </span>
              </div>
            </div>
          </div>
          <div className='border border-linecolor bg-white pb-10 mt-6 rounded-lg p-4'>
            <h1 className='font-bold text-charcoal mb-6 mt-2  font-sans text-xl  tracking-tight sm:text-2xl sm:leading-none'>
              Past sessions
            </h1>
            <div className='grid gap-8 row-gap-5 md:grid-cols-1'>
              {pastSessions.length > 0 ? (
                <div>
                  {pastSessions
                    ?.reverse()
                    ?.slice(0, slice2)
                    .map((value, key) => (
                      <EventCard event={value} key={key} past={true} />
                    ))}
                </div>
              ) : (
                <NoContentPlaceholder type='Past' content='Sessions' />
              )}
              <div className='flex justify-center pt-1'>
                <span
                  className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
                  onClick={() => handleMoreContent2()}
                  disabled={slice2 >= pastSessions?.length}
                >
                  {slice2 >= pastSessions?.length
                    ? pastSessions?.length === 0
                      ? ''
                      : 'No more sessions to show'
                    : 'Show more Sessions'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsSection;
