import React, { useEffect } from 'react';
import firebase from 'firebase/app';
import Routes from './routes';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

require('firebase/analytics');
require('firebase/auth');

// Configuring firebase
const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

function App() {
  useEffect(() => {
    firebaseApp.analytics();
  }, []);

  useEffect(() => {
    ReactGA.initialize('UA-219662982-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className='App'>
      <Routes />
      <ToastContainer />
    </div>
  );
}

export default App;
