import React from 'react';

export default function IconsRole({ icon, role, Action }) {
  return (
    <div
      className='cursor-pointer font-medium tracking-wide text-headingtext transition-colors duration-200 hover:text-darkerblue mx-auto flex flex-wrap justify-center'
      onClick={Action}
    >
      {icon}
      <span className='ml-1 text-xs sm:text-sm text-center'>{role}</span>
    </div>
  );
}
