import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  boxShadow,
  content,
  backgroundColor = 'bg-main',
  loading,
  contentColor = 'text-white',
  borderColor = 'border-linecolor',
  borderRadius = 'rounded',
  padding = 'p-1',
  paddingLeft = 'p:1',
  opacity = 'opacity-100',
  onClick,
  disabled = false,
  icon,
  margin,
  width = 'w-100',
  height = 'h-11',
  border = 'border',
  type,
  focusOutline = 'focus:outline-none ',
  iconWidth = 'w-10',
  iconHeight = 'h-10',
  iconMargin = 'ml-2 mt-2',
}) => {
  const handleButtonClick = (e) => {
    if (disabled || loading) {
      e.preventDefault();
    }

    if (!disabled || !loading) {
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <div
      className={`${backgroundColor} ${boxShadow} ${opacity} flex  ${border} ${borderColor} box-border ${borderRadius} ${margin} m-auto `}
      style={{ padding: '4px 25px 4px 25px' }}
    >
      {icon && (
        <img
          src={icon}
          className={`google_logo ${iconWidth} ${iconHeight} ${iconMargin}`}
          alt='google-logo'
        />
      )}

      <button
        onClick={handleButtonClick}
        type={type}
        className={`submit ${width} ${height}  left-0 ${
          !icon ? 'w-full' : ''
        }   ${contentColor} ${opacity} ${focusOutline} border-none font-semibold leading-4 px-3 text-base font-body ${
          disabled ? `cursor-not-allowed ${backgroundColor} opacity-50` : ''
        }`}
      >
        {content}
      </button>
    </div>
  );
};

Button.protoTypes = {
  content: PropTypes.string.isRequired,
};
export default Button;
