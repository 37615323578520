import * as institutionTypes from '../types/institution';
import institutionEndpoints from '../endpoints/institution';
import * as fetchFunctions from '../../api/index';

export const createInstitution = (payload) => async (dispatch, getState) => {
  dispatch({
    type: institutionTypes.INSTITUTION_LOADING,
  });

  // const { token } = getState().user;

  const result = await fetchFunctions.postData(
    institutionEndpoints.create_institution,
    payload,
  );

  if (result.success) {
    dispatch({
      type: institutionTypes.INSTITUTION_SUCCESS,
      payload: {
        institution: result.response.newInstitution,
        status: true,
        message: 'Institution profile created',
      },
    });
  } else {
    dispatch({
      type: institutionTypes.INSTITUTION_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const profilePic = (payload, ID) => async (dispatch, getState) => {
  dispatch({ type: institutionTypes.INSTITUTION_LOADING });
  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    `${institutionEndpoints.institution_profilepic}/${ID}`,
    payload,
    token,
    true,
  );

  if (result?.success) {
    dispatch({
      type: institutionTypes.INSTITUTION_SUCCESS,
      payload: {
        status: true,
        message: 'Institution profile picture uploaded',
      },
    });
    dispatch({
      type: institutionTypes.INSTITUTION_RESET,
    });
  }
};

export const createMentor = (payload) => async (dispatch, getState) => {
  dispatch({
    type: institutionTypes.INSTITUTION_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    `${process.env.REACT_APP_CORS_PROXY}/${institutionEndpoints.create_mentor}`,
    payload,
    token,
  );
  if (result.success) {
    dispatch({
      type: institutionTypes.INSTITUTION_MENTOR_CREATE,
      payload: {
        status: true,
        message: 'Mentor profile successfully created',
      },
    });
  } else {
    dispatch({
      type: institutionTypes.INSTITUTION_ERROR,
      payload: {
        error: true,
        errorMessage: 'Failed to create mentor profile',
      },
    });
  }
};

export const mentorProfilePic = (payload, ID) => async (dispatch, getState) => {
  dispatch({ type: institutionTypes.INSTITUTION_LOADING });
  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    `${institutionEndpoints.mentor_profilepic}/${ID}`,
    payload,
    token,
    true,
  );

  if (result?.success) {
    dispatch({
      type: institutionTypes.INSTITUTION_MENTOR_CREATE,
      payload: {
        status: true,
        // message: result.response,
        message: 'Mentor profile picture uploaded',
      },
    });
    dispatch({
      type: institutionTypes.INSTITUTION_RESET,
    });
  }
};

export const getYourMentor = () => async (dispatch, getState) => {
  dispatch({
    type: institutionTypes.INSTITUTION_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    institutionEndpoints.get_your_mentor,
    token,
  );
  if (result.success) {
    dispatch({
      type: institutionTypes.YOUR_MENTOR,
      payload: result.data,
    });
  } else {
    dispatch({
      type: institutionTypes.INSTITUTION_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getInstitutionLearners =
  (institutionID) => async (dispatch, getState) => {
    dispatch({
      type: institutionTypes.INSTITUTION_LOADING,
    });
    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${institutionEndpoints.institution_learners}/${institutionID}`,
      token,
    );

    if (result.success) {
      dispatch({
        type: institutionTypes.INSTITUTION_LEARNER,
        payload: result.data,
      });
    } else {
      dispatch({
        type: institutionTypes.INSTITUTION_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const createInstitutionLearner =
  (payload) => async (dispatch, getState) => {
    dispatch({ type: institutionTypes.INSTITUTION_LOADING });
    const { token } = getState().user;

    const result = await fetchFunctions.postData(
      `${institutionEndpoints.institution_learners}`,
      payload,
      token,
      true,
    );

    if (result?.success) {
      dispatch({
        type: institutionTypes.ADDED_INSTITUTION_LEARNER,
        payload: {
          status: true,
          message: 'Institution Learner added successfully',
        },
      });
      setTimeout(function () {
        dispatch({
          type: institutionTypes.INSTITUTION_RESET,
        });
      }, 100);
    }
  };

export const getInvitedMentors = () => async (dispatch, getState) => {
  dispatch({
    type: institutionTypes.INSTITUTION_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    institutionEndpoints.get_invited_mentors,
    token,
  );
  if (result.success) {
    dispatch({
      type: institutionTypes.GET_INVITED_MENTORS,
      payload: result.data,
    });
  } else {
    dispatch({
      type: institutionTypes.INSTITUTION_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getInstitutions =
  ({ skip = 0, limit = 30 }) =>
  async (dispatch, getState) => {
    dispatch({
      type: institutionTypes.INSTITUTION_LOADING,
    });
    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${institutionEndpoints.get_institutions}/?skip=${skip}&limit=${limit}`,
      token,
    );
    if (result.success) {
      dispatch({
        type: institutionTypes.GET_INSTITUTIONS,
        payload: result.data,
      });
      dispatch({
        type: institutionTypes.INSTITUTION_RESET,
      });
    } else {
      dispatch({
        type: institutionTypes.INSTITUTION_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getSpecificInstitution =
  (institutionID) => async (dispatch, getState) => {
    dispatch({
      type: institutionTypes.INSTITUTION_LOADING,
    });

    const { token } = getState().user;
    const result = await fetchFunctions.getData(
      `${institutionEndpoints.get_specific_institution}/${institutionID}`,
      token,
    );

    if (result.success) {
      dispatch({
        type: institutionTypes.GET_SPECIFIC_INSTITUTION,
        payload: result.data,
      });
      dispatch({ type: institutionTypes.INSTITUTION_RESET });
    } else {
      dispatch({
        type: institutionTypes.INSTITUTION_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const getYourSpecificMentor =
  (mentorID) => async (dispatch, getState) => {
    dispatch({
      type: institutionTypes.INSTITUTION_LOADING,
    });

    const { token } = getState().user;
    const result = await fetchFunctions.getData(
      `${institutionEndpoints.get_your_specific_mentor}/${mentorID}`,
      token,
    );

    if (result.success) {
      dispatch({
        type: institutionTypes.YOUR_SPECIFIC_MENTOR,
        payload: result.data,
      });
      dispatch({ type: institutionTypes.INSTITUTION_RESET });
    } else {
      dispatch({
        type: institutionTypes.INSTITUTION_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };

export const inviteMentor = (payload) => async (dispatch, getState) => {
  dispatch({
    type: institutionTypes.INSTITUTION_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    institutionEndpoints.invite_mentor,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: institutionTypes.INSTITUTION_SUCCESS,
      payload: {
        status: true,
        message: 'Mentor successfully invited',
      },
    });
  } else {
    dispatch({
      type: institutionTypes.INSTITUTION_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const sendSurvey = (payload) => async (dispatch, getState) => {
  dispatch({
    type: institutionTypes.INSTITUTION_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    `${process.env.REACT_APP_CORS_PROXY}/${institutionEndpoints.send_survey}`,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: institutionTypes.INSTITUTION_SUCCESS,
      payload: {
        status: true,
        message: 'Survey sent successfully',
      },
    });
  } else {
    dispatch({
      type: institutionTypes.INSTITUTION_ERROR,
      payload: {
        error: true,
        errorMessage: 'Failed to submit survey',
      },
    });
  }
};
