import React from 'react';

import pic from '../assets/careerguidance.svg';
import PDP_1 from '../assets/personalDevelopmentPlan/Step-01.jpg';
import PDP_2 from '../assets/personalDevelopmentPlan/Step-02.jpg';
import PDP_3 from '../assets/personalDevelopmentPlan/Step-03.jpg';
import PDP_4 from '../assets/personalDevelopmentPlan/Step-04.jpg';
import PDP_5 from '../assets/personalDevelopmentPlan/Step-05.jpg';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Footer from '../components/Footer';
import NavHeaderLanding from '../components/NavBarLanding';
import { Link } from 'react-router-dom';
import { ArrowBack, Language } from '@material-ui/icons';

export default function PresentationPage() {
  const images = [
    {
      original: PDP_1,
      thumbnail: PDP_1,
    },
    {
      original: PDP_2,
      thumbnail: PDP_2,
    },
    {
      original: PDP_3,
      thumbnail: PDP_3,
    },
    {
      original: PDP_4,
      thumbnail: PDP_4,
    },
    {
      original: PDP_5,
      thumbnail: PDP_5,
    },
  ];
  return (
    <div>
      <div className='flex flex-col  '>
        <div
          className='flex flex-col relative '
          style={{
            background: ` rgba(0,0,0, 0.7) url(${pic})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundBlendMode: 'darken',
          }}
        >
          <NavHeaderLanding background={'bg-textsecondary bg-opacity-75'} />
        </div>

        <div className='bg-lightgraybg h-full'>
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
              <div className='flex justify-start'>
                <div className='mb-3 text-center md:mb-4 lg:mb-2'>
                  <Link
                    to='/careerguidance'
                    className='inline-flex items-center justify-center w-full h-12 font-medium tracking-wide text-headingtext transition duration-200  md:w-auto bg-none hover:text-navyblue focus:outline-none'
                  >
                    <ArrowBack />
                    <span className='ml-2'>Back</span>
                  </Link>
                </div>
              </div>
              <div className='mb-2 text-left  md:mb-4 flex flex-row justify-between'>
                <div>
                  <h2 className='max-w-lg mb-2 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl '>
                    <span className='relative inline-block'>
                      <span className='relative'>Try </span>
                    </span>{' '}
                    it out yourself
                  </h2>
                  <p className='text-textparagraph font-normal text-sm'>
                    Personal Development Plan
                  </p>
                </div>
                <a
                  href='https://drive.google.com/file/d/1FmyYDy6D_qHXw9G4zZRbsUrRaIcMNLW7/view'
                  target='_blank'
                  rel='noreferrer'
                >
                  <button className=' ml-6 inline-flex items-center justify-center w-full text-sm md:text-base px-4 h-12 md:px-6 font-medium tracking-wide text-darkerblue transition duration-200 rounded shadow-md md:w-auto bg-none hover:bg-navyblue hover:text-white border-2 border-darkerblue hover:border-navyblue focus:shadow-outline focus:outline-none'>
                    <span className='hidden md:flex'>
                      <Language />
                    </span>
                    <span className='ml-2'>Learn More</span>
                  </button>
                </a>
              </div>
              <ImageGallery items={images} thumbnailPosition={'right'} />
            </div>
          </div>
        </div>

        <div className='bg-black2 bottom-0'>
          <Footer />
        </div>
      </div>
    </div>
  );
}
