import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { ExpandMore } from '@material-ui/icons';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import * as usersActions from '../redux/actions/users';

export default function UserMenu({ user }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    dispatch(usersActions.logOut());
    history.push('/');
  };
  const profile = () => {
    history.push('/updateprofile');
  };

  return (
    <div className='text-headingtext lg:text-white'>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        color='inherit'
        style={{ textTransform: 'none' }}
        size='large'
      >
        <span className='font-display'>{user}</span> <ExpandMore />
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={profile}>My profile</MenuItem>
        {/* <MenuItem onClick={handleClose}>Settings</MenuItem> */}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
