import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ArrowBack } from '@material-ui/icons';
import NoContentPlaceholder from '../components/NoContentPlaceholder';

import VideoPlaceholder from '../assets/video1.svg';
import Loading from '../components/Loading';
import Nav from '../components/NavBarR';

// Actions
import * as sessionActions from '../redux/actions/sessions';

const MySessions = () => {
  return (
    <>
      <Nav />
      <PageBody />
    </>
  );
};

const PageBody = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const loading = useSelector((state) => state?.user?.loading);

  const sessionsData = useSelector((state) => state.sessions.data);
  const sessions = sessionsData.filter(
    (session) => moment(session.sessionDate) >= moment(),
  );

  useEffect(() => {
    dispatch(sessionActions.getAllSessions());
  }, []);

  const mySessions = sessions.filter((element) =>
    element.registeredAttendees.includes(user._id),
  );

  return (
    <>
      <div>
        {loading ? (
          <div className='bg-lightgraybg h-screen w-full fixed'>
            <Loading />
          </div>
        ) : (
          <div
            className={`bg-lightgraybg w-full ${
              mySessions.length < 5 ? 'h-screen' : 'h-full'
            }`}
          >
            <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
                <div className='flex justify-start w-full pb-5 lg:w-full mb-1 text-gray '>
                  <Link to='/session/all'>
                    <ArrowBack fontSize='medium' />{' '}
                    <span className='ml-2'>Back </span>
                  </Link>
                </div>

                <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                  <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                    <span className='inline-block mb-1 sm:mb-4'>
                      Subscribed Sessions ({mySessions.length})
                    </span>
                  </h2>
                  {/* <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                    {user.usercategory === 'mentor' && (
                      <button className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'>
                        <Add />
                        <span className='ml-2'>Create Session</span>
                      </button>
                    )}
                  </div> */}
                </div>
                <SessionSection sessions={mySessions} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const SessionCard = ({ session, to }) => {
  return (
    <Link
      to={to}
      aria-label='Video Item'
      className='inline-block overflow-hidden duration-300 transform bg-white rounded shadow-sm hover:-translate-y-2 border-b-4 border-white hover:border-darkerblue sm:m-3 w-full p-2'
    >
      <div className='flex flex-col h-full'>
        <img
          src={session?.thumbnailFile?.publicUrl || VideoPlaceholder}
          className='object-cover w-full h-48'
          alt={session?.sessionTitle}
        />

        <div className='flex flex-col justify-between  rounded-b px-2'>
          <div className='flex flex-row w-full justify-between px-2 mt-2'>
            <p className='text-sm text-gray'>{session?.sessionDate || '--'}</p>
            <p className='text-sm text-green3 font-bold'>
              {session?.price === 0 ? 'FREE' : `Ugx ${session?.price}`}
            </p>
          </div>
          <div className='py-5 px-2 '>
            <h6 className='text-charcoal font-bold text-lg leading-5'>
              {session?.sessionTitle}
            </h6>
          </div>
          <div className='flex flex-row w-full justify-between px-2 mt-2'>
            <p className='text-md text-gray capitalize'>
              {session?.presenter || '--'}
            </p>
            <p className='text-md text-darkerblue font-semibold text-right'>
              {session?.organiser || 'TBD'}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

const SessionSection = ({ sessions }) => {
  return (
    <>
      <div className='flex flex-col mb-6 lg:justify-between'>
        {sessions.length > 0 ? (
          <div className='grid grid-cols-1 gap-6 row-gap-4 mb-8 lg:grid-cols-4 sm:grid-cols-2'>
            {sessions.reverse()?.map((value) => (
              <SessionCard
                key={value?._id}
                session={value}
                to={`/session/${value?._id}`}
              />
            ))}
          </div>
        ) : (
          <NoContentPlaceholder type='Upcoming' content='sessions' />
        )}
      </div>
    </>
  );
};

export default MySessions;
