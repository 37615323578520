import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { People, ControlPoint, AddBox } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';
import Truncate from 'react-truncate';

import * as chatActions from '../redux/actions/chat';
import * as userActions from '../redux/actions/users';
import * as discussionGroupsActions from '../redux/actions/discussiongroups';
import Loading from '../components/Loading';
import CustomAlert from './CustomAlert';

const GroupChat = ({ groupID }) => {
  const [showNewChat, setShowNewChat] = useState(false);

  const dispatch = useDispatch();
  const { count, data, groupChatLoading } = useSelector((state) => state?.chat);

  const { success, error, errorMessage } = useSelector((state) => state.chat);

  useEffect(() => {
    dispatch(chatActions.getAllChats({ groupID }));
  }, []);

  useEffect(() => {
    setShowNewChat(false);

    if (success.message === 'Upload Complete')
      dispatch(chatActions.getAllChats({ groupID }));
    if (error)
      if (!`${errorMessage}`.includes('NetworkError')) {
        CustomAlert({
          alertType: 'error',
          alertMessage: 'Network error!',
        });
      } else {
        dispatch(chatActions.getAllChats({ groupID }));
      }
  }, [success, error]);

  return (
    <>
      {groupChatLoading === false ? (
        <div className='bg-lightGrayBg h-64 w-full p-auto'>
          <Loading />
        </div>
      ) : (
        <>
          <div>
            <h1 className='p-4  font-body font-semibold text-sm border-b border-linecolor mb-5 '>
              Discussions ({count})
              <div style={{ float: 'right' }}>
                <span onClick={() => setShowNewChat(true)}>
                  <AddBox
                    className='text-darkerblue'
                    style={{
                      fontSize: '30px',
                      cursor: 'pointer',
                      marginTop: '-0.5vh',
                    }}
                  />
                </span>
              </div>
            </h1>

            <div
              className='gen-group-chat'
              style={{ height: '50vh', overflowY: 'scroll' }}
            >
              {data.length > 0 ? (
                data.map((data, key) => (
                  <ChatCard key={data._id} chat={data} groupId={groupID} />
                ))
              ) : (
                <></>
              )}
            </div>

            <ViewAddNewChat
              setShowNewChat={setShowNewChat}
              showNewChat={showNewChat}
              groupid={groupID}
            />
          </div>
        </>
      )}
    </>
  );
};

const ViewAddNewChat = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const userId = user?._id;
  const [chatMembers, setChatMembers] = useState([`${userId}`]);
  const [title, setTitle] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      chatActions.createNewChat({
        groupId: props.groupid,
        title: title,
        admin: userId,
        members: chatMembers,
      }),
    );

    setTitle('');
    setChatMembers([`${userId}`]);
  };

  const { members } = useSelector((state) => state?.discussiongroups);

  const { data } = useSelector((state) => state?.user);
  const userList = data?.filter(
    (value) => value.usercategory[0] !== 'institution',
  );

  const groupId = props.groupid;
  useEffect(() => {
    dispatch(discussionGroupsActions.getOneDiscussionGroup({ groupId }));
    dispatch(userActions.getUsers());
  }, []);

  let memberData = [];
  members?.forEach((element) => {
    const user = userList?.filter((item) => item._id === element);
    memberData.push(user);
  });

  return (
    <>
      {props.showNewChat ? (
        <>
          <div
            style={{ width: '70%', overflowY: 'hidden', marginLeft: '15%' }}
            className='modalClass items-start flex overflow-x-hidden overflow-y-auto lg:pt-4 fixed inset-0  '
          >
            <div className='relative w-auto lg:w-8/12 mx-auto max-w-5xl  '>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none h-modal '>
                {/*header*/}
                <div className='h-full'>
                  <div className='flex items-start justify-between px-8 pb-2 pt-8 rounded-t h-2/12 border-b border-linecolor'>
                    <h3 className='text-1xl font-display font-bold text-charcoal'>
                      Create a new chat
                    </h3>
                    <button
                      className='p-1 ml-auto  text-black float-right text-3xl leading-none font-semibold '
                      onClick={() => props.setShowNewChat(false)}
                    >
                      <span className='text-charcoal h-6 w-6 text-2xl  hover:text-reddark'>
                        ×
                      </span>
                    </button>
                  </div>

                  {/* </div>  */}
                  <div style={{ padding: '20px' }}>
                    <form onSubmit={handleSubmit}>
                      <div className=''>
                        <label className='font-semibold text-textparagraph text-sm'>
                          Chat Title
                        </label>
                        <input
                          required
                          type='text'
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                      <br />
                      <label className='font-semibold text-textparagraph text-sm'>
                        To add member check box on the right
                      </label>
                      <div
                        className='gen-group-people'
                        style={{ height: '45vh', overflowY: 'scroll' }}
                      >
                        {memberData.length > 0 ? (
                          memberData.map((value, key) => (
                            <MemberChatCard
                              key={value._id}
                              member={value}
                              setChatMembers={setChatMembers}
                              chatMembers={chatMembers}
                            />
                          ))
                        ) : (
                          <></>
                        )}
                      </div>
                      <button
                        className='w-full h-14 falsefocus:shadow-outline  bg-darkerblue text-white text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                        type='submit'
                        onClick={(e) => handleSubmit(e)}
                      >
                        Create
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-60 modalOverlayClass fixed inset-0 bg-black  flex justify-center '></div>
        </>
      ) : null}
    </>
  );
};

const ChatCard = (props) => {
  const dispatch = useDispatch();
  const [showMembersModal, setShowMembersModal] = useState(false);
  const user = useSelector((state) => state.user.user);
  const userId = user._id;

  const LoadChat = (chatId, groupId, userId) => {
    dispatch(
      chatActions.checkMemberInChat({
        groupId,
        chatId,
        userId,
      }),
    );
    dispatch(
      chatActions.getAllChatDiscussions({
        groupId,
        chatId,
      }),
    );
  };

  const SendRequest = ({ chatadmin }, user, props) => {
    dispatch(
      discussionGroupsActions.joinDiscussionGroup({
        userId: user._id,
        fullName: `${user.firstname} ${user.lastname}`,
        whatToJoin: 'chat',
        idOfWhatToJoin: props.chat?._id,
        nameOfWhatToJoin: props.chat?.title,
        toJoinAs: 'member',
        canBeAcceptedBy: chatadmin,
      }),
    );

    CustomAlert({
      alertType: 'success',
      alertMessage: 'Request has been sent to the Admin',
    });
  };

  const [joinInvitation, setJoinInvitation] = useState(true);

  useEffect(() => {
    props.chat.members
      .filter((groupMember) => groupMember.includes(userId))
      .map((ourusr) => setJoinInvitation(false));
  }, []);

  var chatId = props.chat?._id;
  var groupId = props.groupId;
  var chatadmin = props.chat?.admin;
  return (
    <div
      className='ce-chat-card ce-active-chat-card'
      onClick={() => LoadChat(chatId, groupId, userId)}
      style={{
        padding: '16px 16px 12px',
        cursor: 'pointer',
        border: '0px solid white',
        margin: '0px 5px 10px 0px',
        borderRadius: '10px',
      }}
    >
      <div className='ce-chat-title-text'>
        <div className='chat-title-big w-full'>
          <Truncate width='270' ellipsis={<span>...</span>}>
            {props.chat?.title}
          </Truncate>

          <div style={{ float: 'right', marginRight: '5%' }}>
            <span onClick={() => setShowMembersModal(true)}>
              <People />
            </span>
          </div>

          {joinInvitation ? (
            <div style={{ float: 'right', marginRight: '5%' }}>
              <span onClick={() => SendRequest({ chatadmin }, user, props)}>
                <ControlPoint />
              </span>
            </div>
          ) : null}

          {/*?props.chat.members.filter(member => {
            if (member === userId) {
              return true;

            }
            return false;

          })*/}
        </div>
      </div>

      <ViewMembersList
        setShowMembersModal={setShowMembersModal}
        showMembersModal={showMembersModal}
        members={props.chat?.members}
      />
    </div>
  );
};

const ViewMembersList = (props) => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state?.user);
  const userList = data?.filter(
    (value) => value.usercategory[0] !== 'institution',
  );

  useEffect(() => {
    dispatch(userActions.getUsers());
  }, []);

  let memberData = [];
  props.members?.forEach((element) => {
    const user = userList?.filter((item) => item._id === element);
    memberData.push(user);
  });

  return (
    <>
      {props.showMembersModal ? (
        <>
          <div
            style={{ width: '70%', overflowY: 'hidden', marginLeft: '15%' }}
            className='modalClass items-start flex overflow-x-hidden overflow-y-auto lg:pt-4 fixed inset-0  '
          >
            <div className='relative w-auto lg:w-8/12 mx-auto max-w-5xl  '>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none h-modal '>
                {/*header*/}
                <div className='h-full'>
                  <div className='flex items-start justify-between px-8 pb-2 pt-8 rounded-t h-2/12 border-b border-linecolor'>
                    <h3 className='text-1xl font-display font-bold text-charcoal'>
                      Participants
                    </h3>
                    <button
                      className='p-1 ml-auto  text-black float-right text-3xl leading-none font-semibold '
                      onClick={() => props.setShowMembersModal(false)}
                    >
                      <span className='text-charcoal h-6 w-6 text-2xl  hover:text-reddark'>
                        ×
                      </span>
                    </button>
                  </div>

                  {/* </div>  */}
                  <div className=' overflow-y-auto h-10/12 '>
                    <div className='px-4 sm:px-12 md:px-12 pb-8  relative'>
                      <div className='pt-6'>
                        {memberData.length > 0 ? (
                          memberData.map((value, key) => (
                            <MemberCard key={value._id} member={value} />
                          ))
                        ) : (
                          <NoMembers />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-60 modalOverlayClass fixed inset-0 bg-black  flex justify-center '></div>
        </>
      ) : null}
    </>
  );
};

const NoMembers = () => {
  return (
    <div className='max-w-screen-sm sm:text-center sm:mx-auto mt-8'>
      <h2 className='mb-4 font-sans text-2xl font-bold tracking-tight text-headingtext sm:text-xl sm:leading-none'>
        {`No members registered yet`}
      </h2>
    </div>
  );
};

const MemberCard = ({ member }) => {
  const history = useHistory();
  return (
    <div
      className={`relative p-px mb-4 overflow-hidden transition duration-300 transform border-l-4 border-white group hover:shadow-sm hover:border-darkerblue `}
    >
      <div>
        <div className='relative flex flex-col h-full p-2 bg-white rounded-sm lg:items-center lg:flex-row lg:justify-between w-full '>
          <div className='flex flex-row justify-start '>
            <div className='flex items-center justify-between'>
              <div className='flex'>
                <img
                  src={
                    member?.profilePicture?.publicUrl ||
                    `https://ui-avatars.com/api/?name=${member[0]?.firstname}+${member[0]?.lastname}&bold=true`
                  }
                  alt='Event Thumb'
                  className='object-cover w-10 h-10 mr-1 lg:mr-4 rounded-full shadow'
                />
              </div>
            </div>
            <div className='flex flex-col justify-center lg flex-grow text-charcoal'>
              <div>
                <h2 className='text-sm font-body text-charcoal capitalize'>
                  {`${member[0]?.firstname} ${member[0]?.lastname}`}
                </h2>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 gap-5'>
            <button
              onClick={() => history.push(`/users/profile/${member[0]._id}`)}
              className='inline-flex items-center font-medium text-sm sm:max-w-full justify-center h-8 w-30 px-2 sm:mr-0  border border-darkerblue tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
            >
              Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MemberChatCard = ({ member, setChatMembers, chatMembers }) => {
  return (
    <div
      className={`relative p-px mb-4 overflow-hidden transition duration-300 transform border-l-4 border-white group hover:shadow-sm hover:border-darkerblue `}
    >
      <div>
        <div className='relative flex flex-col h-full p-2 bg-white rounded-sm lg:items-center lg:flex-row lg:justify-between w-full '>
          <div className='flex flex-row justify-start '>
            <div className='flex items-center justify-between'>
              <div className='flex'>
                <img
                  src={
                    member?.profilePicture?.publicUrl ||
                    `https://ui-avatars.com/api/?name=${member[0]?.firstname}+${member[0]?.lastname}&bold=true`
                  }
                  alt='Event Thumb'
                  className='object-cover w-10 h-10 mr-1 lg:mr-4 rounded-full shadow'
                />
              </div>
            </div>
            <div className='flex flex-col justify-center lg flex-grow text-charcoal'>
              <div>
                <h2 className='text-sm font-body text-charcoal capitalize'>
                  {`${member[0]?.firstname} ${member[0]?.lastname}`}
                </h2>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 gap-5'>
            <Checkbox
              onChange={(e) => setChatMembers([...chatMembers, e.target.value])}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }}
              value={`${member[0]._id}`}
              id={`${member[0]._id}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupChat;
