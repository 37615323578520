import { StarRate } from '@material-ui/icons';
import React from 'react';
import Truncate from 'react-truncate';
import Thumbnail from '../assets/landingX.jpeg';

const LibraryContentCard = ({ content, type }) => {
  let action;
  if (type === 'Article') action = 'read';
  if (type === 'Video') action = 'watch';
  if (type === 'Book') action = 'open';
  if (type === 'Course') action = 'course/review';
  if (type === 'OpenLib') action = 'link';

  return (
    <a href={`/${type.toLowerCase()}/${action}/${content?._id}` || '#'}>
      <div className='inline-block overflow-hidden duration-300 transform bg-white rounded shadow-sm hover:-translate-y-2 border-b-4 border-white hover:border-darkerblue m-3 w-11/12 p-2'>
        <img
          src={content?.thumbnailFile?.publicUrl || Thumbnail}
          alt={content?.title}
          className='object-cover w-full h-48 rounded'
        />
        <div className='flex flex-col h-36'>
          <div className='flex flex-col flex-grow justify-between  rounded-b'>
            <div className='py-5 px-2 flex flex-col'>
              {type === 'Course' && (
                <div className='flex flex-row w-full justify-between px-2 mb-5 font-semibold text-sm text-gray'>
                  {type === 'Session' ? (
                    <p className='capitalize'>{content?.date || '- - -'}</p>
                  ) : (
                    <p className=''>
                      {content?.noOfStudents || 200} {`students`}
                    </p>
                  )}

                  <p>
                    <div className=' text-sm'>
                      {content?.duration || 5} {'hrs'}
                    </div>
                  </p>
                </div>
              )}
              <h6 className='font-semibold leading-5 capitalize'>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {content?.title}
                </Truncate>
              </h6>
            </div>
            {type === 'Book' ? (
              <div className='flex flex-col w-full justify-between px-2 mb-5 font-semibold text-sm text-gray'>
                <p className='capitalize'>{content?.author}</p>
                <p>
                  <div className='text-darkerblue text-base '>
                    <span className=''>{content?.publisher || 'TBD'}</span>
                  </div>
                </p>
              </div>
            ) : (
              <div className='flex flex-row w-full justify-between px-2 mb-5 font-semibold text-sm text-gray'>
                <p className='capitalize'>{content?.author}</p>
                <p>
                  {type === 'OpenURL' ? (
                    <div className='text-darkerblue text-base '>
                      <span className=''>{content?.venue || 'TBD'}</span>
                    </div>
                  ) : (
                    <div className='text-starsorange text-sm'>
                      <StarRate fontSize='small' />
                      <span className=' mx-1  font-semibold text-sm text-starsorange '>
                        {parseFloat(content?.rating || 0).toFixed(1)}
                      </span>
                      <span className='text-linecolor'>
                        ({content?.views || 0})
                      </span>
                    </div>
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

export default LibraryContentCard;
