import * as invitationTypes from '../types/invitations';
import * as fetchFunctions from '../../api/index';
import invitationEndpoints from '../endpoints/invitations';

export const createInvitation = (payload) => async (dispatch, getState) => {
  dispatch({
    type: invitationTypes.INVITATION_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    invitationEndpoints.save_invitation,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: invitationTypes.INVITATION_SUCCESS,
      payload: {
        data: result.response.newInvitation,
        status: true,
        message: 'Invitation saved',
      },
    });
    setTimeout(function () {
      dispatch({
        type: invitationTypes.INVITATION_RESET,
      });
    }, 100);
  } else {
    dispatch({
      type: invitationTypes.INVITATION_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const checkInvitation =
  ({ invitationID }) =>
  async (dispatch, getState) => {
    dispatch({
      type: invitationTypes.INVITATION_LOADING,
    });
    const { token } = getState().user;

    const result = await fetchFunctions.getData(
      `${invitationEndpoints.get_invitation}/${invitationID}`,
      token,
    );
    if (result.success) {
      dispatch({
        type: invitationTypes.GET_INVITATION,
        payload: result.data,
      });
      dispatch({
        type: invitationTypes.INVITATION_RESET,
      });
    } else {
      dispatch({
        type: invitationTypes.INVITATION_ERROR,
        payload: {
          error: true,
          errorMessage: result?.error,
        },
      });
    }
  };
