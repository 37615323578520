import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomAlert from '../components/CustomAlert';
import ModalWrapper from '../components/ModalWrapper';

import * as userActions from '../redux/actions/users';
import * as claimActions from '../redux/actions/claim';

const UserPointsModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user);
  const claim = useSelector((state) => state.claim);
  let totalArr = [];
  let activities = [];
  const [enterClaim, setEnterClaim] = useState(false);
  const [phone, setPhone] = useState();
  const [institution, setInstitution] = useState();
  const [redeemableAs, setRedeemableAs] = useState();
  props?.points?.points.map((value) => totalArr.push(value?.point));
  props?.points?.points.map((value) => activities.push(value?._id));

  function getSum(total, num) {
    return total + Math.round(num);
  }

  function claimPoints(amount) {
    if (amount < 2000) {
      CustomAlert({
        alertType: 'error',
        alertMessage:
          'Oops.. You can only redeem amount above 2000. Collect More Points',
      });
    } else {
      setEnterClaim(true);
    }
  }

  const handleClaim = async (e) => {
    e.preventDefault();

    const payload = {
      userId: user?._id,
      institution: institution,
      redeemableAs: redeemableAs,
      phone: phone,
      activities: activities,
      amount: totalArr.reduce(getSum, 0),
    };
    dispatch(claimActions.addClaim(payload));
    setTimeout(function () {
      dispatch(userActions.getUserPoints({ userId: user?._id }));
    }, 1000);
  };

  useEffect(() => {
    if (claim?.success?.status) {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Success: Your claim has been submitted',
      });
    }
  }, [claim]);

  return (
    <>
      {props.showUserPointsModal == null ? ( // To disable to the point modal
        <>
          <ModalWrapper
            title={'My Earned Points'}
            closeModal={() => props.setShowUserPointsModal(false)}
            bodyContent={
              <div className='relative'>
                <table className='min-w-full bg-white '>
                  <thead>
                    <tr className='w-full h-9 border-linecolor  border-b py-8'>
                      <th
                        colSpan='2'
                        className='text-gray dark:text-gray-400 font-bold text-left text-base tracking-normal leading-4'
                      >
                        Activity
                      </th>
                      <th className='text-gray dark:text-gray-400 font-bold text-left text-base tracking-normal leading-4'>
                        Points
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props?.points?.points?.length > 0 ? (
                      <>
                        {props?.points?.activityPoints.map((value, key) => {
                          return (
                            <tr
                              key={value?._id}
                              className='w-full h-9 border-linecolor  border-b py-8'
                            >
                              <td
                                style={{ width: '50%' }}
                                className='text-gray text-left text-base '
                              >
                                {value?.activity}
                              </td>
                              <td
                                style={{ width: '40%' }}
                                className='text-gray text-left text-base '
                              >
                                {
                                  props?.points?.points.filter(
                                    (item) =>
                                      item?.activityPointId ===
                                      value?.activityPointId,
                                  ).length
                                }
                              </td>
                              <td className='text-gray text-left text-base '>
                                {parseInt(
                                  props?.points?.points.filter(
                                    (item) =>
                                      item?.activityPointId ===
                                      value?.activityPointId,
                                  ).length,
                                ) *
                                  parseInt(
                                    props?.points?.points.find(
                                      (item) =>
                                        item?.activityPointId ===
                                        value?.activityPointId,
                                    )?.point,
                                  ) || 0}
                              </td>
                            </tr>
                          );
                        })}

                        <tr className='w-full h-9 border-linecolor  border-b py-8'>
                          <th
                            colSpan='2'
                            className='text-gray dark:text-gray-400 font-bold text-left text-base tracking-normal leading-4'
                          >
                            Total
                          </th>
                          <th className='text-gray dark:text-gray-400 font-bold text-left text-base tracking-normal leading-4'>
                            {totalArr.reduce(getSum, 0)}
                          </th>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan='2'
                          style={{ paddingTop: '20px' }}
                          className='text-gray dark:text-gray-400 font-bold text-center text-base tracking-normal leading-4'
                        >
                          Collect More Points
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div style={{ textAlign: 'center', paddingTop: 30 }}>
                  {props?.points?.points?.length > 0 ? (
                    enterClaim ? (
                      <form onSubmit={handleClaim}>
                        <input
                          required
                          type='tel'
                          value={institution}
                          onChange={(e) => setInstitution(e.target.value)}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-70'
                          placeholder='Enter Institution'
                        />

                        <input
                          required
                          type='tel'
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-70'
                          placeholder='Enter Phone Number'
                        />

                        <select
                          onChange={(e) => setRedeemableAs(e.target.value)}
                          value={redeemableAs}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-70'
                        >
                          <option value=''>REDEEMABLE AS</option>
                          <option value='Cash'>Cash</option>
                          <option value='Airtime'>Airtime</option>
                          <option value='Internet_Data'>Internet Data</option>
                        </select>

                        <div className=''>
                          <br />
                          <button
                            className='lg:w-40 sm:w-full px-10 h-12 falsefocus:shadow-outline  bg-darkerblue text-white text-xl rounded-md shadow hover:bg-navyblue outline-none focus:outline-none  ease-linear transition-all duration-150'
                            type='submit'
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    ) : (
                      <button
                        onClick={() => claimPoints(totalArr.reduce(getSum, 0))}
                        className='lg:w-40 sm:w-20 h-12 falsefocus:shadow-outline  bg-darkerblue text-white text-xl px-2 py-2 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                        type='submit'
                      >
                        Redeem
                      </button>
                    )
                  ) : null}
                </div>
              </div>
            }
          />
        </>
      ) : null}
    </>
  );
};

export default UserPointsModal;
