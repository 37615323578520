import React from 'react';
import { randomSelector } from './randomSelector';

const DefaultThumbnail = ({ text }) => {
  const color = randomSelector();
  return (
    <div
      style={{
        background: `linear-gradient(90deg, ${color.primary} 0%,${color.secondary} 100%)`,
      }}
      className='w-full h-full rounded shadow-md flex flex-col justify-center '
    >
      <div className=' mx-auto  px-8'>
        <h2 className=' text-center justify-center text-white font-semibold text-2xl '>
          {text}
        </h2>
      </div>
    </div>
  );
};

export default DefaultThumbnail;
