import React from 'react';

function MentorCard(props) {
  return (
    <div className='bg-white rounded-md shadow-md text-center w-64 h-52 mr-6 '>
      <div className='bg-gray w-16 h-16 ml-24 mt-6 mb-3.5 rounded-full'></div>
      <h2 className='text-xl m-auto mb-2 font-medium text-gray-700 font-body'>
        {props.name}
      </h2>
      <p className='text-blue-500 block mb-3 font-body'>{props.course}</p>

      <i
        className='m-auto fa fa-star text-starsorange mb-6'
        aria-hidden='true'
      ></i>
      <span className=' ml-1 font-semibold text-base text-starsorange mb-6'>
        {props.star}
      </span>
    </div>
  );
}

export default MentorCard;
