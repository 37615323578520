const base = `${process.env.REACT_APP_BACKEND_URL}`;
const endpoints = {
  create_sessions: `${base}/sessions`,
  get_all_sessions: `${base}/sessions/`,
  get_all_unauth_sessions: `${base}/sessions/all`,
  get_my_sessions: `${base}/sessions/mentor`,
  specific_session: `${base}/sessions/session`,
  register_for_session: `${base}/user/event/register`,
};

export default endpoints;
