import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Route from './RouteWrapper';
import history from '../services/history';
import Login from '../views/Login';
import Feeds from '../views/Feeds';
import Pull_Down_Feeds from '../views/Pull_Down_Feeds';
import Signup from '../views/Signup';
import Courses from '../views/Courses';
// import Dashboard from '../views/Dashboard';
import SearchPage from '../views/SearchPage';
import ComingSoon from '../views/ComingSoon';
import JobSearch from '../views/JobSearch';
import Upskilling from '../views/Upskilling';
import MentorsPage from '../views/MentorsPage';
import SessionsPage from '../views/SessionsPage';
import AllArticles from '../views/AllArticles';
import LandingPage from '../views/LandingPage';
import ArticleRead from '../views/ArticleRead';
import CareerGuidance from '../views/CareerGuidance';
import UpdateProfile from '../views/UpdateProfile';
import SessionCreatePage from '../views/SessionCreatePage';
import MySessions from '../views/MySessions';
import SessionViewOne from '../views/SessionViewOne';
import VideoWatchPage from '../views/VideoWatchPage';
import CourseViewPage from '../views/CourseViewPage';
import UsersProfileDetails from '../views/UsersProfileDetails';
import ArticlesMyArticles from '../views/ArticlesMyArticles';
import PendingClaims from '../views/PendingClaims';
import OnboardingInstitute from '../views/OnboardingInstitute';
import InstitutionHome from '../views/InstitutionHome';
import InstitutionAbout from '../views/InstitutionAbout';
import InstitutionFeeds from '../views/InstitutionFeeds';
import ViewSharedImage from '../views/ViewSharedImage';
import { OnboardingInterests, OnboardingGroups } from '../views/Onboarding';
import YourMentors from '../views/YourMentors';
import Members from '../views/Members';
import YourMentorsMore from '../views/InstitutionMentorsFindoutMore';
import NotFound from '../views/404Page';
import BookSession from '../views/BookSession';
import RegisterSession from '../views/RegisterSession';
import AboutUs from '../views/AboutUs';
import AllVideos from '../views/AllVideos';
import ChatRoom from '../views/ChatRoom';
import Invitation from '../views/Invitation';
import AllIndividualCourses from '../views/AllIndividualCourses';
import VideosMyVideos from '../views/VideosMyVideos';
import IndividualCoursesMyIndividualCourses from '../views/IndividualCoursesMyIndividualCourses';
import ArticleOverview from '../views/ArticleOverview';
import InstitutionFindOutMore from '../views/InstitutionFindOutMore';
import Events from '../views/Events';
import InstitutionGroups from '../views/InstitutionGroups';
import UserGroups from '../views/UserGroups';
import Library from '../views/Library';
import InstitutionVideos from '../views/InstitutionVideos';
import InstitutionArticles from '../views/InstitutionArticles';
import InstitutionBooks from '../views/InstitutionBooks';
import InstitutionOpenLibraryResources from '../views/InstitutionOpenLibraryResources';
import InstitutionCourses from '../views/InstitutionCourses';
import SignupIndividual from '../views/SignupIndividual';
import SignupInstitution from '../views/SignupInstitution';
import PresentationPage from '../views/PresentationPage';
import InstitutionsPage from '../views/InstitutionsPage';
import IndividualAbout from '../views/IndividualAbout';
import Affilliations from '../views/Affilliations';
import ContactUs from '../views/ContactUs';
import SubscribedSessions from '../views/SessionsRegisteredFor';
import Explore from '../components/Explore';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

export const browserHistory = createBrowserHistory();

browserHistory.listen((location) => {
  ReactGA.pageview(location.pathname);
});

export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        {/* Universal Pages */}
        <Route exact path='/' component={LandingPage} />
        <Route exactcon path='/login' component={Login} />
        <Route
          exact
          path='/confirm/login/:unconfirmedUserID'
          component={Login}
        />
        <Route exact path='/invitation/login/:groupID' component={Login} />
        <Route exact path='/signup' component={Signup} />
        <Route exact path='/signup/individual' component={SignupIndividual} />
        <Route exact path='/signup/institution' component={SignupInstitution} />
        <Route exact path='/landingPage' component={LandingPage} />
        <Route exact path='/careerguidance' component={CareerGuidance} />
        <Route exact path='/upskilling' component={Upskilling} />
        <Route exact path='/article/read/:articleID' component={ArticleRead} />
        <Route exact path='/session/create' component={SessionCreatePage} />
        <Route exact path='/session/all' userPrivate component={SessionsPage} />
        <Route exact path='/invitation/:invitationID' component={Invitation} />
        <Route
          exact
          path='/sessions/mylist'
          userPrivate
          component={MySessions}
        />
        <Route
          exact
          path='/sessions/subscribed'
          userPrivate
          component={SubscribedSessions}
        />
        ;
        <Route exact path='/session/:sessionID' component={SessionViewOne} />
        <Route exact path='/search' component={SearchPage} />
        <Route exact path='/explore' component={Explore} />
        <Route exact path='/aboutus' component={AboutUs} />
        <Route path='/mentors/more/:mentorID' component={UsersProfileDetails} />
        <Route
          path='/users/profile/:mentorID'
          component={UsersProfileDetails}
        />
        <Route exact path='/video/watch/:videoID' component={VideoWatchPage} />
        <Route exact path='/ChatRoom/:groupID' component={ChatRoom} />
        <Route exact path='/course/view/:courseID' component={CourseViewPage} />
        <Route exact path='/comingsoon/:pageType' component={ComingSoon} />
        <Route exact path='/comingsoon' component={ComingSoon} />
        <Route exact path='/JobSearch' component={JobSearch} />
        <Route exact path='/image/:imageID' component={ViewSharedImage} />
        <Route
          exact
          path='/joinsession/:sessionID'
          userPrivate
          component={RegisterSession}
        />
        <Route exact path='/slides' component={PresentationPage} />
        {/* ###########################*/}
        {/* User Pages*/}
        {/* <Route exact path='/dashboard'>
          <Dashboard />
        </Route> */}
        <Route
          exact
          path='/onboardingInterests'
          userPrivate
          component={OnboardingInterests}
        />
        <Route
          exact
          path='/onboardingGroups'
          userPrivate
          component={OnboardingGroups}
        />
        <Route exact path='/feeds' userPrivate component={Feeds} />
        <Route
          exact
          path='/pull_down_feeds'
          userPrivate
          component={Pull_Down_Feeds}
        />
        <Route exact path='/courses' userPrivate component={Courses} />
        <Route exact path='/courses/:course' userPrivate component={Courses} />
        <Route exact path='/articles' userPrivate component={AllArticles} />
        <Route exact path='/contact_us' component={ContactUs} />
        <Route
          exact
          path='/videos/mylist'
          userPrivate
          component={VideosMyVideos}
        />
        <Route
          exact
          path='/IndividualCourses/mylist'
          userPrivate
          component={IndividualCoursesMyIndividualCourses}
        />
        <Route exact path='/videos' userPrivate component={AllVideos} />
        <Route
          exact
          path='/IndividualCourses'
          userPrivate
          component={AllIndividualCourses}
        />
        <Route exact path='/mentors' systemPrivate component={MentorsPage} />
        <Route
          exact
          path='/institutions'
          systemPrivate
          component={InstitutionsPage}
        />
        <Route
          exact
          path='/affiliations/mylist'
          userPrivate
          component={Affilliations}
        />
        <Route exact path='/groups' userPrivate component={UserGroups} />
        <Route
          exact
          path='/booksession/:id'
          userPrivate
          component={BookSession}
        />
        <Route
          exact
          path='/updateprofile'
          // userPrivate
          component={UpdateProfile}
        />
        <Route
          exact
          path='/articles/mylist'
          userPrivate
          component={ArticlesMyArticles}
        />
        <Route
          exact
          path='/pending_claims'
          userPrivate
          component={PendingClaims}
        />
        {/* ###########################*/}
        {/* Institution Pages */}
        <Route
          exact
          path='/onboarding/institute'
          component={OnboardingInstitute}
          institutionPrivate
        />
        <Route
          exact
          path='/institution/home'
          institutionPrivate
          component={InstitutionHome}
        />
        <Route
          exact
          path='/institution/about'
          component={InstitutionAbout}
          institutionPrivate
        />
        <Route
          exact
          path='/institution/events'
          component={Events}
          institutionPrivate
        />
        <Route
          exact
          path='/institution/library'
          component={Library}
          institutionPrivate
        />
        <Route
          exact
          path='/institution/more/:institutionID'
          component={InstitutionFindOutMore}
        />
        <Route
          exact
          path='/institution/feeds'
          institutionPrivate
          component={InstitutionFeeds}
        />
        <Route
          exact
          path='/institution/mentors'
          institutionPrivate
          component={YourMentors}
        />
        <Route
          exact
          path='/institution/members'
          institutionPrivate
          component={Members}
        />
        <Route
          exact
          path='/institution/mentor/:mentorID'
          institutionPrivate
          component={YourMentorsMore}
        />
        <Route
          exact
          path='/institution/videos'
          institutionPrivate
          component={InstitutionVideos}
        />
        <Route
          exact
          path='/institution/articles'
          component={InstitutionArticles}
          institutionPrivate
        />
        <Route
          exact
          path='/institution/books'
          institutionPrivate
          component={InstitutionBooks}
        />
        <Route
          exact
          path='/institution/openlibrary'
          component={InstitutionOpenLibraryResources}
          institutionPrivate
        />
        <Route
          exact
          path='/institution/courses'
          component={InstitutionCourses}
          institutionPrivate
        />
        <Route
          exact
          path='/institution/groups'
          component={InstitutionGroups}
          institutionPrivate
        />
        <Route exact path='/profile' component={IndividualAbout} />
        <Route
          exact
          path='/article/overview/:articleID'
          component={ArticleOverview}
        />
        <Route component={NotFound} />
        {/* ###########################*/}
      </Switch>
    </Router>
  );
}
