import * as postTypes from '../types/post';

const initialState = {
  data: [],
  myPosts: [],
  loading: false,
  error: false,
  errorMessage: null,
  loggedIn: false,
  success: {
    status: false,
    message: 'All set!',
  },
};

const postReducer = (state = initialState, action) => {
  switch (action.type) {
    case postTypes.POST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case postTypes.POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case postTypes.UPDATE_POST:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        update_success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case postTypes.POST_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case postTypes.POST_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        update_success: {
          status: false,
          message: 'All set!',
        },
        success: {
          status: false,
          message: 'All set!',
        },
      };
    case postTypes.ALL_POSTS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        data: action.payload,
      };
    case postTypes.MY_POSTS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        myPosts: action.payload,
      };
    default:
      return state;
  }
};

export default postReducer;
