import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Notifications } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MultiSelect from '@kenshooui/react-multi-select';
import '@kenshooui/react-multi-select/dist/style.css';
import Loading from '../components/Loading';
import Nav from '../components/NavBarR';

// Actions
import * as userActions from '../redux/actions/users';
import * as discussionGroupsActions from '../redux/actions/discussiongroups';
import * as institutionActions from '../redux/actions/institution';

import { ItemOption, SelecteItemOption } from '../components/Multiselect';
import TagsInput from '../components/TagsInput';
import { DropzoneArea } from 'material-ui-dropzone';
import ButtonLoader from '../components/ButtonLoader';
import CustomAlert from '../components/CustomAlert';
import ModalWrapper from '../components/ModalWrapper';
import RequestCard from '../components/RequestCard';
import NoRequests from '../components/NoRequests';
import NoContentPlaceholder from '../components/NoContentPlaceholder';

const PageBody = () => {
  const dispatch = useDispatch();
  const { loading, success, error, errorMessage } = useSelector(
    (state) => state?.discussiongroups,
  );
  const PageLoading = useSelector((state) => state?.institution.loading);
  const user = useSelector((state) => state?.user?.user);

  const discussiongroups =
    useSelector((state) => state?.discussiongroups?.discussionGroupsList) || [];

  const AllRequestsList = useSelector(
    (state) => state?.discussiongroups?.joinRequestsList,
  );

  // Pending Join requests
  const joinRequestsList = AllRequestsList?.filter(
    (request) => request.status === 'pending',
  );

  // console.log(`joinRequestsList`, joinRequestsList);
  const [showCreateGroupsModal, setShowCreateGroupsModal] = useState(false);

  const users = useSelector((state) => state?.user?.data);

  const userList = users?.filter(
    (value) => value.usercategory[0] !== 'institution',
  );

  const institution = useSelector((state) => state?.institution)?.data;
  const institute = institution.find(
    (inst) => inst.admins[0] === `${user._id}`,
  );

  const institutionID = institute?._id;

  useEffect(() => {
    dispatch(discussionGroupsActions.getAllDiscussionGroups({}));
    dispatch(institutionActions.getInstitutions({}));
    dispatch(discussionGroupsActions.getAllJoinRequests(user._id));

    dispatch(userActions.getUsers());
  }, []);

  const filteredGroups = discussiongroups.filter((group) =>
    group.admins.includes(user._id),
  );

  return (
    <>
      <Nav
        groupsActive={true}
        userType='institution'
        institutions={institution}
      />
      {PageLoading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div
          className={`bg-lightgraybg ${
            filteredGroups.length < 6 ? 'h-screen' : 'h-full'
          }`}
        >
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
              <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>
                    Groups ({filteredGroups?.length || '0'})
                  </span>
                </h2>

                <div className='mb-10 text-center md:mb-4 lg:mb-4 grid grid-cols-1 lg:grid-cols-1 gap-4'>
                  <button
                    className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'
                    onClick={() => setShowCreateGroupsModal(true)}
                  >
                    <Add />
                    <span className='ml-2'>Create Groups</span>
                  </button>
                </div>
              </div>
              <GroupsSection
                groupList={filteredGroups}
                users={userList}
                user={user}
                loading={loading}
                institutionID={institutionID}
                success={success}
                joinRequests={joinRequestsList}
              />
            </div>
          </div>
          <CreateGroupsModal
            setShowCreateGroupsModal={setShowCreateGroupsModal}
            showCreateGroupsModal={showCreateGroupsModal}
            userList={userList}
            loading={loading}
            institutionID={institutionID}
            user={user}
            success={success}
            error={error}
            errorMessage={errorMessage}
          />
        </div>
      )}
    </>
  );
};

const GroupsSection = (props) => {
  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
  const [showJoinRequestsModal, setShowJoinRequestsModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.success.message === 'Request to join submitted') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Member added to group successfully.',
      });
      dispatch(discussionGroupsActions.getAllDiscussionGroups({}));
      dispatch(discussionGroupsActions.getAllJoinRequests());
    }
  }, [props.success]);
  return (
    <>
      <div className='flex flex-col mb-6 lg:justify-between md:mb- shadow '>
        <div className='grid grid-cols-1 gap-6 row-gap-4 mb-8'>
          <div className=' pb-10 mt-6 p-4'>
            {props.groupList.length > 0 ? (
              <div className='grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3'>
                {props.groupList?.map((value, key) => (
                  <GroupsCard
                    users={props.users}
                    group={value}
                    key={key}
                    selectedGroup={selectedGroup}
                    requests={props.joinRequests}
                    setShowInviteMemberModal={() => {
                      setShowInviteMemberModal(true);
                      setSelectedGroup(value);
                    }}
                    setShowJoinRequestsModal={() => {
                      setShowJoinRequestsModal(true);
                      setSelectedGroup(value);
                    }}
                  />
                ))}
              </div>
            ) : (
              <NoContentPlaceholder content='Groups' generic />
            )}
          </div>
        </div>
        <InviteMemberModal
          selectedGroup={selectedGroup}
          setShowInviteMemberModal={setShowInviteMemberModal}
          showInviteMemberModal={showInviteMemberModal}
          userList={props.users}
          loading={props.loading}
          adminId={props.user._id}
          success={props.success}
        />
        <ViewJoinRequests
          setShowJoinRequestsModal={setShowJoinRequestsModal}
          showJoinRequestsModal={showJoinRequestsModal}
          userList={props.users}
          loading={props.loading}
          selectedGroup={selectedGroup}
          requests={props.joinRequests}
          adminId={props.user._id}
        />
      </div>
    </>
  );
};

const GroupsCard = ({
  group,
  setShowInviteMemberModal,
  setShowJoinRequestsModal,
  users,
  requests,
}) => {
  const joinRequests = requests?.filter(
    (value) => value.nameOfWhatToJoin === group?.groupName,
  );
  const overviewProfilePics = [];
  if (group?.members.length > 5) {
    for (let index = 0; index < 5; index++) {
      overviewProfilePics.push(group?.members[index]);
    }
  } else {
    group?.members.map((value) => overviewProfilePics.push(value));
  }

  // This extracts the group members from users list

  const filteredList = users?.filter((user) =>
    overviewProfilePics.includes(user._id),
  );

  return (
    <div className='px-5 py-6 duration-300 transform bg-white border border-linecolor rounded-lg hover:-translate-y-2'>
      <div className='flex flex-col mb-4 justify-between h-full'>
        <h6 className='mb-2 font-semibold leading-5 text-xl text-charcoal'>
          {group?.groupName}
        </h6>
        <p className='text-headingtext text-base'>{`${
          group?.members.length
        } member${group?.members.length === 1 ? '' : 's'}`}</p>

        <div className='mt-4'>
          <div className='flex items-center pl-4'>
            {filteredList.map((pic, key) => (
              <img
                key={pic._id}
                src={
                  pic?.profilePicture?.publicUrl ||
                  `https://ui-avatars.com/api/?background=random&name=${pic?.firstname}+${pic?.lastname}&bold=true`
                }
                className='shadow-md w-8 h-8 object-cover rounded-full -ml-4 ring-2 ring-white'
                alt='Profile-img'
              />
            ))}
          </div>
        </div>
        <div className='mt-4 flex flex-row justify-between'>
          <span
            onClick={() => setShowJoinRequestsModal(true)}
            className='text-4xl relative cursor-pointer text-darkerblue bg-none hover:text-navyblue inline-flex items-center justify-center mr-8 lg:mr-4'
          >
            <Notifications fontSize='inherit' />
            {joinRequests?.length > 0 ? (
              <span className='absolute top-3 right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-buttonRed rounded-full'>
                {joinRequests?.length}
              </span>
            ) : null}
          </span>
          <button
            className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-darkerblue border-2 border-darkerblue  transition duration-200 rounded-md shadow-md md:w-auto bg-none hover:border-navyblue hover:bg-navyblue hover:text-white  focus:shadow-outline focus:outline-none'
            onClick={() => setShowInviteMemberModal(true)}
          >
            <Add />
            <span className='ml-2'>Add Members</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const CreateGroupsModal = ({
  setShowCreateGroupsModal,
  showCreateGroupsModal,
  userList,
  user,
  loading,
  institutionID,
  success,
  error,
  errorMessage,
}) => {
  const dispatch = useDispatch();
  const suggestions = [];

  userList.forEach((element, index) => {
    suggestions.push({
      id: element?._id,
      text: `${element?.firstname} ${element?.lastname}`,
      image: element?.profilePicture?.publicUrl,
    });
  });

  const [members, setMembers] = useState([]);
  const [profilePicture, setProfilePicture] = useState('');
  const initialValues = {
    groupName: '',
    privacy: '',
    members: [],
    profilePicture: profilePicture,
  };

  const validationSchema = Yup.object({
    groupName: Yup.string().required('Group name is required'),
    privacy: Yup.string().required('Privacy is required'),
  });
  const onSubmit = (values, onSubmitProps) => {
    const tagsText = members.map((tag) => tag.id);
    const payload = {
      ...values,
      // profilePicture: profilePicture,
      members: tagsText,
      admins: [user._id],
    };

    dispatch(discussionGroupsActions.createDiscussionGroup(payload));
    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
    setMembers([]);
  };

  useEffect(() => {
    if (success.message === 'Discussion group Successfully created') {
      dispatch(discussionGroupsActions.getAllDiscussionGroups({}));
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Group created successfully',
      });
    }
    if (error && errorMessage === 'Group creation failed') {
      dispatch(discussionGroupsActions.getAllDiscussionGroups({}));

      CustomAlert({
        alertType: 'error',
        alertMessage: 'Group creation failed, try again.',
      });
    }
    setShowCreateGroupsModal(false);
  }, [success, errorMessage]);

  return (
    <>
      {showCreateGroupsModal ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ touched, errors, getFieldProps, isValid, isSubmitting }) => (
              <Form>
                <ModalWrapper
                  title={'Create New Group'}
                  closeModal={() => setShowCreateGroupsModal(false)}
                  bodyContent={
                    <>
                      <div className='relative'>
                        <div className=''>
                          <div className=''>
                            <div>
                              <label className='font-semibold text-textparagraph text-sm'>
                                GROUP NAME
                              </label>
                              <input
                                type='text'
                                className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                                id='groupName'
                                name='groupName'
                                {...getFieldProps('groupName')}
                              />
                              {touched.groupName && errors.groupName ? (
                                <span className='text-sm text-red'>
                                  {errors.groupName}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className='pt-6'>
                            <label className='font-semibold text-textparagraph text-sm'>
                              CHOOSE PRIVACY
                            </label>
                            <select
                              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='privacy'
                              name='privacy'
                              {...getFieldProps('privacy')}
                            >
                              <option value='select'>Select a type</option>
                              <option value='Public'>Public</option>
                              <option value='Private'>Private</option>
                            </select>
                            {touched.privacy && errors.privacy ? (
                              <span className='text-sm text-red'>
                                {errors.privacy}
                              </span>
                            ) : null}
                          </div>
                          <div className='pt-6 '>
                            <label className='font-semibold text-textparagraph text-sm'>
                              INVITE MEMBERS (Optional)
                            </label>
                            <TagsInput
                              tags={members}
                              noSpaceDelimiter={true}
                              setTags={setMembers}
                              suggestions={suggestions || []}
                              placeholder={`Enter a comma separated list`}
                            />
                          </div>
                          <div className='pt-6 '>
                            <h3 className='font-body text-textparagraph font-semibold'>
                              GROUP PROFILE PICTURE
                            </h3>

                            <p className='font-body pt-3.5 pb-3 text-textparagraph font-normal'>
                              Show us how you look like! Image should be at
                              least 400x400 pixels big.
                            </p>

                            <div>
                              <DropzoneArea
                                filesLimit={1}
                                showAlerts={false}
                                maxFileSize={5000000}
                                acceptedFiles={['image/*']}
                                onChange={(files) => setProfilePicture(files)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  footer={true}
                  footerContent={
                    <>
                      <button
                        className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                        type='submit'
                        disabled={!isValid || loading}
                      >
                        {loading ? <ButtonLoader /> : 'Save'}
                      </button>
                    </>
                  }
                />
              </Form>
            )}
          </Formik>
        </>
      ) : null}
    </>
  );
};

const InviteMemberModal = ({
  setShowInviteMemberModal,
  showInviteMemberModal,
  userList,
  loading,
  adminId,
  selectedGroup,
  success,
}) => {
  const dispatch = useDispatch();
  const members = [];

  const excludeMembers = [];

  selectedGroup?.members?.forEach((member) => excludeMembers.push(member));

  // This extracts the institution created mentors from the other mentors self registered
  const filteredList = userList?.filter(
    (mentor) => !excludeMembers.includes(mentor._id),
  );

  filteredList.forEach((element, index) => {
    members.push({
      id: index,
      user_id: element?._id,
      label: `${element?.firstname} ${element?.lastname}`,
      image: element?.profilePicture?.publicUrl,
    });
  });

  const [selectedItems, setSeletecteItems] = useState([]);

  const multiSelectMessages = {
    searchPlaceholder: `Search for members..`,
    noItemsMessage: 'No selections...',
    noneSelectedMessage: 'No selection',
    selectedMessage: 'selected',
    selectAllMessage: 'Select All',
    clearAllMessage: 'Clear',
  };

  const handleMentorInvitation = (e) => {
    e.preventDefault();

    selectedItems.forEach((member) => {
      dispatch(
        discussionGroupsActions.joinDiscussionGroup({
          userId: member.user_id,
          fullName: member.label,
          whatToJoin: 'discoussiongroup',
          idOfWhatToJoin: selectedGroup._id,
          nameOfWhatToJoin: selectedGroup.groupName,
          toJoinAs: 'member',
          canBeAcceptedBy: [adminId],
        }),
      );
    });
  };

  useEffect(() => {
    if (success.message === 'Request to join submitted') {
      setSeletecteItems([]);
      dispatch(discussionGroupsActions.getAllDiscussionGroups({}));
      setShowInviteMemberModal(false);
    }
  }, [success]);

  return (
    <>
      {showInviteMemberModal ? (
        <>
          <form onSubmit={(e) => handleMentorInvitation(e)}>
            <ModalWrapper
              title={` Add members to ${selectedGroup.groupName}`}
              closeModal={() => setShowInviteMemberModal(false)}
              bodyContent={
                <>
                  <div className='relative'>
                    <div className='w-full'>
                      <MultiSelect
                        messages={multiSelectMessages}
                        items={members}
                        showSelectAll={false}
                        selectedItems={selectedItems}
                        showSelectedItems
                        itemHeight={50}
                        selectedItemHeight={50}
                        onChange={(values) => setSeletecteItems(values)}
                        itemRenderer={(item) => <ItemOption item={item} />}
                        selectedItemRenderer={(item) => (
                          <SelecteItemOption item={item} />
                        )}
                      />
                    </div>
                  </div>
                </>
              }
              footer={true}
              footerContent={
                <>
                  <button
                    className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                    type='submit'
                  >
                    {loading ? <ButtonLoader /> : <> Send Invites </>}
                  </button>
                </>
              }
            />
          </form>
        </>
      ) : null}
    </>
  );
};

const ViewJoinRequests = (props) => {
  const dispatch = useDispatch();
  const { success, error } = useSelector((state) => state?.discussiongroups);

  const joinRequests = props.requests?.filter(
    (value) => value.nameOfWhatToJoin === props.selectedGroup?.groupName,
  );

  useEffect(() => {
    dispatch(discussionGroupsActions.getAllJoinRequests(props.adminId));
  }, []);
  useEffect(() => {
    if (success.message === 'Request to join Accepted') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Request to join Accepted.',
      });
    }
    if (success.message === 'Request to join Rejected') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Request to join Rejected.',
      });
    }
    if (error) {
      if (`${error}`.includes('NetworkError')) {
        CustomAlert({
          alertType: 'error',
          alertMessage: 'An error has occured.',
        });
      }
    }
  }, [success, error]);

  return (
    <>
      {props.showJoinRequestsModal ? (
        <>
          <ModalWrapper
            title={`(${joinRequests.length > 0 ? joinRequests?.length : 0})
                      Join Requests for
                      ${props.selectedGroup.groupName}`}
            closeModal={() => props.setShowJoinRequestsModal(false)}
            bodyContent={
              <>
                <div className='relative'>
                  <div className=''>
                    {joinRequests?.length > 0 ? (
                      joinRequests?.map((value, key) => (
                        <RequestCard
                          member={value}
                          loading={props.loading}
                          key={key}
                          error={error}
                          success={success}
                          adminId={props.institutionID}
                        />
                      ))
                    ) : (
                      <NoRequests />
                    )}
                  </div>
                </div>
              </>
            }
            size={'w-9/12'}
          />
        </>
      ) : null}
    </>
  );
};

export default function Groups() {
  return (
    <>
      <PageBody />
    </>
  );
}
