import React from 'react';
import PropTypes from 'prop-types';

const ContentWrapper = ({ tab }) => {
  return <div>{tab}</div>;
};

ContentWrapper.propTypes = {
  name: PropTypes.string,
};
export default ContentWrapper;
