import React from 'react';
import Sidebar from './Sidebar';

const UserInfoSideMenu = ({
  user,
  userType,
  showUserInfo,
  setShowUserInfo,
  myContent,
  mySessions,
  setShowSendInvitationModal,
  showSendInvitationModal,
}) => {
  return (
    <div
      className={
        showUserInfo
          ? 'w-full h-screen shadow-md absolute z-40  transform  translate-x-0 lg:hidden'
          : '   w-full h-full absolute z-40  transform -translate-x-full'
      }
    >
      <div
        className='bg-black opacity-50 w-full h-screen absolute  lg:hidden  '
        onClick={() => setShowUserInfo(!showUserInfo)}
      ></div>
      <div className='w-64 md:w-72 absolute z-40 bg-white shadow h-full flex-col justify-between lg:hidden pb-4 transition duration-150 ease-in-out'>
        <div className='flex flex-col justify-between h-full'>
          <div>
            <div className='px-4 overflow-y-hidden overscroll-contain h-screen top-0'>
              <div className='block   mb-10 text-headingtext font-sans sm:text-sm'>
                <Sidebar
                  userType={userType}
                  user={user}
                  myContent={myContent}
                  mySessions={mySessions}
                  setShowSendInvitationModal={setShowSendInvitationModal}
                  showSendInvitationModal={showSendInvitationModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoSideMenu;
