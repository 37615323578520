import { ArrowDropDown, ArrowRight, StarRate } from '@material-ui/icons';
// import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import { useToggle } from '../helpers';
import EventThumb from '../assets/eventPlaceholder.svg';

const EventCard = ({ event, past, to }) => {
  const [hideDetails, toggleHideDetails] = useToggle(true);
  const [hideDescription, toggleHideDescription] = useToggle(true);

  return (
    <Link to={to || '#'}>
      <div className='relative mb-6 p-px overflow-hidden transition duration-300 transform border-l-4 border-white group shadow-sm hover:border-darkerblue'>
        <div className='flex flex-col justify-between'>
          <div className='relative flex flex-col h-full p-5 bg-white rounded-sm lg:items-center lg:flex-row'>
            <div className='mb-6 lg:mr-6 lg:mb-0'>
              <div className='flex items-center justify-center mx-auto w-32 h-32 rounded-full lg:w-28 lg:h-28'>
                <img
                  src={event?.thumbnailFile?.publicUrl || EventThumb}
                  alt='Event Thumb'
                  className='object-cover h-full w-full'
                />
              </div>
            </div>
            <div className='flex flex-col justify-between flex-grow text-charcoal'>
              <div>
                <p
                  className={`mb-2 text-sm font-light ${
                    past ? 'text-red' : 'text-buttonGreen'
                  } uppercase`}
                >
                  {event?.sessionDate}
                </p>
                <h2 className='mb-2 font-semibold text-2xl leading-5 capitalize'>
                  {event.sessionTitle}
                </h2>
              </div>
              {hideDescription && (
                <p className='mb-2 text-sm font-base lg:h-16'>
                  <Truncate
                    lines={3}
                    ellipsis={
                      <span>
                        ...
                        <span
                          className='cursor-pointer text-main font-semibold hover:text-navyblue px-4'
                          aria-label='see more'
                          aria-controls='see more'
                          aria-haspopup='true'
                          onClick={() => toggleHideDescription()}
                        >
                          see {hideDescription ? 'more' : 'less'}
                        </span>
                      </span>
                    }
                  >
                    {event.description}
                  </Truncate>
                </p>
              )}
              <p className='mb-2 text-sm font-base' hidden={hideDescription}>
                {event.description}
              </p>
              <div className='flex flex-row justify-between w-full'>
                <span
                  className='inline-flex items-center justify-center lg:justify-start w-full  font-medium tracking-wide text-charcoal hover:text-navyblue focus:shadow-outline '
                  onClick={() => toggleHideDetails()}
                >
                  <span className='mr-2'>
                    {hideDetails ? 'Show' : 'Hide'}
                    {` Details`}
                  </span>
                  {/* <ArrowDropDown /> */}
                  {hideDetails ? <ArrowRight /> : <ArrowDropDown />}
                </span>
              </div>
            </div>
          </div>
          <div className='px-5 pb-5 mt-3' hidden={hideDetails}>
            <div className='bg-lightGrayBg2 border-b border-linecolor leading-6 mt-2'>
              <div className='grid grid-cols-3 gap-4'>
                <span className='font-medium text-sm'>Start Date</span>
                <span className='col-span-2 text-headingtext'>
                  {event.sessionDate}
                </span>
              </div>
              <div className='grid grid-cols-3 gap-4'>
                <span className='font-medium text-sm'>End Date</span>
                <span className='col-span-2 text-headingtext'>
                  {event.endDate}
                </span>
              </div>
              <div className='grid grid-cols-3 gap-4'>
                <span className='font-medium text-sm'>Start Time</span>
                <span className='col-span-2 text-headingtext'>
                  {event.startTime}
                </span>
              </div>
              <div className='grid grid-cols-3 gap-4'>
                <span className='font-medium text-sm'>End Time</span>
                <span className='col-span-2 text-headingtext'>
                  {event.endTime}
                </span>
              </div>
              <div className='grid grid-cols-3 gap-4'>
                <span className='font-medium text-sm'>Presenter</span>
                <span className='col-span-2 text-headingtext'>
                  {event.presenter}
                </span>
              </div>

              <div className='grid grid-cols-3 gap-4'>
                <span className='font-medium text-sm'>Organizer</span>
                <span className='col-span-2 text-headingtext'>
                  {event.organiser}
                </span>
              </div>
              {/* <div className='grid grid-cols-3 gap-4'>
                <span className='font-medium text-sm'>Duration</span>
                <span className='col-span-2 text-headingtext'>
                  {event.duration}
                  {event.sessionDate === event.endDate
                    ? moment(event.startTime) - moment(event.endTime)
                    : moment(
                        moment(event.sessionDate) - moment(event.endDate),
                      ).format('DDD hh:mm')}
                </span>
              </div> */}
              <div className='grid grid-cols-3 gap-4'>
                <span className='font-medium text-sm'>Price</span>
                <span className='col-span-2 text-headingtext'>
                  {event.price === 0 ? 'FREE' : `UGX ${event.price}`}
                </span>
              </div>
              <div className='grid grid-cols-3 gap-4'>
                <span className='font-medium text-sm'>
                  Attendees ({event?.registeredAttendees?.length || 0})
                </span>
                <span className='col-span-2 text-headingtext text-sm'>
                  {event?.registeredAttendees?.map((value, key) => (
                    <span
                      key={value._id}
                    >{`${value?.firstname} ${value?.lastname}, `}</span>
                  ))}
                </span>
              </div>
              <div className='grid grid-cols-3 gap-4'>
                <span className='font-medium text-sm'>Rating</span>
                <span className='col-span-2 text-starsorange  '>
                  <StarRate />{' '}
                  {event.rating === 0 ||
                  event.rating === null ||
                  event.rating === undefined
                    ? 'No Rating'
                    : event.rating}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EventCard;
