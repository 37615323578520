import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { School } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import CourseCard from '../components/CourseCard';

import * as contentActions from '../redux/actions/content';
import NoContentPlaceholder from '../components/NoContentPlaceholder';

const PageBody = () => {
  const dispatch = useDispatch();
  const { data, loading, error, errorMessage } = useSelector(
    (state) => state.content,
  );

  const limit = 32;

  useEffect(() => {
    dispatch(contentActions.getCourses({ limit }));
  }, []);

  useEffect(() => {
    if (error) console.log(errorMessage);
  }, [error]);

  return (
    <>
      {' '}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div
          className={`bg-lightgraybg w-full ${
            data.length < 5 ? 'h-screen' : 'h-full'
          }`}
        >
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
              <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>All Courses</span>
                </h2>
                <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                  <Link to='/IndividualCourses/mylist'>
                    <button className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'>
                      <School />
                      <span className='ml-2'>My Courses</span>
                    </button>
                  </Link>
                </div>
              </div>
              <CourseCategory courses={data} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CourseCategory = ({ courses }) => {
  return (
    <>
      <div className='flex flex-col mb-6 lg:justify-between md:mb- shadow '>
        {courses.length > 0 ? (
          <div className='grid grid-cols-1 gap-6 row-gap-4 mb-8 lg:grid-cols-4 sm:grid-cols-2'>
            {courses?.map((course) => (
              <CourseCard course={course} key={course?._id} />
            ))}
          </div>
        ) : (
          <NoContentPlaceholder content='Courses' generic />
        )}
      </div>
    </>
  );
};

export default function data() {
  return (
    <>
      <Nav IndividualCoursesActive={true} />
      <PageBody />
    </>
  );
}
