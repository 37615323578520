import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dashboard, People } from '@material-ui/icons';

// assets
// import courseImage from '../assets/courseimage.svg';

// components
import Nav from '../components/NavBarR';
import Sidebar from '../components/Sidebar';
import Loading from '../components/Loading';
import Sharepost from '../components/Sharepost';
import FollowCard from '../components/FollowCard';
import CustomAlert from '../components/CustomAlert';
import PopularCard from '../components/PopularCard';
// import Recommended from '../components/Recommended';
import SendPostModal from '../components/SendPostModal';
import SharePostModal from '../components/SharePostModal';
import FollowerSideMenu from '../components/FollowSideMenu';
import UploadSessionModal from '../components/UploadSession';
import UserInfoSideMenu from '../components/UserInfoSideMenu';
import CommentPostModal from '../components/CommentPostModal';
import { NotificationBanner } from '../components/NotificationBanner';
import ModalWrapper from '../components/ModalWrapper';
import UserPointsModal from '../components/UserPointsModal';

import * as invitationActions from '../redux/actions/invitation';
import * as homeActions from '../redux/actions/home';
import * as userActions from '../redux/actions/users';
import * as contentActions from '../redux/actions/content';
import * as sessionsActions from '../redux/actions/sessions';
import * as institutionActions from '../redux/actions/institution';
import * as affiliationsActions from '../redux/actions/affiliations';
import * as discussionGroupsActions from '../redux/actions/discussiongroups';
import * as commentsActions from '../redux/actions/comments';
import * as likesActions from '../redux/actions/likes';

export default function Feeds() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.user);
  const points = useSelector((state) => state.user?.user_points);
  const mentors = useSelector((state) => state?.user?.data);
  const { feeds, loading, error, errorMessage } = useSelector(
    (state) => state.home,
  );
  const myContent = useSelector((state) => state.content.myContent);
  const mySessions = useSelector((state) => state.sessions.mySessions);
  const institutionData = useSelector((state) => state.institution.data);
  const contentComments = useSelector((state) => state.comments?.data);
  const contentLikes = useSelector((state) => state.like?.data);
  const { discussionGroupsList, success } = useSelector(
    (state) => state?.discussiongroups,
  );
  const affiliations = useSelector((state) => state?.affiliations?.data);
  const AllRequestsList = useSelector(
    (state) => state?.discussiongroups?.joinRequestsList,
  );

  const isLinkValid = (link) => {
    let regex = /^https:\/\/calendly\.com\//g;
    return regex.test(link);
  };

  const isMentorCalendlyMissing =
    user?.usercategory[0] === 'mentor' && !isLinkValid(user?.calendly);

  const [showCommentPost, setShowCommentPost] = useState(false);
  const [showSendPost, setShowSendPost] = useState(false);
  const [showSharePost, setShowSharePost] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const [showFollower, setShowFollowers] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showSendInvitationModal, setShowSendInvitationModal] = useState(false);
  const [showUserPointsModal, setShowUserPointsModal] = useState(false);
  const [showCreateSession, setShowCreateSession] = useState(false);
  const [showNotification, setShowNotification] = useState(
    isMentorCalendlyMissing,
  );
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(30);

  useEffect(() => {
    if (showCommentPost === false) {
      dispatch(commentsActions.getAllComments());
      dispatch(likesActions.getAllLikes());
    }
  }, [showCommentPost]);

  useEffect(() => {
    dispatch(homeActions.getFeeds({ limit: 30 }));
    dispatch(contentActions.getMyContent());
    dispatch(sessionsActions.getMySessions());
    dispatch(userActions.getFollow({ userID: user?._id }));
    dispatch(userActions.getUserPoints({ userId: user?._id }));
    dispatch(userActions.getUsers());
    dispatch(institutionActions.getInstitutions({ limit: 4 }));
    dispatch(discussionGroupsActions.getAllDiscussionGroups({ limit: 4 }));
    dispatch(affiliationsActions.getAllAffiliations());
    dispatch(discussionGroupsActions.getAllJoinRequests(user._id));
    setSkip(skip + 30);
    setLimit(limit + 30);
    setShowUserPointsModal(true);
    setTimeout(function () {
      setShowUserPointsModal(false);
    }, 6000);
  }, []);

  //My affiliations
  const myAffiliations = [];
  affiliations
    ?.filter(
      (item) => item.affiliate === user._id && item.affiliateType === 'mentor',
    )
    .map((item) => myAffiliations.push(item.institution));

  myAffiliations.forEach((affiliation) => {});

  const myInstitutions = institutionData?.filter((instituion) =>
    myAffiliations.includes(instituion._id),
  );

  // Pending Join requests
  const joinRequestsList = AllRequestsList?.filter(
    (request) =>
      request.status === 'pending' && request.whatToJoin === 'institution',
  );

  useEffect(() => {
    if (error) {
      console.log(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (success.message === 'Request to join submitted') {
      dispatch(discussionGroupsActions.getAllDiscussionGroups({}));
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Join request submitted.',
      });
    }
  }, [success]);

  function moreFeeds() {
    dispatch(homeActions.getFeeds({ skip: skip, limit: limit }));
  }

  return (
    <div>
      <Nav feedsActive={true} />
      {showNotification && (
        <NotificationBanner setShowNotification={setShowNotification} />
      )}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <>
          <SendInvitationModal
            setShowSendInvitationModal={setShowSendInvitationModal}
            showSendInvitationModal={showSendInvitationModal}
          />
          <UserPointsModal
            setShowUserPointsModal={setShowUserPointsModal}
            showUserPointsModal={showUserPointsModal}
            points={points}
          />
          <div className='bg-lightgraybg'>
            <div className=' text-center md:mb-0  w-full fixed  mb-4 lg:hidden'>
              <div className='inline-flex items-center justify-between w-full h-12 px-6 font-medium font-display tracking-wide text-headingtext transition   bg-white '>
                <span
                  className='hover:text-darkerblue focus:text-darkerblue font-display'
                  onClick={() => {
                    setShowUserInfo(!showUserInfo);
                    setShowFollowers(false);
                  }}
                >
                  <Dashboard />
                  <span className='ml-2'> {' User Info '} </span>
                </span>
                <span
                  className='hover:text-darkerblue focus:text-darkerblue '
                  onClick={() => {
                    setShowFollowers(!showFollower);
                    setShowUserInfo(false);
                  }}
                >
                  <span className='mr-2'>{'Follow'} </span>
                  <People />
                </span>
              </div>
            </div>
            <FollowerSideMenu
              users={mentors}
              institutions={institutionData}
              showFollower={showFollower}
              setShowFollowers={setShowFollowers}
            />

            <UserInfoSideMenu
              user={user}
              myContent={myContent}
              mySessions={mySessions}
              showUserInfo={showUserInfo}
              setShowUserInfo={setShowUserInfo}
              setShowSendInvitationModal={setShowSendInvitationModal}
              showSendInvitationModal={showSendInvitationModal}
            />
            <div className='px-2 mx-auto sm:w-xl md:w-full lg:max-w-screen-2xl md:px-0  '>
              <div className='px-2  container mx-auto sm:max-w-full md:max-w-screen-2xl lg:max-w-full md:px-4  '>
                <div className='w-full grid  grid-cols-1 lg:grid-cols-12 sm:gap-5  lg:px-10'>
                  <aside className='h-screen sticky lg:top-32 lg:col-span-2 hidden font-display lg:flex'>
                    <Sidebar
                      user={user}
                      myContent={myContent}
                      mySessions={mySessions}
                      userAffiliations={{
                        requests: joinRequestsList?.length,
                        affiliations: myInstitutions?.length,
                      }}
                      setShowSendInvitationModal={setShowSendInvitationModal}
                      showSendInvitationModal={showSendInvitationModal}
                      setShowUserPointsModal={setShowUserPointsModal}
                    />
                  </aside>

                  <main className='lg:col-span-7 flex flex-col mt-16 lg:mt-4'>
                    <Sharepost
                      user={user}
                      createSessionClick={() => setShowCreateSession(true)}
                    />
                    <div className='pt-7 mb-20 font-display'>
                      {feeds?.map((value) => (
                        <PopularCard
                          contentComments={contentComments}
                          contentLikes={contentLikes}
                          users={mentors}
                          institutions={institutionData}
                          key={value._id}
                          content={value}
                          sendPostClick={() => {
                            setSelectedPost(value);
                            setShowSendPost(true);
                          }}
                          commentPostClick={() => {
                            setSelectedPost(value);
                            setShowCommentPost(true);
                          }}
                          sharePostClick={() => {
                            setSelectedPost(value);
                            setShowSharePost(true);
                          }}
                        />
                      ))}
                      {/* <Recommended
                      heading='Recommended Courses'
                      courseImage={courseImage}
                      sendPostClick={() => setShowSendPost(true)}
                      commentPostClick={() => setShowCommentPost(true)}
                      sharePostClick={() => setShowSharePost(true)}
                    /> */}
                    </div>
                    <SendPostModal
                      setShowSendPost={setShowSendPost}
                      showSendPost={showSendPost}
                      postSnippet={selectedPost}
                      users={mentors}
                    />
                    <CommentPostModal
                      setShowCommentPost={setShowCommentPost}
                      showCommentPost={showCommentPost}
                      postSnippet={selectedPost}
                      users={mentors}
                    />
                    <SharePostModal
                      setShowSharePost={setShowSharePost}
                      showSharePost={showSharePost}
                      postSnippet={selectedPost}
                      users={mentors}
                    />
                    <UploadSessionModal
                      setShowCreateEventModal={setShowCreateSession}
                      showCreateEventModal={showCreateSession}
                      userId={user?._id}
                    />
                    <button
                      onClick={() => {
                        setSkip(skip + 30);
                        setLimit(limit + 30);
                        moreFeeds();
                      }}
                    >
                      SHOW MORE FEEDS
                    </button>
                  </main>
                  <aside className='h-full sticky lg:col-span-3 top-32 mt-4 hidden lg:flex '>
                    <div className='h-full  lg:mb-20'>
                      <div className='flex flex-col h-full   '>
                        <div className='bg-white w-full  mb-10'>
                          <div className=''>
                            <h1 className='p-4  font-body font-semibold text-sm border-b border-linecolor mb-5 '>
                              Follow Mentors / Trainers / Counsellors
                            </h1>
                            <div>
                              {mentors?.slice(0, 4).map((value) => (
                                <FollowCard
                                  key={value?._id}
                                  pic={value?.profilePicture?.publicUrl}
                                  name={`${value?.firstname} ${value?.lastname}`}
                                  content={
                                    value?.usercategory[
                                      value?.usercategory?.length - 1
                                    ]
                                  }
                                  button='More'
                                  userId={value?._id}
                                  userType={'mentors'}
                                />
                              ))}
                            </div>
                            <div className='flex justify-center py-3'>
                              <span
                                className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
                                // eslint-disable-next-line no-restricted-globals
                                onClick={() => history.push('/mentors')}
                              >
                                View all
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='bg-white w-full mb-10'>
                          <div className=''>
                            <h1 className='p-4  font-body font-semibold text-sm border-b border-linecolor mb-5 '>
                              Follow Institutions
                            </h1>
                            <div>
                              {institutionData.slice(0, 4).map((value) => (
                                <FollowCard
                                  key={value?._id}
                                  pic={value?.profilePicture?.publicUrl}
                                  name={`${value?.institutionName}`}
                                  content={value?.usercategory}
                                  button='More'
                                  userId={value?._id}
                                  userType={'institution'}
                                />
                              ))}
                            </div>
                            <div className='flex justify-center py-3'>
                              <span
                                className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
                                onClick={() => history.push('/institutions')}
                              >
                                View all
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className=' w-full pb-20'>
                          <div className='bg-white '>
                            <h1 className='p-4  font-body font-semibold text-sm border-b border-linecolor mb-5 '>
                              Follow Groups / Communities
                            </h1>

                            <div>
                              {discussionGroupsList?.map((value) => (
                                <FollowCard
                                  user={user}
                                  group={value}
                                  type={'group'}
                                  key={value?._id}
                                  pic={value?.profilePicture?.publicUrl}
                                  name={`${value?.groupName}  `}
                                  content={value?.usercategory}
                                  button='View'
                                />
                              ))}
                            </div>
                            <div className='flex justify-center py-3'>
                              <span
                                className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
                                onClick={() => history.push('/groups')}
                              >
                                View all
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const SendInvitationModal = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [toShow, setToShow] = useState(true);
  const user = useSelector((state) => state.user.user);
  const invitation = useSelector((state) => state?.invitation);

  const handleInvitation = async (e) => {
    e.preventDefault();
    setToShow(false);
    const payload = {
      email: email,
      sentBy: user._id,
      sentUser: `${user.firstname} ${user.lastname}`,
      category: 'general',
    };

    dispatch(invitationActions.createInvitation(payload));
  };

  useEffect(() => {
    if (invitation?.success?.status) {
      setEmail('');
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Email Invitation has been sent',
      });
    }
  }, [invitation]);

  return (
    <>
      {props.showSendInvitationModal ? (
        <>
          <ModalWrapper
            title={'Invite a friend'}
            closeModal={() => {
              props.setShowSendInvitationModal(false);
              setToShow(true);
            }}
            bodyContent={
              <div className='relative'>
                {toShow ? (
                  <form onSubmit={handleInvitation}>
                    <label className='font-semibold text-textparagraph text-sm'>
                      Add Email
                    </label>
                    <div className='grid grid-cols-6'>
                      <div className='col-span-4'>
                        <input
                          required
                          type='email'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                        />
                      </div>
                      <div className='col-span-2'>
                        <button
                          style={{ float: 'right' }}
                          className='lg:w-40 sm:w-20 h-12 falsefocus:shadow-outline  bg-darkerblue text-white text-xl px-2 py-2 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                          type='submit'
                        >
                          Invite
                        </button>
                      </div>
                    </div>
                    <br />
                  </form>
                ) : null}

                {invitation?.data?._id && !toShow ? (
                  <>
                    <label className='font-light text-textparagraph text-sm'>
                      <b>Copy invitation link :</b>
                      <br /> {process.env.REACT_APP_HOST}/invitation/
                      {invitation.data._id}
                    </label>
                    <div className='col-span-12'>
                      <br />
                    </div>
                  </>
                ) : null}
              </div>
            }
          />
        </>
      ) : null}
    </>
  );
};
