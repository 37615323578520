import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import React from 'react';
import VideoPlaceholder from '../assets/video1.svg';

import { useToggle } from '../helpers';

const SessionSnippet = ({ session, users }) => {
  const [hideDescription, toggleHideDescription] = useToggle(true);
  return (
    <div className='w-full overflow-auto bg-white box-border rounded flex flex-col shadow-md border border-linecolor'>
      <div className='w-full h-full text-xs font-body text-charcoal '>
        <div className='px-1  py-2  mx-auto '>
          <div className='grid gap-4 row-gap-4 lg:grid-cols-12'>
            <div className='lg:col-span-5 px-2'>
              <img
                className='object-cover w-full h-40 shadow-lg sm:h-64'
                src={session?.thumbnailFile?.publicUrl || `${VideoPlaceholder}`}
                alt='Session Banner'
              />
            </div>
            <div className='flex flex-col justify-start lg:col-span-7 px-4 lg:px-0'>
              <div className='max-w-full mb-4'>
                <h2 className='max-w-full mb-6 text-xl text-center lg:text-left lg:text-3xl font-bold tracking-tight text-charcoal sm:text-3xl sm:leading-none'>
                  {session?.sessionTitle}
                </h2>
              </div>
              <div className='grid gap-8 row-gap-8 sm:grid-cols-2'>
                <div>
                  <div className='flex flex-row justify-start  w-full mb-4'>
                    <span className='mb-2 font-semibold leading-5 text-headingtext w-2/6 lg:w-24'>
                      Date:
                    </span>
                    <span className='mb-2 font-semibold leading-5 text-charcoal w-4/6'>
                      {session?.sessionDate}
                    </span>
                  </div>
                </div>
                <div>
                  <div className='flex flex-row justify-start  w-full mb-4'>
                    <span className='mb-2 font-semibold leading-5 text-headingtext w-2/6 lg:w-24'>
                      Duration:
                    </span>
                    <span className='mb-2 font-semibold leading-5 text-charcoal w-4/6'>
                      {session?.duration || 'T.B.D'}
                    </span>
                  </div>
                </div>
              </div>
              <div className='grid gap-8 row-gap-8 sm:grid-cols-2'>
                <div>
                  <div className='flex flex-row justify-start  w-full mb-4'>
                    <span className='mb-2 font-semibold leading-5 text-headingtext w-2/6 lg:w-24'>
                      Via:
                    </span>
                    <span className='mb-2 font-semibold leading-5 text-charcoal w-4/6'>
                      {`Google Meets`}
                    </span>
                  </div>
                </div>
                <div>
                  <div className='flex flex-row justify-start  w-full mb-4'>
                    <span className='mb-2 font-semibold leading-5 text-headingtext w-2/6 lg:w-24'>
                      Price:
                    </span>
                    <span className='mb-2 font-semibold leading-5 text-charcoal w-4/6 '>
                      {session?.price === 0 ? 'FREE' : `Ugx ${session?.price}`}
                    </span>
                  </div>
                </div>
              </div>

              <div className='flex flex-row justify-center lg:justify-start w-full mb-4'>
                <div className='flex'>
                  <img
                    className='object-cover w-10 h-10 mr-4 rounded-full shadow'
                    src={`https://ui-avatars.com/api/?name=${session?.presenter}&bold=true`}
                    alt='mentor'
                  />
                  <div className='flex flex-col justify-center'>
                    <p className='text-lg font-bold text-charcoal'>
                      {session?.presenter}
                    </p>
                  </div>
                </div>
              </div>
              <span
                className='mt-2 inline-flex  items-center text-darkerblue cursor-pointer justify-center lg:justify-start   font-medium tracking-wide  hover:text-navyblue focus:shadow-outline '
                onClick={() => toggleHideDescription()}
              >
                <span className='mr-2 '>
                  {hideDescription ? 'Read' : 'Hide'}
                  {` Description`}
                </span>
                {/* <ArrowDropDown /> */}
                {hideDescription ? <ArrowDropDown /> : <ArrowRight />}
              </span>
            </div>
          </div>
          <div
            className={`flex flex-col px-4 mt-8 text-gray ${
              hideDescription && 'hidden'
            }`}
            hidden={hideDescription}
          >
            <p className='text-xl font-bold mb-1 leading-3'>About</p>
            <span className='text-sm  pt-4'>{session.description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionSnippet;
