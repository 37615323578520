import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as sessionsActions from '../redux/actions/sessions';
import CustomAlert from './CustomAlert';
import TagsInput from './TagsInput';
import { DropzoneArea } from 'material-ui-dropzone';
import ButtonLoader from './ButtonLoader';
import ModalWrapper from './ModalWrapper';

const UploadSessionModal = ({
  setShowCreateEventModal,
  showCreateEventModal,
  userId,
  institution,
  mentors,
}) => {
  const dispatch = useDispatch();
  const { loading, success, errorMessage } = useSelector(
    (state) => state.sessions,
  );
  const [presenter, setPresenter] = useState([]);
  const [tags, setTags] = useState([]);

  const [photo, setPhoto] = useState('');

  const suggestions = [];

  mentors?.forEach((element, index) => {
    suggestions.push({
      id: element?._id,
      text: `${element?.firstname} ${element?.lastname}`,
      image: element?.profilePicture?.publicUrl,
    });
  });

  const initialValues = {
    sessionTitle: '',
    description: '',
    presenter: '',
    link: 'Not Available',
    price: 0,
    organiser: '',
    sessionDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
  };

  const validationObject = {
    sessionTitle: Yup.string().required('Event name is required'),
    description: Yup.string().required('Description is required'),
    organiser: Yup.string().required('Organizer is required'),
    presenter: Yup.string().required('Presenter is required'),
    price: Yup.number().required('Price is required'),
    sessionDate: Yup.string().required('Date is required'),
    endDate: Yup.string().required('Date is required'),
    startTime: Yup.string().required('Time is required'),
    endTime: Yup.string().required('Time is required'),
  };

  if (institution) {
    delete initialValues.presenter;
    delete validationObject.presenter;
  }

  const validationSchema = Yup.object(validationObject);

  const onSubmit = (values, onSubmitProps) => {
    const tagsText = tags.map((tag) => tag.text);
    const payload = {
      ...values,
      photo: photo,
      tags: tagsText,
      userId: userId,
    };
    const myForm = new FormData();

    myForm.append('sessionTitle', payload.sessionTitle);
    myForm.append('description', payload.description);
    myForm.append('tags', payload.tags);
    myForm.append('sessionDate', payload.sessionDate);
    myForm.append('endDate', payload.endDate);
    myForm.append('startTime', payload.startTime);
    myForm.append('endTime', payload.endTime);
    myForm.append('organiser', payload.organiser);
    myForm.append('sessionAddressLink', payload.link);
    myForm.append('presenter', payload.presenter);
    myForm.append('price', payload.price);
    myForm.append('photo', payload.photo[0]);
    //     console.log(`presenter`, presenter[0].text);
    dispatch(sessionsActions.createSession(myForm));
    onSubmitProps.resetForm();
  };

  useEffect(() => {
    if (success.message === 'Session Successfully created') {
      dispatch(sessionsActions.getAllSessions());
      CustomAlert({
        alertType: 'success',
        alertMessage: success.message,
      });
      setShowCreateEventModal(false);
    }
    if (errorMessage === 'Failed to create session') {
      dispatch(sessionsActions.getAllSessions());
      CustomAlert({
        alertType: 'error',
        alertMessage: 'Session creation failed, try again.',
      });
      setShowCreateEventModal(false);
    }
  }, [success, errorMessage]);

  return (
    <>
      {showCreateEventModal ? (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isValid, touched, errors, getFieldProps }) => (
              <Form>
                <ModalWrapper
                  title={'Create Session'}
                  closeModal={() => setShowCreateEventModal(false)}
                  bodyContent={
                    <>
                      {' '}
                      <div className='relative'>
                        <div className=''>
                          <div className=''>
                            <div>
                              <label className='font-semibold text-textparagraph text-sm'>
                                SESSION NAME
                              </label>
                              <input
                                type='text'
                                className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                                id='sessionTitle'
                                name='sessionTitle'
                                {...getFieldProps('sessionTitle')}
                              />
                              {touched.sessionTitle && errors.sessionTitle ? (
                                <span className='text-sm text-red'>
                                  {errors.sessionTitle}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className=''>
                            <label className='font-semibold text-textparagraph text-sm'>
                              DESCRIPTION
                            </label>

                            <textarea
                              className='flex-grow w-full p-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='description'
                              name='description'
                              placeholder={
                                'Give a brief summary about the session..'
                              }
                              rows='4'
                              {...getFieldProps('description')}
                            />
                            {touched.description && errors.description ? (
                              <span className='text-sm text-red'>
                                {errors.description}
                              </span>
                            ) : null}
                          </div>
                          <div className=''>
                            <label className='font-semibold text-textparagraph text-sm'>
                              TOPIC TAGS
                            </label>
                            <p className='font-base text-textparagraph text-sm mb-2'>
                              Add any of key topics this session will be about.
                            </p>
                            <TagsInput
                              tags={tags}
                              noSpaceDelimiter={true}
                              setTags={setTags}
                              suggestions={[]}
                              placeholder={`Enter a comma separated list`}
                              classNames={{
                                tagInputField:
                                  'px-3 py-3 text-charcoal relative bg-none rounded text-sm border-b border-textparagraph outline-none focus:outline-noone  focus:border-darkerblue w-full',
                              }}
                            />
                          </div>

                          <div className='grid sm:grid-cols-2 grid-cols-1 gap-4 pt-2 '>
                            <div>
                              <label className='font-semibold text-textparagraph text-sm'>
                                ORGANIZER
                              </label>
                              <input
                                type='text'
                                className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                                id='organiser'
                                placeholder=''
                                name='organiser'
                                {...getFieldProps('organiser')}
                              />
                              {touched.organiser && errors.organiser ? (
                                <span className='text-sm text-red'>
                                  {errors.organiser}
                                </span>
                              ) : null}
                            </div>
                            <div>
                              <label className='font-semibold text-textparagraph text-sm'>
                                PRESENTER{' '}
                              </label>
                              {institution ? (
                                <TagsInput
                                  tags={presenter}
                                  isInstitution={institution}
                                  singleTag
                                  noSpaceDelimiter={true}
                                  setTags={setPresenter}
                                  suggestions={suggestions}
                                  placeholder={`${
                                    institution
                                      ? 'Select from your mentors...'
                                      : 'Enter a comma separated list'
                                  }`}
                                  classNames={{
                                    tagInputField:
                                      'placeholder-charcoal px-3 py-3 text-charcoal relative bg-none rounded text-sm border-b border-textparagraph outline-none focus:outline-noone  focus:border-darkerblue w-full',
                                  }}
                                />
                              ) : (
                                <>
                                  <input
                                    type='text'
                                    className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                                    id='presenter'
                                    name='presenter'
                                    {...getFieldProps('presenter')}
                                  />
                                  {touched.presenter && errors.presenter ? (
                                    <span className='text-sm text-red'>
                                      {errors.presenter}
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </div>

                          <div className='grid sm:grid-cols-2 grid-cols-1 gap-4 '>
                            <div>
                              <label className='font-semibold text-textparagraph text-sm'>
                                START DATE
                              </label>
                              <input
                                type='date'
                                className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                                id='sessionDate'
                                name='sessionDate'
                                {...getFieldProps('sessionDate')}
                              />
                              {touched.sessionDate && errors.sessionDate ? (
                                <span className='text-sm text-red'>
                                  {errors.sessionDate}
                                </span>
                              ) : null}
                            </div>
                            <div>
                              <label className='font-semibold text-textparagraph text-sm'>
                                END DATE
                              </label>
                              <input
                                type='date'
                                className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                                id='endDate'
                                name='endDate'
                                {...getFieldProps('endDate')}
                              />
                              {touched.endDate && errors.endDate ? (
                                <span className='text-sm text-red'>
                                  {errors.endDate}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className='grid sm:grid-cols-2 grid-cols-1 gap-4 '>
                            <div>
                              <label className='font-semibold text-textparagraph text-sm'>
                                START TIME
                              </label>
                              <input
                                type='time'
                                className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                                id='startTime'
                                name='startTime'
                                {...getFieldProps('startTime')}
                              />
                              {touched.startTime && errors.startTime ? (
                                <span className='text-sm text-red'>
                                  {errors.startTime}
                                </span>
                              ) : null}
                            </div>
                            <div>
                              <label className='font-semibold text-textparagraph text-sm'>
                                END TIME
                              </label>
                              <input
                                type='time'
                                className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                                id='endTime'
                                name='endTime'
                                {...getFieldProps('endTime')}
                              />
                              {touched.endTime && errors.endTime ? (
                                <span className='text-sm text-red'>
                                  {errors.endTime}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className=''>
                            <label className='font-semibold text-textparagraph text-sm'>
                              PRICE
                            </label>
                            <input
                              type='number'
                              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='price'
                              name='price'
                              min='0'
                              {...getFieldProps('price')}
                            />
                            {touched.price && errors.price ? (
                              <span className='text-sm text-red'>
                                {errors.price}
                              </span>
                            ) : null}
                          </div>
                          <div className=''>
                            <label className='font-semibold text-textparagraph text-sm'>
                              ONLINE MEETING LINK
                            </label>
                            <input
                              type='text'
                              placeholder={'Not available'}
                              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='link'
                              name='link'
                              {...getFieldProps('link')}
                            />
                            {touched.link && errors.link ? (
                              <span className='text-sm text-red'>
                                {errors.link}
                              </span>
                            ) : null}
                          </div>

                          <label className='font-semibold text-textparagraph text-sm '>
                            UPLOAD BANNER IMAGE
                          </label>

                          <div className=''>
                            <DropzoneArea
                              filesLimit={1}
                              name='photo'
                              showAlerts={false}
                              maxFileSize={5000000}
                              acceptedFiles={['image/*']}
                              onChange={(files) => setPhoto(files)}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  footer={true}
                  footerContent={
                    <>
                      <button
                        className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none  ease-linear transition-all duration-150'
                        type='submit'
                        // disabled={!isValid || loading}
                      >
                        {loading ? <ButtonLoader /> : 'Create Session'}
                      </button>
                    </>
                  }
                />
              </Form>
            )}
          </Formik>
        </>
      ) : null}
    </>
  );
};

export default UploadSessionModal;
