import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Truncate from 'react-truncate';
import {
  ArrowDownward,
  Comment,
  FiberManualRecord,
  Send,
  Share,
  ThumbUp,
} from '@material-ui/icons';

import IconsRole from './IconsRole';
import ContentPreview from './ContentPreview';
import DefaultThumbnail from './DefaultThumbnail';

import { useToggle } from '../helpers';
import { Link } from 'react-router-dom';
import SharedPostSnippet from './SharedPostSnippet';
import * as likesActions from '../redux/actions/likes';
import * as postActions from '../redux/actions/post';

export default function PopularCard({
  users,
  content,
  commentPostClick,
  sendPostClick,
  sharePostClick,
  institutions,
  contentComments,
  contentLikes,
  toShow,
}) {
  const dispatch = useDispatch();
  const user = users?.find((user) => user?._id === `${content?.userID}`);
  const userName = useSelector((state) => state.user.user);

  const [hideMoreText, toggleHideMoreText] = useToggle(true);

  const comments = contentComments?.filter(
    (comment) => comment?.idOfCommentedAbout === content?._id,
  );
  const likes = contentLikes?.filter(
    (like) => like?.idOfLikedAbout === content?._id,
  );
  const isInstitution = user?.usercategory === 'institution';
  let instituteRequired;

  const addNewLike = (e) => {
    e.preventDefault();
    dispatch(
      likesActions.addLike({
        likedAbout: 'content',
        idOfLikedAbout: content?._id,
      }),
    );

    setTimeout(function () {
      dispatch(likesActions.getAllLikes());
    }, 1500);
  };

  function updateStatus() {
    const payload = {
      postId: content?._id,
      status: 'rejected',
      userId: content?.userID,
      statusBy: userName?._id,
    };
    dispatch(postActions.updatePost(payload));
  }

  if (isInstitution) {
    const institutionList = [];
    institutions?.map((value) => institutionList.push(value));
    instituteRequired = institutionList.find(
      (inst) => inst.admins[0] === `${user?._id}`,
    );
  }

  const contentLink = () => {
    if (content?.sessionTitle) return `/session/${content?._id}`;
    if (content?.category === 'Image') return `/image/${content?._id}`;
    if (content.category === 'Video') return `/video/watch/${content?._id}`;
    if (content?.category === 'Article') return `/article/read/${content?._id}`;
  };

  return (
    <div className='max-w-full  bg-white box-border border border-linecolor py-5  flex flex-col overflow-hidden mb-8'>
      {content?.shared ? (
        <>
          <div className='px-4 w-full'>
            <div className='flex flex-row justify-start w-full'>
              <div className='flex justify-start'>
                <img
                  src={
                    content.sharedBy?.profilePicture?.publicUrl ||
                    `https://ui-avatars.com/api/?name=
                      
                        ${content?.sharedBy?.firstname}+${content?.sharedBy?.lastname}
                    &bold=true`
                  }
                  alt={content?.sharedBy?.firstname}
                  className='object-cover rounded-full h-10 w-10 flex items-center justify-center mr-2 '
                />
              </div>
              <div className='flex flex-col px-3 w-full'>
                <div className='flex flex-row justify-between w-full'>
                  <div>
                    <p className='text-sm lg:text-base font-semibold font-body text-charcoal'>
                      {`${content?.sharedBy?.firstname} ${content?.sharedBy?.lastname}`}
                    </p>
                  </div>
                  <div className='flex flex-wrap justify-end'>
                    <span
                      className={`inline-flex items-center justify-center px-2 py-1 text-xs mx-2
                      font-bold leading-none text-white bg-darkerblue rounded-full w-14 h-5`}
                    >
                      Shared
                    </span>
                    <span className='text-xs font-medium font-body text-headingtext'>
                      {moment(content.modifiedAt || content.sharedAt)
                        .local()
                        .fromNow()}
                    </span>
                  </div>
                </div>
                {hideMoreText && (
                  <p className='mb-2 text-xs sm:text-xs font-base text-headingtext '>
                    <Truncate
                      lines={2}
                      ellipsis={
                        <span>
                          ...
                          <span
                            className='cursor-pointer text-main text-xs font-semibold hover:text-navyblue px-4'
                            aria-label='see more'
                            aria-controls='see more'
                            aria-haspopup='true'
                            onClick={() => toggleHideMoreText()}
                          >
                            see {hideMoreText ? 'more' : 'less'}
                          </span>
                        </span>
                      }
                    >
                      {content.shareText}
                    </Truncate>
                  </p>
                )}
                <p
                  className='mb-2 text-xs sm:text-xs font-base text-headingtext'
                  hidden={hideMoreText}
                >
                  {content.shareText}
                </p>
              </div>
            </div>
          </div>
          <div className='w-full h-64 sm:h-80 text-xs font-body text-charcoal px-4 pt-5 mb-2 overflow-y-auto lg:overflow-y-hidden'>
            {content.sharedModel === 'Session' ? (
              <Link to={`/joinsession/${content.shared?._id}`}>
                <div className=' mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-0 lg:px-0 lg:py-4 min-h-80 mb-4'>
                  <div className=' w-full gap-8 row-gap-5 md:row-gap-8 sm:mx-auto'>
                    <div className='  md:text-left sm:text-md flex flex-col '>
                      <div className=' mx-auto sm:max-w-xl md:max-w-full lg:max-w-full lg:py-4'>
                        <div className='grid gap-2 lg:gap-8 row-gap-8 lg:grid-cols-12'>
                          <div className='lg:col-span-5 px-2'>
                            {content.shared?.thumbnailFile ? (
                              <img
                                className='object-cover w-full h-40 shadow-lg sm:h-64'
                                src={content.shared?.thumbnailFile?.publicUrl}
                                alt='Session Banner'
                              />
                            ) : (
                              <DefaultThumbnail
                                text={content.shared.sessionTitle}
                              />
                            )}
                          </div>
                          <div className='flex flex-col  justify-between lg:justify-start lg:col-span-7 px-4 lg:px-0'>
                            <div className='max-w-full mb-2 lg:mb-4'>
                              <span className='flex flex-row justify-center text-center text-base lg:hidden'>
                                <ArrowDownward fontSize='inherit' />
                              </span>
                              <h2 className='max-w-full  text-xl text-center lg:text-left lg:text-3xl font-bold tracking-tight text-charcoal sm:text-3xl sm:leading-none'>
                                {content.shared?.sessionTitle}
                              </h2>
                            </div>
                            <div className='grid gap-4 lg:gap-8 row-gap-4 lg:row-gap-8 sm:grid-cols-2'>
                              <div>
                                <div className='flex flex-row  justify-between lg:justify-start  w-full mb-2 lg:mb-4'>
                                  <span className='mb-2 font-semibold leading-5 text-headingtext   lg:w-24'>
                                    Date:
                                  </span>
                                  <span className='mb-2 font-semibold leading-5 text-charcoal lg:w-4/6'>
                                    {content.shared?.sessionDate}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <div className='flex flex-row  justify-between lg:justify-start  w-full mb-2 lg:mb-4'>
                                  <span className='mb-2 font-semibold leading-5 text-headingtext   lg:w-24'>
                                    Duration:
                                  </span>
                                  <span className='mb-2 font-semibold leading-5 text-charcoal lg:w-4/6'>
                                    {content.shared?.duration || 'T.B.D'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className='grid gap-4 lg:gap-8 row-gap-4 lg:row-gap-8 sm:grid-cols-2'>
                              <div>
                                <div className='flex flex-row  justify-between lg:justify-start  w-full mb-2 lg:mb-4'>
                                  <span className='mb-2 font-semibold leading-5 text-headingtext   lg:w-24'>
                                    Via:
                                  </span>
                                  <span className='mb-2 font-semibold leading-5 text-charcoal lg:w-4/6'>
                                    {`Google Meets`}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <div className='flex flex-row  justify-between lg:justify-start  w-full mb-2 lg:mb-4'>
                                  <span className='mb-2 font-semibold leading-5 text-headingtext   lg:w-24'>
                                    Price:
                                  </span>
                                  <span className='mb-2 font-semibold leading-5 text-charcoal lg:w-4/6 '>
                                    {content.shared?.price >= 1
                                      ? `Ugx ${content.shared?.price}`
                                      : 'FREE'}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className='flex flex-row justify-center lg:justify-start w-full mb-2 lg:mb-4'>
                              <div className='flex'>
                                <img
                                  className='object-cover w-10 h-10 mr-4 rounded-full shadow'
                                  src={`https://ui-avatars.com/api/?name=${content.shared?.presenter}&bold=true`}
                                  alt='mentor'
                                />
                                <div className='flex flex-col justify-center'>
                                  <p className='text-lg font-bold text-charcoal'>
                                    {content.shared?.presenter}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <SharedPostSnippet content={content.shared} users={users} />
            )}
          </div>
        </>
      ) : (
        <>
          <div className='px-4 w-full'>
            <div className='flex flex-row justify-start w-full'>
              <div className='flex justify-start'>
                <img
                  src={
                    user?.profilePicture?.publicUrl ||
                    `https://ui-avatars.com/api/?name=${
                      isInstitution
                        ? `${instituteRequired?.institutionName}`
                        : `${user?.firstname} ${user?.lastname}`
                    }&bold=true`
                  }
                  alt={user?.firstname}
                  className='object-cover rounded-full h-10 w-10 flex items-center justify-center mr-2 '
                />
              </div>
              <div className='flex flex-col px-3 w-full'>
                <div className='flex flex-row justify-between w-full'>
                  <div>
                    <p className='text-sm lg:text-base font-semibold font-body text-charcoal'>
                      {content.author || `${user?.firstname} ${user?.lastname}`}
                    </p>
                  </div>
                  <div>
                    <span className='text-xs font-medium font-body text-headingtext'>
                      {moment(content?.modifiedAt).local().fromNow()}
                    </span>
                  </div>
                </div>
                {content?.contentFile?.mimeType === 'application/pdf' ||
                content?.thumbnailFile?.publicUrl ||
                content?.contentFile?.publicUrl ||
                content?.link
                  ? hideMoreText && (
                      <p className='mb-2 text-xs sm:text-xs font-base text-headingtext '>
                        <Truncate
                          lines={2}
                          ellipsis={
                            <span>
                              ...
                              <span
                                className='cursor-pointer text-main text-xs font-semibold hover:text-navyblue px-4'
                                aria-label='see more'
                                aria-controls='see more'
                                aria-haspopup='true'
                                onClick={() => toggleHideMoreText()}
                              >
                                see {hideMoreText ? 'more' : 'less'}
                              </span>
                            </span>
                          }
                        >
                          {content.title || content?.postText}
                        </Truncate>
                      </p>
                    )
                  : null}
                <p
                  className='mb-2 text-xs sm:text-xs font-base text-headingtext'
                  hidden={hideMoreText}
                >
                  {content.title || content?.postText}
                </p>
              </div>
            </div>
          </div>

          <a href={contentLink()}>
            {content?.contentFile?.mimeType === 'application/pdf' ||
            content?.link ? (
              <div className='w-full h-64 sm:h-96 text-xs font-body text-charcoal px-4 pt-5 mb-2'>
                <iframe
                  width='100%'
                  height='100%'
                  title='post-attachment'
                  src={content?.link || content?.contentFile?.publicUrl}
                />
              </div>
            ) : content?.thumbnailFile?.publicUrl ||
              content?.contentFile?.publicUrl ? (
              <div className='w-full h-64 sm:h-96 text-xs font-body text-charcoal px-4 pt-5 mb-2'>
                <ContentPreview
                  fileUrl={
                    content?.thumbnailFile?.publicUrl ||
                    content?.contentFile?.publicUrl
                  }
                  profile_post
                />
              </div>
            ) : (
              <div
                style={{
                  fontSize: '15px',
                  height: 'auto',
                  paddingBottom: '20px',
                }}
                className='w-full sm:h-20 font-body text-charcoal px-20'
              >
                {content.title || content?.postText}
              </div>
            )}
          </a>
        </>
      )}
      {content.sharedModel === 'Session' ? null : (
        <>
          <div className='flex  mx-auto w-11/12 justify-start my-1'>
            <div className='text-xs text-headingtext mr-2'>{`${
              likes?.length > 0 ? likes?.length : content?.likes || '0'
            } likes`}</div>
            <div className='text-xs text-headingtext'>
              <FiberManualRecord fontSize='inherit' />
            </div>
            <div className='text-xs text-headingtext ml-2'>{`${
              comments?.length > 0
                ? comments.length
                : content?.comments?.length || '0'
            } comments`}</div>
          </div>

          <div
            style={{ borderColor: '#bcadeb' }}
            className='flex border  border-b border-lightgraybg mx-auto w-11/12 justify-center my-2'
          ></div>
          <div className='flex flex-row  w-full md:w-6/6 mt-2 text-sm'>
            {toShow ? (
              <IconsRole
                icon={<ArrowDownward fontSize='small' />}
                role='Pull Down'
                Action={() => updateStatus()}
              />
            ) : (
              <>
                <IconsRole
                  icon={<ThumbUp fontSize='small' />}
                  role='Like'
                  count={
                    likes?.length > 0
                      ? likes?.length
                      : content.likes?.length || 0
                  }
                  Action={addNewLike}
                />
                <IconsRole
                  icon={<Comment fontSize='small' />}
                  role='Comment'
                  count={
                    comments?.length > 0
                      ? comments.length
                      : content?.comments?.length || 0
                  }
                  Action={commentPostClick}
                />
                {!content?.shared ? (
                  <>
                    <IconsRole
                      icon={<Send fontSize='small' />}
                      role='Send'
                      Action={sendPostClick}
                    />
                    <IconsRole
                      icon={<Share fontSize='small' />}
                      role='Share'
                      Action={sharePostClick}
                    />
                  </>
                ) : null}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
