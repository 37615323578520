import React from 'react';
import { Link } from 'react-router-dom';
import PNG_academics from '../assets/Acadmics.svg';

export default function AdBanner() {
  return (
    <div className='relative px-4 py-4 lg:pt-4  mx-auto lg:py-10 md:px-8 xl:px-20 sm:max-w-xl md:max-w-full bg-bannerBlue'>
      <div className='w-8/12 lg:w-9/12 mx-auto lg:max-w-screen-xl h-full'>
        <div className='lg:max-w-lg lg:mb-0'>
          <div className='max-w-xl mb-6'>
            <div>
              <p className='inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-charcoal uppercase rounded-full bg-starsorange'>
                {`Don't Miss`}
              </p>
            </div>
            <h2 className='max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-black sm:text-4xl sm:leading-none'>
              The Job Readiness
              <br className='hidden md:block' />
              Skills Training
            </h2>
            <p className='text-base text-bannerSubtitle md:text-lg'>
              10th Feb 2021 | 9:00AM - 10:00PM
            </p>
          </div>
          <div className='flex items-center'>
            <Link to='/joinsession/69oae4452'>
              <span className='inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-charcoal transition duration-200 rounded shadow-md bg-white hover:bg-lightGrayBg2 focus:shadow-outline focus:outline-none'>
                Register Now
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className='lg:flex justify-center py-2 h-full overflow-hidden lg:w-2/3 xl:w-1/2 lg:absolute lg:justify-start lg:bottom-0 lg:right-0 lg:items-end hidden '>
        <img
          src={PNG_academics}
          className='object-scale-down w-full h-48 max-w-xl  rounded  lg:ml-64 xl:ml-8  lg:h-full lg:max-w-screen-md'
          alt=''
        />
      </div>
    </div>
  );
}
