import * as rateTypes from '../types/rate';
import * as fetchFunctions from '../../api/index';
import rateEndpoints from '../endpoints/rate';

export const rateContent = (payload) => async (dispatch, getState) => {
  dispatch({
    type: rateTypes.RATE_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.putData(
    rateEndpoints.rate_content,
    payload,
    token,
  );

  if (result?.success) {
    dispatch({
      type: rateTypes.RATE_SUCCESS,
      payload: {
        status: true,
        message: result?.response?.success,
      },
    });
  } else {
    dispatch({
      type: rateTypes.RATE_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const rateSession = (payload) => async (dispatch, getState) => {
  dispatch({
    type: rateTypes.RATE_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.putData(
    rateEndpoints.rate_session,
    payload,
    token,
  );

  if (result?.success) {
    dispatch({
      type: rateTypes.RATE_SUCCESS,
      payload: {
        status: true,
        message: result?.response?.success,
      },
    });
  } else {
    dispatch({
      type: rateTypes.RATE_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
