import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InlineWidget } from 'react-calendly';

import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import NavHeader from '../components/NavBarHeader';
import { Check } from '@material-ui/icons';
import RelatedContent from '../components/RelatedContent';

import * as contentActions from '../redux/actions/content';

export default function BookSession() {
  const user = useSelector((state) => state?.user?.user);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const articleID = '602cd9f05d22b10017846ec1';
  const article = useSelector((state) => state.content.data);

  useEffect(() => {
    // Article is stored in an array data type
    if (article?.length === 0) {
      dispatch(contentActions.getSpecificContent(articleID));
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [article, articleID, dispatch]);

  const [showStep1, setShowStep1] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      {user ? <Nav /> : <NavHeader />}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div className='h-full'>
          <div className='bg-lightgraybg w-full h-full '>
            <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
                <div className='flex flex-col mb-6 lg:justify-center md:mb-6'>
                  {showStep1 && (
                    <SessionCard
                      setShowConfirmation={setShowConfirmation}
                      setShowStep1={setShowStep1}
                    />
                  )}
                  {showConfirmation && (
                    <SubmissionConfirmation
                      setShowConfirmation={setShowConfirmation}
                      setShowStep1={setShowStep1}
                    />
                  )}
                  <div className='mt-6'>
                    <RelatedContent type={'Article'} typeID={article?._id} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const SessionCard = ({ setShowConfirmation, setShowStep1 }) => {
  return (
    <>
      <div className='mb-4'>
        <InlineWidget url='https://calendly.com/walihiwalil/webinar-1' />
      </div>
      <div className='flex flex-col lg:flex-row justify-between my-6'>
        <span className='inline-flex items-center cursor-pointer justify-center h-14 px-16 mb-4 font-semibold tracking-wide text-headingtext transition duration-200 rounded-lg border-2 border-headingtext hover:text-white bg-none hover:bg-textsecondary hover:bg-opacity-60 hover:border-lightgraybg focus:shadow-outline focus:outline-noone'>
          Back
        </span>
        <span
          className='inline-flex items-center cursor-pointer justify-center h-14 px-4 lg:px-16 mb-4 font-semibold tracking-wide text-white transition duration-200 rounded-lg  hover:text-white bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
          onClick={() => {
            setShowStep1(false);
            setShowConfirmation(true);
          }}
        >
          Finish
        </span>
      </div>
    </>
  );
};

const SubmissionConfirmation = ({ setShowConfirmation, setShowStep1 }) => {
  return (
    <div className='h-full '>
      <div className='mb-10 text-center md:mb-4 lg:mb-4 bg-white border border-linecolor flex flex-col'>
        <div className=' py-2 lg:mx-48 sm:max-w-full md:max-w-full lg:max-w-full md:px-0 lg:px-0 lg:py-4 min-h-80 my-6'>
          <div className=' w-full sm:mx-auto'>
            <div className='flex flex-col justify-around lg:justify-start'>
              <div className='max-w-full mb-4 lg:mb-10 text-center '>
                <div className='flex flex-row justify-center w-full mb-8'>
                  <div className='flex flex-col justify-center w-28 h-28 rounded-full bg-green3'>
                    <div className=' text-white text-6xl'>
                      <Check fontSize='inherit' />
                    </div>
                  </div>
                </div>
                <div className='flex flex-col justify-center w-full'>
                  <h2 className='max-w-full mb-4 text-xl font-bold tracking-tight text-charcoal sm:text-2xl sm:leading-none'>
                    {'Session Booked'}
                  </h2>
                  <span className='w-full px-4 lg:px-32 mb-6 text-base text-center font-base  text-headingtext sm:text-base '>
                    {`You and your mentor Jonathan Doe  will receive a comfirmation email with the session details and calendar invitation.`}
                  </span>
                </div>
              </div>
              <div className='flex flex-row justify-around mb-6 px-4 lg:px-0 '>
                <a
                  href='/'
                  className=' w-full inline-flex items-center text-sm lg:text-base justify-center h-14 px-4 lg:px-16 font-semibold tracking-wide text-headingtext transition duration-200 rounded-lg border-2 border-headingtext hover:text-white bg-none hover:bg-textsecondary hover:bg-opacity-60 hover:border-lightgraybg focus:shadow-outline focus:outline-noone'
                >
                  View upcoming sessions
                </a>
              </div>
              <div className='flex flex-row justify-around mb-6 px-4 lg:px-0 '>
                <span
                  className='w-full px-4 lg:px-32 mb-6 text-base text-center font-base  text-headingtext sm:text-base hover:text-darkerblue cursor-pointer'
                  onClick={() => {
                    setShowStep1(true);
                    setShowConfirmation(false);
                  }}
                >
                  {`Change of mind? Reschedule the  session`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
