import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import 'react-multi-carousel/lib/styles.css';
import '../bright.css';
import DisplayStarRating from '../components/StarRating';
import ChatGPTModal from '../components/ChatGPT';
import ChatGPTButton from '../components/ChatGPTButton';
import AvatarPlaceholder from '../assets/avatar-placeholder.svg';
import * as commentsActions from '../redux/actions/comments';
import Carousel from 'react-multi-carousel';
import Explore from '../components/Explore';
import UpcomingEventCategory from '../components/UpcomingEventCategory';
// import UserPointsModal from '../components/UserPointsModal';

// import my_earned_points from '../assets/my_earned_points.png';
// import redeem_image from '../assets/redeem.jpg';

// components
import Footer from '../components/NewFooter';
import NavHeaderLanding from '../components/NewNavbar';

// Actions
import * as searchActions from '../redux/actions/search';
// import * as userActions from '../redux/actions/users';

export default function LandingPage() {
  const [showChatGPTModal, setShowChatGPTModal] = useState(false);

  return (
    <>
      <ChatGPTModal
        setShowChatGPTModal={setShowChatGPTModal}
        showChatGPTModal={showChatGPTModal}
      />
      <div>
        <div className='flex flex-col'>
          <NavHeaderLanding background={'bg-newMain'} />
          <div className='w-full h-full bg-lightgraybg'>
            <Header />
          </div>
          <div className='w-full h-full bg-lightgraybg'>
            <LinksSection setShowChatGPTModal={setShowChatGPTModal} />
          </div>
          <div className='bg-lightgraybg w-full'>
            <Explore setShowChatGPTModal={setShowChatGPTModal} />
          </div>

          <div>
            <MoreSection />
          </div>

          {/*<div>
            <AdsSection />
          </div>*/}

          <div>
            <StickySection />
          </div>

          <div>
            <Testimonials />
          </div>
          <div>
            <UpcomingEventCategory />
          </div>

          <div className='w-full'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [keywords, setKeywords] = useState('');

  const handleSubmit = () => {
    dispatch(searchActions.search({ keywords, skip: 0, limit: 20 }));
    history.push('/search');
  };

  return (
    <div className='relative'>
      <div className='landingBannerWrapper md:mb-20'>
        <div className='landingBanner'>
          <video autoPlay muted loop id='landingVideo' className='h-full'>
            <source
              src='https://vid.cdn-website.com/09b19d04/videos/gMfareYSTjy99cxmtEeI_Delv-Careers-Hero-Reel-v.mp4'
              type='video/mp4'
            />
          </video>
          <div className='landing-content'>
            <div className='landingBannerContent w-full mx-10 md:mx-0 md:w-1/2 flex flex-col justify-center md:justify-start mx-auto md:mx-0 md:ml-10'>
              <div className='md:ml-20 lg:ml-28 px-6 md:px-1'>
                <div className='w-full pt-40 md:pt-24 lg:pt-44 xl:pt-56'>
                  <h1 className='text-5xl center-left rethink font-bold md:text-7xl md:mr-16 lg:mr-1  my-4 Cabin'>
                    Let&apos;s rethink your career together
                  </h1>
                  <h1 className='center-left mentors text-xl lg:text-3xl md:pr-4 lg:pr-10 md:px-1 open-sans mb-8'>
                    Connect with peers, mentors, counsellors, trainers, and
                    skill-up for the 21st century.
                  </h1>
                  <div className='mb-4 w-full flex flex-row justify-center md:justify-start'>
                    <Link to={`/signup`} className=''>
                      <div className='px-5 py-4 w-64 categoryLinkTag bg-navyblue text-white rounded-lg text-headingtext'>
                        <div className='flex flex-row justify-center'>
                          <p className='text-white'>START NOW</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='block-hidden w-full formWrappersm'>
          <div className='w-full py-8 px-2 bg-white'>
            <div className='mx-auto px-2 md:px-10'>
              <form onSubmit={handleSubmit}>
                <input
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  className='searchInput w-full px-3 rounded-input Cabin'
                  placeholder='What do you want to learn today?'
                />
              </form>
            </div>
          </div>
        </div>

        <div className='formWrapper w-full'>
          <div className='hidden-block w-full'>
            <div className='w-10/12 mx-auto formSubWrapper bg-white py-10'>
              <div className='topBannerForm mx-auto px-10'>
                <form onSubmit={handleSubmit}>
                  <input
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                    className='searchInput w-full px-3 rounded-input Cabin'
                    placeholder='what do you want to learn today?'
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LinksSection = (props) => {
  return (
    <>
      <div className='px-8 md:px-2 lg:px-8 mt-4 mb-8 w-full bg-gray-100'>
        <div className='w-full flex justify-center align-center'>
          <div className='grid gap-y-4 md:gap-x-5 lg:gap-x-8 grid-cols-1 md:grid-cols-3 w-full  md:w-11/12 lg:w-3/4'>
            <LinkButton
              title='CAREER GUIDANCE'
              link='careerguidance'
              setShowChatGPTModal={props.setShowChatGPTModal}
            />
            <LinkButton
              title='UPSKILL'
              link='upskilling'
              setShowChatGPTModal={props.setShowChatGPTModal}
            />
            <LinkButton
              title='SEARCH JOBS'
              link='Jobsearch'
              setShowChatGPTModal={props.setShowChatGPTModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const MoreSection = () => {
  return (
    <div className='h-full w-full  px-1 md:px-8 lg:px-24 py-8 md:py-36 bg-more-background'>
      <h1 className='text-center more-header text-white font-semibold Cabin md:font-bold md:my-10'>
        More about what we offer
      </h1>

      <div className='my-4 md:my-10 grid grid-cols-1 md:gap-x-5 md:grid-cols-3'>
        <div className='flex flex-col w-full'>
          <div className='w-full'>
            <div
              className='w-full more-image-wrapper overflow-hidden'
              style={{ width: '100%' }}
            >
              <img
                className='more-image'
                alt='something'
                style={{ width: '100%' }}
                src='https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-1181605-1280w.jpeg'
              />
            </div>
          </div>
          <div className='bg-white w-full'>
            <div className='text-center h-16'>
              <h1 className='methods-description text-2xl my-4 Cabin'>
                Career Guidance
              </h1>
            </div>
            <div className='text-center h-24 md:h-32 lg:h-24'>
              <p className='methods-description more-card-description open-sans px-4'>
                Discover yourself, connect with peers, mentors, counsellors.
                Take charge of your career path.
              </p>
            </div>
            <div className='flex justify-center my-4'>
              <Link
                to={`careerguidance`}
                className='w-11/12 mx-auto md:w-48 lg:w-60'
              >
                <div className='px-4 md:px-3 lg:px-5 py-4 w-full md:w-48 lg:w-60 categoryLinkTag bg-navyblue text-white rounded-lg text-headingtext'>
                  <div className='flex flex-row justify-center'>
                    <p className='text-white category-spacing open-sans font-weight-300'>
                      LEARN MORE
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='w-full'>
            <div
              className='w-full overflow-hidden more-image-wrapper'
              style={{ width: '100%' }}
            >
              <img
                className='more-image'
                style={{ height: '280px', width: '100%' }}
                src='https://lirp.cdn-website.com/09b19d04/dms3rep/multi/opt/shutterstock_1343481125-1280w.jpg'
                alt='something'
              />
            </div>
          </div>
          <div className='bg-white w-full'>
            <div className='text-center h-16'>
              <h1 className='methods-description text-2xl my-4 Cabin'>
                UpSkill
              </h1>
            </div>
            <div className='text-center h-24 md:h-32 lg:h-24'>
              <p className='methods-description more-card-description open-sans px-4'>
                Power your career with the right skills. Acquire in demand
                skills for today and tomorrow
              </p>
            </div>
            <div className='flex justify-center my-4'>
              <Link
                to={`upskilling`}
                className='w-11/12 mx-auto md:w-48 lg:w-60'
              >
                <div className='px-4 md:px-3 lg:px-5 py-4 w-full md:w-48 lg:w-60 categoryLinkTag bg-navyblue text-white rounded-lg text-headingtext'>
                  <div className='flex flex-row justify-center'>
                    <p className='text-white category-spacing open-sans font-weight-300'>
                      LEARN MORE
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='w-full'>
            <div
              className='more-image-wrapper w-full overflow-hidden'
              style={{ width: '100%' }}
            >
              <img
                className='more-image'
                alt='something'
                style={{ width: '100%' }}
                src='https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-9429371-1280w.jpeg'
              />
            </div>
          </div>
          <div className='bg-white w-full'>
            <div className='text-center h-16'>
              <h1 className='methods-description text-2xl my-4 Cabin'>
                Job Search
              </h1>
            </div>

            <div className='text-center h-24 md:h-32 lg:h-24'>
              <p className='methods-description more-card-description open-sans px-4'>
                Discover job opportunities. Know what the job market is looking
                for
              </p>
            </div>
            <div className='flex justify-center my-4'>
              <Link
                to={`Jobsearch`}
                className='w-11/12 mx-auto md:w-48 lg:w-60'
              >
                <div className='px-4 md:px-3 lg:px-5 py-4 w-full md:w-48 lg:w-60 categoryLinkTag bg-navyblue text-white rounded-lg text-headingtext'>
                  <div className='flex flex-row justify-center'>
                    <p className='text-white category-spacing open-sans font-weight-300'>
                      LEARN MORE
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StickySection = () => {
  return (
    <div className='w-full sticky-section'>
      <div className='w-full helping' style={{ background: 'rgba(0,0,0,0.4)' }}>
        <div className='pt-40 md:pt-80 px-8 md:px-0'>
          <div className='w-full mt-26 md:w-5/12 lg:w-1/2 flex flex-col ml-0 md:ml-24 w-full md:w-3/4'>
            <h1 className='center-left font-semibold lg:font-semibold text-4xl md:text-7xl join Cabin'>
              Helping people grow their careers every day!
            </h1>
            <p className='center-left my-4 trainer text-2xl md:text-2xl lg:text-3xl pr-1 md:pr-2 lg:pr-4 open-sans font-small'>
              Power your career with in demand skills for the 21st century
            </p>
            <Link
              className='mx-auto md:ml-0 categoryLinkTag py-4 px-10 md:px-16 my-2 mt-4 md:mt-5 category-spacing'
              to={`upskilling`}
            >
              GET STARTED
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialCard = ({ feedback }) => {
  return (
    <div className='mx-6 mb-4 lg:mb-8'>
      <div className='duration-300 transform bg-white border-l-4 border-darkerblue hover:-translate-y-1  h-full sm:h-52  rounded-tr-3xl shadow-sm'>
        <div className='h-full p-5 text-headingtext'>
          <div className='flex flex-col sm:flex-row justify-between mb-4'>
            <div className='flex flex-row justify-start'>
              <div className=' w-20 h-20 mb-3 sm:mb-0'>
                <img
                  className='rounded-full object-cover h-full w-full shadow-md'
                  src={
                    feedback?.commentedBy?.profilePicture?.publicUrl ||
                    AvatarPlaceholder
                  }
                  alt='user-profile-pictue'
                />
              </div>
              <div className='flex flex-col justify-center ml-4'>
                <p className='text-lg font-display capitalize'>{`${
                  feedback?.commentedBy?.firstname || 'Anonymous'
                } ${feedback?.commentedBy?.lastname || 'user'} `}</p>
                <p className='text-sm capitalize font-display'>
                  {
                    feedback?.commentedBy?.usercategory[
                      feedback?.commentedBy?.usercategory.length - 1
                    ]
                  }
                </p>
              </div>
            </div>
            <span className='mr-8 flex items-baseline'>
              {/* Rating is saved in commentAbout */}
              <DisplayStarRating selectStars={Number(feedback?.commentAbout)} />
            </span>
          </div>
          <div className='h-32 sm:h-20 overflow-y-auto'>
            <span className='text-sm '>{feedback?.commentText}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialButtonNavigate = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className='text-center flex flex-row justify-end carousel-button-group '>
      <button
        className={
          currentSlide === 0
            ? 'hidden'
            : 'mx-2 inline-flex items-center justify-center w-full h-10 px-4 font-medium tracking-wide text-headingtext transition duration-200 rounded shadow-md md:w-auto bg-white   hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
        }
        disabled={currentSlide === 0}
        onClick={() => previous()}
      >
        <ArrowBack />
      </button>
      <button
        className='mx-2 inline-flex items-center justify-center w-full h-10 px-4 font-medium tracking-wide text-headingtext transition duration-200 rounded shadow-md md:w-auto bg-white   hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
        onClick={() => next()}
      >
        <ArrowForward />
      </button>
    </div>
  );
};

const Testimonials = () => {
  const dispatch = useDispatch();
  const delvFeedback = useSelector((state) => state.comments?.itemComments);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    dispatch(commentsActions.itemComments('delv-platform'));
  }, []);

  return (
    <div className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
      <div className='max-w-xl mb-10  sm:text-left lg:max-w-full md:mb-12 flex flex-col lg:flex-row lg:justify-between'>
        <h2 className='w-full mb-6 font-display text-3xl  leading-none tracking-tight text-black sm:text-4xl '>
          <span className='relative inline-block'>
            <span className='relative'>What </span>
          </span>{' '}
          people have to say
        </h2>
      </div>

      <div className='flex-row justify-start py-6'>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          transitionDuration={500}
          customButtonGroup={<TestimonialButtonNavigate next previous rest />}
          removeArrowOnDeviceType={[
            'mobile',
            'tablet',
            'desktop',
            'superLargeDesktop',
          ]}
          renderButtonGroupOutside={true}
        >
          {delvFeedback?.map((value) => (
            <TestimonialCard key={value?._id} feedback={value} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

function LinkButton({ title, link, setShowChatGPTModal }) {
  return (
    <div className='my-3 md:my-0 w-full md:w-70 md:px-4'>
      <Link to={`${link}`}>
        <div className='px-5 py-4 duration-300 transform  categoryLinkTag bg-navyblue text-white rounded-lg text-headingtext'>
          <div className='flex flex-row justify-center'>
            <p className='text-white'>{title}</p>
          </div>
        </div>
      </Link>
      <ChatGPTButton
        setShowChatGPTModal={setShowChatGPTModal}
        marginLeft='35%'
      />
    </div>
  );
}

/*const AdsSection = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const points = useSelector((state) => state.user?.user_points);
  const { user } = useSelector((state) => state?.user);
  const [showUserPointsModal, setShowUserPointsModal] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(userActions.getUserPoints({ userId: user?._id }));
    }
  }, [user]);

  function handleChange(user) {
    if (user) {
      setShowUserPointsModal(true);
    } else {
      history.push('/login');
    }
  }

  return (
    <>
      <UserPointsModal
        setShowUserPointsModal={setShowUserPointsModal}
        showUserPointsModal={showUserPointsModal}
        points={points}
      />
      <div className='h-full w-full  px-1 md:px-8 lg:px-24 py-8 md:py-36 '>
        <h1 className='text-center more-header text-darkerblue font-semibold Cabin md:font-bold md:my-10'>
          Earn Points with Delv
        </h1>

        <div className='my-4 md:my-10 grid grid-cols-1 md:gap-x-5 md:grid-cols-3'>
          <div className='bg-lightgraybg flex flex-col w-full py-5'>
            <div className='w-full'>
              <div className='text-center h-16'>
                <h1 className='methods-description text-2xl my-4 Cabin'>
                  How To Earn Points
                </h1>
              </div>
              <div className='text-justify sm:h-80 lg:h-80'>
                <p className='methods-description more-card-description open-sans px-4'>
                  <b>Post knowledgeable content </b> in form of Images, Text,
                  Youtube videos or PDF that can help your fellow colleagues
                  gain more knowledge in one way or the other.
                </p>
                <br />
                <p className='methods-description more-card-description open-sans px-4'>
                  <b>Invite a friend </b>to join Delv through an invitation,
                  then helping this friend sign up successfully will earn you
                  more points.
                </p>
                <br />
                <p className='methods-description more-card-description open-sans px-4'>
                  <b>Share a post on social media</b> from Delv, getting atleast
                  10 likes on the shared post will earn you more extra points.
                </p>
                <br />
                <p className='methods-description more-card-description open-sans px-4'>
                  <b>Signing up on Delv </b> will automatically earn you more
                  points.
                </p>
              </div>
            </div>
          </div>
          <div className='flex flex-col py-1'>
            <div className='w-full'>
              <div
                className='w-full overflow-hidden more-image-wrapper'
                style={{ width: '100%', textAlign: 'center' }}
              >
                <img
                  style={{
                    border: '1px solid #F2F5F9',
                    borderRadius: '5px',
                  }}
                  src={my_earned_points}
                  alt='something'
                />
              </div>
            </div>
            <div className='bg-lightgraybg w-full'>
              <div className='text-center h-16'>
                <h1 className='methods-description text-2xl my-4 Cabin'>
                  Points per Activity
                </h1>
              </div>
              <div className='text-center h-24 md:h-32 lg:h-24'>
                <p className='methods-description more-card-description open-sans px-4'>
                  These are the points earned when an activity is fully
                  accomplished.
                </p>
              </div>
              <div className='flex justify-center my-4'>
                <button
                  onClick={() => {
                    handleChange(user);
                  }}
                  className='w-11/12 mx-auto md:w-48 lg:w-60'
                >
                  <div className='px-4 md:px-3 lg:px-5 py-4 w-full md:w-48 lg:w-60 categoryLinkTag bg-navyblue text-white rounded-lg text-headingtext'>
                    <div className='flex flex-row justify-center'>
                      <p className='text-white category-spacing open-sans font-weight-300'>
                        MY POINTS
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='w-full'>
              <div
                className='more-image-wrapper w-full overflow-hidden'
                style={{ width: '100%' }}
              >
                <img
                  className='more-image'
                  alt='something'
                  style={{ width: '100%' }}
                  src={redeem_image}
                />
              </div>
            </div>
            <div className='bg-lightgraybg w-full'>
              <div className='text-center h-16'>
                <h1 className='methods-description text-2xl my-4 Cabin'>
                  How To Redeem Points
                </h1>
              </div>

              <div className='text-center h-24 md:h-32 lg:h-24'>
                <p className='methods-description more-card-description open-sans px-4'>
                  Points earned are redeemable as Airtime, Internet Data or Cash
                  when you reach 2000 points
                </p>
              </div>
              <div className='flex justify-center my-4'>
                <button
                  onClick={() => {
                    handleChange(user);
                  }}
                  className='w-11/12 mx-auto md:w-48 lg:w-60'
                >
                  <div className='px-4 md:px-3 lg:px-5 py-4 w-full md:w-48 lg:w-60 categoryLinkTag bg-navyblue text-white rounded-lg text-headingtext'>
                    <div className='flex flex-row justify-center'>
                      <p className='text-white category-spacing open-sans font-weight-300'>
                        REDEEM
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};*/
