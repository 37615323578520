import { ArrowBackIos } from '@material-ui/icons';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import FeedbackModal from './ContentFeedBack';
import logo from '../assets/DelvLogo.svg';

const ContentViewNav = ({ contentType, content }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const user = useSelector((state) => state.user.user);

  return (
    <>
      <div className='w-full bg-white sticky top-0 shadow-md z-40'>
        <div className='px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 '>
          <div className='relative  grid items-center grid-cols-2 md:grid-cols-2 '>
            <ul className='flex items-center hidden space-x-8 lg:flex'>
              <li>
                <button onClick={() => window.history.back()}>
                  <div
                    aria-label='Back'
                    title='Back'
                    className='font-medium tracking-wide text-charcoal transition-colors duration-200 hover:text-black'
                  >
                    <ArrowBackIos fontSize='small' />
                    <span className='ml-2 pt-2'>Back</span>
                  </div>
                </button>
              </li>
            </ul>

            <ul className='flex items-center hidden ml-auto space-x-8 lg:flex '>
              {content ? (
                <li>
                  <span
                    className='cursor-pointer inline-flex items-center justify-center h-10 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                    aria-label='Rate Video'
                    title='Rate'
                    aria-controls='Rate'
                    aria-haspopup='true'
                    onClick={() => {
                      setShowFeedbackModal(true);
                    }}
                  >
                    Review this {contentType}
                  </span>
                </li>
              ) : (
                <Link
                  to={`${
                    user.usercategory[0] === 'institution'
                      ? '/institution/about '
                      : '/profile'
                  }`}
                >
                  <span className='font-medium tracking-wide text-darkerblue transition-colors duration-200 hover:text-navy-blue'>
                    Go to Profile
                  </span>
                </Link>
              )}
            </ul>
            <div className='ml-auto lg:hidden md:col-span-2'>
              <button
                aria-label='Open Menu'
                title='Open Menu'
                className='p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50'
                onClick={() => setIsMenuOpen(true)}
              >
                <svg className='w-5 text-gray-600' viewBox='0 0 24 24'>
                  <path
                    fill='currentColor'
                    d='M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z'
                  />
                  <path
                    fill='currentColor'
                    d='M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z'
                  />
                  <path
                    fill='currentColor'
                    d='M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z'
                  />
                </svg>
              </button>
              {isMenuOpen &&
                content(
                  <div className='absolute top-0 left-0 w-full'>
                    <div className='p-5 bg-white border rounded shadow-sm'>
                      <div className='flex items-center justify-between mb-4'>
                        <div>
                          <Link to='/'>
                            <div
                              aria-label='Delv Careers'
                              title='Delv Careers'
                              className=' flex justify-items-end lg:mx-auto w-24 h-auto sm:'
                            >
                              <img
                                src={logo}
                                alt='delv_logo'
                                className='object-scale-down'
                              />
                            </div>
                          </Link>
                        </div>
                        <div>
                          <button
                            aria-label='Close Menu'
                            title='Close Menu'
                            className='p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <svg
                              className='w-5 text-gray-600'
                              viewBox='0 0 24 24'
                            >
                              <path
                                fill='currentColor'
                                d='M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z'
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <nav>
                        <ul className='space-y-4'>
                          <li>
                            <span
                              className='cursor-pointer inline-flex items-center justify-center h-10 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                              aria-label='Rate Video'
                              title='Rate'
                              aria-controls='Rate'
                              aria-haspopup='true'
                              onClick={() => {
                                setShowFeedbackModal(true);
                              }}
                            >
                              Review this {contentType}
                            </span>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>,
                )}
            </div>
          </div>
        </div>
      </div>
      <FeedbackModal
        content={content}
        showFeedbackModal={showFeedbackModal}
        setShowFeedbackModal={setShowFeedbackModal}
      />
    </>
  );
};

export default ContentViewNav;
