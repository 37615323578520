import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Add, ArrowBack } from '@material-ui/icons';

import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import EventsSection from '../components/EventsSection';
import UploadSessionModal from '../components/UploadSession';

// Actions
import * as sessionsActions from '../redux/actions/sessions';

const MySessions = () => {
  return (
    <>
      <Nav />
      <PageBody />
    </>
  );
};

const PageBody = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.sessions.loading);
  const mySessions = useSelector((state) => state.sessions.mySessions);
  const { user } = useSelector((state) => state?.user);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);

  useEffect(() => {
    dispatch(sessionsActions.getMySessions());
  }, []);

  return (
    <>
      <div>
        {loading ? (
          <div className='bg-lightgraybg h-screen w-full fixed'>
            <Loading />
          </div>
        ) : (
          <div className={`bg-lightgraybg w-full h-full`}>
            <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
                <div className='flex justify-start w-full pb-5 lg:w-full mb-1 text-gray '>
                  <button onClick={() => window.history.back()}>
                    <ArrowBack fontSize='medium' />{' '}
                    <span className='ml-2'>Back </span>
                  </button>
                </div>
                <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                  <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                    <span className='inline-block mb-1 sm:mb-4'>
                      My Sessions ({mySessions.length})
                    </span>
                  </h2>
                  <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                    {user.usercategory[0] === 'mentor' ? (
                      <button
                        onClick={() => setShowCreateEventModal(true)}
                        className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'
                      >
                        <Add />
                        <span className='ml-2'>Create Session</span>
                      </button>
                    ) : null}
                  </div>
                </div>
                <EventsSection eventList={mySessions} />;
              </div>
            </div>
          </div>
        )}
      </div>
      <UploadSessionModal
        setShowCreateEventModal={setShowCreateEventModal}
        showCreateEventModal={showCreateEventModal}
        userId={user._id}
      />
      ;
    </>
  );
};
export default MySessions;
