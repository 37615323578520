import React from 'react';
import image from '../assets/32.svg';
import NavHeaderLanding from '../components/NavBarLanding';

const NotFound = () => {
  return (
    <>
      <NavHeaderLanding />
      <div className='relative flex flex-col px-4 py-16 mx-auto lg:block lg:flex-col lg:py-32 xl:py-48 md:px-8 sm:max-w-xl md:max-w-full bg-lightgraybg  lg:h-screen'>
        <div className='z-0 flex justify-center h-full lg:-mx-10 mb-4 overflow-hidden lg:pt-24 lg:pb-16 lg:pr-8 xl:pr-0 lg:w-1/2 lg:absolute lg:justify-end lg:bottom-7 lg:left-0 lg:items-center'>
          <img
            src={image}
            className='object-cover object-left w-10/12 h-auto lg:w-9/12 lg:h-auto'
            alt=''
          />
        </div>
        <div className='relative flex justify-end max-w-xl mx-auto xl:pr-32 lg:max-w-screen-xl'>
          <div className='mb-16 lg:pr-5 lg:max-w-lg lg:mb-0'>
            <div className='max-w-xl mb-6'>
              <div>
                <p className='inline-block px-8 py-1 mb-4 text-lg font-semibold tracking-wider text-darkerblue uppercase rounded-lg border-2 border-darkerblue'>
                  OOPS!
                </p>
              </div>
              <h2 className='max-w-lg mb-6   text-3xl font-bold tracking-tight text-charcoal sm:text-4xl sm:leading-none'>
                We {`can't`} seem
                <br className='hidden md:block' />
                to find the page{' '}
                <span className='inline-block text-deep-purple-accent-400'>
                  you were looking for.
                </span>
              </h2>
              <p className='font-semibold  text-charcoal text-lg md:text-lg'>
                The page you are looking for. <br className='hidden md:block' />
                might be removed or is temporarily unavailable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
