import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import NotFound from '../assets/25.svg';
import NavHeaderLanding from '../components/NavBarLanding';
import { postData } from '../api';

const Coming = () => {
  const [user, setUser] = useState({
    name: '',
    email: '',
  });

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postData(`${process.env.REACT_APP_BACKEND_URL}/mailing-list`, user);
    setUser({ name: '', email: '' });
  };

  let { pageType } = useParams();
  const booksession = pageType === 'booksession';

  return (
    <>
      <NavHeaderLanding />
      <div className='relative flex flex-col px-4 py-16 mx-auto lg:block lg:flex-col lg:py-32 xl:py-48 md:px-8 sm:max-w-xl md:max-w-full bg-lightgraybg  lg:h-screen'>
        <div className='flex justify-center h-full lg:-mx-10 mb-4 overflow-hidden lg:pt-24 lg:pb-16 lg:pr-8 xl:pr-0 lg:w-1/2 lg:absolute lg:justify-end lg:bottom-7 lg:left-0 lg:items-center'>
          <img
            src={NotFound}
            className='object-cover object-left w-10/12 h-auto lg:w-9/12 lg:h-auto'
            alt=''
          />
        </div>
        <div className='relative flex justify-end max-w-xl mx-auto lg:pr-10 lg:max-w-screen-xl'>
          <div className='mb-16 lg:pr-5 lg:max-w-lg lg:mb-0'>
            <div className='max-w-xl mb-6'>
              <div>
                <p className='inline-block px-8 py-1 mb-4 text-lg font-semibold tracking-wider text-darkerblue uppercase rounded-lg border-2 border-darkerblue'>
                  {pageType
                    ? booksession
                      ? `book session`
                      : 'live chat'
                    : `Coming soon`}
                </p>
              </div>
              {pageType ? (
                <>
                  <h2 className='max-w-lg mb-6   text-3xl font-bold tracking-tight text-charcoal sm:text-4xl sm:leading-none'>
                    Reach out to us.
                  </h2>
                  <p className='font-semibold  text-charcoal text-lg md:text-lg mb-2'>
                    This page is currently unavailable. However,{' '}
                    <br className='hidden md:block' />
                    you can still{' '}
                    {booksession
                      ? ` book a session`
                      : 'set up a live chat'}{' '}
                    through the following means.
                  </p>
                  <div className='grid lg:grid-cols-2 grid-cols-1'>
                    <a
                      href='mailto: delvinfo@delvcareers.com'
                      aria-label='Send Email'
                      title='Send Email'
                      className='mb-2 inline-flex items-center justify-center h-12 px-6 mr-6 font-semibold tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                    >
                      Send Email
                    </a>

                    <a
                      href='tel:077-600-7707'
                      aria-label='Our phone'
                      title='Our phone'
                      className='mb-2 inline-flex items-center justify-center h-12 px-6 mr-6 font-semibold tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                    >
                      {`Call:  0776007707`}
                    </a>
                  </div>

                  {/* <p className='font-semibold  text-charcoal text-lg md:text-lg'>
                    <a
                      href='mailto: delvinfo@delvcareers.com'
                      aria-label='Send Email'
                      title='Send Email'
                      className='inline-flex items-center justify-center h-12 px-6 mr-6 font-semibold font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                    >
                      Send Email
                    </a>
                    <br className='hidden md:block' />
                    Mobile:
                    <a
                      href='tel:850-123-5021'
                      aria-label='Our phone'
                      title='Our phone'
                      className='transition-colors duration-300 hover:text-navyblue'
                    >
                      +256776007707
                    </a>
                  </p> */}
                </>
              ) : (
                <>
                  <h2 className='max-w-lg mb-6   text-3xl font-bold tracking-tight text-charcoal sm:text-4xl sm:leading-none'>
                    This page will be
                    <br className='hidden md:block' />
                    brought online{' '}
                    <span className='inline-block text-deep-purple-accent-400'>
                      soon
                    </span>
                  </h2>
                  <p className='font-semibold  text-charcoal text-lg md:text-lg'>
                    Reach out to us for more updates.{' '}
                    <br className='hidden md:block' />
                    Please Subscribe to our mailing list for regular updates.
                  </p>
                </>
              )}
            </div>
            {pageType ? (
              <></>
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  <div className='flex flex-col md:flex-row'>
                    <input
                      placeholder='Name'
                      required=''
                      type='text'
                      className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg  text-headingtext font-semibold border  border-linecolor rounded appearance-none md:mr-2 focus:border-darkerblue focus:outline-noone focus:shadow-outline'
                      name='name'
                      value={user.name}
                      onChange={handleChange}
                    />
                    <input
                      placeholder='Email'
                      required=''
                      type='text'
                      className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg  text-headingtext font-semibold border  border-linecolor rounded appearance-none md:mr-2 focus:border-darkerblue focus:outline-noone focus:shadow-outline'
                      name='email'
                      value={user.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='flex items-center mt-4'>
                    <button
                      type=''
                      className='inline-flex items-center justify-center h-12 px-6 mr-6 font-semibold  tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                    >
                      Subscribe
                    </button>
                    <p className=' hidden sm:flex text-base text-headingtext md:text-lg font-semibold mx-6'>
                      Follow us
                    </p>
                    <div className='grid grid-reverse grid-cols-3 gap-5 justify-items-end'>
                      {/* <a

                  href='#'
                  className='text-white transition-colors duration-300'
                >
                  <span className='rounded-full h-12 w-12 flex items-center justify-center  text-white'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 48 48'
                      width='48px'
                      height='48px'
                    >
                      <path
                        fill='#039be5'
                        d='M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z'
                      />
                      <path
                        fill='#fff'
                        d='M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z'
                      />
                    </svg>
                  </span>
                </a> */}
                      <a
                        href='https://twitter.com/DELV68746992'
                        className='text-gray-500 transition-colors duration-300'
                      >
                        <span className='rounded-full h-12 w-12 flex items-center justify-center text-white'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 48 48'
                            width='48px'
                            height='48px'
                          >
                            <path
                              fill='#03a9f4'
                              d='M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z'
                            />
                            <path
                              fill='#fff'
                              d='M36,17.12c-0.882,0.391-1.999,0.758-3,0.88c1.018-0.604,2.633-1.862,3-3 c-0.951,0.559-2.671,1.156-3.793,1.372C31.311,15.422,30.033,15,28.617,15C25.897,15,24,17.305,24,20v2c-4,0-7.9-3.047-10.327-6 c-0.427,0.721-0.667,1.565-0.667,2.457c0,1.819,1.671,3.665,2.994,4.543c-0.807-0.025-2.335-0.641-3-1c0,0.016,0,0.036,0,0.057 c0,2.367,1.661,3.974,3.912,4.422C16.501,26.592,16,27,14.072,27c0.626,1.935,3.773,2.958,5.928,3c-1.686,1.307-4.692,2-7,2 c-0.399,0-0.615,0.022-1-0.023C14.178,33.357,17.22,34,20,34c9.057,0,14-6.918,14-13.37c0-0.212-0.007-0.922-0.018-1.13 C34.95,18.818,35.342,18.104,36,17.12'
                            />
                          </svg>
                        </span>
                      </a>
                      <a
                        href='https://www.linkedin.com/company/delvcareers'
                        className='text-gray-500 transition-colors duration-300'
                      >
                        <span className='rounded-full h-12 w-12 flex items-center justify-center  text-white'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 48 48'
                            width='48px'
                            height='48px'
                          >
                            <path
                              fill='#0288d1'
                              d='M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z'
                            />
                            <path
                              fill='#fff'
                              d='M14 19H18V34H14zM15.988 17h-.022C14.772 17 14 16.11 14 14.999 14 13.864 14.796 13 16.011 13c1.217 0 1.966.864 1.989 1.999C18 16.11 17.228 17 15.988 17zM35 24.5c0-3.038-2.462-5.5-5.5-5.5-1.862 0-3.505.928-4.5 2.344V19h-4v15h4v-8c0-1.657 1.343-3 3-3s3 1.343 3 3v8h4C35 34 35 24.921 35 24.5z'
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Coming;
