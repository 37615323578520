import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StarRate } from '@material-ui/icons';
import Truncate from 'react-truncate';
import { Link } from 'react-router-dom';
import ChatGPTButton from '../components/ChatGPTButton';

import DefaultThumbnail from './DefaultThumbnail';
import { CarouselController } from './CarouselController';

import * as contentActions from '../redux/actions/content';

const ArticlesCarousel = ({ content, setShowChatGPTModal }) => {
  return (
    <div className=''>
      <div className='flex-row justify-center pb-20 px-0'>
        <CarouselController
          type={'default'}
          content={content?.map((value) =>
            value.category === 'Article' ? <ArticleCard value={value} /> : null,
          )}
        />
        <div className='w-full flex justify-center mt-6'>
          <Link
            className='mx-auto categoryLinkTag px-16 py-4 md:py-5 md:px-16 explore-articles open-sans'
            to='/articles'
          >
            MORE ARTICLES
          </Link>
        </div>
        <ChatGPTButton
          setShowChatGPTModal={setShowChatGPTModal}
          marginLeft='46%'
        />
      </div>
    </div>
  );
};

const CoursesCarousel = ({ content, setShowChatGPTModal }) => {
  return (
    <div className=''>
      <div className='flex-row justify-center pb-20 px-0'>
        <CarouselController
          type={'default'}
          content={content?.map((value) =>
            value.category === 'Course' ? <CourseCard value={value} /> : null,
          )}
        />
        <div className='w-full flex justify-center mt-6'>
          <Link
            className='mx-auto categoryLinkTag px-16 py-4 md:py-5 md:px-16 explore-articles open-sans'
            to='/IndividualCourses'
          >
            MORE COURSES
          </Link>
        </div>
        <ChatGPTButton
          setShowChatGPTModal={setShowChatGPTModal}
          marginLeft='46%'
        />
      </div>
    </div>
  );
};

const ArticleCard = ({ value }) => {
  const user = useSelector((state) => state.user.user);

  return (
    <a
      href={
        user === null
          ? `/article/overview/${value?._id}`
          : `/article/read/${value?._id}`
      }
    >
      <div className='w-full h-full py-2 my-3  p-3'>
        <div className='article-overflow-hidden'>
          <div className='object-cover w-full h-full rounded'>
            <div style={{ height: '100%' }} className='w-full'>
              <DefaultThumbnail text={value.title} />
            </div>
          </div>
        </div>
        <div className='w-full my-1'>
          <p className='articleText w-full text-center md:text-left Cabin'>
            <Truncate lines={2} ellipsis={<span>...</span>}>
              {value?.title}
            </Truncate>
          </p>
        </div>
        <div className='w-full text-center md:text-left mb-1 mt-1 article-desc text-sm article-author open-sans font-thin'>
          <p>{value.author}</p>
          <div className='text-starsorange text-sm'>
            <StarRate fontSize='small' />
            <span className=' mx-1  font-semibold text-sm text-starsorange mb-6'>
              {parseFloat(value?.avgRating || 0).toFixed(1)}
            </span>
            <span className='text-linecolor'>({value?.views || 0})</span>
          </div>
        </div>
      </div>
    </a>
  );
};

const CourseCard = ({ value }) => {
  return (
    <a href={`${value?.sourceLink}`} target='_blank' rel='noreferrer'>
      <div className='w-full h-full py-2 my-3  p-3'>
        <div className='article-overflow-hidden'>
          <div className='object-cover w-full h-full rounded'>
            <div style={{ height: '100%' }} className='w-full'>
              <DefaultThumbnail text={value.title} />
            </div>
          </div>
        </div>
        <div className='w-full my-1'>
          <p className='articleText w-full text-center md:text-left Cabin'>
            <Truncate lines={2} ellipsis={<span>...</span>}>
              {value?.title}
            </Truncate>
          </p>
        </div>
        <div className='w-full text-center md:text-left mb-1 mt-1 article-desc text-sm article-author open-sans font-thin'>
          <p>{value.author}</p>
          <div className='text-starsorange text-sm'>
            <StarRate fontSize='small' />
            <span className=' mx-1  font-semibold text-sm text-starsorange mb-6'>
              {parseFloat(value?.avgRating || 0).toFixed(1)}
            </span>
            <span className='text-linecolor'>({value?.views || 0})</span>
          </div>
        </div>
      </div>
    </a>
  );
};

const VideoCarousel = ({ content, setShowChatGPTModal }) => {
  return (
    <div className=''>
      <div className='flex-row justify-center pb-20 px-0'>
        <CarouselController
          type={'default'}
          content={content?.map((value) =>
            value.category === 'Video' ? <VideoCard value={value} /> : null,
          )}
        />
        <div className='w-full flex justify-center mt-6'>
          <Link
            className='mx-auto categoryLinkTag px-16 py-4 md:py-5 md:px-16 explore-articles open-sans'
            to='/videos'
          >
            MORE VIDEOS
          </Link>
        </div>
        <ChatGPTButton
          setShowChatGPTModal={setShowChatGPTModal}
          marginLeft='46%'
        />
      </div>
    </div>
  );
};

const VideoCard = ({ value }) => {
  return (
    <a href={`/video/watch/${value?._id}`}>
      <div className='w-full h-full py-2 my-3 p-3'>
        <div className='article-overflow-hidden'>
          <img
            src={value?.thumbnailFile?.publicUrl}
            style={{ objectFit: 'cover' }}
            className='w-full h-full articleImage'
            alt={value?.title}
          />
        </div>

        <div className='w-full my-1'>
          <p className='articleText w-full text-center md:text-left Cabin'>
            {value?.title}
          </p>
        </div>
        <div className='w-full text-center md:text-left mb-1 mt-1 article-desc text-sm article-author open-sans font-thin'>
          <p>{value?.author}</p>

          <div className='text-starsorange text-sm'>
            <StarRate fontSize='small' />
            <span className=' mx-1  font-semibold text-sm text-starsorange mb-6'>
              {parseFloat(value?.avgRating || 0).toFixed(1)}
            </span>
            <span className='text-linecolor'>({value?.views || 0})</span>
          </div>
        </div>
      </div>
    </a>
  );
};

const ExploreNav = (props) => {
  const { popularContent, latestContent, trendingContent } = props;
  const [popularSelected, setPopularSelected] = useState(true);
  const [latestSelected, setLatestSelected] = useState(false);
  const [trendingSelected, setTrendingSelected] = useState(false);
  const selectedStyle =
    '  border-b-4 border-darkerblue  outline-none text-darkerblue';

  return (
    <nav className='bg-none shadow block '>
      <div className=' py-0'>
        <div className='flex  md:items-center justify-between'>
          <div className='flex flex-row justify-between w-full'>
            <div className='flex flex-row md:mr-2 xl:mr-16  '>
              <button
                onClick={() => {
                  setPopularSelected(true);
                  setLatestSelected(false);
                  setTrendingSelected(false);
                  props.setContent(popularContent);
                }}
                className={
                  ' flex px-6 items-center py-4 text-base leading-5 transition font-display hover:text-charcoal  duration-150 ease-in-out' +
                  (popularSelected ? selectedStyle : ' text-textparagraph')
                }
              >
                Popular
              </button>
              <button
                onClick={() => {
                  setPopularSelected(false);
                  setLatestSelected(true);
                  setTrendingSelected(false);
                  props.setContent(latestContent);
                }}
                className={
                  ' flex px-6 items-center py-4 text-base leading-5 transition font-display hover:text-charcoal  duration-150 ease-in-out' +
                  (latestSelected ? selectedStyle : ' text-textparagraph')
                }
              >
                Latest
              </button>
              <button
                onClick={() => {
                  setPopularSelected(false);
                  setLatestSelected(false);
                  setTrendingSelected(true);
                  props.setContent(trendingContent);
                }}
                className={
                  ' flex px-6 items-center py-4 text-base leading-5 transition font-display hover:text-charcoal  duration-150 ease-in-out' +
                  (trendingSelected ? selectedStyle : ' text-textparagraph')
                }
              >
                Trending
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default function Explore({ setShowChatGPTModal }) {
  const dispatch = useDispatch();
  const latestContent = useSelector((state) => state.content.latestContent);
  const popularContent = useSelector((state) => state.content.popularContent);
  const trendingContent = useSelector((state) => state.content.trendingContent);
  const [content, setContent] = useState([]);

  useEffect(() => {
    dispatch(contentActions.getLatestContent());
    dispatch(contentActions.getPopularContent());
    dispatch(contentActions.getTrendingContent());
  }, []);

  useEffect(() => {
    if (content.length === 0) setContent(popularContent);
  }, [popularContent]);

  return (
    <>
      <div
        className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 '
        style={{ marginTop: '70px', marginBottom: '14px' }}
      >
        <div className='mx-auto w-full'>
          <h1 className='font-bold mx-8 mb-4 text-3xl md:text-4xl text-center Cabin categoriesHeader'>
            Explore Courses
          </h1>
          <h3 className='mx-6 md:mx-0 my-3 md:px-36 lg:px-52 md:my-4 text-md text-center leading choose font-thin'>
            Choose from top ranking, well curated online courses with new
            additions published every month.
          </h3>
        </div>
        <div className='w-full mb-4 sm:text-left'>
          <ExploreNav
            setContent={setContent}
            latestContent={latestContent}
            popularContent={popularContent}
            trendingContent={trendingContent}
          />
        </div>
        <div className='mb-8'>
          <CoursesCarousel
            content={content}
            setShowChatGPTModal={setShowChatGPTModal}
          />
        </div>
      </div>

      <div
        className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full  md:px-24 lg:px-8 lg:py-20 bg-white'
        style={{ paddingTop: '185px', paddingBottom: '90px' }}
      >
        <div className='mx-auto w-full'>
          <h1 className='font-bold mx-8 mb-4 text-3xl md:text-4xl text-center Cabin categoriesHeader'>
            Explore a broad selection of videos
          </h1>
          <h3 className='mx-6 md:mx-0 my-3 md:px-36 lg:px-52 md:my-4 text-md text-center leading choose font-thin'>
            Choose from thousands of online videos with new additions published
            every month.
          </h3>
        </div>
        <>
          <div className='w-full mb-4 sm:text-center'>
            <h2 className='max-w-lg mb-2 font-sans text-xl font-bold leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-8 '>
              {' '}
            </h2>
          </div>
          <div className='w-full mb-4 sm:text-left'>
            <ExploreNav
              setContent={setContent}
              latestContent={latestContent}
              popularContent={popularContent}
              trendingContent={trendingContent}
            />
          </div>
          <div className='mb-8'>
            <VideoCarousel
              content={content}
              setShowChatGPTModal={setShowChatGPTModal}
            />
          </div>
        </>
      </div>

      <div
        className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'
        style={{ paddingTop: '189px', paddingBottom: '90px' }}
      >
        <>
          <div className='mx-auto w-full'>
            <h1 className='font-bold mx-8 mb-4 text-3xl md:text-4xl text-center Cabin categoriesHeader'>
              Explore articles
            </h1>
            <h3 className='mx-6 md:mx-0 my-3 md:px-36 lg:px-52 md:my-4 text-md text-center leading choose font-thin'>
              Choose from thousands of online articles with new additions
              published every month.
            </h3>
          </div>
          <div className='w-full mb-4 sm:text-left'>
            <ExploreNav
              setContent={setContent}
              latestContent={latestContent}
              popularContent={popularContent}
              trendingContent={trendingContent}
            />
          </div>
          <div className='mb-8'>
            <ArticlesCarousel
              content={content}
              setShowChatGPTModal={setShowChatGPTModal}
            />
          </div>
        </>
      </div>
    </>
  );
}
