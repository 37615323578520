import * as commentsTypes from '../types/comments';

const initialState = {
  data: null,
  itemComments: [],
  loading: false,
  error: false,
  errorMessage: null,
  success: {
    status: false,
    message: 'All set!',
  },
};

const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case commentsTypes.COMMENTS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
      };
    case commentsTypes.COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case commentsTypes.COMMENTS_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
      };
    case commentsTypes.ALL_COMMENTS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case commentsTypes.ITEM_COMMENTS:
      return {
        ...state,
        itemComments: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case commentsTypes.COMMENTS_RESET:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: false,
          message: 'All set!',
        },
      };
    default:
      return state;
  }
};

export default commentsReducer;
