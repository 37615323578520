import { Check, Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonLoader from './ButtonLoader';
import * as discussionGroupsActions from '../redux/actions/discussiongroups';
import * as institutionActions from '../redux/actions/institution';

const RequestCard = ({ type, member, adminId, success, error }) => {
  const requester = member;
  const users = useSelector((state) => state?.user?.data);
  const institutions = useSelector((state) => state?.institution?.data);
  const userProfile = [];

  const isInstitutionInvitation = type === 'mentor_invite';

  if (isInstitutionInvitation) {
    institutions.map(
      (user) => user._id === requester?.userId && userProfile.push(user),
    );
  } else {
    users.map(
      (user) => user._id === requester?.userId && userProfile.push(user),
    );
  }

  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();

  const handleAcceptRequest = (requester) => {
    setDisabled(true);
    setAcceptLoading(true);
    dispatch(
      discussionGroupsActions.acceptRequestJoinDiscussionGroup({
        accepted_request_id: requester._id,
      }),
    );
    if (isInstitutionInvitation) {
      dispatch(
        institutionActions.inviteMentor({
          institutionId: requester?.idOfWhatToJoin,
          mentorId: requester?.userId,
        }),
      );
    }
    dispatch(discussionGroupsActions.getAllJoinRequests(adminId));
  };

  const handleRejectRequest = (requester) => {
    setDisabled(true);
    setRejectLoading(true);
    dispatch(
      discussionGroupsActions.rejectRequestJoinDiscussionGroup({
        rejected_request_id: requester._id,
        reason: 'No reason appended',
      }),
    );
  };

  useEffect(() => {
    dispatch(discussionGroupsActions.getAllJoinRequests(adminId));
  }, []);

  useEffect(() => {
    if (success.message === 'Request to join Accepted') {
      dispatch(discussionGroupsActions.getAllJoinRequests(adminId));
      setAcceptLoading(false);
      setDisabled(false);
      dispatch(discussionGroupsActions.getAllDiscussionGroups({}));
    }
    if (success.message === 'Request to join Rejected') {
      dispatch(discussionGroupsActions.getAllJoinRequests(adminId));
      setRejectLoading(false);
      setDisabled(false);
    }

    if (error) {
      if (`${error}`.includes('NetworkError')) {
        dispatch(discussionGroupsActions.getAllJoinRequests(adminId));
      }
      setAcceptLoading(false);
      setRejectLoading(false);
      setDisabled(false);
    }
  }, [success]);
  return (
    <div
      className={`relative p-px mb-4 overflow-hidden transition duration-300 transform border-l-4 border-white group hover:shadow-sm hover:border-darkerblue `}
    >
      <div>
        <div className='relative flex flex-col h-full p-2 bg-white rounded-sm lg:items-center lg:flex-row lg:justify-between w-full '>
          <div className='flex flex-row justify-start '>
            <div className='mb-6 mr-6 lg:mb-0'>
              <div className='flex items-center justify-center w-16 h-16 rounded-full lg:w-24 lg:h-24  lg:flex'>
                <img
                  src={
                    userProfile[0]?.profilePicture?.publicUrl ||
                    `https://ui-avatars.com/api/?name=${
                      isInstitutionInvitation
                        ? requester?.nameOfWhatToJoin
                        : `${userProfile[0]?.firstname} ${userProfile[0]?.lastname}`
                    }&bold=true`
                  }
                  alt='Event Thumb'
                  className='object-cover h-full w-full rounded-full shadow-sm'
                />
              </div>
            </div>
            <div className='flex flex-col justify-center lg flex-grow text-charcoal'>
              <div>
                <h2 className='mb-2 font-semibold text-2xl leading-5 capitalize'>
                  {isInstitutionInvitation
                    ? requester?.nameOfWhatToJoin
                    : requester?.fullName}
                </h2>
              </div>
              <p className='mb-2 text-base  font-base '>
                {isInstitutionInvitation
                  ? ' is inviting you to join as a mentor.'
                  : ' would like to join.'}
              </p>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-5'>
            <button
              disabled={disabled}
              onClick={() => {
                handleAcceptRequest(member);
              }}
              className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-buttonGreen focus:shadow-outline focus:outline-none'
            >
              {acceptLoading ? (
                <ButtonLoader size='default' />
              ) : (
                <div>
                  <Check />
                  <span className='ml-2'>Accept</span>
                </div>
              )}
            </button>
            <button
              disabled={disabled}
              onClick={() => {
                handleRejectRequest(member);
              }}
              className='inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-darkerblue transition duration-200 rounded shadow-md md:w-auto bg-none hover:bg-buttonRed hover:text-white border-2 border-darkerblue hover:border-buttonRed focus:shadow-outline focus:outline-none'
            >
              {rejectLoading ? (
                <ButtonLoader size='default' color='primary' />
              ) : (
                <div>
                  <Close />
                  <span className='ml-2'>Reject</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestCard;
