export const CONTENT_LOADING = 'CONTENT_LOADING';
export const VIDEOS = 'VIDEOS';
export const ARTICLES = 'ARTICLES';
export const COURSES = 'COURSES';
export const COURSEUNITS = 'COURSEUNITS';
export const ALL_CONTENT = 'ALL_CONTENT';
export const MY_CONTENT = 'MY_CONTENT';
export const MY_ARTICLES = 'MY_ARTICLES';
export const MY_VIDEOS = 'MY_VIDEOS';
export const MY_COURSES = 'MY_COURSES';
export const LATEST_CONTENT = 'LATEST_CONTENT';
export const POPULAR_CONTENT = 'POPULAR_CONTENT';
export const TRENDING_CONTENT = 'TRENDING_CONTENT';
export const SPECIFIC_CONTENT = 'SPECIFIC_CONTENT';
export const RELATED_CONTENT = 'RELATED_CONTENT';
export const CONTENT_ERROR = 'CONTENT_ERROR';
export const CONTENT_RESET = 'CONTENT_RESET';
export const CONTENT_SUCCESS = 'CONTENT_SUCCESS';
