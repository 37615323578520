import * as postTypes from '../types/post';
import * as fetchFunctions from '../../api/index';
import postEndpoints from '../endpoints/post';

export const makePost = (payload) => async (dispatch, getState) => {
  dispatch({
    type: postTypes.POST_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    postEndpoints.make_post,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: postTypes.POST_SUCCESS,
      payload: {
        status: true,
        message: result?.response?.message,
      },
    });
  } else {
    dispatch({
      type: postTypes.POST_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const allPosts = () => async (dispatch) => {
  dispatch({
    type: postTypes.POST_LOADING,
  });

  const result = await fetchFunctions.getData(postEndpoints.get_all_posts);

  if (result.success) {
    dispatch({
      type: postTypes.ALL_POSTS,
      payload: result?.data,
    });
  } else {
    dispatch({
      type: postTypes.POST_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const myPosts = () => async (dispatch, getState) => {
  dispatch({
    type: postTypes.POST_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.postData(postEndpoints.my_posts, token);

  if (result.success) {
    dispatch({
      type: postTypes.MY_POSTS,
      payload: result?.data,
    });
  } else {
    dispatch({
      type: postTypes.POST_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const updatePost = (payload) => async (dispatch, getState) => {
  dispatch({
    type: postTypes.POST_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    postEndpoints.update_post_status,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: postTypes.UPDATE_POST,
      payload: {
        status: true,
        message: 'Post Update Complete',
      },
    });
    setTimeout(function () {
      dispatch({
        type: postTypes.POST_RESET,
      });
    }, 500);
  } else {
    dispatch({
      type: postTypes.POST_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
