import * as fetchFunctions from '../../api/index';
import * as affiliationsTypes from '../types/affiliations';
import affiliationsEndpoints from '../endpoints/affiliations';

export const getAllAffiliations = () => async (dispatch, getState) => {
  dispatch({
    type: affiliationsTypes.AFFILIATIONS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    affiliationsEndpoints.get_all_affiliations,
    token,
  );

  if (result.success) {
    dispatch({
      type: affiliationsTypes.GET_AFFILIATIONS,
      payload: result.data,
    });
    dispatch({
      type: affiliationsTypes.AFFILIATIONS_RESET,
    });
  } else {
    dispatch({
      type: affiliationsTypes.AFFILIATIONS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getUserAffiliations = () => async (dispatch, getState) => {
  dispatch({
    type: affiliationsTypes.AFFILIATIONS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    affiliationsEndpoints.get_current_user_affiliation,
    token,
  );

  if (result.success) {
    dispatch({
      type: affiliationsTypes.GET_CURRENT_USER_AFFILIATIONS,
      payload: result.data,
    });
    dispatch({
      type: affiliationsTypes.AFFILIATIONS_RESET,
    });
  } else {
    dispatch({
      type: affiliationsTypes.AFFILIATIONS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
