import React from 'react';
import AvatarPlaceholder from '../assets/avatar-placeholder.svg';
import { Checkbox } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

export const ItemOption = ({ item }) => {
  return (
    <div className='flex flex-row justify-between hover:bg-headingtext hover:bg-opacity-10 cursor-pointer px-2 lg:px-4 p-2'>
      <div className='flex w-full justify-between'>
        <div className='flex flex-row justify-start'>
          <img
            className='object-cover w-10 h-10 mr-2 rounded-full shadow'
            src={item.item.image || AvatarPlaceholder}
            alt='Person'
          />
          <p className='text-base text-charcoal flex justify-center flex-col capitalize'>
            {item.item.label}
          </p>
        </div>
        <span className='text-headingtext'>
          <Checkbox
            checked={item.checked}
            disabled={item.disabled}
            color='primary'
          />
        </span>
      </div>
    </div>
  );
};

export const SelecteItemOption = ({ item }) => {
  return (
    <div className='flex flex-row justify-between hover:bg-headingtext hover:bg-opacity-10 cursor-pointer px-2 lg:px-4 p-2'>
      <div className='flex flex-row justify-start'>
        <img
          className='object-cover w-10 h-10 mr-2 rounded-full shadow'
          src={item.item.image || AvatarPlaceholder}
          alt='Person'
        />
        <p className='text-base text-charcoal flex justify-center flex-col capitalize'>
          {item.item.label}
        </p>
      </div>
      <span className='text-headingtext text-lg'>
        <Clear fontSize='inherit' />
      </span>
    </div>
  );
};
