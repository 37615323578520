import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Edit, ExpandMore, Room, People, AcUnit } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Twitter from '../assets/twitter.svg';
import LinkedIn from '../assets/linkedIn.svg';

import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import TagsInput from '../components/TagsInput';
import ReviewItem from '../components/ReviewItem';
import ButtonLoader from '../components/ButtonLoader';
import ModalWrapper from '../components/ModalWrapper';
import UserPointsModal from '../components/UserPointsModal';

import * as userActions from '../redux/actions/users';
import * as joinRequestActions from '../redux/actions/joinrequests';
import * as commentActions from '../redux/actions/comments';
import * as institutionActions from '../redux/actions/institution';
import CustomAlert from '../components/CustomAlert';
import CalendlyEmbed from '../components/CalendlyEmbed';
import * as invitationActions from '../redux/actions/invitation';

const NavigationHeader = ({ activeStatus, setActiveStatus, user }) => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('About');

  const { joinRequestCount, success, error, errorMessage } = useSelector(
    (state) => state.joinRequest,
  );

  const userName = useSelector((state) => state.user.user);
  var adminID = userName?._id;
  useEffect(() => {
    dispatch(joinRequestActions.getAllJoinRequests({ adminID }));
  }, [adminID]);

  useEffect(() => {
    if (success.message === 'Upload Complete')
      dispatch(joinRequestActions.getAllJoinRequests({ adminID }));
    if (error)
      if (!`${errorMessage}`.includes('NetworkError')) {
        CustomAlert({
          alertType: 'error',
          alertMessage: 'Network error!',
        });
      }
  }, [success, error, adminID]);

  useEffect(() => {
    if (activeStatus === 2) setSelectedTab('About');
    if (activeStatus === 3) setSelectedTab('Reviews');
    if (activeStatus === 4) setSelectedTab('Schedule');
    if (activeStatus === 5) setSelectedTab('Requests');
  }, [activeStatus]);

  return (
    <div className='px-2 flex items-center justify-center lg:justify-start lg:items-start w-full'>
      <ul className='w-full hidden md:flex items-center  border-b border-linecolor'>
        <li
          onClick={() => setActiveStatus(2)}
          className={
            activeStatus === 2
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-darkerblue text-darkerblue font-semibold  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          About
        </li>
        <li
          onClick={() => setActiveStatus(3)}
          className={
            activeStatus === 3
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-darkerblue text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Reviews
        </li>
        {user.usercategory[0] === 'mentor' && (
          <li
            onClick={() => setActiveStatus(4)}
            className={
              activeStatus === 4
                ? 'py-2 px-2 cursor-pointer  border-b-2 border-darkerblue text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
                : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
            }
          >
            Schedule
          </li>
        )}

        <li
          onClick={() => setActiveStatus(5)}
          className={
            activeStatus === 5
              ? 'py-2 px-2 cursor-pointer  border-b-2 border-darkerblue text-darkerblue font-semibold   ml-12  text-base   leading-none text-center'
              : 'py-2 px-2 cursor-pointer ml-12 bg-transparent hover:bg-lightGrayBg font-semibold   text-base   leading-none text-gray5'
          }
        >
          Requests ({joinRequestCount})
        </li>
      </ul>
      <div className='md:hidden relative w-11/12 mx-auto bg-white rounded'>
        <div className='absolute inset-0 m-auto mr-4 z-0 w-6 h-6'></div>
        <div className='lg:hidden w-full flex justify-center'>
          <div className='flex flex-row lg:hidden py-1 w-full'>
            <button
              aria-label='Open Menu'
              title='Open Menu'
              className='px-2 py-2 cursor-pointer  border-b-2 border-darkerblue text-darkerblue font-semiboldtext-base   leading-none text-center  flex flex-row w-full justify-between  transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50'
              onClick={() => setIsMenuOpen(true)}
            >
              <span className=''>{selectedTab}</span>
              <div>
                <ExpandMore fontSize='inherit' />
              </div>
            </button>
          </div>

          {isMenuOpen && (
            <div className='absolute top-0 left-0 w-full z-50'>
              <div className='p-5 bg-white border rounded shadow-sm'>
                <nav>
                  <ul className='space-y-4'>
                    <li
                      onClick={() => {
                        setActiveStatus(2);
                        setIsMenuOpen(false);
                        setSelectedTab('About');
                      }}
                      className={
                        activeStatus === 2
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-darkerblue text-darkerblue font-semibold  font-display  text-base   leading-none text-center'
                          : 'py-2 px-2 cursor-pointer bg-transparent hover:bg-lightGrayBg font-semibold  font-display text-base   leading-none text-gray5'
                      }
                    >
                      About
                    </li>
                    <li
                      onClick={() => {
                        setActiveStatus(3);
                        setIsMenuOpen(false);
                        setSelectedTab('Reviews');
                      }}
                      className={
                        activeStatus === 3
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-darkerblue text-darkerblue font-semibold font-display  text-base   leading-none text-center '
                          : 'py-2 px-2 cursor-pointer  bg-transparent hover:bg-lightGrayBg font-semibold font-display   text-base   leading-none text-gray5'
                      }
                    >
                      Reviews
                    </li>

                    {user.usercategory[0] === 'mentor' && (
                      <li
                        onClick={() => {
                          setActiveStatus(4);
                          setIsMenuOpen(false);
                          setSelectedTab('Schedule');
                        }}
                        className={
                          activeStatus === 3
                            ? 'py-2 px-2 cursor-pointer  border-b-2 border-darkerblue text-darkerblue font-semibold font-display text-base   leading-none text-center '
                            : 'py-2 px-2 cursor-pointer  bg-transparent hover:bg-lightGrayBg font-semibold font-display  text-base   leading-none text-gray5'
                        }
                      >
                        Schedule
                      </li>
                    )}

                    <li
                      onClick={() => {
                        setActiveStatus(5);
                        setIsMenuOpen(false);
                        setSelectedTab('Requests');
                      }}
                      className={
                        activeStatus === 5
                          ? 'py-2 px-2 cursor-pointer  border-b-2 border-darkerblue text-darkerblue font-semibold font-display  text-base   leading-none text-center '
                          : 'py-2 px-2 cursor-pointer  bg-transparent hover:bg-lightGrayBg font-semibold font-display   text-base   leading-none text-gray5'
                      }
                    >
                      Requests
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
        <form>
          <select
            aria-label='Selected tab'
            className='form-select block w-full p-3 border border-charcoal rounded text-gray5s font-semibold appearance-none bg-white relative z-10 mb-8'
          >
            <option
              className='text-base text-gray5 font-display'
              onClick={(e) => {
                e.preventDefault();
                setActiveStatus(2);
              }}
            >
              About{' '}
            </option>
            <option
              className='text-base text-gray5 font-display'
              onClick={(e) => {
                e.preventDefault();
                setActiveStatus(3);
              }}
            >
              Reviews
            </option>
          </select>
        </form>
      </div>
    </div>
  );
};

const TabLayout = ({ user, comments }) => {
  const [activeStatus, setActiveStatus] = useState(2);
  return (
    <>
      <div className='text-center lg:text-left font-display'>
        <NavigationHeader
          activeStatus={activeStatus}
          setActiveStatus={setActiveStatus}
          user={user}
        />
        {activeStatus === 2 && <About user={user} />}
        {activeStatus === 3 && <Reviews comments={comments} />}
        {activeStatus === 4 && <Schedule user={user} />}
        {activeStatus === 5 && <Requests user={user} />}
      </div>
    </>
  );
};

const Requests = ({ user }) => {
  const [myStatus, setMyStatus] = useState('');
  const [myFilteredRequest, setMyFilteredRequests] = useState([]);

  const { joinRequestData } = useSelector((state) => state.joinRequest);

  const getInputValue = ({ target: { value } }) => {
    setMyStatus(value);
  };

  useEffect(() => {
    if (myStatus !== '') {
      let myFilteredRequestArray = [];
      joinRequestData
        ?.filter((item) => item.status.toLowerCase().includes(myStatus))
        .map((item) => {
          return myFilteredRequestArray.push(item);
        });
      setMyFilteredRequests(myFilteredRequestArray);
    } else {
      setMyFilteredRequests(joinRequestData);
    }
  }, [myStatus]);

  return (
    <div>
      <select
        value={myStatus}
        className={` h-11 outline-none  border box-border rounded px-4 font-body`}
        onChange={getInputValue}
        style={{ float: 'right' }}
      >
        <option value=''>Filter Status</option>
        <option value='accepted'>Accepted</option>
        <option value='pending'>Pending</option>
        <option value='rejected'>Rejected</option>
      </select>

      <table className='w-full bg-white '>
        <thead>
          <tr className='w-full h-16 border-linecolor  border-b py-8'>
            <th className='text-gray font-display pr-6 text-center'>Name</th>
            <th className='text-gray font-display pr-6 text-center'>
              What to Join
            </th>
            <th className='text-gray font-display pr-6 text-center'>Join As</th>
            <th className='text-gray font-display pr-6 text-center'>Status</th>

            <th className='text-gray font-display pr-6 text-center'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {myFilteredRequest?.map((value) => (
            <ContentTableRow content={value} key={value?._id} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ContentTableRow = (props) => {
  const dispatch = useDispatch();

  const updateRequestStatus = (props, status) => {
    dispatch(
      joinRequestActions.updateRequestStatus({
        requestId: props._id,
        requestStatus: status,
        userId: props.userId,
        whatToJoin: props.whatToJoin,
        idOfWhatToJoin: props.idOfWhatToJoin,
      }),
    );
    CustomAlert({
      alertType: 'success',
      alertMessage: 'Success: Request status has been updated',
    });
  };

  return (
    <tr className='border-b-2 border-linecolor mx-4 text-sm pr-6 text-center font-display whitespace-normal text-black tracking-normal leading-4 cursor-pointer'>
      <td className='text-left'>
        <div
          style={{
            padding: '20px',
            float: 'left',
            textTransform: 'capitalize',
          }}
        >
          {props.content.fullName}
        </div>
      </td>
      <td className='text-left'>
        <div
          style={{
            padding: '20px',
            float: 'left',
            textTransform: 'capitalize',
          }}
        >
          {props.content.nameOfWhatToJoin} ({props.content.whatToJoin})
        </div>
      </td>
      <td className='text-left'>
        <div
          style={{
            padding: '20px',
            float: 'left',
            textTransform: 'capitalize',
          }}
        >
          {props.content.toJoinAs}
        </div>
      </td>
      <td className='text-left'>
        <div
          style={{
            padding: '20px',
            float: 'left',
            textTransform: 'capitalize',
          }}
        >
          {props.content.status}
        </div>
      </td>

      <td className='pr-8 relative'>
        {props.content.status === 'pending' ? (
          <>
            <button
              onClick={() => updateRequestStatus(props.content, 'accepted')}
              style={{
                border: '1px solid green',
                color: 'green',
                marginRight: '2px',
              }}
              className='inline-flex items-center font-medium text-sm sm:max-w-full justify-center h-8 w-20 px-0 sm:mr-0 tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:text-white focus:shadow-outline focus:outline-none'
            >
              Approve
            </button>
            <button
              onClick={() => updateRequestStatus(props.content, 'rejected')}
              style={{ border: '1px solid red', color: 'red' }}
              className='inline-flex items-center font-medium text-sm sm:max-w-full justify-center h-8 w-20 px-0 sm:mr-0 tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:text-white focus:shadow-outline focus:outline-none'
            >
              Reject
            </button>
          </>
        ) : null}
      </td>
    </tr>
  );
};

const About = ({ user }) => {
  return (
    <div className='max-w-full  lg:max-w-full md:mb-12 pt-4'>
      <div className='border-b border-linecolor pb-10 mt-6'>
        <h1 className='font-bold text-charcoal mb-3 font-display'>Overview</h1>
        <span className='font-base text-charcoal'>{user?.bio}</span>
      </div>
      <div className=' flex flex-col justify-around py-2 lg:p-8 rounded-lg mb-9'>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Category</span>
          <div className=' lg:w-9/12'>
            <span className='font-base text-charcoal  lg:w-9/12 capitalize'>
              {user?.usercategory[user?.usercategory.length - 1]}
            </span>
          </div>
        </div>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Interests</span>
          <div className=' lg:w-9/12'>
            <span className='font-base text-charcoal  lg:w-9/12'>
              {user?.interests?.map((value) => (
                <span className='lg:mr-1' key={value}>
                  {value}
                  {', '}
                </span>
              ))}
            </span>
          </div>
        </div>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Country</span>
          <div className=' lg:w-9/12'>
            <div className='py-1 px-3 lg:py-2 lg:px-6 font-base text-lightBlue bg-linecolor rounded-lg w-max'>
              <Room fontSize='small' />{' '}
              <span className='ml-2'> {user?.country || ''}</span>
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Website</span>
          <div className='ml-4 lg:ml-0 lg:w-9/12'>
            <a
              href='/'
              className='font-base text-darkerblue lg:w-9/12 text-right'
            >
              {user?.website || 'Not Avaliable'}
            </a>
          </div>
        </div>

        <div className='flex flex-row justify-between mb-9 text-left text-sm  lg:text-base'>
          <span className='font-base text-gray5 lg:w-3/12'>Social Media</span>
          <div className=' lg:w-9/12'>
            <div className='flex justify-start'>
              <a href='/'>
                <img src={Twitter} alt='twitter-link' className='pr-6' />
              </a>

              <a href='/'>
                <img src={LinkedIn} alt='linkedin-link' className='pr-6' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Schedule = ({ user }) => {
  return (
    <div className='max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 mt-4 bg-loading bg-no-repeat bg-top'>
      <CalendlyEmbed link={user.calendly} title={user.calendly} />
    </div>
  );
};

const Reviews = ({ comments }) => {
  const institutions = useSelector((state) => state?.institution.data);
  const [slice, setSlice] = useState(5);

  const handleMoreReview = () => setSlice(slice + 4);

  return (
    <>
      {' '}
      <div className='flex flex-col mt-4 px-2'>
        <div className='w-full flex lg:flex-row flex-col justify-between pt-6 pb-2'>
          <span className='text-lg text-gray font-semibold px-2  mb-4 '>
            {' '}
            Recent reviews from users on Delv.
          </span>
        </div>
        <div className='flex lg:justify-start pb-4'>
          <select className='appearance-none ainline-flex justify-center w-max  px-2 lg:px-6 py-3 font-normal text-headingtext text-sm focus:outline-noone border border-lightGrayBg2  focus:border-linecolor lg:text-base transition duration-200 rounded-lg bg-lightGrayBg2 hover:bg-white hover:text-graybg2 focus:shadow-outline focus:outline-none'>
            <option>Sort By: Time</option>
            <option>Rating Ascending</option>
            <option>Rating Descending</option>
          </select>
        </div>
        <div>
          {comments?.slice(0, slice)?.map((user, key) => (
            <ReviewItem
              key={user._id}
              data={user}
              institutions={institutions}
            />
          ))}
        </div>
        <div className='flex justify-center py-3'>
          <span
            className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
            onClick={() => handleMoreReview()}
            disabled={slice >= comments?.length}
          >
            {slice >= comments?.length
              ? 'No more reviews'
              : 'Show more reviews'}
          </span>
        </div>
      </div>
    </>
  );
};

const SendInvitationModal = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const user = useSelector((state) => state.user.user);
  const invitation = useSelector((state) => state?.invitation);

  const handleInvitation = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      sentBy: user._id,
      sentUser: `${user.firstname} ${user.lastname}`,
      category: 'general',
    };

    dispatch(invitationActions.createInvitation(payload));
  };

  useEffect(() => {
    if (invitation?.success?.status) {
      setEmail('');
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Email Invitation has been sent',
      });
    }
  }, [invitation]);

  return (
    <>
      {props.showSendInvitationModal ? (
        <>
          <ModalWrapper
            title={'Invite a friend'}
            closeModal={() => props.setShowSendInvitationModal(false)}
            bodyContent={
              <div className='relative'>
                <form onSubmit={handleInvitation}>
                  <label className='font-semibold text-textparagraph text-sm'>
                    Add Email
                  </label>
                  <div className='grid grid-cols-6'>
                    <div className='col-span-4'>
                      <input
                        required
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                      />
                    </div>
                    <div className='col-span-2'>
                      <button
                        style={{ float: 'right' }}
                        className='w-40 h-12 falsefocus:shadow-outline  bg-darkerblue text-white text-xl px-2 py-2 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                        type='submit'
                      >
                        Invite
                      </button>
                    </div>
                  </div>
                  <br />
                </form>
                {invitation?.data?._id ? (
                  <>
                    <label className='font-light text-textparagraph text-sm'>
                      <b>Copy invitation link :</b>
                      <br /> {process.env.REACT_APP_HOST}/invitation/
                      {invitation.data._id}
                    </label>
                    <div className='col-span-12'>
                      <br />
                    </div>
                  </>
                ) : null}
              </div>
            }
          />
        </>
      ) : null}
    </>
  );
};

const UpdateProfileModal = ({
  data,

  showUpdateProfileModal,
  setShowUpdateProfileModal,
}) => {
  const dispatch = useDispatch();
  const { loading, success, errorMessage } = useSelector(
    (state) => state?.user,
  );
  const [showCalendlyTutorialModal, setShowCalendlyTutorialModal] =
    useState(false);
  const [profilePicture, setProfilePicture] = useState([]);
  const currentSpecialities = [];
  data?.interests?.forEach((item) =>
    currentSpecialities.push({ id: item, text: item }),
  );
  const [specialities, setSpecialities] = useState(currentSpecialities);

  const initialValues = {
    firstname: data?.firstname,
    lastname: data?.lastname,
    country: data?.country,
    calendly: data?.calendly || '',
    linkedIn: data?.linkedIn || '',
    twitter: data?.twitter || '',
    category: data?.usercategory[data?.usercategory.length - 1],
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    country: Yup.string().required('Country is required'),
    calendly: Yup.string(),
    linkedIn: Yup.string(),
    twitter: Yup.string(),
    category: Yup.string().required('User Category is required'),
  });

  const onSubmit = (values) => {
    let interests = [];
    specialities.map((itm) => interests.push(itm.text));
    let payload = {
      ...values,
      interests: interests,
      facebook: '',
      email: data?.email,
    };
    dispatch(userActions.updateUser(payload));

    const myForm = new FormData();
    myForm.append('profilePicture', profilePicture[0]);
    dispatch(userActions.updateImage(myForm));
  };

  useEffect(() => {
    if (success.message === 'User update complete') {
      dispatch(userActions.getUsers());
      CustomAlert({
        alertType: 'success',
        alertMessage: success.message,
      });
      setShowUpdateProfileModal(false);
    } else if (errorMessage === 'User update failed') {
      CustomAlert({
        alertType: 'error',
        alertMessage: 'Profile update failed, try again.',
      });
      setShowUpdateProfileModal(false);
    }
  }, [success, errorMessage]);

  return (
    <>
      {showUpdateProfileModal ? (
        <>
          {!showCalendlyTutorialModal && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors, values, getFieldProps, isValid }) => (
                <Form>
                  <ModalWrapper
                    title={'Update User profile'}
                    closeModal={() => setShowUpdateProfileModal(false)}
                    bodyContent={
                      <div className='relative'>
                        <div className='pt-6'>
                          <h3 className='uppercase font-body text-textparagraph font-semibold '>
                            First Name
                          </h3>
                          <input
                            type='text'
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='firstname'
                            name='firstname'
                            {...getFieldProps('firstname')}
                          />
                          {touched.firstname && errors.firstname ? (
                            <span className='text-sm text-red'>
                              {errors.firstname}
                            </span>
                          ) : null}
                        </div>
                        <div className='pt-6'>
                          <h3 className='uppercase font-body text-textparagraph font-semibold '>
                            Last Name
                          </h3>
                          <input
                            type='text'
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='lastname'
                            name='lastname'
                            {...getFieldProps('lastname')}
                          />
                          {touched.lastname && errors.lastname ? (
                            <span className='text-sm text-red'>
                              {errors.lastname}
                            </span>
                          ) : null}
                        </div>
                        <div className='pt-6'>
                          {' '}
                          <div className='form-control'>
                            <h3 className='uppercase font-body text-textparagraph font-semibold '>
                              Country
                            </h3>
                            <input
                              type='text'
                              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='country'
                              name='country'
                              {...getFieldProps('country')}
                            />
                            {touched.country && errors.country ? (
                              <span className='text-sm text-red'>
                                {errors.country}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {data.usercategory[0] === 'mentor' && (
                          <div className='pt-6'>
                            <h3 className='uppercase font-body text-textparagraph font-semibold flex flex-row justify-between'>
                              <span>
                                {`Calendly Link  `}{' '}
                                <span
                                  className='capitalize italic text-darkerblue underline lg:ml-6 cursor-pointer hover:text-navyblue'
                                  onClick={() =>
                                    setShowCalendlyTutorialModal(true)
                                  }
                                >{`Watch tutorial`}</span>
                              </span>

                              <a
                                className='capitalize italic text-darkerblue underline cursor-pointer ml-6 hover:text-navyblue'
                                href='https://calendly.com/'
                                aria-label='Create Calendly Profile'
                                title='Visit calendly.com'
                                target='_blank'
                                rel='noreferrer'
                              >
                                Visit Calendly
                              </a>
                            </h3>
                            <input
                              type='text'
                              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='calendly'
                              name='calendly'
                              {...getFieldProps('calendly')}
                            />
                            {touched.calendly && errors.calendly ? (
                              <span className='text-sm text-red'>
                                {errors.calendly}
                              </span>
                            ) : null}
                          </div>
                        )}
                        <h3 className='uppercase font-body text-textparagraph font-semibold  pt-6 pb-2'>
                          Social Media Links
                        </h3>
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-4 '>
                          <div className='form-control'>
                            <h3 className='uppercase font-body text-textparagraph font-semibold'>
                              LinkedIn
                            </h3>
                            <input
                              type='text'
                              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='linkedIn'
                              ml-12
                              name='linkedIn'
                              {...getFieldProps('linkedIn')}
                            />
                            {touched.linkedIn && errors.linkedIn ? (
                              <span className='text-sm text-red'>
                                {errors.linkedIn}
                              </span>
                            ) : null}
                          </div>
                          <div className='form-control'>
                            <h3 className='uppercase font-body text-textparagraph font-semibold'>
                              Twitter
                            </h3>
                            <input
                              type='text'
                              className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                              id='twitter'
                              ml-12
                              name='twitter'
                              {...getFieldProps('twitter')}
                            />
                            {touched.twitter && errors.twitter ? (
                              <span className='text-sm text-red'>
                                {errors.twitter}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className='pt-6'>
                          <h3 className='uppercase font-body text-textparagraph font-semibold'>
                            User Category
                          </h3>
                          <select
                            className='flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-lightgraybg border border-linecolor rounded  appearance-none focus:border-darkerblue focus:outline-noone  focus:shadow-outline'
                            id='category'
                            name='category'
                            {...getFieldProps('category')}
                            multiple={false}
                          >
                            <option value='select'>Select a type</option>
                            <option value='student'>Student</option>
                            <option value='graduate'>Graduate</option>
                            <option value='employee'>Employee</option>
                            <option value='mentor'>Mentor</option>
                            <option value='trainer'>Trainer</option>
                            <option value='Other'>Other</option>
                          </select>
                          {touched.institutionType && errors.institutionType ? (
                            <span className='text-sm text-red'>
                              {errors.institutionType}
                            </span>
                          ) : null}
                        </div>
                        <div className='pt-6'>
                          <h3 className='uppercase font-body text-textparagraph font-semibold pb-2'>
                            INTERESTS
                          </h3>
                          <p className='font-body text-textparagraph font-normal mb-2 '>
                            Comma-separated list of your skills (keep it below
                            10).
                          </p>

                          <TagsInput
                            tags={specialities}
                            setTags={setSpecialities}
                            suggestions={[]}
                            placeholder={
                              'Enter a collection of your specialities'
                            }
                          />
                        </div>
                        <div className='py-6'>
                          <h3 className='uppercase font-body text-textparagraph font-semibold'>
                            PROFILE PICTURE
                          </h3>

                          <p className='font-body pt-3.5 pb-3 text-textparagraph font-normal'>
                            Image should be at least 400x400 pixels big.
                          </p>

                          <div>
                            <DropzoneArea
                              filesLimit={1}
                              showAlerts={false}
                              maxFileSize={5000000}
                              acceptedFiles={['image/*']}
                              onChange={(files) => setProfilePicture(files)}
                            />
                          </div>
                        </div>
                      </div>
                    }
                    footer={true}
                    footerContent={
                      <button
                        className='w-full h-14 falsefocus:shadow-outline  bg-main text-white font-semibold text-xl px-6 py-3 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                        type='submit'
                        disabled={!isValid || loading}
                      >
                        {loading ? <ButtonLoader /> : <> Save Changes </>}
                      </button>
                    }
                  />
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : null}
      <CalendlyTutorial
        setShowCalendlyTutorialModal={setShowCalendlyTutorialModal}
        showCalendlyTutorialModal={showCalendlyTutorialModal}
      />
    </>
  );
};

const CalendlyTutorial = ({
  showCalendlyTutorialModal,
  setShowCalendlyTutorialModal,
}) => {
  return (
    <>
      {showCalendlyTutorialModal ? (
        <>
          <ModalWrapper
            title={'How to use Calendly'}
            closeModal={() => {
              setShowCalendlyTutorialModal(false);
            }}
            bodyContent={
              <div className='mt-4 bg-loading bg-no-repeat bg-top'>
                <iframe
                  width='100%'
                  height='400'
                  src='https://www.youtube.com/embed/BEVPZxDZAJk'
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              </div>
            }
            footer={false}
          />
        </>
      ) : null}
    </>
  );
};

export default function IndividualAbout() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.user);
  const { loading } = useSelector((state) => state.institution);
  const comments = useSelector((state) => state.comments.data);
  const follow = useSelector((state) => state.user?.follow_data);
  const points = useSelector((state) => state.user?.user_points);

  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);
  const [showSendInvitationModal, setShowSendInvitationModal] = useState(false);
  const [showUserPointsModal, setShowUserPointsModal] = useState(false);

  useEffect(() => {
    dispatch(institutionActions.getInstitutions({}));
    dispatch(userActions?.getUsers());
    dispatch(commentActions.getAllComments());
    dispatch(userActions.getFollow({ userID: user?._id }));
    dispatch(userActions.getUserPoints({ userId: user?._id }));
  }, []);

  const mentorComments = comments?.filter(
    (item) => item.idOfCommentedAbout === user?._id,
  );

  return (
    <>
      <Nav aboutActive={true} />
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <>
          <UserPointsModal
            setShowUserPointsModal={setShowUserPointsModal}
            showUserPointsModal={showUserPointsModal}
            points={points}
          />
          <div className='bg-lightgraybg h-full'>
            <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
              <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
                <div className='flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-6'>
                  <div className='flex flex-col  container mx-auto'>
                    <div className='  lg:w-full block mt-1 text-headingtext z-20'>
                      <div className='p-8 flex  flex-col lg:flex-row justify-center lg:justify-between  bg-white rounded filter drop-shadow  md:text-left sm:text-md'>
                        <div className=' mx-auto lg:mx-0 flex flex-col lg:flex-row justify-start'>
                          <div className='mx-auto lg:mx-0 h-28 w-28  sm:h-28 sm:w-28  md:h-36 md:w-36 lg:h-48 lg:w-48  mb-4 lg:mb-0  '>
                            <img
                              src={
                                user?.profilePicture?.publicUrl ||
                                `https://ui-avatars.com/api/?name=${user?.firstname}+${user?.lastname}&bold=true`
                              }
                              alt={`${user?.firstname} ${user?.lastname}`}
                              className='rounded-full object-cover h-full w-full shadow-md'
                            />
                          </div>
                          <div className='lg:2 px-4 lg:text-left lg:w-8/12 w-full'>
                            <h5 className='mb-3 text-xl lg:text-5xl font-bold leading-none text-charcoal font-display'>
                              {`${user.firstname} ${user.lastname}`}
                            </h5>
                            <p className='mb-5 text-textsecondary opacity-70 text-sm lg:text-2xl text-left capitalize font-display'>
                              {
                                user?.usercategory[
                                  user?.usercategory.length - 1
                                ]
                              }
                            </p>
                            <p
                              style={{
                                fontSize: '15px',
                              }}
                              className='mb-5 text-textsecondary opacity-70 lg:text-2xl text-left capitalize font-display'
                            >
                              {follow?.following.length} Following{' '}
                              &nbsp;&nbsp;&nbsp; {follow?.followers.length}{' '}
                              Followers
                            </p>
                          </div>
                        </div>

                        <div className=' px-4  sm:px-2 '>
                          <div className='flex flex-col justify-around w-full lg:w-60  h-full '>
                            <button
                              className={`inline-flex items-center justify-center w-full h-12 px-6 
                                    text-white bg-darkerblue  hover:bg-navyblue
                                 font-medium tracking-wide  transition duration-200 rounded-full shadow-md md:w-auto  focus:shadow-outline focus:outline-none`}
                              onClick={() => {
                                setShowUpdateProfileModal(true);
                              }}
                            >
                              <Edit />

                              <span className='ml-2 font-display'>
                                Edit Profile
                              </span>
                            </button>

                            <button
                              className={`inline-flex items-center justify-center w-full h-12 px-6 
                                    text-white bg-darkerblue  hover:bg-navyblue
                                 font-medium tracking-wide  transition duration-200 rounded-full shadow-md md:w-auto  focus:shadow-outline focus:outline-none`}
                              onClick={() => {
                                setShowSendInvitationModal(true);
                              }}
                            >
                              <People />
                              <span className='ml-2 font-display'>
                                Invite a friend
                              </span>
                            </button>
                            <button
                              className={`inline-flex items-center justify-center w-full h-12 px-6 
                                    text-white bg-darkerblue  hover:bg-navyblue
                                 font-medium tracking-wide  transition duration-200 rounded-full shadow-md md:w-auto  focus:shadow-outline focus:outline-none`}
                              onClick={() => {
                                setShowUserPointsModal(true);
                              }}
                            >
                              <AcUnit />
                              <span className='ml-2 font-display'>
                                My Points
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='lg:w-full block h-full text-headingtext z-10'>
                      <div className=' flex flex-col lg:flex-row justify-between  bg-white  lg:text-left text-md'>
                        <div className=' flex justify-start lg:w-8/12 '>
                          <div className='flex flex-col justify-start pt-6 lg:py-6 lg:pl-6 w-full'>
                            <TabLayout
                              user={user}
                              comments={mentorComments?.reverse()}
                            />
                          </div>
                        </div>

                        <div className='lg:w-4/12 justify-around border-l-2 border-lightGrayBg2'>
                          <div className='flex lg:flex-col justify-start p-6 '>
                            <div className=' bg-upskillingAlt flex justify-center mb-9 bg-cover bg-center'>
                              <div
                                className=''
                                style={{
                                  background:
                                    'linear-gradient(0deg, rgba(0,0,0,0.83) 0%, rgba(0,0,0,0.2) 38%, rgba(255,255,255,0) 100%)',
                                }}
                              >
                                <div className='flex flex-col justify-around py-20 lg:py-48 px-12 text-white'>
                                  <span className='text-xl lg:text-3xl  font-bold font-display text-center mb-10'>{`Career Guidance after 12th science session`}</span>
                                  <button
                                    // type='submit'
                                    className='inline-flex justify-center w-full px-2 lg:px-8 py-2 lg:py-2 border border-white font-display font-semibold text-white text-lg lg:text-xl  transition duration-200 rounded-full shadow-md bg-none hover:bg-white hover:text-graybg2 focus:shadow-outline focus:outline-none'
                                  >
                                    Join Session
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UpdateProfileModal
            data={user}
            loading={loading}
            setShowUpdateProfileModal={setShowUpdateProfileModal}
            showUpdateProfileModal={showUpdateProfileModal}
          />
          <SendInvitationModal
            setShowSendInvitationModal={setShowSendInvitationModal}
            showSendInvitationModal={showSendInvitationModal}
          />
        </>
      )}
    </>
  );
}
