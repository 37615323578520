import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import * as userActions from '../redux/actions/users';

// Actions
//import * as discussionGroupsActions from '../redux/actions/discussiongroups';
import { useSelector, useDispatch } from 'react-redux';
import ButtonLoader from './ButtonLoader';

export default function FollowCard(props) {
  const dispatch = useDispatch();
  //const group = props.group;
  //const user = props.user;
  //const dispatch = useDispatch();

  const { success } = useSelector((state) => state?.discussiongroups);
  const user = useSelector((state) => state?.user);
  const [sendJoinRequest, setSendJoinRequest] = useState(false);

  /*const handleSendRequest = () => {
    setSendJoinRequest(true);
    dispatch(
      discussionGroupsActions.joinDiscussionGroup({
        userId: user._id,
        fullName: `${user.firstname} ${user.lastname}`,
        whatToJoin: 'discoussiongroup',
        idOfWhatToJoin: group._id,
        nameOfWhatToJoin: group.groupName,
        toJoinAs: 'member',
        canBeAcceptedBy: [...group.admins],
      }),
    );
  };*/

  function handleFollow(followedID) {
    const payload = { followed: followedID, follower: user?.user._id };
    dispatch(userActions.addFollow(payload));
    setTimeout(function () {
      dispatch(userActions.getFollow({ userID: user?.user._id }));
    }, 1000);
  }

  useEffect(() => {
    if (success.message === 'Request to join submitted') {
      setSendJoinRequest(false);
    }
  }, [success]);

  const followed = user?.follow_data?.following.find((item) =>
    item.followed.includes(props?.userId),
  );

  return (
    <>
      <div className='p-4 bg-white   w-full pb-2.5'>
        <div className='flex items-center justify-between'>
          <div className='flex'>
            <img
              className='object-cover w-10 h-10 mr-1 lg:mr-4 rounded-full shadow'
              src={
                props.pic ||
                `https://ui-avatars.com/api/?name=${props.name}&bold=true`
              }
              alt='Avatar'
            />

            <div className='flex flex-col pr-2 '>
              <div>
                <p className='text-sm font-semibold font-body text-charcoal capitalize'>
                  {props.name}
                </p>
              </div>
              <div>
                <p className='text-xs font-body text-textparagraph capitalize'>
                  {props?.content}
                </p>
              </div>
            </div>
          </div>

          <div className='items-center'>
            {props.type === 'group' ? (
              <Link
                to={`/ChatRoom/${props?.group?._id}`}
                className='inline-flex items-center font-medium text-sm sm:max-w-full justify-center h-8 w-24 px-4 sm:mr-0  border border-darkerblue tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
              >
                {sendJoinRequest ? (
                  <ButtonLoader size='default' color='primary' />
                ) : (
                  <div>{props.button}</div>
                )}
              </Link>
            ) : props.button === 'More' ? (
              <Link
                to={
                  props?.userId
                    ? `/${
                        props.userType === 'mentors' ? 'mentors' : 'institution'
                      }/more/${props?.userId}`
                    : '#'
                }
              >
                <button
                  style={{
                    fontSize: '12px',
                  }}
                  className='inline-flex items-center font-medium sm:max-w-full justify-center h-8 w-24 px-4 sm:mr-0  border border-darkerblue tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                >
                  {props.button}
                </button>
              </Link>
            ) : props.button === 'Profile' ? (
              <Link
                to={props?.userId ? `/users/profile/${props?.userId}` : '#'}
              >
                <button
                  style={{
                    fontSize: '12px',
                  }}
                  className='inline-flex items-center font-medium sm:max-w-full justify-center h-8 w-24 px-4 sm:mr-0  border border-darkerblue tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
                >
                  {props.button}
                </button>
              </Link>
            ) : followed && props.button === 'Follow' ? (
              <button
                style={{
                  borderColor: 'green',
                  color: 'green',
                  fontSize: '12px',
                }}
                className='inline-flex items-center font-medium sm:max-w-full justify-center h-8 w-24 px-4 sm:mr-0  border border-darkerblue tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
              >
                Following
              </button>
            ) : !followed && props.button === 'Follow' ? (
              <button
                onClick={() => handleFollow(props?.userId)}
                className='inline-flex items-center font-medium text-sm sm:max-w-full justify-center h-8 w-24 px-4 sm:mr-0  border border-darkerblue tracking-wide text-darkerblue transition duration-200 rounded-md bg-white hover:bg-darkerblue hover:text-white focus:shadow-outline focus:outline-none'
              >
                {props.button}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
