import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MyMessage from './MyMessage';
import TheirMessage from './TheirMessage';
import MessageForm from './MessageForm';
import ChatRequest from './ChatRequest';
import Truncate from 'react-truncate';
import NoContentPlaceholder from '../components/NoContentPlaceholder';

import * as chatActions from '../redux/actions/chat';

const ChatFeed = ({ groupID }) => {
  const dispatch = useDispatch();
  const {
    chatDiscussionData,
    chatDiscussionTitle,
    chatDiscussionChatID,
    isChatMember,
    groupChatRequest,
    chatAdmin,
  } = useSelector((state) => state?.chat);

  const userName = useSelector((state) => state.user.user);
  var userId = userName?._id;

  const getAllAChatDiscussion = ({ groupId, chatId }) => {
    dispatch(
      chatActions.getAllChatDiscussions({
        groupId,
        chatId,
      }),
    );
  };

  useEffect(() => {
    if (chatDiscussionChatID !== null && isChatMember === 1) {
      const interval = setInterval(() => {
        const groupId = groupID;
        const chatId = chatDiscussionChatID;
        getAllAChatDiscussion({ groupId, chatId });

        dispatch(chatActions.getGroupChatRequests({ chatId, userId }));

        var objDiv = document.getElementById('renderedMessage');
        objDiv.scrollTo({
          top: objDiv.scrollHeight,
          behavior: 'smooth',
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [chatDiscussionChatID, userId, isChatMember]);

  return (
    <>
      {isChatMember === 1 ? (
        <>
          <div className='chat-feed' style={{ overflow: 'hidden' }}>
            <h1 className='p-4  font-body font-semibold text-sm border-b border-linecolor mb-5 '>
              <div className='chat-title-container'>
                <div
                  className='chat-title'
                  style={{
                    textTransform: 'capitalize',
                    fontSize: '19px',
                    fontWeight: 'normal',
                  }}
                >
                  <Truncate width='360' ellipsis={<span>...</span>}>
                    {chatDiscussionTitle}
                  </Truncate>
                </div>
              </div>
            </h1>
            <div>
              <div id='renderedMessage' className='renderedMessages'>
                {chatDiscussionData.length > 0 ? (
                  chatDiscussionData.map((data, key) => {
                    const isMyMessage = userName?._id === data.userId;

                    return (
                      <div key={`msg_${key}`}>
                        <div className='message-block'>
                          {isMyMessage ? (
                            <MyMessage message={data.content} />
                          ) : (
                            <TheirMessage
                              message={data.content}
                              userID={data.userId}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div style={{ height: '64vh', paddingTop: '20vh' }}>
                    <NoContentPlaceholder content='Discussions' />
                  </div>
                )}

                {chatAdmin === userId ? (
                  <>
                    {groupChatRequest.map((data, key) => {
                      return (
                        <div key={`msg_${key}`}>
                          <ChatRequest data={data} />
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </div>

            <div className='message-form-container'>
              <MessageForm groupID={groupID} />
            </div>
          </div>
        </>
      ) : (
        <div className='chat-feed' style={{ paddingTop: '20vh' }}>
          <NoContentPlaceholder content='Chats' />
        </div>
      )}
    </>
  );
};

export default ChatFeed;
