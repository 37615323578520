import * as fetchFunctions from '../../api/index';
import * as sessionsTypes from '../types/sessions';
import sessionsEndpoints from '../endpoints/sessions';

export const createSession = (payload) => async (dispatch, getState) => {
  dispatch({
    type: sessionsTypes.SESSIONS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    sessionsEndpoints.create_sessions,
    payload,
    token,
    true,
  );

  if (result.success) {
    dispatch({
      type: sessionsTypes.SESSIONS_SUCCESS,
      payload: {
        status: true,
        message: 'Session Successfully created',
      },
    });
    dispatch({
      type: sessionsTypes.SESSIONS_RESET,
    });
  } else {
    dispatch({
      type: sessionsTypes.SESSIONS_ERROR,
      payload: {
        error: true,
        errorMessage: 'Failed to create session',
      },
    });
  }
};

export const registerForSession = (payload) => async (dispatch, getState) => {
  dispatch({
    type: sessionsTypes.SESSIONS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.postData(
    sessionsEndpoints.register_for_session,
    payload,
    token,
  );

  if (result.success) {
    dispatch({
      type: sessionsTypes.SESSIONS_SUCCESS,
      payload: {
        status: true,
        message: 'Registration Successful',
      },
    });
    dispatch({
      type: sessionsTypes.SESSIONS_RESET,
    });
  } else {
    dispatch({
      type: sessionsTypes.SESSIONS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getAllSessions = () => async (dispatch, getState) => {
  dispatch({
    type: sessionsTypes.SESSIONS_LOADING,
  });

  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    sessionsEndpoints.get_all_sessions,
    token,
  );
  if (result.success) {
    dispatch({
      type: sessionsTypes.ALL_SESSIONS,
      payload: result.data,
    });
    dispatch({
      type: sessionsTypes.SESSIONS_RESET,
    });
  } else {
    dispatch({
      type: sessionsTypes.SESSIONS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getMySessions = () => async (dispatch, getState) => {
  dispatch({
    type: sessionsTypes.SESSIONS_LOADING,
  });
  const { token } = getState().user;

  const result = await fetchFunctions.getData(
    sessionsEndpoints.get_my_sessions,
    token,
  );
  if (result.success) {
    dispatch({
      type: sessionsTypes.MY_SESSIONS,
      payload: result.data,
    });
    dispatch({
      type: sessionsTypes.SESSIONS_RESET,
    });
  } else {
    dispatch({
      type: sessionsTypes.SESSIONS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getAllUnauthSessions = () => async (dispatch) => {
  dispatch({
    type: sessionsTypes.SESSIONS_LOADING,
  });

  const result = await fetchFunctions.getData(
    sessionsEndpoints.get_all_unauth_sessions,
  );

  if (result.success) {
    dispatch({
      type: sessionsTypes.ALL_UNAUTH_SESSIONS,
      payload: result.data,
    });
    dispatch({
      type: sessionsTypes.SESSIONS_RESET,
    });
  } else {
    dispatch({
      type: sessionsTypes.SESSIONS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};

export const getSpecificSession = (sessionID) => async (dispatch, getState) => {
  dispatch({
    type: sessionsTypes.SESSIONS_LOADING,
  });

  const { token } = getState().user;
  const result = await fetchFunctions.getData(
    `${sessionsEndpoints.specific_session}/${sessionID}`,
    token,
  );

  if (result.success) {
    dispatch({
      type: sessionsTypes.SPECIFIC_SESSION,
      payload: result.data,
    });
    dispatch({
      type: sessionsTypes.SESSIONS_RESET,
    });
  } else {
    dispatch({
      type: sessionsTypes.SESSIONS_ERROR,
      payload: {
        error: true,
        errorMessage: result?.error,
      },
    });
  }
};
