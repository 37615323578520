import {
  Comment,
  FiberManualRecord,
  Send,
  Share,
  ThumbUp,
} from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import ContentPreview from './ContentPreview';
import IconsRole from './IconsRole';

const PostCard = ({
  users,
  content,
  commentPostClick,
  sendPostClick,
  sharePostClick,
  carousel,
}) => {
  const user = users?.find((user) => user?._id === `${content?.userID}`);

  return (
    <div className='max-w-full mx-2  bg-lightGrayBg2 box-border border border-linecolor py-5  flex flex-col overflow-hidden mb-8 rounded-sm shadow'>
      <div className='px-4 w-full'>
        <div className='flex flex-row justify-between'>
          <div className='flex justify-start'>
            <img
              src={
                user?.profilePicture?.publicUrl ||
                `https://ui-avatars.com/api/?name=${content.author}&bold=true`
              }
              alt={user?.firstname}
              className='object-cover rounded-full h-16 w-16 flex items-center justify-center mr-2 shadow-md '
            />
            <div className='flex flex-col pl-3.5 pr-12'>
              <div>
                <p className='flex-1 w-32 text-sm font-semibold font-body text-charcoal'>
                  {content.author}
                </p>
              </div>
              <div>
                <p className='text-xs font-body text-textparagraph'>
                  {content.title}
                </p>
              </div>
            </div>
          </div>
          <span className='text-xs font-medium font-body text-headingtext'>
            {moment(content?.createdAt).local().fromNow()}
          </span>
        </div>
      </div>
      <div className='w-full h-64 sm:h-96 text-xs font-body text-charcoal px-4 pt-5 mb-2 '>
        <a
          href={
            content.category === 'Video'
              ? `/video/watch/${content?._id}` // For videos
              : content?.category === 'Article'
              ? `/article/read/${content?._id}` // For articles
              : `/image/${content?._id}` // For photos
          }
        >
          <ContentPreview
            fileUrl={content?.thumbnailFile?.publicUrl}
            profile_post
          />
        </a>
      </div>
      <div className='flex  mx-auto w-11/12 justify-start my-1'>
        <div className='text-xs text-headingtext mr-2'>{`${
          content?.likes || '0'
        } likes`}</div>
        <div className='text-xs text-headingtext'>
          <FiberManualRecord fontSize='inherit' />
        </div>
        <div className='text-xs text-headingtext ml-2'>{`${
          content?.comments?.length || '0'
        } comments`}</div>
      </div>

      <div className='flex border border-b border-lightgraybg mx-auto w-11/12 justify-center my-2'></div>
      <div
        className={`flex flex-row  w-full ${
          carousel ? 'w-full' : 'md:w-3/6'
        } mt-2 text-sm`}
      >
        <IconsRole
          icon={<ThumbUp fontSize='small' />}
          role='Like'
          count={content.likes?.length || 0}
        />
        <IconsRole
          icon={<Comment fontSize='small' />}
          role='Comment'
          count={content?.comments?.length || 0}
          Action={commentPostClick}
        />
        <IconsRole
          icon={<Send fontSize='small' />}
          role='Send'
          Action={sendPostClick}
        />
        <IconsRole
          icon={<Share fontSize='small' />}
          role='Share'
          Action={sharePostClick}
        />
      </div>
    </div>
  );
};

export default PostCard;
