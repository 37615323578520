import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Footer from '../components/NewFooter';
import NavHeaderLanding from '../components/NewNavbar';
import { ArrowRightAlt } from '@material-ui/icons';
import { EmailModal } from '../components/ContactForms';
import { useAnimation, motion } from 'framer-motion/dist/framer-motion';
import { useInView } from 'react-intersection-observer';
import Carousel from 'react-multi-carousel';
import '../bright.css';
import Explore from '../components/Explore';
import UserPointsModal from '../components/UserPointsModal';
import ChatGPTModal from '../components/ChatGPT';
import ChatGPTButton from '../components/ChatGPTButton';

import * as userActions from '../redux/actions/users';

const categoryVariants = {
  visible: {
    opacity: 1,
    scale: 1,
    marginBottom: 0,
    transition: { duration: 1 },
  },
  hidden: { opacity: 0.3, scale: 1, marginBottom: 15 },
};

export default function CareerGuide() {
  window.scrollTo(0, 0);

  return (
    <>
      <div>
        <div className=''>
          <div className='flex flex-col relative '>
            <NavHeaderLanding background={'bg-newMain bg-opacity-75'} />
            <div className='headerWrapper'>
              <div className=' w-full bg-lightGrayBg'>
                <Header />
              </div>

              <div className='hidden md:block w-full h-full headerMethodsWrapper'>
                <CareerGuidanceMethods />
              </div>

              <div className='block md:hidden w-full bg-lightGrayBg'>
                <CareerGuidanceMethods />
              </div>
            </div>

            <div className=' w-full h-full md:bg-lightGrayBg bottom-0 pt-10 md:pt-80'>
              <PersonalDevelopmentPlans />
            </div>

            <div className='bg-lightGrayBg w-full h-full md:py-16'>
              <MiddleBanner />
            </div>
            <div className='bg-lightGrayBg w-full h-full md:py-16'>
              <div className='w-full my-10 '>
                <SessionBanner />
              </div>
            </div>

            <div className='w-full'>
              <Explore />
            </div>

            <div className=''>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const Header = () => {
  return (
    <div className=' w-full career-top-banner-wrapper h-screen'>
      <div className='w-full career-transparent'>
        <div className='text-white w-full md:w-5/12 md:ml-28 pl-4 pt-3'>
          <div className='w-full pt-72 md:pt-40 lg:pt-52'>
            <h1 className='center-left text-5xl md:text-7xl font-semibold md:font-bold my-4 Cabin'>
              Career Guidance
            </h1>
            <h1 className='center-left text-xl md:text-3xl my-4 open-sans md:pr-16'>
              Simple steps to discovering your perfect career
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

const CareerGuidanceMethods = () => {
  return (
    <div className='p-0 md:px-2 md:py-5 lg:px-8 lg:py-8'>
      <div className='p-0 lg:px-10 grid grid-cols-1 gap-y-4 md:gap-x-4 lg:gap-x-6 md:grid-cols-3 w-full'>
        <div className=''>
          <CareerAssessmentCard
            title={'Take A Free Career Assesment'}
            image={
              'https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-5198239-640w.jpeg'
            }
            description={`Self awareness is the start to figuring out your career path.
            Take an assessment to know what career options are compatible
            with your interests, skills, values and personality.`}
            link='https://www.truity.com/test/holland-code-career-test'
            target='_blank'
            rel='noreferrer'
          />
        </div>
        <div>
          <CareerAssessmentCard
            title={'Connect With Peers'}
            image={
              '	https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-9429371-640w.jpeg'
            }
            description={`Connect and engage with like minded people to access information, learn together, hone each other's skills. Ask, share, think out loud, through peer communities.`}
            link={'/signup'}
          />
        </div>
        <div>
          <CareerAssessmentCard
            image={
              'https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-6457490-2880w.jpeg'
            }
            title={'Connect With A Mentor'}
            description={`Seek guidance from those who have been there. Connect with
                        Alumni, industry professionals, subject matter experts, and
                        career counsellors.`}
            link={'/mentors'}
          />
        </div>
      </div>
    </div>
  );
};

const CareerAssessmentCard = ({
  title,
  link,
  description,
  target,
  rel,
  image,
}) => {
  const [showChatGPTModal, setShowChatGPTModal] = useState(false);
  return (
    <>
      <ChatGPTModal
        setShowChatGPTModal={setShowChatGPTModal}
        showChatGPTModal={showChatGPTModal}
      />
      <div className=' mx-0 md:mx-2 lg:mx-4 bg-white md:rounded-2xl pt-3'>
        <div className=''>
          <div className={` text-center pb-4`}>
            <div className='methods flex flex-col justify-center p-4 '>
              <div className='w-full block md:hidden'>
                <img
                  className='rounded-xl gray-scale-100'
                  style={{ height: '280px', width: '100%' }}
                  src={image}
                  alt='Career guidance'
                />
              </div>
              <div className='text-center h-40 md:h-80 lg:h-72'>
                <div className='methods-header mb-1 md:mb-3 md:px-3 font-semibold text-xl md:text-3xl'>
                  <h4 className='Cabin'>{title}</h4>
                </div>

                <p className='px-6 md:px-2 text-md lg:text-lg open-sans lg:px-4 methods-description mb-1 md:mb-2 py-2 md:py-6 flex justify-center align-center font-thin'>
                  {description}
                </p>
              </div>

              <div className='w-10/12 md:w-11/12 lg:w-10/12 mx-auto '>
                <div className='flex flex-row justify-center w-full px-auto categoryLinkTag py-3 lg:py-4'>
                  <p className='text-white'>
                    <a href={link}>
                      LEARN MORE
                      <span className='ml-1'>
                        <ArrowRightAlt />
                      </span>
                    </a>
                  </p>
                </div>
                <ChatGPTButton
                  setShowChatGPTModal={setShowChatGPTModal}
                  marginLeft='0%'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PersonalDevelopmentPlans = () => {
  return (
    <div className='  mx-auto sm:max-w-xl md:max-w-full md:max-w-screen-xl px-4 mb-3 md:mb-0 md:pt-12 lg:pt-2'>
      <div className='mb-10  md:mb-12 text-center'>
        <h1 className='px-2 md:px-4 plansHeader text-center text-3xl md:text-4xl font-semibold Cabin my-6'>
          Personal Development Plan
        </h1>
        <p className='px-2 md:px-4 mx-auto leading texl-xl my-6 font-thin open-sans'>
          Map out your plan today
        </p>
      </div>
      <div className='grid gap-x-10 md:max-w-screen-lg md:grid-cols-2 xl:max-w-full md:space-x-0'>
        <PersonalDevelopmentCard
          title={'TRY IT YOURSELF'}
          link={'/slides'}
          background='https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-669986-640w.jpeg'
        />

        <PersonalDevelopmentCard
          title={'GET HELP FROM A MENTOR'}
          link={'/mentors'}
          background='https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-3894383-640w.jpeg'
        />
      </div>
    </div>
  );
};

const PersonalDevelopmentCard = ({ title, link, background, anchor }) => {
  return (
    <Link to={link}>
      <div className='w-full group flex flex-col relative bg-white rounded-lg h-80 md:h-96 shadow-sm '>
        <img
          className='w-full h-full rounded-xl'
          style={{ height: '100%', width: '100%' }}
          src={background}
          alt='session banner'
        />

        <div
          className='absolute rounded-xl h-80 md:h-96 top-0 flex flex-col w-full justify-evenly flex-grow p-8 hidden md:block z-5'
          style={{ background: 'rgba(0,0,0,0.4)' }}
        >
          <div className='text-center'>
            <div className='text-2xl font-semibold text-white group-hover:text-white md:pt-28 Cabin'>
              {title}
            </div>
          </div>
        </div>
      </div>

      <div
        className='flex flex-col justify-center w-full flex-grow py-3 px-auto block md:hidden'
        style={{ background: 'rgba(0,0,0, 0.7)' }}
      >
        <div className='text-center'>
          <div className='text-xl font-semibold text-white group-hover:text-white Cabin'>
            {title}
          </div>
        </div>
      </div>
    </Link>
  );
};

const SessionBanner = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const points = useSelector((state) => state.user?.user_points);
  const { user } = useSelector((state) => state?.user);
  const [showUserPointsModal, setShowUserPointsModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(userActions.getUserPoints({ userId: user?._id }));
    }
  }, [user]);

  function handleChange(user) {
    if (user) {
      setShowUserPointsModal(true);
    } else {
      history.push('/login');
    }
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <UserPointsModal
        setShowUserPointsModal={setShowUserPointsModal}
        showUserPointsModal={showUserPointsModal}
        points={points}
      />
      <div className='w-11/12 lg:w-10/12 mx-auto flex flex-col md:flex-row justify-around h-full my-10 md:my-20 h-full'>
        <div className='w-full md:w-2/3 md:pr-6 mb-4 md:mb-0'>
          <div className='w-full h-full sessionBanner'>
            <img
              className='w-full h-1/2 md:h-full '
              style={{ height: '100%', width: '100%' }}
              src='https://lirp.cdn-website.com/md/pexels/dms3rep/multi/opt/pexels-photo-3183197-2880w.jpeg'
              alt='session banner'
            />
          </div>
        </div>

        <div className='w-full md:w-1/3'>
          <div className='px-10 md:px-6 lg:px-10 h-full w-full text-white px-auto py-6 bg-newMain flex flex-col justify-center'>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              transitionDuration={500}
              removeArrowOnDeviceType={[
                'mobile',
                'tablet',
                'desktop',
                'superLargeDesktop',
              ]}
              renderButtonGroupOutside={true}
            >
              <div className=' w-full justify-center '>
                <h1 className='text-center text-4xl mt-3 mb-1 Cabin'>CAREER</h1>
                <h1 className='text-center text-4xl my-3 Cabin'>GUIDANCE</h1>
                <p className='text-center text-md md:text-xl work-sans'>
                  Support our cause. Contact us with any support, suggestions on
                  how we can improve, partner, collaborate
                </p>
                <div className='w-full flex justify-center mt-8 mb-2'>
                  <p
                    className='w-full px-auto whiteCategoryLinkTag py-4 px-8 md:px-4 lg:px-4 text-center xl:px-10 bg-white category-spacing'
                    onClick={() => setShowEmailModal(true)}
                  >
                    REACH OUT
                    <span className='ml-1'>
                      <ArrowRightAlt />
                    </span>
                  </p>
                </div>
              </div>
              <div className=' w-full justify-center '>
                <h1 className='text-center text-3xl mt-3 mb-1 Cabin'>
                  HOW TO EARN
                </h1>
                <h1 className='text-center text-3xl my-3 Cabin'>
                  POINTS WITH DELV
                </h1>
                <br />
                <p className='text-center text-md md:text-xl work-sans'>
                  Earn points when you sign up, and everytime you post, share on
                  social media or invite a friend
                </p>

                <div className='w-full flex justify-center mt-8 mb-2'>
                  <p
                    className='w-full px-auto whiteCategoryLinkTag py-4 px-8 md:px-4 lg:px-4 text-center xl:px-10 bg-white category-spacing'
                    onClick={() => {
                      handleChange(user);
                    }}
                  >
                    START NOW
                    <span className='ml-1'>
                      <ArrowRightAlt />
                    </span>
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
      <EmailModal
        showEmailModal={showEmailModal}
        setShowEmailModal={setShowEmailModal}
      />
    </>
  );
};

const MiddleBanner = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <div className='middleBannerWrapper'>
      <div className='middleBanner w-full h-full'>
        <div className='w-full middleBannerContent pt-80 md:pt-64 lg:pt-80 flex flex-col justify-center md:justify-start md:ml-24'>
          <motion.h4
            ref={ref}
            animate={controls}
            initial='hidden'
            variants={categoryVariants}
            className='center-left text-5xl w-full md:w-1/2 font-semibold font-bold md:text-7xl join Cabin mt-28 md:mt-24'
          >
            <h4>Shape the next generation</h4>
          </motion.h4>
          <motion.p
            ref={ref}
            animate={controls}
            initial='hidden'
            variants={categoryVariants}
            className='center-left mb-5 mt-1 open-sans text-lg md:text-3xl md:mb-1 trainer'
          >
            <p>Help someone achieve their dreams.</p>
          </motion.p>
          <motion.p
            ref={ref}
            animate={controls}
            initial='hidden'
            variants={categoryVariants}
            className='center-left mb-3 open-sans mt-1 text-lg md:text-3xl md:mb-6 md:mt-1 trainer'
          >
            <p>Make a difference.</p>
          </motion.p>
          <div className='w-full flex justify-center align-center md:justify-start md:align-start pt-5'>
            <a
              target='_blank'
              href='/login'
              className='categoryLinkTag px-8 md:px-16 tracking-wide py-4 text-md open-sans'
            >
              JOIN AS A MENTOR
              <span className='mx-2'>
                <ArrowRightAlt />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
