import React from 'react';
import PropTypes from 'prop-types';

import Thumbnail from '../assets/landingX.jpeg';

const ContentPreview = ({ fileUrl, height = '100%', profile_post }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${fileUrl || Thumbnail})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // borderRadius: '5px',
        height,
      }}
      className={` ${profile_post && 'border border-linecolor'} w-full`}
    />
  );
};
ContentPreview.protoTypes = {
  fileUrl: PropTypes.string.isRequired,
};
export default ContentPreview;
