import React, { useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { useDispatch, useSelector } from 'react-redux';
import * as commentsActions from '../redux/actions/comments';
import ButtonLoader from './ButtonLoader';
import ModalWrapper from './ModalWrapper';

const FeedbackModal = ({ setShowFeedbackModal, showFeedbackModal }) => {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const { loading } = useSelector((state) => state.comments);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (rating > 0 && feedback !== '') {
      // This will not submit poorly formed feedback
      dispatch(
        commentsActions.addComment({
          commentAbout: rating,
          commentText: feedback,
          idOfCommentedAbout: 'delv-platform',
        }),
      );
    }
    setRating(0);
    setFeedback('');
    setShowFeedbackModal(false);
    alert('Thank you for your feedback');
  };

  return (
    <>
      {showFeedbackModal ? (
        <>
          <form onSubmit={handleSubmit}>
            <ModalWrapper
              title={'Feedback'}
              closeModal={() => setShowFeedbackModal(false)}
              bodyContent={
                <>
                  <div className='relative flex-auto'>
                    <span className='font-semibold text-charcoal text-lg w-full mt-3 flex justify-start'>
                      Your rating
                    </span>
                    <div className='flex flex-row text-graybg pb-2'>
                      <ReactStars
                        count={5}
                        onChange={(new_rating) => {
                          setRating(new_rating);
                        }}
                        size={50}
                        isHalf={true}
                        emptyIcon={<i className='far fa-star'></i>}
                        halfIcon={<i className='fa fa-star-half-alt'></i>}
                        fullIcon={<i className='fa fa-star'></i>}
                        activeColor='#ffd700'
                      />
                    </div>
                    <span className='font-semibold text-charcoal text-xl sm:text-3xl w-full mt-1 flex justify-start'>
                      Leave us some feedback. It will take just a minute of your
                      time. Thank you.
                    </span>
                    <div className='mt-4'>
                      <textarea
                        rows='9'
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        className=' px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-noone focus:ring focus:ring-offset-darkerblue focus:ring-opacity-50 w-full'
                      />
                    </div>
                  </div>
                </>
              }
              footer={true}
              footerContent={
                <>
                  <div className='justify-start'>
                    <span className='font-normal text-charcoal text-sm mb-4 flex justify-start   '>
                      By clicking submit, I agree that my feedback may be viewed
                      by the Delv community in compliance with the Delv Terms of
                      use and my profile privacy settings.
                    </span>
                  </div>
                  <div className='flex w-full justify-center  lg:justify-end'>
                    <button
                      type='submit'
                      className='cursor-pointer w-full inline-flex items-center justify-center h-14 text-xl px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                    >
                      {loading ? <ButtonLoader /> : <> Submit Feedback </>}
                    </button>
                  </div>
                </>
              }
            />
          </form>
        </>
      ) : null}
    </>
  );
};

export default FeedbackModal;
