import React, { useEffect, useState } from 'react';
import Truncate from 'react-truncate';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Description, StarRate } from '@material-ui/icons';

import Nav from '../components/NavBarR';
import Loading from '../components/Loading';
import DefaultThumbnail from '../components/DefaultThumbnail';

import * as contentActions from '../redux/actions/content';

const PageBody = () => {
  const dispatch = useDispatch();
  const { data, count, loading, error, errorMessage } = useSelector(
    (state) => state.content,
  );
  const [skip, setSkip] = useState(0);
  const limit = 32;

  const [articleName, setArticleName] = useState('');
  const [myFilteredArticle, setMyFilteredArticles] = useState([]);

  useEffect(() => {
    dispatch(contentActions.getArticles({ limit }));
  }, []);

  useEffect(() => {
    if (error) console.log(errorMessage);
  }, [error]);

  useEffect(() => {
    if (articleName !== '') {
      let myFilteredArticleArray = [];
      data
        ?.filter((item) => item.title.toLowerCase().includes(articleName))
        .map((item) => {
          return myFilteredArticleArray.push(item);
        });
      setMyFilteredArticles(myFilteredArticleArray);
    } else {
      setMyFilteredArticles(data);
    }
  }, [articleName]);

  return (
    <>
      {' '}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div
          className={`bg-lightgraybg w-full ${
            data.length < 5 ? 'h-screen' : 'h-full'
          }`}
        >
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
              <div className='flex flex-col lg:justify-between lg:flex-row'>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4 font-display'>
                    All Articles (
                    {myFilteredArticle.length === 0
                      ? count
                      : myFilteredArticle.length}
                    )
                  </span>
                </h2>
                <div className='mb-10 text-center md:mb-4 lg:mb-4'>
                  <Link to='/articles/mylist'>
                    <button className='inline-flex items-center justify-center w-full h-12 px-2 font-medium tracking-wide text-white transition duration-200 rounded-full shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'>
                      <Description />
                      <span className='ml-2 font-display'>My Articles</span>
                    </button>
                  </Link>
                </div>
              </div>

              <input
                value={articleName}
                onChange={(e) => setArticleName(e.target.value)}
                style={{ border: '1px solid grey', width: '33%' }}
                className='rounded-input px-6 md:mb-8 mb-8'
                placeholder='Search Articles'
                type='search'
              />

              {myFilteredArticle.length === 0 ? (
                <ArticleCategory articles={data} />
              ) : (
                <ArticleCategory articles={myFilteredArticle} />
              )}
              <div>
                `Viewing {data.length > 0 ? skip + 1 : 0} -`
                {count > limit ? skip + limit : data.length} of {count || 0}`
              </div>
              {skip > 30 ? (
                <button
                  onClick={() => {
                    if (skip > 30) setSkip(skip - limit);
                    dispatch(contentActions.getArticles({ skip, limit }));
                  }}
                >
                  {'<< Previous'}
                </button>
              ) : null}
              {skip + limit < count ? (
                <>
                  <span> | </span>
                  <button
                    onClick={() => {
                      setSkip(skip + limit);
                      dispatch(contentActions.getArticles({ skip, limit }));
                    }}
                  >
                    {'Next >>'}
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ArticleCategory = ({ articles }) => {
  return (
    <>
      <div className='flex flex-col mb-6 lg:justify-between md:mb- shadow '>
        <div className='grid grid-cols-1 gap-6 row-gap-4 mb-8 lg:grid-cols-4 sm:grid-cols-2 font-display'>
          {articles?.map((value) => (
            <ArticleCard article={value} key={value?._id} />
          ))}
        </div>
      </div>
    </>
  );
};

const ArticleCard = ({ article }) => {
  return (
    <a href={`/article/read/${article?._id}`}>
      <div
        aria-label='value Item'
        className='inline-block overflow-hidden duration-300 transform bg-white rounded shadow-sm hover:-translate-y-2 border-b-4 border-white hover:border-darkerblue sm:m-3 w-full p-2'
      >
        {article?.thumbnailFile?.publicUrl ? (
          <img
            src={article?.thumbnailFile?.publicUrl}
            alt={article?.title}
            className='object-cover w-full h-48 rounded'
          />
        ) : (
          <div className='object-cover w-full h-48 rounded'>
            <DefaultThumbnail text={article.title} />
          </div>
        )}
        <div className='flex flex-col h-36'>
          <div className='flex flex-col flex-grow justify-between  rounded-b'>
            <div className='py-5 px-2'>
              <h6 className='font-semibold leading-5 capitalize'>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {article?.title}
                </Truncate>
              </h6>
            </div>
            <div className='flex flex-row w-full justify-between px-2 mb-5 font-semibold text-sm text-gray'>
              <p className='capitalize'>{article?.author}</p>
              <p>
                <div className='text-starsorange text-sm'>
                  <StarRate fontSize='small' />
                  <span className=' mx-1  font-semibold text-sm text-starsorange mb-6'>
                    {parseFloat(article?.avgRating || 0).toFixed(1)}
                  </span>
                  <span className='text-linecolor'>
                    ({article?.views || 0}) reads
                  </span>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default function AllArticles() {
  return (
    <>
      <Nav articlesActive={true} />
      <PageBody />
    </>
  );
}
