import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Nav from '../components/NavBarR';
import * as institutionActions from '../redux/actions/institution';
import * as discussionGroupsActions from '../redux/actions/discussiongroups';
import * as affiliationsActions from '../redux/actions/affiliations';

import Loading from '../components/Loading';

import { Close, Notifications, Visibility } from '@material-ui/icons';
import ButtonLoader from '../components/ButtonLoader';
import CustomAlert from '../components/CustomAlert';
import ModalWrapper from '../components/ModalWrapper';
import RequestCard from '../components/RequestCard';
import NoRequests from '../components/NoRequests';
import { useHistory } from 'react-router';

export default function Affilliations() {
  return (
    <>
      <Nav affiliationsActive={true} />
      <PageBody />
    </>
  );
}

const PageBody = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const { data, loading } = useSelector((state) => state?.affiliations);
  const institutions = useSelector((state) => state?.institution)?.data;

  const affiliations = data;

  const AllRequestsList = useSelector(
    (state) => state?.discussiongroups?.joinRequestsList,
  );

  useEffect(() => {
    dispatch(institutionActions.getInstitutions({}));
    dispatch(discussionGroupsActions.getAllJoinRequests(user._id));
    dispatch(affiliationsActions.getAllAffiliations());
  }, []);

  //My affiliations
  const myAffiliations = [];
  affiliations
    ?.filter(
      (item) => item.affiliate === user._id && item.affiliateType === 'mentor',
    )
    .map((item) => myAffiliations.push(item.institution));

  myAffiliations.forEach((affiliation) => {});

  const myInstitutions = institutions?.filter((instituion) =>
    myAffiliations.includes(instituion._id),
  );

  // Pending Join requests
  const joinRequestsList = AllRequestsList?.filter(
    (request) =>
      request.status === 'pending' && request.whatToJoin === 'institution',
  );
  const [showJoinRequestsModal, setShowJoinRequestsModal] = useState(false);

  return (
    <>
      {' '}
      {loading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <>
          <div className='px-4 mx-auto sm:w-xl md:w-full lg:max-w-screen-xl md:px-4 lg:px-8  '>
            <div className='px-2 py-6 container mx-auto sm:max-w-full md:max-w-full lg:max-w-full md:px-24 lg:px-8 lg:py-14'>
              <div className='flex flex-col mb-2 lg:justify-between lg:flex-row md:mb-4'>
                <h2 className='max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none group'>
                  <span className='inline-block mb-1 sm:mb-4'>
                    My Affiliations
                  </span>
                  <div className='h-1 ml-auto duration-300 origin-left transform bg-deep-purple-accent-400 scale-x-30 group-hover:scale-x-100' />
                </h2>
                <div className='mb-6 text-center md:mb-4 lg:mb-4'>
                  <button
                    className=' relative inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-none'
                    onClick={() => setShowJoinRequestsModal(true)}
                  >
                    <Notifications />
                    <span className='ml-2'>Invitations</span>
                    {joinRequestsList?.length > 0 ? (
                      <span className='absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-buttonRed rounded-full'>
                        {joinRequestsList?.length}
                      </span>
                    ) : null}
                  </button>
                </div>
              </div>
              {/* Content Table */}
            </div>
          </div>

          <div className='mx-auto sm:w-screen md:w-full lg:max-w-screen-xl lg:px-8'>
            <div className=' pb-4 container mx-auto sm:max-w-full sm:px-2 md:max-w-full lg:max-w-full md:px-8 lg:px-8 '>
              <div className='flex flex-col mb-6 lg:justify-between md:mb-8 shadow  overflow-x-scroll xl:overflow-x-hidden'>
                <table className='min-w-full bg-white '>
                  <thead>
                    <tr className='w-full h-16 border-linecolor  border-b py-8'>
                      <th className='text-center'></th>
                      <th className='text-gray text-left font-bold  text-base tracking-normal leading-4'>
                        Instituion Name
                      </th>
                      <th className='text-gray text-center  font-bold  text-base tracking-normal leading-4'>
                        Affiliated as
                      </th>
                      <th className='text-center text-gray  font-bold   text-base tracking-normal leading-4'>
                        View Profile
                      </th>

                      <th className=' text-gray  font-bold text-center  text-base tracking-normal leading-4'>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {myInstitutions.map((value, key) => (
                      <ContentTableRow key={value._id} institution={value} />
                    ))}
                  </tbody>
                </table>
                <div className='py-1'>
                  <div className='  bg-white  '>
                    <div className='w-full overflow-x-scroll xl:overflow-x-hidden'></div>
                    <div className='flex flex-col lg:flex-row p-4 justify-between items-start w-full'>
                      <div className='w-full lg:w-1/3 flex flex-col lg:flex-row items-start lg:items-center'>
                        {/* Space between  */}
                        <div className='flex items-center'> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ViewJoinRequests
                setShowJoinRequestsModal={setShowJoinRequestsModal}
                showJoinRequestsModal={showJoinRequestsModal}
                institutions={institutions}
                requests={joinRequestsList}
                adminId={user._id}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

const ViewJoinRequests = (props) => {
  const { loading, success, error } = useSelector(
    (state) => state?.discussiongroups,
  );
  const dispatch = useDispatch();

  const joinRequests = props.requests;

  useEffect(() => {
    if (success.message === 'Request to join Accepted') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Invitation request accepted.',
      });
      dispatch(affiliationsActions.getAllAffiliations());
      dispatch(institutionActions.getInstitutions());
      props.setShowJoinRequestsModal(false);
    }
    if (success.message === 'Request to join Rejected') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Invitation request rejected.',
      });
      props.setShowJoinRequestsModal(false);
    }

    if (error) {
      if (`${error}`.includes('NetworkError')) {
        CustomAlert({
          alertType: 'error',
          alertMessage: 'An error has occured.',
        });
      }
    }
  }, [success, error]);

  return (
    <>
      {props.showJoinRequestsModal ? (
        <>
          <ModalWrapper
            title={`${joinRequests.length > 0 ? joinRequests?.length : 0}
                      Invitations
                      `}
            closeModal={() => props.setShowJoinRequestsModal(false)}
            bodyContent={
              <>
                <div className='relative'>
                  <div className=''>
                    {joinRequests?.length > 0 ? (
                      joinRequests?.map((value, key) => (
                        <RequestCard
                          type='mentor_invite'
                          member={value}
                          loading={loading}
                          key={key}
                          error={error}
                          success={success}
                          adminId={props.adminId}
                        />
                      ))
                    ) : (
                      <NoRequests invitation />
                    )}
                  </div>
                </div>
              </>
            }
            size={'w-9/12'}
          />
        </>
      ) : null}
    </>
  );
};

const ContentTableRow = ({ institution }) => {
  const history = useHistory();
  const deleteAffiliation = true;
  return (
    <tr className='border-b border-linecolor mx-4 text-sm text-center  font-bold whitespace-normal text-black tracking-normal leading-4 cursor-pointer'>
      <td className=' w-36  whitespace-no-wrap  text-gray tracking-normal leading-4'>
        <div className='relative p-2  h-16 w-16 sm:h-16 sm:w-24 md:h-16 md:w-32  lg:h-28 lg:w-36 sm:p-2'>
          <div className='flex items-center justify-center w-16 h-16 rounded-full lg:w-24 lg:h-24  lg:flex'>
            <img
              src={
                institution?.profilePicture?.publicUrl ||
                `https://ui-avatars.com/api/?name=${institution?.institutionName}&bold=true`
              }
              alt='Event Thumb'
              className='object-cover h-full w-full rounded-full shadow-sm'
            />
          </div>
        </div>
      </td>
      <td className=' text-left '>{institution.institutionName}</td>
      <td className=' '>Mentor</td>
      <td className='  text-lg '>
        <button
          onClick={() => {
            history.push(`/institution/more/${institution._id}`);
          }}
          className='inline-flex items-center text-sm font-medium justify-center w-full h-10 px-4 tracking-wide text-darkerblue transition duration-200 rounded bg-none shadow-md md:w-auto  hover:bg-darkerblue hover:text-white border-2 border-darkerblue focus:shadow-outline focus:outline-none'
        >
          <div>
            <Visibility />
            <span className='ml-1'>View </span>
          </div>
        </button>
      </td>

      <td className='  text-lg  '>
        <button
          disabled={deleteAffiliation}
          // onClick={() => {
          //   handleRejectRequest(member);
          // }}

          className='inline-flex items-center text-sm font-medium justify-center w-full h-10 px-4 tracking-wide text-white transition duration-200 rounded bg-buttonRed shadow-md md:w-auto  hover:bg-reddark focus:shadow-outline focus:outline-none'
        >
          {!deleteAffiliation ? (
            <ButtonLoader size='default' color='primary' />
          ) : (
            <div>
              <Close />
              <span className='ml-1'>Remove </span>
            </div>
          )}
        </button>
      </td>
    </tr>
  );
};
