import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
//import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

// Icons
//import googleImg from '../assets/googleG.svg';
import logo from '../assets/new_delvLogo.png';
import facebook from '../assets/facebook.svg';

// Components
import Input from '../components/Input';
import Button from '../components/Button';
import CustomAlert from '../components/CustomAlert';
import ButtonLoader from '../components/ButtonLoader';
import VisibilityHandler from '../components/VisibilityHandler';

// Actions
import * as userActions from '../redux/actions/users';
import * as discussionGroupsActions from '../redux/actions/discussiongroups';

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { unconfirmedUserID, groupID } = useParams();
  const query = new URLSearchParams(props.location.search);
  const articleID = query.get('article');
  const userState = useSelector((state) => state.user);
  const { loading, success, error, errorMessage, user } = userState;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newUser, setNewUser] = useState(false);

  let form = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      userActions.logIn({
        email,
        password,
      }),
    );
  };

  const responseGoogle = (response) => {
    dispatch(userActions.googleLogIn({ googleUserToken: response.credential }));
  };

  const responseFacebook = (response) => {
    // console.log(response);
  };

  useEffect(() => {
    if (unconfirmedUserID) {
      setNewUser(true);
      dispatch(userActions.confirmUser(unconfirmedUserID));
    }
  }, []);

  useEffect(() => {
    if (userState?.user_confirm?.status) {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Email verified',
      });
    }
  }, [userState]);

  useEffect(() => {
    if (user) {
      if (groupID && success?.message === 'User logged in') {
        dispatch(
          discussionGroupsActions.updateGroupMembers({
            groupId: groupID,
            groupMembers: user?._id,
          }),
        );
      }
    }
  }, [success, user]);

  useEffect(() => {
    if (user) {
      if (user.usercategory[0] === 'institution')
        history.push('/institution/feeds');
      if (
        ['student', 'graduate', 'employee', 'mentor', 'trainer'].includes(
          user.usercategory[user.usercategory.length - 1],
        )
      )
        history.push('/feeds');
    }
  }, []);
  useEffect(() => {
    if (success?.message === 'User logged in') {
      if (newUser) {
        history.push('/onboardingInterests');
      }
      if (articleID) {
        history.push(`/article/read/${articleID}`);
      } else {
        history.push('/feeds');
      }

      if (groupID) {
        history.push(`/ChatRoom/${groupID}`);
      }
    }
  }, [success, userState]);

  useEffect(() => {
    if (`${errorMessage}`.includes('incorrect'))
      CustomAlert({
        alertType: 'error',
        alertMessage: errorMessage,
      });
  }, [error]);

  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    /* global google */

    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_CLIENT_ID,
      callback: responseGoogle,
    });

    google.accounts.id.renderButton(
      document.getElementById('renderGoogleButton'),
      { theme: 'outline', size: 'large', width: 500, text: 'continue_with' },
    );
  }, []);

  return (
    <div>
      <link
        rel='stylesheet'
        href='https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css'
        integrity='sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh'
      />
      <section className='bg-white {-- h-screen --}'>
        <div className='mx-auto flex lg:justify-center h-full flex-col lg:flex-row'>
          <div className='w-full lg:w-2/5 px-2 py-20 sm:py-40 sm:px-12 flex flex-col justify-center items-center bg-darkerblue relative'>
            <div className='absolute left-0 top-0 pt-4 px-4 sm:px-12 sm:pt-8'>
              <div className=''>
                <Link to='/'>
                  <img
                    src={logo}
                    className='object-scale-down w-28 h-8 sm:w-28 sm:h-8'
                    alt='delv logo'
                  />
                </Link>
              </div>
            </div>
            <div className='absolute right-0 top-0 '>
              <svg width='208' height='208' xmlns='http://www.w3.org/2000/svg'>
                <g id='SemiCircle'>
                  <title>SemiCircle</title>
                  <ellipse
                    stroke='null'
                    fill='#000000'
                    strokeWidth='12'
                    cx='145'
                    cy='63'
                    id='svg_6'
                    rx='144'
                    ry='144'
                    opacity='0.1'
                  />
                </g>
              </svg>
            </div>
            <div className='flex relative z-30 flex-col justify-center px-4 md:pr-12 text-left text-white'>
              <h3 className='text-4xl leading-tight font-bold font-display'>
                Delv-in.
              </h3>
              <h5 className='text-3xl pt-4 leading-tight font-semibold font-display'>
                Your lifelong learning journey starts here
              </h5>
              <span className='text-lg pt-8 leading-relaxed  font-normal font-display'>
                Find practical career guidance, Learn new skills, Join learning
                communities, explore, engage, collaborate, become a life long
                learner
              </span>
            </div>
            <div className='absolute left-0 bottom-0'>
              <svg width='208' height='256' xmlns='http://www.w3.org/2000/svg'>
                <g id='squarePattern'>
                  <title>SquarePatten</title>
                  <rect
                    stroke='#000000'
                    fill='none'
                    strokeWidth='9'
                    x='-188.00001'
                    y='7.99999'
                    width='389.00001'
                    height='330.42585'
                    id='svg_7'
                    opacity='0.1'
                  />
                  <rect
                    stroke='null'
                    fill='#000000'
                    strokeWidth='21'
                    x='-160.50001'
                    y='27.84689'
                    width='343.00001'
                    height='294.30622'
                    id='svg_8'
                    opacity='0.1'
                  />
                </g>
              </svg>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            ref={form}
            className='w-full lg:w-3/5 flex justify-center bg-white '
          >
            <div className='w-full sm:w-4/6 md:w-3/6 lg:w-2/3 text-charcoal  flex flex-col justify-center px-2 sm:px-0 py-16'>
              <div className='px-2 sm:px-6'>
                <h3 className='text-2xl sm:text-3xl md:text-2xl font-bold leading-relaxed text-center font-display'>
                  Sign In
                </h3>
              </div>
              <div className='mt-8 w-full px-2 sm:px-6'>
                <div className='flex flex-col mt-5'>
                  <div
                    id='renderGoogleButton'
                    style={{ width: '100%', marginLeft: '10%' }}
                  ></div>

                  {/*<GoogleLogin
                    render={(renderProps) => (
                      <button
                        onClick={showGooglePop}
                        disabled={renderProps.disabled}
                        className='bg-white hover:border-main py-2 px-4 rounded inline-flex items-center text-charcoal w-full border border-linecolor mb-3 p-1 opacity-100 font-semibold leading-4 text-base'
                      >
                        <img
                          src={googleImg}
                          alt='google icon'
                          className='fill-current w-10 h-10 mr-6'
                        />
                        <span className='font-display'>
                          Continue with Google
                        </span>
                      </button>
                    )}
                    buttonText=''
                    
                    />*/}
                </div>
                <div className='flex flex-col mt-2'>
                  <FacebookLogin
                    appId='' // APP ID NOT CREATED YET
                    fields='name,email,picture'
                    icon={facebook}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button
                        style={{
                          width: '400px',
                          height: '40px',
                          marginLeft: '10%',
                        }}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className='bg-white hover:border-main py-2 px-4 rounded inline-flex items-center text-charcoal w-full border border-linecolor mb-3 p-1 opacity-100 font-semibold leading-4 text-base'
                      >
                        <img
                          src={facebook}
                          style={{ width: '8%' }}
                          className='fill-current mr-6'
                          alt='Facebook icon'
                        />
                        <span className='font-display'>
                          Continue with Facebook
                        </span>
                      </button>
                    )}
                  />
                </div>
                <div
                  className='flex flex-col mt-5'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <div className='flex justify-between flex-row mb-5'>
                    <span className='border-linecolor border-b-2 mr-1 m-auto  w-full'></span>
                    <span className='font-semibold text-headingtext mx-2 font-display'>
                      OR
                    </span>
                    <span className='border-linecolor border-b-2 ml-1 m-auto  w-full'></span>
                  </div>
                </div>
                <div
                  className='flex flex-col mt-2'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <Input
                    placeholder='Email'
                    type='email'
                    margin='mb-2'
                    width='w-full'
                    submitInput={setEmail}
                    id='email'
                    value={email}
                  />
                </div>
                <div
                  className='flex flex-col mt-2'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <div className='relative mb-4'>
                    <VisibilityHandler
                      visibility={visibility}
                      setVisibility={setVisibility}
                    />
                    <input
                      id='password'
                      type={visibility ? 'text' : 'password'}
                      placeholder='Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className='text-sm focus:outline-noone sm:text-lg text-charcoal relative h-11 border rounded border-linecolor focus:border-main bg-lightgraybg opacity-1 text-gray w-full py-2 pr-2 pl-12'
                    />
                  </div>

                  {/* <Input
                    placeholder='Password'
                    type='password'
                    margin='mb-4'
                    width='w-full'
                    submitInput={setPassword}
                    id='password'
                    value={password}
                  /> */}
                </div>
                <div
                  className=' w-full flex justify-between px-2 sm:px-6 mb-4'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  <a
                    className='text-xs text-textheading font-display'
                    href='/comingsoon'
                  >
                    Forgot Password?
                  </a>
                </div>
              </div>

              <div
                className='px-2 sm:px-6'
                style={{ marginLeft: '10%', width: '440px' }}
              >
                <Button
                  type='submit'
                  disabled={loading}
                  content={loading ? <ButtonLoader /> : 'Sign In'}
                  margin='mb-6'
                  backgroundColor='bg-darkerblue'
                />
                <p
                  className='mt-6 text-xs font-display'
                  style={{ marginLeft: '10%', width: '400px' }}
                >
                  Don’t Have An Account?{' '}
                  <Link
                    to='/signup'
                    className='font-semibold underline text-main font-display'
                  >
                    Sign Up
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Login;
