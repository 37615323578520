import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Comment, Send, Share, ThumbUp } from '@material-ui/icons';

// components
import Navbar from '../components/Navbar';
import Button from '../components/Button';
import FollowCard from '../components/FollowCard';
import InstitutionTabs from '../components/InstitutionTab';
import IconsRole from '../components/IconsRole';
import Video from '../components/Video';
import Loading from '../components/Loading';

// SVG
import pic from '../assets/upskillingpic.svg';
import institutionVideo from '../assets/institution.svg';
import institutionLogo from '../assets/institutionLogo.svg';
import institutionLogo2 from '../assets/institutionLogo2.svg';
import learners from '../assets/institution3.svg';
import Pic from '../assets/mentor.svg';

import * as userActions from '../redux/actions/users';
import * as institutionActions from '../redux/actions/institution';

const PostsCard = (props) => {
  return (
    <div className='bg-white box-border border border-linecolor rounded-lg w-96'>
      <div className='flex py-8'>
        <img src={props.logo} alt='institution logo' className=' px-5' />
        <div>
          <h1 className='font-semibold font-body text-lg text-black'>
            {props.institute}
          </h1>
          <p className='font-normal font-body text-sm text-textparagraph'>
            {props.followers}
          </p>
          <p className='font-normal font-body text-xs text-textparagraph'>
            {props.hours}
          </p>
        </div>
      </div>
      <p className='px-5 font-normal font-body text-xs text-black w-80'>
        {props.content} <Link className='text-main'>see more</Link>
      </p>
      <img
        src={props.courseImage}
        alt='course logo'
        className='object-cover h-44 w-80 px-5 pt-8 '
      />
      <div className='flex border border-b border-lightgraybg mx-auto w-11/12 justify-center my-2'></div>
      <div className='flex flex-row  w-full mt-2 text-sm'>
        <IconsRole
          icon={<ThumbUp fontSize='small' />}
          role='Like'
          // count={content.likes?.length || 0}
        />
        <IconsRole
          icon={<Comment fontSize='small' />}
          role='Comment'
          // count={contentComments?.length || 0}
          // Action={commentPostClick}
        />
        <IconsRole
          icon={<Send fontSize='small' />}
          role='Send'
          // Action={sendPostClick}
        />
        <IconsRole
          icon={<Share fontSize='small' />}
          role='Share'
          // Action={sharePostClick}
        />
      </div>
    </div>
  );
};
const InstitutionHome = () => {
  const dispatch = useDispatch();
  dispatch(userActions.getUsers());
  const users = useSelector((state) => state?.user?.data);

  const { institutionID } = useParams();

  const user = useSelector((state) => state?.user?.user);
  const institutions = useSelector((state) => state.institution.data);
  const institution = useSelector((state) => state.institution.institution);
  const [loading, setLoading] = useState(true);
  const institutionss = [];
  institutions?.map((value) => institutionss.push(value));
  const institute2 = institutionss.find(
    (inst) => inst.admins[0] === `${user._id}`,
  );

  useEffect(() => {
    if (institutions.length === 0) {
      dispatch(institutionActions.getInstitutions({}));
      setLoading(true);
    } else {
      setLoading(false);
    }

    // if (institute2) {
    //   console.log('You are an admin');
    //   console.log(institute2);
    // }

    if (institution.length === 0) {
      dispatch(institutionActions.getSpecificInstitution(institutionID));
    }
  }, [dispatch, institute2, institutions, institution, institutionID]);
  return (
    <div className='flex flex-col '>
      <Navbar />
      <>
        {loading ? (
          <div className='bg-lightgraybg h-screen w-full fixed'>
            <Loading />
          </div>
        ) : (
          <div className='bg-lightgraybg '>
            <div className='flex flex-col pt-12 pb-12 container mx-auto '>
              <div
                className='flex justify-between border-2 border-white   bg-white shadow-md px-8 h-72 max-w-screen-xl'
                style={{ borderRadius: '6px 6px 0px 0px' }}
              >
                <div className=''>
                  <img
                    src={institutionLogo}
                    alt='video posta'
                    className='object-contain  overflow-hidden'
                  />
                </div>
                <div className='flex-1  px-8 py-16 '>
                  <h1 className='font-bold font-body text-5xl text-charcoal'>
                    {/* Makerere University */}
                    {institute2?.institutionName}
                  </h1>
                  <p className='pt-3.5 font-normal font-body text-2xl text-charcoal'>
                    {/* One of the oldest and most prestigious Universities in
                    Africa. A top research university in Africa and beyond. */}
                    {institute2?.bio}
                  </p>
                </div>
                <div>
                  <Button
                    content='Follow'
                    backgroundColor='bg-main'
                    contentColor='text-white'
                    borderRadius='rounded-md'
                    padding='px-20 py-4'
                    borderColor='border-white'
                    margin='my-24'
                    width='w-56'
                    height='h-4'
                    boxShadow='shadow-md3'
                  />
                </div>
              </div>
              <div className='bg-white flex-1 mt-0.5 max-w-screen-xl '>
                <div className='flex justify-between '>
                  <div className='flex flex-col border-r border-linecolor'>
                    <div className='sticky top-20 z-40'>
                      <InstitutionTabs homeActive={true} />
                    </div>
                    <div className='pt-14 pr-6 pl-12'>
                      <h1 className='text-lg font-body font-semibold text-charcoal'>
                        About
                      </h1>
                      <p className='pt-4 font-normal font-body text-base text-charcoal'>
                        Makerere University is one of the oldest and most
                        prestigious Universities in Africa. Our vision is to be
                        the leading institution for academic excellence and
                        innovations in Africa. We strive towards this goal by
                        providing innovative teaching, learning, research and
                        services that are responsive to Uganda&rsquo;s as well
                        as and Global needs
                      </p>
                      <p className='font-normal font-body text-base text-main pt-8 pb-10 text-center underline'>
                        <Link to='/institutionabout'>Show all details</Link>
                      </p>
                      <div className='border-b border-linecolor'></div>
                    </div>
                    <div className='pt-10 pr-6 pl-12'>
                      <h1 className='text-lg font-body font-semibold text-charcoal'>
                        Recently Posted Videos
                      </h1>
                      <div className='flex justify-between pt-4 '>
                        <Video
                          url={institutionVideo}
                          className='w-1/3 h-96 pr-3'
                        />
                        <p className='font-normal font-body text-sm text-charcoal w-2/3 pl-6 pt-2'>
                          Makerere University is one of the oldest and most
                          prestigious Universities in Africa. Our vision is to
                          be the leading institution for academic excellence and
                          innovations in Africa. We strive towards this goal by
                          providing innovative teaching, learning, research and
                          services that are responsive to Uganda&rsquo;s as well
                          as and Global needs
                        </p>
                      </div>
                      <p className='font-normal font-body text-base text-main pt-8 pb-10 text-center underline'>
                        <Link to='/library'>Show all details</Link>
                      </p>
                      <div className='border-b border-linecolor'></div>
                    </div>
                    <div className='pt-10 pr-6 pl-12 '>
                      <h1 className='text-lg font-body font-semibold text-charcoal'>
                        Page Posts
                      </h1>
                      <div>
                        <div className='flex justify-between w-full'>
                          <PostsCard
                            logo={institutionLogo2}
                            institute={`${institute2?.institutionName}`}
                            // institute='Makerere University'
                            followers='12,000 followers'
                            hours='22hrs ago'
                            content={`A team of three Makerere University Students and a Coach are part of Team Uganda that travelled to...`}
                            courseImage={learners}
                            className='w-1/2'
                          />
                          <PostsCard
                            logo={institutionLogo2}
                            // institute='Makerere University'
                            institute={`${institute2?.institutionName}`}
                            followers='12,000 followers'
                            hours='22hrs ago'
                            content={`A team of three Makerere University Students and a Coach are part of Team Uganda that travelled to...`}
                            courseImage={learners}
                            className='w-1/2'
                          />
                        </div>
                      </div>
                      <p className='font-normal font-body text-base text-main pt-16 pb-10 text-center underline'>
                        <Link to='/institution/feeds'>Show all details</Link>
                      </p>
                      <div className='border-b border-linecolor mb-96'></div>
                    </div>
                  </div>
                  <div>
                    <sidebar className='lg:col-span-3 min-w-full  lg:flex'>
                      <div className='flex flex-col pb-20 h-full overflow-visible bg-white'>
                        <div className='bg-white w-full  mb-10 '>
                          <div className=''>
                            <h1 className='p-4  font-body font-semibold text-sm  '>
                              Follow Mentors / Trainers / Counsellors
                            </h1>
                            <div className='w-72 border mx-2 border-linecolor mb-5'></div>
                            <div>
                              {users?.map((value) =>
                                value.usercategory[0] === 'mentor' ? (
                                  <FollowCard
                                    key={value._id}
                                    pic={value.profilePicture?.publicUrl}
                                    name={`${value.firstname} ${value.lastname}`}
                                    content={value.usercategory}
                                    button='Follow'
                                  />
                                ) : null,
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='bg-white w-full   mb-10'>
                          <div className=''>
                            <h1 className='p-4 font-body font-semibold text-sm  text-center'>
                              Follow Groups / Communities
                            </h1>
                            <div className='w-72 border mx-2 border-linecolor mb-5'></div>
                            <div>
                              <FollowCard
                                pic={Pic}
                                name='Coding girls'
                                content='Python'
                                button='Join'
                              />
                              <FollowCard
                                pic={Pic}
                                name='Business Startup'
                                // content='LLM Antoney'
                                button='Join'
                              />
                              <FollowCard
                                pic={Pic}
                                name='Dev Ops'
                                // content='LLM Antoney'
                                button='Join'
                              />
                            </div>
                          </div>
                        </div>

                        <div className='w-full  '>
                          <div className='px-5 w-full   '>
                            <div
                              className='w-full mb-20 pb-16  '
                              style={{
                                background: ` rgba(0,0,0, 0.7) url(${pic})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundBlendMode: 'darken',
                                borderRadius: '0px 85px 0px 0px',
                              }}
                            >
                              <h1 className='text-white pt-20 text-center px-10 text-2xl font-semibold font-body px-5  mb-10 '>
                                Career Guidance After 12th science session
                              </h1>
                              <Button
                                content='Join Session'
                                backgroundColor='bg-transpernt'
                                contentColor='text-white'
                                borderRadius='rounded-xl'
                                padding='px-3'
                                width='w-full'
                                margin='mx-12'

                                // onClick={() => history.push('/signup')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </sidebar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default InstitutionHome;
