import * as chatTypes from '../types/chat';

const initialState = {
  data: [],
  chatDiscussionData: [],
  chatDiscussionCount: null,
  chatDiscussionTitle: null,
  chatDiscussionChatID: null,
  chatAdmin: null,
  chatRequestMessage: null,
  groupChatRequest: [],
  groupChatRequestCount: 0,
  isChatMember: 3,
  count: 0,
  loading: false,
  groupChatLoading: false,
  error: false,
  errorMessage: null,
  success: {
    status: false,
    message: 'All set!',
  },
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case chatTypes.ALL_CHATS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
        groupChatLoading: true,
        error: false,
        errorMessage: null,
      };
    case chatTypes.CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: null,
        success: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case chatTypes.CHECK_MEMBER_IN_CHAT:
      return {
        ...state,
        isChatMember: action.payload.count,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case chatTypes.GET_GROUP_CHAT_REQUESTS:
      return {
        ...state,
        groupChatRequestCount: action.payload.count,
        groupChatRequest: action.payload.data,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case chatTypes.SUBMIT_CHAT_REQUEST_STATUS:
      console.log(action.payload);
      return {
        ...state,
        chatRequestMessage: action.payload,
        loading: false,
        error: false,
        errorMessage: null,
      };
    case chatTypes.ALL_CHAT_DISCUSSIONS:
      return {
        ...state,
        chatDiscussionData: action.payload.data,
        chatDiscussionCount: action.payload.count,
        chatDiscussionTitle: action.payload.chat[0].title,
        chatDiscussionChatID: action.payload.chat[0]._id,
        chatAdmin: action.payload.chat[0].admin,
        loading: false,
        error: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default chatReducer;
