import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactStars from 'react-rating-stars-component';

import * as rateActions from '../redux/actions/rate';
import * as commentsActions from '../redux/actions/comments';
import ModalWrapper from './ModalWrapper';
import ButtonLoader from './ButtonLoader';

export default function FeedbackModal({
  content,
  showFeedbackModal,
  setShowFeedbackModal,
}) {
  const dispatch = useDispatch();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const { loading } = useSelector((state) => state.comments);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (rating !== 0) {
      dispatch(
        rateActions.rateContent({
          idOfRated: content?._id,
          ratingLevel: rating,
        }),
      );
    }
    if (review !== '') {
      dispatch(
        commentsActions.addComment({
          commentAbout: 'content',
          commentText: review,
          idOfCommentedAbout: content?._id,
        }),
      );
    }
    setShowFeedbackModal(false);
    setReview('');
  };

  return (
    <>
      {showFeedbackModal ? (
        <>
          <form onSubmit={handleSubmit}>
            <ModalWrapper
              title={'Feedback'}
              closeModal={() => setShowFeedbackModal(false)}
              bodyContent={
                <>
                  {' '}
                  <div className='relative  flex-auto'>
                    <div className='mt-3 pt-0 w-full grid grid-cols-1 sm:grid-cols-2 gap-4 '>
                      <div className=''>
                        <img
                          src={content?.thumbnailFile?.publicUrl}
                          alt={content?.title}
                          className='object-scale-down  w-full h-44 rounded shadow-md sm:h-52'
                        />
                      </div>
                      <span className='font-semibold text-charcoal text-xl sm:text-lg w-full mb-4 ml-4 flex justify-start'>
                        {content?.title}
                      </span>
                    </div>
                    <span className='font-semibold text-charcoal text-lg w-full mt-3 flex justify-start'>
                      Your rating
                    </span>
                    <div className='flex flex-row text-graybg pb-2'>
                      <ReactStars
                        count={5}
                        onChange={(new_rating) => {
                          setRating(new_rating);
                        }}
                        size={50}
                        isHalf={true}
                        emptyIcon={<i className='far fa-star'></i>}
                        halfIcon={<i className='fa fa-star-half-alt'></i>}
                        fullIcon={<i className='fa fa-star'></i>}
                        activeColor='#ffd700'
                      />
                    </div>
                    <div className='mt-4'>
                      <textarea
                        rows='6'
                        value={review}
                        onChange={(e) => setReview(e.target.value)}
                        placeholder={'Type a review...'}
                        className=' px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-noone focus:ring focus:ring-offset-darkerblue focus:ring-opacity-50 w-full'
                      />
                    </div>
                  </div>
                </>
              }
              footer={true}
              footerContent={
                <>
                  <div className='justify-start'>
                    <span className='font-normal text-charcoal text-sm mb-4 flex justify-start   '>
                      By clicking submit, I agree that my feedback may be viewed
                      by the Delv community in compliance with the Delv Terms of
                      use and my profile privacy settings.
                    </span>
                  </div>
                  <div className='flex w-full justify-center  lg:justify-end'>
                    <button
                      type='submit'
                      className='cursor-pointer w-full inline-flex items-center justify-center h-14 text-xl px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-darkerblue hover:bg-navyblue focus:shadow-outline focus:outline-noone'
                    >
                      {loading ? <ButtonLoader /> : <> Submit Review </>}
                    </button>
                  </div>
                </>
              }
            />
          </form>
        </>
      ) : null}
    </>
  );
}
