import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SendOutlined, PictureOutlined } from '@ant-design/icons';

import * as chatActions from '../redux/actions/chat';

const MessageForm = (props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const user = useSelector((state) => state.user.user);

  const { chatDiscussionChatID } = useSelector((state) => state?.chat);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      chatActions.createNewChatDiscussion({
        groupId: props?.groupID,
        chatId: chatDiscussionChatID,
        userId: user?._id,
        content: value,
        attachment: {
          fileName: 'filename',
          publicUrl: 'publicUrl',
        },
      }),
    );
    setValue('');
  };

  const handleUpload = (event) => {
    //sendMessage(creds, chatId, { files: event.target.files, text: '' });
  };

  return (
    <form className='message-form' onSubmit={handleSubmit}>
      <input
        className='message-input'
        placeholder='Send a message...'
        value={value}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
      <label htmlFor='upload-button'>
        <span className='image-button'>
          <PictureOutlined className='picture-icon' />
        </span>
      </label>
      <input
        type='file'
        multiple={false}
        id='upload-button'
        style={{ display: 'none' }}
        onChange={handleUpload.bind(this)}
      />
      <button
        type='submit'
        className='send-button'
        onClick={(e) => handleSubmit(e)}
      >
        <SendOutlined className='send-icon' />
      </button>
    </form>
  );
};

export default MessageForm;
