import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Dashboard, People } from '@material-ui/icons';

// components
// import Sidebar from '../components/Sidebar';
import Sharepost from '../components/Sharepost';
import FollowCard from '../components/FollowCard';
import ModalWrapper from '../components/ModalWrapper';

// import Recommended from '../components/Recommended';

import Loading from '../components/Loading';
import { UploadNewContent } from '../components/UploadContent';

//SVG
// import courseImage from '../assets/courseimage.svg';

import * as homeActions from '../redux/actions/home';
import * as userActions from '../redux/actions/users';
import * as institutionActions from '../redux/actions/institution';
import * as contentActions from '../redux/actions/content';
import * as sessionsActions from '../redux/actions/sessions';
import * as affiliationsActions from '../redux/actions/affiliations';
import * as invitationActions from '../redux/actions/invitation';

import Sidebar from '../components/Sidebar';
import PopularCard from '../components/PopularCard';
import Nav from '../components/NavBarR';
import FollowerSideMenu from '../components/FollowSideMenu';
import UserInfoSideMenu from '../components/UserInfoSideMenu';
import SendPostModal from '../components/SendPostModal';
import CommentPostModal from '../components/CommentPostModal';
import SharePostModal from '../components/SharePostModal';
import UploadSessionModal from '../components/UploadSession';
import CustomAlert from '../components/CustomAlert';
import { SurveyModal } from '../components/ContactForms';
import { useHistory } from 'react-router-dom';

const InstitutionFeeds = () => {
  const dispatch = useDispatch();
  const feeds = useSelector((state) => state.home.feeds);
  const user = useSelector((state) => state?.user?.user);
  const users = useSelector((state) => state?.user?.data);
  const loading = useSelector((state) => state?.home.loading);
  const { success, errorMessage } = useSelector((state) => state?.institution);
  const institutions = useSelector((state) => state.institution.data);
  const myContent = useSelector((state) => state.content.myContent);
  const mySessions = useSelector((state) => state.sessions.mySessions);
  const [showUploadPhoto, setShowUploadPhoto] = useState(false);
  const [showShareArticle, setShowShareArticle] = useState(false);
  const [showCommentPost, setShowCommentPost] = useState(false);
  const [showSendPost, setShowSendPost] = useState(false);
  const [showSharePost, setShowSharePost] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const [dataFetchLoading, setDataFetchLoading] = useState(false);
  const [showFollower, setShowFollowers] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showCreateSession, setShowCreateSession] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const affiliations = useSelector((state) => state?.affiliations?.data);
  const [showSendInvitationModal, setShowSendInvitationModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    dispatch(userActions.getUsers());
    if (!users?.length > 0) {
      setDataFetchLoading(true);
      dispatch(institutionActions.getInstitutions({}));
    } else {
      setDataFetchLoading(false);
    }
  }, []);

  useEffect(() => {
    dispatch(homeActions.getFeeds({ limit: 30 }));
    dispatch(sessionsActions.getMySessions());
    dispatch(contentActions.getMyContent());
    dispatch(institutionActions.getInstitutions({}));
    dispatch(userActions.getUsers());
    dispatch(affiliationsActions.getAllAffiliations());
  }, [user]);

  useEffect(() => {
    dispatch(homeActions.getFeeds({ limit: 30 }));
    dispatch(userActions.getUsers());
  }, [myContent, mySessions]);

  const mentors = users?.filter(
    (mentor) => mentor.usercategory[0] === 'mentor',
  );

  const institute = institutions.find(
    (inst) => inst.admins[0] === `${user._id}`,
  );
  const institutionID = institute?._id;

  useEffect(() => {
    if (success.message === 'Survey sent successfully') {
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Survey dispatched successfully',
      });
    } else if (errorMessage === 'Failed to submit survey') {
      CustomAlert({
        alertType: 'error',
        alertMessage: 'Failed to submit survey, try again.',
      });
    }
  }, [success, errorMessage]);

  const affiliatedMentors = [];
  const affiliatedLearners = [];
  affiliations
    ?.filter(
      (item) =>
        item.institution === institutionID && item.affiliateType === 'mentor',
    )
    .map((item) => affiliatedMentors.push(item.affiliate));

  affiliations
    ?.filter(
      (item) =>
        item.institution === institutionID && item.affiliateType === 'learner',
    )
    .map((item) => affiliatedLearners.push(item.affiliate));

  const mentorsList = users?.filter(
    (value) => value?.usercategory[0] === 'mentor',
  );

  const learnersList = users?.filter(
    (value) =>
      value?.usercategory[0] !== 'mentor' &&
      value?.usercategory[0] !== 'institution',
  );

  const myMentors = mentorsList?.filter((mentor) =>
    affiliatedMentors.includes(mentor._id),
  );

  const myLearners = learnersList?.filter((learner) =>
    affiliatedLearners.includes(learner._id),
  );

  return (
    <div>
      <SendInvitationModal
        setShowSendInvitationModal={setShowSendInvitationModal}
        showSendInvitationModal={showSendInvitationModal}
      />
      <Nav
        feedsActive={true}
        userType='institution'
        institutions={institutions}
      />
      {loading && dataFetchLoading ? (
        <div className='bg-lightgraybg h-screen w-full fixed'>
          <Loading />
        </div>
      ) : (
        <div className='bg-lightgraybg'>
          <div className=' text-center md:mb-0  w-full fixed  mb-4 lg:hidden'>
            <div className='inline-flex items-center justify-between w-full h-12 px-6 font-medium tracking-wide text-headingtext transition   bg-white '>
              <span
                className='hover:text-darkerblue focus:text-darkerblue '
                onClick={() => {
                  setShowUserInfo(!showUserInfo);
                  setShowFollowers(false);
                }}
              >
                <Dashboard />
                <span className='ml-2'> {' User Info '} </span>
              </span>
              <span
                className='hover:text-darkerblue focus:text-darkerblue '
                onClick={() => {
                  setShowFollowers(!showFollower);
                  setShowUserInfo(false);
                }}
              >
                <span className='mr-2'>{'Follow'} </span>
                <People />
              </span>
            </div>
          </div>
          <FollowerSideMenu
            users={users}
            showFollower={showFollower}
            setShowFollowers={setShowFollowers}
          />
          <UserInfoSideMenu
            showUserInfo={showUserInfo}
            setShowUserInfo={setShowUserInfo}
            userType={'institution'}
            myContent={myContent}
            mySessions={mySessions}
          />
          <div className='px-2 mx-auto sm:w-xl md:w-full lg:max-w-screen-2xl md:px-0  '>
            <div className='px-2  container mx-auto sm:max-w-full md:max-w-screen-2xl lg:max-w-full md:px-4  '>
              <div className='w-full grid  grid-cols-1 lg:grid-cols-12 sm:gap-5  lg:px-10'>
                <aside className='h-screen sticky lg:top-32 lg:col-span-2 hidden lg:flex'>
                  <Sidebar
                    userType='institution'
                    institutions={institutions}
                    user={user}
                    setShowSurveyModal={setShowSurveyModal}
                    setShowSendInvitationModal={setShowSendInvitationModal}
                    showSendInvitationModal={showSendInvitationModal}
                  />
                </aside>

                <main className='lg:col-span-7 flex flex-col mt-16 lg:mt-4'>
                  <Sharepost
                    institutions={institutions}
                    user={user}
                    createSessionClick={() => setShowCreateSession(true)}
                  />
                  <div className='pt-7 mb-20'>
                    {feeds?.map((value) => (
                      <PopularCard
                        users={users}
                        institutions={institutions}
                        key={value._id}
                        content={value}
                        sendPostClick={() => {
                          setSelectedPost(value);
                          setShowSendPost(true);
                        }}
                        commentPostClick={() => {
                          setSelectedPost(value);
                          setShowCommentPost(true);
                        }}
                        sharePostClick={() => {
                          setSelectedPost(value);
                          setShowSharePost(true);
                        }}
                      />
                    ))}
                    {/* <Recommended
                      heading='Recommended Courses'
                      courseImage={courseImage}
                      sendPostClick={() => setShowSendPost(true)}
                      commentPostClick={() => setShowCommentPost(true)}
                      sharePostClick={() => setShowSharePost(true)}
                    /> */}
                  </div>
                  <UploadNewContent
                    contentType={'Photo'}
                    showUploadContent={showUploadPhoto}
                    setShowUploadContent={setShowUploadPhoto}
                  />
                  <UploadNewContent
                    contentType={'Article'}
                    showUploadContent={showShareArticle}
                    setShowUploadContent={setShowShareArticle}
                  />
                  <SendPostModal
                    setShowSendPost={setShowSendPost}
                    showSendPost={showSendPost}
                    postSnippet={selectedPost}
                  />
                  <CommentPostModal
                    setShowCommentPost={setShowCommentPost}
                    showCommentPost={showCommentPost}
                    postSnippet={selectedPost}
                  />
                  <SharePostModal
                    setShowSharePost={setShowSharePost}
                    showSharePost={showSharePost}
                    postSnippet={selectedPost}
                  />
                  <UploadSessionModal
                    institution
                    mentors={myMentors}
                    setShowCreateEventModal={setShowCreateSession}
                    showCreateEventModal={showCreateSession}
                    userId={institutionID}
                  />
                </main>
                <aside className='h-full sticky lg:col-span-3 top-32 mt-4 hidden lg:flex '>
                  <div className='h-full lg:mb-20'>
                    <div className='flex flex-col h-full   '>
                      <div className='bg-white w-full  mb-10'>
                        <div className=''>
                          <h1 className='p-4  font-body font-semibold text-sm border-b border-linecolor mb-5 '>
                            Follow Mentors / Trainers / Counsellors
                          </h1>
                          <div>
                            {mentors?.slice(0, 4)?.map((value) => (
                              <FollowCard
                                key={value?._id}
                                pic={value?.profilePicture?.publicUrl}
                                name={`${value?.firstname} ${value?.lastname}`}
                                content={
                                  value?.usercategory[
                                    value?.usercategory?.length - 1
                                  ]
                                }
                                button='More'
                                userId={value?._id}
                                userType={'mentors'}
                              />
                            ))}
                          </div>
                          <div className='flex justify-center py-3'>
                            <span
                              className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
                              onClick={() => history.push('/mentors')}
                            >
                              View all
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='bg-white w-full mb-10'>
                        <div className=''>
                          <h1 className='p-4  font-body font-semibold text-sm border-b border-linecolor mb-5 '>
                            Follow Institutions
                          </h1>
                          <div>
                            {institutions?.slice(0, 4).map((value) => (
                              <FollowCard
                                key={value?._id}
                                pic={value?.profilePicture?.publicUrl}
                                name={`${value?.institutionName}`}
                                content={value?.usercategory}
                                button='More'
                                userId={value?._id}
                                userType={'institution'}
                              />
                            ))}
                          </div>
                          <div className='flex justify-center py-3'>
                            <span
                              className='font-semibold text-headingtext hover:text-navyblue cursor-pointer text-sm'
                              onClick={() => history.push('/institutions')}
                            >
                              View all
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      )}
      <SurveyModal
        userType={'Mentor'}
        showSurveyModal={showSurveyModal}
        setShowSurveyModal={setShowSurveyModal}
        affiliations={{
          learners: myLearners,
          mentors: myMentors,
        }}
      />
    </div>
  );
};

const SendInvitationModal = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const user = useSelector((state) => state.user.user);
  const invitation = useSelector((state) => state?.invitation);

  const handleInvitation = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      sentBy: user._id,
      sentUser: `${user.firstname} ${user.lastname}`,
      category: 'general',
    };

    dispatch(invitationActions.createInvitation(payload));
  };

  useEffect(() => {
    if (invitation?.success?.status) {
      setEmail('');
      CustomAlert({
        alertType: 'success',
        alertMessage: 'Email Invitation has been sent',
      });
    }
  }, [invitation]);

  return (
    <>
      {props.showSendInvitationModal ? (
        <>
          <ModalWrapper
            title={'Invite a friend'}
            closeModal={() => props.setShowSendInvitationModal(false)}
            bodyContent={
              <div className='relative'>
                <form onSubmit={handleInvitation}>
                  <label className='font-semibold text-textparagraph text-sm'>
                    Add Email
                  </label>
                  <div className='grid grid-cols-6'>
                    <div className='col-span-4'>
                      <input
                        required
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='px-3 py-3 text-charcoal relative bg-lightgraybg rounded text-sm border border-textparagraph outline-none focus:outline-none focus:ring focus:ring-offset-charcoal focus:ring-opacity-50 w-full'
                      />
                    </div>
                    <div className='col-span-2'>
                      <button
                        style={{ float: 'right' }}
                        className='w-40 h-12 falsefocus:shadow-outline  bg-darkerblue text-white text-xl px-2 py-2 rounded-md shadow hover:bg-navyblue outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                        type='submit'
                      >
                        Invite
                      </button>
                    </div>
                  </div>
                  <br />
                </form>
                {invitation?.data?._id ? (
                  <>
                    <label className='font-light text-textparagraph text-sm'>
                      <b>Copy invitation link :</b>
                      <br /> {process.env.REACT_APP_HOST}/invitation/
                      {invitation.data._id}
                    </label>
                    <div className='col-span-12'>
                      <br />
                    </div>
                  </>
                ) : null}
              </div>
            }
          />
        </>
      ) : null}
    </>
  );
};

export default InstitutionFeeds;
