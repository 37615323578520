import { Close } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

export const NotificationBanner = ({ setShowNotification }) => {
  return (
    <div className='bg-buttonGreen sticky'>
      <div className='px-4  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8'>
        <div className='relative flex grid items-center grid-cols-2 lg:grid-cols-3'>
          <ul className='flex items-center  space-x-8 lg:flex'>
            <li>
              <a
                href='/'
                aria-label='Our product'
                title='Our product'
                className='font-medium tracking-wide text-white transition-colors duration-200 hover:text-teal-accent-400'
              >
                You have not yet updated your Calendly profile.
              </a>
            </li>
          </ul>
          <Link
            to={'/profile'}
            aria-label='Company'
            title='Company'
            className='inline-flex items-center lg:mx-auto'
          >
            <span
              href='/profile'
              className='inline-flex items-center justify-center my-2 h-8 px-6 font-medium tracking-wide text-buttonGreen transition duration-200 rounded shadow-md bg-white hover:text-charcoal focus:shadow-outline focus:outline-none'
              aria-label='Update Profile'
              title='Update Profile'
            >
              Update Link
            </span>
          </Link>
          <ul className='flex items-center   ml-auto space-x-8 lg:flex'>
            <li>
              <span
                onClick={() => setShowNotification(false)}
                className='inline-flex items-center justify-center h-8  font-medium tracking-wide hover:text-red cursor-pointer text-white transition duration-200  focus:shadow-outline focus:outline-none'
                aria-label='close'
                title='close'
              >
                <Close />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
