import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function RouteWrapper({
  component: Component,
  institutionPrivate,
  systemPrivate,
  userPrivate,
  ...rest
}) {
  const user = useSelector((state) => state?.user);

  const signedIn = user?.token !== null;
  const activeUser = user?.user;

  /**
   * Redirect user to signin page if he tries to access a private route
   * without authentication.
   */
  if ((institutionPrivate && !signedIn) || (userPrivate && !signedIn)) {
    return <Redirect to='/login' />;
  }

  if (systemPrivate && !signedIn) {
    return <Redirect to='/login' />;
  }
  /**
   * Redirect user to Main page if he tries to access a non private route
   * after being authenticated.
   */
  if (activeUser?.usercategory[0] === 'institution') {
    if (userPrivate && signedIn) return <Redirect to='/institution/feeds' />;
  }

  if (activeUser?.usercategory[0] !== 'institution') {
    if (institutionPrivate && signedIn) return <Redirect to='/feeds' />;
  }

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return <Route {...rest} component={Component} />;
}
RouteWrapper.propTypes = {
  institutionPrivate: PropTypes.bool,
  userPrivate: PropTypes.bool,
  systemPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
RouteWrapper.defaultProps = {
  institutionPrivate: false,
  userPrivate: false,
  systemPrivate: false,
};
