import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

// Icons
import { Close, ExpandMore } from '@material-ui/icons';
import { motion } from 'framer-motion/dist/framer-motion';

// css
import '../bright.css';
// import { logOut } from '../redux/actions/users';

// svg icons
// import { ReactComponent as Search } from '../assets/search.svg';
import { ReactComponent as NotificationBellIcon } from '../assets/notification.svg';

//Components
// import Input from './Input';
import UserMenu from './UserDropdown';

// Actions
// import * as searchActions from '../redux/actions/search';

export default function Navbar({ background }) {
  const history = useHistory();

  const [dropDownActive, setDropDownActive] = useState(false);
  const [show, setShow] = useState(false);

  // const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  // let [keywords, setKeywords] = useState('');

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(searchActions.search({ keywords, skip: 0, limit: 30 }));
  //   history.push('/search');
  // };

  const mouseHoveredBrowse = () => {
    setDropDownActive(true);
  };

  const mouseUnHoveredDropDown = () => {
    setDropDownActive(false);
  };

  const toLink = (link) => {
    history.push(`${link}`);
    console.log(link);
    setDropDownActive(false);
  };

  const close = () => {
    setDropDownActive(false);
    setShow(false);
  };

  return (
    <div className='top-0 fixed z-50 w-full'>
      {/* <div className='flex items-center hidden space-x-8 md:flex'> */}
      <div className={`w-full ${background} md:navbarHeight`}>
        <div className='nav open-sans'>
          <nav className='pt-3 pb-5'>
            <div className='pl-3 md:pl-10 block w-full lg:hidden flex flex-row justify-between pr-3 py-auto'>
              <Link to='/'>
                {/* Delv icon on big screen */}
                <p
                  style={{
                    height: '48px',
                    width: '130px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundImage: `url('https://irp.cdn-website.com/09b19d04/dms3rep/multi/delv-web-logo-01.svg')`,
                  }}
                  className='delv cursor-pointer'
                ></p>
              </Link>

              <div className='my-auto'>
                <button
                  onClick={() => setShow(!show)}
                  className='flex items-center p-1 text-gray hover:text-white hover:border-white'
                >
                  <svg
                    className='fill-current h-4 w-4'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <title>Menu</title>
                    <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
                  </svg>
                </button>
              </div>
            </div>

            {show && (
              <div
                className='w-full absolute top-20 md:top-0 md:h-screen'
                style={{
                  background: 'rgba(0,0,0,0.4)',
                  width: '100vw',
                  height: '100vh',
                }}
              >
                {/* Navbar on small screens that has no log in link */}
                <nav>
                  <ul className='w-full h-2/3 md:h-screen ml-0 md:ml-auto bg-newMain md:w-1/2 z-55'>
                    <li className='text-4xl w-full Cabin cursor-pointer text-right pr-6'>
                      <span
                        className='text-white Cabin cursor-pointer text-2xl'
                        onClick={() => close()}
                      >
                        <Close />
                      </span>
                    </li>
                    {/* Broswe on small screens */}
                    <li className='w-full pt-6 pl-5 md:pl-4'>
                      <div
                        className='flex flex-row text-white cursor-pointer'
                        onClick={() => setDropDownActive(!dropDownActive)}
                        onMouseOver={() => mouseHoveredBrowse()}
                      >
                        BROWSE
                        <span className='ml-2'>
                          {dropDownActive ? (
                            <motion.span
                              animate={{ rotate: 180, x: 0, y: 0 }}
                              transition={{ duration: 0.3 }}
                              className='flex inline'
                            >
                              <span>
                                <ExpandMore />
                              </span>
                            </motion.span>
                          ) : (
                            <ExpandMore />
                          )}
                        </span>
                      </div>
                    </li>

                    {/* User name on smaller screens */}
                    <li className='w-full pb-6 mt-2 pl-5 md:pl-4 flex flex-row'>
                      {user?.profilePicture?.publicUrl && (
                        <img
                          src={user?.profilePicture?.publicUrl}
                          alt='profile'
                          className='rounded-full h-10 w-10 flex items-center object-cover justify-center mr-1 ml-3'
                        />
                      )}
                      <div className='flex items-center text-white text-white'>
                        <UserMenu
                          user={`${user?.firstname} ${user?.lastname}`}
                        />
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            )}

            <div className='nav hidden lg:block'>
              <div className='w-full flex flex-row justify-center'>
                <div className=''>
                  <Link to='/'>
                    {/* Delv icon on small screen */}
                    <p
                      style={{
                        height: '48px',
                        width: '130px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundImage: `url('https://irp.cdn-website.com/09b19d04/dms3rep/multi/delv-web-logo-01.svg')`,
                      }}
                      className='delv text-xl ml-10 mr-2 my-auto cursor-pointer'
                    ></p>
                  </Link>
                </div>

                <div
                  className='flex flex-row text-white ml-8 py-3 my-auto cursor-pointer'
                  onClick={() => setDropDownActive(!dropDownActive)}
                  onMouseOver={() => mouseHoveredBrowse()}
                >
                  BROWSE
                  <span className='ml-2'>
                    {dropDownActive ? (
                      <motion.span
                        animate={{ rotate: 180, x: 0, y: 0 }}
                        transition={{ duration: 0.3 }}
                        className=''
                      >
                        <span>
                          <ExpandMore />
                        </span>
                      </motion.span>
                    ) : (
                      <ExpandMore />
                    )}
                  </span>
                </div>

                {/* Navbar data for logged in user */}
                {user ? (
                  <div className='flex lg:flex-row  ml-auto mr-3 items-center'>
                    <span className='rounded-full  h-10 w-10 flex items-center justify-center bg-newMain mr-3 text-white'>
                      <NotificationBellIcon />
                    </span>

                    {user?.profilePicture?.publicUrl && (
                      <img
                        src={user?.profilePicture?.publicUrl}
                        alt='profile'
                        className='rounded-full h-10 w-10 flex items-center object-cover justify-center mr-1 ml-3'
                      />
                    )}

                    <div className='flex items-center mr-6'>
                      <UserMenu user={`${user?.firstname} ${user?.lastname}`} />
                    </div>
                    <div className='w-50 h-12 text-white flex justify-center align-center mr-3 border border-white rounded-signup'>
                      <Link
                        to='/signup'
                        className='md:px-6 lg:px-8 my-auto cursor-pointer'
                      >
                        GO PREMIUM
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className='flex flex-row ml-auto mr-3'>
                    {/* Navbar right content for un logged in user */}
                    <div className='flex flex-row ml-auto mr-3'>
                      <div className='hover:text-decoration-none text-white mr-6 my-auto py-auto'>
                        <Link to='/login'>LOGIN</Link>
                      </div>

                      <div className='w-50 h-12 text-white flex justify-center align-center ml-8 mr-3 border border-white rounded-signup'>
                        <Link to='/signup' className='px-10 my-auto'>
                          SIGNUP TODAY
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>

      {dropDownActive && (
        <motion.div animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
          {/* Broswe drop down on small screens */}
          <div
            className='bg-newMain text-white w-full md:w-1/3 lg:w-1/6 absolute top-48 md:top-20 md:right-10 lg:top-16 lg:left-40 lg:right-96 z-99'
            onMouseLeave={() => mouseUnHoveredDropDown()}
          >
            <nav>
              <ul className='w-full'>
                <li
                  className='dropDownItem p-3 animate-spin'
                  onClick={() => toLink('/careerguidance')}
                >
                  CAREER GUIDANCE
                </li>
                <li
                  className='dropDownItem p-3 border-t border-white border-b'
                  onClick={() => toLink('/upskilling')}
                >
                  UPSKILL
                </li>
                <li
                  className='dropDownItem p-3  '
                  onClick={() => toLink('/JobSearch')}
                >
                  JOBS
                </li>
              </ul>
            </nav>
          </div>
        </motion.div>
      )}
    </div>
  );
}
